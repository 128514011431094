import { EditOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { navigate } from "@reach/router";
import * as React from "react";
import styled from "styled-components";
import ProfileAvailability from "./ProfileAvailability";
import ProfileItemData from "./ProfileItemData";
import { GetProfiles_profiles } from "./__generated__/GetProfiles";

interface Props {
    profile: GetProfiles_profiles;
    organizationId: string;
}

const Wrapper = styled.div`
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    margin-bottom: 14px;
`;

const TitleSection = styled.div`
    padding: 5px 10px;
    background-color: ${(props) => props.theme.grayBackground};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.code`
    font-size: 14px;
    color: ${(props) => props.theme.secondary};
    font-weight: bold;
`;

const Content = styled.div`
    padding: 10px 25px 10px 15px;
    font-size: 14px;
    display: flex;
    width: 100%;
`;

const Data = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const UL = styled.ul`
    padding: 0px;
    list-style: none;
    margin: 0px;
`;

const LI = styled.li`
    display: flex;
    margin-bottom: 3px;
`;

const AbrResolution = styled.div`
    width: 60px;
`;

const Price = styled.div`
    width: 100px;
`;

export default function ProfileItem({ profile, organizationId }: Props) {
    const {
        profileId,
        publiclyAvailable,
        viewedPricePerMinute,
        transcodedPricePerMinute,
        maxBitrate,
        maxFps,
        maxResolution,
        abrLadder,
    } = profile;

    function onEditClick() {
        navigate(`/app/${organizationId}/config/profiles/${profile.id}/edit`);
    }

    return (
        <Wrapper>
            <TitleSection>
                <Title>{profileId}</Title>
                <Actions>
                    <Tooltip title="Edit">
                        <IconButton onClick={onEditClick}>
                            <EditOutlined style={{ fontSize: "20px" }} />
                        </IconButton>
                    </Tooltip>
                </Actions>
            </TitleSection>
            <Content>
                <Data>
                    <ProfileAvailability type={publiclyAvailable ? "public" : "private"} />
                    <ProfileItemData title="Max bit rate">{maxBitrate} Mbps</ProfileItemData>
                    <ProfileItemData title="Max resolution">{maxResolution.replace("r_", "")}</ProfileItemData>
                    <ProfileItemData title="Max fps">{maxFps}fps</ProfileItemData>
                    <ProfileItemData title="ABR ladder">
                        <UL>
                            {abrLadder.entries.map((entry, i) => (
                                <LI key={`entry-${i}`}>
                                    <AbrResolution>{entry.resolution.replace("r_", "")}:</AbrResolution> {entry.bitRate}{" "}
                                    Mbps
                                </LI>
                            ))}
                        </UL>
                    </ProfileItemData>
                    <ProfileItemData title="Pricing">
                        <UL>
                            <LI>
                                <Price>Transcoded:</Price> ${transcodedPricePerMinute}/min
                            </LI>
                            <LI>
                                <Price>Viewed:</Price> ${viewedPricePerMinute}/min
                            </LI>
                        </UL>
                    </ProfileItemData>
                </Data>
            </Content>
        </Wrapper>
    );
}

import { Grid, DialogActions, DialogContent } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { BitrateMode, ResolutionType } from "../../../__generated__/globalTypes";
import { GetProfiles_profiles } from "../../AdminConfig/Profiles/__generated__/GetProfiles";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";

import DialogHESP from "../../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import ChannelStreamConfigurator, {
    determineSelectedFpsAfterBitrateOrResolutionChange,
    determineSelectedResolutionAfterBitrateChange,
    getFpsValues,
    StreamConfigType,
} from "../Create/ChannelStreamConfigurator";
import StreamConfigurationInformation from "../Create/StreamConfigurationInformation";
import { GET_CHANNEL_QUERY, UPDATE_STREAM_CONFIG_MUTATION } from "../Overview/channelQueriesMutations";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import {
    UpdateStreamConfigMutation,
    UpdateStreamConfigMutationVariables,
} from "../Overview/__generated__/UpdateStreamConfigMutation";
import { resolutionSort } from "../Create/CreateChannelForm";

interface Props {
    open: boolean;
    onClose: () => void;
    channel: GetChannelQuery_channel;
    profiles: GetProfiles_profiles[];
    onSuccess: () => void;
    onError: () => void;
}

interface ConfigValues {
    bitrate: number;
    resolution: ResolutionType;
    fps: number;
    abr: boolean;
    drm: boolean;
    bitrateMode: BitrateMode;
}

function UpdateStreamConfigDialog({ open, onClose, channel, profiles, onSuccess, onError }: Props) {
    const { channelId, streamConfig } = channel;

    const allBitrates = _.uniq(profiles.map((p) => p.maxBitrate)).sort((a, b) => (a > b ? -1 : 1));
    const allResolutions = _.uniq(profiles.map((p) => p.maxResolution)).sort(resolutionSort);
    const fpses = _.uniq(profiles.map((p) => p.maxFps));
    const allFpsValues = getFpsValues(fpses);

    const initBitrate = streamConfig.bitrate;
    const initResolution = streamConfig.resolution;
    const initFps = streamConfig.fps;
    const initAbr = streamConfig.abr;
    const initDrm = streamConfig.drm?.enabled === true;
    const initBitrateMode = streamConfig.bitrateMode;

    const [values, setValues] = React.useState<ConfigValues>({
        bitrate: initBitrate,
        resolution: initResolution,
        fps: initFps,
        abr: initAbr,
        drm: initDrm,
        bitrateMode: initBitrateMode,
    });

    const [updateStreamConfigMut, { loading }] = useMutation<
        UpdateStreamConfigMutation,
        UpdateStreamConfigMutationVariables
    >(UPDATE_STREAM_CONFIG_MUTATION);

    function onChangeConfigValue(
        type: StreamConfigType,
        value: string | number | ResolutionType | BitrateMode | boolean,
    ) {
        switch (type) {
            case "bitrate":
                onBitrateChange(value as number);
                break;
            case "resolution":
                onResolutionChanged(value as ResolutionType);
                break;
            case "abr":
                setValues({
                    ...values,
                    abr: value as boolean,
                });
                break;
            case "drm":
                setValues({
                    ...values,
                    drm: value as boolean,
                });
                break;
            case "fps":
                setValues({
                    ...values,
                    fps: value as number,
                });
                break;
            case "bitratemode":
                setValues({
                    ...values,
                    bitrateMode: value as BitrateMode,
                });
                break;
        }
    }

    function onBitrateChange(value: number) {
        const newBitrate = value as number;
        const newResolution = determineSelectedResolutionAfterBitrateChange(profiles, newBitrate, values.resolution);
        const newFps = determineSelectedFpsAfterBitrateOrResolutionChange(
            profiles,
            newBitrate,
            newResolution,
            values.fps,
        );
        setValues({
            ...values,
            bitrate: newBitrate,
            fps: newFps,
            resolution: newResolution,
        });
    }

    function onResolutionChanged(value: ResolutionType) {
        const newResolution = value;
        const newFps = determineSelectedFpsAfterBitrateOrResolutionChange(
            profiles,
            values.bitrate,
            newResolution,
            values.fps,
        );
        setValues({
            ...values,
            fps: newFps,
            resolution: newResolution,
        });
    }

    async function onSave() {
        try {
            const { bitrate, fps, resolution, abr, drm, bitrateMode } = values;
            await updateStreamConfigMut({
                variables: {
                    input: {
                        bitrate,
                        channelId,
                        organizationId: channel.organizationId,
                        fps,
                        resolution,
                        abr,
                        drm,
                        bitrateMode,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            toast.success("Configuration updated!", TOAST_SETTINGS);
            onClose();
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    const selectedProfile = profiles.find(
        (p) =>
            p.maxBitrate === values.bitrate &&
            p.maxFps === getMaxFps(values.fps) &&
            p.maxResolution === values.resolution,
    );

    function getMaxFps(val: number) {
        return val <= 30 ? 30 : 60;
    }

    return (
        <DialogHESP
            title={"Update config"}
            open={open}
            onClose={onClose}
            bigSize={true}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                                <ChannelStreamConfigurator
                                    allBitrates={allBitrates}
                                    allFpsValues={allFpsValues}
                                    allResolutions={allResolutions}
                                    selectedBitrate={values.bitrate}
                                    selectedResolution={values.resolution}
                                    selectedFps={values.fps}
                                    abr={values.abr}
                                    bitrateMode={values.bitrateMode}
                                    profiles={profiles}
                                    onChange={(
                                        type: StreamConfigType,
                                        value: string | number | ResolutionType | boolean,
                                    ) => onChangeConfigValue(type, value)}
                                    drm={values.drm}
                                    hasAccessToDrm={channel.organization.channelSettings?.drm?.enabled === true}
                                    organizationId={channel.organizationId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StreamConfigurationInformation
                                    drm={values.drm}
                                    abr={values.abr}
                                    profile={selectedProfile!}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onActionClick={onSave}
                            onCancelClick={onClose}
                            defaultText={"Update"}
                            loadingText={"Updating..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default UpdateStreamConfigDialog;

import * as React from "react";

import { Divider, Paper, Typography } from "@mui/material";

export interface Props {
    payload?: any;
    label?: string;
    title?: string;
    unit?: string;
    unitValue?: string;
}

const InsightsChartTooltip = ({ label, payload = [], unit, unitValue }: Props) => {
    const total = payload ? payload.reduce((acc: any, item: any) => acc + item.value, 0) : 0;

    return (
        <Paper style={{ opacity: 0.95, padding: "5px", display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" style={{ fontSize: "14px", paddingBottom: "5px" }}>{`${label}${
                unit ?? ""
            }`}</Typography>
            <Divider />
            <Typography variant="caption" style={{ color: "#FFC713", paddingTop: "5px" }}>
                Amount: {total}
                {unitValue ?? ""}
            </Typography>
        </Paper>
    );
};

export default InsightsChartTooltip;

import * as React from "react";
import styled from "styled-components";
import { GetWebhookLogsQuery_webhookLogs_logs } from "./__generated__/GetWebhookLogsQuery";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { STYLED_THEME } from "../../AppWrapper";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import moment from "moment";

const Wrapper = styled.div.attrs((props: { selected: boolean }) => ({
    selected: props.selected,
}))`
    padding: 12px 5px 8px 5px;
    border-bottom: ${(props) =>
        props.selected ? `2px solid ${props.theme.primary}` : `1px solid ${props.theme.lightGrayBottom}`};
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
`;

const EventWrapper = styled.div`
    font-family: monospace;
    color: ${(props) => props.theme.secondary};
    display: flex;
    flex-direction: row;
`;

const DateWrapper = styled.div`
    text-align: right;
    font-size: 14px;
`;

const IconWrapper = styled.div`
    margin-right: 16px;
`;

interface Props {
    log: GetWebhookLogsQuery_webhookLogs_logs;
    selected: boolean;
    onClick: () => void;
}

function WebhookHistoryDataOverviewItem({ log, selected, onClick }: Props) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <Wrapper selected={selected} onClick={onClick}>
            <EventWrapper>
                <IconWrapper>
                    {log.statusCode === 200 ? (
                        <CheckCircleRoundedIcon style={{ fontSize: "18px", color: STYLED_THEME.success }} />
                    ) : (
                        <CancelRoundedIcon style={{ fontSize: "18px", color: STYLED_THEME.error }} />
                    )}
                </IconWrapper>
                {log.requestData.type}
            </EventWrapper>
            <DateWrapper>{moment(log.timestamp).tz(tz).format("YYYY-MM-DD HH:mm:ss")}</DateWrapper>
        </Wrapper>
    );
}

export default WebhookHistoryDataOverviewItem;

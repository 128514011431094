interface AnalyticsRecord {
    timestamp: string;
    amount: number;
}

export function calculateTotal(records: AnalyticsRecord[]): number {
    const amountsOnly: number[] = records.map((record) => record.amount);
    const total = amountsOnly.reduce((a, b) => a + b, 0);
    return total;
}

import { FormControl, Grid, TextField } from "@mui/material";
import * as React from "react";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";

interface Props {
    transcoded: number;
    viewed: number;
    drm: number;
    onChangeTranscoded: (price: number) => void;
    onChangeViewed: (price: number) => void;
    onChangeDrm: (price: number) => void;
}

export default function ChannelAdminPricing({
    transcoded,
    viewed,
    onChangeTranscoded,
    onChangeViewed,
    drm,
    onChangeDrm,
}: Props) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Price per transcoded minute (USD)" />
                    <TextField
                        onChange={(e) => onChangeTranscoded(parseFloat(e.target.value))}
                        fullWidth
                        InputProps={{ inputProps: { min: 0, max: 100, step: 0.001 } }}
                        type="number"
                        value={transcoded}
                        style={{ backgroundColor: "white" }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Price per viewed minute (USD)" />
                    <TextField
                        onChange={(e) => onChangeViewed(parseFloat(e.target.value))}
                        fullWidth
                        InputProps={{ inputProps: { min: 0, max: 100, step: 0.0001 } }}
                        type="number"
                        value={viewed}
                        style={{ backgroundColor: "white" }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Price for DRM per viewed minute (USD)" />
                    <TextField
                        onChange={(e) => onChangeDrm(parseFloat(e.target.value))}
                        fullWidth
                        InputProps={{ inputProps: { min: 0, max: 100, step: 0.0001 } }}
                        type="number"
                        value={drm}
                        style={{ backgroundColor: "white" }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

import * as React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import styled from "styled-components";
import { HESPlink } from "../Landing/SignUpComplete";

const Wrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
`;

function GoogleDenied() {
    return (
        <>
            <SectionTitle title="Google Sign Up denied" align="center" />
            <Wrapper>
                <p style={{ marginBottom: "40px" }}>It's not possible anymore to create an account via Google.</p>
                <p>
                    You can request an account <HESPlink link={`https://www.theoplayer.com/free-trial`}>here</HESPlink>.
                </p>
            </Wrapper>
        </>
    );
}

export default GoogleDenied;

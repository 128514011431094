import * as React from "react";
import styled from "styled-components";
import { DialogActions, DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { GetChannelsOrganizationQuery_channels } from "./__generated__/GetChannelsOrganizationQuery";
import { DELETE_CHANNEL_MUTATION, GET_CHANNELS_ORGANIZATION_QUERY, GET_CHANNEL_QUERY } from "./channelQueriesMutations";
import { useMutation } from "react-apollo";
import { DeleteChannelMutation, DeleteChannelMutationVariables } from "./__generated__/DeleteChannelMutation";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";

interface Props {
    open: boolean;
    onClose: () => void;
    channel: GetChannelsOrganizationQuery_channels;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

function DeleteChannelDialog({ open, onClose, channel }: Props) {
    const [deleteChannelMut, { loading }] = useMutation<DeleteChannelMutation, DeleteChannelMutationVariables>(
        DELETE_CHANNEL_MUTATION,
    );

    const { channelId } = channel;

    async function deleteChannel() {
        try {
            await deleteChannelMut({
                variables: {
                    input: {
                        channelId,
                        organizationId: channel.organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            toast.success("Channel got deleted", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Delete channel"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>Are you sure you want to delete the channel "{channel.metadata.name}"?</Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Delete"}
                            loadingText={"Deleting..."}
                            onActionClick={() => deleteChannel()}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default DeleteChannelDialog;

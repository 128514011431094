import * as React from "react";
import styled from "styled-components";
import { GetActiveRunsQuery } from "./__generated__/GetActiveRunsQuery";
import ActiveChannelsTable, { GET_ACTIVE_RUNS } from "./ActiveChannelsTable";
import CardHESP from "../../CardHESP/CardHESP";
import { useQuery } from "react-apollo";
import CentralLoader from "../../Loading/CentralLoader";

const CenterText = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function ActiveChannelsContent() {
    const { data, loading } = useQuery<GetActiveRunsQuery>(GET_ACTIVE_RUNS);

    if (loading || !data) {
        return (
            <CardHESP style={{ height: "150px" }}>
                <CentralLoader text="Getting channels..." />
            </CardHESP>
        );
    }

    if (data.activeChannelRuns.length === 0) {
        return (
            <CardHESP style={{ height: "150px" }}>
                <CenterText>No active channels</CenterText>
            </CardHESP>
        );
    }

    return <ActiveChannelsTable activeRuns={data.activeChannelRuns} />;
}

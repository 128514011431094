import { Check, Clear, DeleteForever } from "@mui/icons-material";
import { Chip, IconButton, Switch } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import AutocompleteDropdown from "../../AutocompleteDropdown/AutocompleteDropdown";

interface Props {
    title: string;
    isDropdownSearch?: boolean;
    onRemove?: () => void;
    onSelectItem: (item: string) => void;
    onDeselectItem: (item: string) => void;
    onSelectAll: () => void;
    onDeselectAll: () => void;
    items: { key: string; value: string }[];
    selectedItems: string[];
}

const Wrapper = styled.div`
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const DataWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 15px;
    margin-bottom: 4px;
    font-weight: bolder;
`;

const BadgeWrapper = styled.div`
    margin-bottom: 12px;
    margin-right: 10px;
`;

const ItemsWrapper = styled.div`
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
`;

const SwitchWrapper = styled.div`
    margin-top: 2px;
`;

const RemoveWrapper = styled.div``;

const SwitchTitle = styled.span`
    font-size: 15px;
`;

const SearchWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 4px;
    min-width: 320px;
`;

export default function ChannelFilterGroup({
    items,
    onRemove,
    selectedItems,
    title,
    onDeselectItem,
    onSelectItem,
    onDeselectAll,
    onSelectAll,
    isDropdownSearch,
}: Props) {
    const allSelected = items.length === selectedItems.length;

    const showDropdown = isDropdownSearch === true;

    return (
        <Wrapper>
            <DataWrapper>
                <Title>{title} </Title>
                <SwitchWrapper>
                    <Switch
                        size="small"
                        checked={allSelected}
                        onChange={() => (allSelected ? onDeselectAll() : onSelectAll())}
                    />{" "}
                    <SwitchTitle>Select all</SwitchTitle>
                </SwitchWrapper>
                {!allSelected && (
                    <>
                        {showDropdown && (
                            <SearchWrapper>
                                <AutocompleteDropdown
                                    items={items
                                        .filter((i) => !selectedItems.includes(i.key))
                                        .map((i) => ({ id: i.key, name: i.value }))}
                                    title={title}
                                    onSelectItem={(key: string) => {
                                        onSelectItem(key);
                                    }}
                                    selectedItem={undefined}
                                    key={`${selectedItems.length}`}
                                />
                            </SearchWrapper>
                        )}

                        <ItemsWrapper>
                            {showDropdown && (
                                <>
                                    {selectedItems.map((item, i) => (
                                        <BadgeWrapper key={`event-${i}`}>
                                            <Chip
                                                style={{
                                                    padding: "6px",
                                                    height: "30px",
                                                }}
                                                size="small"
                                                label={items.find((i) => i.key === item)!.value}
                                                onDelete={() => onDeselectItem(item)}
                                            />
                                        </BadgeWrapper>
                                    ))}
                                </>
                            )}
                            {!showDropdown && (
                                <>
                                    {items.map((item, i) => (
                                        <BadgeWrapper key={`event-${i}`}>
                                            <Chip
                                                style={{
                                                    padding: "6px",
                                                    height: "30px",
                                                    cursor: "pointer",
                                                    backgroundColor: selectedItems.includes(item.key) ? "#dde7ed" : "",
                                                }}
                                                size="small"
                                                label={item.value}
                                                onClick={() => {
                                                    if (selectedItems.includes(item.key)) {
                                                        onDeselectItem(item.key);
                                                    } else {
                                                        onSelectItem(item.key);
                                                    }
                                                }}
                                                icon={selectedItems.includes(item.key) ? <Check /> : <Clear />}
                                            />
                                        </BadgeWrapper>
                                    ))}
                                </>
                            )}
                        </ItemsWrapper>
                    </>
                )}
            </DataWrapper>

            <RemoveWrapper>
                {onRemove && (
                    <IconButton onClick={onRemove} color="error">
                        <DeleteForever />
                    </IconButton>
                )}
            </RemoveWrapper>
        </Wrapper>
    );
}

import { DialogContent, Grid, FormControl, TextField, Typography, DialogActions } from "@mui/material";
import { toast } from "react-toastify";
import * as React from "react";
import { useForm } from "react-hook-form";
import DialogHESP from "../DialogHESP/DialogHESP";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ChangePasswordMutation, ChangePasswordMutationVariables } from "./__generated__/ChangePasswordMutation";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const CHANGE_PASSWORD_MUTATION = gql`
    mutation ChangePasswordMutation($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            firstName
        }
    }
`;

interface Props {
    open: boolean;
    onClose: () => void;
}

interface FormData {
    password: string;
    repeat: string;
}

function ChangePasswordDialog({ open, onClose }: Props) {
    const { handleSubmit, register, errors, getValues } = useForm<FormData>();

    const [changePasswordMut, { loading }] = useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
        CHANGE_PASSWORD_MUTATION,
    );

    const changePassword = handleSubmit(async ({ password }: FormData) => {
        try {
            await changePasswordMut({
                variables: {
                    input: {
                        password,
                    },
                },
            });
            toast.success("Password changed! 🚀", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    });

    return (
        <DialogHESP
            title={"Change password"}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit((data1, e) => changePassword(e))}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label={"New Password"} />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="password"
                                        type="password"
                                        inputRef={register({
                                            required: "Password is required",
                                            minLength: {
                                                value: 6,
                                                message: "Password should contain at least 6 characters",
                                            },
                                        })}
                                    />
                                    <Typography variant="subtitle2" color="error">
                                        {errors.password?.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label={"Confirm Password"} />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="repeat"
                                        type="password"
                                        inputRef={register({
                                            required: "Repeat password is required",
                                            validate: (value: string) =>
                                                value === getValues().password || "Should match password",
                                        })}
                                    />
                                    <Typography variant="subtitle2" color="error">
                                        {errors.repeat?.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText="Change"
                            loadingText="Changing..."
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default ChangePasswordDialog;

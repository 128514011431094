import { Grid } from "@mui/material";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { GetDatacenter_datacenter_channels } from "../../views/App/__generated__/GetDatacenter";
import styled from "styled-components";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Code } from "../Channels/Details/ChannelEmbedExample";
import { navigate } from "@reach/router";

interface Props {
    channels: GetDatacenter_datacenter_channels[];
}

const Channel = styled.div`
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 14px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 13px;
`;

export default function TLADatacenterChannels({ channels }: Props): JSX.Element {
    return (
        <CardHESP>
            <CardHESPTitle title="Connected channels" />
            <Grid container spacing={2} style={{ marginTop: "1px" }}>
                {channels.length === 0 && (
                    <Grid item xs={12}>
                        No channels connected
                    </Grid>
                )}
                {channels.map((channel) => (
                    <Grid item xs={12}>
                        <Channel
                            key={channel.channelId}
                            onClick={() =>
                                navigate(`/app/${channel.organization.organizationId}/channels/${channel.channelId}`)
                            }
                        >
                            <div>
                                <div>
                                    {channel.metadata.name} (<Code>{channel.channelId}</Code>)
                                </div>
                                <div style={{ color: "#8c8c8c" }}>{channel.organization.name}</div>
                            </div>
                            <div>
                                <KeyboardDoubleArrowRight color="primary" />
                            </div>
                        </Channel>
                    </Grid>
                ))}
            </Grid>
        </CardHESP>
    );
}

import { FormControl, TextField, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import GoogleButtonHESP from "../GoogleButtonHESP/GoogleButtonHESP";
import { RegistrationOrSection } from "./SignUp";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { HESPlink } from "./SignUpComplete";
import styled from "styled-components";
import AuthService from "../../auth/AuthService";
import Callout, { CalloutType } from "../Callout/Callout";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { LoginMutation } from "./__generated__/LoginMutation";
import { navigate } from "@reach/router";
import LoadingButtonPublic from "../Loading/LoadingButtonPublic";

export const CREATE_ORGANIZATION_FOR_USER_MUTATION = gql`
    mutation CreateOrganizationForUserMutation {
        createOrganizationForUser {
            organizationId
        }
    }
`;

export const LOGIN_MUTATION = gql`
    mutation LoginMutation {
        login
    }
`;

interface Props {
    onForgotPassword: () => void;
}

interface FormData {
    email: string;
    password: string;
}

export const UseTHEOportalSection = styled.div`
    color: ${(props) => props.theme.gray};
    text-align: left;
`;

function SignIn({ onForgotPassword }: Props) {
    const [error, setError] = React.useState({ showError: false, title: "", description: "", type: "danger" });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [loginMut] = useMutation<LoginMutation>(LOGIN_MUTATION);

    const { handleSubmit, register } = useForm<FormData>();

    const onSubmit = handleSubmit(async ({ email, password }: FormData) => {
        setIsLoading(true);
        try {
            const res = await AuthService.login(email, password);
            const userProfile = await AuthService.getUserProfile(res.accessToken);
            AuthService.setAuth(res, userProfile);
            if (!userProfile.organizationId || userProfile.organizationId === null) {
                // user has THEOportal account, but no organization for THEOlive
                navigate(`/no-account`);
            } else {
                await loginMut();
                navigate(`/app/${userProfile.organizationId}`);
            }
        } catch (e: any) {
            if (e.description) {
                setError({
                    showError: true,
                    type: "danger",
                    title: "Authentication error",
                    description: e.description,
                });
            } else {
                setError({
                    showError: true,
                    type: "danger",
                    title: "Something went wrong",
                    description: "An unexpected error happened",
                });
            }
        }
        setIsLoading(false);
    });

    function signInWithGoogle() {
        AuthService.signInWithGoogle();
    }

    return (
        <form onSubmit={handleSubmit((data, e) => onSubmit(e))}>
            <GoogleButtonHESP isSignUp={false} onClick={signInWithGoogle} />
            <RegistrationOrSection>Or</RegistrationOrSection>
            {error.showError && (
                <Callout type={error.type as CalloutType} title={error.title} description={error.description} />
            )}

            <Grid container spacing={2} direction="column">
                <Grid item>
                    <FormControl fullWidth>
                        <HESPFormLabel label={"Work email"} />
                        <TextField variant="outlined" fullWidth name="email" inputRef={register} />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <HESPFormLabel label={"Password"} />
                        <TextField variant="outlined" fullWidth type="password" name="password" inputRef={register} />
                        <div style={{ textAlign: "right", marginTop: "5px", fontSize: "14px" }}>
                            Forgot your password? Request <HESPlink link={"forgot-password"}>a link</HESPlink>
                        </div>
                    </FormControl>
                </Grid>

                <Grid item style={{ paddingBottom: "50px" }}>
                    <LoadingButtonPublic isLoading={isLoading} defaultText={"Sign In"} loadingText={"Signing in..."} />
                </Grid>
            </Grid>
        </form>
    );
}

export default SignIn;

import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import WebhookDetails from "../../components/WebhookDetails/WebhookDetails";
import WebhookHistory from "../../components/WebhookDetails/WebhookHistory";

interface Props extends RouteComponentProps {
    webhookId?: string;
    organizationId?: string;
}

function Webhook({ webhookId, organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <WebhookDetails webhookId={webhookId!} organizationId={organizationId!} />
                    </Grid>
                    <Grid item xs={12}>
                        <WebhookHistory webhookId={webhookId!} organizationId={organizationId!} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Webhook;

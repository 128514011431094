import { Grid } from "@mui/material";
import * as React from "react";
import BasicOrganizationData from "./BasicOrganizationData";
import OrganizationUsers from "./OrganizationUsers";
import OrganizationContract from "./OrganizationContract";
import OrganizationCommitmentUsage from "./OrganizationCommitmentUsage";
import OrganizationChannelSettings from "./OrganizationChannelSettings";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";
import OrganizationTLA from "./OrganizationTLA";

interface Props {
    organization: GetFullOrganizationDetails_organization;
}

function OrganizationDetails({ organization }: Props) {
    const { organizationId } = organization;
    return (
        <Grid container spacing={4} style={{ marginTop: "10px" }}>
            <Grid item xs={12} md={5}>
                <BasicOrganizationData organization={organization} />
            </Grid>
            <Grid item xs={12} md={7}>
                <OrganizationContract organization={organization} />
            </Grid>
            <Grid item xs={12}>
                <OrganizationChannelSettings organization={organization} />
            </Grid>
            <Grid item xs={12}>
                <OrganizationTLA organization={organization} />
            </Grid>
            <Grid item xs={12}>
                <OrganizationCommitmentUsage organization={organization} />
            </Grid>
            <Grid item xs={12}>
                <OrganizationUsers organizationId={organizationId} />
            </Grid>
        </Grid>
    );
}

export default OrganizationDetails;

import {
    DeleteForever,
    Edit,
    Engineering,
    FormatPaintOutlined,
    PowerSettingsNew,
    PublicOutlined,
    Security,
    SwitchVideoOutlined,
} from "@mui/icons-material";
import { IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import AuthService from "../../../auth/AuthService";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChannelDeleteAliasDialog from "./ChannelDeleteAliasDialog";
import ChannelDisableAliasDialog from "./ChannelDisableAliasDialog";
import ChannelEnableAliasDialog from "./ChannelEnableAliasDialog";
import { AliasNavigationType } from "./ChannelPlayouts";
import ChannelUpdateAliasDialog from "./ChannelUpdateAliasDialog";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const NavigationSection = styled.div`
    margin-right: 20px;
`;

const EditSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100px;
`;

const TabStyle = styled.div`
    display: flex;
    // width: 100%;
    flex-direction: row;
`;

const TabIconWrapper = styled.div``;

const TabTextWrapper = styled.div`
    font-weight: 600;
    // color: black;
    font-size: 14px;
    margin-left: 10px;
    @media (max-width: 650px) {
        display: none;
    }
`;

interface Props {
    channel: GetChannelQuery_channel;
    alias: string;
    selectedItem: AliasNavigationType;
    isActive: boolean;
    onChange: (item: AliasNavigationType) => void;
    isDefaultChannel: boolean;
    onDeletedAlias: () => void;
}

function ChannelPlayoutsNav({
    alias,
    channel,
    selectedItem,
    onChange,
    isDefaultChannel,
    onDeletedAlias,
    isActive,
}: Props) {
    const iconStyle = { fontSize: "18px" };

    const [openDeleteAliasDialog, setOpenDeleteAliasDialog] = React.useState<boolean>(false);
    const [openUpdateAliasDialog, setOpenUpdateAliasDialog] = React.useState<boolean>(false);
    const [openEnableAliasDialog, setOpenEnableAliasDialog] = React.useState<boolean>(false);
    const [openDisableAliasDialog, setOpenDisableAliasDialog] = React.useState<boolean>(false);

    function onErrorDeleteAlias() {
        toast.error("Something went wrong 😞", TOAST_SETTINGS);
    }

    function onSuccessDeleteAlias() {
        toast.success("Alias got deleted", TOAST_SETTINGS);
        onDeletedAlias();
    }

    function onErrorUpdateAlias() {
        toast.error("Something went wrong 😞", TOAST_SETTINGS);
    }

    function onSuccessUpdateAlias(type: "updated" | "enabled" | "disabled") {
        toast.success(`Alias got ${type} successfully!`, TOAST_SETTINGS);
    }

    const isTHEOAdmin = AuthService.isTHEOAdminSales();

    return (
        <>
            <Wrapper>
                <NavigationSection>
                    <Tabs
                        value={selectedItem}
                        onChange={(_ignore, value: string) => onChange(value as AliasNavigationType)}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        textColor={"secondary"}
                        indicatorColor={"primary"}
                    >
                        <Tab
                            value={"customization"}
                            style={{ minWidth: "10px" }}
                            label={
                                <TabStyle>
                                    <TabIconWrapper>
                                        <FormatPaintOutlined style={iconStyle} color="secondary" />
                                    </TabIconWrapper>
                                    <TabTextWrapper>Customizations</TabTextWrapper>
                                </TabStyle>
                            }
                        />
                        <Tab
                            value={"include"}
                            style={{ minWidth: "10px" }}
                            label={
                                <TabStyle>
                                    <TabIconWrapper>
                                        <PublicOutlined fontSize="small" style={iconStyle} />
                                    </TabIconWrapper>
                                    <TabTextWrapper>Include channel on your page</TabTextWrapper>
                                </TabStyle>
                            }
                        />
                        <Tab
                            value={"failover"}
                            style={{ minWidth: "10px" }}
                            label={
                                <TabStyle>
                                    <TabIconWrapper>
                                        <SwitchVideoOutlined fontSize="small" style={iconStyle} />
                                    </TabIconWrapper>
                                    <TabTextWrapper>Fallback</TabTextWrapper>
                                </TabStyle>
                            }
                        />
                        <Tab
                            value={"security"}
                            style={{ minWidth: "10px" }}
                            label={
                                <TabStyle>
                                    <TabIconWrapper>
                                        <Security fontSize="small" style={iconStyle} />
                                    </TabIconWrapper>
                                    <TabTextWrapper>Security</TabTextWrapper>
                                </TabStyle>
                            }
                        />
                        {isTHEOAdmin && (
                            <Tab
                                value={"admin"}
                                style={{ minWidth: "10px" }}
                                label={
                                    <TabStyle>
                                        <TabIconWrapper>
                                            <Engineering fontSize="small" style={iconStyle} />
                                        </TabIconWrapper>
                                        <TabTextWrapper>Admin</TabTextWrapper>
                                    </TabStyle>
                                }
                            />
                        )}
                    </Tabs>
                </NavigationSection>
                {!isDefaultChannel && (
                    <EditSection>
                        <Tooltip title={`${isActive ? "Disable" : "Enable"} alias`}>
                            <IconButton
                                size="small"
                                color="secondary"
                                onClick={() =>
                                    isActive ? setOpenDisableAliasDialog(true) : setOpenEnableAliasDialog(true)
                                }
                            >
                                <PowerSettingsNew fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Change name of alias">
                            <IconButton size="small" color="secondary" onClick={() => setOpenUpdateAliasDialog(true)}>
                                <Edit fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete alias">
                            <IconButton size="small" color="secondary" onClick={() => setOpenDeleteAliasDialog(true)}>
                                <DeleteForever fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </EditSection>
                )}
            </Wrapper>
            {!isDefaultChannel && (
                <>
                    <ChannelDeleteAliasDialog
                        parentChannelId={channel.channelId}
                        open={openDeleteAliasDialog}
                        alias={channel.aliases.find((a) => a.channelId === alias)!}
                        onClose={() => setOpenDeleteAliasDialog(false)}
                        onError={onErrorDeleteAlias}
                        onSuccess={onSuccessDeleteAlias}
                    />
                    <ChannelUpdateAliasDialog
                        parentChannelId={channel.channelId}
                        open={openUpdateAliasDialog}
                        alias={channel.aliases.find((a) => a.channelId === alias)!}
                        onClose={() => setOpenUpdateAliasDialog(false)}
                        onError={onErrorUpdateAlias}
                        onSuccess={() => onSuccessUpdateAlias("updated")}
                    />
                    <ChannelEnableAliasDialog
                        alias={channel.aliases.find((a) => a.channelId === alias)!}
                        onClose={() => setOpenEnableAliasDialog(false)}
                        onError={onErrorUpdateAlias}
                        onSuccess={() => onSuccessUpdateAlias("enabled")}
                        open={openEnableAliasDialog}
                        parentChannelId={channel.channelId}
                    />
                    <ChannelDisableAliasDialog
                        alias={channel.aliases.find((a) => a.channelId === alias)!}
                        onClose={() => setOpenDisableAliasDialog(false)}
                        onError={onErrorUpdateAlias}
                        onSuccess={() => onSuccessUpdateAlias("disabled")}
                        open={openDisableAliasDialog}
                        parentChannelId={channel.channelId}
                    />
                </>
            )}

            <ToastContainer />
        </>
    );
}

export default ChannelPlayoutsNav;

import { DialogContent, Grid, DialogActions, TextField, FormControl, Typography } from "@mui/material";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import * as React from "react";
import DialogHESP from "../DialogHESP/DialogHESP";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { useForm } from "react-hook-form";
import { useLazyQuery } from "react-apollo";
import gql from "graphql-tag";
import {
    GetOrganizationsByPrimaryEmail,
    GetOrganizationsByPrimaryEmailVariables,
    GetOrganizationsByPrimaryEmail_getOrganizationsByPrimaryEmail,
} from "../AdminSearchCard/__generated__/GetOrganizationsByPrimaryEmail";
import HESPTable from "../HESPTable/HESPTable";
import { HESPlink } from "../Landing/SignUpComplete";
import { TableHeadItem } from "../HESPTable/HESPTableHead";

export const GET_ORGANIZATION_PRIMARY_EMAIL = gql`
    query GetOrganizationsByPrimaryEmail($email: String!) {
        getOrganizationsByPrimaryEmail(email: $email) {
            name
            organizationId
        }
    }
`;
interface Props {
    onClose: () => void;
    open: boolean;
}
interface FormData {
    email: string;
}
function AdminAdvancedSearchDialog({ open, onClose }: Props) {
    const [email, setEmail] = React.useState<string>("");
    const [organizationList, setOrganizationList] = React.useState<GetOrganizationsByPrimaryEmail | undefined>(
        undefined,
    );
    const [isValidInput, setIsValidInput] = React.useState<boolean>(true);
    const [getOrganizationsByEmail, { loading }] = useLazyQuery<
        GetOrganizationsByPrimaryEmail,
        GetOrganizationsByPrimaryEmailVariables
    >(GET_ORGANIZATION_PRIMARY_EMAIL, {
        onCompleted: (data) => {
            setOrganizationList(data);
        },
        onError: (e) => {
            setIsValidInput(false);
        },
        variables: { email },
    });

    const { register, errors, handleSubmit } = useForm<FormData>();

    const searchOrganizationByEmail = handleSubmit(async ({ email }: FormData) => {
        try {
            setOrganizationList(undefined);
            getOrganizationsByEmail({ variables: { email } });
        } catch (_) {}
    });

    const headers: TableHeadItem[] = [
        {
            id: "name",
            label: "Name",
            allowSort: true,
        },
        {
            id: "manage",
            label: "Manage",
            align: "center",
        },
        {
            id: "account",
            label: "Account",
            align: "center",
        },
    ];

    const records = organizationList
        ? organizationList.getOrganizationsByPrimaryEmail.map(
              (organization: GetOrganizationsByPrimaryEmail_getOrganizationsByPrimaryEmail) => ({
                  name: organization.name,
                  manage: <HESPlink link={`/app/${organization.organizationId}/manage-organization`}>Manage</HESPlink>,
                  account: <HESPlink link={`/app/${organization.organizationId}`}>View</HESPlink>,
              }),
          )
        : [{}];

    function onEmailChange(email: string) {
        setIsValidInput(true);
        setOrganizationList(undefined);
        setEmail(email);
    }

    function onClickClose() {
        setEmail("");
        setOrganizationList(undefined);
        onClose();
    }

    return (
        <form onSubmit={handleSubmit((data1, e) => searchOrganizationByEmail(e))}>
            <DialogHESP
                title={"Advanced search"}
                open={open}
                onClose={onClickClose}
                content={
                    <>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <HESPFormLabel label="Organization Email" />
                                        <TextField
                                            onChange={(e) => onEmailChange(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Email"
                                            name="email"
                                            inputRef={register({
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i,
                                                    message: "invalid email address",
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <Typography variant="subtitle2" color="error">
                                        {errors.email && errors.email?.message}
                                    </Typography>
                                </Grid>
                                {organizationList && (
                                    <Grid item xs={12}>
                                        <HESPTable
                                            headers={headers}
                                            data={records}
                                            defaultSortOn={"name"}
                                            defaultSortDirection={"desc"}
                                            size="small"
                                            enablePagination
                                            paginationRowsPerPage={10}
                                            paginationRowsPerPageOptions={[5, 10, 15]}
                                        />
                                    </Grid>
                                )}

                                {!isValidInput && email && (
                                    <Grid item xs={12}>
                                        <Typography color="error">
                                            No organizations could be found for the given email
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <DialogFooterWithLoading
                                isLoading={loading}
                                disabled={!email}
                                onCancelClick={onClickClose}
                                defaultText={"Search"}
                                loadingText={"Searching.."}
                                onActionClick={searchOrganizationByEmail}
                            />
                        </DialogActions>
                    </>
                }
            />
        </form>
    );
}

export default AdminAdvancedSearchDialog;

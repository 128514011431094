import * as React from "react";
import styled from "styled-components";

type ProfileAvailabilityType = "public" | "private";

interface Props {
    type: ProfileAvailabilityType;
}

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
`;

const Badge = styled.div.attrs((props: { type: ProfileAvailabilityType }) => ({
    type: props.type,
}))`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 6px 10px;
    width: 60px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => getTextColor(props.type, props.theme)};
    background-color: ${(props) => getBackgroundColor(props.type, props.theme)};
`;

function getTextColor(type: ProfileAvailabilityType, theme: any) {
    switch (type) {
        case "private":
            return "#8c8c8c";
        case "public":
            return "#6d9979";
    }
}

function getBackgroundColor(type: ProfileAvailabilityType, theme: any) {
    switch (type) {
        case "private":
            return "#f3f3f3";
        case "public":
            return "#e1f4e6";
    }
}

export default function ProfileAvailability({ type }: Props) {
    return (
        <Wrapper>
            <Badge type={type}>{type}</Badge>
        </Wrapper>
    );
}

import * as React from "react";
import styled from "styled-components";
import { GetProfile_profile } from "../../../views/App/__generated__/GetProfile";

interface Props {
    profile: GetProfile_profile;
    abr: boolean;
    drm: boolean;
}

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.lightBlueBackground};
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
`;

export default function StreamConfigurationInformation({ profile, abr, drm }: Props) {
    const defaultDrmCost = 0.0005;
    const extraDrmCost = drm ? defaultDrmCost : 0;
    return (
        <Wrapper>
            <p>
                <strong>Pricing for this configuration: </strong> ${profile.transcodedPricePerMinute} per minute
                transcoded, ${profile.viewedPricePerMinute + extraDrmCost} per minute viewed.
            </p>
            <div>
                <strong>ABR ladder for this configuration:</strong>{" "}
                {abr ? (
                    <span>
                        <ul>
                            {profile.abrLadder.entries.map((e, i) => (
                                <li key={`abr-entry-${i}`}>
                                    {e.resolution.replace("r_", "")}@{e.bitRate}Mbps
                                </li>
                            ))}
                        </ul>
                    </span>
                ) : (
                    <ul>
                        <li>
                            {profile.abrLadder.entries[0].resolution.replace("r_", "")}@
                            {profile.abrLadder.entries[0].bitRate}Mbps
                        </li>
                    </ul>
                )}
            </div>
        </Wrapper>
    );
}

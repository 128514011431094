import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import CardHESP from "../../components/CardHESP/CardHESP";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TLAManageDeviceContextsForm from "../../components/TLA/TLAManageDeviceContextsForm";

interface Props extends RouteComponentProps {
    organizationId?: string;
    datacenterId?: string;
}

export default function TLADatacenterDeviceContextCreate({ datacenterId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <SectionTitle title={`Create a device context for ${datacenterId}`} align="left" showBottomBorder />
                <CardHESP>
                    <TLAManageDeviceContextsForm
                        datacenterId={datacenterId!}
                        values={{
                            capacity: 0,
                            description: "",
                            labels: [],
                            name: "",
                            privateId: "",
                            serial: "",
                        }}
                    />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

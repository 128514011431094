import { Button, Switch } from "@mui/material";
import * as React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
    border-radius: 10px;
    padding: 15px 20px;
    // border: 1px solid ${(props) => props.theme.lightGrayBottom};
    background: #fafafa;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
`;

const Subtitle = styled.div`
    margin-top: 2px;
    font-size: 14px;
    color: ${(props) => props.theme.gray};
`;

const Content = styled.div`
    margin-top: 10px;
`;

const SaveSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
`;

interface Props {
    title: string;
    subtitle: string;
    showSwitch: boolean;
    enabled?: boolean;
    needsSave: boolean;
    onSaveClick: () => void;
    onEnabledChange?: () => void;
    onDiscardClick: () => void;
    children: React.ReactNode;
    isLoading: boolean;
}

function ContentSettingsWithSwitchCard({
    title,
    subtitle,
    children,
    showSwitch,
    enabled,
    onSaveClick,
    needsSave,
    onEnabledChange,
    onDiscardClick,
    isLoading,
}: Props) {
    return (
        <Wrapper>
            <Header>
                <TitleSection>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </TitleSection>
                <SaveSection>
                    {showSwitch && typeof enabled !== "undefined" && typeof onEnabledChange !== "undefined" && (
                        <Switch checked={enabled} color="primary" onChange={onEnabledChange} />
                    )}
                    {needsSave && (
                        <>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: "10px" }}
                                onClick={onSaveClick}
                                disabled={isLoading}
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </Button>
                            {!isLoading && (
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginLeft: "10px" }}
                                    onClick={onDiscardClick}
                                >
                                    Discard
                                </Button>
                            )}
                        </>
                    )}
                </SaveSection>
            </Header>
            {(enabled || !showSwitch) && <Content>{children}</Content>}
        </Wrapper>
    );
}

export default ContentSettingsWithSwitchCard;

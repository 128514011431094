import * as React from "react";

import { Router, Redirect } from "@reach/router";
import Landing from "./views/Landing";
import Provider from "./views/Provider";
import LogoutPage from "./views/App/Logout";
import Blocked from "./views/Blocked";

const AppPage = React.lazy(() => import("./views/App/AppPage"));

export default function Routes() {
    const redirect = `/login`;
    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <Router>
                <Redirect from="/" to={`${redirect}`} noThrow />
                <AppPage path="app" />
                <AppPage path="app/:organizationId/*" />
                <Landing path="landing" />
                <Landing path="login" />
                {<Landing path="signup" />}
                <Landing path="activation" />
                <Landing path="forgot-password" />
                {<Landing path="registration-success" />}
                <Landing path="accounts-merged" />
                <Landing path="forgot-password-mail-sent" />
                <Landing path="google-denied" />
                <Landing path="access-denied" />
                <Landing path="no-account" />
                <Provider path="provider" />
                <LogoutPage path="logout" />
                <Blocked path="blocked" />
            </Router>
        </React.Suspense>
    );
}

import { FormControl, TextField, Grid, FormControlLabel, Checkbox, useTheme, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import LoadingButtonPublic from "../Loading/LoadingButtonPublic";

import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import Callout, { CalloutType } from "../Callout/Callout";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import {
    RegisterWithEmailMutation,
    RegisterWithEmailMutationVariables,
} from "./__generated__/RegisterWithEmailMutation";

export const REGISTER_WITH_EMAIL_MUTATION = gql`
    mutation RegisterWithEmailMutation($input: RegisterWithEmailInput!) {
        registerWithEmail(input: $input) {
            auth0Id
        }
    }
`;

export const RegistrationOrSection = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.gray};
    text-align: center;
`;

export const UseTHEOportalSection = styled.div`
    color: ${(props) => props.theme.gray};
    text-align: left;
`;

interface Props {
    onSignUpComplete: () => void;
}

interface FormData {
    email: string;
    password: string;
    terms: boolean;
}

function SignUp({ onSignUpComplete }: Props) {
    const theme = useTheme();

    const [error, setError] = React.useState({ showError: false, title: "", description: "", type: "danger" });
    // const [acceptedTerms, setAcceptedTerms] = React.useState<boolean>(false);
    // const [showGoogleError, setShowGoogleError] = React.useState<boolean>(false);

    const { handleSubmit, register, errors } = useForm<FormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    const [registerWithEmailMut, { loading }] = useMutation<
        RegisterWithEmailMutation,
        RegisterWithEmailMutationVariables
    >(REGISTER_WITH_EMAIL_MUTATION);

    const termsLabel = (
        <span style={{ fontSize: "14px" }}>
            I agree to the{" "}
            <a
                href="https://www.theoplayer.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.palette.primary.main }}
            >
                General Terms & Conditions and Privacy Policy
            </a>
            {"."}
        </span>
    );

    const onSubmit = handleSubmit(async ({ email, password }: FormData) => {
        try {
            await registerWithEmailMut({
                variables: {
                    input: {
                        email,
                        password,
                    },
                },
            });
            onSignUpComplete();
        } catch (e: any) {
            handleError(e.graphQLErrors[0].message);
        }
    });

    // function signUpWithGoogle() {
    //     if (acceptedTerms) {
    //         AuthService.signUpWithGoogle();
    //     } else {
    //         setShowGoogleError(true);
    //     }
    // }

    function handleError(errorMessage: string) {
        switch (errorMessage) {
            case "USER_ALREADY_EXISTS":
                setError({
                    showError: true,
                    title: "Already connected",
                    description: "There already exists a user with this email address.",
                    type: "danger",
                });
                break;
            case "USER_HAS_THEOPORTAL_ACCOUNT":
                setError({
                    showError: true,
                    type: "info",
                    title: "Email address used for THEOplayer",
                    description:
                        "This email address has already been used as a THEOplayer account. You can login with the same credentials.",
                });
                break;
            default:
                setError({
                    showError: true,
                    type: "danger",
                    title: "Something went wrong",
                    description: "An unexpected error happened",
                });
        }
    }

    return (
        <form onSubmit={handleSubmit((data, e) => onSubmit(e))}>
            {/* <GoogleButtonHESP isSignUp={true} onClick={signUpWithGoogle} />
            {showGoogleError && !acceptedTerms && (
                <Typography variant="subtitle2" color="error" style={{ marginTop: "10px" }}>
                    You have to agree with our terms and privacy policy
                </Typography>
            )}
            <RegistrationOrSection>Or</RegistrationOrSection> */}
            {error.showError && (
                <Callout type={error.type as CalloutType} title={error.title} description={error.description} />
            )}
            <Grid container spacing={2} direction="column" style={{ marginTop: "40px" }}>
                <Grid item>
                    <FormControl fullWidth>
                        <HESPFormLabel label={"Work email"} />
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="email"
                            placeholder="Please provide your company email address"
                            inputRef={register({
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i,
                                    message: "invalid email address",
                                },
                                // validate: (val: string) => {
                                //     const emailDomain = val.substring(val.lastIndexOf("@") + 1);
                                //     if (BLOCKED_EMAIL_DOMAINS.includes(emailDomain)) {
                                //         return `Email addresses from ${emailDomain} are not allowed.`;
                                //     }
                                //     return true;
                                // },
                            })}
                        />
                        <Typography variant="subtitle2" color="error">
                            {errors.email && errors.email.message}
                        </Typography>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <HESPFormLabel label="Password" />
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="******"
                            name="password"
                            type="password"
                            inputRef={register({
                                required: "Password is required",
                                minLength: {
                                    value: 6,
                                    message: "Password should contain at least 6 characters",
                                },
                            })}
                        />
                        <Typography variant="subtitle2" color="error">
                            {errors.password && errors.password.message}
                        </Typography>
                    </FormControl>
                </Grid>
                <Grid item style={{ margin: "10px 0px" }}>
                    <FormControl>
                        <FormControlLabel
                            label={termsLabel}
                            control={
                                <Checkbox
                                    color="primary"
                                    name="terms"
                                    // onChange={(e) => {
                                    //     setAcceptedTerms(e.target.checked);
                                    //     if (e.target.checked) {
                                    //         setShowGoogleError(false);
                                    //     }
                                    // }}
                                    inputRef={register({
                                        required: "You have to agree with our terms and privacy policy",
                                    })}
                                />
                            }
                        />
                        <Typography variant="subtitle2" color="error">
                            {errors.terms && errors.terms.message}
                        </Typography>
                    </FormControl>
                </Grid>

                <Grid item style={{ paddingBottom: "50px" }}>
                    <LoadingButtonPublic
                        isLoading={loading}
                        defaultText={"Create account"}
                        loadingText={"Creating account..."}
                    />
                </Grid>
            </Grid>
        </form>
    );
}

export default SignUp;

export const BLOCKED_EMAIL_DOMAINS = [
    "wp.pl",
    "gmx.com",
    "mail.ru",
    "citromail.hu",
    "nabajin.com",
    "welljimer.store",
    "inbox.lv",
    "mailst.cjcu.edu.tw",
    "mail1s.edu.vn",
    "windowslive.com",
    "ya.ru",
    "web.de",
    "hotmail.fr",
    "@Naver.com",
    "@icloud.com",
    "@vivaldi.net",
    "@emailnax.com",
    "aol.com",
    "mail.com",
    "protonmail.com",
    "ymail.com",
    "gmail.com",
    "yahoo.com",
    "gmali.com",
    "gamil.com",
    "qq.com",
    "outlook.com",
    "live.com",
    "outlook.be",
    "outlook.it",
    "0-mail.com",
    "027168.com",
    "0815.su",
    "0sg.net",
    "10mail.org",
    "10minutemail.co.za",
    "11mail.com",
    "123.com",
    "123box.net",
    "123india.com",
    "123mail.cl",
    "123mail.org",
    "123qwe.co.uk",
    "126.com",
    "139.com",
    "150mail.com",
    "150ml.com",
    "15meg4free.com",
    "163.com",
    "16mail.com",
    "188.com",
    "189.cn",
    "1ce.us",
    "1chuan.com",
    "1coolplace.com",
    "1freeemail.com",
    "1funplace.com",
    "1internetdrive.com",
    "1mail.ml",
    "1mail.net",
    "1me.net",
    "1mum.com",
    "1musicrow.com",
    "1netdrive.com",
    "1nsyncfan.com",
    "1pad.de",
    "1under.com",
    "1webave.com",
    "1webhighway.com",
    "1zhuan.com",
    "2-mail.com",
    "20email.eu",
    "20mail.in",
    "20mail.it",
    "212.com",
    "21cn.com",
    "24horas.com",
    "2911.net",
    "2980.com",
    "2bmail.co.uk",
    "2d2i.com",
    "2die4.com",
    "2trom.com",
    "3000.it",
    "30minutesmail.com",
    "3126.com",
    "321media.com",
    "33mail.com",
    "37.com",
    "3ammagazine.com",
    "3dmail.com",
    "3email.com",
    "3g.ua",
    "3mail.ga",
    "3xl.net",
    "444.net",
    "4email.com",
    "4email.net",
    "4mg.com",
    "4newyork.com",
    "4warding.net",
    "4warding.org",
    "4x4man.com",
    "email.com",
    "hotmail.com",
    "foxmail.com",
    "ggmail.com",
    "sezam.cz",
];

import { Grid } from "@mui/material";
import DeveloperLinkItem from "../DevelopersZone/DeveloperLinkItem";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";
import { StorageOutlined } from "@mui/icons-material";
import Badge from "../Badge/Badge";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetNumberOfDatacenters } from "./__generated__/GetNumberOfDatacenters";

export const GET_NUMBER_OF_DATACENTERS = gql`
    query GetNumberOfDatacenters {
        numberOfDatacenters
    }
`;

export default function TLAContent() {
    const { data } = useQuery<GetNumberOfDatacenters>(GET_NUMBER_OF_DATACENTERS);

    return (
        <div style={{ marginTop: "22px" }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4}>
                    <DeveloperLinkItem
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                }}
                            >
                                Data centers <Badge type="warning">{data?.numberOfDatacenters}</Badge>
                            </div>
                        }
                        description="Collection of all the THEOlive data centers"
                        onClick={() => navigate(`/app/${THEO_ID}/tla/data-centers`)}
                        icon={<StorageOutlined />}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

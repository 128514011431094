import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SchedulersAnalytics from "../../components/SchedulersOverview/SchedulersAnalytics";
import gql from "graphql-tag";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export const GET_SCHEDULERS_QUERY = gql`
    query GetSchedulersQuery($organizationId: ID!) {
        schedulers(organizationId: $organizationId) {
            schedulerId
            name
            channelIds
            name
            start
            end
            phase
            organizationId
            clientId
            metadata {
                key
                value
            }
            channels {
                channelId
                metadata {
                    name
                }
            }
        }
    }
`;

function Schedulers({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center" alignItems={"center"} alignContent="center">
            <Grid item xs={12}>
                <SchedulersAnalytics organizationId={organizationId!} />
            </Grid>
        </Grid>
    );
}

export default Schedulers;

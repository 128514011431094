import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import React from "react";
import { REQUEST_ORGANIZATION_DRM_ACCESS_MUTATION } from "./Create/ChannelStreamConfigurator";
import {
    RequestOrganizationDrmAccessMutation,
    RequestOrganizationDrmAccessMutationVariables,
} from "./Create/__generated__/RequestOrganizationDrmAccessMutation";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";

interface Props {
    organizationId: string;
    open: boolean;
    onClose: () => void;
}

export default function RequestDrmDialog({ organizationId, onClose, open }: Props) {
    const [requestSent, setRequestSent] = React.useState<boolean>(false);

    const [requestDrmAccessMut, { loading }] = useMutation<
        RequestOrganizationDrmAccessMutation,
        RequestOrganizationDrmAccessMutationVariables
    >(REQUEST_ORGANIZATION_DRM_ACCESS_MUTATION);

    async function onRequestAccess() {
        try {
            await requestDrmAccessMut({
                variables: {
                    input: {
                        organizationId,
                    },
                },
            });
            setRequestSent(true);
        } catch (_e) {
            toast.error("Something went wrong");
        }
    }

    return (
        <DialogHESP
            title={"Activate DRM capabilities"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {!requestSent && (
                                    <>
                                        <p>
                                            To utilize this feature and ensure the protection and control of your
                                            digital content on this channel, it is first necessary to activate the DRM
                                            feature across your account.
                                        </p>
                                        <p>
                                            We kindly request you to reach out to your customer success manager to
                                            initiate the process of enabling DRM in your account. They will guide you
                                            through the activation process, answer any questions you may have, and
                                            provide further assistance in leveraging the full potential of DRM within
                                            your account.
                                        </p>
                                    </>
                                )}
                                {requestSent && (
                                    <p>
                                        Thank you for reaching out to our customer success team. Your customer success
                                        manager will contact you in order to activate the DRM feature in your account.
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {requestSent ? (
                            <Button onClick={onClose} color="secondary">
                                Close
                            </Button>
                        ) : (
                            <DialogFooterWithLoading
                                onActionClick={onRequestAccess}
                                isLoading={loading}
                                onCancelClick={onClose}
                                defaultText={"Contact Customer Success"}
                                loadingText={"Sending..."}
                            />
                        )}
                    </DialogActions>
                </>
            }
        />
    );
}

import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import HESPTableHead, { OrderByType, TableHeadItem } from "./HESPTableHead";

interface Props {
    headers: TableHeadItem[];
    data: object[];
    defaultSortOn: string;
    defaultSortDirection?: "asc" | "desc";
    size?: "small" | "medium";
    enablePagination?: boolean;
    disableSorting?: boolean;
    paginationRowsPerPage?: number;
    paginationRowsPerPageOptions?: number[];
}

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

function HESPTable({
    headers,
    data,
    enablePagination,
    paginationRowsPerPage,
    defaultSortOn,
    disableSorting,
    paginationRowsPerPageOptions,
    defaultSortDirection,
    size,
}: Props) {
    const [order, setOrder] = React.useState<OrderByType>(defaultSortDirection ?? "asc");
    const [orderBy, setOrderBy] = React.useState<string>(defaultSortOn ?? "name");
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(paginationRowsPerPage ?? DEFAULT_ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = React.useState<number>(0);

    useEffect(() => {
        setCurrentPage(0);
    }, [data]);

    function onOrderClick(header: string) {
        const isAsc = orderBy === header && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(header);
    }

    function handleChangeRowsPerPage(event: any) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    }

    function handleChangePage(_: any, newPage: number) {
        setCurrentPage(newPage);
    }

    function descendingComparator(a: any, b: any, orderBy: string) {
        const firstItem =
            typeof b[orderBy] !== "string" && typeof b[orderBy] !== "number"
                ? grabTextFromElement(b[orderBy])
                : b[orderBy];
        const secondItem =
            typeof a[orderBy] !== "string" && typeof b[orderBy] !== "number"
                ? grabTextFromElement(a[orderBy])
                : a[orderBy];

        const isDate = headers.find((h) => h.id === orderBy)?.isDate ?? false;

        if (transformIfDate(firstItem, isDate) < transformIfDate(secondItem, isDate)) {
            return -1;
        }
        if (transformIfDate(firstItem, isDate) > transformIfDate(secondItem, isDate)) {
            return 1;
        }
        return 0;
    }

    function transformIfDate(item: any, isDate: boolean) {
        if (!isDate) {
            return item;
        }
        return moment(item).valueOf();
    }

    function grabTextFromElement(el: any) {
        return el.props.children;
    }

    function getComparator(order: OrderByType, orderBy: string) {
        return order === "desc"
            ? (a: any, b: any) => descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array: any, comparator: any) {
        const stabilizedThis = array.map((el: any, index: number) => [el, index]);

        stabilizedThis.sort((a: any, b: any) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el: any) => el[0]);
    }

    const sortedData = disableSorting === true ? data : stableSort(data, getComparator(order, orderBy));

    return (
        <>
            <TableContainer style={{ background: "white" }}>
                <Table size={size ?? "medium"}>
                    <HESPTableHead headers={headers} order={order} orderBy={orderBy} onOrderClick={onOrderClick} />
                    <TableBody>
                        {sortedData
                            .slice(
                                enablePagination ? currentPage * rowsPerPage : 0,
                                enablePagination ? currentPage * rowsPerPage + rowsPerPage : data.length,
                            )
                            .map((entry: any, i: number) => {
                                return (
                                    <TableRow key={`tr-${i}`}>
                                        {Object.keys(entry)
                                            .filter((_, j) => !headers[j].hide)
                                            .map((cell, j) => {
                                                return (
                                                    <TableCell
                                                        key={`tr-${i}-c-${j}`}
                                                        align={headers[j].align}
                                                        style={{
                                                            maxWidth: `${headers[j].maxWidth ?? 800}px`,
                                                            width: `${headers[j].maxWidth ?? 800}px`,
                                                        }}
                                                    >
                                                        {entry[cell]}
                                                    </TableCell>
                                                );
                                            })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {enablePagination && (
                <TablePagination
                    rowsPerPageOptions={paginationRowsPerPageOptions ?? DEFAULT_ROWS_PER_PAGE_OPTIONS}
                    component="div"
                    count={data.length}
                    style={{ width: "100%" }}
                    align={"right"}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </>
    );
}

export default HESPTable;

import { DialogActions, DialogContent } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { GetWebhooksOrganization_webhooks } from "./__generated__/GetWebhooksOrganization";
import styled from "styled-components";
import { useMutation } from "react-apollo";
import { DeleteWebhookMutation, DeleteWebhookMutationVariables } from "./__generated__/DeleteWebhookMutation";
import { GET_WEBHOOKS_ORGANIZATION } from "./WebhooksOverview";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const DELETE_WEBHOOK_MUTATION = gql`
    mutation DeleteWebhookMutation($input: DeleteWebhookInput!) {
        deleteWebhook(input: $input)
    }
`;

interface Props {
    webhook: GetWebhooksOrganization_webhooks;
    open: boolean;
    onClose: () => void;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

function DeleteWebhookDialog({ open, onClose, webhook }: Props) {
    const [deleteWebhookMut, { loading }] = useMutation<DeleteWebhookMutation, DeleteWebhookMutationVariables>(
        DELETE_WEBHOOK_MUTATION,
    );

    const { webhookId, name, organizationId } = webhook;

    async function deleteWebhook() {
        try {
            await deleteWebhookMut({
                variables: {
                    input: {
                        webhookId,
                        organizationId: webhook.organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_WEBHOOKS_ORGANIZATION,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Webhook got deleted", TOAST_SETTINGS);
        } catch (_ignore) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Delete webhook"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>Are you sure you want to delete the webhook "{name}"?</Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Delete"}
                            loadingText={"Deleting..."}
                            onActionClick={() => deleteWebhook()}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default DeleteWebhookDialog;

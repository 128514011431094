import * as React from "react";
import styled from "styled-components";
import CardHESP from "../CardHESP/CardHESP";
import Registration from "./Registration";
import logoColorNoText from "../../img/theolive-vertical.png";
import SignUpComplete from "./SignUpComplete";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSent from "./ForgotPasswordSent";
import { navigate, useLocation } from "@reach/router";
import { parse } from "query-string";
import Activation from "./Activation";
import AccountVerified from "./AccountVerified";
import GoogleComplete from "./GoogleComplete";
import GoogleDenied from "./GoogleDenied";
import AccountsMerged from "./AccountsMerged";
import NoAccount from "./NoAccount";
import AccessDenied from "./AccessDenied";

const LogoHeader = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    @media (max-width: 600px) {
        margin-bottom: 10px;
    }
`;

export type LandingComponentToShowType =
    | "activation"
    | "login"
    | "google-denied"
    | "access-denied"
    | "signup"
    | "forgot-password"
    | "forgot-password-mail-sent"
    | "registration-success"
    | "no-account"
    | "accounts-merged";

interface Props {
    mode: LandingComponentToShowType;
}

function LandingCard({ mode }: Props) {
    const location = useLocation();
    const searchParams = parse(location.search);

    function getComponentToShow() {
        if (searchParams.ticket) {
            const { success, email, ticket, type } = searchParams;
            if (type === "invite") {
                return <Activation code={ticket! as string} email={email! as string} success={success === "true"} />;
            }
            return <AccountVerified toSignIn={() => navigate(`login`)} />;
        }

        if (searchParams.status && searchParams.status === "google-complete") {
            return <GoogleComplete />;
        }

        switch (mode) {
            case "signup":
                return navigate("https://www.theoplayer.com/contact-us");
            case "login":
                return (
                    <Registration
                        onForgotPasswordClick={() => navigate("forgot-password")}
                        onRegistrationSuccess={() => navigate("registration-success")}
                        selectedTab={mode === "login" ? "login" : "signup"}
                    />
                );
            case "registration-success":
                return <SignUpComplete />;
            case "forgot-password":
                return (
                    <ForgotPassword
                        onBackToLogin={() => navigate("login")}
                        onLinkSent={() => navigate("forgot-password-mail-sent")}
                    />
                );
            case "google-denied":
                return <GoogleDenied />;
            case "access-denied":
                return <AccessDenied />;
            case "accounts-merged":
                return <AccountsMerged />;
            case "forgot-password-mail-sent":
                return <ForgotPasswordSent onBackToLogin={() => navigate("login")} />;
            case "no-account":
                return <NoAccount />;
            default:
                return (
                    <Registration
                        onForgotPasswordClick={() => navigate("forgot-password")}
                        onRegistrationSuccess={() => navigate("registration-success")}
                        selectedTab={"login"}
                    />
                );
        }
    }

    return (
        <CardHESP>
            <LogoHeader>
                <img src={logoColorNoText} height="80" alt="logo" />
            </LogoHeader>
            {getComponentToShow()}
        </CardHESP>
    );
}

export default LandingCard;

import * as React from "react";
import { GetAdminEventsQuery_allEventsForAdmin_events } from "./__generated__/GetAdminEventsQuery";
import { Button, Grid } from "@mui/material";
import RecentErrorsTable from "./RecentErrorsTable";

interface Props {
    events: GetAdminEventsQuery_allEventsForAdmin_events[];
    onLoadMore: () => void;
    hasMore: boolean;
}

export default function RecentErrorsList({ events, hasMore, onLoadMore }: Props) {
    return (
        <Grid container spacing={2}>
            {events.length === 0 && (
                <Grid item xs={12}>
                    No errors available
                </Grid>
            )}
            {events.length > 0 && (
                <>
                    <Grid item xs={12}>
                        <RecentErrorsTable events={events} />
                    </Grid>
                    {hasMore && (
                        <Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button size="small" color="secondary" onClick={onLoadMore}>
                                    Load more
                                </Button>
                            </div>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

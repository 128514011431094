import * as React from "react";
import styled from "styled-components";
import { HelperText } from "../AdminSearchCard/CreateOrganizationForm";

interface Props {
    label: string;
    description?: string | React.ReactNode;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
`;

export const Label = styled.div`
    font-weight: 600;
    font-size: 14px;
    color: black;
`;

function HESPFormLabel({ label, description }: Props) {
    return (
        <Wrapper>
            <Label>{label}</Label>
            {description && <HelperText style={{ marginTop: "2px" }}>{description}</HelperText>}
        </Wrapper>
    );
}

export default HESPFormLabel;

import * as React from "react";
import styled from "styled-components";
import { Button, Grid } from "@mui/material";
import { Save, Undo } from "@mui/icons-material";
import ChannelAdminSettingsSection from "./ChannelAdminSettingsSection";
import { GetChannelAdminPartQuery_channel } from "../Overview/__generated__/GetChannelAdminPartQuery";
import ChannelAdminEnhancedLogging from "./ChannelAdminEnhancedLogging";
import gql from "graphql-tag";
import {
    UpdateChannelAdminSettings,
    UpdateChannelAdminSettingsVariables,
} from "./__generated__/UpdateChannelAdminSettings";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import { GET_CHANNEL_ADMIN_PART, GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import { CdnType } from "../../../__generated__/globalTypes";
import {
    PRICE_PER_DRM_MINUTE_VIEWED,
    PRICE_PER_MINUTE_TRANSCODED,
    PRICE_PER_MINUTE_VIEWED,
} from "../../Contracts/CreateCommitmentContract";
import ChannelAdminPricing from "./ChannelAdminPricing";
import ChannelConvertToTLA from "./ChannelConvertToTLA";
import ChannelAdminEngine from "./ChannelAdminEngine";

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

interface Props {
    channel: GetChannelAdminPartQuery_channel;
    onClose: () => void;
}

interface ChannelAdminSettingsData {
    enhancedLogging: {
        enabled: boolean;
        percentage: number;
    };
    insights: {
        enabled: boolean;
    };
    originSecurity: {
        enabled: boolean;
    };
    pricing: {
        enabled: boolean;
        transcoded: number;
        viewed: number;
        drm: number;
    };
    engine: {
        override: {
            enabled: boolean;
            version: string;
        };
    };
}

export interface AdminCdnInput {
    provider: CdnType;
    url: string;
}

export const UPDATE_CHANNEL_ADMIN_SETTINGS = gql`
    mutation UpdateChannelAdminSettings($input: UpdateChannelAdminSettingsInput!) {
        updateChannelAdminSettings(input: $input) {
            channelId
        }
    }
`;

export default function ChannelAdminSettingsContent({ channel, onClose }: Props) {
    const [admindata, setAdminData] = React.useState<ChannelAdminSettingsData>({
        enhancedLogging: {
            enabled: channel.metadata.enhancedLogging.enabled,
            percentage: channel.metadata.enhancedLogging.percentage,
        },
        insights: {
            enabled: channel.metadata.insights.enabled,
        },
        originSecurity: {
            enabled: channel.publicationConfig?.originSecurity?.enabled !== false,
        },
        pricing: {
            enabled: channel.pricing !== null,
            transcoded: channel.pricing?.transcoded ?? PRICE_PER_MINUTE_TRANSCODED,
            viewed: channel.pricing?.viewed ?? PRICE_PER_MINUTE_VIEWED,
            drm: channel.pricing?.drm ?? PRICE_PER_DRM_MINUTE_VIEWED,
        },
        engine: {
            override: {
                enabled: channel.engine?.override?.enabled === true,
                version: channel.engine?.override?.version ?? "",
            },
        },
    });

    const [updateAdminSettingsMut, { loading }] = useMutation<
        UpdateChannelAdminSettings,
        UpdateChannelAdminSettingsVariables
    >(UPDATE_CHANNEL_ADMIN_SETTINGS);

    async function onSave() {
        try {
            updateAdminSettingsMut({
                variables: {
                    input: {
                        channelId: channel.channelId,
                        organizationId: channel.organizationId,
                        enhancedLogging: admindata.enhancedLogging,
                        insights: admindata.insights,
                        originSecurity: admindata.originSecurity,
                        pricing: {
                            enabled: admindata.pricing.enabled,
                            transcoded: admindata.pricing.transcoded,
                            viewed: admindata.pricing.viewed,
                            drm: admindata.pricing.drm,
                        },
                        engine: {
                            override: {
                                enabled: admindata.engine.override.enabled,
                                version: admindata.engine.override.version,
                            },
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_ADMIN_PART,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            toast.success("Channel admin settings updated", TOAST_SETTINGS);
            onClose();
        } catch (_e) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    return (
        <Grid container spacing={2} style={{ marginBottom: "50px" }}>
            <Grid item xs={12}>
                <TitleWrapper>
                    <Title>Admin settings</Title>
                    <ButtonsWrapper>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={loading}
                            startIcon={<Save />}
                            style={{ marginRight: "10px" }}
                            onClick={onSave}
                        >
                            {loading ? "Saving..." : "Save"}
                        </Button>
                        {!loading && (
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                startIcon={<Undo />}
                                onClick={onClose}
                            >
                                Back
                            </Button>
                        )}
                    </ButtonsWrapper>
                </TitleWrapper>
            </Grid>
            <Grid item xs={12}>
                <ChannelAdminSettingsSection
                    title="Custom pricing"
                    subtitle="Pricing especially for this channel. Note: if customer changes the config, the same pricing will still apply"
                    enabled={admindata.pricing.enabled}
                    onEnabledChange={() =>
                        setAdminData({
                            ...admindata,
                            pricing: {
                                ...admindata.pricing,
                                enabled: !admindata.pricing.enabled,
                            },
                        })
                    }
                >
                    {admindata.pricing.enabled ? (
                        <ChannelAdminPricing
                            transcoded={admindata.pricing.transcoded}
                            viewed={admindata.pricing.viewed}
                            drm={admindata.pricing.drm}
                            onChangeTranscoded={(price: number) =>
                                setAdminData({
                                    ...admindata,
                                    pricing: {
                                        ...admindata.pricing,
                                        transcoded: price,
                                    },
                                })
                            }
                            onChangeDrm={(price: number) =>
                                setAdminData({
                                    ...admindata,
                                    pricing: {
                                        ...admindata.pricing,
                                        drm: price,
                                    },
                                })
                            }
                            onChangeViewed={(price: number) =>
                                setAdminData({
                                    ...admindata,
                                    pricing: {
                                        ...admindata.pricing,
                                        viewed: price,
                                    },
                                })
                            }
                        />
                    ) : (
                        <></>
                    )}
                </ChannelAdminSettingsSection>
            </Grid>
            <Grid item xs={12}>
                <ChannelAdminSettingsSection
                    title="Enhanced logging"
                    subtitle="Define if enhanced logging should be enabled, and for how many % of the requests"
                    enabled={admindata.enhancedLogging.enabled}
                    onEnabledChange={() =>
                        setAdminData({
                            ...admindata,
                            enhancedLogging: {
                                ...admindata.enhancedLogging,
                                enabled: !admindata.enhancedLogging.enabled,
                            },
                        })
                    }
                >
                    {admindata.enhancedLogging.enabled === true ? (
                        <ChannelAdminEnhancedLogging
                            percentage={admindata.enhancedLogging.percentage}
                            onChangePercentage={(percentage: number) =>
                                setAdminData({
                                    ...admindata,
                                    enhancedLogging: { ...admindata.enhancedLogging, percentage },
                                })
                            }
                        />
                    ) : (
                        <></>
                    )}
                </ChannelAdminSettingsSection>
            </Grid>
            <Grid item xs={12}>
                <ChannelAdminSettingsSection
                    title="Insights"
                    subtitle="Define if player should send insights"
                    enabled={admindata.insights.enabled}
                    onEnabledChange={() =>
                        setAdminData({
                            ...admindata,
                            insights: {
                                ...admindata.insights,
                                enabled: !admindata.insights.enabled,
                            },
                        })
                    }
                >
                    <></>
                </ChannelAdminSettingsSection>
            </Grid>
            <Grid item xs={12}>
                <ChannelAdminSettingsSection
                    title="Origin security"
                    subtitle="Define if origin should be secured (restart of channel needed!)"
                    enabled={admindata.originSecurity.enabled}
                    onEnabledChange={() =>
                        setAdminData({
                            ...admindata,
                            originSecurity: {
                                ...admindata.originSecurity,
                                enabled: !admindata.originSecurity.enabled,
                            },
                        })
                    }
                >
                    <></>
                </ChannelAdminSettingsSection>
            </Grid>
            {channel.organization.channelSettings.engine.override?.enabled === true && (
                <Grid item xs={12}>
                    <ChannelAdminSettingsSection
                        title="Override channel engine version"
                        subtitle={`Use a different engine version than globally defined (set to "${
                            channel.organization.channelSettings.engine?.override?.defaultVersion !== ""
                                ? channel.organization.channelSettings.engine?.override?.defaultVersion
                                : "latest"
                        }")`}
                        enabled={admindata.engine.override.enabled}
                        onEnabledChange={() =>
                            setAdminData({
                                ...admindata,
                                engine: {
                                    ...admindata.engine,
                                    override: {
                                        ...admindata.engine.override,
                                        enabled: !admindata.engine.override.enabled,
                                    },
                                },
                            })
                        }
                    >
                        {admindata.engine.override.enabled === true ? (
                            <ChannelAdminEngine
                                version={admindata.engine.override.version ?? ""}
                                onChangeVersion={(version: string) =>
                                    setAdminData({
                                        ...admindata,
                                        engine: {
                                            ...admindata.engine,
                                            override: {
                                                ...admindata.engine.override,
                                                version,
                                            },
                                        },
                                    })
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </ChannelAdminSettingsSection>
                </Grid>
            )}
            {channel.organization.tla?.activated === true && channel.tla?.enabled !== true && (
                <Grid item xs={12}>
                    <ChannelAdminSettingsSection
                        title="Convert to THEOlive Anywhere"
                        subtitle="Convert this channel to a THEOlive Anywhere stream. This action is non-reversible!"
                    >
                        <ChannelConvertToTLA channel={channel} onDone={() => onClose()} />
                    </ChannelAdminSettingsSection>
                </Grid>
            )}
        </Grid>
    );
}

import { IconButton, Tooltip } from "@mui/material";
import { AddAPhoto, DeleteForever } from "@mui/icons-material";
import * as React from "react";
import { ProperyChangeType } from "./customizationHelpers";
import CustomizeItem from "./CustomizeItem";
import UploadImageDialog from "../UploadImageDialog/UploadImageDialog";

interface Props {
    currentLogo: string;
    onCustomizationChange: (type: ProperyChangeType, key: string, value: string) => void;
}

function ChannelCustomizerLogo({ currentLogo, onCustomizationChange }: Props) {
    const [chooseLogo, setChooseLogo] = React.useState<boolean>(false);

    function onRemoveLogo(): void {
        onCustomizationChange("main", "logo", "");
        setChooseLogo(false);
    }

    function setNewImageUrl(url: string): void {
        onCustomizationChange("main", "logo", url);
    }

    return (
        <>
            <CustomizeItem
                title={"Logo"}
                description={"A small image that will show in the top left corner of the player when playing"}
                currentValue={currentLogo === "" ? "No image set" : currentLogo}
                type="text"
                onDoubleClick={() => setChooseLogo(true)}
                customizationActions={
                    <>
                        <Tooltip title="Choose">
                            <IconButton size="small" onClick={() => setChooseLogo(true)}>
                                <AddAPhoto fontSize="small" style={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove">
                            <IconButton size="small" onClick={onRemoveLogo}>
                                <DeleteForever fontSize="small" style={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            <UploadImageDialog
                open={chooseLogo}
                onClose={() => setChooseLogo(false)}
                title={"Upload a new logo"}
                currentUrl={currentLogo}
                onUploadComplete={(url: string) => setNewImageUrl(url)}
            />
        </>
    );
}

export default ChannelCustomizerLogo;

import { Grid, MenuItem, TextField, styled as materialStyled } from "@mui/material";

import * as React from "react";
import { UsageType } from "../../__generated__/globalTypes";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import AdminTopOrganizationTable from "./AdminTopOrganizationTable";
import TopOrganizationSettings from "./TopOrganizationSettings";

export type OrganizationAnalyticsMode = "all" | "promotional" | "production" | "demo";
export type OrganizationAnalyticsRange = "day" | "week" | "month";

export default function AdminTopOrganizations() {
    const [selectedMode, setSelectedMode] = React.useState<OrganizationAnalyticsMode>("production");
    const [selectedUsageType, setSelectedUsageType] = React.useState<UsageType>(UsageType.transcoded);
    const [selectedRange, setSelectedRange] = React.useState<OrganizationAnalyticsRange>("day");

    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "6px 30px 6px 10px",
            fontSize: "13px",
            minWidth: "80px",
            width: "100%",
        },
    };

    const CssTextField = materialStyled(TextField)(smallCss);

    return (
        <CardHESP style={{ height: "100%" }}>
            <CardHESPTitle
                title="Top customers"
                button={
                    <CssTextField
                        select
                        variant="outlined"
                        value={selectedRange}
                        onChange={(e) => setSelectedRange(e.target.value as OrganizationAnalyticsRange)}
                    >
                        <MenuItem value={"day"}>Last 24 hours</MenuItem>
                        <MenuItem value={"week"}>Last 7 days</MenuItem>
                        <MenuItem value={"month"}>Last 30 days</MenuItem>
                    </CssTextField>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TopOrganizationSettings
                        selectedMode={selectedMode}
                        onChangeMode={(mode: OrganizationAnalyticsMode) => setSelectedMode(mode)}
                        selectedType={selectedUsageType}
                        onChangeType={(type: UsageType) => setSelectedUsageType(type)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AdminTopOrganizationTable
                        selectedMode={selectedMode}
                        selectedPeriod={selectedRange}
                        selectedType={selectedUsageType}
                    />
                </Grid>
            </Grid>
        </CardHESP>
    );
}

import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    children: React.ReactNode;
}

const Wrapper = styled.div`
    margin-right: 40px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-weight: bold;
    margin-bottom: 4px;
`;

export default function ProfileItemData({ title, children }: Props) {
    return (
        <Wrapper>
            <Title>{title}</Title>
            <div>{children}</div>
        </Wrapper>
    );
}

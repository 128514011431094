import * as React from "react";
import { Grid } from "@mui/material";
import AnalyticsCard from "./AnalyticsCard";
import { Error, PlayArrow, Settings, Visibility } from "@mui/icons-material";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetAdminAnalytics } from "./__generated__/GetAdminAnalytics";
import { navigate } from "@reach/router";

export const GET_ADMIN_ANALYTICS = gql`
    query GetAdminAnalytics {
        getAdminAnalytics {
            channelsPlaying
            errors
            transcodedMinutesMonth
            viewedMinutesMonth
        }
    }
`;

function AdminAnalytics() {
    const { data, loading } = useQuery<GetAdminAnalytics>(GET_ADMIN_ANALYTICS);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
                <AnalyticsCard
                    amount={data?.getAdminAnalytics.channelsPlaying}
                    isLoading={loading}
                    icon={<PlayArrow fontSize="inherit" color="inherit" />}
                    iconColor={"#58ad6f"}
                    title={"Channels playing"}
                    onClick={() => navigate("active-channels")}
                    backgroundColor="#e4f5e8"
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <AnalyticsCard
                    amount={data?.getAdminAnalytics.errors}
                    isLoading={loading}
                    icon={<Error fontSize="inherit" color="inherit" />}
                    iconColor={"#ff695e"}
                    title={"Errors last 24 hours"}
                    onClick={() => navigate("recent-errors")}
                    backgroundColor="#ffd5d2"
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <AnalyticsCard
                    amount={data?.getAdminAnalytics.transcodedMinutesMonth}
                    isLoading={loading}
                    icon={<Settings fontSize="inherit" color="inherit" />}
                    iconColor={"#2e158a"}
                    title={"Transcoded this month"}
                    onClick={() => navigate("analytics/transcoded")}
                    backgroundColor="#EAE7F3"
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <AnalyticsCard
                    amount={data?.getAdminAnalytics.viewedMinutesMonth}
                    isLoading={loading}
                    icon={<Visibility fontSize="inherit" color="inherit" />}
                    iconColor={"#d166d1"}
                    title={"Viewed this month"}
                    onClick={() => navigate("analytics/viewed")}
                    backgroundColor="#FAEFFA"
                />
            </Grid>
        </Grid>
    );
}

export default AdminAnalytics;

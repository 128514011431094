import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { useQuery } from "react-apollo";
import { GET_CHANNEL_WITH_ALL_ALIASES_QUERY } from "../../components/Channels/Overview/channelQueriesMutations";
import {
    GetChannelWithAllAliasesQuery,
    GetChannelWithAllAliasesQueryVariables,
} from "../../components/Channels/Overview/__generated__/GetChannelWithAllAliasesQuery";
import ChannelUsageOverview from "../../components/ChannelUsage/ChannelUsageOverview";
import CentralPageLoader from "../../components/Loading/CentralPageLoader";

interface Props extends RouteComponentProps {
    channelId?: string;
    organizationId?: string;
}

function ChannelUsage({ channelId, organizationId }: Props) {
    const { data, loading } = useQuery<GetChannelWithAllAliasesQuery, GetChannelWithAllAliasesQueryVariables>(
        GET_CHANNEL_WITH_ALL_ALIASES_QUERY,
        {
            variables: {
                channelId: channelId!,
                organizationId: organizationId!,
            },
        },
    );

    if (loading) {
        return <CentralPageLoader text="Getting data..." />;
    }

    if (!data) {
        return <div>An error occurred</div>;
    }

    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <ChannelUsageOverview channel={data.channel} />
            </Grid>
        </Grid>
    );
}

export default ChannelUsage;

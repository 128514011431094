import gql from "graphql-tag";
import moment from "moment";
import * as React from "react";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { SkeletonRectangle } from "../Loading/Skeletons";
import WebhookHistoryData from "./WebhookHistoryData";
import { GetWebhookLogsQuery, GetWebhookLogsQueryVariables } from "./__generated__/GetWebhookLogsQuery";

interface Props {
    webhookId: string;
    organizationId: string;
}

const NoLogsWrapper = styled.div`
    margin: 10px 20px;
    display: flex;
    justify-content: center;
`;

export const GET_WEBHOOK_LOGS_QUERY = gql`
    query GetWebhookLogsQuery($webhookId: ID!, $organizationId: ID!, $startingAfter: String!, $limit: Int!) {
        webhookLogs(
            webhookId: $webhookId
            organizationId: $organizationId
            startingAfter: $startingAfter
            limit: $limit
        ) {
            logs {
                webhookId
                timestamp
                statusCode
                requestData {
                    created
                    type
                    livemode
                    object {
                        id
                        type
                    }
                }
            }
            pagination {
                hasMore
            }
        }
    }
`;

export const HISTORY_LIMIT = 10;

function WebhookHistory({ webhookId, organizationId }: Props) {
    const [startingAfter] = React.useState<string>(moment().utc().toISOString());
    const { data, loading, fetchMore } = useQuery<GetWebhookLogsQuery, GetWebhookLogsQueryVariables>(
        GET_WEBHOOK_LOGS_QUERY,
        {
            variables: {
                limit: HISTORY_LIMIT,
                webhookId,
                startingAfter,
                organizationId,
            },
        },
    );

    function onLoadMore() {
        const logs = data!.webhookLogs.logs;
        const lastTimestamp = logs[logs.length - 1].timestamp;
        fetchMore({
            variables: {
                limit: HISTORY_LIMIT,
                webhookId,
                startingAfter: lastTimestamp,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                const res: GetWebhookLogsQuery = {
                    webhookLogs: {
                        __typename: "WebhookLogsResult",
                        logs: [...previousQueryResult.webhookLogs.logs, ...fetchMoreResult.webhookLogs.logs],
                        pagination: fetchMoreResult.webhookLogs.pagination,
                    },
                };

                return res;
            },
        });
    }

    return (
        <CardHESP>
            <CardHESPTitle title="History" />
            {loading && (
                <>
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                </>
            )}
            {!loading && !data && <div>An error occurred</div>}
            {!loading && data && data.webhookLogs.logs.length === 0 && <NoLogsWrapper>No logs yet</NoLogsWrapper>}
            {!loading && data && data.webhookLogs.logs.length > 0 && (
                <WebhookHistoryData
                    logs={data.webhookLogs.logs}
                    onLoadMoreLogs={onLoadMore}
                    hasMore={data.webhookLogs.pagination.hasMore}
                />
            )}
        </CardHESP>
    );
}

export default WebhookHistory;

import * as React from "react";
import styled from "styled-components";
import { Popover } from "@mui/material";
import { Color, ColorResult, RGBColor, SketchPicker } from "react-color";

interface Props {
    currentColor: string;
    onColorChange: (color: string) => void;
}

const ColorShowWrapper = styled.div`
    cursor: pointer;
    height: 28px;
    width: 40px;
    border: 1px solid #a3a3a3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CurrentColor = styled.div.attrs((props: { color: string }) => ({
    color: props.color,
}))`
    height: 16px;
    width: 30px;
    background-color: ${(props) => props.color}; ;
`;

function ColorPicker({ currentColor, onColorChange }: Props) {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    function handleChangeColor(color: ColorResult) {
        onColorChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }

    const colorToShow: Color = currentColor.includes("rgb")
        ? {
              r: getRgbValues(currentColor).r,
              g: getRgbValues(currentColor).g,
              b: getRgbValues(currentColor).b,
              a: getRgbValues(currentColor).a,
          }
        : currentColor;

    return (
        <>
            <ColorShowWrapper aria-describedby={id} onClick={handleClick}>
                <CurrentColor color={currentColor} />
            </ColorShowWrapper>
            <Popover
                id={id}
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                style={{ marginTop: "10px" }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <SketchPicker color={colorToShow} onChange={handleChangeColor} onChangeComplete={handleChangeColor} />
            </Popover>
        </>
    );
}

function getRgbValues(rgb: string): RGBColor {
    const valuesPart = rgb.substring(rgb.indexOf("(") + 1, rgb.indexOf(")"));
    const splitted = valuesPart.split(",");

    return {
        r: parseInt(splitted[0]),
        g: parseInt(splitted[1]),
        b: parseInt(splitted[2]),
        a: parseFloat(splitted[3]),
    };
}

export default ColorPicker;

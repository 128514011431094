import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import CardHESP from "../../CardHESP/CardHESP";
import CardHESPTitle from "../../CardHESP/CardHESPTitle";
import { SkeletonRectangle } from "../../Loading/Skeletons";
import ProfileItem from "./ProfileItem";
import { GetProfiles, GetProfilesVariables } from "./__generated__/GetProfiles";

export const GET_PROFILES = gql`
    query GetProfiles($organizationId: ID!) {
        profiles(organizationId: $organizationId) {
            id
            profileId
            maxBitrate
            maxResolution
            maxFps
            deprecated
            publiclyAvailable
            transcodedPricePerMinute
            viewedPricePerMinute
            abrLadder {
                entries {
                    resolution
                    bitRate
                }
            }
        }
    }
`;

interface Props {
    organizationId: string;
}

const Content = styled.div`
    margin-top: 10px;
`;

export default function ProfilesCard({ organizationId }: Props) {
    const { data, loading } = useQuery<GetProfiles, GetProfilesVariables>(GET_PROFILES, {
        variables: {
            organizationId,
        },
    });

    return (
        <CardHESP>
            <CardHESPTitle
                title="List of profiles"
                subtitle="All default profiles that can be used by any customer"
                button={
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={() => navigate(`/app/${organizationId}/config/profiles/create`)}
                    >
                        Add
                    </Button>
                }
            />
            <Content>
                {!data || loading ? (
                    <>
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                    </>
                ) : (
                    data.profiles.map((p, i) => (
                        <ProfileItem key={`profile-${i}`} profile={p} organizationId={organizationId} />
                    ))
                )}
            </Content>
            <ToastContainer />
        </CardHESP>
    );
}

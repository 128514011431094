import { Grid } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import KeyReason from "./KeyReason";

const ReasonsContainer = styled.div`
    margin-top: 170px;
    margin-left: 20px;
    @media (max-width: 600px) {
        margin-top: 30px;
        margin-bottom: 50px;
    }
`;

function KeyReasons() {
    return (
        <ReasonsContainer>
            <Grid container spacing={3}>
                {keyReasons.map((keyReason, i) => (
                    <Grid item xs={12} key={i}>
                        <KeyReason title={keyReason.title} description={keyReason.description} />
                    </Grid>
                ))}
            </Grid>
        </ReasonsContainer>
    );
}

const keyReasons: { title: string; description: string }[] = [
    {
        title: "Real-time streaming",
        description: "Stream real-time to your viewers and create interactive experiences.",
    },
    {
        title: "API-first approach",
        description: "Get started in minutes via APIs or the management console.",
    },
    {
        title: "Highly scalable",
        description: "Stream to a virtually unlimited number of viewers.",
    },
];

export default KeyReasons;

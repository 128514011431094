import * as React from "react";
import { GetRunsQuery_runs_runs } from "./__generated__/GetRunsQuery";
import { Button, Grid } from "@mui/material";
import ChannelRunsTable from "./ChannelRunsTable";

interface Props {
    runs: GetRunsQuery_runs_runs[];
    hasMore: boolean;
    onMoreClick: () => void;
}

export default function ChannelRunsList({ hasMore, onMoreClick, runs }: Props) {
    return (
        <Grid container spacing={2}>
            {runs.length === 0 && (
                <Grid item xs={12}>
                    No runs available
                </Grid>
            )}
            {runs.length > 0 && (
                <>
                    <Grid item xs={12}>
                        <ChannelRunsTable runs={runs} />
                    </Grid>
                    {hasMore && (
                        <Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button size="small" color="secondary" onClick={onMoreClick}>
                                    Load more
                                </Button>
                            </div>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

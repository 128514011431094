import { FormGroup, TextField } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { HelperText } from "../AdminSearchCard/CreateOrganizationForm";

interface Props {
    url: string;
    onChangeUrl: (url: string) => void;
}

export default function OrganizationChannelDefaultDomain({ onChangeUrl, url }: Props): JSX.Element {
    const chars = [
        "a",
        "b",
        "c",
        "d",
        "e,",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
    ];

    function endWithChar() {
        if (url.length > 0) {
            const last = url.charAt(url.length - 1);
            return chars.includes(last.toLowerCase());
        }
        return true;
    }

    return (
        <FormGroup style={{ minWidth: "300px" }}>
            <HESPFormLabel label="Default domain" description="Leave empty if default should be used" />
            <TextField
                fullWidth
                type="text"
                size="small"
                placeholder="Default domain to use"
                onChange={(e) => onChangeUrl(e.target.value)}
                variant="outlined"
                key="default-domain"
                value={url}
            />
            {!endWithChar() && <HelperText color="error">Should end with a letter</HelperText>}
        </FormGroup>
    );
}

import * as React from "react";
import { useQuery } from "react-apollo";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { HESPlink } from "../Landing/SignUpComplete";
import HESPTable from "../HESPTable/HESPTable";
import styled from "styled-components";
import gql from "graphql-tag";
import { GetOrganizations, GetOrganizations_organizations } from "./__generated__/GetOrganizations";
import ModeBadge from "../ModeBadge/ModeBadge";
import { DATE_FORMAT } from "../UsageSummary/UsageSummary";
import moment from "moment";
import OrganizationOverviewSkeleton from "./OrganizationOverviewSkeleton";
import { ContractType } from "../../__generated__/globalTypes";

const NoChannelsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: ${(props) => props.theme.gray};
`;

const ChannelModeCell = styled.div`
    display: flex;
    width: 100%;
`;

export const GET_ORGANIZATIONS_QUERY = gql`
    query GetOrganizations {
        organizations {
            name
            createdAt
            organizationStatus
            organizationId
            primaryHubspotContactId
            contractType
        }
    }
`;

interface Props {
    searchValue: string;
    filterContractTypes: ContractType | "all";
}

function OrganizationsOverview({ searchValue, filterContractTypes }: Props) {
    const { data, loading } = useQuery<GetOrganizations>(GET_ORGANIZATIONS_QUERY);

    if (loading) {
        return <OrganizationOverviewSkeleton />;
    }

    if (!data) {
        return <NoChannelsWrapper>No organizations found.</NoChannelsWrapper>;
    }

    const organizations = data.organizations;

    const filterOnContractType = (
        organizations: GetOrganizations_organizations[],
        contractType: ContractType | "all",
    ) => {
        switch (contractType) {
            case ContractType.payg:
                return organizations.filter((organization) => organization.contractType === ContractType.payg);
            case ContractType.commitment:
                return organizations.filter((organization) => organization.contractType === ContractType.commitment);
            default:
                return organizations;
        }
    };

    const filterOrganizations = (searchValue: string, filterContractTypes: ContractType | "all") => {
        const filteredList = filterOnContractType(organizations, filterContractTypes);

        const result = searchValue
            ? filteredList.filter((organization) => organization.name.toLowerCase().includes(searchValue.toLowerCase()))
            : filteredList;
        return result;
    };

    const filteredOrganizations = filterOrganizations(searchValue, filterContractTypes);

    const headers: TableHeadItem[] = [
        {
            id: "name",
            label: "Name",
            allowSort: true,
        },
        {
            id: "date",
            label: "Sign Up Date",
            allowSort: true,
            isDate: true,
        },
        {
            id: "mode",
            label: "Mode",
        },
        {
            id: "hubspot",
            label: "Hubspot",
            align: "center",
        },
        {
            id: "manage",
            label: "Manage",
            align: "center",
        },
        {
            id: "account",
            label: "Account",
            align: "center",
        },
    ];

    const records = filteredOrganizations.map((organization: GetOrganizations_organizations) => ({
        name: organization.name,
        date: moment(organization.createdAt).format(DATE_FORMAT),
        mode: (
            <ChannelModeCell>
                <ModeBadge mode={organization.organizationStatus} />
            </ChannelModeCell>
        ),
        hubspot: organization.primaryHubspotContactId ? (
            <HESPlink
                link={`https://app.hubspot.com/contacts/2163521/contact/${organization.primaryHubspotContactId}`}
                openInNewTab
            >
                View
            </HESPlink>
        ) : (
            ""
        ),
        manage: <HESPlink link={`/app/${organization.organizationId}/manage-organization`}>Manage</HESPlink>,
        account: <HESPlink link={`/app/${organization.organizationId}`}>View</HESPlink>,
    }));

    return (
        <HESPTable
            headers={headers}
            data={records}
            defaultSortOn={"date"}
            defaultSortDirection={"desc"}
            size="small"
            enablePagination
            paginationRowsPerPage={10}
            paginationRowsPerPageOptions={[5, 10, 15]}
        />
    );
}

export default OrganizationsOverview;

import { Campaign } from "@mui/icons-material";
import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import CardHESP from "../CardHESP/CardHESP";
import ChangelogOverview from "./ChangelogOverview";

const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: ${(props) => props.theme.secondary};
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const TitleIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Icon = styled.div`
    border-radius: 8px;
    background-color: ${(props) => props.theme.lighterBlue};
    height: 40px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

export default function Changelog() {
    return (
        <CardHESP>
            <TitleWrapper>
                <TitleIconWrapper>
                    <Icon>
                        <Campaign color="secondary" />
                    </Icon>
                    <Title>Changelog</Title>
                </TitleIconWrapper>
                <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    style={{ fontSize: "13px", padding: "10px 16px", height: "28px" }}
                    onClick={() => window.open("https://developers.theo.live/changelog")}
                >
                    See all
                </Button>
            </TitleWrapper>
            <ChangelogOverview />
        </CardHESP>
    );
}

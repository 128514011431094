import { Switch } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    enabled: boolean;
    title: string;
    description: string;
    onClick: () => void;
}

const Wrapper = styled.div`
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
    display: flex;
    padding: 10px;
    border-radius: 8px;
    flex-direction: row;
    max-width: 750px;
    height: 100%;
    align-items: center;
`;

const SwitchPart = styled.div`
    margin-right: 20px;
`;

const InfoPart = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const Subtitle = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 14px;
`;

export default function ChannelSwitchConfigurator({ title, description, enabled, onClick }: Props) {
    return (
        <Wrapper>
            <SwitchPart>
                <Switch color="primary" checked={enabled} onChange={onClick} />
            </SwitchPart>
            <InfoPart>
                <Title>{title}</Title>
                <Subtitle>{description}</Subtitle>
            </InfoPart>
        </Wrapper>
    );
}

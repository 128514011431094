import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import DataItem from "../DataItem/DataItem";
import { Grid } from "@mui/material";
import { DATE_FORMAT } from "../UsageSummary/UsageSummary";
import moment from "moment";
import ModeBadge from "../ModeBadge/ModeBadge";
import { ChannelModeType, OrganizationStatusType } from "../../__generated__/globalTypes";
import ChangeOrganizationStatusDialog from "./ChangeOrganizationStatusDialog";
import ChangeOrganizationNameDialog from "./ChangeOrganizationNameDialog";
import ChangeOrganizationTypeDialog from "./ChangeOrganizationTypeDialog";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";

interface Props {
    organization: GetFullOrganizationDetails_organization;
}

function BasicOrganizationData({ organization }: Props) {
    const [openChangeStatusDialog, setOpenChangeStatusDialog] = React.useState<boolean>(false);
    const [openChangeNameDialog, setOpenChangeNameDialog] = React.useState<boolean>(false);
    const [openChangeTypeDialog, setOpenChangeTypeDialog] = React.useState<boolean>(false);

    const { organizationId, organizationStatus, createdAt, name } = organization;
    return (
        <>
            <CardHESP>
                <CardHESPTitle title="Basic information" />
                <Grid container>
                    <Grid item xs={12}>
                        <DataItem title="ID" value={organizationId} />
                        <DataItem
                            title="Name"
                            editable
                            onEditClick={() => setOpenChangeNameDialog(true)}
                            value={name}
                        />
                        <DataItem title="Joined on" value={moment(createdAt).format(DATE_FORMAT)} />
                        <DataItem
                            title="Status"
                            editable
                            onEditClick={() => setOpenChangeStatusDialog(true)}
                            value={<ModeBadge mode={organizationStatus as ChannelModeType} />}
                        />
                        <DataItem
                            title="Type"
                            editable
                            onEditClick={() => setOpenChangeTypeDialog(true)}
                            value={<ModeBadge mode={organization.isIndividual ? "individual" : "company"} />}
                        />
                    </Grid>
                </Grid>
            </CardHESP>
            <ChangeOrganizationStatusDialog
                organizationId={organizationId}
                open={openChangeStatusDialog}
                currentStatus={organizationStatus as OrganizationStatusType}
                onClose={() => setOpenChangeStatusDialog(false)}
            />
            <ChangeOrganizationNameDialog
                organizationId={organizationId}
                open={openChangeNameDialog}
                onClose={() => setOpenChangeNameDialog(false)}
                currentName={name}
            />
            <ChangeOrganizationTypeDialog
                organizationId={organizationId}
                isIndividual={organization.isIndividual}
                open={openChangeTypeDialog}
                onClose={() => setOpenChangeTypeDialog(false)}
            />
        </>
    );
}

export default BasicOrganizationData;

import { FormControl, TextField, Grid, Button, Typography, FormControlLabel, Checkbox } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";

import Callout, { CalloutType } from "../Callout/Callout";
import gql from "graphql-tag";
import Loading from "../Loading/Loading";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useMutation } from "@apollo/react-hooks";
import { ActivateAccountMutation, ActivateAccountMutationVariables } from "./__generated__/ActivateAccountMutation";
import { navigate } from "@reach/router";
import styled from "styled-components";

export const ACTIVATE_ACCOUNT_MUTATION = gql`
    mutation ActivateAccountMutation($input: ActivateAccountInput!) {
        activateAccount(input: $input) {
            auth0Id
        }
    }
`;

interface Props {
    code: string;
    email: string;
    success: boolean;
}

interface FormData {
    password: string;
    terms: boolean;
}

const Link = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.secondary};
`;

function Activation({ code, email, success }: Props) {
    const [error, setError] = React.useState({
        showError: false,
        title: "",
        description: "",
        type: "danger",
    });

    const termsLabel = (
        <span>
            By activating this account you confirm that you have read and also accept{" "}
            <Link
                href="https://www.theoplayer.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                // className={classes.link}
            >
                the latest commercial and legal Terms and the Privacy Policy of THEO Technologies NV
            </Link>
        </span>
    );

    const { handleSubmit, register, errors } = useForm<FormData>();
    const [activateAccountMut, { loading }] = useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(
        ACTIVATE_ACCOUNT_MUTATION,
    );

    const onSubmit = handleSubmit(async ({ password }: FormData) => {
        try {
            await activateAccountMut({
                variables: {
                    input: {
                        code,
                        email,
                        password,
                    },
                },
            });
            navigate(`/landing`);
        } catch (e: any) {
            handleError(e.graphQLErrors[0].message);
        }
    });

    function handleError(errorMessage: string) {
        switch (errorMessage) {
            case "USER_ACTIVATION_FAILED":
                setError({
                    showError: true,
                    title: "Activation failed",
                    description: "The activation code doesn't exist or is already used",
                    type: "danger",
                });
                break;

            default:
                setError({
                    showError: true,
                    type: "danger",
                    title: "Something went wrong",
                    description: "An unexpected error happened",
                });
        }
    }

    return (
        <form onSubmit={handleSubmit((data, e) => onSubmit(e))}>
            <SectionTitle title="Activate your account" align="center" />
            <p style={{ marginTop: "50px", marginBottom: "20px" }}>
                Welcome to THEOlive! To complete the process, please choose a password.
            </p>
            {error.showError && (
                <Callout type={error.type as CalloutType} title={error.title} description={error.description} />
            )}

            <Grid container spacing={2} direction="column" style={{ marginTop: "20px" }}>
                <Grid item>
                    <FormControl fullWidth>
                        <HESPFormLabel label={"Choose a password"} />
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="password"
                            name="password"
                            inputRef={register({
                                required: "Password is required",
                                minLength: {
                                    value: 6,
                                    message: "Password should contain at least 6 characters",
                                },
                            })}
                        />
                        <Typography variant="subtitle2" color="error">
                            {errors.password?.message}
                        </Typography>
                    </FormControl>
                </Grid>
                <Grid item style={{ margin: "10px 0px" }}>
                    <FormControl>
                        <FormControlLabel
                            label={termsLabel}
                            control={
                                <Checkbox
                                    color="primary"
                                    name="terms"
                                    inputRef={register({
                                        required: "You have to agree with our terms and privacy policy",
                                    })}
                                />
                            }
                        />
                        <Typography variant="subtitle2" color="error">
                            {errors.terms && errors.terms.message}
                        </Typography>
                    </FormControl>
                </Grid>

                <Grid item style={{ paddingBottom: "50px" }}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            style={{ marginTop: "15px", padding: "10px 0px" }}
                            type="submit"
                        >
                            Complete activation
                        </Button>
                    )}
                </Grid>
            </Grid>
        </form>
    );
}

export default Activation;

import { FormGroup, Grid, Hidden, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { IngestProtocol } from "../../../__generated__/globalTypes";
import { CopyAll } from "@mui/icons-material";
import { copyToClipboard } from "../../CopyLabel/CopyLabel";

interface Props {
    disabled: boolean;
    ingestProtocol: IngestProtocol;
    ingestUrl: string;
    onChangeIngestUrl: (url: string) => void;
    audioPid: string;
    onChangeAudioPid: (pid: string) => void;
}

const Input = styled.input.attrs((props: { disabled: boolean }) => ({
    disabled: props.disabled,
}))`
    font-size: 14px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 6px;
    padding: 6px 10px;
    outline: rgba(0, 0, 0, 0.6);
    color: #696969;
    width: 100%;
    cursor: ${(props) => (props.disabled === true ? "not-allowed" : "text")};
`;

const Label = styled.label`
    font-size: 13px;
    font-weight: bolder;
    width: 80px;
`;

const Helper = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

function ChannelIngestSettingsPull({
    audioPid,
    ingestProtocol,
    ingestUrl,
    onChangeAudioPid,
    onChangeIngestUrl,
    disabled,
}: Props) {
    function isValidUrl(): boolean {
        if (ingestProtocol === IngestProtocol.rtmp) {
            return ingestUrl.startsWith("rtmp://") || ingestUrl.startsWith("rtmps://");
        }
        if (ingestProtocol === IngestProtocol.srt) {
            return ingestUrl.startsWith("srt://");
        }
        return true;
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <FormGroup>
                        <Label>URL:</Label>
                        <div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "10px" }}>
                            <Input
                                type="text"
                                value={ingestUrl}
                                onChange={(e) => onChangeIngestUrl(e.target.value)}
                                disabled={disabled}
                            />

                            <Tooltip title="Copy">
                                <IconButton size="small" onClick={() => copyToClipboard(ingestUrl)}>
                                    <CopyAll fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                        {!isValidUrl() && (
                            <Helper>
                                Not a valid {ingestProtocol === IngestProtocol.rtmp ? "RTMP(s)" : "SRT"} url. Should
                                start with {ingestProtocol === IngestProtocol.rtmp ? "rtmp:// or rtmps://" : "srt://"}
                            </Helper>
                        )}
                    </FormGroup>
                </Grid>
                {ingestProtocol === IngestProtocol.srt && (
                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                        <FormGroup>
                            <Label>Audio PID:</Label>
                            <div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "10px" }}>
                                <Input
                                    type="text"
                                    value={audioPid}
                                    onChange={(e) => onChangeAudioPid(e.target.value)}
                                    disabled={disabled}
                                />

                                <Tooltip title="Copy">
                                    <IconButton size="small" onClick={() => copyToClipboard(audioPid)}>
                                        <CopyAll fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </FormGroup>
                    </Grid>
                )}
                <Hidden lgDown>
                    <Grid item xs={12}>
                        <div style={{ height: "70px" }}></div>
                    </Grid>
                </Hidden>
            </Grid>
        </>
    );
}

export default ChannelIngestSettingsPull;

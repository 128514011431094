import * as React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

interface Props {
    title: string;
    description: string;
}

const Reason = styled.div`
    display: flex;
    width: 100%;
`;

const IconDiv = styled.div`
    margin-right: 30px;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
`;

const Description = styled.div`
    margin-top: 10px;
    max-width: 350px;
    font-size: 16px;
`;

function KeyReason({ title, description }: Props) {
    const theme = useTheme();

    return (
        <Reason>
            <IconDiv>
                <CheckCircle style={{ color: theme.palette.primary.main }} />
            </IconDiv>

            <div>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </div>
        </Reason>
    );
}

export default KeyReason;

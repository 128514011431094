import { Edit } from "@mui/icons-material";
import { Button, Chip, Grid } from "@mui/material";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { STYLED_THEME } from "../../AppWrapper";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import DataItem from "../DataItem/DataItem";
import { SkeletonRectangle } from "../Loading/Skeletons";
import SectionTitle from "../SectionTitle/SectionTitle";
import WebhookEventsListenedOn from "./WebhookEventsListenedOn";
import WebhookSecretCell from "./WebhookSecretCell";
import { GetWebhookQuery, GetWebhookQueryVariables } from "./__generated__/GetWebhookQuery";
import AuthService from "../../auth/AuthService";

export const GET_WEBHOOK_QUERY = gql`
    query GetWebhookQuery($webhookId: ID!, $organizationId: ID!) {
        webhook(webhookId: $webhookId, organizationId: $organizationId) {
            webhookId
            name
            description
            url
            active
            events
            organizationId
            validEvents
            isAdmin
        }
    }
`;

interface Props {
    webhookId: string;
    organizationId: string;
}

function WebhookDetails({ webhookId, organizationId }: Props) {
    const { data, loading } = useQuery<GetWebhookQuery, GetWebhookQueryVariables>(GET_WEBHOOK_QUERY, {
        variables: {
            webhookId,
            organizationId,
        },
    });

    const isAdmin = AuthService.isTHEOAdmin();

    return (
        <>
            <SectionTitle title={`Webhook ${data?.webhook?.name}`} showBottomBorder align="left" />
            <CardHESP>
                <CardHESPTitle
                    title="Details"
                    subtitle={isAdmin ? (data?.webhook?.isAdmin === true ? "⭐ Admin webhook" : undefined) : undefined}
                    button={
                        data?.webhook?.organizationId ? (
                            <Button
                                size="small"
                                variant="contained"
                                startIcon={<Edit />}
                                color="primary"
                                onClick={() =>
                                    navigate(
                                        `/app/${data.webhook.organizationId}/webhooks/${data.webhook.webhookId}/edit`,
                                    )
                                }
                            >
                                Edit
                            </Button>
                        ) : null
                    }
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <DataItem
                            title="Name"
                            value={loading || !data ? <SkeletonRectangle width="50%" /> : data.webhook.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DataItem
                            title="URL"
                            value={loading || !data ? <SkeletonRectangle width="50%" /> : data.webhook.url}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        <DataItem
                            title="Status"
                            value={
                                loading || !data ? (
                                    <SkeletonRectangle width="50%" />
                                ) : (
                                    <Chip
                                        label={data.webhook.active ? "Enabled" : "Disabled"}
                                        style={{
                                            backgroundColor: data.webhook.active
                                                ? STYLED_THEME.successLight
                                                : STYLED_THEME.errorLight,
                                        }}
                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DataItem
                            title="Secret"
                            value={
                                loading || !data ? (
                                    <SkeletonRectangle width="50%" />
                                ) : (
                                    <WebhookSecretCell
                                        webhookId={webhookId}
                                        organizationId={data.webhook.organizationId}
                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataItem
                            title="Description"
                            value={loading || !data ? <SkeletonRectangle width="50%" /> : data.webhook.description}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DataItem
                            title="Events listened on"
                            value={
                                loading || !data ? (
                                    <SkeletonRectangle width="50%" />
                                ) : (
                                    <WebhookEventsListenedOn
                                        allEvents={data.webhook.validEvents}
                                        selectedEvents={data.webhook.events}
                                    />
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </CardHESP>
        </>
    );
}

export default WebhookDetails;

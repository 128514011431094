import { TableHead, TableRow, TableCell, TableSortLabel, Breakpoint } from "@mui/material";

export interface TableHeadItem {
    id: string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    label: string;
    allowSort?: boolean;
    hide?: boolean;
    isDate?: boolean;
    hiddenFrom?: Breakpoint;
    maxWidth?: number;
}

export type OrderByType = "asc" | "desc";

interface Props {
    headers: TableHeadItem[];
    orderBy: string;
    order: OrderByType;
    onOrderClick: (head: string) => void;
}

function HESPTableHead({ headers, orderBy, order, onOrderClick }: Props) {
    return (
        <TableHead>
            <TableRow>
                {headers
                    .filter((h) => !h.hide)
                    .map((head) => (
                        <TableCell
                            key={head.id}
                            align={head.align ? head.align : "left"}
                            sortDirection={orderBy === head.id ? order : false}
                            style={{ maxWidth: `${head.maxWidth ?? 800}px`, width: `${head.maxWidth ?? 800}px` }}
                        >
                            {head.allowSort ? (
                                <TableSortLabel
                                    active={orderBy === head.id}
                                    direction={orderBy === head.id ? order : "asc"}
                                    onClick={() => onOrderClick(head.id)}
                                >
                                    {head.label}
                                </TableSortLabel>
                            ) : (
                                <>{head.label}</>
                            )}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

export default HESPTableHead;

import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetChangelogQuery } from "./__generated__/GetChangelogQuery";
import styled from "styled-components";
import ChangelogItem from "./ChangelogItem";
import { SkeletonRectangle } from "../Loading/Skeletons";

const Wrapper = styled.div`
    margin-top: 10px;
    width: 100%;
`;

export const GET_CHANGELOG_QUERY = gql`
    query GetChangelogQuery {
        changelog {
            metadata {
                title
                description
            }
            createdAt
            slug
            type
        }
    }
`;

export default function ChangelogOverview() {
    const { data, loading } = useQuery<GetChangelogQuery>(GET_CHANGELOG_QUERY);

    if (loading || !data) {
        return (
            <>
                <SkeletonRectangle width="80%" />
                <SkeletonRectangle width="80%" />
                <SkeletonRectangle width="80%" />
            </>
        );
    }

    return (
        <Wrapper>
            {data.changelog.map((c) => (
                <ChangelogItem item={c} />
            ))}
        </Wrapper>
    );
}

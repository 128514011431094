import * as React from "react";
import CodeSample from "../../CodeSample/CodeSample";
import { HESPlink } from "../../Landing/SignUpComplete";
import styled from "styled-components";

interface Props {
    channelId: string;
}

const P = styled.p`
    font-size: 15px;
`;

const Wrapper = styled.div`
    margin: 15px 10px;
`;

export const Code = styled.code`
    color: #de5b5b;
    padding: 2px;
    background-color: ${(props) => props.theme.grayBackground};
`;

const EMBED_SCRIPT_URL = process.env.REACT_APP_EMBED_SCRIPT_URL!;

function ChannelPlayoutIncludeDocumentation({ channelId }: Props) {
    const embedScriptCode = `<script src="${EMBED_SCRIPT_URL}"></script>`;

    const embedDivCode = `<div data-theo-live-id="${channelId}" style="width: 600px; height: 400px"></div>`;

    return (
        <Wrapper>
            <P>
                Showing your channel with your stream on a page is very straightforward! First of all, include a{" "}
                <Code>{"<div>"}</Code> somewhere on your page, and give it a <Code>data-theo-live-id</Code> attribute.
                Pass your channel ID as a value to this attribute, and apply some styling to determine the dimensions of
                your player:
            </P>
            <CodeSample language="html" code={embedDivCode} />
            <P>
                Next, include our embed script{" "}
                <strong>
                    after the closing <Code>{"</body>"}</Code> tag
                </strong>{" "}
                in your HTML page:
            </P>
            <CodeSample language="html" code={embedScriptCode} />
            <P>
                That's it, time to share it with your viewers! Looking for an example? Then check our{" "}
                <HESPlink
                    link="https://developers.theo.live/docs/including-a-theolive-player-on-your-page"
                    openInNewTab
                >
                    documentation
                </HESPlink>
                .
            </P>
        </Wrapper>
    );
}

export default ChannelPlayoutIncludeDocumentation;

import * as React from "react";
import styled from "styled-components";
import { ResolutionType } from "../../../__generated__/globalTypes";
import ChannelStreamConfiguratorPartItem from "./ChannelStreamConfiguratorPartItem";

export type ChannelIngestConfiguratorPartType = "bitrate" | "resolution" | "fps";

interface Props {
    type: ChannelIngestConfiguratorPartType;
    title: string;
    values: string[] | number[] | ResolutionType[];
    selectableValues: string[] | number[] | ResolutionType[];
    selectedValue: string | number | ResolutionType;
    unit: string;
    onClick: (value: string | number | ResolutionType) => void;
}

const Wrapper = styled.div`
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
    // border: 1px solid ${(props) => props.theme.lightBlue};
    // background-color: ${(props) => props.theme.grayBackground};
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Title = styled.span`
    background-color: ${(props) => props.theme.secondary};
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 6px 30px;
    border-radius: 8px;
    margin-top: -14px;
    font-size: 14px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 18px;
`;

export default function ChannelStreamConfiguratorPart({
    type,
    title,
    values,
    unit,
    onClick,
    selectedValue,
    selectableValues,
}: Props) {
    return (
        <Wrapper>
            <TitleWrapper>
                <Title>{title}</Title>
            </TitleWrapper>
            <Content>
                {values.map((value, i) => (
                    <ChannelStreamConfiguratorPartItem
                        key={`item-${i}`}
                        onClick={() => onClick(value)}
                        selected={selectedValue === value}
                        // @ts-ignore
                        disabled={!selectableValues.includes(value)}
                        value={`${type !== "resolution" ? value : (value as string).replace("r_", "")} ${unit}`}
                    />
                ))}
            </Content>
        </Wrapper>
    );
}

import { Switch } from "@mui/material";
import * as React from "react";
import { ProperyChangeType } from "./customizationHelpers";
import CustomizeItem from "./CustomizeItem";

interface Props {
    currentAutoplay: boolean;
    onCustomizationChange: (type: ProperyChangeType, key: string, value: string | boolean | number) => void;
}

function ChannelCustomizerAutoplay({ currentAutoplay, onCustomizationChange }: Props) {
    function onChangeAutoplay() {
        onCustomizationChange("main", "autoplay", !currentAutoplay);
    }

    return (
        <CustomizeItem
            title={"Auto-play"}
            description={"Setting to switch on/off automatic playback"}
            customizationActions={<Switch checked={currentAutoplay} color={"secondary"} onChange={onChangeAutoplay} />}
        />
    );
}

export default ChannelCustomizerAutoplay;

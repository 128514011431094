import {
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from "@mui/material/colors";

export class ColorGenerator {
    private availableColors: string[] = [
        "#FFC713",
        blue[500],
        blueGrey[500],
        brown[500],
        cyan[500],
        deepOrange[500],
        deepPurple[500],
        green[500],
        grey[500],
        indigo[500],
        lightBlue[500],
        lightGreen[500],
        lime[500],
        orange[500],
        pink[500],
        purple[500],
        red[500],
        teal[500],
        yellow[500],
        amber[300],
        blue[300],
        blueGrey[300],
        brown[300],
        cyan[300],
        deepOrange[300],
        deepPurple[300],
        green[300],
        grey[300],
        indigo[300],
        lightBlue[300],
        lightGreen[300],
        lime[300],
        orange[300],
        pink[300],
        purple[300],
        red[300],
        teal[300],
        yellow[300],
        amber[800],
        blue[800],
        blueGrey[800],
        brown[800],
        cyan[800],
        deepOrange[800],
        deepPurple[800],
        green[800],
        grey[800],
        indigo[800],
        lightBlue[800],
        lightGreen[800],
        lime[800],
        orange[800],
        pink[800],
        purple[800],
        red[800],
        teal[800],
        yellow[800],
        amber[200],
        blue[200],
        blueGrey[200],
        brown[200],
        cyan[200],
        deepOrange[200],
        deepPurple[200],
        green[200],
        grey[200],
        indigo[200],
        lightBlue[200],
        lightGreen[200],
        lime[200],
        orange[200],
        pink[200],
        purple[200],
        red[200],
        teal[200],
        yellow[200],
    ];

    public getColor() {
        const selectedColor = this.availableColors[0];
        this.removeUsedColor();
        this.addToBack(selectedColor);
        return selectedColor;
    }

    private removeUsedColor() {
        this.availableColors.reverse().pop();
    }

    private addToBack(color: string) {
        this.availableColors.reverse().push(color);
    }
}

import { useTheme } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";
import SectionTitle from "../SectionTitle/SectionTitle";
import { navigate } from "@reach/router";

export const HESPlinkElement = styled.a`
    color: ${(props) => props.theme.secondary};
    cursor: pointer;
    text-decoration: none;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
`;

const SignUpInformation = styled.div`
    margin-top: 30px;
`;

export const HESPListItem = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    width: 100%;
`;

interface HESPLinkProps {
    children: React.ReactNode;
    link: string;
    openInNewTab?: boolean;
    underline?: boolean;
}

export function HESPlink({ children, link, openInNewTab, underline }: HESPLinkProps): JSX.Element {
    return (
        <HESPlinkElement
            href={link}
            style={{ textDecoration: underline === true ? "underline" : "none" }}
            onClick={(e: any) => {
                e.preventDefault();
                if (e.ctrlKey || openInNewTab === true) {
                    const newWindow = window.open(link, "_blank", "noopener,noreferrer");
                    if (newWindow) newWindow.opener = null;
                } else {
                    navigate(link);
                }
            }}
        >
            {children}
        </HESPlinkElement>
    );
}

function SignUp() {
    const theme = useTheme();

    return (
        <>
            <SectionTitle title="Almost there!" align="center" />
            <SignUpInformation>
                <p>Please check your email for an activation link to finish the sign-up process.</p>
                <p>Not received an email?</p>
                <div style={{ marginTop: "10px" }}>
                    <HESPListItem>
                        <CheckCircle style={{ color: theme.palette.primary.main }} />
                        <div style={{ marginLeft: "15px" }}>Check your spam folder</div>
                    </HESPListItem>
                    {/* <HESPListItem>
                        <CheckCircle style={{ color: theme.palette.primary.main }} />
                        <div style={{ marginLeft: "15px" }}>
                            <HESPlink href="">Click here</HESPlink> to re-send the email
                        </div>
                    </HESPListItem> */}
                </div>
            </SignUpInformation>
        </>
    );
}

export default SignUp;

import * as React from "react";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import InvoicesOverview from "./InvoicesOverview";
import CardHESP from "../CardHESP/CardHESP";

interface Props {
    organizationId: string;
}

function Invoices({ organizationId }: Props) {
    return (
        <>
            <SectionTitleWithAction title="Overview of Invoices" />
            <CardHESP>
                <InvoicesOverview organizationId={organizationId} />
            </CardHESP>
        </>
    );
}

export default Invoices;

import { DialogActions, DialogContent } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import styled from "styled-components";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { DeleteSchedulerMutation, DeleteSchedulerMutationVariables } from "./__generated__/DeleteSchedulerMutation";
import { navigate } from "@reach/router";
import { GetSchedulersBasedOnParamsVariables } from "./__generated__/GetSchedulersBasedOnParams";
import { GET_SCHEDULERS_BASED_ON_PARAMS } from "./SchedulerSection";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";
import { GET_SCHEDULERS_PER_PHASE } from "./SchedulersAnalytics";

export const DELETE_SCHEDULER_MUTATION = gql`
    mutation DeleteSchedulerMutation($input: DeleteSchedulerInput!) {
        deleteScheduler(input: $input)
    }
`;

interface Props {
    scheduler: {
        name: string;
        id: string;
        organizationId: string;
    };
    open: boolean;
    onClose: () => void;
    onDone?: () => void;
    view: "overview" | "details";
    queryParamsVariables?: GetSchedulersBasedOnParamsVariables;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

export default function DeleteSchedulerDialog({ open, onClose, scheduler, view, queryParamsVariables, onDone }: Props) {
    const [deleteSchedulerMut, { loading }] = useMutation<DeleteSchedulerMutation, DeleteSchedulerMutationVariables>(
        DELETE_SCHEDULER_MUTATION,
    );

    const { id, name, organizationId } = scheduler;

    async function deleteScheduler() {
        try {
            await deleteSchedulerMut({
                variables: {
                    input: {
                        schedulerId: id,
                        organizationId: scheduler.organizationId,
                    },
                },
                refetchQueries: queryParamsVariables
                    ? [
                          {
                              query: GET_SCHEDULERS_BASED_ON_PARAMS,
                              variables: { ...queryParamsVariables, phases: [SchedulerPhaseType.pending] },
                          },
                          {
                              query: GET_SCHEDULERS_BASED_ON_PARAMS,
                              variables: { ...queryParamsVariables, phases: [SchedulerPhaseType.terminated] },
                          },
                          {
                              query: GET_SCHEDULERS_PER_PHASE,
                              variables: {
                                  organizationId,
                              },
                          },
                      ]
                    : [],
            });
            toast.success("Scheduler got deleted", TOAST_SETTINGS);
            if (onDone) {
                onDone();
            }
            if (view === "details") {
                navigate(`/app/${scheduler.organizationId}/schedulers`);
            }
        } catch (_ignore) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Delete scheduler"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>Are you sure you want to delete the scheduler "{name}"?</Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Delete"}
                            loadingText={"Deleting..."}
                            onActionClick={() => deleteScheduler()}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import { ArrowCircleDown, Error, Warning } from "@mui/icons-material";
import * as React from "react";
import styled from "styled-components";
import StreamAnalyzerEventTypeTab from "./StreamAnalyzerEventTypeTabs";
import StreamAnalyzerEventsTable from "./StreamAnalyzerEventsTable";

const Wrapper = styled.div`
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    // padding: 10px 15px;
`;

const Tabs = styled.div`
    margin-top: 16px;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: row;
    padding-bottom: 6px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const TableWrapper = styled.div`
    margin-top: 10px;
`;

export type AnalyzerEventType = "warning" | "error";

export interface AnalyzerEvent {
    timestamp: string;
    level: AnalyzerEventType;
    type: string;
    mediatype: "audio" | "video";
    streamindex: number;
    message: string;
}

interface Props {
    events: AnalyzerEvent[];
}

type SelectedEventType = "all" | "warning" | "error";

export default function StreamAnalyzerEvents({ events }: Props) {
    const [selectedType, setSelectedType] = React.useState<SelectedEventType>("all");

    function getEvents() {
        switch (selectedType) {
            case "all":
                return events;
            case "error":
                return events.filter((e) => e.level === "error");
            case "warning":
                return events.filter((e) => e.level === "warning");
            default:
                return events;
        }
    }

    return (
        <Wrapper>
            <Tabs>
                <StreamAnalyzerEventTypeTab
                    icon={<ArrowCircleDown style={{ fontSize: "18px" }} />}
                    isSelected={selectedType === "all"}
                    selectedColor="#84b4cf"
                    title="All"
                    events={events}
                    onSelect={() => setSelectedType("all")}
                />
                <StreamAnalyzerEventTypeTab
                    icon={<Warning style={{ fontSize: "18px" }} />}
                    isSelected={selectedType === "warning"}
                    selectedColor="#ffbc47"
                    title="Warnings"
                    events={events.filter((e) => e.level === "warning")}
                    onSelect={() => setSelectedType("warning")}
                />
                <StreamAnalyzerEventTypeTab
                    icon={<Error style={{ fontSize: "18px" }} />}
                    isSelected={selectedType === "error"}
                    events={events.filter((e) => e.level === "error")}
                    selectedColor="#fc857e"
                    title="Errors"
                    onSelect={() => setSelectedType("error")}
                />
            </Tabs>
            <TableWrapper>
                <StreamAnalyzerEventsTable events={getEvents()} />
            </TableWrapper>
        </Wrapper>
    );
}

import { Circle, CloudDownload, DeleteForever, Edit, PlayArrow, Stop } from "@mui/icons-material";
import { Button, Chip, Grid, Tooltip } from "@mui/material";
import { navigate, RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import { SchedulerPhaseType, SchedulerReportType } from "../../__generated__/globalTypes";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import DataItem from "../DataItem/DataItem";
import { SkeletonRectangle } from "../Loading/Skeletons";
import { GET_SCHEDULER_QUERY } from "../SchedulersOverview/ManageSchedulerForm";
import {
    GetSchedulerQuery,
    GetSchedulerQueryVariables,
    GetSchedulerQuery_scheduler_report,
} from "../SchedulersOverview/__generated__/GetSchedulerQuery";
import * as React from "react";
import TimestampWithTooltip from "../TimestampWithTooltip/TimestampWithTooltip";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import SchedulerDetailsChannelItem from "./SchedulerDetailsChannelItem";
import styled from "styled-components";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import DeleteSchedulerDialog from "../SchedulersOverview/DeleteSchedulerDialog";
import TerminateSchedulerDialog from "../SchedulersOverview/TerminateSchedulerDialog";
import StartSchedulerNowDialog from "../SchedulersOverview/StartSchedulerNowDialog";
import AuthService from "../../auth/AuthService";
import { HESPlink } from "../Landing/SignUpComplete";
import SchedulerReportTimings from "./SchedulerReportTimings";

interface Props extends RouteComponentProps {
    schedulerId: string;
    organizationId: string;
}

const P = styled.p`
    font-size: 14px;
    margin: 5px;
`;

export default function SchedulerDetails({ schedulerId, organizationId }: Props) {
    const { data, loading } = useQuery<GetSchedulerQuery, GetSchedulerQueryVariables>(GET_SCHEDULER_QUERY, {
        variables: {
            schedulerId,
            organizationId,
        },
    });

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
    const [openStartNowDialog, setOpenStartNowDialog] = React.useState<boolean>(false);
    const [openTerminateDialog, setOpenTerminateDialog] = React.useState<boolean>(false);

    const showEditButton = data?.scheduler?.organizationId && data?.scheduler?.phase !== SchedulerPhaseType.terminated;
    const showStartNow =
        data?.scheduler?.phase &&
        [SchedulerPhaseType.pending, SchedulerPhaseType.starting].includes(data.scheduler.phase);
    const showTerminate =
        data?.scheduler?.phase &&
        [SchedulerPhaseType.active, SchedulerPhaseType.starting].includes(data.scheduler.phase);
    const showDelete =
        data?.scheduler?.phase &&
        [SchedulerPhaseType.pending, SchedulerPhaseType.terminated].includes(data.scheduler.phase);

    const startNowTooltipText =
        data?.scheduler?.phase === SchedulerPhaseType.pending
            ? "Start all channels now and make them available for your viewers"
            : data?.scheduler?.phase === SchedulerPhaseType.starting
            ? "Release all channels now to your viewers"
            : "";

    const isTHEOAdmin = AuthService.isTHEOAdmin();

    function getSchedulerPhaseColorAndText(): { color: string; text: string } {
        if (!data?.scheduler.phase) {
            return {
                color: "#dedede",
                text: "",
            };
        }

        switch (data.scheduler.phase) {
            case SchedulerPhaseType.active:
                return {
                    color: "green",
                    text: "Scheduler is active: all channels available for viewers",
                };
            case SchedulerPhaseType.deleted:
                return {
                    color: "red",
                    text: "Scheduler got deleted",
                };
            case SchedulerPhaseType.pending:
                return {
                    color: "#dedede",
                    text: "Scheduler is pending to start",
                };
            case SchedulerPhaseType.starting:
                return {
                    color: "#d3bade",
                    text: "Scheduler is starting up, channels will be shortly be available for viewers",
                };
            case SchedulerPhaseType.terminated:
                return {
                    color: "#dedede",
                    text: "Scheduler is terminated",
                };
        }
    }

    const updateReportTimes =
        data?.scheduler.shouldGenerateReport === true &&
        data?.scheduler.report.status !== SchedulerReportType.available;

    return (
        <>
            <SectionTitleWithAction
                title={
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title={getSchedulerPhaseColorAndText().text}>
                            <Circle style={{ fontSize: "12px", color: getSchedulerPhaseColorAndText().color }} />
                        </Tooltip>{" "}
                        <div style={{ marginLeft: "10px" }}>Scheduler {data?.scheduler?.name}</div>
                    </div>
                }
                action={
                    <>
                        {showStartNow && (
                            <Tooltip title={startNowTooltipText}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    startIcon={<PlayArrow />}
                                    style={{ marginLeft: "10px" }}
                                    color="success"
                                    onClick={() => setOpenStartNowDialog(true)}
                                >
                                    Start now
                                </Button>
                            </Tooltip>
                        )}
                        {showTerminate && (
                            <Button
                                size="small"
                                variant="contained"
                                startIcon={<Stop />}
                                style={{ marginLeft: "10px" }}
                                color="error"
                                onClick={() => setOpenTerminateDialog(true)}
                            >
                                Terminate
                            </Button>
                        )}
                    </>
                }
            />
            <CardHESP>
                <CardHESPTitle
                    title="Details"
                    subtitle={getSchedulerPhaseColorAndText().text}
                    button={
                        <>
                            {showEditButton && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    startIcon={<Edit />}
                                    color="primary"
                                    onClick={() =>
                                        navigate(
                                            `/app/${data.scheduler.organizationId}/schedulers/${data.scheduler.schedulerId}/edit`,
                                        )
                                    }
                                >
                                    Edit
                                </Button>
                            )}

                            {showDelete && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    startIcon={<DeleteForever />}
                                    style={{ marginLeft: "10px" }}
                                    color="inherit"
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    Delete
                                </Button>
                            )}
                        </>
                    }
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <DataItem
                            title="Name"
                            value={loading || !data ? <SkeletonRectangle width="50%" /> : <>{data.scheduler.name}</>}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DataItem
                            title="ClientID"
                            value={
                                loading || !data ? <SkeletonRectangle width="50%" /> : <>{data.scheduler.clientId}</>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DataItem
                            title="Start"
                            value={
                                loading || !data ? (
                                    <SkeletonRectangle width="50%" />
                                ) : (
                                    <TimestampWithTooltip
                                        timestampISOString={moment(data.scheduler.start).toISOString()}
                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DataItem
                            title="End"
                            value={
                                loading || !data ? (
                                    <SkeletonRectangle width="50%" />
                                ) : data.scheduler.end ? (
                                    <TimestampWithTooltip
                                        timestampISOString={moment(data.scheduler.end).toISOString()}
                                    />
                                ) : (
                                    "No end time set"
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataItem
                            title="Connected channels"
                            value={
                                loading || !data ? (
                                    <>
                                        <SkeletonRectangle width="50%" />
                                        <SkeletonRectangle width="50%" />
                                        <SkeletonRectangle width="50%" />
                                    </>
                                ) : (
                                    <Grid container spacing={2}>
                                        {data.scheduler.channels.map((channel) => (
                                            <Grid item xs={12} sm={6} md={4} key={`channel-${channel.channelId}`}>
                                                <SchedulerDetailsChannelItem
                                                    id={channel.channelId}
                                                    name={channel.metadata.name}
                                                    organizationId={data.scheduler.organizationId}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DataItem
                            title="Metadata"
                            value={
                                loading || !data ? (
                                    <>
                                        <SkeletonRectangle width="50%" />
                                        <SkeletonRectangle width="50%" />
                                        <SkeletonRectangle width="50%" />
                                    </>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            {data.scheduler.metadata.length === 0 ? (
                                                <span>No metadata set</span>
                                            ) : (
                                                <div style={{ display: "flex" }}>
                                                    {data.scheduler.metadata.map((item, i) => (
                                                        <Chip
                                                            key={`item-${i}`}
                                                            style={{ marginBottom: "6px", marginRight: "10px" }}
                                                            label={`${item.key}: ${item.value}`}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                )
                            }
                        />
                    </Grid>
                    {data?.scheduler.recurring.enabled === true && (
                        <Grid item xs={12}>
                            <DataItem
                                title="Recurring scheduler"
                                value={
                                    <>
                                        This scheduler will be repeated on{" "}
                                        {data.scheduler.recurring.days
                                            .map(
                                                (day) =>
                                                    [
                                                        "Sunday",
                                                        "Monday",
                                                        "Tuesday",
                                                        "Wednesday",
                                                        "Thursday",
                                                        "Friday",
                                                        "Saturday",
                                                    ][day],
                                            )
                                            .join(", ")}{" "}
                                    </>
                                }
                            />
                        </Grid>
                    )}
                    {isTHEOAdmin && data?.scheduler.auditDone === true && (
                        <Grid item xs={12}>
                            <DataItem
                                title="Audit report"
                                value={
                                    <HESPlink
                                        link={`/app/${data?.scheduler.organizationId}/schedulers/${schedulerId}/audit`}
                                    >
                                        View
                                    </HESPlink>
                                }
                            />{" "}
                        </Grid>
                    )}
                </Grid>
            </CardHESP>
            {!loading && data?.scheduler && data.scheduler.shouldGenerateReport && (
                <CardHESP style={{ marginTop: "20px" }}>
                    <CardHESPTitle
                        title="Usage report"
                        subtitle="See the distribution between channels, countries, browsers, OS, ..."
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            <P style={{ marginTop: "10px" }}>{getReportAvailability(data.scheduler.report)}</P>
                        </Grid>
                        {updateReportTimes && (
                            <Grid item xs={12}>
                                <P>
                                    By default a report will be generated for data between the scheduler start and end
                                    time. Below, you can set your own time range if desired. The report start and end
                                    time should lay between the scheduler start and end time.
                                </P>
                                {data.scheduler.end && (
                                    <div style={{ marginTop: "18px" }}>
                                        <SchedulerReportTimings scheduler={data.scheduler} />
                                    </div>
                                )}
                                {!data.scheduler.end && (
                                    <P>
                                        <strong>
                                            You can only set report timings when the end time of your scheduler is
                                            known.
                                        </strong>
                                    </P>
                                )}
                            </Grid>
                        )}
                        {data.scheduler.report.status === SchedulerReportType.available &&
                            data.scheduler.report.link && (
                                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                    <Button
                                        size="small"
                                        startIcon={<CloudDownload />}
                                        variant="contained"
                                        href={data.scheduler.report.link}
                                        target="_blank"
                                        download
                                    >
                                        Download
                                    </Button>
                                </Grid>
                            )}
                    </Grid>
                </CardHESP>
            )}
            <ToastContainer />
            {data?.scheduler && (
                <>
                    <DeleteSchedulerDialog
                        onClose={() => setOpenDeleteDialog(false)}
                        open={openDeleteDialog}
                        scheduler={{
                            id: data.scheduler.schedulerId,
                            name: data.scheduler.name,
                            organizationId: data.scheduler.organizationId,
                        }}
                        view="details"
                    />
                    <TerminateSchedulerDialog
                        onClose={() => setOpenTerminateDialog(false)}
                        open={openTerminateDialog}
                        scheduler={{
                            id: data.scheduler.schedulerId,
                            name: data.scheduler.name,
                            organizationId: data.scheduler.organizationId,
                        }}
                    />
                    <StartSchedulerNowDialog
                        onClose={() => setOpenStartNowDialog(false)}
                        open={openStartNowDialog}
                        scheduler={{
                            id: data.scheduler.schedulerId,
                            name: data.scheduler.name,
                            organizationId: data.scheduler.organizationId,
                        }}
                    />
                </>
            )}
        </>
    );
}

export function getReportAvailability(report: GetSchedulerQuery_scheduler_report) {
    const { status, start, end } = report;

    switch (status) {
        case SchedulerReportType.available:
            return (
                <div style={{ marginBottom: "20px" }}>
                    <p>Usage report available. You can download it via the button below.</p>
                    {start && end && (
                        <p>
                            For this report, a custom range has been set: {moment(start).format("YYYY/MM/DD HH:mm")}{" "}
                            until {moment(end).format("YYYY/MM/DD HH:mm")}
                        </p>
                    )}
                </div>
            );
        case SchedulerReportType.generating:
            return "A usage report will be generated +- 15 minutes after the scheduler end time.";
        case SchedulerReportType.unavailable:
            return "A usage report will be generated once the scheduler is finished.";
    }
}

import { Button, Divider, FormControl, Grid, Hidden, TextField, Typography } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { useState } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";
import {
    CreateDeviceContextForDatacenterMutation,
    CreateDeviceContextForDatacenterMutationVariables,
} from "./__generated__/CreateDeviceContextForDatacenterMutation";
import { Add } from "@mui/icons-material";
import { isEmpty } from "lodash";
import TLALabelsManager from "./TLALabelsManager";
import { v4 } from "uuid";
import { GET_DATACENTER } from "../../views/App/TLADatacenter";

export interface TLADeviceContextInput {
    name: string;
    description: string;
    labels: string[];
    serial: string;
    privateId: string;
    capacity: number;
}

const SectionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

interface Props {
    datacenterId: string;
    values: TLADeviceContextInput;
}

export const CREATE_DEVICE_CONTEXT_FOR_DATACENTER = gql`
    mutation CreateDeviceContextForDatacenterMutation($input: CreateDeviceContextForDatacenterInput!) {
        createDeviceContextForDatacenter(input: $input) {
            id
        }
    }
`;

export default function TLAManageDeviceContextsForm({ datacenterId, values }: Props): JSX.Element {
    const [name, setName] = useState<string>(values.name);
    const [description, setDescription] = useState<string>(values.description);
    const [labels, setLabels] = useState<Record<string, string>>(arrayToRecord(values.labels));
    const [serial, setSerial] = useState<string>(values.serial);
    const [privateId, setPrivateId] = useState<string>(values.privateId);
    const [capacity, setCapacity] = useState<number>(values.capacity);

    const [labelsOk, setLabelsOk] = useState<boolean>(true);
    const [firstSubmitDone, setFirstSubmitDone] = useState<boolean>(false);

    const [createDeviceContext, { loading }] = useMutation<
        CreateDeviceContextForDatacenterMutation,
        CreateDeviceContextForDatacenterMutationVariables
    >(CREATE_DEVICE_CONTEXT_FOR_DATACENTER);

    function notEmpty(val: string): boolean {
        if (val.trim().length === 0) {
            return false;
        }
        return true;
    }

    const regex = /([A-z0-9/+-.,])*=([A-z0-9/+-.,])*/;

    function arrayToRecord(lbls: string[]): Record<string, string> {
        const record: Record<string, string> = {};

        lbls.forEach((label) => (record[v4()] = label));
        return record;
    }

    async function onSubmitClick(): Promise<void> {
        setFirstSubmitDone(true);
        if (isValid()) {
            try {
                await createDeviceContext({
                    variables: {
                        input: {
                            capacity: parseInt(capacity.toString(), 10),
                            description,
                            id: datacenterId,
                            labels: Object.values(labels),
                            name,
                            privateId,
                            serial,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_DATACENTER,
                            variables: {
                                id: datacenterId,
                            },
                        },
                    ],
                });
                navigate(`/app/${THEO_ID}/tla/data-centers/${datacenterId}`);
                toast.success("TLA device context created", TOAST_SETTINGS);
            } catch (_e) {
                toast.error("Something went wrong", TOAST_SETTINGS);
            }
        }
    }

    function onChangeLabel(idx: string, value: string) {
        const copyLabels = labels;
        copyLabels[idx] = value;
        setLabels(copyLabels);
        setLabelsOk(validLabels(Object.values(copyLabels)));
    }

    function isValid(): boolean {
        return !isEmpty(name) && !isEmpty(privateId) && !isEmpty(serial);
    }

    function validLabels(lbls: string[]) {
        let isValid = true;
        lbls.forEach((label) => {
            if (!regex.test(label)) {
                isValid = false;
            }
        });
        return isValid;
    }

    function onRemoveLabel(idx: string) {
        const newList: Record<string, string> = {};
        for (let key in labels) {
            if (key !== idx) {
                newList[key] = labels[key];
            }
        }
        setLabels(newList);
        setLabelsOk(validLabels(Object.values(newList)));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionTitle>Basic information</SectionTitle>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Name" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={name}
                        placeholder="Name"
                        onChange={(e: any) => setName(e.target.value)}
                    />
                    {!notEmpty(name) && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid name
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Description" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={description}
                        placeholder="Description"
                        onChange={(e: any) => setDescription(e.target.value)}
                    />
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Serial" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={serial}
                        placeholder="Serial"
                        onChange={(e: any) => setSerial(e.target.value)}
                    />
                    {!notEmpty(serial) && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid serial
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Private ID" />
                    <TextField
                        type="password"
                        variant="outlined"
                        fullWidth
                        defaultValue={privateId}
                        placeholder="Private ID"
                        onChange={(e: any) => setPrivateId(e.target.value)}
                    />
                    {!notEmpty(privateId) && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid private ID
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Capacity" />
                    <TextField
                        InputProps={{
                            inputProps: { min: -1 },
                        }}
                        type="number"
                        variant="outlined"
                        fullWidth
                        defaultValue={capacity}
                        placeholder="Capacity"
                        onChange={(e: any) => setCapacity(e.target.value)}
                    />
                    {!notEmpty(capacity.toString()) && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid capacity
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Labels</SectionTitle>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <TLALabelsManager
                        labels={labels}
                        onChangeLabel={(idx: string, val: string) => onChangeLabel(idx, val)}
                        onRemoveLabel={(idx: string) => onRemoveLabel(idx)}
                        regex={regex}
                    />
                </FormControl>
                <FormControl fullWidth style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        color="secondary"
                        startIcon={<Add />}
                        onClick={() => {
                            const copyLabels = labels;
                            copyLabels[v4()] = "";
                            setLabels({ ...copyLabels });
                            setLabelsOk(validLabels(Object.values(copyLabels)));
                        }}
                    >
                        Add label
                    </Button>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button
                        onClick={onSubmitClick}
                        variant="contained"
                        size="large"
                        disabled={loading || !isValid() || !labelsOk}
                    >
                        {loading ? `Creating...` : `Create`}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    onCreateClick: () => void;
}

const CreationDiv = styled.div`
    min-height: 100px;
    display: flex;
    width: 100;
    justify-content: center;
    align-items: center;
`;

function ChannelsNoneCreated({ onCreateClick }: Props) {
    return (
        <CreationDiv>
            <Button onClick={() => onCreateClick()} color="primary" size="large" variant="contained">
                Create your first channel!
            </Button>
        </CreationDiv>
    );
}

export default ChannelsNoneCreated;

import * as React from "react";
import styled from "styled-components";
import RegistrationTabs, { SelectedRegistrationTabType } from "./RegistrationTabs";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet";

interface Props {
    selectedTab: SelectedRegistrationTabType;
    onForgotPasswordClick: () => void;
    onRegistrationSuccess: () => void;
}

const RegistrationTabContent = styled.div`
    margin-top: 30px;
    padding: 0px 20px;
`;

function Registration({ onForgotPasswordClick, onRegistrationSuccess, selectedTab }: Props) {
    return (
        <>
            {selectedTab === "signup" && (
                <Helmet>
                    <script>
                        {`
                         var callback = function () {
                            if (typeof(url) != 'undefined') {
                              window.location = url;
                            }
                          };
                        gtag('event', 'conversion', {'send_to': 'AW-10802167347/eHBCCMXNtaUDELP8754o', event_callback: callback });`}
                    </script>
                </Helmet>
            )}
            <RegistrationTabs selectedTab={selectedTab} onChangeTab={(tab) => navigate(tab)} />
            <RegistrationTabContent>
                {selectedTab === "login" && <SignIn onForgotPassword={onForgotPasswordClick} />}
                {selectedTab === "signup" && <SignUp onSignUpComplete={onRegistrationSuccess} />}
            </RegistrationTabContent>
        </>
    );
}

export default Registration;

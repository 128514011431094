import moment from "moment";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { Grid } from "@mui/material";
import OrganizationCommitmentUsageSettings from "./OrganizationCommitmentUsageSettings";
import * as React from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "react-apollo";
import {
    GetCommitmentUsageReport,
    GetCommitmentUsageReportVariables,
    GetCommitmentUsageReport_getCommitmentUsageReport,
} from "./__generated__/GetCommitmentUsageReport";
import OrganizationCommitmentUsageData from "./OrganizationCommitmentUsageData";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";
interface Props {
    organization: GetFullOrganizationDetails_organization;
}

export const GET_COMMITMENT_USAGE = gql`
    query GetCommitmentUsageReport($organizationId: ID!, $startDate: String!, $endDate: String!) {
        getCommitmentUsageReport(organizationId: $organizationId, startDate: $startDate, endDate: $endDate) {
            transcoded {
                items {
                    channel {
                        channelId
                        name
                    }
                    scheduler {
                        schedulerId
                        name
                        clientId
                    }
                    config {
                        abr
                        fps
                        resolution
                        bitrate
                        drm
                    }
                    start
                    end
                    seconds
                }
            }
            viewed {
                items {
                    alias {
                        aliasId
                        name
                    }
                    channel {
                        channelId
                        name
                    }
                    scheduler {
                        schedulerId
                        name
                        clientId
                        start
                        end
                    }
                    config {
                        abr
                        fps
                        resolution
                        bitrate
                        drm
                    }
                    zone
                    src
                    seconds
                    gbConsumed
                }
            }
        }
    }
`;

export default function OrganizationCommitmentUsage({ organization }: Props) {
    const [startDate, setStartDate] = React.useState(moment().startOf("month"));
    const [endDate, setEndDate] = React.useState(moment().endOf("day"));

    const [data, setData] = React.useState<GetCommitmentUsageReport_getCommitmentUsageReport | undefined>(undefined);

    const [getCommitmentUsage, { loading }] = useLazyQuery<GetCommitmentUsageReport, GetCommitmentUsageReportVariables>(
        GET_COMMITMENT_USAGE,
        {
            onCompleted: (data) => {
                setData(data.getCommitmentUsageReport);
            },

            variables: {
                endDate: endDate.toISOString(),
                organizationId: organization.organizationId,
                startDate: startDate.toISOString(),
            },
        },
    );

    const contract =
        organization.contracts.filter(
            (contract) => moment(contract.start) < moment() && moment(contract.end) > moment(),
        )[0] ??
        organization.contracts[0] ??
        undefined;

    const includeBillingInGb = contract?.billInGb === true ?? false;

    return (
        <CardHESP>
            <CardHESPTitle title="Usage" subtitle="Calculate seconds transcoded and viewed over a period of time" />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <OrganizationCommitmentUsageSettings
                        endDate={endDate}
                        onChangeEnd={(val: moment.Moment) => setEndDate(val)}
                        onChangeStart={(val: moment.Moment) => setStartDate(val)}
                        onSubmit={() => {
                            getCommitmentUsage({
                                variables: {
                                    endDate: endDate.toISOString(),
                                    organizationId: organization.organizationId,
                                    startDate: startDate.toISOString(),
                                },
                            });
                        }}
                        startDate={startDate}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OrganizationCommitmentUsageData
                        loading={loading}
                        data={data}
                        includeBillingInGb={includeBillingInGb}
                    />
                </Grid>
            </Grid>
        </CardHESP>
    );
}

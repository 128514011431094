import * as React from "react";
import RecentErrorsFilters, { RecentErrorsFilter } from "./RecentErrorsFilters";
import { RunTimeRange } from "../Channels/Runs/ChannelRunsContent";
import moment from "moment";
import { SkeletonRectangle } from "../Loading/Skeletons";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetAdminEventsQuery, GetAdminEventsQueryVariables } from "./__generated__/GetAdminEventsQuery";
import RecentErrorsList from "./RecentErrorsList";

export const GET_ADMIN_EVENTS = gql`
    query GetAdminEventsQuery($startingAfter: Int!, $startDate: String!, $endDate: String!, $types: [String!]) {
        allEventsForAdmin(startDate: $startDate, endDate: $endDate, startingAfter: $startingAfter, types: $types) {
            events {
                eventId
                organizationId
                organization {
                    organizationId
                    name
                }
                objectId
                type
                timestamp
                data
            }
            hasMore
        }
    }
`;

export default function RecentErrorsContent() {
    const initEndDate = moment().endOf("day").toISOString();
    const initStartDate = moment().subtract(24, "hours").startOf("hour").toISOString();
    const initRange: RunTimeRange = "day";

    const { data, loading, fetchMore, refetch } = useQuery<GetAdminEventsQuery, GetAdminEventsQueryVariables>(
        GET_ADMIN_EVENTS,
        {
            variables: {
                startingAfter: 0,
                startDate: initStartDate,
                endDate: initEndDate,
                types: ["channel.log.error"],
            },
        },
    );

    async function onApplyFilters({ endDate, startDate }: RecentErrorsFilter) {
        refetch({
            endDate,
            startDate,
            startingAfter: 0,
        });
    }

    function onMoreClick() {
        const events = data!.allEventsForAdmin.events;
        fetchMore({
            variables: {
                startingAfter: events.length,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                const res: GetAdminEventsQuery = {
                    allEventsForAdmin: {
                        __typename: "EventsListResult",
                        events: [
                            ...previousQueryResult.allEventsForAdmin.events,
                            ...fetchMoreResult.allEventsForAdmin.events,
                        ],
                        hasMore: fetchMoreResult.allEventsForAdmin.hasMore,
                    },
                };

                return res;
            },
        });
    }

    return (
        <>
            <RecentErrorsFilters
                filters={{
                    endDate: initEndDate,
                    startDate: initStartDate,
                    range: initRange,
                }}
                onApplyFilter={(updatedFilters: RecentErrorsFilter) => onApplyFilters(updatedFilters)}
            />
            {loading ||
                (!data && (
                    <>
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                    </>
                ))}
            {!loading && data && (
                <RecentErrorsList
                    events={data.allEventsForAdmin.events}
                    hasMore={data.allEventsForAdmin.hasMore}
                    onLoadMore={onMoreClick}
                />
            )}
        </>
    );
}

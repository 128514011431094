import { Button, Grid, Popover } from "@mui/material";
import * as React from "react";
import SearchField from "../../SearchField/SearchField";
import styled from "styled-components";
import { Add, MonetizationOn, Podcasts, Public } from "@mui/icons-material";
import ChannelsOverviewFilterItem from "./ChannelsOverviewFilterItem";
import ChannelFilterGroup from "./ChannelFilterGroup";

interface Props {
    items: {
        regions: string[];
        modes: string[];
        states: string[];
    };
    selectedItems: {
        regions: string[];
        modes: string[];
        states: string[];
    };
    onDeselectItem: (type: ChannelFilterType, item: string) => void;
    onSelectItem: (type: ChannelFilterType, item: string) => void;
    onSelectAll: (type: ChannelFilterType) => void;
    onDeselectAll: (type: ChannelFilterType) => void;
    onChangeSearchInputValue: (value: string) => void;
    initFiltersToShow: ChannelFilterType[];
}

const Wrapper = styled.div`
    border-radius: 8px;
    padding: 14px 20px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const FilterSelectorWrapper = styled.div`
    padding: 4px 10px;
    width: 250px;
`;

export type ChannelFilterType = "region" | "mode" | "status";
const AMOUNT_OF_FILTERS = 3;

function ChannelsOverviewFilters({
    onChangeSearchInputValue,
    onDeselectItem,
    onSelectItem,
    onDeselectAll,
    onSelectAll,
    items,
    selectedItems,
    initFiltersToShow,
}: Props) {
    const [filtersToShow, setFiltersToShow] = React.useState<ChannelFilterType[]>(initFiltersToShow);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Wrapper>
            <Grid container justifyContent="flex-end" alignItems={"center"} spacing={2}>
                <Grid item>
                    <SearchField
                        placeholder="Search..."
                        onKeyStroke={(value: string) => onChangeSearchInputValue(value)}
                        size={"small"}
                    />
                </Grid>
                <Grid item>
                    <Button size="small" startIcon={<Add />} color="inherit" variant="contained" onClick={handleClick}>
                        Add filter
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        style={{
                            marginTop: "4px",
                        }}
                    >
                        <FilterSelectorWrapper>
                            {!filtersToShow.includes("region") && (
                                <ChannelsOverviewFilterItem
                                    icon={<Public color="secondary" style={{ fontSize: "16px" }} />}
                                    title={"Regions"}
                                    onClick={() => setFiltersToShow(filtersToShow.concat(["region"]))}
                                />
                            )}
                            {!filtersToShow.includes("status") && (
                                <ChannelsOverviewFilterItem
                                    icon={<Podcasts color="secondary" style={{ fontSize: "16px" }} />}
                                    title={"States"}
                                    onClick={() => setFiltersToShow(filtersToShow.concat(["status"]))}
                                />
                            )}
                            {!filtersToShow.includes("mode") && (
                                <ChannelsOverviewFilterItem
                                    icon={<MonetizationOn color="secondary" style={{ fontSize: "16px" }} />}
                                    title={"Modes"}
                                    onClick={() => setFiltersToShow(filtersToShow.concat(["mode"]))}
                                />
                            )}
                            {filtersToShow.length === AMOUNT_OF_FILTERS && (
                                <div style={{ fontSize: "13px" }}>No more filters available</div>
                            )}
                        </FilterSelectorWrapper>
                    </Popover>
                </Grid>
            </Grid>
            {filtersToShow.length > 0 && (
                <Grid container spacing={2} style={{ marginTop: "2px" }}>
                    {filtersToShow.includes("region") && (
                        <Grid item xs={12}>
                            <ChannelFilterGroup
                                items={items.regions.map((r) => ({ key: r, value: r }))}
                                onDeselectAll={() => onDeselectAll("region")}
                                onSelectAll={() => onSelectAll("region")}
                                onDeselectItem={(item: string) => onDeselectItem("region", item)}
                                onRemove={() => {
                                    setFiltersToShow(filtersToShow.filter((f) => f !== "region"));
                                    onSelectAll("region");
                                }}
                                onSelectItem={(item: string) => onSelectItem("region", item)}
                                selectedItems={selectedItems.regions}
                                title="Regions"
                            />
                        </Grid>
                    )}
                    {filtersToShow.includes("status") && (
                        <Grid item xs={12}>
                            <ChannelFilterGroup
                                items={items.states.map((s) => ({ key: s, value: s }))}
                                onDeselectAll={() => onDeselectAll("status")}
                                onSelectAll={() => onSelectAll("status")}
                                onDeselectItem={(item: string) => onDeselectItem("status", item)}
                                onRemove={() => {
                                    setFiltersToShow(filtersToShow.filter((f) => f !== "status"));
                                    onSelectAll("status");
                                }}
                                onSelectItem={(item: string) => onSelectItem("status", item)}
                                selectedItems={selectedItems.states}
                                title="States"
                            />
                        </Grid>
                    )}
                    {filtersToShow.includes("mode") && (
                        <Grid item xs={12}>
                            <ChannelFilterGroup
                                items={items.modes.map((m) => ({ key: m, value: m }))}
                                onDeselectItem={(item: string) => onDeselectItem("mode", item)}
                                onDeselectAll={() => onDeselectAll("mode")}
                                onSelectAll={() => onSelectAll("mode")}
                                onRemove={() => {
                                    setFiltersToShow(filtersToShow.filter((f) => f !== "mode"));
                                    onSelectAll("mode");
                                }}
                                onSelectItem={(item: string) => onSelectItem("mode", item)}
                                selectedItems={selectedItems.modes}
                                title="Modes"
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </Wrapper>
    );
}

export default ChannelsOverviewFilters;

import * as React from "react";
import styled from "styled-components";
import { DialogActions, DialogContent } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { useMutation } from "react-apollo";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import { GetChannelQuery_channel_aliases } from "../Overview/__generated__/GetChannelQuery";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import {
    DeleteChannelAliasMutation,
    DeleteChannelAliasMutationVariables,
} from "./__generated__/DeleteChannelAliasMutation";
import gql from "graphql-tag";

interface Props {
    open: boolean;
    alias: GetChannelQuery_channel_aliases;
    parentChannelId: string;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

export const DELETE_CHANNEL_ALIAS_MUTATION = gql`
    mutation DeleteChannelAliasMutation($input: DeleteChannelAliasInput!) {
        deleteChannelAlias(input: $input)
    }
`;

function ChannelDeleteAliasDialog({ open, onClose, alias, onSuccess, onError, parentChannelId }: Props) {
    const [deleteChannelAliasMut, { loading }] = useMutation<
        DeleteChannelAliasMutation,
        DeleteChannelAliasMutationVariables
    >(DELETE_CHANNEL_ALIAS_MUTATION);

    const { channelId, metadata } = alias;

    async function deleteChannelAlias() {
        try {
            await deleteChannelAliasMut({
                variables: {
                    input: {
                        channelAliasId: channelId,
                        organizationId: alias.organizationId,
                        channelId: alias.parentChannelId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: parentChannelId,
                            organizationId: alias.organizationId,
                        },
                    },
                ],
            });
            onSuccess();
        } catch (e: any) {
            onError();
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Delete channel alias"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>Are you sure you want to delete the alias "{metadata.name}"?</Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Delete"}
                            loadingText={"Deleting..."}
                            onActionClick={() => deleteChannelAlias()}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default ChannelDeleteAliasDialog;

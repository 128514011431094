import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import {
    GetChannelAdminLinksQuery,
    GetChannelAdminLinksQueryVariables,
} from "./__generated__/GetChannelAdminLinksQuery";
import ChannelAdminLinkItem from "./ChannelAdminLinkItem";
import { DonutSmall, Explicit, LiveTv, Subject, VideoCameraBack } from "@mui/icons-material";
import styled from "styled-components";

export const GET_CHANNEL_ADMIN_LINKS = gql`
    query GetChannelAdminLinksQuery($channelId: ID!, $organizationId: ID!) {
        channel(channelId: $channelId, organizationId: $organizationId) {
            channelId
            organizationId
            tla {
                enabled
            }
            adminLinks {
                channel
                ingest
                elastic
                loki
            }
        }
    }
`;

interface Props {
    channelId: string;
    organizationId: string;
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
`;

export default function ChannelAdminLinks({ channelId, organizationId }: Props) {
    const { data, loading } = useQuery<GetChannelAdminLinksQuery, GetChannelAdminLinksQueryVariables>(
        GET_CHANNEL_ADMIN_LINKS,
        {
            variables: {
                channelId,
                organizationId,
            },
        },
    );

    if (!data || loading) {
        return <></>;
    }

    const { channel, elastic, ingest, loki } = data.channel.adminLinks;

    return (
        <Wrapper>
            <ChannelAdminLinkItem
                icon={
                    data.channel.tla?.enabled === true ? (
                        <Explicit color="secondary" style={{ fontSize: "16px" }} />
                    ) : (
                        <LiveTv color="secondary" style={{ fontSize: "16px" }} />
                    )
                }
                onClick={() => window.open(channel)}
                title={data.channel.tla?.enabled === true ? "Grafana: engine" : "Grafana: channel"}
            />
            {ingest.trim().length !== 0 && (
                <ChannelAdminLinkItem
                    icon={<VideoCameraBack color="secondary" style={{ fontSize: "16px" }} />}
                    onClick={() => window.open(ingest)}
                    title="Grafana ingest"
                />
            )}
            {elastic.trim().length !== 0 && (
                <ChannelAdminLinkItem
                    icon={<DonutSmall color="secondary" style={{ fontSize: "16px" }} />}
                    onClick={() => window.open(elastic)}
                    title="Elastic"
                />
            )}
            {loki.trim().length !== 0 && (
                <ChannelAdminLinkItem
                    icon={<Subject color="secondary" style={{ fontSize: "16px" }} />}
                    onClick={() => window.open(loki)}
                    title="Loki logs"
                />
            )}
        </Wrapper>
    );
}

import { Add, DeleteForever } from "@mui/icons-material";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { KeyValuePairInput } from "../__generated__/globalTypes";

interface Props {
    items: KeyValuePairInput[];
    onAdd: () => void;
    onUpdate: (type: "key" | "value", val: string, index: number) => void;
    onRemove: (index: number) => void;
}

export default function KeyValueConstructor({ items, onAdd, onRemove, onUpdate }: Props) {
    function showRedBorder(key: string, index: number) {
        if (key.trim().length === 0) {
            return true;
        }

        const itemsBefore = items.slice(0, index).map((i) => i.key.trim());
        if (itemsBefore.includes(key.trim())) {
            return true;
        }

        return false;
    }

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell style={{ width: "80px" }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, i) => (
                        <TableRow key={`kv-item-${i}`}>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    type="text"
                                    size="small"
                                    onChange={(e) => onUpdate("key", e.target.value, i)}
                                    variant="outlined"
                                    value={item.key}
                                    InputProps={{
                                        style: {
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            padding: "0px 10px",
                                            height: "35px",
                                            ...(showRedBorder(item.key, i) && { border: "1px solid #de613e" }),
                                        },
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    type="text"
                                    size="small"
                                    onChange={(e) => onUpdate("value", e.target.value, i)}
                                    variant="outlined"
                                    value={item.value}
                                    InputProps={{
                                        style: {
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            padding: "0px 10px",
                                            height: "35px",
                                        },
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{ width: "80px" }}>
                                <IconButton size="small" onClick={() => onRemove(i)}>
                                    <DeleteForever />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <Button size="small" color="primary" onClick={() => onAdd()} startIcon={<Add />}>
                    Add entry
                </Button>
            </div>
        </>
    );
}

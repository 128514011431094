import { RouteComponentProps, useLocation } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitleWithAction from "../../components/SectionTitle/SectionTitleWithAction";
import SchedulerFilters from "../../components/SchedulersOverview/SchedulerFilters";
import moment from "moment";
import { parse } from "query-string";
import { OrderDirectionType, SchedulerPhaseType } from "../../__generated__/globalTypes";
import CardHESP from "../../components/CardHESP/CardHESP";

import SchedulersOverviewList from "../../components/SchedulersOverview/SchedulersOverviewList";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export interface SchedulerFilterValues {
    startDate: string;
    endDate: string;
    search: string;
    sortBy: string;
    sortDirection: OrderDirectionType;
    phases: SchedulerPhaseType[];
}

export default function SchedulersOverviewWithFilters({ organizationId }: Props) {
    const location = useLocation();
    const searchParams = parse(location.search);

    const { phases: urlPhases } = searchParams;

    const [filters, setFilters] = React.useState<SchedulerFilterValues>({
        startDate: moment("2023-01-01").startOf("day").toISOString(),
        endDate: moment().endOf("day").toISOString(),
        phases: (urlPhases as string).split(",") as SchedulerPhaseType[],
        search: "",
        sortBy: "start",
        sortDirection: OrderDirectionType.desc,
    });

    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center" alignItems={"center"} alignContent="center">
            <Grid item xs={12}>
                <SectionTitleWithAction title="Schedulers" />
                <CardHESP>
                    <SchedulerFilters
                        filterValues={filters}
                        onApplyFilters={(newFilter: SchedulerFilterValues) => setFilters(newFilter)}
                        sortItems={[
                            { id: "name", text: "Name" },
                            { id: "id", text: "ID" },
                            { id: "client_id", text: "Client ID" },
                            { id: "start", text: "Start Date" },
                            { id: "end", text: "End Date" },
                        ]}
                    />
                    <SchedulersOverviewList organizationId={organizationId!} filters={filters} />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

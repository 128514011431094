import { createTheme } from "@mui/material/styles";

import { red } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            light: "#FFC713",
            main: "#FFC713",
            dark: "#e5b311",
        },
        secondary: {
            main: "#344a5e",
            light: "#f6f8fa",
        },
        success: {
            main: "#62b33e",
            contrastText: "white",
            light: "#93c97b",
        },
        info: {
            main: "#0fa8bf",
        },
        warning: {
            main: "#f79f11",
        },
        error: {
            light: red[200],
            main: red[300],
            dark: red[500],
            contrastText: "white",
        },
    },
    typography: {
        fontFamily: "Open sans",
        // fontSize: 12,
    },
    components: {
        MuiChip: {
            styleOverrides: {
                label: {
                    userSelect: "auto",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#f6f8fa",
                    color: "black",
                    fontSize: "12px",
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "@global": {
                    body: {
                        backgroundColor: "white",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: "14px 10px",
                    color: "#696969",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: "5px 0px",
                    fontWeight: "bolder",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    textTransform: "capitalize",
                    boxShadow: "none",
                },
                containedPrimary: {
                    color: "#344a5e",
                },
                containedSecondary: {
                    color: "white",
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                "spacing-xs-6": {
                    width: "100%",
                    margin: "0px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "16px 24px 4px 24px",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    marginTop: "6px",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingTop: "18px",
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                markLabel: {
                    fontSize: "12px",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
    },
});

export default theme;

import * as React from "react";
import { Button, CircularProgress } from "@mui/material";

interface Props {
    isLoading: boolean;
    defaultText: string;
    loadingText: string;
}

function LoadingButtonPublic({ isLoading, defaultText, loadingText }: Props) {
    return (
        <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={isLoading && <CircularProgress size="24px" style={{ color: "white", marginRight: "10px" }} />}
            style={{
                marginTop: "15px",
                padding: "10px 0px",
                opacity: isLoading ? 0.3 : 1,
                cursor: isLoading ? "default" : "pointer",
            }}
            type="submit"
        >
            {isLoading ? loadingText : defaultText}
        </Button>
    );
}

export default LoadingButtonPublic;

import * as React from "react";
import MaintenanceTimeSelector from "./MaintenanceTimeSelector";
import styled from "styled-components";
import MaintenanceDaySelector from "./MaintenanceDaySelector";
import { IconButton } from "@mui/material";
import { AddBox, DeleteForever } from "@mui/icons-material";
import { MaintenanceSettings } from "./MaintenanceManagement";

interface Props {
    settings: MaintenanceSettings;
    changeTimeOfSlot: (idx: number, time: string) => void;
    changeDayOfSlot: (idx: number, day: string) => void;
    addEntry: () => void;
    removeEntry: (idx: number) => void;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 8px;
    padding-top: 10px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

const MoreWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
`;

export default function MaintenanceWindowFixedSlots({
    settings,
    changeTimeOfSlot,
    addEntry,
    removeEntry,
    changeDayOfSlot,
}: Props) {
    const slots = settings.slots!;

    return (
        <div style={{ width: "100%" }}>
            {slots.map((s, i) => (
                <Wrapper key={`slot-${i}`}>
                    <MaintenanceDaySelector onDayChange={(day: string) => changeDayOfSlot(i, day)} day={s.day} />
                    <MaintenanceTimeSelector onTimeChange={(time: string) => changeTimeOfSlot(i, time)} time={s.time} />
                    {slots.length > 1 && (
                        <IconButton style={{ fontSize: "22px", marginLeft: "16px" }} color="error">
                            <DeleteForever style={{ fontSize: "22px" }} onClick={() => removeEntry(i)} />
                        </IconButton>
                    )}
                </Wrapper>
            ))}
            <MoreWrapper>
                <IconButton color="primary" style={{ fontSize: "22px" }} onClick={addEntry}>
                    <AddBox style={{ fontSize: "22px" }} />
                </IconButton>
            </MoreWrapper>
        </div>
    );
}

import * as React from "react";
import styled from "styled-components";

interface Item {
    key: any;
    value: string;
}

interface Props {
    items: Item[];
    selectedItem: any;
    onSelect: (key: any) => void;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

type Position = "left" | "center" | "right";

const TabItem = styled.div.attrs((props: { position: Position; idx: number; selected?: boolean }) => ({
    position: props.position,
    idx: props.idx,
    selected: props.selected === true,
}))`
    padding: 6px 10px;
    min-width: 120px;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-bottom: ${(props) =>
        props.selected === true ? `2px solid ${props.theme.primary}` : `1px solid ${props.theme.lightGrayBottom}`};
    background-color: ${(props) => (props.selected === true ? props.theme.primaryLight : "white")};
    border-right-width: ${(props) => (props.position === "left" ? "0px" : "1px")};
    border-left-width: ${(props) => (props.position === "right" && props.idx !== 1 ? "0px" : "1px")};
    border-top-left-radius: ${(props) => (props.position === "left" ? "8px" : "0px")};
    border-bottom-left-radius: ${(props) => (props.position === "left" ? "8px" : "0px")};
    border-top-right-radius: ${(props) => (props.position === "right" ? "8px" : "0px")};
    border-bottom-right-radius: ${(props) => (props.position === "right" ? "8px" : "0px")};
`;

export default function TabSelector({ items, selectedItem, onSelect }: Props) {
    function getPosition(index: number): Position {
        if (items.length === 1) {
            return "center";
        }

        if (index === 0) {
            return "left";
        }

        if (index === items.length - 1) {
            return "right";
        }

        return "center";
    }

    return (
        <Wrapper>
            {items.map((item, i) => (
                <TabItem
                    position={getPosition(i)}
                    key={`tabselector-idx-${i}`}
                    idx={i}
                    selected={selectedItem === item.key}
                    onClick={() => onSelect(item.key)}
                >
                    {item.value}
                </TabItem>
            ))}
        </Wrapper>
    );
}

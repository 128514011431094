import * as React from "react";
import Loading from "../Loading/Loading";
import styled from "styled-components";
import { useQuery } from "react-apollo";
import DataItem from "../DataItem/DataItem";
import ModeBadge from "../ModeBadge/ModeBadge";
import { HESPlink } from "../Landing/SignUpComplete";
import { getStatusVisuals } from "../Channels/Overview/ChannelsOverview";
import gql from "graphql-tag";
import { ChannelAdminSearchQuery, ChannelAdminSearchQueryVariables } from "./__generated__/ChannelAdminSearchQuery";

export const CHANNEL_ADMIN_SEARCH_QUERY = gql`
    query ChannelAdminSearchQuery($channelId: String!) {
        channelAdminSearch(channelId: $channelId) {
            channelId
            isAlias
            name
            channelStatus
            channelMode
            organizationId
            organizationName
            region
            parentId
        }
    }
`;

interface Props {
    searchId: string;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    width: 100%;
`;

function QuickChannelSearchResult({ searchId }: Props) {
    const { data, loading, error } = useQuery<ChannelAdminSearchQuery, ChannelAdminSearchQueryVariables>(
        CHANNEL_ADMIN_SEARCH_QUERY,
        {
            variables: {
                channelId: searchId,
            },
        },
    );

    if (loading) {
        return (
            <Wrapper>
                <Loading />
            </Wrapper>
        );
    }

    if (!data || error) {
        return <Wrapper>No channel found</Wrapper>;
    }

    const { isAlias, name, organizationId, organizationName, channelStatus, channelMode, region, parentId } =
        data.channelAdminSearch;

    return (
        <div>
            <DataItem title="Name" value={`${name} ${isAlias ? "(alias)" : ""}`} />
            <DataItem title="Region" value={region} />
            <DataItem title="Status" value={<div>{getStatusVisuals(channelStatus)}</div>} />
            <DataItem title="Mode" value={<ModeBadge mode={channelMode} />} />
            <DataItem
                title="Organization"
                value={<HESPlink link={`/app/${organizationId}`}>{organizationName}</HESPlink>}
            />
            <DataItem
                title="Details"
                value={<HESPlink link={`/app/${organizationId}/channels/${parentId}`}>See more</HESPlink>}
            />
        </div>
    );
}

export default QuickChannelSearchResult;

import * as React from "react";

import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { Grid, FormControl, TextField, Button, Typography } from "@mui/material";
import QuickChannelSearchResult from "./QuickChannelSearchResult";
import { useForm } from "react-hook-form";

interface FormData {
    channelId: string;
}

function QuickChannelSearch() {
    const [searchId, setSearchId] = React.useState<string>("");

    const { register, errors, handleSubmit } = useForm<FormData>();

    const searchChannel = handleSubmit(async ({ channelId }: FormData) => {
        setSearchId(channelId.trim());
    });

    return (
        <CardHESP>
            <CardHESPTitle title="Quick channel search" />
            <form onSubmit={handleSubmit((data1, e) => searchChannel(e))}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={8}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                placeholder="Channel ID"
                                name="channelId"
                                inputRef={register({
                                    required: "Channel ID is required",
                                })}
                                inputProps={{
                                    style: {
                                        padding: "8px 10px",
                                    },
                                }}
                            />
                            <Typography variant="subtitle2" color="error">
                                {errors.channelId?.message ?? ""}
                            </Typography>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Search
                        </Button>
                    </Grid>
                    {searchId.trim() !== "" && (
                        <Grid item xs={12}>
                            <QuickChannelSearchResult searchId={searchId} />
                        </Grid>
                    )}
                </Grid>
            </form>
        </CardHESP>
    );
}

export default QuickChannelSearch;

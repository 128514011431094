/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "../../SectionTitle/SectionTitle";
import { GetOrganizationBasics_organization } from "../../GetStarted/__generated__/GetOrganizationBasics";
import SettingsMaintenanceWindowContent from "./SettingsMaintenanceWindowContent";

interface Props extends RouteComponentProps {
    organizationId?: string;
    organization: GetOrganizationBasics_organization;
}

// eslint-disable-next-line no-empty-pattern
export default function SettingsMaintenanceWindow({ organizationId, organization }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`Settings: maintenance windows`} align={"left"} showBottomBorder />
                <SettingsMaintenanceWindowContent organization={organization} />
            </Grid>
        </Grid>
    );
}

import { Grid } from "@mui/material";
import moment from "moment";
import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { GetChannelWithAllAliasesQuery_channel } from "../Channels/Overview/__generated__/GetChannelWithAllAliasesQuery";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import SectionTitle from "../SectionTitle/SectionTitle";
import ChannelUsageTranscoded from "./ChannelUsageTranscoded";
import ChannelUsageViewedWrapper from "./ChannelUsageViewedWrapper";
import { DatePicker } from "@mui/x-date-pickers";

interface Props {
    channel: GetChannelWithAllAliasesQuery_channel;
}

function ChannelUsageOverview({ channel }: Props) {
    const [startDate, setStartDate] = React.useState(moment().subtract(1, "month").startOf("day"));
    const [endDate, setEndDate] = React.useState(moment().endOf("day"));

    return (
        <>
            <SectionTitle
                title={`Usage overview channel ${channel.channelId} (${channel.metadata.name})`}
                align="left"
                showBottomBorder
            />
            <CardHESP>
                <CardHESPTitle title="Settings" />
                <Grid container style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Grid item xs={6} lg={3}>
                        <HESPFormLabel label="Start" />
                        <DatePicker
                            openTo="day"
                            format="YYYY/MM/DD"
                            views={["day"]}
                            value={startDate}
                            onChange={(newValue: moment.Moment | null) => {
                                if (newValue !== null) {
                                    setStartDate(newValue);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <HESPFormLabel label="End" />
                        <DatePicker
                            disableFuture
                            openTo="day"
                            format="YYYY/MM/DD"
                            views={["day"]}
                            value={endDate}
                            onChange={(newValue: moment.Moment | null) => {
                                if (newValue !== null) {
                                    setEndDate(newValue);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </CardHESP>
            <Grid container spacing={2} style={{ marginTop: "4px" }}>
                <Grid item xs={12}>
                    <CardHESP>
                        <ChannelUsageTranscoded channel={channel} startDate={startDate} endDate={endDate} />
                    </CardHESP>
                </Grid>
                <Grid item xs={12}>
                    <CardHESP>
                        <ChannelUsageViewedWrapper channel={channel} startDate={startDate} endDate={endDate} />
                    </CardHESP>
                </Grid>
            </Grid>
        </>
    );
}

export default ChannelUsageOverview;

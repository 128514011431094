import { Grid } from "@mui/material";
import { UsageType } from "../../__generated__/globalTypes";
import AdminUsageModes from "./AdminUsageModes";

interface Props {
    usageType: UsageType;
}

export default function AdminUsageAnalytics({ usageType }: Props) {
    return (
        <div style={{ marginTop: "22px" }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <AdminUsageModes usageType={usageType} />
                </Grid>
            </Grid>
        </div>
    );
}

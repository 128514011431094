import { RouteComponentProps } from "@reach/router";
import { Grid } from "@mui/material";
import * as React from "react";
import PersonalInfo from "../../components/Profile/PersonalInfo";

// eslint-disable-next-line no-unused-vars
function Profile(_props: RouteComponentProps) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12} sm={8}>
                <PersonalInfo />
            </Grid>
        </Grid>
    );
}

export default Profile;

import { Grid } from "@mui/material";
import * as React from "react";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChannelPlayoutSecurityGeoBlocking from "./ChannelPlayoutSecurityGeoBlocking";
import ChannelPlayoutTokenSecurity from "./ChannelPlayoutTokenSecurity";
import { ChannelStatusType, GeoBlockingModeEnum } from "../../../__generated__/globalTypes";

interface Props {
    channel: GetChannelQuery_channel;
    playoutId: string;
}

export interface PlayoutSecuritySettings {
    channelId: string;
    parentChannelId: string;
    isAlias: boolean;
    geoBlocking: {
        enabled: boolean;
        countries: string[];
        mode: GeoBlockingModeEnum;
    };
    jwt: {
        enabled: boolean;
        key: string;
    };
}

function ChannelPlayoutSecurity({ channel, playoutId }: Props) {
    const isAlias = channel.channelId !== playoutId;

    const publicationConfig = isAlias
        ? channel.aliases.find((a) => a.channelId === playoutId)!.publicationConfig
        : channel.publicationConfig;

    const securitySettings: PlayoutSecuritySettings = {
        parentChannelId: channel.channelId,
        channelId: playoutId,
        isAlias,
        geoBlocking: {
            enabled: publicationConfig?.geoBlocking?.enabled ?? false,
            countries: publicationConfig?.geoBlocking?.countries ?? [],
            mode: publicationConfig?.geoBlocking?.mode ?? GeoBlockingModeEnum.whitelist,
        },
        jwt: {
            enabled: publicationConfig?.tokenSecurity.enabled === true,
            key: "",
        },
    };

    const isMainChannelThatIsPlayingAndTLA =
        !isAlias && channel.tla?.enabled === true && channel.channelStatus !== ChannelStatusType.stopped;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ChannelPlayoutSecurityGeoBlocking
                    securitySettings={securitySettings}
                    organizationId={channel.organizationId}
                    disabled={isMainChannelThatIsPlayingAndTLA}
                />
            </Grid>
            {!(channel.tla?.enabled === true) && (
                <Grid item xs={12}>
                    <ChannelPlayoutTokenSecurity settings={securitySettings} organizationId={channel.organizationId} />
                </Grid>
            )}
        </Grid>
    );
}

export default ChannelPlayoutSecurity;

import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import CardHESP from "../CardHESP/CardHESP";
import CentralPageLoader from "../Loading/CentralPageLoader";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import NoWebhooks from "./NoWebhooks";
import WebhooksTable from "./WebhooksTable";
import { GetWebhooksOrganization, GetWebhooksOrganizationVariables } from "./__generated__/GetWebhooksOrganization";

export const GET_WEBHOOKS_ORGANIZATION = gql`
    query GetWebhooksOrganization($organizationId: ID!) {
        webhooks(organizationId: $organizationId) {
            webhookId
            name
            active
            url
            events
            organizationId
            isAdmin
        }
    }
`;

interface Props {
    organizationId: string;
}

function WebhooksOverview({ organizationId }: Props) {
    const { data, loading } = useQuery<GetWebhooksOrganization, GetWebhooksOrganizationVariables>(
        GET_WEBHOOKS_ORGANIZATION,
        {
            variables: {
                organizationId,
            },
        },
    );

    if (loading) {
        return <CentralPageLoader text="Getting webhooks" />;
    }

    if (!data) {
        return <div>An error occurred</div>;
    }

    function onCreateClick() {
        navigate(`/app/${organizationId}/webhooks/create`);
    }

    const { webhooks } = data;

    return (
        <>
            <SectionTitleWithAction
                title="Webhooks"
                action={
                    <Button variant="contained" color="primary" startIcon={<AddCircle />} onClick={onCreateClick}>
                        Create webhook
                    </Button>
                }
            />
            <CardHESP>
                {webhooks.length === 0 && <NoWebhooks onCreateClick={onCreateClick} />}
                {webhooks.length > 0 && <WebhooksTable organizationId={organizationId} webhooks={webhooks} />}
            </CardHESP>
        </>
    );
}

export default WebhooksOverview;

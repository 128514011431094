import { DialogActions, DialogContent, FormGroup, Grid, TextField, Typography } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";

import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { GET_DATACENTER } from "../../views/App/TLADatacenter";
import { GetDatacenter_datacenter_tla_deviceContexts } from "../../views/App/__generated__/GetDatacenter";
import {
    UpdateDeviceContextMutation,
    UpdateDeviceContextMutationVariables,
} from "./__generated__/UpdateDeviceContextMutation";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";

interface Props {
    open: boolean;
    datacenterId: string;
    deviceContext: GetDatacenter_datacenter_tla_deviceContexts;
    onClose: () => void;
}

export const UPDATE_TLA_DEVICE_CONTEXT = gql`
    mutation UpdateDeviceContextMutation($input: UpdateDeviceContextInput!) {
        updateDeviceContext(input: $input) {
            id
        }
    }
`;

export default function TLAUpdateDeviceContextDialog({ onClose, open, deviceContext, datacenterId }: Props) {
    const [updateDeviceContext, { loading }] = useMutation<
        UpdateDeviceContextMutation,
        UpdateDeviceContextMutationVariables
    >(UPDATE_TLA_DEVICE_CONTEXT);

    const [capacity, setCapacity] = useState<number>(deviceContext.capacity);
    const [firstSubmitDone, setFirstSubmitDone] = useState<boolean>(false);

    useEffect(() => {
        setCapacity(deviceContext.capacity);
    }, [open, deviceContext.capacity]);

    function notEmpty(val: string): boolean {
        if (val.trim().length === 0) {
            return false;
        }
        return true;
    }

    async function onConfirm() {
        setFirstSubmitDone(true);
        if (notEmpty(capacity.toString())) {
            try {
                await updateDeviceContext({
                    variables: {
                        input: {
                            capacity: parseInt(capacity.toString(), 10),
                            id: deviceContext.id,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_DATACENTER,
                            variables: {
                                id: datacenterId,
                            },
                        },
                    ],
                });
                onClose();
            } catch (e) {
                toast.error("Error while removing device context", TOAST_SETTINGS);
            }
        }
    }

    return (
        <DialogHESP
            title={"Update device context"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginTop: "14px" }}>
                                <FormGroup>
                                    <HESPFormLabel label="Capacity" />
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={capacity}
                                        placeholder="Capacity"
                                        onChange={(e: any) => setCapacity(e.target.value)}
                                    />
                                    {!notEmpty(capacity.toString()) && firstSubmitDone && (
                                        <Typography variant="subtitle2" color="error">
                                            Please enter a valid capacity
                                        </Typography>
                                    )}
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onConfirm}
                            defaultText={"Update"}
                            loadingText={"Updating..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

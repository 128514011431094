import styled from "styled-components";
import { GetDatacenter_datacenter_tla_deviceContexts } from "../../views/App/__generated__/GetDatacenter";
import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { DeleteForeverOutlined, EditOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px 14px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    align-items: center;
    width: 100%;
`;

const Main = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
`;

const Title = styled.div`
    font-size: 14px;
`;

const Description = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

const Details = styled.div`
    border-radius: 8px;
    background-color: ${(props) => props.theme.grayBackground};
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    font-size: 13px;
    padding: 10px 14px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const Group = styled.div`
    margin-bottom: 8px;
`;

interface Props {
    deviceContext: GetDatacenter_datacenter_tla_deviceContexts;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

export default function TLADatacenterDeviceContextsItem({
    deviceContext,
    onDeleteClick,
    onEditClick,
}: Props): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);

    const { capacity, description, id, labels, name, privateId, serial } = deviceContext;

    return (
        <Content>
            <Main onClick={() => setOpen(!open)}>
                <div>
                    <Title>{name}</Title>
                    <Description>{description}</Description>
                </div>
                <div>
                    <IconButton size="small" onClick={onEditClick}>
                        <EditOutlined fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={onDeleteClick}>
                        {<DeleteForeverOutlined fontSize="small" />}
                    </IconButton>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown color="primary" />}
                    </IconButton>
                </div>
            </Main>
            {open && (
                <Details>
                    <Group>
                        <strong>ID</strong>
                        <div>{id}</div>
                    </Group>
                    <Group>
                        <strong>Name</strong>
                        <div>{name}</div>
                    </Group>
                    <Group>
                        <strong>Description</strong>
                        <div>{description}</div>
                    </Group>
                    <Group>
                        <strong>Capacity</strong>
                        <div>{capacity}</div>
                    </Group>
                    <Group>
                        <strong>Serial</strong>
                        <div>{serial}</div>
                    </Group>
                    <Group>
                        <strong>Private ID</strong>
                        <div>{privateId}</div>
                    </Group>
                    <Group>
                        <strong>Labels</strong>
                        <ul style={{ margin: 0, padding: 0, paddingLeft: "28px" }}>
                            {labels.map((label, i) => (
                                <li key={`label-${i}`}>{label}</li>
                            ))}
                        </ul>
                    </Group>
                    <Group>
                        <strong>Grafana dashboards</strong>
                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", marginTop: "4px" }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    window.open(
                                        `https://grafana.eu-west-3.cuteanteater.com/d/pMEd7m0Mz/cadvisor-exporter?orgId=1&var-job=tla-node-metrics&var-instance=${id}`,
                                    )
                                }
                            >
                                Cadvisor exporter
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    window.open(
                                        `https://grafana.eu-west-3.cuteanteater.com/d/rYdddlPWk/full-node-exporter?orgId=1&refresh=1m&var-datasource=default&var-job=tla-node-metrics&var-node=${id}&var-diskdevices=%5Ba-z%5D%2B%7Cnvme%5B0-9%5D%2Bn%5B0-9%5D%2B%7Cmmcblk%5B0-9%5D%2B&from=now-5m&to=now`,
                                    )
                                }
                            >
                                Full node exporter
                            </Button>
                        </div>
                    </Group>
                </Details>
            )}
        </Content>
    );
}

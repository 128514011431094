import { Grid } from "@mui/material";
import { GetOrganizationChannelSettingsQuery_organization_channelSettings } from "../../../views/App/__generated__/GetOrganizationChannelSettingsQuery";
import ChannelSwitchConfigurator from "./ChannelSwitchConfigurator";

export type CreateChannelMiscellaneousType = "perpetual" | "autoStop";

interface Props {
    channelSettings: GetOrganizationChannelSettingsQuery_organization_channelSettings;
    values: {
        perpetual: boolean;
        autoStop: boolean;
    };
    onChangeBoolean: (type: CreateChannelMiscellaneousType) => void;
}

export default function CreateChannelMiscellaneousSettings({ channelSettings, values, onChangeBoolean }: Props) {
    const { perpetual } = channelSettings;

    return (
        <Grid container spacing={2}>
            {perpetual.enabled === true && (
                <Grid item xs={12} md={6}>
                    <ChannelSwitchConfigurator
                        enabled={values.perpetual}
                        onClick={() => onChangeBoolean("perpetual")}
                        title="24/7 channel"
                        description="Indicate if this channel will run 24/7. Pricing for this channel will be fixed based on your contract."
                    />
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <ChannelSwitchConfigurator
                    enabled={values.autoStop}
                    onClick={() => onChangeBoolean("autoStop")}
                    title="Auto-stop channel"
                    description="When enabled, a channel will stop automatically when no ingest has been received for one hour. It can help you save unnecessary costs."
                />
            </Grid>
        </Grid>
    );
}

import * as React from "react";
import { DeepMap, FieldError } from "react-hook-form";
import { CreateWebhookFormData } from "./CreateWebhookForm";
import styled from "styled-components";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";

interface Props {
    errors: DeepMap<CreateWebhookFormData, FieldError>;
    register: any;
    defaultValues: {
        name: string;
        description: string;
        url: string;
    };
}

export const CreateWebhookSectionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    // padding-bottom: 10px;
    // border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
    margin-bottom: 15px;
`;

function WebhookFormBasicInfo({ register, errors, defaultValues }: Props) {
    const { description, name, url } = defaultValues;
    return (
        <>
            <CreateWebhookSectionTitle>Basic information</CreateWebhookSectionTitle>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <HESPFormLabel label="Name" />
                        <TextField
                            variant="outlined"
                            fullWidth
                            defaultValue={name}
                            placeholder="Name of webhook"
                            name="name"
                            inputRef={register({
                                required: "Name is required",
                            })}
                        />
                    </FormControl>
                    <Typography variant="subtitle2" color="error">
                        {errors.name?.message ?? ""}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <HESPFormLabel label="Endpoint URL" />
                        <TextField
                            variant="outlined"
                            fullWidth
                            defaultValue={url}
                            placeholder="HTTPS endpoint URL"
                            name="url"
                            inputRef={register({
                                required: "URL with https is required",
                                pattern: {
                                    value: /^(https):\/\/[^ "]+$/,
                                    message: "No valid https URL",
                                },
                            })}
                        />
                    </FormControl>
                    <Typography variant="subtitle2" color="error">
                        {errors.url?.message ?? ""}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <HESPFormLabel label="Description" />
                        <TextField
                            variant="outlined"
                            multiline
                            defaultValue={description}
                            rows={2}
                            placeholder="Optional description"
                            fullWidth
                            name="description"
                            inputRef={register()}
                            inputProps={{
                                style: {
                                    padding: "0px",
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}

export default WebhookFormBasicInfo;

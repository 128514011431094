import * as React from "react";

import { useQuery } from "react-apollo";
import { GET_CHANNEL_ADMIN_PART } from "../Overview/channelQueriesMutations";
import ChannelAdminSettingsContent from "./ChannelAdminSettingsContent";
import Loading from "../../Loading/Loading";
import {
    GetChannelAdminPartQuery,
    GetChannelAdminPartQueryVariables,
} from "../Overview/__generated__/GetChannelAdminPartQuery";

interface Props {
    channelId: string;
    organizationId: string;

    onClose: () => void;
}

export default function ChannelAdminSettings({ onClose, channelId, organizationId }: Props) {
    const { data, loading } = useQuery<GetChannelAdminPartQuery, GetChannelAdminPartQueryVariables>(
        GET_CHANNEL_ADMIN_PART,
        {
            variables: {
                channelId,
                organizationId,
            },
        },
    );

    if (loading || !data) {
        return <Loading />;
    }

    return <ChannelAdminSettingsContent channel={data.channel} onClose={onClose} />;
}

import { Settings } from "@mui/icons-material";
import { Button, Drawer, Grid, Tooltip } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import { toast, ToastContainer } from "react-toastify";
import AuthService from "../../auth/AuthService";
import { TOAST_SETTINGS } from "../../components/BillingDetails/AddPaymentMethodDialog";
import Callout from "../../components/Callout/Callout";
import ChannelAdminSettings from "../../components/Channels/Admin/ChannelAdminSettings";
import ChannelAnalytics from "../../components/Channels/Details/ChannelAnalytics";
import ChannelIngestSettings from "../../components/Channels/Details/ChannelIngestSettings";
import ChannelMainDetails from "../../components/Channels/Details/ChannelMainDetails";
import ChannelPlayouts from "../../components/Channels/Details/ChannelPlayouts";
import {
    GET_CHANNELS_ORGANIZATION_QUERY,
    GET_CHANNEL_QUERY,
    START_CHANNEL_MUTATION,
    STOP_CHANNEL_MUTATION,
} from "../../components/Channels/Overview/channelQueriesMutations";
import {
    GetChannelQuery,
    GetChannelQueryVariables,
} from "../../components/Channels/Overview/__generated__/GetChannelQuery";
import {
    StartChannelMutation,
    StartChannelMutationVariables,
} from "../../components/Channels/Overview/__generated__/StartChannelMutation";
import {
    StopChannelMutation,
    StopChannelMutationVariables,
} from "../../components/Channels/Overview/__generated__/StopChannelMutation";
import { HESPlink } from "../../components/Landing/SignUpComplete";
import CentralPageLoader from "../../components/Loading/CentralPageLoader";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import SectionTitleWithAction from "../../components/SectionTitle/SectionTitleWithAction";
import { ChannelStatusType } from "../../__generated__/globalTypes";

interface Props extends RouteComponentProps {
    organizationId?: string;
    channelId?: string;
}

export const CHANNEL_POLLING_TIMES: Record<ChannelStatusType, number> = {
    creating: 5000,
    deleting: 5000,
    deploying: 5000,
    starting: 5000,
    ingesting: 5000,
    waiting: 5000,
    playing: 20000,
    stopping: 5000,
    stopped: 20000,
    deleted: 2000000,
    error: 10000,
    scheduled: 10000,
};

function PlayerDetailsV2({ channelId, organizationId }: Props) {
    const [openAdmin, setOpenAdmin] = React.useState<boolean>(false);

    const { data, loading, startPolling } = useQuery<GetChannelQuery, GetChannelQueryVariables>(GET_CHANNEL_QUERY, {
        variables: {
            channelId: channelId!,
            organizationId: organizationId!,
        },
    });

    const [startChannelMut, { loading: loadingStart }] = useMutation<
        StartChannelMutation,
        StartChannelMutationVariables
    >(START_CHANNEL_MUTATION);
    const [stopChannelMut, { loading: loadingStop }] = useMutation<StopChannelMutation, StopChannelMutationVariables>(
        STOP_CHANNEL_MUTATION,
    );

    if (loading) {
        return <CentralPageLoader text="Getting channel..." />;
    }

    if (!data) {
        return <div>Channel not found</div>;
    }

    const { channel } = data;

    if (channel.channelStatus === ChannelStatusType.deleted) {
        return <div>This channel got deleted</div>;
    }

    startPolling(CHANNEL_POLLING_TIMES[channel.channelStatus]);

    async function changeChannelStatus(setToStart: boolean) {
        if (setToStart) {
            try {
                await startChannelMut({
                    variables: {
                        input: {
                            channelId: channelId!,
                            organizationId: channel.organizationId,
                        },
                    },
                    refetchQueries: [
                        { query: GET_CHANNEL_QUERY, variables: { channelId, organizationId: channel.organizationId } },
                        {
                            query: GET_CHANNELS_ORGANIZATION_QUERY,
                            variables: {
                                organizationId: data!.channel.organizationId,
                            },
                        },
                    ],
                });
                startPolling(CHANNEL_POLLING_TIMES[channel.channelStatus]);
                toast.success(`Channel got started succesfully 🚀`, TOAST_SETTINGS);
            } catch (e: any) {
                onError(e);
            }
        } else {
            try {
                await stopChannelMut({
                    variables: {
                        input: {
                            channelId: channelId!,
                            organizationId: channel.organizationId,
                        },
                    },
                    refetchQueries: [
                        { query: GET_CHANNEL_QUERY, variables: { channelId, organizationId: channel.organizationId } },
                        {
                            query: GET_CHANNELS_ORGANIZATION_QUERY,
                            variables: {
                                organizationId: data!.channel.organizationId,
                            },
                        },
                    ],
                });
                toast.success(`Channel got stopped succesfully 🛑`, TOAST_SETTINGS);
            } catch (e: any) {
                onError(e);
            }
        }
    }

    function onError(e: any) {
        toast.error(e.graphQLErrors[0].message ?? "Something went wrong 😞", TOAST_SETTINGS);
    }

    const isTHEOAdmin = AuthService.isTHEOAdmin();

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {!isTHEOAdmin && (
                        <SectionTitle
                            title={
                                <div>
                                    {channel.metadata.name.length > 60 ? (
                                        <Tooltip title={<div>{channel.metadata.name}</div>}>
                                            <div>Channel {channel.metadata.name.substring(0, 60)}...</div>
                                        </Tooltip>
                                    ) : (
                                        `Channel ${channel.metadata.name}`
                                    )}
                                </div>
                            }
                            align="left"
                            showBottomBorder
                        />
                    )}
                    {isTHEOAdmin && (
                        <SectionTitleWithAction
                            title={
                                channel.metadata.name.length > 60 ? (
                                    <Tooltip
                                        title={channel.metadata.name}
                                        children={<>Channel {channel.metadata.name.substring(0, 60)}...</>}
                                    />
                                ) : (
                                    `Channel ${channel.metadata.name}`
                                )
                            }
                            action={
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    startIcon={<Settings />}
                                    onClick={() => setOpenAdmin(true)}
                                >
                                    Admin settings
                                </Button>
                            }
                        />
                    )}
                </Grid>
                {channel.channelMode === "demo" && (
                    <Grid item xs={12}>
                        <Callout
                            type="warning"
                            title="Channel in demo mode"
                            description={
                                <div>
                                    This is a free test channel. Activate your PAYG plan{" "}
                                    <HESPlink link={`/app/${channel.organizationId}/billing`}>here</HESPlink> for
                                    real-time streaming without watermark and the 5-min limit. $20 for free.
                                </div>
                            }
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={7}>
                            <ChannelMainDetails
                                channel={channel}
                                isLoading={loadingStart || loadingStop}
                                onChangeStatus={(starting: boolean) => changeChannelStatus(starting)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ChannelIngestSettings
                                        channel={channel}
                                        disabled={
                                            channel.channelStatus !== ChannelStatusType.stopped ||
                                            loadingStart ||
                                            loadingStop
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ChannelAnalytics channel={channel} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <ChannelPlayouts channel={channel} />
                </Grid>
            </Grid>
            {isTHEOAdmin && (
                <Drawer
                    anchor={"bottom"}
                    open={openAdmin}
                    onClose={() => setOpenAdmin(false)}
                    style={{ display: "flex", justifyContent: "center" }}
                    sx={{
                        "& .MuiDrawer-paper": {
                            maxWidth: "900px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            minHeight: "200px",
                            padding: "30px",
                        },
                    }}
                >
                    <ChannelAdminSettings
                        channelId={channel.channelId}
                        onClose={() => setOpenAdmin(false)}
                        organizationId={channel.organizationId}
                    />
                </Drawer>
            )}
            <ToastContainer />
        </>
    );
}

export default PlayerDetailsV2;

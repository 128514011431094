import { Grid } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { GetProfile_profile } from "../../../views/App/__generated__/GetProfile";
import { BitrateMode, ResolutionType } from "../../../__generated__/globalTypes";
import { GetProfiles_profiles } from "../../AdminConfig/Profiles/__generated__/GetProfiles";
import ChannelStreamConfiguratorPart from "./ChannelStreamConfiguratorPart";
import ChannelSwitchConfigurator from "./ChannelSwitchConfigurator";
import RequestDrmDialog from "../RequestDrmDialog";
import gql from "graphql-tag";
import ChannelSelectorConfigurator from "./ChannelSelectorConfigurator";

export type StreamConfigType = "bitrate" | "resolution" | "fps" | "abr" | "drm" | "bitratemode";

export const REQUEST_ORGANIZATION_DRM_ACCESS_MUTATION = gql`
    mutation RequestOrganizationDrmAccessMutation($input: RequestDrmAccessInput!) {
        requestDrmAccess(input: $input) {
            organizationId
        }
    }
`;

interface Props {
    profiles: GetProfile_profile[];
    abr: boolean;
    drm: boolean;
    bitrateMode: BitrateMode;
    allBitrates: number[];
    allResolutions: ResolutionType[];
    allFpsValues: number[];
    selectedBitrate: number;
    selectedResolution: ResolutionType;
    selectedFps: number;
    onChange: (type: StreamConfigType, value: string | number | ResolutionType | boolean | BitrateMode) => void;
    organizationId: string;
    hasAccessToDrm: boolean;
}

export default function ChannelStreamConfigurator({
    profiles,
    allBitrates,
    allFpsValues,
    allResolutions,
    selectedBitrate,
    selectedFps,
    abr,
    selectedResolution,
    onChange,
    drm,
    bitrateMode,
    hasAccessToDrm,
    organizationId,
}: Props) {
    const [openDrmDialog, setOpenDrmDialog] = React.useState<boolean>(false);

    function onBitrateChanged(newBitrate: number) {
        onChange("bitrate", newBitrate);
    }

    function onResolutionChanged(newResolution: ResolutionType) {
        onChange("resolution", newResolution);
    }

    function onFpsChanged(newFps: number) {
        onChange("fps", newFps);
    }

    function onAbrChange() {
        onChange("abr", !abr);
    }

    function onDrmChange() {
        if (!drm === true && !hasAccessToDrm) {
            setOpenDrmDialog(true);
        } else {
            onChange("drm", !drm);
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ChannelSwitchConfigurator
                        enabled={abr}
                        onClick={onAbrChange}
                        title={"ABR (Adaptive bitrate)"}
                        description={
                            "When enabled, multiple qualities will be provided to which the player will switch automatically based on the network conditions of your end user."
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <ChannelSelectorConfigurator
                        title={"Bitrate mode"}
                        description={"Choose wether VBR (Variable bitrate) or CBR (Constant bitrate) should be used"}
                        options={[
                            { key: "variable", value: "VBR" },
                            { key: "constant", value: "CBR" },
                        ]}
                        selectedKey={bitrateMode as string}
                        onClick={(key: string) => onChange("bitratemode", key as BitrateMode)}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "40px" }}>
                    <ChannelSwitchConfigurator
                        enabled={drm}
                        onClick={onDrmChange}
                        title={"DRM"}
                        description={
                            "When enabled, your content will be delivered using studio approved DRM mechanisms. Your content will be encrypted and users are restricted from copying or sharing your content."
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ChannelStreamConfiguratorPart
                        selectedValue={selectedBitrate}
                        type="bitrate"
                        title="Max bitrate"
                        values={allBitrates}
                        selectableValues={allBitrates}
                        unit={"Mbps"}
                        onClick={(val) => onBitrateChanged(val as number)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ChannelStreamConfiguratorPart
                        selectedValue={selectedResolution}
                        type="resolution"
                        title="Max resolution"
                        values={allResolutions}
                        unit={""}
                        selectableValues={getSelectableResolutions(profiles, selectedBitrate)}
                        onClick={(val) => onResolutionChanged(val as ResolutionType)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ChannelStreamConfiguratorPart
                        selectedValue={selectedFps}
                        type="fps"
                        title="Fps"
                        values={allFpsValues}
                        unit={"fps"}
                        selectableValues={getSelectableFpses(profiles, selectedBitrate, selectedResolution)}
                        onClick={(val) => onFpsChanged(val as number)}
                    />
                </Grid>
            </Grid>
            <RequestDrmDialog
                organizationId={organizationId}
                onClose={() => setOpenDrmDialog(false)}
                open={openDrmDialog}
            />
        </>
    );
}

export function getFpsValues(fpses: number[]): number[] {
    const fpsValues: number[] = [];

    if (fpses.includes(60)) {
        fpsValues.push(...[50, 59.94, 60]);
    }
    if (fpses.includes(30)) {
        fpsValues.push(...[25, 29.97, 30]);
    }

    return fpsValues.sort((a, b) => (a > b ? -1 : 1));
}

export function determineSelectedResolutionAfterBitrateChange(
    profiles: GetProfile_profile[],
    newBitrate: number,
    currentResolution?: ResolutionType,
): ResolutionType {
    const resolutions = getSelectableResolutions(profiles, newBitrate);
    if (currentResolution && resolutions.includes(currentResolution)) {
        return currentResolution;
    }
    return resolutions[0]!;
}

export function determineSelectedFpsAfterBitrateOrResolutionChange(
    profiles: GetProfile_profile[],
    newBitrate: number,
    newResolution: ResolutionType,
    currentFps?: number,
) {
    const fpses = getSelectableFpses(profiles, newBitrate, newResolution);
    const fpsValues = getFpsValues(fpses);

    if (currentFps && fpses.includes(currentFps)) {
        return currentFps;
    }

    return fpsValues[0];
}

function getSelectableResolutions(profiles: GetProfiles_profiles[], newBitrate: number): ResolutionType[] {
    return _.uniq(profiles.filter((p) => p.maxBitrate === newBitrate).map((p) => p.maxResolution));
}

function getSelectableFpses(
    profiles: GetProfiles_profiles[],
    newBitrate: number,
    newResolution: ResolutionType,
): number[] {
    const fpses = _.uniq(
        profiles.filter((p) => p.maxBitrate === newBitrate && p.maxResolution === newResolution).map((p) => p.maxFps),
    );

    return getFpsValues(fpses);
}

/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import { Grid } from "@mui/material";
import * as React from "react";
import GetStarted from "../../components/GetStarted/GetStarted";
import UsageSummary from "../../components/UsageSummary/UsageSummary";
import DevelopersZone from "../../components/DevelopersZone/DevelopersZone";
import AccountWarningMessage from "../../components/GetStarted/AccountWarningMessage";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function Dashboard({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <AccountWarningMessage organizationId={organizationId!} />
            <Grid item xs={12} md={6}>
                <GetStarted organizationId={organizationId!} />
            </Grid>
            <Grid item xs={12} md={6}>
                <UsageSummary organizationId={organizationId!} />
            </Grid>
            <Grid item xs={12} md={12}>
                <DevelopersZone organizationId={organizationId!} />
            </Grid>
        </Grid>
    );
}

export default Dashboard;

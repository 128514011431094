import * as React from "react";
import { ResponsiveContainer, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis, Area, Label } from "recharts";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_viewerLatency_bins } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import InsightsChartTooltip from "./InsightsChartTooltip";

interface Props {
    bins: GetChannelViewerInsightsQuery_channel_viewerInsights_viewerLatency_bins[];
}

function ViewerLatencyChart({ bins }: Props) {
    const totalAmount = bins.map((b) => b.amount).reduce((a, b) => a + b, 0);

    const convertedBins: { bin: number; amount: number }[] = bins.map(({ bin, amount }) => ({
        bin: bin / 2,
        amount: parseFloat(totalAmount > 0 ? ((amount / totalAmount) * 100).toFixed(2) : "0"),
    }));

    return (
        <div style={{ height: "200px" }}>
            <ResponsiveContainer width="100%" height="95%">
                <AreaChart
                    data={convertedBins}
                    style={{ fontSize: "13px" }}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 10,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<InsightsChartTooltip unit={"ms"} unitValue={"%"} />} />
                    <XAxis dataKey="bin">
                        <Label
                            value="Latency in ms"
                            position="insideBottomRight"
                            dy={8}
                            style={{ fill: "#a3a3a3" }}
                            fontSize={11}
                        />
                    </XAxis>
                    <YAxis allowDecimals={false} unit={"%"}>
                        <Label
                            value="% viewer sessions"
                            position="centerBottom"
                            textAnchor={"middle"}
                            dx={-15}
                            angle={-90}
                            fontSize={11}
                            style={{ fill: "#a3a3a3" }}
                        />
                    </YAxis>
                    <Area type="monotone" dataKey="amount" stroke="#FFC713" fill="#FFF3D4" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

export default ViewerLatencyChart;

import { FormControl, Grid, Slider } from "@mui/material";
import * as React from "react";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import styled from "styled-components";

interface Props {
    percentage: number;
    onChangePercentage: (percentage: number) => void;
}

const SliderWrapper = styled.div`
    max-width: 300px;
    margin-left: 10px;
`;

const Wrapper = styled.div`
    font-size: 12px;
    display: flex;
    color: #757575;
    justify-content: space-between;
`;

const Left = styled.div`
    width: 40%;
    text-align: left;
`;

const Right = styled.div`
    width: 40%;
    text-align: right;
`;

export default function ChannelAdminEnhancedLogging({ percentage, onChangePercentage }: Props) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <HESPFormLabel label={`Percentage: ${percentage}%`} />
                    <SliderWrapper>
                        <Slider
                            defaultValue={percentage}
                            color="primary"
                            min={0}
                            max={100}
                            onChange={(e: any) => onChangePercentage(parseInt(e.target.value))}
                        />
                        <Wrapper>
                            <Left>0%</Left>
                            <Right>100%</Right>
                        </Wrapper>
                    </SliderWrapper>
                </FormControl>
            </Grid>
        </Grid>
    );
}

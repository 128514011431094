import * as React from "react";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_concurrentViewerSessions } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import { ViewerInsightsIntervalType } from "../../__generated__/globalTypes";

import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import ViewerConcurrentSessionsChart from "./ViewerConcurrentSessionsChart";
import ViewerNumberWithTitle from "./ViewerNumberWithTitle";

interface Props {
    data?: GetChannelViewerInsightsQuery_channel_viewerInsights_concurrentViewerSessions;
    intervalType: ViewerInsightsIntervalType;
}

function ViewerConcurrentSessions({ data, intervalType }: Props) {
    const [initLoad, setInitLoad] = React.useState<boolean>(true);
    const [myData, setMyData] = React.useState<
        GetChannelViewerInsightsQuery_channel_viewerInsights_concurrentViewerSessions | undefined
    >(undefined);

    React.useEffect(() => {
        if (data) {
            if (!myData) {
                setInitLoad(false);
            }
            setMyData(data);
        }
    }, [initLoad, data, setMyData, myData]);

    const title = intervalType === ViewerInsightsIntervalType.minute ? "Active sessions now" : "Total in period";
    const subtitle =
        intervalType === ViewerInsightsIntervalType.minute
            ? "Concurrent sessions since start period"
            : "Unique sessions per day during period";
    const amount = initLoad
        ? 0
        : intervalType === ViewerInsightsIntervalType.minute
        ? Math.round(myData!.details[myData!.details.length - 1].amount)
        : myData!.total;

    return (
        <CardHESP style={{ height: "100%" }}>
            <CardHESPTitle title={"Viewer sessions"} subtitle={subtitle} />
            <ViewerNumberWithTitle amount={amount} title={title} />
            {!initLoad && <ViewerConcurrentSessionsChart details={myData!.details} intervalType={intervalType} />}
        </CardHESP>
    );
}

export default ViewerConcurrentSessions;

import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import ManageProfileForm from "../../components/AdminConfig/Profiles/ManageProfileForm";
import CardHESP from "../../components/CardHESP/CardHESP";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export default function CreateProfile({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <SectionTitle title={`Create a profile`} align="left" showBottomBorder />
                <CardHESP>
                    <ManageProfileForm organizationId={organizationId!} isCreate={true} />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

import gql from "graphql-tag";

export const GET_CHANNELS_ORGANIZATION_QUERY = gql`
    query GetChannelsOrganizationQuery($organizationId: ID!) {
        channels(organizationId: $organizationId) {
            channelId
            organizationId
            channelStatus
            domain
            createdAt
            metadata {
                name
            }
            lastStart
            lastStop
            regionId
            rtmpPullUrl
            ingestType
            channelMode
            perpetual
            regionId
            tla {
                enabled
                datacenter {
                    id
                    name
                }
            }
            engine {
                override {
                    enabled
                    version
                }
            }
            useOrganizationOrigin
        }
    }
`;

export const GET_CHANNEL_ADMIN_PART = gql`
    query GetChannelAdminPartQuery($channelId: ID!, $organizationId: ID!) {
        channel(channelId: $channelId, organizationId: $organizationId) {
            channelId
            organizationId
            ingestProtocol
            ingestPullUrl
            organization {
                organizationId
                tla {
                    activated
                    datacenters {
                        id
                        name
                    }
                }
                channelSettings {
                    engine {
                        override {
                            enabled
                            defaultVersion
                        }
                    }
                }
            }
            pricing {
                transcoded
                viewed
                drm
            }
            metadata {
                enhancedLogging {
                    enabled
                    percentage
                }
                insights {
                    enabled
                }
            }
            cdn {
                provider
                url
            }
            publicationConfig {
                originSecurity {
                    enabled
                }
            }
            tla {
                enabled
            }
            engine {
                override {
                    enabled
                    version
                }
            }
        }
    }
`;

export const GET_CHANNEL_QUERY = gql`
    query GetChannelQuery($channelId: ID!, $organizationId: ID!) {
        channel(channelId: $channelId, organizationId: $organizationId) {
            channelId
            channelStatus
            channelMode
            organizationId
            organization {
                organizationId
                name
                channelSettings {
                    hybridFallback {
                        enabled
                    }
                    perpetual {
                        enabled
                    }
                    drm {
                        enabled
                    }
                    engine {
                        override {
                            enabled
                            defaultVersion
                        }
                    }
                    domain {
                        override {
                            enabled
                            defaultDomain
                        }
                    }
                }
            }
            metadata {
                name
                customization {
                    colors {
                        primary
                        secondary
                        tertiary
                    }
                    announcement
                    posterImg
                    logo
                    autoplay
                    targetLatency
                }
            }
            region {
                regionId
                name
                awsRegion
            }
            tla {
                enabled
                datacenter {
                    id
                    name
                }
            }
            ingestType
            ingestProtocol
            ingestPullUrl
            streamConfig {
                abr
                fps
                resolution
                bitrate
                bitrateMode
                audioPid
                drm {
                    enabled
                    widevine {
                        licenseAcquisitionUrl
                    }
                    playready {
                        licenseAcquisitionUrl
                    }
                    fairplay {
                        licenseAcquisitionUrl
                        certificateUrl
                    }
                }
            }
            rtmpPushUrl
            rtmpPullUrl
            domain
            streamKey
            failoverId
            fallback {
                enabled
                src
                type
            }
            nextMaintenance
            maintenance {
                type
                slots {
                    day
                    time
                }
                interval {
                    period
                    amount
                    time
                }
            }
            lastStart
            lastStop
            lastErrorMessage
            hasNoStandardConfig
            manifestUrl
            analyzerWebsocketUrl
            useOrganizationOrigin
            perpetual
            ingestTimeout
            cdn {
                provider
                url
            }
            publicationConfig {
                geoBlocking {
                    enabled
                    countries
                    mode
                }
                tokenSecurity {
                    enabled
                }
                defaultDomain
            }
            schedulers {
                schedulerId
                name
                start
                end
                phase
            }
            currentRun {
                ingestTimeout
            }
            aliases {
                channelId
                active
                isDeleted
                failoverId
                fallback {
                    enabled
                    src
                    type
                }
                metadata {
                    name
                    customization {
                        colors {
                            primary
                            secondary
                            tertiary
                        }
                        announcement
                        posterImg
                        logo
                        autoplay
                        targetLatency
                    }
                }
                parentChannelId
                organizationId
                cdn {
                    provider
                    url
                }
                publicationConfig {
                    geoBlocking {
                        enabled
                        countries
                        mode
                    }
                    defaultDomain
                    tokenSecurity {
                        enabled
                    }
                }
            }
        }
    }
`;

export const GET_CHANNEL_WITH_ALL_ALIASES_QUERY = gql`
    query GetChannelWithAllAliasesQuery($channelId: ID!, $organizationId: ID!) {
        channel(channelId: $channelId, organizationId: $organizationId) {
            channelId
            channelStatus
            channelMode
            organizationId
            tla {
                enabled
                networkingConfig {
                    websocketUrl
                }
            }
            metadata {
                name
            }
            domain
            region {
                regionId
                awsRegion
            }
            aliases(showDeleted: true) {
                channelId
                active
                isDeleted
                metadata {
                    name
                }
                parentChannel {
                    channelId
                    metadata {
                        name
                    }
                }
            }
        }
    }
`;

export const START_CHANNEL_MUTATION = gql`
    mutation StartChannelMutation($input: StartChannelInput!) {
        startChannel(input: $input) {
            channelId
            channelStatus
        }
    }
`;

export const STOP_CHANNEL_MUTATION = gql`
    mutation StopChannelMutation($input: StopChannelInput!) {
        stopChannel(input: $input) {
            channelId
            channelStatus
        }
    }
`;

export const DELETE_CHANNEL_MUTATION = gql`
    mutation DeleteChannelMutation($input: DeleteChannelInput!) {
        deleteChannel(input: $input) {
            channelId
            channelStatus
        }
    }
`;

export const UPDATE_CHANNEL_MUTATION = gql`
    mutation UpdateChannelMutation($input: UpdateChannelInput!) {
        updateChannel(input: $input) {
            channelId
            metadata {
                name
                customization {
                    colors {
                        primary
                        secondary
                        tertiary
                    }
                }
            }
        }
    }
`;

export const UPDATE_STREAM_CONFIG_MUTATION = gql`
    mutation UpdateStreamConfigMutation($input: UpdateStreamConfigInput!) {
        updateStreamConfig(input: $input) {
            channelId
        }
    }
`;

export const CREATE_CHANNEL_MUTATION = gql`
    mutation CreateChannelMutation($input: CreateChannelInput!) {
        createChannel(input: $input) {
            channelId
        }
    }
`;

import { Button, Grid } from "@mui/material";
import * as React from "react";
import { CSVLink } from "react-csv";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_browsersAndOS } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import ViewerBrowserTable from "./ViewerBrowserTable";
import ViewerBrowserTableLoad from "./ViewerBrowserTableLoad";

interface Props {
    data?: GetChannelViewerInsightsQuery_channel_viewerInsights_browsersAndOS[];
}

function ViewerBrowserOverview({ data }: Props) {
    const [initLoad, setInitLoad] = React.useState<boolean>(true);
    const [myData, setMyData] = React.useState<
        GetChannelViewerInsightsQuery_channel_viewerInsights_browsersAndOS[] | undefined
    >(undefined);

    React.useEffect(() => {
        if (data) {
            if (!myData) {
                setInitLoad(false);
            }
            setMyData(data);
        }
    }, [initLoad, data, setMyData, myData]);

    const csvHeaders = ["browser", "os", "osVersion", "browserVersion", "amount"];
    const csvData = myData
        ? myData.map((item) => {
              return {
                  browser: item.browser,
                  os: item.os,
                  osVersion: item.osVersion,
                  browserVersion: item.browserVersion,
                  amount: item.amount,
              };
          })
        : [];

    return (
        <CardHESP>
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <CardHESPTitle
                        title="Top browser and operating systems"
                        subtitle="Total unique sessions since start period"
                        button={
                            myData && myData.length > 0 ? (
                                <CSVLink
                                    data={csvData}
                                    headers={csvHeaders}
                                    filename={"ViewerBrowsersAndOS.csv"}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button variant="contained" style={{ color: "black" }} color="inherit">
                                        Export as CSV
                                    </Button>
                                </CSVLink>
                            ) : (
                                <></>
                            )
                        }
                    />
                </Grid>
                {myData && myData?.length > 0 && <Grid item sx={{ position: "absolute", right: "2rem" }}></Grid>}
            </Grid>
            {initLoad ? <ViewerBrowserTableLoad /> : <ViewerBrowserTable data={myData!} />}
        </CardHESP>
    );
}
export default ViewerBrowserOverview;

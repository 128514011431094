import { Button, FormGroup, Grid } from "@mui/material";
import { GetDatacenter_datacenter } from "../../views/App/__generated__/GetDatacenter";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import DataItem from "../DataItem/DataItem";
import styled from "styled-components";
import { DeleteForever } from "@mui/icons-material";
import TLARemoveDatacenterDialog from "./TLARemoveDatacenterDialog";
import { useState } from "react";

interface Props {
    datacenter: GetDatacenter_datacenter;
}

const TLAContent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px;
    background-color: ${(props) => props.theme.grayBackground};
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 10px 16px;
`;

const Title = styled.h1`
    font-weight: bolder;
    font-size: 16px;
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

export default function TLADatacenterBasicDetails({ datacenter }: Props): JSX.Element {
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    return (
        <>
            <CardHESP>
                <CardHESPTitle
                    title="Basic details"
                    button={
                        <Button
                            startIcon={<DeleteForever />}
                            color="error"
                            disabled={datacenter.channels.length > 0}
                            onClick={() => setOpenDialog(true)}
                        >
                            Remove
                        </Button>
                    }
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <DataItem title="ID" value={datacenter.id} />
                        </FormGroup>
                        <FormGroup>
                            <DataItem title="Name" value={datacenter.name} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TLAContent>
                            <Title>TLA environment info</Title>
                            <FormGroup>
                                <DataItem title="ID" value={datacenter.tla.id} />
                            </FormGroup>
                            <FormGroup>
                                <DataItem title="Name" value={datacenter.tla.name} />
                            </FormGroup>
                            <FormGroup>
                                <DataItem title="Description" value={datacenter.tla.description} />
                            </FormGroup>
                            <FormGroup>
                                <DataItem title="Domain" value={datacenter.tla.domain} />
                            </FormGroup>
                            <FormGroup>
                                <DataItem title="Host ID" value={datacenter.tla.hostId} />
                            </FormGroup>
                        </TLAContent>
                    </Grid>
                </Grid>
            </CardHESP>
            <TLARemoveDatacenterDialog
                datacenterId={datacenter.id}
                name={datacenter.name}
                onClose={() => setOpenDialog(false)}
                open={openDialog}
            />
        </>
    );
}

import { Button } from "@mui/material";
import * as React from "react";
import AuthService from "../../auth/AuthService";
import DataItem from "../DataItem/DataItem";

interface Props {
    onChangePassword: () => void;
}

function PersonalInfoData({ onChangePassword }: Props) {
    const userProfile = AuthService.getLocalUserProfile();

    return (
        <>
            <DataItem title="First name" value={showValue(userProfile?.firstName)} />
            <DataItem title="Last name" value={showValue(userProfile?.lastName)} />
            <DataItem title="Phone number" value={showValue(userProfile?.phone)} />
            {!userProfile?.auth0Id.includes("google") && (
                <DataItem
                    title="Password"
                    value={
                        <Button variant="outlined" size="small" color="secondary" onClick={onChangePassword}>
                            Change password
                        </Button>
                    }
                />
            )}
        </>
    );
}

function showValue(value: string | null | undefined): string {
    return value ? value : "Unknown";
}

export default PersonalInfoData;

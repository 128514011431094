import styled from "styled-components";
import * as React from "react";
import { Grid } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { DatePicker } from "@mui/x-date-pickers";

interface Props {
    range: AdminUsagAnalyticsRange;
    startDate: moment.Moment;
    endDate: moment.Moment;
    setStartDate: (date: moment.Moment) => void;
    setEndDate: (data: moment.Moment) => void;
}
export type AdminUsagAnalyticsRange = "daily" | "monthly";

const Wrapper = styled.div`
    display: flex;
    margin-top: 10px;
`;

export default function AdminUsageAnalyticsSettings({ range, startDate, endDate, setEndDate, setStartDate }: Props) {
    return (
        <Wrapper>
            <Grid item xs={6} lg={3}>
                <HESPFormLabel label="Start" />
                <DatePicker
                    openTo={range === "daily" ? "day" : "month"}
                    format={range === "daily" ? "YYYY/MM/DD" : "MMM YYYY"}
                    views={range === "daily" ? ["day"] : ["month", "year"]}
                    value={startDate}
                    onChange={(newValue: moment.Moment | null) => {
                        if (newValue !== null) {
                            setStartDate(newValue);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <HESPFormLabel label="End" />
                <DatePicker
                    openTo={range === "daily" ? "day" : "month"}
                    format={range === "daily" ? "YYYY/MM/DD" : "MMM YYYY"}
                    views={range === "daily" ? ["day"] : ["month"]}
                    value={endDate}
                    onChange={(newValue: moment.Moment | null) => {
                        if (newValue !== null) {
                            setEndDate(newValue);
                        }
                    }}
                />
            </Grid>
        </Wrapper>
    );
}

import { Redirect, RouteComponentProps } from "@reach/router";
import * as React from "react";
import { useApolloClient } from "react-apollo";
import AuthService from "../../auth/AuthService";

function LogoutPage(props: RouteComponentProps) {
    const client = useApolloClient();
    AuthService.logout();
    client.stop();
    client.clearStore();

    return <Redirect to="/landing" />;
}

export default LogoutPage;

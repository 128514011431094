import * as React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { DialogContent, Grid, Typography, FormControl, TextField, DialogActions } from "@mui/material";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
    ChangeOrganizationNameMutation,
    ChangeOrganizationNameMutationVariables,
} from "./__generated__/ChangeOrganizationNameMutation";
import DialogHESP from "../DialogHESP/DialogHESP";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { GET_FULL_BILLING_DETAILS } from "../BillingDetails/BillingDetailsCardContent";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const CHANGE_ORGANIZATION_NAME_MUTATION = gql`
    mutation ChangeOrganizationNameMutation($input: ChangeOrganizationNameInput!) {
        changeOrganizationName(input: $input) {
            organizationId
        }
    }
`;

interface Props {
    open: boolean;
    organizationId: string;
    currentName: string;
    onClose: () => void;
}

interface FormData {
    name: string;
}

function ChangeOrganizationNameDialog({ open, organizationId, currentName, onClose }: Props) {
    const { handleSubmit, register, errors } = useForm<FormData>();

    const [changeOrganizationNameMut, { loading }] = useMutation<
        ChangeOrganizationNameMutation,
        ChangeOrganizationNameMutationVariables
    >(CHANGE_ORGANIZATION_NAME_MUTATION);

    const editChannel = handleSubmit(async ({ name }: FormData) => {
        try {
            await changeOrganizationNameMut({
                variables: { input: { organizationId, newName: name } },
                refetchQueries: [
                    {
                        query: GET_FULL_BILLING_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Organization name changed", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    });

    return (
        <DialogHESP
            title={"Change name"}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit((data1, e) => editChannel(e))}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Name" />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={currentName}
                                        name="name"
                                        inputRef={register({
                                            required: "Name is required",
                                        })}
                                    />
                                    <Typography variant="subtitle2" color="error">
                                        {errors.name && errors.name.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default ChangeOrganizationNameDialog;

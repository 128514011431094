import { CircularProgress } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    text?: string;
    size?: "small" | "normal";
    color?: string;
}

const Text = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.gray};
    margin-left: 10px;
`;

function Loading({ text, size, color }: Props) {
    function getSize(): number {
        if (!size || size === "normal") {
            return 32;
        }
        return 22;
    }
    return (
        <div>
            <CircularProgress size={getSize()} style={{ color }} />
            {text && <Text>{text}</Text>}
        </div>
    );
}

export default Loading;

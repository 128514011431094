import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { ResponsiveContainer, LineChart, CartesianGrid, Tooltip, XAxis, YAxis, Line } from "recharts";
import { ColorGenerator } from "../Usage/ColorGenerator";
import CustomTooltip from "../Usage/CustomTooltip";
import { chartContainerStyle } from "../Usage/UsageGraph";

import { UsageItem } from "./ChannelUsageViewedSettings";
import {
    GetViewingMinutesPerAlias_getViewedMinutesPerChannelAlias,
    GetViewingMinutesPerAlias_getViewedMinutesPerChannelAlias_records,
} from "./__generated__/GetViewingMinutesPerAlias";

interface Props {
    viewingData: GetViewingMinutesPerAlias_getViewedMinutesPerChannelAlias[];
    selectedItems: UsageItem[];
    showTotal: boolean;
    showName: boolean;
}

function ChannelUsageViewedChart({ viewingData, selectedItems, showTotal, showName }: Props) {
    const records = showTotal
        ? calculateTotalsForViewedMinutesForChannel(viewingData.map((v) => v.records))
        : dividePerAlias(viewingData, selectedItems, showName);
    const colorGenerator = new ColorGenerator();

    const renderChart = (data: object[], colorGenerator: ColorGenerator) => {
        if (data.length <= 0) {
            return null;
        }

        if (showTotal) {
            const color = colorGenerator.getColor();
            return (
                <Line
                    key={"total"}
                    type="monotone"
                    dataKey={"total"}
                    stroke={color}
                    strokeWidth={2}
                    activeDot={{ r: 8 }}
                />
            );
        } else {
            const REMOVE_KEYS = ["timestamp"];
            const removeKeys = (obj: any) => Object.keys(obj).filter((item) => REMOVE_KEYS.indexOf(item) < 0);

            return removeKeys(data[0]).map((key) => {
                const color = colorGenerator.getColor();
                return (
                    <Line key={key} type="monotone" dataKey={key} stroke={color} strokeWidth={2} activeDot={{ r: 8 }} />
                );
            });
        }
    };

    return (
        <div style={{ height: "250px" }}>
            <ResponsiveContainer height="90%" width="100%">
                <LineChart
                    data={records}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 30,
                        bottom: 1 - 0,
                    }}
                    style={chartContainerStyle()}
                >
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis dataKey="timestamp" name={"Day"} minTickGap={10} />
                    <YAxis minTickGap={10} />

                    {/* <Legend
                verticalAlign="bottom"
                iconType="circle"
                iconSize={12}
                wrapperStyle={{
                    fontSize: 12,
                }}
            /> */}
                    {renderChart(records, colorGenerator)}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export function calculateTotalsForViewedMinutesForChannel(
    records: GetViewingMinutesPerAlias_getViewedMinutesPerChannelAlias_records[][],
) {
    const flatted = _.flatMap(records);
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const recordsWithTz = flatted.map((record) => ({
        timestamp: moment(record.timestamp).tz(tz).format("DD/MM"),
        amount: record.amount,
    }));

    const grouped = _.chain(recordsWithTz)
        .groupBy("timestamp")
        .map((value, key) => ({
            timestamp: key,
            total: value.map((v) => v.amount).reduce((a, b) => a + b, 0),
        }))
        .value();

    return _.orderBy(grouped, ["time"], ["asc"]);
}

function dividePerAlias(
    data: GetViewingMinutesPerAlias_getViewedMinutesPerChannelAlias[],
    selectedItems: UsageItem[],
    showName: boolean,
) {
    const selectedIds = selectedItems.map((i) => i.id);

    const filteredAliases = data.filter((alias) => selectedIds.includes(alias.id));
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const recordsWithTz = filteredAliases.map((alias) => {
        return alias.records.map((record) => {
            return {
                aliasId: alias.id,
                timestamp: moment(record.timestamp).tz(tz).format("YYYY-MM-DD"),
                amount: record.amount,
            };
        });
    });

    const flatted: {
        aliasId: string;
        timestamp: string;
        amount: number;
    }[] = _.flatMap(recordsWithTz);

    const groupedByAlias = _.chain(flatted)
        .groupBy("aliasId")
        .map((value, key1) => ({
            aliasId: key1,
            records: _.chain(value)
                .groupBy("timestamp")
                .map((value, key2) => ({
                    timestamp: key2,
                    aliasId: key1,
                    total: value.map((v) => v.amount).reduce((a, b) => a + b, 0),
                }))
                .value(),
        }))
        .value();

    const flat2 = _.flatMap(groupedByAlias.map((c) => c.records));

    const groupedByDate = _.chain(flat2)
        .groupBy("timestamp")
        .map((value, key) => ({
            timestamp: key,
            aliases: value.map((v) => ({
                aliasId: v.aliasId,
                amount: v.total,
            })),
        }))
        .value();

    const sorted = _.orderBy(groupedByDate, ["time"], ["asc"]);

    const chartData = sorted.map((entry) => {
        let object: {
            [key: string]: any;
        } = {};
        object["timestamp"] = moment(entry.timestamp).format("DD/MM");

        entry.aliases.map((alias) => {
            const item = selectedItems.find((s) => s.id === alias.aliasId)!;
            return (object[!showName ? item.id : item.name] = alias.amount);
        });

        return object;
    });

    return chartData;
}

export default ChannelUsageViewedChart;

import { IconButton, Tooltip } from "@mui/material";
import { Check, Edit, Clear, AddAPhoto, DeleteForever } from "@mui/icons-material";
import * as React from "react";
import { ProperyChangeType } from "./customizationHelpers";
import CustomizeItem from "./CustomizeItem";
import UploadImageDialog from "../UploadImageDialog/UploadImageDialog";

interface Props {
    currentAnnouncement: string;
    currentPosterImg: string;
    onCustomizationChange: (type: ProperyChangeType, key: string, value: string) => void;
}

function ChannelCustomizerPreLiveSettings({ currentAnnouncement, currentPosterImg, onCustomizationChange }: Props) {
    const [editAnnouncement, setEditAnnouncement] = React.useState<boolean>(false);
    const [choosePoster, setChoosePoster] = React.useState<boolean>(false);
    const [announcement, setAnnouncement] = React.useState<string>(currentAnnouncement);

    React.useEffect(() => {
        setAnnouncement(currentAnnouncement);
    }, [currentAnnouncement]);

    function onSaveAnnouncement(): void {
        onCustomizationChange("prelive-settings", "announcement", announcement);
        setEditAnnouncement(false);
    }

    function onDiscardAnnouncementChanges(): void {
        setEditAnnouncement(false);
        setAnnouncement(currentAnnouncement);
    }

    function onRemovePoster(): void {
        onCustomizationChange("prelive-settings", "posterImg", "");
        setChoosePoster(false);
    }

    function setNewImageUrl(url: string): void {
        onCustomizationChange("prelive-settings", "posterImg", url);
    }

    return (
        <>
            <CustomizeItem
                title={"Announcement"}
                description={"Message when the stream isn't active yet"}
                currentValue={announcement}
                isEditable={editAnnouncement}
                type="text"
                onValueChange={(text: string | number) => typeof text === "string" && setAnnouncement(text)}
                onDoubleClick={() => setEditAnnouncement(true)}
                customizationActions={
                    <>
                        {!editAnnouncement && (
                            <>
                                <Tooltip title="Edit">
                                    <IconButton size="small" onClick={() => setEditAnnouncement(true)}>
                                        <Edit fontSize="small" style={{ color: "black" }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {editAnnouncement && (
                            <>
                                <Tooltip title="Save">
                                    <IconButton size="small" onClick={onSaveAnnouncement}>
                                        <Check fontSize="small" style={{ color: "black" }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Discard">
                                    <IconButton size="small" onClick={onDiscardAnnouncementChanges}>
                                        <Clear fontSize="small" style={{ color: "black" }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </>
                }
            />
            <CustomizeItem
                title={"Poster image"}
                description={"Background image when the stream isn't active yet"}
                currentValue={currentPosterImg === "" ? "No image set" : currentPosterImg}
                type="text"
                onDoubleClick={() => setChoosePoster(true)}
                customizationActions={
                    <>
                        <Tooltip title="Choose">
                            <IconButton size="small" onClick={() => setChoosePoster(true)}>
                                <AddAPhoto fontSize="small" style={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove">
                            <IconButton size="small" onClick={onRemovePoster}>
                                <DeleteForever fontSize="small" style={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            />
            <UploadImageDialog
                open={choosePoster}
                onClose={() => setChoosePoster(false)}
                title={"Upload a new poster image"}
                currentUrl={currentPosterImg}
                onUploadComplete={(url: string) => setNewImageUrl(url)}
            />
        </>
    );
}

export default ChannelCustomizerPreLiveSettings;

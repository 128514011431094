import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import styled from "styled-components";
import Badge from "../Badge/Badge";
import { Tooltip } from "@mui/material";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";

interface Props {
    id: string;
    name: string;
    organizationsConnected: number;
}

const Wrapper = styled.div`
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    padding: 6px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const Right = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const Title = styled.div`
    font-weight: bolder;
    font-size: 14px;
`;

const Description = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 12px;
`;

export default function TLADatacenterItem({ id, name, organizationsConnected }: Props): JSX.Element {
    return (
        <Wrapper onClick={() => navigate(`/app/${THEO_ID}/tla/data-centers/${id}`)}>
            <Left>
                <Title>{id}</Title>
                <Description>{name}</Description>
            </Left>
            <Right>
                <div>
                    <Tooltip title="Organizations using this data center">
                        <div>
                            <Badge type="warning">{organizationsConnected}</Badge>
                        </div>
                    </Tooltip>
                </div>
                <div style={{ marginTop: "4px" }}>
                    <KeyboardDoubleArrowRight color="primary" />
                </div>
            </Right>
        </Wrapper>
    );
}

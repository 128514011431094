interface Props {
    icon: React.ReactNode;
}

function GradientIcon({ icon }: Props) {
    return (
        <>
            <svg width={0} height={0}>
                <linearGradient id="linearColors" x1="0%" y1="80%">
                    <stop offset={0} stopColor="#344a5e" />
                    <stop offset={1} stopColor="#344a5e" />
                </linearGradient>
            </svg>
            {icon}
        </>
    );
}

export default GradientIcon;

import React from "react";
import { GetFullBillingDetails_organization_billingDetails } from "./__generated__/GetFullBillingDetails";
import { DeepMap, FieldError } from "react-hook-form";
import { ManageBillingDetailsFormData } from "./BillingDetails";
import { Grid, FormControl, TextField, Typography, MenuItem, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { getData } from "country-list";
import { CancelLink } from "../Profile/PersonalInfoEdit";

interface Props {
    details: GetFullBillingDetails_organization_billingDetails | null;
    errors: DeepMap<ManageBillingDetailsFormData, FieldError>;
    selectedCountry: string;
    onChangeCountry: (event: any) => void;
    isIndividual: boolean;
    onIsIndividualChange: () => void;
    register: any;
    organizationName: string;
    onCancel: () => void;
}
export const sanctionedCountryList = ["LR", "BY", "CU", "ZW", "SY", "KP", "CI", "IQ", "SD", "MM", "IR", "CD"];

function BillingDetailsManage({
    organizationName,
    details,
    errors,
    register,
    onCancel,
    selectedCountry,
    onChangeCountry,
    isIndividual,
    onIsIndividualChange,
}: Props) {
    const countries = getData()
        .filter((country) => !sanctionedCountryList.includes(country.code))
        .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });

    const chosenCountry = details?.country ?? "";
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup
                        value={isIndividual ? "individual" : "company"}
                        onChange={(e) => onIsIndividualChange()}
                    >
                        <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                        <FormControlLabel value="company" control={<Radio />} label="Company" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Company name" />
                    <TextField
                        variant="outlined"
                        disabled={isIndividual}
                        style={{ backgroundColor: isIndividual ? "#f0f0f0" : "white" }}
                        fullWidth
                        defaultValue={
                            isIndividual
                                ? "Individual"
                                : organizationName.includes("Account created by")
                                ? ""
                                : organizationName
                        }
                        name="organizationName"
                        inputRef={register({
                            ...(!isIndividual && { required: "Organization name is required" }),
                        })}
                    />
                </FormControl>
                <Typography variant="subtitle2" color="error">
                    {!isIndividual && errors.organizationName?.message ? errors.organizationName?.message : ""}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Country" />
                    <TextField
                        select
                        name="country"
                        onChange={onChangeCountry}
                        variant="outlined"
                        value={selectedCountry === "" ? chosenCountry : selectedCountry}
                        inputRef={() =>
                            register(
                                { name: "country" },
                                {
                                    validate: (value: any) => {
                                        return selectedCountry || chosenCountry !== "" ? true : "Country is required";
                                    },
                                },
                            )
                        }
                    >
                        <MenuItem value={""} disabled>
                            <em>Select a country</em>
                        </MenuItem>
                        {countries.map((c) => {
                            return (
                                <MenuItem
                                    value={c.code.toUpperCase()}
                                    key={c.code}
                                    selected={chosenCountry === c.code.toUpperCase()}
                                >
                                    {c.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
                <Typography variant="subtitle2" color="error">
                    {errors.country && errors.country.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Address" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={details?.street ?? ""}
                        name="street"
                        inputRef={register({
                            required: "Address is required",
                        })}
                    />
                    <Typography variant="subtitle2" color="error">
                        {errors.street?.message ?? ""}
                    </Typography>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                    <HESPFormLabel label="ZIP" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={details?.zip ?? ""}
                        name="zip"
                        inputRef={register({
                            required: "ZIP is required",
                        })}
                    />
                    <Typography variant="subtitle2" color="error">
                        {errors.zip?.message ?? ""}
                    </Typography>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="City" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={details?.city ?? ""}
                        name="city"
                        inputRef={register({
                            required: "City is required",
                        })}
                    />
                    <Typography variant="subtitle2" color="error">
                        {errors.city?.message ?? ""}
                    </Typography>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="State" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={details?.state ?? ""}
                        name="state"
                        inputRef={register({
                            required: "State is required",
                        })}
                    />
                    <Typography variant="subtitle2" color="error">
                        {errors.state?.message ?? ""}
                    </Typography>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Primary Email" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={details?.email ?? ""}
                        name="email"
                        inputRef={register({
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i,
                                message: "invalid email address",
                            },
                        })}
                    />
                </FormControl>
                <Typography variant="subtitle2" color="error">
                    {errors.email?.message ?? ""}
                </Typography>
            </Grid>
            {(vatCountries.includes(selectedCountry) || vatCountries.includes(chosenCountry)) && (
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <HESPFormLabel label="VAT number" />
                        <TextField
                            variant="outlined"
                            fullWidth
                            defaultValue={details?.vat ?? ""}
                            name="vat"
                            disabled={isIndividual}
                            style={{ backgroundColor: isIndividual ? "#f0f0f0" : "white" }}
                            inputRef={register({
                                ...(!isIndividual &&
                                    (vatCountries.includes(selectedCountry) ||
                                        vatCountries.includes(chosenCountry)) && {
                                        required: "VAT number is required",
                                    }),
                            })}
                        />
                        <Typography variant="subtitle2" color="error">
                            {!isIndividual && errors.vat?.message ? errors.vat?.message : ""}
                        </Typography>
                    </FormControl>
                </Grid>
            )}

            <Grid item xs={12} style={{ textAlign: "right" }}>
                <CancelLink
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                    }}
                >
                    Cancel
                </CancelLink>
            </Grid>
        </Grid>
    );
}

export default BillingDetailsManage;

export const vatCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SK",
    "SI",
];

export const euCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SK",
    "SI",
];

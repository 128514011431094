import { Check } from "@mui/icons-material";
import {
    Button,
    ButtonGroup,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import * as React from "react";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { CdnType } from "../../../__generated__/globalTypes";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { GET_CHANNEL_QUERY, UPDATE_CHANNEL_MUTATION } from "../Overview/channelQueriesMutations";
import { UpdateChannelMutation, UpdateChannelMutationVariables } from "../Overview/__generated__/UpdateChannelMutation";
import ChannelPlayoutCardNoSwitch from "./ChannelPlayoutCardNoSwitch";
import { UPDATE_CHANNEL_ALIAS_MUTATION } from "./ChannelUpdateAliasDialog";
import {
    UpdateChannelAliasMutation,
    UpdateChannelAliasMutationVariables,
} from "./__generated__/UpdateChannelAliasMutation";
import { GetChannelQuery_channel_organization } from "../Overview/__generated__/GetChannelQuery";

interface Props {
    settings: PlayoutCdnSettings;
    organization: GetChannelQuery_channel_organization;
}

export interface PlayoutCdnSettings {
    parentChannelId: string;
    channelId: string;
    isAlias: boolean;
    cdn: {
        provider: CdnType;
        url: string;
        defaultDomain: string;
    };
}

export default function ChannelPlayoutAdminCdn({ settings, organization }: Props) {
    const { channelId, parentChannelId, isAlias } = settings;
    const { organizationId } = organization;
    const initialConfig = settings.cdn;

    const [selectedProvider, setSelectedProvider] = React.useState<CdnType>(initialConfig.provider);
    const [url, setUrl] = React.useState<string>(initialConfig.url);
    const [domain, setDomain] = React.useState<string>(initialConfig.url);

    React.useEffect(() => {
        setSelectedProvider(initialConfig.provider);
        setUrl(initialConfig.url);
        setDomain(initialConfig.defaultDomain);
        setNeedsSave(false);
    }, [initialConfig]);

    const [needsSave, setNeedsSave] = React.useState<boolean>(false);

    const [updateChannelCdnMut, { loading: loadingChannel }] = useMutation<
        UpdateChannelMutation,
        UpdateChannelMutationVariables
    >(UPDATE_CHANNEL_MUTATION);
    const [updateChannelAliasCdnMut, { loading: loadingChannelAlias }] = useMutation<
        UpdateChannelAliasMutation,
        UpdateChannelAliasMutationVariables
    >(UPDATE_CHANNEL_ALIAS_MUTATION);

    function onChangeProvider(prov: CdnType) {
        setNeedsSave(true);
        setSelectedProvider(prov);
    }

    function onChangeUrl(value: string) {
        setNeedsSave(true);
        setUrl(value);
    }

    function onChangeDomain(value: string) {
        setNeedsSave(true);
        setDomain(value);
    }

    function onDiscard() {
        setNeedsSave(false);
        setSelectedProvider(initialConfig.provider);
        setUrl(initialConfig.url);
        setDomain(initialConfig.defaultDomain);
    }

    async function onSave() {
        if (
            (selectedProvider === CdnType.fastly || (selectedProvider === CdnType.akamai && url.trim() !== "")) &&
            endWithChar()
        ) {
            try {
                if (isAlias) {
                    await updateChannelAliasCdnMut({
                        variables: {
                            input: {
                                channelAliasId: channelId,
                                organizationId: organization.organizationId,
                                channelId: settings.parentChannelId,
                                publicationUpdates: {
                                    cdnUpdates: {
                                        provider: selectedProvider,
                                        url: selectedProvider === CdnType.fastly ? "" : url,
                                        defaultDomain: domain.trim(),
                                    },
                                },
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_CHANNEL_QUERY,
                                variables: {
                                    channelId: parentChannelId,
                                    organizationId,
                                },
                            },
                        ],
                    });
                } else {
                    await updateChannelCdnMut({
                        variables: {
                            input: {
                                channelId,
                                organizationId,
                                publicationUpdates: {
                                    cdnUpdates: {
                                        provider: selectedProvider,
                                        url: selectedProvider === CdnType.fastly ? "" : url,
                                        defaultDomain: domain.trim(),
                                    },
                                },
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_CHANNEL_QUERY,
                                variables: {
                                    channelId: parentChannelId,
                                    organizationId,
                                },
                            },
                        ],
                    });
                }
                setNeedsSave(false);
                toast.success("CDN settings successfully updated", TOAST_SETTINGS);
            } catch (_e) {
                toast.error("Something went wrong", TOAST_SETTINGS);
            }
        }
    }

    const chars = [
        "a",
        "b",
        "c",
        "d",
        "e,",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
    ];

    function endWithChar() {
        if (domain.length > 0) {
            const last = domain.charAt(domain.length - 1);
            return chars.includes(last.toLowerCase());
        }
        return true;
    }

    return (
        <ChannelPlayoutCardNoSwitch
            isLoading={loadingChannel || loadingChannelAlias}
            needsSave={needsSave}
            onDiscardClick={onDiscard}
            onSaveClick={onSave}
            title="CDN settings"
            subtitle="Choose the CDN provider and pass a URL when necessary"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <HESPFormLabel label={`Provider`} />
                        <ButtonGroup color="secondary" size="small">
                            <Button
                                variant={selectedProvider === CdnType.fastly ? "contained" : "outlined"}
                                onClick={() => onChangeProvider(CdnType.fastly)}
                                startIcon={selectedProvider === CdnType.fastly && <Check style={{ color: "white" }} />}
                                size="small"
                            >
                                Fastly
                            </Button>
                            <Button
                                variant={selectedProvider === CdnType.akamai ? "contained" : "outlined"}
                                onClick={() => onChangeProvider(CdnType.akamai)}
                                startIcon={selectedProvider === CdnType.akamai && <Check style={{ color: "white" }} />}
                                size="small"
                            >
                                Akamai
                            </Button>
                        </ButtonGroup>
                    </FormControl>
                </Grid>
                {selectedProvider === CdnType.akamai && (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <HESPFormLabel label={`URL`} />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={url}
                                inputProps={{
                                    style: {
                                        padding: "8px 10px",
                                        background: "white",
                                    },
                                }}
                                size="small"
                                onChange={(e) => onChangeUrl(e.target.value)}
                            />
                            {url.trim() === "" && (
                                <Typography variant="body2" color="error">
                                    Please provide a CDN URL
                                </Typography>
                            )}
                        </FormControl>
                    </Grid>
                )}
                {organization.channelSettings.domain.override?.enabled === true && (
                    <Grid item xs={12}>
                        <FormGroup>
                            <HESPFormLabel
                                label="Override default domain"
                                description={`${
                                    organization.channelSettings.domain.override?.enabled === true
                                        ? `Globally set to ${organization.channelSettings.domain.override?.defaultDomain}. `
                                        : ""
                                } Leave empty to not override.`}
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder="Default domain to use. Leave empty to not override"
                                value={domain}
                                inputProps={{
                                    style: {
                                        padding: "8px 10px",
                                        background: "white",
                                    },
                                }}
                                size="small"
                                onChange={(e) => onChangeDomain(e.target.value)}
                            />
                            {!endWithChar() && <FormHelperText color="">Should end with a letter</FormHelperText>}
                        </FormGroup>
                    </Grid>
                )}
            </Grid>
        </ChannelPlayoutCardNoSwitch>
    );
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Collection of all possible analytics range types
 */
export enum AnalyticsRangeType {
  daily = "daily",
  monthly = "monthly",
}

/**
 * Collection of all possible auth cred types
 */
export enum AuthCredTypeEnum {
  auto = "auto",
  maintenance = "maintenance",
  scheduler = "scheduler",
  token = "token",
  user = "user",
}

/**
 * Collection of all possible bitrate modes a channel can have
 */
export enum BitrateMode {
  constant = "constant",
  variable = "variable",
}

/**
 * Collection of all possible CDN types
 */
export enum CdnType {
  akamai = "akamai",
  fastly = "fastly",
}

/**
 * Collection of all possible types a changelog can have
 */
export enum ChangelogType {
  added = "added",
  deprecated = "deprecated",
  fixed = "fixed",
  improved = "improved",
  removed = "removed",
}

/**
 * Collection of all possible modes a channel can have
 */
export enum ChannelModeType {
  demo = "demo",
  locked = "locked",
  production = "production",
  promotional = "promotional",
}

/**
 * Collection of all possible statuses a channel can have
 */
export enum ChannelStatusType {
  creating = "creating",
  deleted = "deleted",
  deleting = "deleting",
  deploying = "deploying",
  error = "error",
  ingesting = "ingesting",
  playing = "playing",
  scheduled = "scheduled",
  starting = "starting",
  stopped = "stopped",
  stopping = "stopping",
  waiting = "waiting",
}

/**
 * Collection of all possible contract types an organization can have
 */
export enum ContractType {
  commitment = "commitment",
  payg = "payg",
}

/**
 * Collection of all possible subsets
 */
export enum EventSubsetType {
  action = "action",
  log = "log",
}

/**
 * Collection of all possible events
 */
export enum EventType {
  alias_created = "alias_created",
  alias_deleted = "alias_deleted",
  alias_deleting = "alias_deleting",
  alias_disabled = "alias_disabled",
  alias_enabled = "alias_enabled",
  alias_updated = "alias_updated",
  all = "all",
  channel_created = "channel_created",
  channel_creating = "channel_creating",
  channel_deleted = "channel_deleted",
  channel_deleting = "channel_deleting",
  channel_deploying = "channel_deploying",
  channel_error = "channel_error",
  channel_ingesting = "channel_ingesting",
  channel_log_error = "channel_log_error",
  channel_log_info = "channel_log_info",
  channel_log_warn = "channel_log_warn",
  channel_playing = "channel_playing",
  channel_scheduled = "channel_scheduled",
  channel_starting = "channel_starting",
  channel_stopped = "channel_stopped",
  channel_stopping = "channel_stopping",
  channel_updated = "channel_updated",
  channel_waiting = "channel_waiting",
  scheduler_active = "scheduler_active",
  scheduler_created = "scheduler_created",
  scheduler_deleted = "scheduler_deleted",
  scheduler_starting = "scheduler_starting",
  scheduler_terminated = "scheduler_terminated",
  scheduler_updated = "scheduler_updated",
  webhook_created = "webhook_created",
  webhook_deleted = "webhook_deleted",
  webhook_disabled = "webhook_disabled",
  webhook_enabled = "webhook_enabled",
  webhook_updated = "webhook_updated",
}

/**
 * Collection of all possible fallback types a channel can have
 */
export enum FallbackType {
  dash = "dash",
  hls = "hls",
  theo_live = "theo_live",
}

/**
 * Collection of all possible modes that can be assigned to geoblocking
 */
export enum GeoBlockingModeEnum {
  blacklist = "blacklist",
  whitelist = "whitelist",
}

/**
 * Collection of all ingest protocols THEOlive supports
 */
export enum IngestProtocol {
  rtmp = "rtmp",
  sdi = "sdi",
  srt = "srt",
}

/**
 * Collection of all possible ingest types
 */
export enum IngestType {
  pull = "pull",
  push = "push",
}

/**
 * Collection of all possible statuses an invoice can have
 */
export enum InvoiceStatusType {
  deleted = "deleted",
  draft = "draft",
  open = "open",
  paid = "paid",
  uncollectible = "uncollectible",
  void = "void",
}

/**
 * Collection of all types a recurring maintenance can have
 */
export enum MaintenanceRecurringPeriodType {
  days = "days",
  month = "month",
  weeks = "weeks",
}

/**
 * Collection of all types a maintenance can have
 */
export enum MaintenanceType {
  recurring = "recurring",
  scheduled = "scheduled",
}

/**
 * Collection of possible order directions
 */
export enum OrderDirectionType {
  asc = "asc",
  desc = "desc",
}

/**
 * Collection of all possible statuses an organization can have
 */
export enum OrganizationStatusType {
  demo = "demo",
  locked = "locked",
  production = "production",
}

/**
 * Collection of all possible overage types a contract can have
 */
export enum OverageType {
  monthly = "monthly",
  yearly = "yearly",
}

/**
 * Collection of all possible resolutions
 */
export enum ResolutionType {
  r_1080p = "r_1080p",
  r_240p = "r_240p",
  r_360p = "r_360p",
  r_576p = "r_576p",
  r_720p = "r_720p",
}

/**
 * Collection of all possible phases a scheduler can have
 */
export enum SchedulerPhaseType {
  active = "active",
  deleted = "deleted",
  pending = "pending",
  starting = "starting",
  terminated = "terminated",
}

/**
 * Collection of all possible types a report can have
 */
export enum SchedulerReportType {
  available = "available",
  generating = "generating",
  unavailable = "unavailable",
}

/**
 * Collection of all possible usage types
 */
export enum UsageType {
  transcoded = "transcoded",
  viewed = "viewed",
}

/**
 * Collection of all possible roles a user can have
 */
export enum UserRoleType {
  hesp_admin = "hesp_admin",
  hesp_theo_admin = "hesp_theo_admin",
  hesp_theo_admin_sales = "hesp_theo_admin_sales",
  hesp_user = "hesp_user",
}

export enum ViewerInsightsIntervalType {
  day = "day",
  hour = "hour",
  minute = "minute",
}

/**
 * Collection of all possible viewing source types
 */
export enum ViewingSourceType {
  fallback__dash = "fallback__dash",
  fallback__hls = "fallback__hls",
  fallback__theo_live = "fallback__theo_live",
  primary = "primary",
}

export interface AbrEntryInput {
  resolution: string;
  bitRate: number;
}

export interface AbrLadderInput {
  entries: AbrEntryInput[];
}

export interface ActivateAccountInput {
  code: string;
  email: string;
  password: string;
}

export interface AddDatacenterToOrganizationInput {
  organizationId: string;
  datacenterId: string;
}

export interface AddPaymentMethodInput {
  organizationId: string;
  paymentMethodId: string;
}

export interface AddUserInput {
  email: string;
  role: UserRoleType;
  organizationId: string;
}

export interface ChangeChannelModeInput {
  channelId: string;
  channelMode: ChannelModeType;
}

export interface ChangeIngestTypeInput {
  channelId: string;
  organizationId: string;
  ingestType: IngestType;
  ingestProtocol: IngestProtocol;
  audioPid?: string | null;
  ingestPullUrl?: string | null;
}

export interface ChangeOrganizationNameInput {
  organizationId: string;
  newName: string;
}

export interface ChangeOrganizationStatusInput {
  organizationId: string;
  newStatus: OrganizationStatusType;
}

export interface ChangeOrganizationTypeInput {
  isIndividual: boolean;
  organizationId: string;
}

export interface ChangePasswordInput {
  password: string;
}

export interface ChannelCustomizationColorsInput {
  primary?: string | null;
  secondary?: string | null;
  tertiary?: string | null;
}

export interface ChannelCustomizationInput {
  targetLatency?: number | null;
  posterImg?: string | null;
  logo?: string | null;
  announcement?: string | null;
  colors?: ChannelCustomizationColorsInput | null;
  autoplay?: boolean | null;
}

export interface ChannelEngineInput {
  override: ChannelEngineOverrideInput;
}

export interface ChannelEngineOverrideInput {
  enabled: boolean;
  version: string;
}

export interface ChannelMetadataEnhancedLoggingInput {
  enabled: boolean;
  percentage: number;
}

export interface ChannelMetadataInsightsInput {
  enabled: boolean;
}

export interface ChannelPricingInput {
  enabled: boolean;
  transcoded: number;
  viewed: number;
  drm: number;
}

export interface ChannelStreamConfigInput {
  abr: boolean;
  drm: boolean;
  fps: number;
  bitrate: number;
  resolution: ResolutionType;
  bitrateMode: BitrateMode;
}

export interface ConvertChannelToTLAInput {
  channelId: string;
  organizationId: string;
  ingestPullUrl: string;
  ingestProtocol: IngestProtocol;
  datacenterId: string;
}

export interface CopyAliasConfigInput {
  copyFromId: string;
  copyFromChannelId: string;
  organizationId: string;
  fromIsAlias: boolean;
  copyToId: string;
  copyToChannelId: string;
  toIsAlias: boolean;
}

export interface CreateChannelAliasInput {
  channelId: string;
  organizationId: string;
  name: string;
}

export interface CreateChannelInput {
  organizationId: string;
  ingestLocation?: string | null;
  datacenterId?: string | null;
  ingestProtocol?: IngestProtocol | null;
  ingestPullUrl?: string | null;
  metadata: CreateChannelMetadataInput;
  streamConfig: ChannelStreamConfigInput;
  ingestTimeout: number;
  perpetual?: boolean | null;
}

export interface CreateChannelMetadataInput {
  name: string;
}

export interface CreateContractInput {
  organizationId: string;
  start: string;
  commitment: number;
  billAutomatically: boolean;
  billOveragesAutomatically: boolean;
  durationInMonths: number;
  overageType: OverageType;
  billInGb: boolean;
  hasAccessToSD: boolean;
  gbUsedPerMinute: number;
}

export interface CreateDatacenterInput {
  id: string;
  name: string;
  tlaName: string;
  tlaDescription: string;
  tlaDomain: string;
  tlaHostId: string;
}

export interface CreateDeviceContextForDatacenterInput {
  id: string;
  name: string;
  description: string;
  serial: string;
  privateId: string;
  capacity: number;
  labels: string[];
}

export interface CreateOrganizationInput {
  isIndividual: boolean;
  name: string;
  primaryEmail: string;
  contactEmail: string;
}

export interface CreateProfileInput {
  maxBitrate: number;
  organizationId: string;
  maxResolution: string;
  maxFps: number;
  publiclyAvailable: boolean;
  transcodedPricePerMinute: number;
  viewedPricePerMinute: number;
  abrLadder: AbrLadderInput;
}

export interface CreateSchedulerInput {
  organizationId: string;
  name: string;
  startNow: boolean;
  start: string;
  end?: string | null;
  clientId: string;
  metadata: KeyValuePairInput[];
  channelIds: string[];
  recurring: SchedulerRecurringInput;
}

export interface CreateTokenInput {
  organizationId: string;
  name: string;
}

export interface CreateWebhookInput {
  organizationId: string;
  name: string;
  description?: string | null;
  url: string;
  events: string[];
  isAdmin?: boolean | null;
}

export interface DeleteChannelAliasInput {
  channelAliasId: string;
  channelId: string;
  organizationId: string;
}

export interface DeleteChannelInput {
  channelId: string;
  organizationId: string;
}

export interface DeleteDeviceContextInput {
  id: string;
}

export interface DeleteOrganizationInput {
  organizationId: string;
}

export interface DeleteSchedulerInput {
  schedulerId: string;
  organizationId: string;
}

export interface DeleteUserInput {
  auth0Id: string;
}

export interface DeleteWebhookInput {
  webhookId: string;
  organizationId: string;
}

export interface DisableChannelAliasInput {
  channelAliasId: string;
  channelId: string;
  organizationId: string;
}

export interface EnableChannelAliasInput {
  channelAliasId: string;
  channelId: string;
  organizationId: string;
}

export interface EnablePaygInput {
  organizationId: string;
}

export interface KeyValuePairInput {
  key: string;
  value: string;
}

export interface MaintenanceIntervalInput {
  period: MaintenanceRecurringPeriodType;
  amount: number;
  time: string;
}

export interface MaintenanceSlotInput {
  day: string;
  time: string;
}

export interface ManageBillingDetailsInput {
  organizationId: string;
  isIndividual: boolean;
  email: string;
  name: string;
  street: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  vat: string;
}

export interface ManageChannelTokenSecurityInput {
  organizationId: string;
  channelId: string;
  parentChannelId: string;
  isAlias: boolean;
  enable: boolean;
  key: string;
}

export interface OriginSecurityInput {
  enabled: boolean;
}

export interface RegisterWithEmailInput {
  email: string;
  password: string;
}

export interface RemoveDatacenterInput {
  datacenterId: string;
}

export interface RemoveTLADatacenterFromOrganizationInput {
  organizationId: string;
  datacenterId: string;
}

export interface RequestChangePasswordInput {
  email: string;
}

export interface RequestDrmAccessInput {
  organizationId: string;
}

export interface ResendActivationLinkInput {
  auth0Id: string;
}

export interface RevokeTokenInput {
  tokenKey: string;
}

export interface SchedulerRecurringInput {
  enabled: boolean;
  days: number[];
  tz?: string | null;
}

export interface StartChannelInput {
  channelId: string;
  organizationId: string;
}

export interface StartSchedulerInput {
  schedulerId: string;
  organizationId: string;
}

export interface StopChannelInput {
  channelId: string;
  organizationId: string;
}

export interface TerminateSchedulerInput {
  schedulerId: string;
  organizationId: string;
}

export interface UpdateAliasFallbackInput {
  enabled: boolean;
  channelId: string;
  channelAliasId: string;
  organizationId: string;
  type?: string | null;
  src?: string | null;
}

export interface UpdateChannelAdminSettingsInput {
  channelId: string;
  organizationId: string;
  enhancedLogging: ChannelMetadataEnhancedLoggingInput;
  insights: ChannelMetadataInsightsInput;
  originSecurity: OriginSecurityInput;
  pricing: ChannelPricingInput;
  engine: ChannelEngineInput;
}

export interface UpdateChannelAliasInput {
  channelAliasId: string;
  channelId: string;
  organizationId: string;
  name?: string | null;
  customization?: ChannelCustomizationInput | null;
  publicationUpdates?: UpdatePublicationConfigInput | null;
}

export interface UpdateChannelInput {
  channelId: string;
  organizationId: string;
  metadata?: UpdateChannelMetadataInput | null;
  publicationUpdates?: UpdatePublicationConfigInput | null;
  perpetual?: boolean | null;
  ingestTimeout?: number | null;
}

export interface UpdateChannelMaintenanceInput {
  channelId: string;
  organizationId: string;
  isCustom: boolean;
  maintenance?: UpdateMaintenanceInput | null;
}

export interface UpdateChannelMetadataInput {
  name?: string | null;
  customization?: ChannelCustomizationInput | null;
}

export interface UpdateContractInput {
  organizationId: string;
  contractId: string;
  billInGb?: boolean | null;
  hasAccessToSD?: boolean | null;
  gbUsedPerMinute?: number | null;
}

export interface UpdateDeviceContextInput {
  id: string;
  capacity: number;
}

export interface UpdateFallbackInput {
  enabled: boolean;
  channelId: string;
  organizationId: string;
  type?: string | null;
  src?: string | null;
}

export interface UpdateIngestPullUrlInput {
  channelId: string;
  organizationId: string;
  ingestPullUrl: string;
}

export interface UpdateMaintenanceInput {
  type: MaintenanceType;
  slots?: MaintenanceSlotInput[] | null;
  interval?: MaintenanceIntervalInput | null;
}

export interface UpdateOrganizationChannelSettingsDomainInput {
  override: UpdateOrganizationChannelSettingsDomainOverrideInput;
}

export interface UpdateOrganizationChannelSettingsDomainOverrideInput {
  enabled: boolean;
  defaultDomain: string;
}

export interface UpdateOrganizationChannelSettingsDrmInput {
  enabled: boolean;
}

export interface UpdateOrganizationChannelSettingsEngineInput {
  override: UpdateOrganizationChannelSettingsEngineOverrideInput;
}

export interface UpdateOrganizationChannelSettingsEngineOverrideInput {
  enabled: boolean;
  defaultVersion: string;
}

export interface UpdateOrganizationChannelSettingsHybridFallbackInput {
  enabled: boolean;
}

export interface UpdateOrganizationChannelSettingsInput {
  organizationId: string;
  hybridFallback: UpdateOrganizationChannelSettingsHybridFallbackInput;
  perpetual: UpdateOrganizationChannelSettingsPerpetualInput;
  drm: UpdateOrganizationChannelSettingsDrmInput;
  engine: UpdateOrganizationChannelSettingsEngineInput;
  domain: UpdateOrganizationChannelSettingsDomainInput;
}

export interface UpdateOrganizationChannelSettingsPerpetualInput {
  enabled: boolean;
}

export interface UpdateOrganizationMaintenanceInput {
  organizationId: string;
  maintenance: UpdateMaintenanceInput;
}

export interface UpdateOrganizationTLASettingsInput {
  organizationId: string;
  activated: boolean;
}

export interface UpdatePaygPricingInput {
  organizationId: string;
  transcodingCost: number;
  viewingCost: number;
}

export interface UpdateProfileInput {
  id: string;
  maxBitrate: number;
  maxResolution: string;
  maxFps: number;
  publiclyAvailable: boolean;
  transcodedPricePerMinute: number;
  viewedPricePerMinute: number;
  abrLadder: AbrLadderInput;
}

export interface UpdatePublicationCdnInput {
  provider: CdnType;
  url: string;
  defaultDomain: string;
}

export interface UpdatePublicationConfigInput {
  geoblockingUpdates?: UpdatePublicationGeoblockingInput | null;
  cdnUpdates?: UpdatePublicationCdnInput | null;
}

export interface UpdatePublicationGeoblockingInput {
  enabled: boolean;
  countries: string[];
  mode: GeoBlockingModeEnum;
}

export interface UpdateSchedulerInput {
  schedulerId: string;
  organizationId: string;
  name: string;
  start?: string | null;
  end?: string | null;
  clientId: string;
  metadata: KeyValuePairInput[];
  channelIds: string[];
  recurring: SchedulerRecurringInput;
}

export interface UpdateSchedulerReportInput {
  schedulerId: string;
  organizationId: string;
  start: string;
  end: string;
}

export interface UpdateStreamConfigInput {
  channelId: string;
  organizationId: string;
  fps: number;
  bitrate: number;
  resolution: ResolutionType;
  abr: boolean;
  drm: boolean;
  bitrateMode: BitrateMode;
}

export interface UpdateUserInput {
  auth0Id: string;
  role: UserRoleType;
}

export interface UpdateUserProfileInput {
  firstName: string;
  lastName: string;
  phone: string;
}

export interface UpdateWebhookInput {
  webhookId: string;
  organizationId: string;
  name?: string | null;
  description?: string | null;
  url?: string | null;
  events?: string[] | null;
  active?: boolean | null;
}

export interface VerifiedAccountInput {
  email: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

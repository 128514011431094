import { FormControl, Grid, TextField } from "@mui/material";
import * as React from "react";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { ProfileObject } from "./ManageProfileForm";

interface Props {
    profile: ProfileObject;
    onTranscodingPriceChange: (newValue: number) => void;
    onViewingPriceChange: (newValue: number) => void;
}

export default function ManageProfilePricingForm({ profile, onTranscodingPriceChange, onViewingPriceChange }: Props) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Transcoding price per minute" />
                    <TextField
                        fullWidth
                        type="number"
                        size="small"
                        onChange={(e) => onTranscodingPriceChange(parseFloat(e.target.value))}
                        variant="outlined"
                        value={profile.pricing.transcoded}
                        InputProps={{ inputProps: { min: 0.0, max: 10.0, step: 0.00001 } }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Viewing price per minute" />
                    <TextField
                        fullWidth
                        type="number"
                        size="small"
                        onChange={(e) => onViewingPriceChange(parseFloat(e.target.value))}
                        variant="outlined"
                        value={profile.pricing.viewed}
                        InputProps={{ inputProps: { min: 0.0, max: 10.0, step: 0.00001 } }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

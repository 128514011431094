import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

const TextValueWrapper = styled.div`
    cursor: pointer;
`;

export interface CustomizeItemDropdownValue {
    key: string | number;
    value: string | number;
}

interface Props {
    selectedValue: string | number;
    isEditable: boolean;
    onValueChange: (val: string | number) => void;
    onDoubleClick: () => void;
    items: CustomizeItemDropdownValue[];
}

function CustomizeItemDropdown({ selectedValue, isEditable, onValueChange, onDoubleClick, items }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 30px 8px 10px",
            fontSize: "14px",
            minWidth: "80px",
            width: "100%",
        },
    };

    const CssTextField = styled(TextField)(smallCss);

    return (
        <TextValueWrapper>
            <CssTextField
                select
                onChange={(e: any) => onValueChange(e.target.value)}
                variant="outlined"
                value={selectedValue}
                fullWidth
                onDoubleClick={onDoubleClick}
                disabled={!isEditable}
            >
                {items.map(({ key, value }: CustomizeItemDropdownValue) => (
                    <MenuItem value={key} key={key} selected={key === selectedValue} style={{ fontSize: "14px" }}>
                        {value}
                    </MenuItem>
                ))}
            </CssTextField>
        </TextValueWrapper>
    );
}

export default CustomizeItemDropdown;

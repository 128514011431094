import { Add, DeleteForever } from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    MenuItem,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    styled as materialStyled,
    TableBody,
} from "@mui/material";
import * as React from "react";
import { AbrEntry } from "./ManageProfileForm";

interface Props {
    maxResolution: string;
    maxBitrate: number;
    entries: AbrEntry[];
    onAddEntry: () => void;
    onChangeBitrate: (newValue: number, index: number) => void;
    onChangeResolution: (newValue: string, index: number) => void;
    onRemoveEntry: (index: number) => void;
}

const availableResolutions = ["1080p", "720p", "576p", "360p", "240p"];

export default function AbrLadderConstruction({
    maxBitrate,
    maxResolution,
    entries,
    onAddEntry,
    onChangeBitrate,
    onChangeResolution,
    onRemoveEntry,
}: Props) {
    const resolutionOptions = availableResolutions.slice(availableResolutions.findIndex((a) => a === maxResolution));

    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "10px 10px 10px 10px",
            fontSize: "14px",
            minWidth: "80px",
            // width: "80px",
        },
    };
    const CssTextField = materialStyled(TextField)(smallCss);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resolution</TableCell>
                            <TableCell>Bitrate in Mbps</TableCell>
                            <TableCell style={{ width: "80px" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entries.map((entry, i) => (
                            <TableRow key={`abrEntry-${i}`}>
                                <TableCell>
                                    <CssTextField
                                        fullWidth
                                        select
                                        key={`res-entry-${i}`}
                                        size="small"
                                        onChange={(e) => onChangeResolution(e.target.value, i)}
                                        variant="outlined"
                                        value={entry.resolution}
                                    >
                                        {resolutionOptions.map((r, j) => (
                                            <MenuItem value={r} key={`abrr-${i}-${j}`} style={{ fontSize: "14px" }}>
                                                {r}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        key={`fps-entry-${i}`}
                                        type="number"
                                        size="small"
                                        onChange={(e) => onChangeBitrate(parseFloat(e.target.value), i)}
                                        variant="outlined"
                                        value={entry.bitRate}
                                        InputProps={{
                                            inputProps: { min: 0.0, max: maxBitrate, step: 0.1 },
                                            style: {
                                                minWidth: "80px",
                                                fontSize: "14px",
                                                padding: "0px 10px",
                                                height: "43px",
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell style={{ width: "80px" }}>
                                    <IconButton size="small" onClick={() => onRemoveEntry(i)}>
                                        <DeleteForever />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                    <Button size="small" color="primary" onClick={onAddEntry} startIcon={<Add />}>
                        Add entry
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

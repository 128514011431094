import * as React from "react";
import { GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps_overlappingSchedulers } from "./__generated__/GetSchedulerChannelsWithOverlapsQuery";
import styled from "styled-components";
import { KeyboardArrowRight } from "@mui/icons-material";
import TimestampWithTooltip from "../TimestampWithTooltip/TimestampWithTooltip";
import moment from "moment";
import { navigate } from "@reach/router";

interface Props {
    schedulers: GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps_overlappingSchedulers[];
}

const Info = styled.p`
    padding: 0;
    margin: 0;
    font-size: 14px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
`;

const Item = styled.div`
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.primary};
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-right: 18px;
    margin-bottom: 18px;
    align-items: center;
    cursor: pointer;
`;

const InfoWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

const Name = styled.div`
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 3px;
`;

const Details = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

export default function SchedulerAuditOverlappingSchedulers({ schedulers }: Props) {
    return (
        <>
            <Info>This channel was being used at the same time by the following scheduler(s):</Info>
            <Wrapper>
                {schedulers.map((sched, i) => (
                    <Item
                        key={`sched-${i}-${sched.schedulerId}`}
                        onClick={() => navigate(`/app/${sched.organizationId}/schedulers/${sched.schedulerId}`)}
                    >
                        <InfoWrap>
                            <Name>{sched.name}</Name>
                            <Details>
                                <div>{sched.schedulerId}</div>
                                <div style={{ display: "flex" }}>
                                    <TimestampWithTooltip timestampISOString={moment(sched.start).toISOString()} />
                                    <span style={{ marginLeft: "4px", marginRight: "4px" }}>-</span>
                                    <TimestampWithTooltip timestampISOString={moment(sched.end).toISOString()} />
                                </div>
                            </Details>
                        </InfoWrap>
                        <div>
                            <KeyboardArrowRight color="primary" />
                        </div>
                    </Item>
                ))}
            </Wrapper>
        </>
    );
}

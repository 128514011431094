import * as React from "react";
import TypeSelectorItemWithIcon from "../../Channels/Details/TypeSelectorItemWithIcon";
import { CalendarMonth, EventRepeat } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { MaintenanceType } from "../../../__generated__/globalTypes";

interface Props {
    selectedType: string;
    onSelectType: (type: MaintenanceType) => void;
}

export default function MaintenanceTypeSelector({ onSelectType, selectedType }: Props) {
    const theme = useTheme();
    const disabledColor = "rgba(0, 0, 0, 0.12)";

    return (
        <>
            <TypeSelectorItemWithIcon
                selected={selectedType === "scheduled"}
                icon={
                    <CalendarMonth
                        style={{
                            fontSize: "22px",
                            color: selectedType === "scheduled" ? theme.palette.primary.main : disabledColor,
                        }}
                    />
                }
                title="Fixed slot(s)"
                description="Choose time slot(s) per week when maintenance should happen."
                onClick={() => onSelectType(MaintenanceType.scheduled)}
            />
            <TypeSelectorItemWithIcon
                selected={selectedType === "recurring"}
                icon={
                    <EventRepeat
                        style={{
                            fontSize: "22px",
                            color: selectedType === "recurring" ? theme.palette.primary.main : disabledColor,
                        }}
                    />
                }
                title="Recurring"
                description="Define an interval in which maintenance should happen."
                onClick={() => onSelectType(MaintenanceType.recurring)}
            />
        </>
    );
}

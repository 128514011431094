import * as React from "react";
import styled from "styled-components";

import { NavigateNext } from "@mui/icons-material";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";

const Wrapper = styled.div`
    padding: 8px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 8px;
`;

const IconTextWrapper = styled.div`
    display: flex;
    width: 90%;
`;

const IconWrapper = styled.div`
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
`;

const SubTitle = styled.div`
    font-size: 13px;
    margin-top: 4px;
    color: ${(props) => props.theme.gray};
`;

const IconLinkWrapper = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const TypeText = styled.div`
    font-size: 12px;
    color: ${(props) => props.theme.secondary};
    text-transform: capitalize;
    font-weight: 600;
`;

type LinkType = "guide" | "blog";

interface Props {
    type: LinkType;
    title: string;
    description: string;
    link: string;
}

function UsefulLink({ type, title, description, link }: Props) {
    return (
        <Wrapper onClick={() => window.open(link)}>
            <IconTextWrapper>
                <IconWrapper>
                    <div>{getIcon(type)}</div>
                    <TypeText>{type}</TypeText>
                </IconWrapper>
                <TextWrapper>
                    <Title>{title}</Title>
                    <SubTitle>{description}</SubTitle>
                </TextWrapper>
            </IconTextWrapper>
            <IconLinkWrapper>
                <NavigateNext color={"primary"} style={{ fontSize: "28px" }} />
            </IconLinkWrapper>
        </Wrapper>
    );
}

function getIcon(type: LinkType): React.ReactNode {
    switch (type) {
        case "blog":
            return <ChromeReaderModeOutlinedIcon color="secondary" />;
        case "guide":
            return <LibraryBooksOutlinedIcon color="secondary" />;
    }
}

export default UsefulLink;

import { DeleteForeverOutlined } from "@mui/icons-material";
import { FormHelperText, IconButton, TextField } from "@mui/material";
import styled from "styled-components";

interface Props {
    regex: RegExp;
    labels: Record<string, string>;
    onRemoveLabel: (idx: string) => void;
    onChangeLabel: (idx: string, value: string) => void;
}

const Labels = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const LabelEntry = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

export default function TLALabelsManager({ labels, onRemoveLabel, onChangeLabel, regex }: Props): JSX.Element {
    return (
        <Labels>
            {Object.keys(labels).map((key) => {
                return (
                    <div key={`label-entry-${key}`} style={{ marginBottom: "10px" }}>
                        <LabelEntry>
                            <TextField
                                fullWidth
                                variant="outlined"
                                defaultValue={labels[key]}
                                placeholder="Label"
                                onChange={(e: any) => onChangeLabel(key, e.target.value)}
                            />
                            <IconButton color="error" onClick={() => onRemoveLabel(key)}>
                                <DeleteForeverOutlined />
                            </IconButton>
                        </LabelEntry>
                        {!regex.test(labels[key]) && (
                            <FormHelperText color="error">Should be of format key=value</FormHelperText>
                        )}
                    </div>
                );
            })}
        </Labels>
    );
}

import { Grid } from "@mui/material";
import * as React from "react";
import CopyLabelSmall from "../../CopyLabel/CopyLabelSmall";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";

interface Props {
    channel: GetChannelQuery_channel;
}

function ChannelIngestSettingsPush({ channel }: Props) {
    const { rtmpPushUrl, streamKey } = channel;

    return (
        <Grid container>
            <Grid item xs={12}>
                <CopyLabelSmall title="URL" value={rtmpPushUrl} />
            </Grid>
            <Grid item xs={12}>
                <CopyLabelSmall title="Stream key" value={streamKey} />
            </Grid>
        </Grid>
    );
}

export default ChannelIngestSettingsPush;

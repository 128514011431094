import { KeyboardArrowRight } from "@mui/icons-material";
import { navigate } from "@reach/router";
import * as React from "react";
import styled from "styled-components";

interface Props {
    id: string;
    name: string;
    organizationId: string;
}

const ChannelItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
`;

const ChannelName = styled.div`
    font-size: 14px;
`;

const ChannelId = styled.div`
    margin-top: 4px;
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

export default function SchedulerDetailsChannelItem({ id, name, organizationId }: Props) {
    return (
        <ChannelItem onClick={() => navigate(`/app/${organizationId}/channels/${id}`)}>
            <div>
                <ChannelName>{name}</ChannelName>
                <ChannelId>{id}</ChannelId>
            </div>
            <div>
                <KeyboardArrowRight color="primary" />
            </div>
        </ChannelItem>
    );
}

import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "./SectionTitle";

interface Props {
    title: string | React.ReactNode;
    action?: React.ReactNode;
}

function SectionTitleWithAction({ title, action }: Props) {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ height: "50px", borderBottom: "1px solid #ececec", marginBottom: "10px" }}
        >
            <Grid item>
                <SectionTitle title={title} isAction={true} />
            </Grid>
            <Grid item>{action}</Grid>
        </Grid>
    );
}

export default SectionTitleWithAction;

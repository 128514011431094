import { lighten, Theme, useTheme } from "@mui/material";
import { Warning, Info, Error } from "@mui/icons-material";
import * as React from "react";
import styled from "styled-components";

export type CalloutType = "danger" | "warning" | "info";

interface Props {
    type: CalloutType;
    title: string;
    description?: string | React.ReactNode;
}

const Wrapper = styled.div`
    // margin-bottom: 10px;
    border-left: 3px solid;
    padding: 10px 20px 15px 20px;
    // margin-top: 10px;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
`;

const Description = styled.div`
    font-size: 14px;
`;

function Callout({ type, title, description }: Props) {
    const theme = useTheme();

    const colors = getColors(type, theme);

    return (
        <Wrapper style={{ borderColor: colors.primary, backgroundColor: colors.secondary }}>
            <TitleWrapper>
                {getIcon(type, theme)}
                <Title style={{ color: colors.primary }}>{title}</Title>
            </TitleWrapper>
            {description && <Description>{description}</Description>}
        </Wrapper>
    );
}

export default Callout;

function getIcon(type: CalloutType, theme: Theme) {
    const color = getColors(type, theme);

    switch (type) {
        case "info":
            return <Info style={{ color: color.primary }} fontSize="small" />;
        case "danger":
            return <Error style={{ color: color.primary }} fontSize="small" />;
        case "warning":
            return <Warning style={{ color: color.primary }} fontSize="small" />;
        default:
            return <Info style={{ color: color.primary }} fontSize="small" />;
    }
}

function getColors(type: CalloutType, theme: Theme): { primary: string; secondary: string } {
    const lightenPercentage: number = 0.95;

    switch (type) {
        case "info":
            return {
                primary: theme.palette.info.main,
                secondary: lighten(theme.palette.info.main, lightenPercentage),
            };
        case "danger":
            return {
                primary: theme.palette.error.main,
                secondary: lighten(theme.palette.error.main, lightenPercentage),
            };
        case "warning":
            return {
                primary: theme.palette.warning.main,
                secondary: lighten(theme.palette.warning.main, lightenPercentage),
            };
        default:
            return {
                primary: theme.palette.info.main,
                secondary: lighten(theme.palette.info.main, lightenPercentage),
            };
    }
}

import * as React from "react";
import ChannelCustomizerSection from "./ChannelCustomizerSection";
import ChannelCustomizerColors from "./ChannelCustomizerColors";
import styled from "styled-components";
import { ChannelCustomization, ProperyChangeType } from "./customizationHelpers";
import ChannelCustomizerPreLiveSettings from "./ChannelCustomizerPreLiveSettings";
import ChannelCustomizerLogo from "./ChannelCustomizerLogo";
import ChannelCustomizerAutoplay from "./ChannelCustomizerAutoplay";
import ChannelCustomizerLatency from "./ChannelCustomizerLatency";

interface Props {
    customization: ChannelCustomization;
    onCustomizationChange: (type: ProperyChangeType, key: string, value: string | boolean | number) => void;
}

const Wrapper = styled.div``;

function ChannelCustomizer({ customization, onCustomizationChange }: Props) {
    return (
        <Wrapper>
            <ChannelCustomizerSection
                customizations={
                    <>
                        <ChannelCustomizerLatency
                            currentTargetLatency={customization.targetLatency}
                            onCustomizationChange={(type: ProperyChangeType, key: string, value: number) =>
                                onCustomizationChange(type, key, value)
                            }
                        />
                        <ChannelCustomizerColors
                            colors={customization.colors}
                            onCustomizationChange={(type: ProperyChangeType, key: string, value: string) =>
                                onCustomizationChange(type, key, value)
                            }
                        />
                        <ChannelCustomizerPreLiveSettings
                            currentAnnouncement={customization.announcement}
                            currentPosterImg={customization.posterImg}
                            onCustomizationChange={(type: ProperyChangeType, key: string, value: string) =>
                                onCustomizationChange(type, key, value)
                            }
                        />
                        <ChannelCustomizerLogo
                            currentLogo={customization.logo}
                            onCustomizationChange={(type: ProperyChangeType, key: string, value: string) =>
                                onCustomizationChange(type, key, value)
                            }
                        />
                        <ChannelCustomizerAutoplay
                            currentAutoplay={customization.autoplay}
                            onCustomizationChange={(
                                type: ProperyChangeType,
                                key: string,
                                value: string | boolean | number,
                            ) => onCustomizationChange(type, key, value)}
                        />
                    </>
                }
            />
        </Wrapper>
    );
}

export default ChannelCustomizer;

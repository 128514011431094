import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div.attrs((props: { type: "error" | "warning" }) => ({
    type: props.type,
}))`
    margin-right: 8px;
    border-radius: 20px;
    font-weight: 800;
    max-width: 40px;
    text-align: center;
    color: black;
    font-size: 12px;
    padding: 4px 8px;
    background-color: ${(props) => (props.type === "warning" ? props.theme.warningLight : props.theme.errorLight)};
`;

interface Props {
    children: React.ReactNode;
    type: "error" | "warning";
}

export default function Badge({ children, type }: Props) {
    return <Wrapper type={type}>{children}</Wrapper>;
}

import * as React from "react";
import styled from "styled-components";
import { DialogActions, DialogContent } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { useMutation } from "react-apollo";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import { GetChannelQuery_channel_aliases } from "../Overview/__generated__/GetChannelQuery";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import {
    DisableChannelAliasMutation,
    DisableChannelAliasMutationVariables,
} from "./__generated__/DisableChannelAliasMutation";
import gql from "graphql-tag";

interface Props {
    open: boolean;
    alias: GetChannelQuery_channel_aliases;
    parentChannelId: string;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

export const DISABLE_CHANNEL_ALIAS_MUTATION = gql`
    mutation DisableChannelAliasMutation($input: DisableChannelAliasInput!) {
        disableChannelAlias(input: $input) {
            channelId
        }
    }
`;

function ChannelDisableAliasDialog({ open, onClose, alias, onSuccess, onError, parentChannelId }: Props) {
    const [disableChannelAliasMut, { loading }] = useMutation<
        DisableChannelAliasMutation,
        DisableChannelAliasMutationVariables
    >(DISABLE_CHANNEL_ALIAS_MUTATION);

    async function disableChannelAlias() {
        try {
            await disableChannelAliasMut({
                variables: {
                    input: {
                        channelAliasId: alias.channelId,
                        organizationId: alias.organizationId,
                        channelId: alias.parentChannelId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: parentChannelId,
                            organizationId: alias.organizationId,
                        },
                    },
                ],
            });
            onSuccess();
        } catch (e: any) {
            onError();
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Disable channel alias"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>Are you sure you want to disable this alias?</Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Disable"}
                            loadingText={"Disabling..."}
                            onActionClick={() => disableChannelAlias()}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default ChannelDisableAliasDialog;

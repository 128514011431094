/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetDatacenter, GetDatacenterVariables } from "./__generated__/GetDatacenter";
import TLADatacenterDetails from "../../components/TLA/TLADatacenterDetails";

interface Props extends RouteComponentProps {
    path?: string;
    id?: string;
}

export const GET_DATACENTER = gql`
    query GetDatacenter($id: ID!) {
        datacenter(id: $id) {
            id
            name
            tla {
                id
                name
                description
                domain
                hostId
                deviceContexts {
                    id
                    name
                    labels
                    serial
                    capacity
                    privateId
                    description
                }
            }
            organizations {
                organizationId
                name
            }
            channels {
                channelId
                organization {
                    organizationId
                    name
                }
                metadata {
                    name
                }
            }
        }
    }
`;

// eslint-disable-next-line no-empty-pattern
export default function TLADatacenter({ id }: Props) {
    const { data } = useQuery<GetDatacenter, GetDatacenterVariables>(GET_DATACENTER, {
        variables: {
            id: id!,
        },
    });

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`Data center ${data?.datacenter?.id ?? ""}`} align={"left"} showBottomBorder />
                {data?.datacenter && <TLADatacenterDetails datacenter={data.datacenter} />}
            </Grid>
        </Grid>
    );
}

import * as React from "react";
import styled from "styled-components";

interface Props {
    icon: React.ReactNode;
    onClick: () => void;
    title: string;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    // align-items: center;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 14px;
    min-width: 140px;
    margin-bottom: 8px;
    border: 1px solid ${(props) => props.theme.lightBlue};
    background-color: ${(props) => props.theme.lightBlueBackground};
`;

const Icon = styled.div`
    margin-right: 8px;
`;

const Title = styled.div`
    font-size: 13px;
    font-weight: bolder;
`;

export default function ChannelAdminLinkItem({ icon, onClick, title }: Props) {
    return (
        <Wrapper onClick={onClick}>
            <Icon>{icon}</Icon>
            <Title>{title}</Title>
        </Wrapper>
    );
}

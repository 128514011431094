import { Grid } from "@mui/material";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { DATE_FORMAT } from "../UsageSummary/UsageSummary";
import { GetFullBillingDetails_organization } from "./__generated__/GetFullBillingDetails";

import { styled as materialStyled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

interface Props {
    organization: GetFullBillingDetails_organization;
}

const CurrentToPay = styled.div`
    font-weight: 600;
    font-size: 24px;
    width: 100%;
    text-align: right;
`;

const Information = styled.div`
    font-size: 16px;
`;

const CurrentProgress = styled.div`
    margin-top: 30px;
`;

const CurrentToPayMessage = styled.div`
    margin-top: 8px;
    color: ${(props) => props.theme.gray};
`;

const BorderLinearProgress = materialStyled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
    },
}));

function CurrentBillingPayg({ organization }: Props) {
    const { nextInvoiceAt, paymentThreshold: threshold } = organization;
    const toPay = organization.totalNextInvoice;

    const progress = toPay / threshold > 1 ? 100 : Math.round((toPay / threshold) * 100);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={9}>
                <Information>
                    You'll be charged automatically on {moment(nextInvoiceAt).format(DATE_FORMAT)}. If you exceed the $
                    {threshold.toFixed(2)} threshold before this date, you'll be charged immediately.
                </Information>
                <CurrentProgress>
                    <BorderLinearProgress
                        variant="determinate"
                        color="primary"
                        value={progress}
                        style={{ marginTop: "8px" }}
                    />
                </CurrentProgress>
                <CurrentToPayMessage>
                    You're currently at ${toPay.toFixed(2)} of your ${threshold.toFixed(2)} payment threshold
                </CurrentToPayMessage>
            </Grid>
            <Grid item xs={12} sm={3}>
                <CurrentToPay>${toPay.toFixed(2)}</CurrentToPay>
            </Grid>
        </Grid>
    );
}

export default CurrentBillingPayg;

import * as React from "react";
import { GetCommitmentUsageReport_getCommitmentUsageReport } from "./__generated__/GetCommitmentUsageReport";
import { SkeletonRectangle } from "../Loading/Skeletons";
import OrganizationCommitmentUsageDataContent from "./OrganizationCommitmentUsageDataContent";

interface Props {
    loading: boolean;
    includeBillingInGb: boolean;
    data?: GetCommitmentUsageReport_getCommitmentUsageReport;
}

export type CommitmentUsageTab = "transcoded" | "viewed";

export default function OrganizationCommitmentUsageData({ loading, data, includeBillingInGb }: Props) {
    const [selectedTab, setSelectedTab] = React.useState<CommitmentUsageTab>("transcoded");

    return (
        <>
            {loading ? (
                <div>
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                </div>
            ) : !data ? (
                <div style={{ marginTop: "10px" }}>Select a date range and hit the search button</div>
            ) : (
                <OrganizationCommitmentUsageDataContent
                    selectedTab={selectedTab}
                    onSelectTab={(tab: CommitmentUsageTab) => setSelectedTab(tab)}
                    includeBillingInGb={includeBillingInGb}
                    data={data}
                />
            )}
        </>
    );
}

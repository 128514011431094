import { IconButton, Tooltip } from "@mui/material";
import { Check, Clear, Edit } from "@mui/icons-material";
import * as React from "react";
import { ProperyChangeType } from "./customizationHelpers";
import CustomizeItem from "./CustomizeItem";
import { Mark } from "@mui/base";

export const MIN_LATENCY: number = 0.5;
export const MAX_LATENCY: number = 5;
export const LATENCY_STEP: number = 0.5;

interface Props {
    currentTargetLatency: number;
    onCustomizationChange: (type: ProperyChangeType, key: string, value: number) => void;
}

function ChannelCustomizerLatency({ currentTargetLatency, onCustomizationChange }: Props) {
    const [targetLatency, setTargetLatency] = React.useState<number>(currentTargetLatency);
    const [editTarget, setEditTarget] = React.useState<boolean>(false);

    React.useEffect(() => {
        setTargetLatency(currentTargetLatency);
    }, [currentTargetLatency]);

    function onSaveLatency(): void {
        onCustomizationChange("latency", "targetLatency", targetLatency);
        setEditTarget(false);
    }

    function onDiscardLatencyChanges(): void {
        setTargetLatency(currentTargetLatency);
        setEditTarget(false);
    }

    const marks: Mark[] = [];
    for (let i = MIN_LATENCY; i <= MAX_LATENCY; i = i + LATENCY_STEP) {
        if (i === MIN_LATENCY) {
            marks.push({ value: i, label: `<1s` });
        } else {
            marks.push({ value: i, label: i % 1 === 0 ? `${i}s` : "" });
        }
    }

    function transformTooltip(value: number): string {
        if (value <= 1) {
            return `<1s`;
        }
        return `${value}s`;
    }

    return (
        <>
            <CustomizeItem
                title={"Network resilience"}
                description={"Choose for Optimized latency or Optimized network resilience by setting target latency"}
                currentValue={targetLatency}
                isEditable={editTarget}
                type="slider"
                sliderValues={{
                    showMarks: true,
                    minValue: MIN_LATENCY,
                    maxValue: MAX_LATENCY,
                    step: LATENCY_STEP,
                    marks,
                    rangeTitles: {
                        left: "Optimized latency",
                        right: "Optimized network resilience",
                    },
                }}
                transformTooltipText={transformTooltip}
                onValueChange={(val: string | number) => typeof val === "number" && setTargetLatency(val)}
                onDoubleClick={() => setEditTarget(true)}
                customizationActions={
                    <>
                        {!editTarget && (
                            <>
                                <Tooltip title="Edit">
                                    <IconButton size="small" onClick={() => setEditTarget(true)}>
                                        <Edit fontSize="small" style={{ color: "black" }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {editTarget && (
                            <>
                                <Tooltip title="Save">
                                    <IconButton size="small" onClick={onSaveLatency}>
                                        <Check fontSize="small" style={{ color: "black" }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Discard">
                                    <IconButton size="small" onClick={onDiscardLatencyChanges}>
                                        <Clear fontSize="small" style={{ color: "black" }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </>
                }
            />
        </>
    );
}

export default ChannelCustomizerLatency;

import { Redirect, Router } from "@reach/router";
import CenterPage from "../../components/CenterPage/CenterPage";
import Channels from "./Channels";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Team from "./Team";
import Tokens from "./Tokens";
import Billing from "./Billing";
import Admin from "./Admin";
import ActiveChannels from "./ActiveChannels";
import ManageOrganization from "./ManageOrganization";
import AvcRoyaltyReporting from "./AvcRoyaltyReporting";
import Usage from "./Usage";
import Logout from "./Logout";
import AuthService from "../../auth/AuthService";
import { useQuery } from "react-apollo";
import { GET_REGIONS_QUERY } from "../../components/Regions/RegionSelector";
import { preloadMainQueries } from "./preloader";
import { GetRegionsQuery, GetRegionsQueryVariables } from "../../components/Regions/__generated__/GetRegionsQuery";
import React from "react";
import Insights from "./Insights";
// import IngestHealth from "./IngestHealth";
import IngestHealth from "./IngestHealthV2";

import ChannelV2 from "./ChannelV2";
import ChannelUsage from "./ChannelUsage";
import Webhooks from "./Webhooks";
import Events from "./Events";
import CreateWebhook from "./CreateWebhook";
import Webhook from "./Webhook";
import EditWebhook from "./EditWebhook";
import Schedulers from "./Schedulers";
import CreateScheduler from "./CreateScheduler";
import Scheduler from "./Scheduler";
import EditScheduler from "./EditScheduler";
import Analytics from "./Analytics";
import AdminConfig from "./AdminConfig";
import AdminConfigProfiles from "./AdminConfigProfiles";
import CreateProfile from "./CreateProfile";
import EditProfile from "./EditProfile";
import CreateChannel from "./CreateChannel";
import ChannelRuns from "../../components/Channels/Runs/ChannelRuns";
import RecentErrors from "./RecentErrors";
import Settings from "./Settings";
import { GetOrganizationBasics_organization } from "../../components/GetStarted/__generated__/GetOrganizationBasics";
import SettingsMaintenanceWindow from "../../components/Settings/SettingsMaintenanceWindow/SettingsMaintenanceWindow";
import SchedulersOverviewWithFilters from "./SchedulerOverviewWithFilters";
import SchedulerAudit from "./SchedulerAudit";
import TLA from "./TLA";
import TLADatacenters from "./TLADatacenters";
import TLADatacenter from "./TLADatacenter";
import TLADatacenterCreate from "./TLADatacenterCreate";
import TLADatacenterDeviceContextCreate from "./TLADatacenterDeviceContextCreate";

interface Props {
    organizationId: string;
    organization: GetOrganizationBasics_organization;
}

export const THEO_ID = "e2c4220c-3cf4-4499-ab3a-ea5e904d0406";

// eslint-disable-next-line no-unused-vars
function AppRoot({ organizationId, organization }: Props) {
    const isTHEOAdmin = AuthService.isTHEOAdmin();
    const isAdmin = AuthService.isAdmin();
    const hasAdminRights = isTHEOAdmin || isAdmin;

    // pre-loading queries
    const { client } = useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GET_REGIONS_QUERY, {
        variables: { showDeprecated: false },
    });
    preloadMainQueries(client, organizationId);

    return (
        <CenterPage>
            <Router primary={false}>
                <Redirect
                    from="/"
                    to={`/app/${organizationId}/${isTHEOAdmin && organizationId === THEO_ID ? "admin" : "dashboard"}`}
                    noThrow
                />
                {isTHEOAdmin && organizationId === THEO_ID && (
                    <>
                        <Admin path="admin" />
                        <AdminConfig path="config" />
                        <TLA path="tla" />
                        <TLADatacenters path="tla/data-centers" />
                        <TLADatacenterCreate path="tla/data-centers/create" />
                        <TLADatacenter path="tla/data-centers/:id" />
                        <TLADatacenterDeviceContextCreate path="tla/data-centers/:datacenterId/create" />
                        <AdminConfigProfiles path="config/profiles" />
                        <Analytics path="analytics/:type" />
                        <ActiveChannels path="active-channels" />
                        <RecentErrors path="recent-errors" />
                        <AvcRoyaltyReporting path="avc-royalty-reporting" />
                        <CreateProfile path="config/profiles/create" />
                        <EditProfile path="config/profiles/:id/edit" />
                    </>
                )}
                {isTHEOAdmin && <ManageOrganization path="manage-organization" />}

                <Dashboard path="dashboard" />
                <Channels path="channels" />
                <CreateChannel path="channels/create" />
                <ChannelV2 path="channels/:channelId" />
                <ChannelUsage path="channels/:channelId/usage" />
                <ChannelRuns path="channels/:channelId/runs" />
                <Webhook path="webhooks/:webhookId" />
                <Profile path="profile" />
                <Insights path="channels/:channelId/insights" />
                <IngestHealth path="channels/:channelId/ingest-health" />
                {hasAdminRights && <Team path="team" />}
                {hasAdminRights && <Billing path="billing" />}
                <Tokens path="tokens" />
                <Webhooks path="webhooks" />
                <Events path="events" />
                <Schedulers path="schedulers" />
                <SchedulersOverviewWithFilters path="schedulers/overview" />
                <CreateWebhook path="webhooks/create" />
                <EditWebhook path="webhooks/:webhookId/edit" />
                <CreateScheduler path="schedulers/create" />
                <Scheduler path="schedulers/:schedulerId" />
                {isTHEOAdmin && <SchedulerAudit path="schedulers/:schedulerId/audit" />}
                <EditScheduler path="schedulers/:schedulerId/edit" />
                <Usage path="usage" />
                {organization.channelSettings.perpetual.enabled === true && (
                    <>
                        <Settings path="settings" organization={organization} />
                        <SettingsMaintenanceWindow path="settings/maintenance" organization={organization} />
                    </>
                )}

                <Logout path="logout" />
            </Router>
        </CenterPage>
    );
}

export default AppRoot;

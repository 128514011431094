/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import AdminConfigContent from "../../components/AdminConfig/AdminConfigContent";

interface Props extends RouteComponentProps {
    path?: string;
}

// eslint-disable-next-line no-empty-pattern
export default function AdminConfig({}: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`Admin configurations`} align={"left"} showBottomBorder />
                <AdminConfigContent />
            </Grid>
        </Grid>
    );
}

import auth0, { Auth0Result, AuthorizeOptions } from "auth0-js";
import axios from "axios";
import { UserRoleType } from "../__generated__/globalTypes";
import { ViewerQuery_viewer } from "./__generated__/ViewerQuery";

const AUTH0_REALM = "Username-Password-Authentication";
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string;
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT as string;
const AUTH0_RESPONSE_TYPE = "token id_token";
const AUTH0_SCOPES = "openid profile email";
const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export interface Auth {
    accessToken: string;
    idToken: string;
    expiresAt: number;
    userProfile?: ViewerQuery_viewer;
}

interface UpdateLocalUserProfile {
    firstName: string;
    lastName: string;
    phone: string;
}

class AuthService {
    private auth?: Auth;
    private webAuth: auth0.WebAuth;

    constructor() {
        const accessToken = localStorage.getItem("access_token");
        const idToken = localStorage.getItem("id_token");
        const expiresAt = localStorage.getItem("expires_at");

        if (accessToken && idToken && expiresAt) {
            this.auth = {
                accessToken,
                idToken,
                expiresAt: parseInt(expiresAt),
            };
        }

        this.webAuth = new auth0.WebAuth({
            domain: AUTH0_DOMAIN,
            clientID: AUTH0_CLIENT_ID,
            responseType: AUTH0_RESPONSE_TYPE,
        });
    }

    public getAccessToken(): string | undefined {
        return this.auth?.accessToken;
    }

    public login(email: string, password: string) {
        return new Promise<Auth>((resolve, reject) => {
            this.webAuth.client.login(
                {
                    realm: AUTH0_REALM,
                    username: email,
                    password,
                    audience: AUTH0_AUDIENCE,
                    scope: AUTH0_SCOPES,
                },
                (error: any, authResult: any) => (error ? reject(error) : resolve(authResult)),
            );
        });
    }

    public signUpWithGoogle() {
        const options: AuthorizeOptions = {
            connection: "google-oauth2",
            responseType: "token",
            redirectUri: `${BASE_URL}/provider`,
            audience: AUTH0_AUDIENCE,
            scope: AUTH0_SCOPES,
            mode: "signUp",
        };
        this.webAuth.authorize(options);
    }

    public signInWithGoogle() {
        const options: AuthorizeOptions = {
            connection: "google-oauth2",
            responseType: "token",
            redirectUri: `${BASE_URL}/provider`,
            audience: AUTH0_AUDIENCE,
            scope: AUTH0_SCOPES,
            mode: "login",
        };
        this.webAuth.authorize(options);
    }

    public setAuth(authResult: Auth0Result, userProfile: ViewerQuery_viewer) {
        const expiresAt = authResult.expiresIn! * 1000 + new Date().getTime();

        this.auth = {
            accessToken: authResult.accessToken!,
            expiresAt,
            idToken: authResult.idToken!,
            userProfile,
        };
        localStorage.setItem("access_token", authResult.accessToken!);
        localStorage.setItem("id_token", authResult.idToken!);
        localStorage.setItem("expires_at", expiresAt.toString());
    }

    public tokenIsExpired = () => {
        if (!this.auth || !this.auth.expiresAt || !localStorage.getItem("expires_at")) {
            return true;
        }
        return new Date().getTime() >= this.auth.expiresAt;
    };

    public getLocalUserProfile() {
        if (this.auth?.userProfile) {
            return this.auth.userProfile;
        }
    }

    public isAdmin() {
        const profile = this.getLocalUserProfile();
        return (
            profile &&
            (profile.roles.includes(UserRoleType.hesp_theo_admin) || profile.roles.includes(UserRoleType.hesp_admin))
        );
    }

    public isTHEOAdmin(): boolean {
        const profile = this.getLocalUserProfile();
        if (!profile) {
            return false;
        }
        return profile.roles.includes(UserRoleType.hesp_theo_admin);
    }

    public isTHEOAdminSales(): boolean {
        const profile = this.getLocalUserProfile();
        if (!profile) {
            return false;
        }
        return profile.roles.includes(UserRoleType.hesp_theo_admin_sales);
    }

    public hasAccessToOrganization(organizationId: string): boolean {
        const profile = this.getLocalUserProfile();
        if (!profile) {
            return false;
        }
        return this.isTHEOAdmin() || (profile && profile.organizationId === organizationId);
    }

    public async getUserProfile(accessToken: string): Promise<ViewerQuery_viewer> {
        try {
            const res = await axios({
                url: GRAPHQL_ENDPOINT,
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                data: {
                    query: `
                    query ViewerQuery {
                        viewer {
                            auth0Id
                            organizationId
                            firstName
                            lastName
                            phone
                            email
                            emailVerified
                            roles
                            createdAt
                            picture
                            activation {
                                activated
                                url
                                activationMailSentAt
                            }
                        }
                    }
                    `,
                },
            });
            return res.data.data.viewer as ViewerQuery_viewer;
        } catch (e: any) {
            throw new Error("ERROR_GETTING_USERPROFILE");
        }
    }

    public setUserProfile(userProfile: ViewerQuery_viewer) {
        this.auth!.userProfile = userProfile;
    }

    public updateLocalUserProfile({ firstName, lastName, phone }: UpdateLocalUserProfile) {
        if (this.auth?.userProfile) {
            this.auth.userProfile = {
                ...this.auth.userProfile,
                firstName,
                lastName,
                phone,
            };
        }
    }

    public logout() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");

        this.auth = undefined;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();

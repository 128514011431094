import { Button, Grid } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import WebhookFormBasicInfo from "./WebhookFormBasicInfo";
import WebhookEventsSelector from "./WebhookEventsSelector";
import { useMutation, useQuery } from "react-apollo";
import { GET_THEOLIVE_WEBHOOK_EVENTS } from "../../views/App/CreateWebhook";
import { GetTHEOliveWebhookEvents } from "../../views/App/__generated__/GetTHEOliveWebhookEvents";
import { SkeletonRectangle } from "../Loading/Skeletons";
import gql from "graphql-tag";
import { CreateWebhookMutation, CreateWebhookMutationVariables } from "./__generated__/CreateWebhookMutation";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { navigate } from "@reach/router";
import { GET_WEBHOOKS_ORGANIZATION } from "./WebhooksOverview";
import AuthService from "../../auth/AuthService";
import WebhookAdminSelector from "./WebhookAdminSelector";

export const CREATE_WEBHOOK_MUTATION = gql`
    mutation CreateWebhookMutation($input: CreateWebhookInput!) {
        createWebhook(input: $input) {
            webhookId
        }
    }
`;

interface Props {
    organizationId: string;
}

export interface CreateWebhookFormData {
    name: string;
    url: string;
    description: string;
}

function CreateWebhookForm({ organizationId }: Props) {
    const { handleSubmit, register, errors } = useForm<CreateWebhookFormData>();

    const [selectAll, setSelectAll] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [selectedEvents, setSelectedEvents] = React.useState<string[]>([]);

    const { data, loading } = useQuery<GetTHEOliveWebhookEvents>(GET_THEOLIVE_WEBHOOK_EVENTS);
    const [createWebhookMut, { loading: loadingMut }] = useMutation<
        CreateWebhookMutation,
        CreateWebhookMutationVariables
    >(CREATE_WEBHOOK_MUTATION);

    const isTHEOAdmin = AuthService.isTHEOAdmin();

    if (loading) {
        return (
            <>
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
            </>
        );
    }

    if (!data) {
        return <div>An error occurred</div>;
    }

    const allEvents = data.webhookEvents.filter((w) => w !== "*");

    function onSelectAllChange() {
        if (!selectAll) {
            setSelectedEvents(allEvents);
        } else {
            setSelectedEvents([]);
        }
        setSelectAll(!selectAll);
    }

    function setNewEvents(events: string[]) {
        setSelectedEvents(events);
        if (events.length === allEvents.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }

    async function onSubmitForm({ name, url, description }: CreateWebhookFormData) {
        try {
            const res = await createWebhookMut({
                variables: {
                    input: {
                        organizationId,
                        name,
                        url,
                        events: selectAll === true ? ["*"] : selectedEvents,
                        ...(description !== "" && { description }),
                        ...(isTHEOAdmin && { isAdmin }),
                    },
                },
                refetchQueries: [
                    {
                        query: GET_WEBHOOKS_ORGANIZATION,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });

            toast.success("Webhook created! 🚀", TOAST_SETTINGS);
            if (res.data?.createWebhook.webhookId) {
                navigate(`/app/${organizationId}/webhooks/${res.data?.createWebhook.webhookId}`);
            }
        } catch (e) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <WebhookFormBasicInfo
                        register={register}
                        errors={errors}
                        defaultValues={{ name: "", description: "", url: "" }}
                    />
                </Grid>
                {isTHEOAdmin && (
                    <Grid item xs={12}>
                        <WebhookAdminSelector selected={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <WebhookEventsSelector
                        allEvents={allEvents}
                        selectAll={selectAll}
                        onSelectAllChange={onSelectAllChange}
                        selectedEvents={selectedEvents}
                        onSelectedEventsChange={(events: string[]) => setNewEvents(events)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button
                            onClick={handleSubmit((d) => onSubmitForm(d))}
                            variant="contained"
                            size="large"
                            disabled={loadingMut}
                        >
                            {loadingMut ? "Creating webhook..." : "Create webhook"}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <ToastContainer />
        </>
    );
}

export default CreateWebhookForm;

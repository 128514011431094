/* eslint-disable no-undef */
import { Grid, MenuItem, TextField, styled as materialStyled } from "@mui/material";

import * as React from "react";
import DataItem from "../DataItem/DataItem";
import styled from "styled-components";
import { SkeletonRectangle } from "../Loading/Skeletons";
import { GetChannelViewerInsightsQuery_channel } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import { HESPlink } from "../Landing/SignUpComplete";
import InsightsRefreshSection from "./InsightsRefreshSection";
import { GetChannelBasics_channel } from "../../views/App/__generated__/GetChannelBasics";
import { STARTING_STATES } from "../Channels/Overview/ChannelActionsCell";

export type InsightsType = "viewer" | "streaming";

export interface InsightAliasItem {
    id: string;
    name: string;
}

interface Props {
    channel?: GetChannelViewerInsightsQuery_channel | GetChannelBasics_channel;
    type: InsightsType;
    isLoading: boolean;
    selectedAlias?: string;
    aliases?: InsightAliasItem[];
    onChangeAlias?: (aliasId: string) => void;
    initialLoadDone: boolean;
    selectedPeriod?: InsightPeriodType;
    onChangePeriod?: (period: InsightPeriodType) => void;
    onRefetch?: () => void;
}

const Badge = styled.div.attrs((props: { isLive: boolean }) => ({
    isLive: props.isLive,
}))`
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid ${(props) => (props.isLive ? "#5a9926" : "#eb4034")};
    color: ${(props) => (props.isLive ? "#5a9926" : "#eb4034")};
    cursor: default;
    max-width: 100px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
    ? ElementType
    : never;
export const insightPeriodTypes = ["hour", "day", "week", "month"] as const;
export type InsightPeriodType = ElementType<typeof insightPeriodTypes>;

function InsightsChannelData(props: Props) {
    const {
        channel,
        selectedPeriod,
        onChangePeriod,
        isLoading,
        onRefetch,
        initialLoadDone,
        type,
        selectedAlias,
        onChangeAlias,
        aliases,
    } = props;

    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 30px 8px 10px",
            fontSize: "14px",
            minWidth: "80px",
            width: "100%",
        },
    };

    const CssTextField = materialStyled(TextField)(smallCss);

    if (type === "viewer") {
        const requiredPropsForViewerInsights: string[] = ["selectedPeriod", "onChangePeriod", "onRefetch"];

        requiredPropsForViewerInsights.forEach((r: string) => {
            if (!(r in props)) {
                throw new Error(`Missing '${r}' property`);
            }
        });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <DataItem
                        title={"ID"}
                        value={
                            isLoading && !initialLoadDone ? (
                                <SkeletonRectangle width={"100%"} />
                            ) : (
                                <HESPlink link={`/app/${channel!.organizationId}/channels/${channel!.channelId}`}>
                                    {channel!.channelId}
                                </HESPlink>
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DataItem
                        title={"Name"}
                        value={
                            isLoading && !initialLoadDone ? (
                                <SkeletonRectangle width={"100%"} />
                            ) : (
                                channel!.metadata.name
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DataItem
                        title={"Status"}
                        value={
                            isLoading && !initialLoadDone ? (
                                <SkeletonRectangle width={"50%"} />
                            ) : (
                                <Badge isLive={STARTING_STATES.includes(channel!.channelStatus)}>
                                    {STARTING_STATES.includes(channel!.channelStatus) ? "Live" : "Offline"}
                                </Badge>
                            )
                        }
                    />
                </Grid>
                {type === "viewer" && (
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <DataItem
                                title={"Show data for"}
                                value={
                                    <CssTextField
                                        select
                                        name="channel"
                                        onChange={(e: any) => onChangeAlias!(e.target.value as string)}
                                        variant="outlined"
                                        value={selectedAlias}
                                        fullWidth
                                    >
                                        {aliases!.map((a: InsightAliasItem) => (
                                            <MenuItem value={a.id} style={{ fontSize: "14px" }} key={`option-${a.id}`}>
                                                {a.id === "total" ? "Total" : `${a.id} (${a.name})`}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DataItem
                                title={"Period"}
                                value={
                                    <CssTextField
                                        select
                                        name="period"
                                        onChange={(e: any) => onChangePeriod!(e.target.value as InsightPeriodType)}
                                        variant="outlined"
                                        value={selectedPeriod}
                                        fullWidth
                                    >
                                        {insightPeriodTypes.map((t: string) => (
                                            <MenuItem value={t} style={{ fontSize: "14px" }} key={`option-${t}`}>
                                                {getPeriodName(t as InsightPeriodType)}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                }
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            {channel && STARTING_STATES.includes(channel!.channelStatus) && type === "viewer" && (
                <InsightsRefreshSection onRefetch={() => onRefetch!()} isLoading={isLoading} />
            )}
        </>
    );
}

function getPeriodName(period: InsightPeriodType): string {
    switch (period) {
        case "hour":
            return "Last hour";
        case "day":
            return "Last day";
        case "week":
            return "Last week";
        case "month":
            return "Last 30 days";
        default:
            return "Last Hour";
    }
}

export default InsightsChannelData;

import { Grid } from "@mui/material";
import * as React from "react";
import { ToastContainer } from "react-toastify";
import { CdnType } from "../../../__generated__/globalTypes";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChannelPlayoutAdminCdn, { PlayoutCdnSettings } from "./ChannelPlayoutAdminCdn";

interface Props {
    channel: GetChannelQuery_channel;
    playoutId: string;
}

export default function ChannelPlayoutAdmin({ channel, playoutId }: Props) {
    const isAlias = channel.channelId !== playoutId;

    const config = isAlias ? channel.aliases.find((a) => a.channelId === playoutId) : channel;

    const cdnSettings: PlayoutCdnSettings = {
        parentChannelId: channel.channelId,
        channelId: playoutId,
        isAlias,
        cdn: {
            provider: config?.cdn?.provider ?? CdnType.fastly,
            url: config?.cdn?.url ?? "",
            defaultDomain: config?.publicationConfig?.defaultDomain ?? "",
        },
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ChannelPlayoutAdminCdn settings={cdnSettings} organization={channel.organization} />
                </Grid>
            </Grid>
            <ToastContainer />
        </>
    );
}

import * as React from "react";
import styled, { keyframes } from "styled-components";
import { Tooltip } from "@mui/material";
import { Autorenew } from "@mui/icons-material";

interface Props {
    onRefetch: () => void;
    isLoading: boolean;
}

const LastRefreshWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const LastRefreshText = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 12px;
    margin-left: 4px;
    cursor: default;
`;

const spinnerAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const LastRefreshIcon = styled.div`
    animation: ${spinnerAnimation} 2s linear infinite;
    padding-top: 2px;
`;

const INTERVAL = 5000;
const REFETCH_AFTER = 60000;

function InsightsRefreshSection({ onRefetch, isLoading }: Props) {
    const [timePassed, setTimePassed] = React.useState<number>(0);

    React.useEffect(() => {
        if (isLoading) {
            setTimePassed(0);
        }

        const interval = setInterval(() => setTimePassed(timePassed + INTERVAL), INTERVAL);

        if (timePassed >= REFETCH_AFTER) {
            setTimePassed(0);
            onRefetch();
        }
        return () => {
            clearInterval(interval);
        };
    }, [timePassed, onRefetch, isLoading]);

    return (
        <LastRefreshWrapper>
            <>
                <LastRefreshIcon>
                    <Autorenew style={{ fontSize: "14px", color: "#a3a3a3" }} />
                </LastRefreshIcon>
                <Tooltip title="Refreshing every minute">
                    <LastRefreshText>
                        {isLoading ? "Refreshing..." : `Last refresh: ${timePassed / 1000} seconds ago`}
                    </LastRefreshText>
                </Tooltip>
            </>
        </LastRefreshWrapper>
    );
}

export default InsightsRefreshSection;

import * as React from "react";
import { GetFullBillingDetails_organization_contracts } from "../BillingDetails/__generated__/GetFullBillingDetails";
import DialogHESP from "../DialogHESP/DialogHESP";
import { DialogActions, DialogContent, FormControl, Grid, Switch } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import styled from "styled-components";
import { useMutation } from "react-apollo";
import { UpdateContractMutation, UpdateContractMutationVariables } from "./__generated__/UpdateContractMutation";
import { toast } from "react-toastify";
import { GET_FULL_BILLING_DETAILS } from "../BillingDetails/BillingDetailsCardContent";
import { UPDATE_CONTRACT_MUTATION } from "./EditContractGbDialog";

interface Props {
    open: boolean;
    onClose: () => void;
    contract: GetFullBillingDetails_organization_contracts;
    organizationId: string;
}

const SwitchTitle = styled.span`
    font-size: 15px;
`;

export default function EditContractAccessSDDialog({ contract, onClose, open, organizationId }: Props) {
    const { hasAccessToSD: initHasAccessToSD } = contract;

    const [hasAccessToSD, setHasAccessToSD] = React.useState<boolean>(initHasAccessToSD);

    const [updateContractMut, { loading }] = useMutation<UpdateContractMutation, UpdateContractMutationVariables>(
        UPDATE_CONTRACT_MUTATION,
    );

    async function onSave() {
        try {
            await updateContractMut({
                variables: {
                    input: {
                        hasAccessToSD,
                        contractId: contract.id,
                        organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_FULL_BILLING_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });

            toast.success("Contract successfully updated");
            onClose();
        } catch (_e) {
            toast.error("Something went wrong");
        }
    }

    return (
        <DialogHESP
            title={"Change Service Desk settings"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent style={{ marginTop: "20px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <HESPFormLabel label="Access to Service Desk" />
                                <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                    <Switch
                                        color="primary"
                                        size="small"
                                        checked={hasAccessToSD}
                                        onChange={() => setHasAccessToSD(!hasAccessToSD)}
                                    />{" "}
                                    <SwitchTitle>Organization has access to Service Desk</SwitchTitle>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onSave}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit, FileCopy } from "@mui/icons-material";

import styled from "styled-components";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { copyToClipboard } from "./CopyLabel";

interface Props {
    title: string;
    value: string;
    showEditIcon?: boolean;
    onEditClick?: () => void;
}

const Wrapper = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 6px;
`;

const CopyWrapper = styled.div`
    border: 1px solid black;
    border-radius: 4px;
    min-height: 34px;
    background-color: #f7f7f7;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
`;

const Text = styled.div`
    @media (max-width: 420px) {
        white-space: nowrap;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const IconRight = styled.div`
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 4px;
    border-left: 1px solid black;
`;

const IconCenter = styled.div`
    padding: 4px;
    border-left: 1px solid black;
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

function CopyLabelSmall({ title, value, showEditIcon, onEditClick }: Props) {
    async function copyValue() {
        try {
            await copyToClipboard(value);
            toast.success("Value copied", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Error while copying", TOAST_SETTINGS);
        }
    }

    return (
        <Wrapper>
            <Label>{title}</Label>
            <CopyWrapper>
                <Text>{value}</Text>
                <IconWrapper>
                    {showEditIcon && (
                        <IconCenter>
                            <Tooltip title="Edit">
                                <IconButton size="small" onClick={onEditClick}>
                                    <Edit style={{ color: "black", fontSize: "12px" }} />
                                </IconButton>
                            </Tooltip>
                        </IconCenter>
                    )}
                    <IconRight>
                        <Tooltip title="Copy">
                            <IconButton size="small" onClick={copyValue}>
                                <FileCopy style={{ color: "black", fontSize: "12px" }} />
                            </IconButton>
                        </Tooltip>
                    </IconRight>
                </IconWrapper>
            </CopyWrapper>
        </Wrapper>
    );
}

export default CopyLabelSmall;

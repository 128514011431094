import * as React from "react";
import CustomizeItem from "./CustomizeItem";
import ColorPicker from "../ColorPicker/ColorPicker";

interface Props {
    title: string;
    description: string;
    color: string;
    onColorChange: (color: string) => void;
}

function ChannelCustomizerColor({ title, description, color, onColorChange }: Props) {
    return (
        <CustomizeItem
            title={title}
            description={description}
            customizationActions={
                <ColorPicker currentColor={color} onColorChange={(color: string) => onColorChange(color)} />
            }
        />
    );
}

export default ChannelCustomizerColor;

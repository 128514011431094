import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import RecentErrorsContent from "../../components/RecentErrors/RecentErrors";
import CardHESP from "../../components/CardHESP/CardHESP";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function RecentErrors({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`Recent errors 🙂🔥`} align="left" showBottomBorder />
                <CardHESP>
                    <RecentErrorsContent />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

export default RecentErrors;

import { Button, FormControl, Grid, MenuItem, TextField } from "@mui/material";
import { useQuery } from "react-apollo";
import { GET_CHANNELS_ORGANIZATION_QUERY } from "../Channels/Overview/channelQueriesMutations";
import {
    GetChannelsOrganizationQuery,
    GetChannelsOrganizationQueryVariables,
} from "../Channels/Overview/__generated__/GetChannelsOrganizationQuery";
import { SkeletonRectangle } from "../Loading/Skeletons";
import * as React from "react";
import SchedulersChannelsOverview from "./SchedulerChannelsOverview";

interface Props {
    channelIds: string[];
    onChannelsChange: (newChannelIds: string[]) => void;
    organizationId: string;
    firstSubmitDone: boolean;
    isActive: boolean;
}

export default function SchedulerChannelsSelector({
    channelIds,
    organizationId,
    firstSubmitDone,
    onChannelsChange,
    isActive,
}: Props) {
    const [selectedChannel, setSelectedChannel] = React.useState<string>("none");

    const { data, loading } = useQuery<GetChannelsOrganizationQuery, GetChannelsOrganizationQueryVariables>(
        GET_CHANNELS_ORGANIZATION_QUERY,
        {
            variables: {
                organizationId,
            },
        },
    );

    if (loading || !data) {
        return (
            <>
                <SkeletonRectangle width="50%" />
                <SkeletonRectangle width="50%" />
                <SkeletonRectangle width="50%" />
            </>
        );
    }

    function addSelectedChannel() {
        if (selectedChannel !== "none") {
            const newSelectedChannels = channelIds.concat([selectedChannel]);
            onChannelsChange(newSelectedChannels);
            setSelectedChannel("none");
        }
    }

    function onDelete(channelId: string) {
        const newSelectedChannels = channelIds.filter((c) => c !== channelId);
        onChannelsChange(newSelectedChannels);
    }

    const channelsToSelect = data.channels
        .filter((c) => !channelIds.includes(c.channelId))
        .sort((a, b) => (a.metadata.name < b.metadata.name ? -1 : 1));

    const selectedChannels = data.channels
        .filter((c) => channelIds.includes(c.channelId))
        .sort((a, b) => (a.metadata.name < b.metadata.name ? -1 : 1));

    return (
        <>
            {!isActive && (
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                select
                                size="small"
                                name="channel"
                                onChange={(e) => setSelectedChannel(e.target.value)}
                                variant="outlined"
                                value={selectedChannel}
                                placeholder="Select a channel"
                                InputProps={{
                                    style: {
                                        fontSize: "14px",
                                        paddingTop: "0px",
                                        height: "42px",
                                        lineHeight: "20px",
                                        background: "white",
                                    },
                                }}
                            >
                                <MenuItem value="none" disabled style={{ fontSize: "14px" }}>
                                    Select a channel
                                </MenuItem>
                                {channelsToSelect.map((c) => {
                                    return (
                                        <MenuItem value={c.channelId} key={c.channelId} style={{ fontSize: "14px" }}>
                                            {c.metadata.name} ({c.channelId})
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" size="small" onClick={addSelectedChannel}>
                            Add to list
                        </Button>
                    </Grid>
                </Grid>
            )}

            <div style={{ marginTop: "10px" }}>
                <SchedulersChannelsOverview
                    canDelete={!isActive}
                    onDelete={(channelId: string) => onDelete(channelId)}
                    channels={selectedChannels}
                    firstSubmitDone={firstSubmitDone}
                />
            </div>
        </>
    );
}

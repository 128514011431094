import { AddCircle, DoneAll, PlayArrow, Schedule, Update } from "@mui/icons-material";
import { Button, Grid, useTheme } from "@mui/material";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetSchedulersPerPhase, GetSchedulersPerPhaseVariables } from "./__generated__/GetSchedulersPerPhase";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import SchedulerAnalyticsCard from "./SchedulerAnalyticsCard";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import SchedulersQuickSearch from "./SchedulersQuickSearch";
import { navigate } from "@reach/router";
import SchedulerSection from "./SchedulerSection";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";

interface Props {
    organizationId: string;
}

export const GET_SCHEDULERS_PER_PHASE = gql`
    query GetSchedulersPerPhase($organizationId: ID!) {
        schedulersAnalytics(organizationId: $organizationId) {
            active
            pending
            terminated
        }
    }
`;

export default function SchedulersAnalytics({ organizationId }: Props) {
    const { data } = useQuery<GetSchedulersPerPhase, GetSchedulersPerPhaseVariables>(GET_SCHEDULERS_PER_PHASE, {
        variables: {
            organizationId,
        },
        pollInterval: 30 * 1000,
    });

    const theme = useTheme();

    function onCreateClick() {
        navigate(`/app/${organizationId}/schedulers/create`);
    }

    return (
        <>
            <Grid item xs={12}>
                <SectionTitleWithAction
                    title="Overview schedulers"
                    action={
                        <Button variant="contained" color="primary" startIcon={<AddCircle />} onClick={onCreateClick}>
                            Create scheduler
                        </Button>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SchedulerAnalyticsCard
                                    icon={<PlayArrow style={{ color: theme.palette.success.main, fontSize: "28px" }} />}
                                    amount={data?.schedulersAnalytics?.active ?? 0}
                                    title={`scheduler${
                                        (data?.schedulersAnalytics?.active ?? 0) !== 1 ? "s" : ""
                                    } active`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SchedulerAnalyticsCard
                                    icon={<Update style={{ color: theme.palette.info.main, fontSize: "28px" }} />}
                                    amount={data?.schedulersAnalytics?.pending ?? 0}
                                    title={`scheduler${
                                        (data?.schedulersAnalytics?.pending ?? 0) !== 1 ? "s" : ""
                                    } pending`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SchedulerAnalyticsCard
                                    icon={<DoneAll style={{ color: theme.palette.grey[500], fontSize: "28px" }} />}
                                    amount={data?.schedulersAnalytics?.terminated ?? 0}
                                    title={`scheduler${
                                        (data?.schedulersAnalytics?.terminated ?? 0) !== 1 ? "s" : ""
                                    } terminated`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardHESP style={{ height: "100%" }}>
                            <CardHESPTitle title="Quick search" subtitle="Search on ID, name or clientId" />
                            <SchedulersQuickSearch organizationId={organizationId} />
                        </CardHESP>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={4} xs={12}>
                                <CardHESP style={{ height: "100%" }}>
                                    <SchedulerSection
                                        icon={
                                            <PlayArrow
                                                style={{
                                                    color: theme.palette.success.main,
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    padding: "0px",
                                                    marginLeft: "-5px",
                                                }}
                                            />
                                        }
                                        title={"Active"}
                                        phases={[SchedulerPhaseType.active, SchedulerPhaseType.starting]}
                                        organizationId={organizationId}
                                    />
                                </CardHESP>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <CardHESP style={{ height: "100%" }}>
                                    <SchedulerSection
                                        icon={
                                            <Schedule
                                                style={{
                                                    color: theme.palette.info.main,
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    padding: "0px",
                                                    marginLeft: "-5px",
                                                }}
                                            />
                                        }
                                        title={"Pending"}
                                        phases={[SchedulerPhaseType.pending]}
                                        organizationId={organizationId}
                                    />
                                </CardHESP>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <CardHESP style={{ height: "100%" }}>
                                    <SchedulerSection
                                        icon={
                                            <DoneAll
                                                style={{
                                                    color: theme.palette.grey[500],
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    padding: "0px",
                                                    marginLeft: "-5px",
                                                }}
                                            />
                                        }
                                        title={"Terminated"}
                                        phases={[SchedulerPhaseType.terminated]}
                                        organizationId={organizationId}
                                    />
                                </CardHESP>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

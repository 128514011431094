import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import CardHESP from "../../components/CardHESP/CardHESP";
import CentralPageLoader from "../../components/Loading/CentralPageLoader";
import ManageSchedulerForm, { GET_SCHEDULER_QUERY } from "../../components/SchedulersOverview/ManageSchedulerForm";
import {
    GetSchedulerQuery,
    GetSchedulerQueryVariables,
} from "../../components/SchedulersOverview/__generated__/GetSchedulerQuery";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";

interface Props extends RouteComponentProps {
    schedulerId?: string;
    organizationId?: string;
}

export default function EditScheduler({ schedulerId, organizationId }: Props) {
    const { data, loading } = useQuery<GetSchedulerQuery, GetSchedulerQueryVariables>(GET_SCHEDULER_QUERY, {
        variables: {
            schedulerId: schedulerId!,
            organizationId: organizationId!,
        },
    });

    if (loading) {
        return <CentralPageLoader text="Getting scheduler info" />;
    }

    if (!data) {
        return <div>An error occurred</div>;
    }

    if (data.scheduler.phase === SchedulerPhaseType.terminated) return <div>Cannot update a terminated scheduler</div>;

    return (
        <>
            <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
                <Grid item xs={12}>
                    <SectionTitle title="Edit scheduler" align="left" showBottomBorder />
                    <CardHESP>
                        <ManageSchedulerForm
                            organizationId={organizationId!}
                            isCreate={false}
                            scheduler={data.scheduler}
                        />
                    </CardHESP>
                </Grid>
            </Grid>
        </>
    );
}

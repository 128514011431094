import { Grid } from "@mui/material";
import * as React from "react";
import { GetChannelBasics_channel } from "../../views/App/__generated__/GetChannelBasics";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import IngestHealthGraph from "../IngestHealth/IngestHealthGraph";
import StreamAnalyzerEvents, { AnalyzerEvent } from "./StreamAnalyzerEvents";
import moment from "moment";
import _ from "lodash";

interface Props {
    channel: GetChannelBasics_channel;
}

interface ChartRecord {
    timestamp: string;
    audio: number;
    video: number;
}

function getInitialChartValues(): ChartRecord[] {
    const now = moment();
    const start = moment().subtract(10, "minutes");
    const records: ChartRecord[] = [];

    while (start < now) {
        records.push({
            timestamp: start.format("YYYY-MM-DD HH:mm:ss"),
            audio: 0,
            video: 0,
        });
        start.add(1, "second");
    }

    return records;
}

export default function StreamAnalyzer({ channel }: Props) {
    const [events, setEvents] = React.useState<AnalyzerEvent[]>([]);
    const [bitrates, setBitrates] = React.useState<ChartRecord[]>(getInitialChartValues());
    const [fpses, setFpses] = React.useState<ChartRecord[]>(getInitialChartValues());

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!isOpen) {
            let ws = new WebSocket(
                channel.tla?.enabled === true
                    ? channel.tla!.networkingConfig?.websocketUrl ?? ""
                    : `wss://analyzer.${channel.region?.awsRegion!}.${process.env.REACT_APP_ENVIRONMENT_URL}/${
                          channel.organizationId
                      }/${channel.channelId}`,
            );

            ws.onopen = (_d) => {
                setIsOpen(true);
            };
            ws.onmessage = (message) => {
                const msg = JSON.parse(message.data);

                if (typeof msg.level === "undefined" || msg.level === "info") {
                    if (msg.type === "bitrate") {
                        if (msg)
                            setBitrates((bitrates) => [
                                ...(msg.mediaType === "video" || msg.mediatype === "video"
                                    ? bitrates.slice(1)
                                    : bitrates),
                                {
                                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                                    video:
                                        msg.mediaType === "video" || msg.mediatype === "video"
                                            ? parseFloat(((msg.value ?? msg.bitrate) / 1000000).toFixed(3))
                                            : -1,
                                    audio:
                                        msg.mediaType === "audio" || msg.mediatype === "audio"
                                            ? parseFloat(((msg.value ?? msg.bitrate) / 1000000).toFixed(3))
                                            : -1,
                                },
                            ]);
                    }

                    if (msg.type === "fps") {
                        setFpses((fpses) => [
                            ...(msg.mediaType === "video" || msg.mediatype === "video" ? fpses.slice(1) : fpses),
                            {
                                timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                                video:
                                    msg.mediaType === "video" || msg.mediatype === "video"
                                        ? parseFloat(msg.value ? msg.value.toFixed(2) : msg.fps.toFixed(2))
                                        : -1,
                                audio:
                                    msg.mediaType === "audio" || msg.mediatype === "audio"
                                        ? parseFloat(msg.value ? msg.value.toFixed(2) : msg.fps.toFixed(2))
                                        : -1,
                            },
                        ]);
                    }
                }

                if (msg.level === "warning") {
                    const evt: AnalyzerEvent = {
                        level: "warning",
                        mediatype: msg.mediaType ?? msg.mediatype,
                        message: msg.message ?? msg.warning,
                        streamindex: msg.streamindex,
                        timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                        type: msg.type,
                    };
                    setEvents((events) => [...events, evt]);
                }

                if (msg.level === "error") {
                    const evt: AnalyzerEvent = {
                        level: "error",
                        mediatype: msg.mediaType ?? msg.mediatype,
                        message: msg.message ?? msg.error,
                        streamindex: msg.streamindex,
                        timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                        type: msg.type,
                    };
                    setEvents((events) => [...events, evt]);
                }
            };

            ws.addEventListener("close", () => {
                setIsOpen(false);
            });
        }
    }, [channel, isOpen]);

    const fpsChartValues = _.chain(fpses)
        .groupBy(({ timestamp }) => timestamp)
        .map((value) => ({
            timestamp: value[0].timestamp,
            video: Math.max(...value.map((v) => v.video)),
            audio: Math.max(...value.map((v) => v.audio)),
        }))
        .value()
        .slice(1);

    const bitrateChartValues = _.chain(bitrates)
        .groupBy(({ timestamp }) => timestamp)
        .map((value) => ({
            timestamp: value[0].timestamp,
            video: Math.max(...value.map((v) => v.video)),
            audio: Math.max(...value.map((v) => v.audio)),
        }))
        .value()
        .slice(1);

    const fpsWithoutZeros = fpsChartValues.slice(0, fpsChartValues.length - 1).map((v, index) => {
        if (index > 1) {
            return {
                ...v,
                audio: flatZeroes(fpsChartValues, index, "audio"),
                video: flatZeroes(fpsChartValues, index, "video"),
            };
        }

        return v;
    });

    const bitratesWithoutZeros = bitrateChartValues.slice(0, bitrateChartValues.length - 1).map((v, index) => {
        if (index > 1) {
            return {
                ...v,
                audio: flatZeroes(bitrateChartValues, index, "audio"),
                video: flatZeroes(bitrateChartValues, index, "video"),
            };
        }

        return v;
    });

    function flatZeroes(records: ChartRecord[], index: number, type: "audio" | "video") {
        if (records[index][type] === -1) {
            return records[index - 1][type];
        }

        return records[index][type];
    }

    return (
        <div style={{ paddingTop: "15px" }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <CardHESP>
                        <CardHESPTitle title="Fps" />
                        <IngestHealthGraph
                            items={fpsWithoutZeros}
                            type={"fps"}
                            onRefetch={() => null}
                            isLive={channel.channelStatus === "playing"}
                        />
                    </CardHESP>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardHESP>
                        <CardHESPTitle title="Bitrate (in Mbps)" />
                        <IngestHealthGraph
                            items={bitratesWithoutZeros}
                            type={"bitrate"}
                            onRefetch={() => null}
                            isLive={channel.channelStatus === "playing"}
                        />
                    </CardHESP>
                </Grid>
                <Grid item xs={12}>
                    <CardHESP>
                        <CardHESPTitle title="Events" />
                        <StreamAnalyzerEvents events={events} />
                    </CardHESP>
                </Grid>
            </Grid>
        </div>
    );
}

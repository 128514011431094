import { EventNote, HeadsetMic, School, VpnKey, Webhook } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { navigate } from "@reach/router";
import SectionTitle from "../SectionTitle/SectionTitle";
import Changelog from "./Changelog";
import DeveloperLinkItem from "./DeveloperLinkItem";
import { useQuery } from "react-apollo";
import { GET_ORGANIZATION_BASICS_QUERY } from "../GetStarted/AccountWarningMessage";
import {
    GetOrganizationBasics,
    GetOrganizationBasicsVariables,
} from "../GetStarted/__generated__/GetOrganizationBasics";

interface Props {
    organizationId: string;
}

export default function DevelopersZone({ organizationId }: Props) {
    const { data, loading } = useQuery<GetOrganizationBasics, GetOrganizationBasicsVariables>(
        GET_ORGANIZATION_BASICS_QUERY,
        {
            variables: {
                organizationId,
            },
        },
    );

    if (!data || loading) {
        return <></>;
    }

    return (
        <>
            <SectionTitle align="left" showBottomBorder title="Developer zone" />
            <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                    <DeveloperLinkItem
                        title={"API tokens"}
                        description={"Create and manage tokens to communicate with the THEOlive API"}
                        icon={<VpnKey color={"secondary"} />}
                        onClick={() => navigate(`/app/${organizationId}/tokens`)}
                    />
                    <DeveloperLinkItem
                        title={"Webhooks"}
                        description={"Receive updates in real-time about THEOlive components"}
                        icon={<Webhook color={"secondary"} />}
                        onClick={() => navigate(`/app/${organizationId}/webhooks`)}
                    />
                    <DeveloperLinkItem
                        title={"Events"}
                        description={"Check events that happened in the last 30 days"}
                        icon={<EventNote color={"secondary"} />}
                        onClick={() => navigate(`/app/${organizationId}/events`)}
                    />
                    {data.organization.currentContract?.hasAccessToSD === true && (
                        <DeveloperLinkItem
                            title={"Service Desk"}
                            description={"Facing an issue? Our THEOlive experts are here to help"}
                            icon={<HeadsetMic color={"secondary"} />}
                            onClick={() => window.open(`https://opentelly.atlassian.net/servicedesk/customer/portal/1`)}
                        />
                    )}

                    <DeveloperLinkItem
                        title={"Documentation"}
                        description={"Read our guides and find the THEOlive API references"}
                        icon={<School color={"secondary"} />}
                        onClick={() => window.open(`https://developers.theo.live`)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Changelog />
                </Grid>
            </Grid>
        </>
    );
}

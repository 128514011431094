import * as React from "react";
import { useQuery } from "react-apollo";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { UsageType } from "../../__generated__/globalTypes";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import CentralLoader from "../Loading/CentralLoader";
import { GET_ANALYTICS_ORGANIZATION } from "../UsageSummary/UsageSummary";
import {
    GetAnalyticsOrganization,
    GetAnalyticsOrganizationVariables,
} from "../UsageSummary/__generated__/GetAnalyticsOrganization";

import CustomTooltip from "./CustomTooltip";
import { tooltipFormatter } from "./formatter";
import { calculateTotal } from "./usageHelper";
import _ from "lodash";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";

interface Props {
    type: UsageType;
    organizationId: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export const chartContainerMargin = {
    top: 20,
    right: 30,
    left: 20,
    bottom: 10,
};

export const chartContainerStyle = (): object | undefined => ({
    fontSize: "15px",
});

function UsageGraph({ type, organizationId, startDate, endDate }: Props) {
    const { data, loading } = useQuery<GetAnalyticsOrganization, GetAnalyticsOrganizationVariables>(
        GET_ANALYTICS_ORGANIZATION,
        {
            variables: {
                organizationId,
                startDate: moment(startDate).startOf("day").utc().toISOString(),
                endDate: moment(endDate).endOf("day").utc().toISOString(),
                usageType: type,
            },
        },
    );

    if (loading || !data) {
        return <CentralLoader text={`Getting data for ${type} minutes...`} />;
    }

    const records = (data as GetAnalyticsOrganization).getAnalyticsOrganization.records;

    const total = tooltipFormatter(calculateTotal(records));

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const recordsWithTz = records.map((record) => ({
        timestamp: moment(record.timestamp).tz(tz).format("DD/MM"),
        amount: record.amount,
    }));

    const recordsGroupedByDate = _.chain(recordsWithTz)
        .groupBy("timestamp")
        .map((value, key) => ({
            timeLabel: key,
            total: value.map((v) => v.amount).reduce((a, b) => a + b, 0),
        }))
        .value();

    const graphData = recordsGroupedByDate;

    const csvHeaders = ["date", "amount"];
    const csvData = recordsGroupedByDate.map((r) => ({
        date: r.timeLabel,
        amount: r.total,
    }));

    return (
        <>
            <CardHESPTitle
                title={`Total minutes ${type} on chart: ${total}`}
                button={
                    csvData && csvData.length > 0 ? (
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={`usage-${type}.csv`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button variant="contained" style={{ color: "black" }} color="inherit">
                                Export as CSV
                            </Button>
                        </CSVLink>
                    ) : (
                        <></>
                    )
                }
            />
            <ResponsiveContainer height="90%" width="100%">
                <LineChart data={graphData} margin={chartContainerMargin} style={chartContainerStyle()}>
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis dataKey="timeLabel" name={"Day"} minTickGap={10} />
                    <YAxis minTickGap={10} />
                    <Legend
                        verticalAlign="bottom"
                        iconType="circle"
                        iconSize={12}
                        wrapperStyle={{
                            fontSize: 12,
                        }}
                    />
                    <Line type="monotone" dataKey="total" stroke="#FFC713" strokeWidth={2} activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </>
    );
}

export default UsageGraph;

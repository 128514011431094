import { Grid } from "@mui/material";
import * as React from "react";

import { CreateWebhookSectionTitle } from "./WebhookFormBasicInfo";
import WebhookEventsSelectorCategory from "./WebhookEventsSelectorCategory";
import SwitchWithTitle from "../SwitchWithTitle/SwitchWithTitle";

interface Props {
    allEvents: string[];
    selectedEvents: string[];
    selectAll: boolean;
    onSelectAllChange: () => void;
    onSelectedEventsChange: (events: string[]) => void;
}

function WebhookEventsSelector({
    selectedEvents,
    onSelectedEventsChange,
    selectAll,
    onSelectAllChange,
    allEvents,
}: Props) {
    function onSelectEvent(event: string) {
        const newSelectedEvents = selectedEvents.concat([event]);
        onSelectedEventsChange(newSelectedEvents);
    }

    function onDeselectEvent(event: string) {
        const newSelectedEvents = selectedEvents.filter((e) => e !== event);
        onSelectedEventsChange(newSelectedEvents);
    }

    return (
        <>
            <CreateWebhookSectionTitle>Events to listen to</CreateWebhookSectionTitle>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SwitchWithTitle title="Select all events" checked={selectAll} onChange={onSelectAllChange} />
                </Grid>
                <Grid item xs={12}>
                    <WebhookEventsSelectorCategory
                        title={"Channel events"}
                        category="channel"
                        allEvents={allEvents}
                        selectedEvents={selectedEvents}
                        onSelectEvent={(event: string) => onSelectEvent(event)}
                        onDeselectEvent={(event: string) => onDeselectEvent(event)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <WebhookEventsSelectorCategory
                        title={"Channel alias events"}
                        category="alias"
                        allEvents={allEvents}
                        selectedEvents={selectedEvents}
                        onSelectEvent={(event: string) => onSelectEvent(event)}
                        onDeselectEvent={(event: string) => onDeselectEvent(event)}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default WebhookEventsSelector;

const theoLiveEventsDict: Record<string, string> = {
    "channel.creating": "Occurs when someone initiated a creation of a channel",
    "channel.created": "Occurs when the creation of a channel is completed",
    "channel.stopping": "Occurs when someone decides to stop a channel",
    "channel.stopped": "Occurs when all procedures to stop a channel are completed",
    "channel.deleting": "Occurs when someone decides to delete a channel",
    "channel.deleted": "Occurs when the process to delete a channel is completed",
    "channel.error": "Occurs when a channel goes into error state",
    "channel.deploying": "Occurs when someone starts a channel and all infrastructure starts deploying",
    "channel.starting": "Occurs when all infrastructure is deployed and channel is starting up",
    "channel.waiting": "Occurs when channel is ready, but isn't receiving ingest yet",
    "channel.ingesting": "Occurs when channel is receiving ingest",
    "channel.playing": "Occurs when manifest is ready and channel can playout content",
    "alias.created": "Occurs when an new alias got created",
    "alias.deleting": "Occurs when someone decides to delete a channel",
    "alias.deleted": "Occurs when the process to delete an alias is finished",
    "alias.enabled": "Occurs when an alias got enabled",
    "alias.disabled": "Occurs when an alias got disabled",
};

export function getTHEOliveDescription(event: string) {
    return theoLiveEventsDict[event] ?? "";
}

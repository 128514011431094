import { Grid, Button } from "@mui/material";
import { navigate, RouteComponentProps } from "@reach/router";
import * as React from "react";
import { AddCircleOutline } from "@mui/icons-material";
import ChannelsOverview, { ChannelDatacenter } from "../../components/Channels/Overview/ChannelsOverview";
import SectionTitleWithAction from "../../components/SectionTitle/SectionTitleWithAction";
import CardHESP from "../../components/CardHESP/CardHESP";
import Loading from "../../components/Loading/Loading";
import { useQuery } from "react-apollo";
import { GET_REGIONS_QUERY } from "../../components/Regions/RegionSelector";
import { GetRegionsQuery, GetRegionsQueryVariables } from "../../components/Regions/__generated__/GetRegionsQuery";

import gql from "graphql-tag";
import {
    GetOrganizationDatacenters,
    GetOrganizationDatacentersVariables,
} from "./__generated__/GetOrganizationDatacenters";
import {
    GetOrganizationBasics,
    GetOrganizationBasicsVariables,
} from "../../components/GetStarted/__generated__/GetOrganizationBasics";
import { GET_ORGANIZATION_BASICS_QUERY } from "../../components/GetStarted/AccountWarningMessage";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export const GET_ORGANIZATION_DATACENTERS = gql`
    query GetOrganizationDatacenters($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            organizationId
            tla {
                activated
                datacenters {
                    id
                    name
                }
            }
        }
    }
`;

function Channels({ organizationId }: Props) {
    const { data, loading } = useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GET_REGIONS_QUERY, {
        variables: { showDeprecated: false },
    });

    const { data: dataOrg, loading: loadingOrg } = useQuery<GetOrganizationBasics, GetOrganizationBasicsVariables>(
        GET_ORGANIZATION_BASICS_QUERY,
        {
            variables: {
                organizationId: organizationId!,
            },
        },
    );

    const { data: dataDatacenter, loading: loadingDatacenter } = useQuery<
        GetOrganizationDatacenters,
        GetOrganizationDatacentersVariables
    >(GET_ORGANIZATION_DATACENTERS, {
        variables: {
            organizationId: organizationId!,
        },
    });

    if (loading || !data || !dataDatacenter || loadingDatacenter || !dataOrg || loadingOrg) {
        return <Loading />;
    }

    const { regions } = data;
    const { tla } = dataDatacenter.organization;

    const datacenters: ChannelDatacenter[] =
        tla?.activated === true
            ? (tla?.datacenters ?? []).map((dc) => ({
                  id: dc.id,
                  name: dc.name,
              }))
            : [];

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitleWithAction
                    title={`My channels`}
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutline />}
                            onClick={() => navigate(`/app/${organizationId}/channels/create`)}
                        >
                            Create
                        </Button>
                    }
                />
                <CardHESP>
                    <ChannelsOverview
                        datacenters={datacenters}
                        regions={regions}
                        organization={dataOrg.organization}
                        onCreateClick={() => navigate(`/app/${organizationId}/channels/create`)}
                    />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

export default Channels;

import * as React from "react";
import styled from "styled-components";
import { ChannelModeType, ContractType } from "../../__generated__/globalTypes";

const Badge = styled.div.attrs((props: { mode: ChannelModeType | string }) => ({
    mode: props.mode,
}))`
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid ${(props) => getModeColor(props.mode)};
    color: ${(props) => getModeColor(props.mode)};
    cursor: default;
    max-width: 100px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

interface Props {
    mode: ChannelModeType | string | ContractType;
}

function ModeBadge({ mode }: Props) {
    return <Badge mode={mode}>{mode}</Badge>;
}

function getModeColor(mode: ChannelModeType | string | ContractType): string {
    switch (mode) {
        case "demo":
            return "#32739c";
        case "production":
            return "#5a9926";
        case "locked":
            return "#eb4034";
        case "promotional":
            return "#99118b";
        case "payg":
            return "#2c9599";
        case "commitment":
            return "#b0a027";
        case "individual":
            return "#B49A67";
        case "company":
            return "#417B5A";
        default:
            return "#32739c";
    }
}

export default ModeBadge;

import { DialogActions, DialogContent, Grid } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import {
    RemoveTLADatacenterFromOrganization,
    RemoveTLADatacenterFromOrganizationVariables,
} from "./__generated__/RemoveTLADatacenterFromOrganization";
import { GET_FULL_ORGANIZATION_DETAILS } from "../../views/App/ManageOrganization";

import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

interface Props {
    open: boolean;
    organizationId: string;
    datacenterId: string;
    onClose: () => void;
}

export const REMOVE_TLA_DATACENTER_FROM_ORGANIZATION = gql`
    mutation RemoveTLADatacenterFromOrganization($input: RemoveTLADatacenterFromOrganizationInput!) {
        removeTLADatacenterFromOrganization(input: $input) {
            organizationId
        }
    }
`;

export default function RemoveDataCenterDialog({ onClose, open, organizationId, datacenterId }: Props) {
    const [disconnectDC, { loading }] = useMutation<
        RemoveTLADatacenterFromOrganization,
        RemoveTLADatacenterFromOrganizationVariables
    >(REMOVE_TLA_DATACENTER_FROM_ORGANIZATION);

    async function onConfirm() {
        try {
            await disconnectDC({
                variables: {
                    input: {
                        datacenterId,
                        organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_FULL_ORGANIZATION_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            onClose();
        } catch (e) {
            toast.error(
                "Could not disconnect data center: some non deleted channels are still connected to it",
                TOAST_SETTINGS,
            );
        }
    }

    return (
        <DialogHESP
            title={"Disconnect data center from organization"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginTop: "14px" }}>
                                Are you sure you want to disconnect the data center "{datacenterId}" from this
                                organizations?
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onConfirm}
                            defaultText={"Remove"}
                            loadingText={"Removing..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

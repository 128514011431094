import * as React from "react";
import styled from "styled-components";

interface Props {
    value: string;
    text: string;
}

const Wrapper = styled.div`
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: align-center;
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
    margin-bottom: 18px;
    padding: 12px 16px;
`;

const Amount = styled.div`
    font-size: 16px;
    font-weight: bold;
`;
const Text = styled.div`
    margin-right: 20px;
    font-size: 15px;
`;

export default function SchedulerAuditAnalytics({ value, text }: Props) {
    return (
        <Wrapper>
            <Text>{text}</Text>

            <Amount>{value}</Amount>
        </Wrapper>
    );
}

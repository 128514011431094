import gql from "graphql-tag";
import moment from "moment";
import * as React from "react";
import { useQuery } from "react-apollo";
import { ChannelModeType, UsageType } from "../../__generated__/globalTypes";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { HESPlink } from "../Landing/SignUpComplete";
import { SkeletonRectangle } from "../Loading/Skeletons";
import { numberFormatter } from "../Usage/formatter";
import { OrganizationAnalyticsMode, OrganizationAnalyticsRange } from "./AdminTopOrganizations";
import {
    GetTopOrganizationsQuery,
    GetTopOrganizationsQueryVariables,
    GetTopOrganizationsQuery_getTopOrganizations,
} from "./__generated__/GetTopOrganizationsQuery";
import styled from "styled-components";

interface Props {
    selectedType: UsageType;
    selectedPeriod: OrganizationAnalyticsRange;
    selectedMode: OrganizationAnalyticsMode;
}

const NoData = styled.div`
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.gray};
`;

export const GET_TOP_ORGANIZATIONS_QUERY = gql`
    query GetTopOrganizationsQuery(
        $startDate: String!
        $endDate: String!
        $usageType: UsageType!
        $channelMode: ChannelModeType
    ) {
        getTopOrganizations(
            startDate: $startDate
            endDate: $endDate
            usageType: $usageType
            channelMode: $channelMode
        ) {
            amount
            organization {
                organizationId
                name
            }
        }
    }
`;

export default function AdminTopOrganizationTable({ selectedMode, selectedPeriod, selectedType }: Props) {
    function getStartDate(): string {
        if (selectedPeriod === "day") {
            return moment().startOf("hour").subtract(24, "hours").toISOString();
        }
        if (selectedPeriod === "week") {
            return moment().startOf("hour").subtract(7, "days").toISOString();
        }
        return moment().startOf("hour").subtract(30, "days").toISOString();
    }

    function getChannelMode(): ChannelModeType | undefined {
        if (selectedMode === "demo") {
            return ChannelModeType.demo;
        }
        if (selectedMode === "promotional") {
            return ChannelModeType.promotional;
        }
        if (selectedMode === "production") {
            return ChannelModeType.production;
        }
        return undefined;
    }

    const { data, loading } = useQuery<GetTopOrganizationsQuery, GetTopOrganizationsQueryVariables>(
        GET_TOP_ORGANIZATIONS_QUERY,
        {
            variables: {
                endDate: moment().endOf("day").toISOString(),
                startDate: getStartDate(),
                usageType: selectedType,
                channelMode: getChannelMode(),
            },
        },
    );

    if (loading || !data) {
        return (
            <>
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
            </>
        );
    }

    if (data.getTopOrganizations.length === 0) {
        return <NoData>No data available for period</NoData>;
    }

    const headers: TableHeadItem[] = [
        {
            id: "organization",
            label: "Organization",
        },
        {
            id: "amount",
            label: "Amount",
        },
    ];

    const mappedData = data.getTopOrganizations
        .sort((a, b) => (a.amount > b.amount ? -1 : 1))
        .map(({ amount, organization }: GetTopOrganizationsQuery_getTopOrganizations) => ({
            organization: <HESPlink link={`/app/${organization.organizationId}`}>{organization.name}</HESPlink>,
            amount: numberFormatter(amount),
        }));

    return (
        <HESPTable
            headers={headers}
            data={mappedData}
            defaultSortOn={"amount"}
            defaultSortDirection="desc"
            disableSorting
            size="small"
        />
    );
}

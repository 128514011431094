import * as React from "react";

import styled from "styled-components";
import ChannelsNoneCreated from "./ChannelsNoneCreated";
import { useQuery } from "@apollo/react-hooks";
import {
    GetChannelsOrganizationQuery,
    GetChannelsOrganizationQueryVariables,
    GetChannelsOrganizationQuery_channels,
} from "./__generated__/GetChannelsOrganizationQuery";
import CentralLoader from "../../Loading/CentralLoader";
import { GET_CHANNELS_ORGANIZATION_QUERY } from "./channelQueriesMutations";
import HESPTable from "../../HESPTable/HESPTable";
import { TableHeadItem } from "../../HESPTable/HESPTableHead";
import ChannelActionsCell from "./ChannelActionsCell";
import { ToastContainer } from "react-toastify";
import {
    HourglassEmpty,
    HighlightOff,
    CheckCircle,
    DonutLarge,
    Timer,
    ErrorOutline,
    SettingsInputAntenna,
    SignalCellular0Bar,
    DeleteSweep,
    Schedule,
    AlarmOff,
} from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import { ChannelModeType, ChannelStatusType } from "../../../__generated__/globalTypes";
import ChannelStatusCell from "./ChannelStatusCell";
import ModeBadge from "../../ModeBadge/ModeBadge";
import ChannelsOverviewFilters, { ChannelFilterType } from "./ChannelsOverviewFilters";
import { useLocation } from "@reach/router";
import { HESPlink } from "../../Landing/SignUpComplete";
import { GetRegionsQuery_regions } from "../../Regions/__generated__/GetRegionsQuery";
import { parse } from "query-string";
import { GetOrganizationBasics_organization } from "../../GetStarted/__generated__/GetOrganizationBasics";
import AuthService from "../../../auth/AuthService";
import { THEO_ID } from "../../../views/App/AppRoot";

interface Props {
    organization: GetOrganizationBasics_organization;
    onCreateClick: () => void;
    datacenters: ChannelDatacenter[];
    regions: GetRegionsQuery_regions[];
}

export const TableWrapper = styled.div`
    overflow-x: auto;
`;

const ChannelModeCell = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const EngineBadge = styled.div`
    background-color: ${(props) => props.theme.lightBlueBackground};
    width: 40px;
    text-align: center;
    padding: 4px;
    margin-left: 6px;
    border-radius: 8px;
    height: 26px;
    font-size: 12px;
    font-weight: bolder;
`;

export const PerpetualBadge = styled.div`
    background-color: ${(props) => props.theme.primaryLight};
    width: 40px;
    text-align: center;
    padding: 4px;
    margin-left: 6px;
    border-radius: 8px;
    height: 26px;
    font-size: 12px;
    font-weight: bolder;
`;

export interface ChannelDatacenter {
    id: string;
    name: string;
}

function ChannelsOverview({ organization, onCreateClick, regions, datacenters }: Props) {
    const allChannelStates = [
        ChannelStatusType.playing,
        ChannelStatusType.waiting,
        ChannelStatusType.ingesting,
        ChannelStatusType.starting,
        ChannelStatusType.deploying,
        ChannelStatusType.error,
        ChannelStatusType.scheduled,
        ChannelStatusType.creating,
        ChannelStatusType.stopping,
        ChannelStatusType.stopped,
        ChannelStatusType.deleting,
        ChannelStatusType.deleted,
    ];
    const allChannelModes = [ChannelModeType.production, ChannelModeType.promotional, ChannelModeType.demo];

    const allRegions = regions.map((r) => r.regionId);
    const allDatacenters = datacenters.map((dc) => dc.id);
    const location = useLocation();
    const searchParams = parse(location.search);
    const { status } = searchParams;

    const showChannelsWithStatusOnLoad = status && allChannelStates.includes(status as ChannelStatusType);

    const [searchValue, setSearchValue] = React.useState<string>("");
    const [selectedValues, setSelectedValues] = React.useState<{
        modes: string[];
        states: string[];
        regions: string[];
        datacenters: string[];
    }>({
        modes: allChannelModes,
        states: showChannelsWithStatusOnLoad ? [status as ChannelStatusType.playing] : allChannelStates,
        regions: allRegions,
        datacenters: allDatacenters,
    });

    const { data, loading, startPolling } = useQuery<
        GetChannelsOrganizationQuery,
        GetChannelsOrganizationQueryVariables
    >(GET_CHANNELS_ORGANIZATION_QUERY, {
        variables: {
            organizationId: organization.organizationId,
        },
    });

    if (loading || !data) {
        return (
            <div style={{ height: "150px" }}>
                <CentralLoader text="Getting channels..." />
            </div>
        );
    }

    const { channels } = data;

    startPolling(determinePollingTime(data.channels));

    const isTHEOAdmin = AuthService.isTHEOAdmin();
    const engineOverridesPossible = isTHEOAdmin && organization.channelSettings.engine.override?.enabled === true;

    const channelHeaders: TableHeadItem[] = [
        { id: "status", label: "Status", maxWidth: 150 },
        { id: "mode", label: "Mode", maxWidth: 150 },
        { id: "name", label: "Name", allowSort: true },
        ...(engineOverridesPossible ? [{ id: "engine", label: "" }] : []),
        {
            id: "region",
            label: datacenters.length > 0 ? "Region/Data center" : "Region",
            allowSort: true,
            maxWidth: 300,
        },
        { id: "id", label: "ID", allowSort: true },
        { id: "actions", label: "Actions", align: "right", allowSort: false },
    ];

    function getChannelsToShow() {
        const regionFilter = channels.filter(
            (c) =>
                ((c.regionId && selectedValues.regions.includes(c.regionId)) ||
                    (c.tla?.enabled === true && selectedValues.datacenters.includes(c.tla!.datacenter!.id))) &&
                selectedValues.modes.includes(c.channelMode) &&
                selectedValues.states.includes(c.channelStatus),
        );

        const searchFilter = searchValue
            ? regionFilter.filter(
                  (channel) =>
                      channel.channelId.toLowerCase().includes(searchValue.toLowerCase()) ||
                      channel.metadata.name.toLowerCase().includes(searchValue.toLowerCase()),
              )
            : regionFilter;
        return searchFilter;
    }

    const channelData = getChannelsToShow().map((channel: GetChannelsOrganizationQuery_channels) => ({
        status: (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <ChannelStatusCell channelStatus={channel.channelStatus} />
                {channel.perpetual && <PerpetualBadge>24/7</PerpetualBadge>}
            </div>
        ),

        mode: (
            <ChannelModeCell>
                <ModeBadge mode={channel.channelMode} />
            </ChannelModeCell>
        ),
        name: (
            <HESPlink link={`/app/${channel.organizationId}/channels/${channel.channelId}`}>
                {channel.metadata.name.length > 60
                    ? `${channel.metadata.name.substring(0, 60)}...`
                    : channel.metadata.name}
            </HESPlink>
        ),
        ...(engineOverridesPossible && {
            engine:
                getEngineVersionForChannel(channel) !== "" ? (
                    <EngineBadge> {getEngineVersionForChannel(channel)}</EngineBadge>
                ) : (
                    <></>
                ),
        }),
        region:
            channel.tla?.enabled === true ? (
                isTHEOAdmin ? (
                    <HESPlink link={`/app/${THEO_ID}/tla/data-centers/${channel.tla?.datacenter?.id}`}>
                        {channel.tla!.datacenter!.id}
                    </HESPlink>
                ) : (
                    channel.tla!.datacenter!.id
                )
            ) : (
                channel.regionId!
            ),
        id: channel.channelId,
        actions: [<ChannelActionsCell channel={channel} />],
    }));

    function getEngineVersionForChannel(channel: GetChannelsOrganizationQuery_channels): string {
        if (channel.engine?.override?.enabled === true) {
            return channel.engine?.override?.version ?? "";
        }
        return organization.channelSettings.engine?.override?.defaultVersion ?? "";
    }

    function onSelectAll(type: ChannelFilterType) {
        const newValues = selectedValues;

        switch (type) {
            case "region":
                setSelectedValues({
                    ...newValues,
                    regions: allRegions,
                });
                break;
            case "status":
                setSelectedValues({
                    ...newValues,
                    states: allChannelStates,
                });
                break;
            case "mode":
                setSelectedValues({
                    ...newValues,
                    modes: allChannelModes,
                });
                break;
        }
    }

    function onDeselectAll(type: ChannelFilterType) {
        const newValues = selectedValues;

        switch (type) {
            case "region":
                setSelectedValues({
                    ...newValues,
                    regions: [],
                });
                break;
            case "status":
                setSelectedValues({
                    ...newValues,
                    states: [],
                });
                break;
            case "mode":
                setSelectedValues({
                    ...newValues,
                    modes: [],
                });
                break;
        }
    }

    function onSelectItem(type: ChannelFilterType, item: string) {
        const newValues = selectedValues;

        switch (type) {
            case "region":
                setSelectedValues({
                    ...newValues,
                    regions: selectedValues.regions.concat([item]),
                });
                break;
            case "status":
                setSelectedValues({
                    ...newValues,
                    states: selectedValues.states.concat([item]),
                });
                break;
            case "mode":
                setSelectedValues({
                    ...newValues,
                    modes: selectedValues.modes.concat([item]),
                });
                break;
        }
    }

    function onDeselectItem(type: ChannelFilterType, item: string) {
        const newValues = selectedValues;

        switch (type) {
            case "region":
                setSelectedValues({
                    ...newValues,
                    regions: selectedValues.regions.filter((i) => i !== item),
                });
                break;
            case "status":
                setSelectedValues({
                    ...newValues,
                    states: selectedValues.states.filter((i) => i !== item),
                });
                break;
            case "mode":
                setSelectedValues({
                    ...newValues,
                    modes: selectedValues.modes.filter((i) => i !== item),
                });
                break;
        }
    }

    return (
        <>
            {channels.length === 0 && <ChannelsNoneCreated onCreateClick={onCreateClick} />}
            {channels.length > 0 && (
                <TableWrapper>
                    <Grid container>
                        <Grid item xs={12}>
                            <ChannelsOverviewFilters
                                onDeselectItem={(type: ChannelFilterType, item: string) => onDeselectItem(type, item)}
                                onSelectItem={(type: ChannelFilterType, item: string) => onSelectItem(type, item)}
                                onSelectAll={(type: ChannelFilterType) => onSelectAll(type)}
                                onDeselectAll={(type: ChannelFilterType) => onDeselectAll(type)}
                                items={{
                                    modes: allChannelModes,
                                    regions: allRegions,
                                    states: allChannelStates,
                                }}
                                selectedItems={{
                                    modes: selectedValues.modes,
                                    states: selectedValues.states,
                                    regions: selectedValues.regions,
                                }}
                                onChangeSearchInputValue={(val: string) => setSearchValue(val)}
                                initFiltersToShow={showChannelsWithStatusOnLoad ? ["status"] : []}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HESPTable
                                headers={channelHeaders}
                                data={channelData}
                                defaultSortOn="name"
                                defaultSortDirection="asc"
                            ></HESPTable>
                        </Grid>
                    </Grid>
                </TableWrapper>
            )}
            <ToastContainer />
        </>
    );
}

export default ChannelsOverview;

export function getStatusVisuals(status: ChannelStatusType): React.ReactNode {
    return (
        <Tooltip title={CHANNEL_STATES_ICONS_DESCRIPTION_RECORD[status].description}>
            <div>{CHANNEL_STATES_ICONS_DESCRIPTION_RECORD[status].icon}</div>
        </Tooltip>
    );
}

export const CHANNEL_STATES_ICONS_DESCRIPTION_RECORD: Record<
    ChannelStatusType,
    { icon: React.ReactNode; description: string }
> = {
    creating: { icon: <HourglassEmpty style={{ color: "#ebd534" }} />, description: "Channel is being created" },
    deleting: { icon: <DeleteSweep style={{ color: "#b5b5b5" }} />, description: "Channel is currently being deleted" },
    deleted: { icon: <HighlightOff style={{ color: "#b5b5b5" }} />, description: "Channel got deleted" },
    deploying: {
        icon: <DonutLarge style={{ color: "#b3dfff" }} />,
        description: "Full channel stack is being deployed",
    },
    starting: {
        icon: <DonutLarge style={{ color: "#199fff" }} />,
        description: "Channel got deployed and is starting up",
    },
    playing: { icon: <CheckCircle style={{ color: "#59d61c" }} />, description: "Channel is playing content" },
    error: { icon: <ErrorOutline style={{ color: "#f50a0a" }} />, description: "Channel is stuck in error state" },
    ingesting: {
        icon: <SettingsInputAntenna style={{ color: "#9fe87b" }} />,
        description: "Channel is receiving ingest, waiting for manifest to be ready",
    },
    waiting: {
        icon: <SignalCellular0Bar style={{ color: "#d3ebc7" }} />,
        description: "Channel is started, waiting for ingest",
    },
    stopping: { icon: <AlarmOff style={{ color: "#b5b5b5" }} />, description: "Channel is being stopped" },
    stopped: { icon: <Timer style={{ color: "#b5b5b5" }} />, description: "Channel is stopped" },
    scheduled: {
        icon: <Schedule style={{ color: "#d3bade" }} />,
        description: "Channel is scheduled to start shortly",
    },
};

function determinePollingTime(channels: GetChannelsOrganizationQuery_channels[]): number {
    const allChannelStates: ChannelStatusType[] = channels.map((c) => c.channelStatus);

    const quickPollingStates: ChannelStatusType[] = [
        ChannelStatusType.creating,
        ChannelStatusType.deleting,
        ChannelStatusType.deploying,
        ChannelStatusType.starting,
        ChannelStatusType.ingesting,
        ChannelStatusType.waiting,
        ChannelStatusType.stopping,
        ChannelStatusType.scheduled,
    ];

    if (allChannelStates.some((item) => quickPollingStates.includes(item))) {
        return 5000;
    }

    if (allChannelStates.some((item) => [ChannelStatusType.playing, ChannelStatusType.stopped].includes(item))) {
        return 20000;
    }

    if (allChannelStates.some((item) => [ChannelStatusType.error].includes(item))) {
        return 10000;
    }

    return 2000000;
}

import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    onDayChange: (day: string) => void;
    day: string;
}

export default function MaintenanceDaySelector({ day, onDayChange }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 10px 8px 15px",
            fontSize: "14px",
            width: "120px",
            maxWidth: "120px",
        },
    };

    const CssTextField = styled(TextField)(smallCss);
    const smallSizeText = { fontSize: "14px" };

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    return (
        <CssTextField
            select
            name="days"
            onChange={(e: any) => onDayChange(e.target.value)}
            variant="outlined"
            value={day}
        >
            {days.map((d) => (
                <MenuItem value={d} key={`day-${d}`} style={smallSizeText}>
                    Every {d}
                </MenuItem>
            ))}
        </CssTextField>
    );
}

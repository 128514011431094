import * as React from "react";
import { Chip } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";

interface Props {
    id: string;
    name: string;
    checked: boolean;
    showName: boolean;
    onChange: (checked: boolean) => void;
}

function ChannelChip({ id, name, checked, showName, onChange }: Props) {
    return (
        <Chip
            key={id}
            label={showName ? name : id}
            style={{
                backgroundColor: checked ? "#FFF3D4" : "",
            }}
            onClick={() => onChange(!checked)}
            icon={checked ? <Check /> : <Clear />}
        />
    );
}

export default ChannelChip;

import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";
import { Button } from "@mui/material";
import OrganizationChannelSettingsItem from "./OrganizationChannelSettingsItem";
import gql from "graphql-tag";
import { Save } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useMutation } from "react-apollo";
import {
    UpdateOrganizationChannelSettingsMutation,
    UpdateOrganizationChannelSettingsMutationVariables,
} from "./__generated__/UpdateOrganizationChannelSettingsMutation";
import { UpdateOrganizationChannelSettingsInput } from "../../__generated__/globalTypes";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import OrganizationChannelSettingsEngine from "./OrganizationChannelSettingsEngine";
import OrganizationChannelDefaultDomain from "./OrganizationChannelSettingsDefaultDomain";
interface Props {
    organization: GetFullOrganizationDetails_organization;
}

export const UPDATE_ORGANIZATION_CHANNEL_SETTINGS_MUTATION = gql`
    mutation UpdateOrganizationChannelSettingsMutation($input: UpdateOrganizationChannelSettingsInput!) {
        updateOrganizationChannelSettings(input: $input) {
            organizationId
        }
    }
`;

export default function OrganizationChannelSettings({ organization }: Props) {
    const { channelSettings, organizationId } = organization;

    const [input, setInput] = React.useState<UpdateOrganizationChannelSettingsInput>({
        organizationId,
        hybridFallback: channelSettings.hybridFallback,
        perpetual: channelSettings.perpetual,
        drm: channelSettings.drm,
        engine: {
            override: {
                enabled: channelSettings.engine.override?.enabled === true,
                defaultVersion: channelSettings.engine.override?.defaultVersion ?? "",
            },
        },
        domain: {
            override: {
                enabled: channelSettings.domain.override?.enabled === true,
                defaultDomain: channelSettings.domain.override?.defaultDomain ?? "",
            },
        },
    });

    const [updateChannelSettingsMut, { loading }] = useMutation<
        UpdateOrganizationChannelSettingsMutation,
        UpdateOrganizationChannelSettingsMutationVariables
    >(UPDATE_ORGANIZATION_CHANNEL_SETTINGS_MUTATION);

    async function onSave() {
        if (isValid()) {
            try {
                await updateChannelSettingsMut({
                    variables: {
                        input,
                    },
                });
                toast.success("Channel setting saved!", TOAST_SETTINGS);
            } catch (_e) {
                toast.error("Something went wrong", TOAST_SETTINGS);
            }
        }
    }

    function isValid(): boolean {
        if (input.domain.override.enabled === true) {
            if (!endWithChar(input.domain.override.defaultDomain)) {
                return false;
            }
        }
        return true;
    }

    function endWithChar(text: string) {
        const chars = [
            "a",
            "b",
            "c",
            "d",
            "e,",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
        ];

        if (text.length > 0) {
            const last = text.charAt(text.length - 1);
            return chars.includes(last.toLowerCase());
        }
        return true;
    }

    return (
        <>
            <CardHESP>
                <CardHESPTitle
                    title="Channel settings"
                    button={
                        <Button
                            color="primary"
                            startIcon={<Save />}
                            variant="contained"
                            onClick={onSave}
                            disabled={loading || !isValid()}
                        >
                            {loading ? "Saving..." : "Save"}
                        </Button>
                    }
                />
                <OrganizationChannelSettingsItem
                    title="Hybrid fallback"
                    description="Determine if customer is able to set a hybrid fallback (DASH, HLS) for a channel"
                    switchUI={{
                        enabled: input.hybridFallback.enabled,
                        onChange: () =>
                            setInput({
                                ...input,
                                hybridFallback: {
                                    ...input.hybridFallback,
                                    enabled: !input.hybridFallback.enabled,
                                },
                            }),
                    }}
                />
                <OrganizationChannelSettingsItem
                    title="24/7 (perpetual) channels"
                    description="Determine if customer is allowed to create and start 24/7 channels"
                    switchUI={{
                        enabled: input.perpetual.enabled,
                        onChange: () =>
                            setInput({
                                ...input,
                                perpetual: {
                                    ...input.perpetual,
                                    enabled: !input.perpetual.enabled,
                                },
                            }),
                    }}
                />
                <OrganizationChannelSettingsItem
                    title="DRM"
                    description="Determine if customer is able to activate DRM for a channel"
                    switchUI={{
                        enabled: input.drm.enabled,
                        onChange: () =>
                            setInput({
                                ...input,
                                drm: {
                                    ...input.drm,
                                    enabled: !input.drm.enabled,
                                },
                            }),
                    }}
                />
                <OrganizationChannelSettingsItem
                    title="Engine override"
                    description="Allow THEO admins to override the engine version of certain channels"
                    switchUI={{
                        enabled: input.engine.override.enabled,
                        onChange: () =>
                            setInput({
                                ...input,
                                engine: {
                                    ...input.engine,
                                    override: {
                                        ...input.engine.override,
                                        enabled: !input.engine.override.enabled,
                                    },
                                },
                            }),
                    }}
                >
                    <OrganizationChannelSettingsEngine
                        onChangeVersion={(version: string) =>
                            setInput({
                                ...input,
                                engine: {
                                    ...input.engine,
                                    override: {
                                        ...input.engine.override,
                                        defaultVersion: version,
                                    },
                                },
                            })
                        }
                        version={input.engine.override.defaultVersion}
                    />
                </OrganizationChannelSettingsItem>
                <OrganizationChannelSettingsItem
                    title="Default domain override"
                    description="Allow THEO admins to override the default domain of certain channels"
                    switchUI={{
                        enabled: input.domain.override.enabled,
                        onChange: () =>
                            setInput({
                                ...input,
                                domain: {
                                    ...input.domain,
                                    override: {
                                        ...input.domain.override,
                                        enabled: !input.domain.override.enabled,
                                    },
                                },
                            }),
                    }}
                >
                    <OrganizationChannelDefaultDomain
                        onChangeUrl={(url: string) =>
                            setInput({
                                ...input,
                                domain: {
                                    ...input.domain,
                                    override: {
                                        ...input.domain.override,
                                        defaultDomain: url,
                                    },
                                },
                            })
                        }
                        url={input.domain.override.defaultDomain}
                    />
                </OrganizationChannelSettingsItem>
            </CardHESP>
            <ToastContainer />
        </>
    );
}

import * as React from "react";
import { GetAdminEventsQuery_allEventsForAdmin_events } from "./__generated__/GetAdminEventsQuery";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Code } from "../Channels/Details/ChannelEmbedExample";
import { HESPlink } from "../Landing/SignUpComplete";
import { navigate } from "@reach/router";
import { KeyboardArrowRight } from "@mui/icons-material";

interface Props {
    events: GetAdminEventsQuery_allEventsForAdmin_events[];
}

export default function RecentErrorsTable({ events }: Props) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: "220px", maxWidth: "220px" }}>Channel</TableCell>
                    <TableCell style={{ maxWidth: "180px", width: "180px" }}>Organization</TableCell>
                    <TableCell>Error</TableCell>
                    <TableCell style={{ maxWidth: "50px", width: "50px" }} align="center"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {events.map((event, idx) => (
                    <TableRow key={`error-${idx}`}>
                        <TableCell style={{ maxWidth: "220px", width: "220px" }}>
                            <HESPlink link={`/app/${event.organization.organizationId}/channels/${event.objectId}`}>
                                {event.objectId}
                            </HESPlink>
                        </TableCell>
                        <TableCell style={{ maxWidth: "180px", width: "180px" }}>
                            <HESPlink link={`/app/${event.organization.organizationId}`}>
                                {event.organization.name}
                            </HESPlink>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                            <Code>{JSON.parse(event.data).message}</Code>
                        </TableCell>
                        <TableCell style={{ maxWidth: "50px", width: "50px" }}>
                            <IconButton
                                onClick={() =>
                                    navigate(`/app/${event.organization.organizationId}/channels/${event.objectId}`)
                                }
                            >
                                <KeyboardArrowRight color="primary" style={{ fontSize: "24px" }} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

import * as React from "react";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_viewerLatency } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import ViewerLatencyChart from "./ViewerLatencyChart";
import ViewerNumberWithTitle from "./ViewerNumberWithTitle";

interface Props {
    data?: GetChannelViewerInsightsQuery_channel_viewerInsights_viewerLatency;
}

function ViewerLatency({ data }: Props) {
    const [initLoad, setInitLoad] = React.useState<boolean>(true);
    const [myData, setMyData] = React.useState<
        GetChannelViewerInsightsQuery_channel_viewerInsights_viewerLatency | undefined
    >(undefined);

    React.useEffect(() => {
        if (data) {
            if (!myData) {
                setInitLoad(false);
            }
            setMyData(data);
        }
    }, [initLoad, data, setMyData, myData]);

    return (
        <CardHESP style={{ height: "100%" }}>
            <CardHESPTitle title="Viewer latency" subtitle="Latency distribution in period across viewers" />
            <ViewerNumberWithTitle
                amount={
                    initLoad
                        ? 0
                        : myData!.median > 3
                        ? "+3000ms"
                        : `${new Intl.NumberFormat("en-GB").format(Math.round(myData!.median * 1000))}ms`
                }
                title="Median"
            />
            {!initLoad && myData?.bins && <ViewerLatencyChart bins={myData.bins} />}
        </CardHESP>
    );
}

export default ViewerLatency;

import { DeleteForever, MoreHoriz, PlayArrow, Schedule, Stop } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";
import { GetSchedulersBasedOnParams_schedulersWithParams } from "./__generated__/GetSchedulersBasedOnParams";
import moment from "moment";

const Wrapper = styled.div`
    padding-bottom: 8px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 13px;
`;

const Description = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 11px;
`;

interface Props {
    scheduler: GetSchedulersBasedOnParams_schedulersWithParams;
    onDelete: () => void;
    onTerminate: () => void;
    onStart: () => void;
}

export default function SmallSchedulerItem({ onDelete, onStart, onTerminate, scheduler }: Props) {
    const { clientId, end, name, phase, schedulerId, organizationId, start } = scheduler;
    const isActive = phase === SchedulerPhaseType.active || phase === SchedulerPhaseType.starting;

    function getNamePart(name: string, phase: SchedulerPhaseType) {
        if (!isActive) {
            return name;
        }

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {phase === SchedulerPhaseType.active ? (
                    <Tooltip title="Channels are playing content">
                        <PlayArrow
                            style={{ color: "green", fontSize: "16px", marginRight: "6px", marginLeft: "-5px" }}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title="Channels are scheduled to be active shortly">
                        <Schedule
                            style={{ color: "#d3bade", fontSize: "16px", marginRight: "6px", marginLeft: "-5px" }}
                        />
                    </Tooltip>
                )}
                {name}
            </div>
        );
    }

    return (
        <Wrapper>
            <Info>
                <Title>{getNamePart(name, SchedulerPhaseType.active)}</Title>
                {clientId && <Description>ClientID: {clientId}</Description>}
                <Description>
                    {moment(start).format("MMM DD, HH:mm")} - {end ? moment(end).format("MMM DD, HH:mm") : "..."}
                </Description>
            </Info>
            <div>
                {[SchedulerPhaseType.pending, SchedulerPhaseType.starting].includes(phase) && (
                    <Tooltip title="start now">
                        <IconButton onClick={onStart} size="small">
                            <PlayArrow fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                {isActive && (
                    <Tooltip title="terminate">
                        <IconButton onClick={onTerminate} size="small">
                            <Stop fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="details">
                    <IconButton
                        onClick={() => navigate(`/app/${organizationId}/schedulers/${schedulerId}`)}
                        size="small"
                    >
                        <MoreHoriz fontSize="small" />
                    </IconButton>
                </Tooltip>
                {!isActive && (
                    <Tooltip title="delete">
                        <IconButton onClick={onDelete} size="small">
                            <DeleteForever fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Wrapper>
    );
}

import * as React from "react";
import ContentSettingsWithSwitchCard from "../../Channels/Details/ContentSettingsWithSwitchCard";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import styled from "styled-components";
import MaintenanceTypeSelector from "./MaintenanceTypeSelector";
import MaintenanceWindowFixedSlots from "./MaintenanceWindowFixedSlots";
import MaintenanceWindowRecurring from "./MaintenanceWindowRecurring";
import { MaintenanceType, MaintenanceRecurringPeriodType } from "../../../__generated__/globalTypes";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import {
    UpdateOrganizationMaintenanceMutation,
    UpdateOrganizationMaintenanceMutationVariables,
} from "./__generated__/UpdateOrganizationMaintenanceMutation";
import { ToastContainer, toast } from "react-toastify";
import { GET_ORGANIZATION_BASICS_QUERY } from "../../GetStarted/AccountWarningMessage";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";

const TypeWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    @media (max-width: 620px) {
        flex-direction: column;
    }
`;

const ScheduleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background-color: white;
    padding: 12px 16px;
`;

interface Props {
    initValues: MaintenanceSettings;
    objectId: string;
    type: "organization" | "channel";
}

export interface MaintenanceSettings {
    type: MaintenanceType;
    slots: {
        day: string;
        time: string;
    }[];
    interval?: {
        period: MaintenanceRecurringPeriodType;
        amount: number;
        time: string;
    };
}

const UPDATE_ORGANIZATION_MAINTENANCE_MUTATION = gql`
    mutation UpdateOrganizationMaintenanceMutation($input: UpdateOrganizationMaintenanceInput!) {
        updateOrganizationMaintenance(input: $input) {
            organizationId
        }
    }
`;

export default function MaintenanceManagement({ initValues, objectId, type }: Props) {
    const [needsSave, setNeedsSave] = React.useState<boolean>(false);
    const [settings, setSettings] = React.useState<MaintenanceSettings>(initValues);

    const [updateOrganizationMaintenance, { loading }] = useMutation<
        UpdateOrganizationMaintenanceMutation,
        UpdateOrganizationMaintenanceMutationVariables
    >(UPDATE_ORGANIZATION_MAINTENANCE_MUTATION);

    async function onSave() {
        try {
            if (type === "organization") {
                await updateOrganizationMaintenance({
                    variables: {
                        input: {
                            organizationId: objectId,
                            maintenance: {
                                type: settings.type,
                                slots: settings.type === MaintenanceType.scheduled ? settings.slots! : undefined,
                                interval: settings.type === MaintenanceType.recurring ? settings.interval! : undefined,
                            },
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_ORGANIZATION_BASICS_QUERY,
                            variables: {
                                organizationId: objectId,
                            },
                        },
                    ],
                });
            }

            toast.success("Maintenance schedule updated", TOAST_SETTINGS);
            setNeedsSave(false);
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    function onDiscardClick() {
        setNeedsSave(false);
        setSettings(initValues);
    }

    function changeTime(idx: number, time: string) {
        const slots = settings.slots!;
        slots[idx].time = time;
        setSettings({
            ...settings,
            slots,
        });
        setNeedsSave(true);
    }

    function changeDay(idx: number, day: string) {
        const slots = settings.slots!;
        slots[idx].day = day;
        setSettings({
            ...settings,
            slots,
        });
        setNeedsSave(true);
    }

    function removeEntry(idx: number) {
        const slots = settings.slots!;
        slots.splice(idx, 1);
        setSettings({
            ...settings,
            slots,
        });
        setNeedsSave(true);
    }

    return (
        <>
            <ContentSettingsWithSwitchCard
                title="Maintenance windows"
                subtitle="Define when maintenance on your channels should take place"
                showSwitch={false}
                onSaveClick={onSave}
                needsSave={needsSave}
                onDiscardClick={onDiscardClick}
                isLoading={loading}
            >
                <HESPFormLabel label="Type:" />
                <TypeWrapper>
                    <MaintenanceTypeSelector
                        selectedType={settings.type}
                        onSelectType={(type: MaintenanceType) => {
                            setSettings({
                                ...settings,
                                type,
                            });
                            setNeedsSave(true);
                        }}
                    />
                </TypeWrapper>
                <ScheduleWrapper>
                    {settings.type === MaintenanceType.scheduled && (
                        <MaintenanceWindowFixedSlots
                            settings={settings}
                            changeTimeOfSlot={(idx: number, time: string) => changeTime(idx, time)}
                            changeDayOfSlot={(idx: number, day: string) => changeDay(idx, day)}
                            addEntry={() => {
                                setSettings({
                                    ...settings,
                                    slots: settings.slots!.concat([
                                        {
                                            day: "Monday",
                                            time: "10:00",
                                        },
                                    ]),
                                });
                                setNeedsSave(true);
                            }}
                            removeEntry={(idx: number) => removeEntry(idx)}
                        />
                    )}
                    {settings.type === MaintenanceType.recurring && (
                        <MaintenanceWindowRecurring
                            settings={settings}
                            onAmountChange={(amount: number) => {
                                setSettings({
                                    ...settings,
                                    interval: {
                                        ...settings.interval!,
                                        amount,
                                    },
                                });
                                setNeedsSave(true);
                            }}
                            onPeriodChange={(period: MaintenanceRecurringPeriodType) => {
                                setSettings({
                                    ...settings,
                                    interval: {
                                        ...settings.interval!,
                                        amount: 1,
                                        period,
                                    },
                                });
                                setNeedsSave(true);
                            }}
                            onTimeChange={(time: string) => {
                                setSettings({
                                    ...settings,
                                    interval: {
                                        ...settings.interval!,
                                        time,
                                    },
                                });
                                setNeedsSave(true);
                            }}
                        />
                    )}
                </ScheduleWrapper>
            </ContentSettingsWithSwitchCard>
            <ToastContainer />
        </>
    );
}

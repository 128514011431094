import * as React from "react";
import styled from "styled-components";
import UsefulLink from "../../UsefulLink/UsefulLink";

const Title = styled.h1`
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const Wrapper = styled.div`
    padding: 10px 20px;
    background-color: #fafafa;
    border-radius: 8px;
`;

const Links = styled.div``;

function ChannelCustomizationUsefulLinks() {
    return (
        <Wrapper>
            <Title>Useful links</Title>
            <Links>
                <UsefulLink
                    type="guide"
                    title="Including a THEOlive player on your page"
                    description="Check out how to include a THEOlive player on your webpage and share your stream to an unlimited amount of viewers, at sub-second latency."
                    link={`https://developers.theo.live/docs/including-a-theolive-player-on-your-page`}
                />
                <UsefulLink
                    type="guide"
                    title="Customize your THEOlive player"
                    description="Learn about all the customization options THEOlive has to offer. Apply your own style to the player."
                    link={`https://developers.theo.live/docs/customize-your-theolive-player`}
                />
                <UsefulLink
                    type="guide"
                    title="How to auto-size my THEOlive player"
                    description="A fixed player width and height might not always serve your needs. See how to grow your player dynamically with the size of your page."
                    link={`https://developers.theo.live/docs/how-to-auto-size-my-player`}
                />
            </Links>
        </Wrapper>
    );
}

export default ChannelCustomizationUsefulLinks;

import {
    AddOutlined,
    Check,
    Close,
    FavoriteBorderOutlined,
    KeyboardArrowRight,
    WarningAmberOutlined,
} from "@mui/icons-material";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { ChangelogType } from "../../__generated__/globalTypes";
import { GetChangelogQuery_changelog } from "./__generated__/GetChangelogQuery";

interface Props {
    item: GetChangelogQuery_changelog;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 8px;
    cursor: pointer;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const TypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-width: 50px;
`;

const DataWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const IconWrapper = styled.div.attrs((props: { type: ChangelogType }) => ({
    type: props.type,
}))`
    padding: 2px;
    background-color: ${(props) => getColor(props.type)};
    border-radius: 4px;
    height: 23px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-content: center;
`;

const TypeText = styled.div`
    margin-top: 3px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 16px;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
`;

const Description = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.gray};
    margin: 5px 0px;
`;

const Timestamp = styled.div`
    text-align: right;
    font-size: 11px;
    font-style: italic;
`;

function getColor(type: ChangelogType): string {
    switch (type) {
        case ChangelogType.added:
            return "#12ca93";
        case ChangelogType.deprecated:
            return "#f7c543";
        case ChangelogType.improved:
            return "#8470be";
        case ChangelogType.fixed:
            return "#118cfd";
        case ChangelogType.removed:
            return "#e95f6a";
    }
}

export default function ChangelogItem({ item }: Props) {
    const { createdAt, metadata, slug, type } = item;
    const { description, title } = metadata;
    return (
        <Wrapper onClick={() => window.open(`https://developers.theo.live/changelog/${slug}`)}>
            <DataWrapper>
                <TypeWrapper>
                    <IconWrapper type={type}>{getIcon(type)}</IconWrapper>
                    <TypeText>{type}</TypeText>
                </TypeWrapper>
                <ContentWrapper>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    <Timestamp>{moment(createdAt).format("MMM DD, YYYY")}</Timestamp>
                </ContentWrapper>
            </DataWrapper>
            <div>
                <KeyboardArrowRight color="primary" />
            </div>
        </Wrapper>
    );
}

function getIcon(type: ChangelogType) {
    const iconStyle = { color: "white", fontSize: "20px" };

    switch (type) {
        case ChangelogType.added:
            return <AddOutlined style={iconStyle} />;
        case ChangelogType.improved:
            return <FavoriteBorderOutlined style={iconStyle} />;
        case ChangelogType.fixed:
            return <Check style={iconStyle} />;
        case ChangelogType.deprecated:
            return <WarningAmberOutlined style={iconStyle} />;
        case ChangelogType.removed:
            return <Close style={iconStyle} />;
    }
}

import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { FileCopy } from "@mui/icons-material";

import styled from "styled-components";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

interface Props {
    title: string;
    value: string;
}

const Wrapper = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
`;

const CopyWrapper = styled.div`
    border: 1px solid black;
    border-radius: 4px;
    height: 40px;
    background-color: #f7f7f7;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
`;

const Text = styled.div`
    @media (max-width: 420px) {
        white-space: nowrap;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const IconWrapper = styled.div`
    background-color: #bdbdbd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px;
    border-left: 1px solid black;
`;

function CopyLabel({ title, value }: Props) {
    async function copyValue() {
        try {
            await copyToClipboard(value);
            toast.success("Value copied", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Error while copying", TOAST_SETTINGS);
        }
    }

    return (
        <Wrapper>
            <Label>{title}</Label>
            <CopyWrapper>
                <Text>{value}</Text>
                <IconWrapper>
                    <Tooltip title="Copy">
                        <IconButton size="small" onClick={copyValue}>
                            <FileCopy fontSize="small" style={{ color: "black" }} />
                        </IconButton>
                    </Tooltip>
                </IconWrapper>
            </CopyWrapper>
        </Wrapper>
    );
}

export default CopyLabel;

export async function copyToClipboard(text: string) {
    // eslint-disable-next-line no-undef
    const myNavigator = navigator as any;

    if ((!myNavigator.clipboard && !myNavigator.clipboard.writeText) || !text) {
        throw new Error("Error copying to clipboard");
    }

    // eslint-disable-next-line no-undef
    const result = await (navigator as any).clipboard.writeText(text);

    return result;
}

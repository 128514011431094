import { DialogContent, Grid, FormControl, TextField, DialogActions } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { GET_FULL_BILLING_DETAILS } from "../BillingDetails/BillingDetailsCardContent";
import { GetFullBillingDetails_organization } from "../BillingDetails/__generated__/GetFullBillingDetails";
import DialogHESP from "../DialogHESP/DialogHESP";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { UpdatePaygPricing, UpdatePaygPricingVariables } from "./__generated__/UpdatePaygPricing";

interface Props {
    open: boolean;
    onClose: () => void;
    organization: GetFullBillingDetails_organization;
}

interface InputValues {
    transcodingCost: number;
    viewingCost: number;
}

export const UPDATE_PAYG_PRICING = gql`
    mutation UpdatePaygPricing($input: UpdatePaygPricingInput!) {
        updatePaygPricing(input: $input) {
            organizationId
        }
    }
`;

export default function ChangePaygPricingDialog({ open, onClose, organization }: Props) {
    const { organizationId, paygPricing } = organization;
    const { transcodingCost, viewingCost } = paygPricing;
    const [inputValues, setInputValues] = React.useState<InputValues>({ transcodingCost, viewingCost });

    const [updatePricingMut, { loading }] = useMutation<UpdatePaygPricing, UpdatePaygPricingVariables>(
        UPDATE_PAYG_PRICING,
    );

    async function onUpdate() {
        try {
            await updatePricingMut({
                variables: {
                    input: {
                        organizationId,
                        transcodingCost: inputValues.transcodingCost,
                        viewingCost: inputValues.viewingCost,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_FULL_BILLING_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("PAYG pricing updated");
            onClose();
        } catch (_e) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    return (
        <DialogHESP
            title={"Change PAYG pricing"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent style={{ overflowY: "hidden", marginTop: "20px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Price per transcoded minute" />
                                    <TextField
                                        type="number"
                                        value={inputValues.transcodingCost}
                                        onChange={(e) =>
                                            setInputValues({
                                                ...inputValues,
                                                transcodingCost: parseFloat(e.target.value),
                                            })
                                        }
                                        fullWidth
                                        InputProps={{ inputProps: { min: 0, max: 100, step: 0.01 } }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Price per viewed minute" />
                                    <TextField
                                        type="number"
                                        value={inputValues.viewingCost}
                                        onChange={(e) =>
                                            setInputValues({
                                                ...inputValues,
                                                viewingCost: parseFloat(e.target.value),
                                            })
                                        }
                                        fullWidth
                                        InputProps={{ inputProps: { min: 0, max: 100, step: 0.00001 } }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Update"}
                            loadingText={"Updating..."}
                            onActionClick={onUpdate}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import * as React from "react";
import styled from "styled-components";

interface Props {
    selected: boolean;
    icon: React.ReactNode;
    onClick: () => void;
    title: string;
    description: string;
}

const Wrapper = styled.div.attrs((props: { selected: boolean }) => ({
    selected: props.selected,
}))`
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-right: 16px;
    padding: 10px;
    width: 280px;
    margin-bottom: 16px;
    cursor: pointer;
    background-color: ${(props) => (props.selected ? "#fffcf2" : "white")};
    border: 1px solid ${(props) => (props.selected ? props.theme.primary : props.theme.lightGrayBottom)};
    @media (max-width: 620px) {
        width: 100%;
    }
`;

const TextPart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
`;

const IconPart = styled.div`
    width: 50px;
`;

const Title = styled.div`
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 14px;
`;

const Description = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 13px;
`;

export default function TypeSelectorItemWithIcon({ selected, description, icon, onClick, title }: Props) {
    return (
        <Wrapper selected={selected} onClick={onClick}>
            <IconPart>{icon}</IconPart>
            <TextPart>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </TextPart>
        </Wrapper>
    );
}

import { FileCopyOutlined, HighlightOff } from "@mui/icons-material";
import { Tab, Tabs, useTheme } from "@mui/material";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";

import styled from "styled-components";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import { copyToClipboard } from "../../CopyLabel/CopyLabel";

const TabWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
`;

const TabTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
`;

const DisabledIconWrapper = styled.div`
    margin-left: 10px;
`;

const TabDescription = styled.div`
    margin-top: 5px;
    font-size: 11px;
`;

export interface ChannelWithName {
    channelId: string;
    name: string;
    isDefault: boolean;
    isActive: boolean;
}

interface Props {
    channelAndAliases: ChannelWithName[];
    selectedAlias: string;
    onChange: (alias: string) => void;
}

function ChannelPlayoutsSelector({ selectedAlias, onChange, channelAndAliases }: Props) {
    async function copyValue(value: string) {
        await copyToClipboard(value);
        toast.success("Value copied", TOAST_SETTINGS);
    }

    const theme = useTheme();

    return (
        <>
            <Tabs
                value={selectedAlias}
                onChange={(_ignore, value: string) => onChange(value)}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                textColor={"secondary"}
                indicatorColor={"secondary"}
                style={{
                    backgroundColor: theme.palette.secondary.light,
                    paddingBottom: "5px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
            >
                {channelAndAliases.map((c, idx) => (
                    <Tab
                        key={`alias-tab-${idx}`}
                        value={c.channelId}
                        label={
                            <TabWrapper>
                                <TitleWrapper>
                                    <TabTitle>{c.isDefault ? "Default" : c.name}</TabTitle>
                                    {!c.isActive && (
                                        <DisabledIconWrapper>
                                            <HighlightOff style={{ fontSize: "18px" }} />
                                        </DisabledIconWrapper>
                                    )}
                                </TitleWrapper>
                                <TitleWrapper>
                                    <TabDescription>{c.channelId}</TabDescription>
                                    <div
                                        style={{ fontSize: "14px", paddingTop: "5px", paddingLeft: "10px" }}
                                        onClick={() => copyValue(c.channelId)}
                                    >
                                        <FileCopyOutlined style={{ fontSize: "14px" }} />
                                    </div>
                                </TitleWrapper>
                            </TabWrapper>
                        }
                    />
                ))}
            </Tabs>
            <ToastContainer />
        </>
    );
}

export default ChannelPlayoutsSelector;

import styled from "styled-components";

interface Props {
    amount: number;
    icon: React.ReactNode;
    title: string;
}

const Wrapper = styled.div`
    background-color: white;
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
`;

const InfoWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
`;

const Number = styled.div`
    font-weight: bold;
    font-size: 24px;
`;

const Title = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 13px;
`;

export default function SchedulerAnalyticsCard({ amount, title, icon }: Props) {
    return (
        <Wrapper>
            <>{icon}</>
            <InfoWrapper>
                <Number>{amount}</Number> <Title>{title}</Title>
            </InfoWrapper>
        </Wrapper>
    );
}

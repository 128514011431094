import { useMutation } from "@apollo/react-hooks";
import { DialogActions, DialogContent } from "@mui/material";
import gql from "graphql-tag";
import React from "react";
import { useForm } from "react-hook-form";
import DialogHESP from "../DialogHESP/DialogHESP";
import {
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables,
} from "./__generated__/CreateOrganizationMutation";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import CreateOrganizationForm, { CreateOrganizationFormData } from "./CreateOrganizationForm";
import { GET_ORGANIZATIONS_QUERY } from "../OrganizationsOverview/OrganizationsOverview";
import { navigate } from "@reach/router";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import Callout from "../Callout/Callout";

export const CREATE_ORGANIZATION_MUTATION = gql`
    mutation CreateOrganizationMutation($input: CreateOrganizationInput!) {
        createOrganization(input: $input) {
            organizationId
        }
    }
`;

interface Props {
    onClose: () => void;
    open: boolean;
}

function CreateOrganizationDialog({ open, onClose }: Props) {
    const [isIndividual, setIsIndividual] = React.useState<boolean>(false);
    const { register, errors, handleSubmit, getValues } = useForm<CreateOrganizationFormData>();
    const [showError, setShowError] = React.useState<boolean>(false);

    const [createOrganizationMut, { loading }] = useMutation<
        CreateOrganizationMutation,
        CreateOrganizationMutationVariables
    >(CREATE_ORGANIZATION_MUTATION);

    const onCreateOrganization = handleSubmit(
        async ({ name, primaryEmail, contactEmail }: CreateOrganizationFormData) => {
            setShowError(false);
            try {
                const organization = await createOrganizationMut({
                    variables: {
                        input: {
                            isIndividual,
                            name,
                            primaryEmail,
                            contactEmail,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_ORGANIZATIONS_QUERY,
                        },
                    ],
                });
                toast.success("Organization created! 🚀", TOAST_SETTINGS);
                if (organization.data) {
                    navigate(`/app/${organization.data.createOrganization.organizationId}/manage-organization`);
                }
            } catch (e: any) {
                if (e.graphQLErrors[0].message === "USER_ALREADY_CONNECTED") {
                    setShowError(true);
                }
            }
        },
    );

    return (
        <>
            <DialogHESP
                title={"Create new company/individual"}
                open={open}
                onClose={onClose}
                content={
                    <form onSubmit={handleSubmit((data1, e) => onCreateOrganization(e))}>
                        <DialogContent>
                            {showError && (
                                <Callout
                                    type="danger"
                                    title="User already connected"
                                    description={`${
                                        getValues().primaryEmail
                                    } is already connected to another THEOlive organization`}
                                />
                            )}

                            <CreateOrganizationForm
                                register={register}
                                errors={errors}
                                isIndividual={isIndividual}
                                onChangeIndividual={() => setIsIndividual(!isIndividual)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <DialogFooterWithLoading
                                isLoading={loading}
                                onCancelClick={onClose}
                                defaultText={"Create"}
                                loadingText={"Creating.."}
                            />
                        </DialogActions>
                    </form>
                }
            />
            <ToastContainer />
        </>
    );
}

export default CreateOrganizationDialog;

import * as React from "react";
import { Grid } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";

interface Props {
    startDate: moment.Moment;
    endDate: moment.Moment;
    onSetStart: (date: moment.Moment) => void;
    onSetEnd: (date: moment.Moment) => void;
}

function UsageSettings({ startDate, endDate, onSetStart, onSetEnd }: Props) {
    return (
        <Grid container spacing={4}>
            <Grid item xs={6} lg={3}>
                <HESPFormLabel label="Start" />
                <DatePicker
                    openTo="day"
                    format="YYYY/MM/DD"
                    views={["day"]}
                    value={startDate}
                    onChange={(newValue: moment.Moment | null) => {
                        if (newValue !== null) {
                            onSetStart(newValue);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <HESPFormLabel label="End" />
                <DatePicker
                    disableFuture
                    openTo="day"
                    format="YYYY/MM/DD"
                    views={["day"]}
                    value={endDate}
                    onChange={(newValue: moment.Moment | null) => {
                        if (newValue !== null) {
                            onSetEnd(newValue);
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default UsageSettings;

import { Grid } from "@mui/material";
import { THEO_ID } from "../../views/App/AppRoot";
import ProfilesCard from "./Profiles/ProfilesCard";

export default function AdminConfigProfilesContent() {
    return (
        <div style={{ marginTop: "12px" }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <ProfilesCard organizationId={THEO_ID} />
                </Grid>
            </Grid>
        </div>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import { Table, TableHead, TableRow, TableCell, TableBody, Avatar, IconButton, Tooltip } from "@mui/material";
import { DeleteForever, Mail } from "@mui/icons-material";
import { TableWrapper } from "../Channels/Overview/ChannelsOverview";
import { UserRoleType } from "../../__generated__/globalTypes";
import { toast, ToastContainer } from "react-toastify";
import AuthService from "../../auth/AuthService";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { ResendActivationLink, ResendActivationLinkVariables } from "./__generated__/ResendActivationLink";
import UserRoleSwitch from "./UserRoleSwitch";
import {
    GetOrganizationUsers,
    GetOrganizationUsersVariables,
    GetOrganizationUsers_organization_users,
} from "./__generated__/GetOrganizationUsers";
import { useQuery } from "react-apollo";
import { GET_ORGANIZATION_USERS_QUERY } from "./TeamOverview";
import CentralLoader from "../Loading/CentralLoader";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const RESEND_ACTIVATION_LINK_MUTATION = gql`
    mutation ResendActivationLink($input: ResendActivationLinkInput!) {
        resendActivationLink(input: $input) {
            auth0Id
        }
    }
`;

interface Props {
    organizationId: string;
    // eslint-disable-next-line no-unused-vars
    onDeleteUser: (user: GetOrganizationUsers_organization_users) => void;
}

function TeamOverviewTable({ organizationId, onDeleteUser }: Props) {
    const MAX_WIDTH_ROLE_CELL = "70px";

    const [resendActivationLinkMut] = useMutation<ResendActivationLink, ResendActivationLinkVariables>(
        RESEND_ACTIVATION_LINK_MUTATION,
    );

    const { data, loading } = useQuery<GetOrganizationUsers, GetOrganizationUsersVariables>(
        GET_ORGANIZATION_USERS_QUERY,
        {
            variables: {
                organizationId,
            },
        },
    );

    if (loading || !data) {
        return <CentralLoader text="Getting team members..." />;
    }

    const users = data.organization.users;

    async function onResendActivationLink(user: GetOrganizationUsers_organization_users) {
        try {
            toast.info("Sending activation link...", TOAST_SETTINGS);
            await resendActivationLinkMut({
                variables: {
                    input: {
                        auth0Id: user.auth0Id,
                    },
                },
            });
            toast.success("Activation link sent! 🚀", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    return (
        <>
            <TableWrapper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="center" style={{ maxWidth: MAX_WIDTH_ROLE_CELL }}>
                                Admin
                            </TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <Avatar
                                        alt="Person"
                                        style={{
                                            cursor: "pointer",
                                            width: "35px",
                                            height: "35px",
                                        }}
                                        src={user.picture !== null ? user.picture : ""}
                                    />
                                </TableCell>
                                <TableCell>
                                    {user.email}
                                    {user.activation && !user.activation.activated && (
                                        <span style={{ fontStyle: "italic", marginLeft: "10px", fontSize: "11px" }}>
                                            (pending)
                                        </span>
                                    )}
                                </TableCell>
                                <TableCell align="center" style={{ maxWidth: MAX_WIDTH_ROLE_CELL }}>
                                    <UserRoleSwitch userId={user.auth0Id} roles={user.roles} />
                                </TableCell>
                                <TableCell align="right">
                                    {getActions(
                                        user,
                                        (userToDelete: GetOrganizationUsers_organization_users) =>
                                            onDeleteUser(userToDelete),
                                        (userToResendTo: GetOrganizationUsers_organization_users) =>
                                            onResendActivationLink(userToResendTo),
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
            <ToastContainer />
        </>
    );
}

export default TeamOverviewTable;

// eslint-disable-next-line no-unused-vars
function getActions(
    user: GetOrganizationUsers_organization_users,
    onDeleteUser: (userToDelete: GetOrganizationUsers_organization_users) => void,
    onResendActivationLink: (userToResendTo: GetOrganizationUsers_organization_users) => void,
) {
    const isNormalUser = AuthService.getLocalUserProfile()?.roles.includes(UserRoleType.hesp_user);
    const isCurrentUser = AuthService.getLocalUserProfile()?.auth0Id === user.auth0Id;

    if (isNormalUser || isCurrentUser) {
        return <div></div>;
    }

    return (
        <>
            {user.activation && !user.activation.activated && (
                <Tooltip title="Resend activation link" placement="top">
                    <IconButton onClick={() => onResendActivationLink(user)}>
                        <Mail fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
            {user.auth0Id !== AuthService.getLocalUserProfile()?.auth0Id && (
                <Tooltip title="Delete" placement="top">
                    <IconButton onClick={() => onDeleteUser(user)}>
                        <DeleteForever fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
}

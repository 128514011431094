import { Grid, Button, Hidden } from "@mui/material";
import * as React from "react";

import webhooksImg from "../../img/webhooks.png";

import styled from "styled-components";

const Wrapper = styled.div`
    display flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 22px;
`;

const Description = styled.div`
    margin-top: 18px;
    font-size: 16px;
    max-width: 600px;
    text-align: center;
    margin-bottom: 30px;
`;

interface Props {
    onCreateClick: () => void;
}

function NoWebhooks({ onCreateClick }: Props) {
    return (
        <Wrapper>
            <Hidden smDown>
                <ImageWrapper>
                    <img src={webhooksImg} alt="webhooks" />
                </ImageWrapper>
            </Hidden>
            <Title>Get informed by THEOlive, in real-time</Title>
            <Description>
                Create a webhook endpoint on your server and receive real-time messages from THEOlive when specific
                events happen.
            </Description>
            <Grid container spacing={4} justifyContent="center">
                <Grid item>
                    <Button color="primary" variant="contained" size="large" onClick={onCreateClick}>
                        Create your first
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        variant="outlined"
                        size="large"
                        onClick={() => window.open("https://developers.theo.live/docs/real-time-updates-with-webhooks")}
                    >
                        Read the docs
                    </Button>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default NoWebhooks;

import { Grid } from "@mui/material";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChannelPlayoutFailoverManager from "./ChannelPlayoutFailoverManager";
import { useQuery } from "react-apollo";
import { GET_CHANNELS_ORGANIZATION_QUERY } from "../Overview/channelQueriesMutations";
import {
    GetChannelsOrganizationQuery,
    GetChannelsOrganizationQueryVariables,
} from "../Overview/__generated__/GetChannelsOrganizationQuery";

interface Props {
    channel: GetChannelQuery_channel;
    playoutId: string;
}

export interface PlayoutSecuritySettings {
    channelId: string;
    parentChannelId: string;
    isAlias: boolean;
    geoBlocking: {
        enabled: boolean;
        allowedCountries: string[];
    };
    jwt: {
        enabled: boolean;
        key: string;
    };
}

function ChannelPlayoutFailover({ channel, playoutId }: Props) {
    const { data, loading } = useQuery<GetChannelsOrganizationQuery, GetChannelsOrganizationQueryVariables>(
        GET_CHANNELS_ORGANIZATION_QUERY,
        {
            variables: {
                organizationId: channel.organizationId,
            },
        },
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {!loading && data && (
                    <ChannelPlayoutFailoverManager
                        channel={channel}
                        playoutId={playoutId}
                        allParentChannels={data.channels}
                    />
                )}
            </Grid>
        </Grid>
    );
}

export default ChannelPlayoutFailover;

import gql from "graphql-tag";
import moment from "moment";
import { useQuery } from "react-apollo";
import CardHESP from "../CardHESP/CardHESP";
import { SkeletonRectangle } from "../Loading/Skeletons";
import EventsData from "./EventsData";
import * as React from "react";
import EventsFilters from "./EventsFilters";
import {
    GetOrganizationEventsQuery,
    GetOrganizationEventsQueryVariables,
} from "./__generated__/GetOrganizationEventsQuery";

interface Props {
    organizationId: string;
    showFilters: boolean;
}

export const GET_ORGANIZATION_EVENTS = gql`
    query GetOrganizationEventsQuery(
        $startingAfter: Int!
        $organizationId: ID!
        $startDate: String!
        $endDate: String!
        $types: [String!]
        $objectId: String
    ) {
        organizationEvents(
            organizationId: $organizationId
            startDate: $startDate
            endDate: $endDate
            startingAfter: $startingAfter
            types: $types
            objectId: $objectId
        ) {
            events {
                eventId
                objectId
                type
                timestamp
                data
            }
            hasMore
        }
    }
`;

export interface EventFiltersArgs {
    startDate: string;
    endDate: string;
    objectId?: string;
    types?: string[];
}

const initValues: EventFiltersArgs = {
    startDate: moment().subtract(30, "days").startOf("day").toISOString(),
    endDate: moment().endOf("day").toISOString(),
    objectId: undefined,
    types: undefined,
};

export default function EventsOverview({ organizationId, showFilters }: Props) {
    const [filters, setFilters] = React.useState<EventFiltersArgs>(initValues);

    React.useEffect(() => {
        if (!showFilters) {
            setFilters(initValues);
        }
    }, [showFilters]);

    const { data, loading, fetchMore } = useQuery<GetOrganizationEventsQuery, GetOrganizationEventsQueryVariables>(
        GET_ORGANIZATION_EVENTS,
        {
            variables: {
                organizationId,
                startingAfter: 0,
                startDate: filters.startDate,
                endDate: filters.endDate,
                objectId: filters.objectId,
                types: filters.types,
            },
        },
    );

    function onMoreClick() {
        const events = data!.organizationEvents.events;
        fetchMore({
            variables: {
                organizationId,
                startingAfter: events.length,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                const res: GetOrganizationEventsQuery = {
                    organizationEvents: {
                        __typename: "EventsListResult",
                        events: [
                            ...previousQueryResult.organizationEvents.events,
                            ...fetchMoreResult.organizationEvents.events,
                        ],
                        hasMore: fetchMoreResult.organizationEvents.hasMore,
                    },
                };

                return res;
            },
        });
    }

    return (
        <CardHESP>
            {showFilters && (
                <EventsFilters
                    filters={filters}
                    onApplyFilters={(newFilters: EventFiltersArgs) => {
                        setFilters(newFilters);
                    }}
                />
            )}
            {loading || !data ? (
                <div>
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                </div>
            ) : (
                <EventsData
                    hasMore={data.organizationEvents.hasMore}
                    events={data.organizationEvents.events}
                    onMoreClick={onMoreClick}
                    organizationId={organizationId}
                />
            )}
        </CardHESP>
    );
}

import { Grid, FormControl, Button } from "@mui/material";
import { getData } from "country-list";
import * as React from "react";
import AutocompleteDropdown from "../../AutocompleteDropdown/AutocompleteDropdown";

interface Props {
    alreadySelectedCountries: string[];
    onAddCountry: (countryCode: string) => void;
}

function GeoBlockingAddCountry({ onAddCountry, alreadySelectedCountries }: Props) {
    const [selectedCountry, setSelectedCountry] = React.useState<string | undefined>("");

    function onAdd() {
        if (selectedCountry) {
            onAddCountry(selectedCountry as string);
        }
        setSelectedCountry(undefined);
    }

    const countries = getData()
        .filter((country) => !alreadySelectedCountries.includes(country.code))
        .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });

    return (
        <Grid container spacing={2} alignContent="center" alignItems={"center"}>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    {/* <TextField
                        select
                        size="small"
                        name="country"
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        variant="outlined"
                        value={selectedCountry}
                        placeholder="Select a country"
                        InputProps={{
                            style: {
                                fontSize: "14px",
                                paddingTop: "0px",
                                height: "42px",
                                lineHeight: "20px",
                                background: "white",
                            },
                        }}
                    >
                        <MenuItem value="none" disabled style={{ fontSize: "14px" }}>
                            Select a country
                        </MenuItem>
                        {countries.map((c) => {
                            return (
                                <MenuItem value={c.code.toUpperCase()} key={c.code} style={{ fontSize: "14px" }}>
                                    {c.name}
                                </MenuItem>
                            );
                        })} 
                        
                        </TextField>
                        */}
                    <AutocompleteDropdown
                        title="Country"
                        items={countries.map((c) => ({
                            id: c.code,
                            name: c.name,
                        }))}
                        selectedItem={selectedCountry}
                        onSelectItem={(country: string) => setSelectedCountry(country)}
                        onEnterClick={() => onAdd()}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <Button variant="contained" size="small" onClick={onAdd}>
                    Add to list
                </Button>
            </Grid>
        </Grid>
    );
}

export default GeoBlockingAddCountry;

import { Grid, DialogActions, Typography, DialogContent, useTheme } from "@mui/material";
import * as React from "react";
import DialogHESP from "../DialogHESP/DialogHESP";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { AddPaymentMethodMutation, AddPaymentMethodMutationVariables } from "./__generated__/AddPaymentMethodMutation";
import { GetFullBillingDetails_organization_billingDetails } from "./__generated__/GetFullBillingDetails";
import { GET_FULL_BILLING_DETAILS } from "./BillingDetailsCardContent";
import styled from "styled-components";
import { GET_ORGANIZATION_BASICS_QUERY } from "../GetStarted/AccountWarningMessage";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { toast, ToastOptions } from "react-toastify";

export const TOAST_SETTINGS: ToastOptions<{}> = {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

export const ADD_PAYMENT_METHOD_MUTATION = gql`
    mutation AddPaymentMethodMutation($input: AddPaymentMethodInput!) {
        addPaymentMethod(input: $input) {
            organizationId
        }
    }
`;

interface Props {
    open: boolean;
    type: "card";
    organizationId: string;
    organizationName: string;
    onClose: () => void;
    onSuccess: () => void;
    details: GetFullBillingDetails_organization_billingDetails;
}

const CardInput = styled.div`
    border-radius: 4px;
    padding: 12px 8px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

function AddPaymentMethodDialog({ open, onClose, organizationId, onSuccess, type, details }: Props) {
    const stripe = useStripe();
    const elements = useElements();

    const [addPaymentMethodMut, { loading }] = useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(
        ADD_PAYMENT_METHOD_MUTATION,
    );

    const theme = useTheme();

    async function handleSubmit(e: any) {
        if (type === "card") {
            const createPaymentMethodObj = {
                type,
                card: elements!.getElement(CardElement)!,
            };

            const { error, paymentMethod } = await stripe!.createPaymentMethod(createPaymentMethodObj);

            if (error) {
                toast.error("Something went wrong 😞", TOAST_SETTINGS);
            } else if (paymentMethod) {
                const paymentMethodId = paymentMethod.id;
                try {
                    await addPaymentMethodMut({
                        variables: {
                            input: {
                                paymentMethodId,
                                organizationId,
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_FULL_BILLING_DETAILS,
                                variables: {
                                    organizationId,
                                },
                            },
                            {
                                query: GET_ORGANIZATION_BASICS_QUERY,
                                variables: {
                                    organizationId,
                                },
                            },
                        ],
                    });

                    toast.success("Payment method added! 🚀", TOAST_SETTINGS);

                    onSuccess();
                } catch (e: any) {
                    toast.error("Something went wrong 😞", TOAST_SETTINGS);
                }
            }
        }
    }

    return (
        <DialogHESP
            title={`Add credit card`}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ padding: "20px 10px" }}>
                                <CardInput>
                                    {type === "card" && (
                                        <CardElement
                                            options={{ style: { base: { fontSize: "16px" } }, hidePostalCode: true }}
                                        />
                                    )}
                                </CardInput>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                {type === "card" && (
                                    <Typography variant="caption" style={{ color: "#a3a7ad" }}>
                                        Please note that we use Stripe as payment provider, whose{" "}
                                        <a
                                            href="https://stripe.com/en-be/legal/end-users"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: theme.palette.primary.main }}
                                        >
                                            terms and conditions
                                        </a>{" "}
                                        apply to any payment transaction. By providing your credit card number, you
                                        authorize 1) THEO Technologies and Stripe to send instructions to your credit
                                        card provider to debit your account and 2) your credit card provider to act in
                                        accordance with these instructions. This credit card will be utilized for all
                                        future payments, unless we receive further instructions from you.
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Add"}
                            loadingText={"Adding..."}
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default AddPaymentMethodDialog;

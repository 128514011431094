import React from "react";
import styled from "styled-components";
import BillingDetailsData from "./BillingDetailsData";
import gql from "graphql-tag";
import { Button } from "@mui/material";
import BillingDetailsManage from "./BillingDetailsManage";
import { DeepMap, FieldError } from "react-hook-form";
import { ManageBillingDetailsFormData } from "./BillingDetails";
import { GetFullBillingDetails } from "./__generated__/GetFullBillingDetails";
import CentralLoader from "../Loading/CentralLoader";

export const GET_FULL_BILLING_DETAILS = gql`
    query GetFullBillingDetails($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            organizationId
            name
            isIndividual
            organizationStatus
            totalNextInvoice
            nextInvoiceAt
            paymentThreshold
            createdAt
            billingDetails {
                email
                street
                city
                state
                zip
                country
                vat
                paymentMethod {
                    brand
                    last4
                }
            }
            contractType
            hasPaidFirstContractInvoice
            paygPricing {
                transcodingCost
                viewingCost
            }
            contracts {
                start
                end
                id
                durationInMonths
                hasAccessToSD
                overageType
                mrr
                commitment
                billAutomatically
                billOveragesAutomatically
                billInGb
                gbUsedPerMinute
            }
            currentContract {
                start
                end
                id
                hasAccessToSD
                currentPeriod {
                    start
                    end
                    transcodedMinutes
                    viewedMinutes
                }
            }
        }
    }
`;

interface Props {
    details: GetFullBillingDetails | undefined;
    isEditing: boolean;
    onAddDetailsClick: () => void;
    errors: DeepMap<ManageBillingDetailsFormData, FieldError>;
    register: any;
    setValue: any;
    isLoading: boolean;
    onChangeCountry: (event: any) => void;
    selectedCountry: string;
    onCancel: () => void;
    isIndividual: boolean;
    onIsIndividualChange: () => void;
}

const NoDetails = styled.div`
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

function BillingDetailsCardContent({
    details,
    isLoading,
    isEditing,
    onAddDetailsClick,
    errors,
    register,
    onCancel,
    onChangeCountry,
    selectedCountry,
    isIndividual,
    onIsIndividualChange,
}: Props) {
    if (isLoading || !details) {
        return <CentralLoader text="Getting billing details..." />;
    }

    const { billingDetails, name } = details.organization;

    return (
        <React.Fragment>
            {billingDetails === null && !isEditing && (
                <NoDetails>
                    <div>
                        <Button variant="contained" color="primary" onClick={onAddDetailsClick}>
                            Add Billing Details
                        </Button>
                    </div>
                </NoDetails>
            )}
            {isEditing && (
                <BillingDetailsManage
                    details={billingDetails}
                    organizationName={name}
                    errors={errors}
                    selectedCountry={selectedCountry}
                    onChangeCountry={onChangeCountry}
                    register={register}
                    onCancel={onCancel}
                    isIndividual={isIndividual}
                    onIsIndividualChange={onIsIndividualChange}
                />
            )}
            {billingDetails !== null && !isEditing && <BillingDetailsData organization={details.organization} />}
        </React.Fragment>
    );
}

export default BillingDetailsCardContent;

import * as React from "react";
import { SchedulerFilterValues } from "../../views/App/SchedulerOverviewWithFilters";
import { useQuery } from "react-apollo";
import { GET_SCHEDULERS_BASED_ON_PARAMS } from "../../components/SchedulersOverview/SchedulerSection";
import {
    GetSchedulersBasedOnParams,
    GetSchedulersBasedOnParamsVariables,
    GetSchedulersBasedOnParams_schedulersWithParams,
} from "../../components/SchedulersOverview/__generated__/GetSchedulersBasedOnParams";
import styled from "styled-components";
import { Button } from "@mui/material";
import SchedulersOverviewListItem from "./SchedulersOverviewListItem";
import TerminateSchedulerDialog from "./TerminateSchedulerDialog";
import DeleteSchedulerDialog from "./DeleteSchedulerDialog";
import StartSchedulerNowDialog from "./StartSchedulerNowDialog";
import NoSchedulersFound from "./NoSchedulersFound";

interface Props {
    organizationId: string;
    filters: SchedulerFilterValues;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const More = styled.div`
    margin: 10px 0px;
    display: flex;
    width: 100%;
    justify-content: center;
`;

export default function SchedulersOverviewList({ filters, organizationId }: Props) {
    const [selectedScheduler, setSelectedScheduler] = React.useState<
        GetSchedulersBasedOnParams_schedulersWithParams | undefined
    >(undefined);
    const [openDeleteScheduler, setOpenDeleteScheduler] = React.useState<boolean>(false);
    const [openTerminateScheduler, setOpenTerminateScheduler] = React.useState<boolean>(false);
    const [openStartSchedulerNow, setOpenStartSchedulerNow] = React.useState<boolean>(false);

    const [list, setList] = React.useState<GetSchedulersBasedOnParams_schedulersWithParams[]>([]);
    const [showMore, setShowMore] = React.useState<boolean>(false);

    const USED_LIMIT = 20;

    const { endDate, phases, search, sortBy, sortDirection, startDate } = filters;

    const { data, fetchMore } = useQuery<GetSchedulersBasedOnParams, GetSchedulersBasedOnParamsVariables>(
        GET_SCHEDULERS_BASED_ON_PARAMS,
        {
            variables: {
                limit: USED_LIMIT,
                orderBy: sortBy,
                orderDirection: sortDirection,
                organizationId: organizationId!,
                page: 1,
                end: endDate,
                start: startDate,
                phases,
                ...(search.trim().length > 0 && { search }),
            },
            onCompleted(data) {
                setList(data.schedulersWithParams);
                setShowMore(data.schedulersWithParams.length >= USED_LIMIT);
            },
        },
    );

    function onMoreClick() {
        const schedulers = data!.schedulersWithParams;
        fetchMore({
            variables: {
                limit: USED_LIMIT,
                orderBy: sortBy,
                orderDirection: sortDirection,
                organizationId: organizationId!,
                page: parseInt((schedulers.length / USED_LIMIT).toString()) + 1,
                end: endDate,
                start: startDate,
                phases,
                ...(search.trim().length > 0 && { search }),
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                const res: GetSchedulersBasedOnParams = {
                    schedulersWithParams: [
                        ...previousQueryResult.schedulersWithParams,
                        ...fetchMoreResult.schedulersWithParams,
                    ],
                };

                setList(res.schedulersWithParams);

                if (
                    fetchMoreResult.schedulersWithParams.length < USED_LIMIT ||
                    res.schedulersWithParams.length === schedulers.length
                ) {
                    setShowMore(false);
                }
                return res;
            },
        });
    }

    function onDeleteScheduler(scheduler: GetSchedulersBasedOnParams_schedulersWithParams) {
        setSelectedScheduler(scheduler);
        setOpenDeleteScheduler(true);
    }

    function onTerminateScheduler(scheduler: GetSchedulersBasedOnParams_schedulersWithParams) {
        setSelectedScheduler(scheduler);
        setOpenTerminateScheduler(true);
    }

    function onStartSchedulerNow(scheduler: GetSchedulersBasedOnParams_schedulersWithParams) {
        setSelectedScheduler(scheduler);
        setOpenStartSchedulerNow(true);
    }

    function onDone() {
        setList(list.filter((item) => item.schedulerId !== selectedScheduler?.schedulerId));
    }

    return (
        <>
            <Wrapper>
                {list.length === 0 && <NoSchedulersFound />}
                {list.length > 0 &&
                    list.map((scheduler) => (
                        <SchedulersOverviewListItem
                            key={`sched-${scheduler.schedulerId}`}
                            item={scheduler}
                            onDelete={() => onDeleteScheduler(scheduler)}
                            onTerminate={() => onTerminateScheduler(scheduler)}
                            onStart={() => onStartSchedulerNow(scheduler)}
                        />
                    ))}
                <More>
                    {showMore && (
                        <Button color="secondary" onClick={onMoreClick}>
                            Load more
                        </Button>
                    )}
                </More>
            </Wrapper>
            {selectedScheduler && (
                <>
                    <StartSchedulerNowDialog
                        onClose={() => setOpenStartSchedulerNow(false)}
                        open={openStartSchedulerNow}
                        scheduler={{
                            id: selectedScheduler?.schedulerId,
                            name: selectedScheduler?.name,
                            organizationId,
                        }}
                        onDone={onDone}
                    />
                    <DeleteSchedulerDialog
                        open={openDeleteScheduler}
                        onClose={() => setOpenDeleteScheduler(false)}
                        scheduler={{
                            id: selectedScheduler?.schedulerId,
                            name: selectedScheduler?.name,
                            organizationId,
                        }}
                        onDone={onDone}
                        view="overview"
                    />
                    <TerminateSchedulerDialog
                        open={openTerminateScheduler}
                        onClose={() => setOpenTerminateScheduler(false)}
                        onDone={onDone}
                        scheduler={{
                            id: selectedScheduler?.schedulerId,
                            name: selectedScheduler?.name,
                            organizationId,
                        }}
                    />
                </>
            )}
        </>
    );
}

import * as React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import styled from "styled-components";
import { HESPlink } from "./SignUpComplete";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { VerifiedAccountMutation, VerifiedAccountMutationVariables } from "./__generated__/VerifiedAccountMutation";
import { parse } from "query-string";
import { useLocation } from "@reach/router";

const Content = styled.div`
    margin-top: 30px;
    margin-bottom: 10px;
`;

const LinkToLogin = styled.div`
    margin-top: 30px;
    text-align: center;
`;

interface Props {
    toSignIn: () => void;
}

export const VERIFIED_ACCOUNT_MUTATION = gql`
    mutation VerifiedAccountMutation($input: VerifiedAccountInput!) {
        verifiedAccount(input: $input)
    }
`;

function AccountVerified({ toSignIn }: Props) {
    const location = useLocation();

    const [verifiedAccountMut] = useMutation<VerifiedAccountMutation, VerifiedAccountMutationVariables>(
        VERIFIED_ACCOUNT_MUTATION,
    );

    React.useEffect(() => {
        async function verifyAccount() {
            const searchParams = parse(location.search);
            const { email } = searchParams;
            await verifiedAccountMut({
                variables: {
                    input: {
                        email: email! as string,
                    },
                },
            });
        }
        verifyAccount();
    }, [verifiedAccountMut, location.search]);

    return (
        <>
            <SectionTitle title="Account verified!" align="center" />
            <Content>
                <p>Your email address have been verified, you can now sign in to THEOlive.</p>
                <LinkToLogin>
                    <HESPlink link={"login"}>Go to Sign In</HESPlink>
                </LinkToLogin>
            </Content>
        </>
    );
}

export default AccountVerified;

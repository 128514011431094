import * as React from "react";
import styled from "styled-components";
import CustomizeItemDropdown, { CustomizeItemDropdownValue } from "./CustomizeItemDropdown";
import CustomizeItemSlider from "./CustomizeItemSlider";
import CustomizeItemTextField from "./CustomizeItemTextField";
import { Mark } from "@mui/base";

export type CustomizationEditType = "text" | "dropdown" | "slider";

interface SliderValues {
    minValue?: number;
    maxValue?: number;
    step?: number;
    showMarks?: boolean;
    marks?: Mark[];
    rangeTitles?: {
        left: string;
        right: string;
    };
}

interface Props {
    title: string;
    description?: string;
    currentValue?: string | number; // current text value of an item (in case of announcement, image url, ...)
    isEditable?: boolean;
    type?: CustomizationEditType;
    dropdownValues?: CustomizeItemDropdownValue[];
    onValueChange?: (val: string | number) => void;
    onDoubleClick?: () => void;
    sliderValues?: SliderValues;
    customizationActions: React.ReactNode;
    transformTooltipText?: (value: number) => string;
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const Information = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 10px 10px 10px 0px;
`;

const CustomizationActions = styled.div`
    padding-top: 10px;
    width: 20%;
    max-width: 80px;
    display: flex;
    justify-content: flex-end;
    height: 100%;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
`;

const Description = styled.div`
    font-size: 13px;
    margin-bottom: 10px;
    padding-top: 5px;
    color: #757575;
`;

function CustomizeItem({
    title,
    description,
    type,
    onValueChange,
    currentValue,
    dropdownValues,
    customizationActions,
    onDoubleClick,
    isEditable,
    sliderValues,
    transformTooltipText,
}: Props) {
    function handleValueChange(val: string | number) {
        if (onValueChange) {
            onValueChange(val);
        }
    }

    function handleTextClick() {
        if (!isEditable && onDoubleClick) {
            onDoubleClick();
        }
    }

    return (
        <Wrapper>
            <Information>
                <Title>{title}</Title>
                {description && <Description>{description}</Description>}
                {type === "text" && typeof currentValue === "string" && (
                    <CustomizeItemTextField
                        value={currentValue ?? ""}
                        isEditable={typeof isEditable !== "undefined" && isEditable}
                        onValueChange={(val: string) => handleValueChange(val)}
                        onDoubleClick={handleTextClick}
                    />
                )}
                {type === "dropdown" && typeof isEditable !== "undefined" && dropdownValues && currentValue && (
                    <CustomizeItemDropdown
                        selectedValue={currentValue}
                        isEditable={typeof isEditable !== "undefined" && isEditable}
                        items={dropdownValues}
                        onValueChange={(val: string | number) => handleValueChange(val)}
                        onDoubleClick={handleTextClick}
                    />
                )}
                {type === "slider" &&
                    sliderValues &&
                    transformTooltipText &&
                    typeof sliderValues.minValue === "number" &&
                    typeof sliderValues.maxValue === "number" &&
                    typeof currentValue === "number" && (
                        <CustomizeItemSlider
                            selectedValue={currentValue}
                            isEditable={typeof isEditable !== "undefined" && isEditable}
                            sliderMinValue={sliderValues.minValue}
                            sliderMaxValue={sliderValues.maxValue}
                            sliderStep={sliderValues.step}
                            marks={sliderValues.marks}
                            showMarks={typeof sliderValues.showMarks !== "undefined" && sliderValues.showMarks}
                            rangeTitles={sliderValues.rangeTitles}
                            onValueChange={(val: number) => handleValueChange(val)}
                            onDoubleClick={handleTextClick}
                            transformTooltipText={transformTooltipText}
                        />
                    )}
            </Information>
            <CustomizationActions>{customizationActions}</CustomizationActions>
        </Wrapper>
    );
}

export default CustomizeItem;

import { Grid, Tooltip } from "@mui/material";
import * as React from "react";
import DataItem from "../DataItem/DataItem";
import { DATE_FORMAT } from "../UsageSummary/UsageSummary";
import moment from "moment";
import ModeBadge from "../ModeBadge/ModeBadge";
import { OverageType } from "../../__generated__/globalTypes";
import EditContractGbDialog from "./EditContractGbDialog";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";
import EditContractAccessSDDialog from "./EditContractAccessSDDialog";

interface Props {
    organization: GetFullOrganizationDetails_organization;
}

function ContractCommitment({ organization }: Props) {
    const [openEditGbDialog, setOpenEditGbDialog] = React.useState<boolean>(false);
    const [openEditSDDialog, setOpenEditSDDialog] = React.useState<boolean>(false);

    const { contractType, contracts } = organization;

    const contract =
        contracts.filter((contract) => moment(contract.start) < moment() && moment(contract.end) > moment())[0] ??
        contracts[0];

    const {
        end,
        start,
        overageType,
        mrr,
        commitment,
        billAutomatically,
        billInGb,
        billOveragesAutomatically,
        gbUsedPerMinute,
        hasAccessToSD,
    } = contract;

    return (
        <>
            <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                    <DataItem title="Contract Type" value={<ModeBadge mode={contractType} />} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem title="Start" value={moment(start).utc().format(DATE_FORMAT)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem title="End" value={moment(end).utc().format(DATE_FORMAT)} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem title="Commitment" value={`$${commitment} ($${mrr} billed per month)`} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem
                        title="Overage Type"
                        value={getOverageValue(overageType, overageType === OverageType.monthly ? mrr : commitment)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem title="Monthly billing" value={billAutomatically ? "automatically" : "manually"} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem
                        title="Billing of overages"
                        value={billOveragesAutomatically ? "automatically" : "manually"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem
                        title="Convert minutes to GB?"
                        value={billInGb ? `Yes, at ${gbUsedPerMinute} GB per minute` : "No"}
                        editable
                        onEditClick={() => setOpenEditGbDialog(true)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DataItem
                        title="Has access to Service Desk?"
                        value={hasAccessToSD ? `Yes` : "No"}
                        editable
                        onEditClick={() => setOpenEditSDDialog(true)}
                    />
                </Grid>
            </Grid>
            <EditContractGbDialog
                contract={contract}
                onClose={() => setOpenEditGbDialog(false)}
                open={openEditGbDialog}
                organizationId={organization.organizationId}
            />
            <EditContractAccessSDDialog
                contract={contract}
                onClose={() => setOpenEditSDDialog(false)}
                open={openEditSDDialog}
                organizationId={organization.organizationId}
            />
        </>
    );
}

export function getOverageValue(overageType: OverageType, amount: number) {
    const name = overageType === OverageType.monthly ? "Monthly" : "Annually";
    const title =
        overageType === OverageType.monthly
            ? `Each month, we'll see if your monthly usage passed the amount billed per month ($${amount}). If so, an overage invoice will be sent.`
            : `Each month, we'll see if your total usage passed the commitment of $${amount}. If so, an overage invoice will be sent.`;
    return (
        <Tooltip title={title}>
            <div>{name}</div>
        </Tooltip>
    );
}

export default ContractCommitment;

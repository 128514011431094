import { Switch } from "@mui/material";
import * as React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
    border-radius: 10px;
    padding: 15px 20px;
    // border: 1px solid ${(props) => props.theme.lightGrayBottom};
    background: #fafafa;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const TitleSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
`;

const Subtitle = styled.div`
    margin-top: 2px;
    font-size: 14px;
    color: ${(props) => props.theme.gray};
`;

const Content = styled.div`
    margin-top: 10px;
`;

interface Props {
    title: string;
    subtitle: string;
    enabled?: boolean;
    onEnabledChange?: () => void;

    children: React.ReactNode;
}

export default function ChannelAdminSettingsSection({ title, subtitle, children, enabled, onEnabledChange }: Props) {
    const showSwitch = typeof enabled === "boolean";

    return (
        <Wrapper>
            <Header>
                <TitleSection>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </TitleSection>
                {showSwitch && <Switch checked={enabled} color="primary" onChange={onEnabledChange} />}
            </Header>
            <Content>{children}</Content>
        </Wrapper>
    );
}

import { Grid } from "@mui/material";
import { getName } from "country-list";
import * as React from "react";
import {
    GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting,
    GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting_avcRoyaltyReporting_periods,
} from "../../views/App/__generated__/GetAvcRoyaltyReportingQuery";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { HESPlink } from "../Landing/SignUpComplete";

interface TableItem {
    id: string;
    name: React.ReactNode;
    country: string;
    maxBefore: number;
    maxInPeriod: number;
    toReport: React.ReactNode;
}

interface OrgsThisPeriod {
    id: string;
    name: string;
    maxOverall: number;
    dataThisPeriod?: GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting_avcRoyaltyReporting_periods;
    country: string;
}

interface Props {
    organizations: GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting[];
    selectedPeriodStart: string;
}

function AvcRoyaltyPeriod({ organizations, selectedPeriodStart }: Props) {
    const headers: TableHeadItem[] = [
        {
            id: "id",
            label: "ID",
        },
        {
            id: "name",
            label: "Name",
            allowSort: true,
        },
        {
            id: "country",
            label: "Country",
            allowSort: true,
        },
        {
            id: "maxBefore",
            label: "Max before period",
            align: "center",
            allowSort: true,
        },
        {
            id: "maxInPeriod",
            label: "Max in period",
            align: "center",
            allowSort: true,
        },
        {
            id: "toReport",
            label: "To report",
            align: "center",
            allowSort: true,
        },
    ];

    const orgsWithDataThisPeriod: OrgsThisPeriod[] = organizations
        .map(({ organizationId, name, avcRoyaltyReporting, isIndividual, billingDetails }) => {
            const thisPeriod = avcRoyaltyReporting!.periods.find((p) => p.start === selectedPeriodStart);
            return {
                id: organizationId,
                name: isIndividual ? `Individual (${name})` : name,
                maxOverall: avcRoyaltyReporting!.maxConcurrentOverall,
                dataThisPeriod: thisPeriod,
                country: getName(billingDetails!.country) as string,
            };
        })
        .filter((o) => o.dataThisPeriod);

    const records: TableItem[] = orgsWithDataThisPeriod.map(({ id, name, maxOverall, dataThisPeriod, country }) => ({
        id,
        name: <HESPlink link={`/app/${id}`}>{name}</HESPlink>,
        country: country,
        maxBefore:
            dataThisPeriod!.toReport === dataThisPeriod!.maxConcurrentInPeriod
                ? 0
                : maxOverall - dataThisPeriod!.toReport,
        maxInPeriod: dataThisPeriod!.maxConcurrentInPeriod,
        toReport: <strong>{dataThisPeriod!.toReport}</strong>,
    }));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <HESPTable
                    headers={headers}
                    data={records}
                    defaultSortOn={"toReport"}
                    defaultSortDirection={"desc"}
                    size="medium"
                    enablePagination
                    paginationRowsPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                />
            </Grid>
        </Grid>
    );
}

export default AvcRoyaltyPeriod;

import { Settings, Visibility } from "@mui/icons-material";
import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { CommitmentUsageTab } from "./OrganizationCommitmentUsageData";
import { CSVLink } from "react-csv";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { GetCommitmentUsageReport_getCommitmentUsageReport } from "./__generated__/GetCommitmentUsageReport";
import moment from "moment";

interface Props {
    selectedTab: CommitmentUsageTab;
    onSelectTab: (tab: CommitmentUsageTab) => void;
    includeBillingInGb: boolean;
    data: GetCommitmentUsageReport_getCommitmentUsageReport;
}

const Wrapper = styled.div`
    border-radius: 8px;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const TabItem = styled.div.attrs((props: { selected?: boolean }) => ({
    selected: props.selected === true,
}))`
    font-weight: ${(props) => (props.selected === true ? 600 : 500)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin-top: 6px;
    padding-bottom: 10px;
    padding-left: 6px;
    color: ${(props) => props.theme.secondary};
    cursor: pointer;
    border-bottom: 2px solid
        ${(props) => (props.selected === true ? props.theme.secondary : props.theme.lightBlueBackground)};
`;

const Navigation = styled.div`
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.lightBlueBackground};
    padding: 8px 12px;
`;

const Tabs = styled.div`
    display: flex;
    flex-direction: row;
`;

const DataWrapper = styled.div`
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export default function OrganizationCommitmentUsageDataContent({
    onSelectTab,
    selectedTab,
    includeBillingInGb,
    data,
}: Props) {
    const csvHeadersTranscoded: string[] = [
        "channelId",
        "channelName",
        "src",
        "schedulerId",
        "schedulerName",
        "schedulerClientId",
        "abr",
        "bitrate",
        "resolution",
        "fps",
        "drm",
        "start",
        "end",
        "seconds",
    ];

    const csvHeadersViewed: string[] = [
        "aliasId",
        "aliasName",
        "channelId",
        "channelName",
        "src",
        "schedulerId",
        "schedulerClientId",
        "schedulerName",
        "schedulerStart",
        "schedulerEnd",
        "billingZone",
        "abr",
        "bitrate",
        "resolution",
        "fps",
        "drm",
        "seconds",
    ];

    if (includeBillingInGb) {
        csvHeadersViewed.push("gbConsumed");
    }

    const tableHeadersTranscoded: TableHeadItem[] = [
        {
            id: "channelId",
            label: "ChannelId",
            allowSort: true,
        },
        {
            id: "channelName",
            label: "Name channel",
        },
        {
            id: "schedulerId",
            label: "SchedulerId",
        },
        {
            id: "schedulerName",
            label: "Scheduler Name",
        },
        {
            id: "schedulerClientId",
            label: "Client ID scheduler",
        },
        {
            id: "config",
            label: "Config",
        },
        {
            id: "start",
            label: "Start",
        },
        {
            id: "end",
            label: "End",
        },
        {
            id: "seconds",
            label: "Seconds",
            allowSort: true,
        },
    ];

    const tableHeadersViewed: TableHeadItem[] = [
        {
            id: "aliasId",
            label: "AliasId",
            allowSort: true,
        },
        {
            id: "aliasName",
            label: "Name alias",
        },
        {
            id: "channelId",
            label: "ChannelId",
            allowSort: true,
        },
        {
            id: "channelName",
            label: "Name channel",
        },
        {
            id: "src",
            label: "Source",
        },
        {
            id: "schedulerId",
            label: "SchedulerId",
        },
        {
            id: "schedulerName",
            label: "Scheduler Name",
        },
        {
            id: "schedulerClientId",
            label: "Client ID scheduler",
        },
        {
            id: "schedulerStart",
            label: "Scheduler start",
        },
        {
            id: "schedulerEnd",
            label: "Scheduler end",
        },
        {
            id: "zone",
            label: "Billing zone (EU-US or other)",
        },
        {
            id: "config",
            label: "Config",
        },
        {
            id: "seconds",
            label: "Seconds",
            allowSort: true,
        },
        {
            id: "gbConsumed",
            label: "GB consumed",
            hide: !includeBillingInGb,
        },
    ];

    const transcodedData = data.transcoded.items.map((t) => ({
        channelId: t.channel.channelId,
        channelName: t.channel.name,
        schedulerId: t.scheduler?.schedulerId ?? "",
        schedulerName: t.scheduler?.name ?? "",
        schedulerClientId: t.scheduler?.clientId ?? "",
        config: `ABR ${t.config.abr ? "enabled" : "disabled"} - DRM ${
            t.config.drm === true ? "enabled" : "disabled"
        } - ${t.config.bitrate}Mbps - ${t.config.resolution.replace("r_", "")} - ${t.config.fps}fps`,
        start: moment(t.start).format("YYYY-MM-DD HH:mm:ss"),
        end: t.end ? moment(t.end).format("YYYY-MM-DD HH:mm:ss") : "",
        seconds: Math.ceil(t.seconds),
    }));

    const viewedData = data.viewed.items.map((v) => ({
        aliasId: v.alias.aliasId,
        aliasName: v.alias.name,
        channelId: v.channel.channelId,
        channelName: v.channel.name,
        src: v.src.replace("__", "/").replace("_", "."),
        schedulerId: v.scheduler?.schedulerId ?? "",
        schedulerName: v.scheduler?.name ?? "",
        schedulerClientId: v.scheduler?.clientId ?? "",
        schedulerStart: v.scheduler?.start ? moment(v.scheduler.start).format("YYYY-MM-DD HH:mm:ss") : "",
        schedulerEnd: v.scheduler?.end ? moment(v.scheduler.end).format("YYYY-MM-DD HH:mm:ss") : "",
        zone: v.zone,
        config: `ABR ${v.config.abr ? "enabled" : "disabled"} - DRM ${v.config.drm ? "enabled" : "disabled"} - ${
            v.config.bitrate
        }Mbps - ${v.config.resolution.replace("r_", "")} - ${v.config.fps}fps`,
        seconds: Math.ceil(v.seconds),
        ...(includeBillingInGb && { gbConsumed: v.gbConsumed?.toFixed(7) }),
    }));

    const csvDataTranscoded = data.transcoded.items.map((t) => ({
        channelId: t.channel.channelId,
        channelName: t.channel.name,
        schedulerId: t.scheduler?.schedulerId ?? "",
        schedulerName: t.scheduler?.name ?? "",
        schedulerClientId: t.scheduler?.clientId ?? "",
        abr: t.config.abr,
        bitrate: t.config.bitrate,
        resolution: t.config.resolution.replace("r_", ""),
        fps: t.config.fps,
        drm: t.config.drm === true,
        start: t.start,
        end: t.end ?? "",
        seconds: Math.ceil(t.seconds),
    }));

    const csvDataViewed = data.viewed.items.map((v) => ({
        aliasId: v.alias.aliasId,
        aliasName: v.alias.name,
        channelId: v.channel.channelId,
        channelName: v.channel.name,
        src: v.src.replace("__", "/").replace("_", "."),
        schedulerId: v.scheduler?.schedulerId ?? "",
        schedulerName: v.scheduler?.name ?? "",
        schedulerClientId: v.scheduler?.clientId ?? "",
        schedulerStart: v.scheduler?.start ? moment(v.scheduler.start).format("YYYY-MM-DD HH:mm:ss") : "",
        schedulerEnd: v.scheduler?.end ? moment(v.scheduler.end).format("YYYY-MM-DD HH:mm:ss") : "",
        billingZone: v.zone,
        abr: v.config.abr,
        bitrate: v.config.bitrate,
        resolution: v.config.resolution.replace("r_", ""),
        fps: v.config.fps,
        drm: v.config.drm,
        seconds: Math.ceil(v.seconds),
        ...(includeBillingInGb && { gbConsumed: v.gbConsumed }),
    }));

    return (
        <Wrapper>
            <Navigation>
                <Tabs>
                    <TabItem selected={selectedTab === "transcoded"} onClick={() => onSelectTab("transcoded")}>
                        <Settings style={{ marginRight: "10px", fontSize: "20px" }} />
                        Transcoded
                    </TabItem>

                    <TabItem selected={selectedTab === "viewed"} onClick={() => onSelectTab("viewed")}>
                        <Visibility style={{ marginRight: "10px", fontSize: "20px" }} />
                        Viewed
                    </TabItem>
                </Tabs>
                <ButtonWrapper>
                    <CSVLink
                        data={selectedTab === "transcoded" ? csvDataTranscoded : csvDataViewed}
                        headers={selectedTab === "transcoded" ? csvHeadersTranscoded : csvHeadersViewed}
                        filename={`usage-${selectedTab}.csv`}
                        style={{ textDecoration: "none" }}
                    >
                        <Button variant="contained" color="secondary">
                            Export as CSV
                        </Button>
                    </CSVLink>
                </ButtonWrapper>
            </Navigation>
            <DataWrapper>
                <HESPTable
                    headers={selectedTab === "transcoded" ? tableHeadersTranscoded : tableHeadersViewed}
                    data={selectedTab === "transcoded" ? transcodedData : viewedData}
                    defaultSortOn={selectedTab === "transcoded" ? "channelId" : "aliasId"}
                    defaultSortDirection={"asc"}
                    size="small"
                    enablePagination
                    paginationRowsPerPage={20}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                />
            </DataWrapper>
        </Wrapper>
    );
}

import { Button, Grid } from "@mui/material";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { Add } from "@mui/icons-material";
import { THEO_ID } from "../../views/App/AppRoot";
import { navigate } from "@reach/router";
import { ToastContainer } from "react-toastify";
import SearchField from "../SearchField/SearchField";
import React from "react";
import TLADatacenterItem from "./TLADatacenterItem";
import { GetAllDatacenters } from "../../views/App/__generated__/GetAllDatacenters";
import { SkeletonRectangle } from "../Loading/Skeletons";

interface Props {
    datacenters?: GetAllDatacenters;
}

export default function TLADatacentersContent({ datacenters }: Props): JSX.Element {
    const [searchInput, onChangeSearchInput] = React.useState<string>("");

    return (
        <div style={{ marginTop: "12px" }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CardHESP>
                        <CardHESPTitle
                            title="List of data centers"
                            button={
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Add />}
                                    onClick={() => navigate(`/app/${THEO_ID}/tla/data-centers/create`)}
                                >
                                    Add
                                </Button>
                            }
                        />
                        <Grid container spacing={2} style={{ marginTop: "1px" }}>
                            <Grid item xs={12}>
                                <Grid container justifyContent={"flex-end"}>
                                    <Grid item>
                                        <SearchField
                                            placeholder="Search..."
                                            onKeyStroke={(value: string) => onChangeSearchInput(value)}
                                            size={"small"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {datacenters && (
                                        <>
                                            {datacenters.getAllDataCenters
                                                .filter((datacenter) => {
                                                    if (searchInput.trim() !== "") {
                                                        if (
                                                            datacenter.id
                                                                .toLowerCase()
                                                                .includes(searchInput.toLowerCase()) ||
                                                            datacenter.name
                                                                .toLowerCase()
                                                                .includes(searchInput.toLowerCase())
                                                        ) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }
                                                    return true;
                                                })
                                                .map((datacenter) => (
                                                    <Grid item xs={12} md={6} lg={4} key={`dc-${datacenter.id}`}>
                                                        <TLADatacenterItem
                                                            id={datacenter.id}
                                                            name={datacenter.name}
                                                            organizationsConnected={datacenter.numberOfOrganizations}
                                                        />
                                                    </Grid>
                                                ))}
                                        </>
                                    )}
                                    {!datacenters && (
                                        <>
                                            <SkeletonRectangle width="100%" />
                                            <SkeletonRectangle width="100%" />
                                            <SkeletonRectangle width="100%" />
                                            <SkeletonRectangle width="100%" />
                                            <SkeletonRectangle width="100%" />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <ToastContainer />
                    </CardHESP>
                </Grid>
            </Grid>
        </div>
    );
}

import { DeleteForever } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import HESPTable from "../../HESPTable/HESPTable";
import { TableHeadItem } from "../../HESPTable/HESPTableHead";
import styled from "styled-components";

export interface GeoBlockingCountry {
    code: string;
    name: string;
}

interface Props {
    countries: GeoBlockingCountry[];
    onRemove: (code: string) => void;
    showAtLeastOneCountryError: boolean;
}

const Error = styled.div`
    background-color: ${(props) => props.theme.errorLight};
    padding: 10px 10px;
    font-size: 14px;
    border-radius: 6px;
`;

function GeoBlockingOverview({ countries, onRemove, showAtLeastOneCountryError }: Props) {
    const headers: TableHeadItem[] = [
        {
            id: "country",
            label: "Country",
            allowSort: true,
        },
        { id: "actions", label: "Actions", align: "right", allowSort: false },
    ];

    const records = countries.map(({ code, name }) => ({
        country: `${code} (${name})`,
        actions: (
            <>
                <Tooltip title="delete">
                    <IconButton onClick={() => onRemove(code)}>
                        <DeleteForever fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        ),
    }));

    return (
        <Grid container spacing={2}>
            {showAtLeastOneCountryError && (
                <Grid item xs={12}>
                    <Error> You should add at least one country</Error>
                </Grid>
            )}
            <Grid item xs={12}>
                <HESPTable
                    headers={headers}
                    data={records}
                    size="small"
                    defaultSortOn="country"
                    defaultSortDirection="asc"
                    enablePagination
                    paginationRowsPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 20]}
                />
            </Grid>
        </Grid>
    );
}

export default GeoBlockingOverview;

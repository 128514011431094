import { Grid, IconButton } from "@mui/material";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { GetDatacenter_datacenter_organizations } from "../../views/App/__generated__/GetDatacenter";
import styled from "styled-components";
import { useState } from "react";
import RemoveDataCenterDialog from "../OrganizationDetails/RemoveDataCenterDialog";
import { ToastContainer } from "react-toastify";

interface Props {
    organizations: GetDatacenter_datacenter_organizations[];
    datacenterId: string;
}

const Organization = styled.div`
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 14px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: bolder;
`;

export default function TLADatacenterOrganizations({ organizations, datacenterId }: Props): JSX.Element {
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>(undefined);
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);

    function onRemoveClick(organizationId: string): void {
        setSelectedOrganizationId(organizationId);
        setOpenRemoveDialog(true);
    }

    return (
        <CardHESP>
            <CardHESPTitle title="Connected organizations" />
            <Grid container spacing={2} style={{ marginTop: "1px" }}>
                {organizations.length === 0 && (
                    <Grid item xs={12}>
                        No organizations connected
                    </Grid>
                )}
                {organizations.map((organization) => (
                    <Grid item xs={12}>
                        <Organization key={organization.organizationId}>
                            <div>{organization.name}</div>
                            <div>
                                <IconButton size="small" onClick={() => onRemoveClick(organization.organizationId)}>
                                    <DeleteForeverOutlined fontSize="small" color="error" />
                                </IconButton>
                            </div>
                        </Organization>
                    </Grid>
                ))}
            </Grid>

            {selectedOrganizationId && (
                <>
                    <RemoveDataCenterDialog
                        datacenterId={datacenterId}
                        organizationId={selectedOrganizationId}
                        open={openRemoveDialog}
                        onClose={() => setOpenRemoveDialog(false)}
                    />
                    <ToastContainer />
                </>
            )}
        </CardHESP>
    );
}

/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

import { GET_CHANNEL_WITH_ALL_ALIASES_QUERY } from "../../components/Channels/Overview/channelQueriesMutations";
import {
    GetChannelWithAllAliasesQueryVariables,
    GetChannelWithAllAliasesQuery,
} from "../../components/Channels/Overview/__generated__/GetChannelWithAllAliasesQuery";
import InsightsWrapper from "../../components/Insights/InsightsWrapper";
import CentralPageLoader from "../../components/Loading/CentralPageLoader";

export const GET_CHANNEL_VIEWER_INSIGHTS = gql`
    query GetChannelViewerInsightsQuery(
        $channelId: ID!
        $organizationId: ID!
        $timestamp: String!
        $aliasId: ID!
        $showTotal: Boolean!
        $intervalType: ViewerInsightsIntervalType!
    ) {
        channel(channelId: $channelId, organizationId: $organizationId) {
            channelId
            channelStatus
            organizationId
            analyzerWebsocketUrl
            metadata {
                name
            }
            viewerInsights(
                timestamp: $timestamp
                intervalType: $intervalType
                showTotal: $showTotal
                aliasId: $aliasId
            ) {
                channelId
                timestamp
                browsersAndOS {
                    browser
                    os
                    osVersion
                    browserVersion
                    amount
                }
                viewerLatency {
                    median
                    bins {
                        bin
                        amount
                    }
                }
                regions {
                    city
                    country
                    region
                    amount
                }
                concurrentViewerSessions {
                    total
                    details {
                        timestamp
                        amount
                    }
                }
            }
        }
    }
`;

interface Props extends RouteComponentProps {
    path?: string;
    channelId?: string;
    organizationId?: string;
}

function Insights({ channelId, organizationId }: Props) {
    const { data, loading } = useQuery<GetChannelWithAllAliasesQuery, GetChannelWithAllAliasesQueryVariables>(
        GET_CHANNEL_WITH_ALL_ALIASES_QUERY,
        {
            variables: {
                channelId: channelId!,
                organizationId: organizationId!,
            },
        },
    );

    if (loading) {
        return <CentralPageLoader text="Getting data" />;
    }

    if (!data) {
        return <div>An error occurred</div>;
    }

    return <InsightsWrapper channel={data.channel} />;
}

export default Insights;

import { Grid, Switch } from "@mui/material";
import * as React from "react";

import styled from "styled-components";
import SearchField from "../SearchField/SearchField";
import ChannelChip from "../Usage/ChannelChip";

const Wrapper = styled.div`
    border-radius: 8px;
    padding: 10px 16px;
    background: #fafafa;
`;

const SwitchTitle = styled.span`
    font-size: 15px;
`;

export interface UsageItem {
    id: string;
    name: string;
    isDeleted: boolean;
}

interface Props {
    items: UsageItem[];
    selectedItems: UsageItem[];
    onSelectItem: (item: UsageItem) => void;
    onDeselectItem: (item: UsageItem) => void;
    showDetailed: boolean;
    onShowDetailedChange: () => void;
    selectAll: boolean;
    onSelectAllChange: () => void;
    showDeleted: boolean;
    onShowDeletedChange: () => void;
    searchValue: string;
    onSearchValueChange: (value: string) => void;
    showNames: boolean;
    onShowNamesChange: () => void;
}

function ChannelUsageViewedSettings({
    items,
    onShowDetailedChange,
    onDeselectItem,
    onSelectAllChange,
    onSelectItem,
    selectAll,
    selectedItems,
    showDetailed,
    showDeleted,
    onShowDeletedChange,
    searchValue,
    onSearchValueChange,
    showNames,
    onShowNamesChange,
}: Props) {
    const hasSearchInput = searchValue.trim().length > 0;

    const itemsAfterDeletedCheck = showDeleted ? items : items.filter((i) => !i.isDeleted);
    const itemsAfterSearchCheck = hasSearchInput
        ? itemsAfterDeletedCheck.filter((i) =>
              showNames
                  ? i.name.toLowerCase().startsWith(searchValue.toLowerCase())
                  : i.id.toLowerCase().startsWith(searchValue.toLowerCase()),
          )
        : itemsAfterDeletedCheck;

    const selectedItemIds = selectedItems.map((s) => s.id);

    return (
        <Wrapper>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} lg={3}>
                    <Switch size="small" checked={showDetailed} onChange={onShowDetailedChange} />{" "}
                    <SwitchTitle>Show per alias</SwitchTitle>
                </Grid>
                {showDetailed && (
                    <>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Switch size="small" checked={showDeleted} onChange={onShowDeletedChange} />{" "}
                            <SwitchTitle>Show deleted</SwitchTitle>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Switch size="small" checked={showNames} onChange={onShowNamesChange} />{" "}
                            <SwitchTitle>Show channel names</SwitchTitle>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Switch size="small" checked={selectAll} onChange={onSelectAllChange} />{" "}
                            <SwitchTitle>Select all</SwitchTitle>
                        </Grid>
                    </>
                )}
            </Grid>
            {showDetailed && (
                <>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                            <SearchField
                                placeholder="Search..."
                                onKeyStroke={(value: string) => onSearchValueChange(value)}
                                size={"small"}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "20px" }}>
                        <Grid container spacing={2}>
                            {itemsAfterSearchCheck.map((i) => (
                                <Grid item key={`item-${i.id}`}>
                                    <ChannelChip
                                        id={i.id}
                                        name={i.name}
                                        checked={selectedItemIds.includes(i.id)}
                                        showName={showNames}
                                        onChange={() =>
                                            selectedItemIds.includes(i.id) ? onDeselectItem(i) : onSelectItem(i)
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </>
            )}
        </Wrapper>
    );
}

export default ChannelUsageViewedSettings;

import * as React from "react";
import styled from "styled-components";
import { SkeletonCircle, SkeletonRectangle } from "../Loading/Skeletons";

export const TableRowSkeleton = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
`;

function AdminSignUpsTableSkeleton() {
    return (
        <>
            {[0, 1, 2, 3, 4].map((i) => (
                <TableRowSkeleton key={`skeleton-row-${i}`}>
                    <SkeletonRectangle width={"50%"} />
                    <SkeletonRectangle width={"50%"} />
                    <SkeletonCircle />
                </TableRowSkeleton>
            ))}
        </>
    );
}

export default AdminSignUpsTableSkeleton;

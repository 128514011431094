import { Button, FormControl, Grid, TextField } from "@mui/material";
import ChannelIngestSettingsSelectorItem from "../Details/ChannelIngestSettingsSelectorItem";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { IngestProtocol } from "../../../__generated__/globalTypes";
import { GetChannelAdminPartQuery_channel } from "../Overview/__generated__/GetChannelAdminPartQuery";
import { useState } from "react";
import styled from "styled-components";
import { HelperText } from "../../AdminSearchCard/CreateOrganizationForm";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ConvertToTLAMutation, ConvertToTLAMutationVariables } from "./__generated__/ConvertToTLAMutation";
import { toast } from "react-toastify";
import DatacenterSelector from "../../Regions/DataCenterSelector";
import {
    GET_CHANNELS_ORGANIZATION_QUERY,
    GET_CHANNEL_ADMIN_PART,
    GET_CHANNEL_QUERY,
} from "../Overview/channelQueriesMutations";

const Selections = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const CONVERT_TO_TLA_MUTATION = gql`
    mutation ConvertToTLAMutation($input: ConvertChannelToTLAInput!) {
        convertChannelToTLA(input: $input) {
            channelId
        }
    }
`;

interface Props {
    channel: GetChannelAdminPartQuery_channel;
    onDone: () => void;
}

export default function ChannelConvertToTLA({ channel, onDone }: Props) {
    const [ingestProtocol, setIngestProtocol] = useState<IngestProtocol>(channel.ingestProtocol);
    const [datacenter, setDatacenter] = useState<string>(channel.organization.tla!.datacenters[0].id);
    const [ingestPullUrl, setIngestPullUrl] = useState<string>(channel.ingestPullUrl ?? "");
    const [convertToTLA, { loading }] = useMutation<ConvertToTLAMutation, ConvertToTLAMutationVariables>(
        CONVERT_TO_TLA_MUTATION,
    );

    async function onSave() {
        try {
            await convertToTLA({
                variables: {
                    input: {
                        channelId: channel.channelId,
                        datacenterId: datacenter,
                        ingestProtocol,
                        ingestPullUrl,
                        organizationId: channel.organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_ADMIN_PART,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            onDone();
            toast.success("Channel converted to TLA");
        } catch (_e) {
            toast.error("Something went wrong");
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <FormControl>
                    <HESPFormLabel label="Ingest Protocol" />
                    <DatacenterSelector
                        organization={channel.organization}
                        selectDatacenterId={datacenter!}
                        onChangeSelected={(datacenterId: string) => setDatacenter(datacenterId)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={7}>
                <FormControl>
                    <HESPFormLabel label="Ingest Protocol" />
                    <Selections>
                        <ChannelIngestSettingsSelectorItem
                            onClick={() => setIngestProtocol(IngestProtocol.rtmp)}
                            selected={ingestProtocol === IngestProtocol.rtmp}
                            title="RTMP"
                            tooltip=""
                        />
                        <ChannelIngestSettingsSelectorItem
                            onClick={() => setIngestProtocol(IngestProtocol.srt)}
                            selected={ingestProtocol === IngestProtocol.srt}
                            title="SRT"
                            tooltip=""
                        />
                        <ChannelIngestSettingsSelectorItem
                            onClick={() => setIngestProtocol(IngestProtocol.sdi)}
                            selected={ingestProtocol === IngestProtocol.sdi}
                            title="SDI"
                            tooltip=""
                        />
                    </Selections>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Ingest Pull URL" />
                    <TextField
                        value={ingestPullUrl}
                        onChange={(e) => setIngestPullUrl(e.target.value)}
                        fullWidth
                        style={{ backgroundColor: "white" }}
                    />
                    {ingestPullUrl.trim().length === 0 && (
                        <HelperText style={{ color: "red" }}>Setting an ingest pull URL is mandatory</HelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={onSave} disabled={loading || ingestPullUrl.trim().length === 0}>
                    {loading ? "Converting..." : "Convert to TLA"}
                </Button>
            </Grid>
        </Grid>
    );
}

import { Button, Grid } from "@mui/material";
import EventsTable from "./EventsTable";
import { GetEventsQuery_events_events } from "./__generated__/GetEventsQuery";

interface Props {
    hasMore: boolean;
    onMoreClick: () => void;
    organizationId: string;
    events: GetEventsQuery_events_events[];
}

export default function EventsData({ events, hasMore, onMoreClick, organizationId }: Props) {
    return (
        <Grid container spacing={2}>
            {events.length === 0 && (
                <Grid item xs={12}>
                    No events available
                </Grid>
            )}
            {events.length > 0 && (
                <>
                    <Grid item xs={12}>
                        <EventsTable events={events} organizationId={organizationId} />
                    </Grid>
                    {hasMore && (
                        <Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button size="small" color="secondary" onClick={onMoreClick}>
                                    Load more
                                </Button>
                            </div>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

import { IconButton, Tooltip } from "@mui/material";
import { EditOutlined, FileCopyOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import * as React from "react";
import styled from "styled-components";
import { copyToClipboard } from "../CopyLabel/CopyLabel";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { iconStyling } from "../Channels/Details/ChannelMainDetails";

interface Props {
    title: string;
    value: string | React.ReactNode;
    copyable?: boolean;
    size?: "small" | "medium";
    editable?: boolean;
    onEditClick?: () => void;
}

const Title = styled.div`
    background-color: #fafafa;
    padding: 10px 10px;
    font-weight: bolder;
    font-size: 14px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Value = styled.div.attrs((props: { size?: "small" | "medium" }) => ({
    size: props.size ?? "medium",
}))`
    padding: ${(props) => (props.size === "small" ? "5px 10px" : "10px")}};
    width: 100%;
    display: flex;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
`;

const IconSection = styled.div`
    margin-left: 10px;
`;

function DataItem({ title, value, copyable, size, editable, onEditClick }: Props) {
    async function copyValue() {
        try {
            if (typeof value === "string") {
                await copyToClipboard(value as string);
                toast.success("Value copied", TOAST_SETTINGS);
            }
        } catch (e: any) {
            toast.error("Error while copying", TOAST_SETTINGS);
        }
    }

    return (
        <React.Fragment>
            <Title>
                {title}{" "}
                {editable === true && onEditClick && (
                    <IconSection>
                        <Tooltip title="Edit">
                            <IconButton size="small" onClick={onEditClick}>
                                <EditOutlined fontSize="small" style={iconStyling} />
                            </IconButton>
                        </Tooltip>
                    </IconSection>
                )}
            </Title>
            <Value size={size}>
                {value}
                {copyable === true && (
                    <IconSection>
                        <Tooltip title="Copy">
                            <IconButton size="small" onClick={copyValue}>
                                <FileCopyOutlined fontSize="small" style={iconStyling} />
                            </IconButton>
                        </Tooltip>
                    </IconSection>
                )}
            </Value>
        </React.Fragment>
    );
}

export default DataItem;

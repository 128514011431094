import { DialogActions, DialogContent, Grid } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";

import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { GET_DATACENTERS } from "../../views/App/TLADatacenters";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";
import { DeleteDataCenterMutation, DeleteDataCenterMutationVariables } from "./__generated__/DeleteDataCenterMutation";

interface Props {
    open: boolean;
    datacenterId: string;
    name: string;
    onClose: () => void;
}

export const REMOVE_TLA_DATA_CENTER = gql`
    mutation DeleteDataCenterMutation($input: RemoveDatacenterInput!) {
        removeTLADatacenter(input: $input)
    }
`;

export default function TLARemoveDatacenterDialog({ onClose, open, datacenterId, name }: Props) {
    const [removeDatacenter, { loading }] = useMutation<DeleteDataCenterMutation, DeleteDataCenterMutationVariables>(
        REMOVE_TLA_DATA_CENTER,
    );

    async function onConfirm() {
        try {
            await removeDatacenter({
                variables: {
                    input: {
                        datacenterId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_DATACENTERS,
                    },
                ],
            });
            navigate(`/app/${THEO_ID}/tla/data-centers`);
            onClose();
        } catch (e) {
            toast.error("Error while removing device context", TOAST_SETTINGS);
        }
    }

    return (
        <DialogHESP
            title={"Remove device context"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginTop: "14px" }}>
                                Are you sure you want to remove data center "{name}"?
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onConfirm}
                            defaultText={"Remove"}
                            loadingText={"Removing..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import { CopyAll } from "@mui/icons-material";
import { Button } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { CopyAliasConfigMutation, CopyAliasConfigMutationVariables } from "./__generated__/CopyAliasConfigMutation";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";

interface Props {
    playoutId: string;
    isAlias: boolean;
    isFailoverAlias: boolean;
    toChannelId: string;
    toAliasId: string;
    channel: GetChannelQuery_channel;
}

const Wrapper = styled.div`
    padding: 10px;
    border: 1px solid ${(props) => props.theme.grayedOut};
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.lightBlueBackground};
`;

export const COPY_ALIAS_CONFIG_MUTATION = gql`
    mutation CopyAliasConfigMutation($input: CopyAliasConfigInput!) {
        copyAliasConfig(input: $input)
    }
`;

export default function CopyAliasConfigToFailover({
    toAliasId,
    toChannelId,
    isAlias,
    playoutId,
    isFailoverAlias,
    channel,
}: Props) {
    const [copyAliasConfigMut, { loading }] = useMutation<CopyAliasConfigMutation, CopyAliasConfigMutationVariables>(
        COPY_ALIAS_CONFIG_MUTATION,
    );

    async function onCopy() {
        try {
            await copyAliasConfigMut({
                variables: {
                    input: {
                        organizationId: channel.organizationId,
                        copyFromId: playoutId,
                        copyFromChannelId: channel.channelId,
                        copyToId: toAliasId,
                        copyToChannelId: toChannelId,
                        fromIsAlias: isAlias,
                        toIsAlias: isFailoverAlias,
                    },
                },
            });
            toast.success("Config succesfully copied!", TOAST_SETTINGS);
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    return (
        <>
            <Wrapper>
                <HESPFormLabel
                    label={"Copy configuration to failover alias"}
                    description={
                        "Copy the complete configuration of this alias (customization, security, ...) and use it as configuration of the failover alias as well"
                    }
                />
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={<CopyAll />}
                    onClick={onCopy}
                >
                    {loading ? "Copying..." : "Copy to failover"}
                </Button>
            </Wrapper>
            <ToastContainer />
        </>
    );
}

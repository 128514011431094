import { Button, Grid } from "@mui/material";
import * as React from "react";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import ActivatePaygDialog from "./ActivatePaygDialog";
import PricingItem from "./PricingItem";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { ToastContainer } from "react-toastify";

interface Props {
    organizationId: string;
    detailsFilledIn: boolean;
}

export default function BillingPricing({ organizationId, detailsFilledIn }: Props) {
    const [openActivateDialog, setOpenActivateDialog] = React.useState<boolean>(false);

    return (
        <>
            <div style={{ marginTop: "12px" }}>
                <SectionTitleWithAction title="Choose your plan" />
                <Grid container spacing={4} justifyContent="space-evenly" style={{ marginTop: "48px" }}>
                    <PricingItem
                        color="primary"
                        title={"Pay as you go"}
                        icon={<PaidOutlinedIcon color="primary" style={{ fontSize: "60px" }} />}
                        button={
                            detailsFilledIn ? (
                                <Button variant="contained" color="primary" onClick={() => setOpenActivateDialog(true)}>
                                    Activate
                                </Button>
                            ) : (
                                <div style={{ fontSize: "14px", textAlign: "center" }}>
                                    Please enter your billing details and a payment method
                                </div>
                            )
                        }
                        items={[
                            <span>Zero upfront costs</span>,
                            <span>Pay only for what you use</span>,
                            <span>
                                Billed per minute of video ingest and minutes of video delivered per viewer, based on{" "}
                                <a href="https://www.theoplayer.com/pricing/theolive" target="_blank" rel="noreferrer">
                                    chosen profile
                                </a>{" "}
                            </span>,
                        ]}
                    />
                    <PricingItem
                        color="secondary"
                        title={"Commitment"}
                        icon={<HistoryEduOutlinedIcon color="secondary" style={{ fontSize: "60px" }} />}
                        button={
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => window.open(`https://www.theoplayer.com/contact`)}
                            >
                                Contact us
                            </Button>
                        }
                        items={["Discount for high volumes", "Annual contracts", "Option to bring your own CDN"]}
                    />
                </Grid>
            </div>
            <ActivatePaygDialog
                onClose={() => setOpenActivateDialog(false)}
                open={openActivateDialog}
                organizationId={organizationId}
            />
            <ToastContainer />
        </>
    );
}

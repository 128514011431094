import * as React from "react";

import styled from "styled-components";
import { useQuery } from "react-apollo";
import { GET_ANALYTICS_TOTALS_ORGANIZATION } from "./UsageSummary";
import {
    GetAnalyticsTotalsOrganization,
    GetAnalyticsTotalsOrganizationVariables,
} from "./__generated__/GetAnalyticsTotalsOrganization";
import { tooltipFormatter } from "../Usage/formatter";
import { UsageType } from "../../__generated__/globalTypes";
import { CircularProgress } from "@mui/material";

interface Props {
    startDate: string;
    endDate: string;
    organizationId: string;
    type: UsageType;
}

const Number = styled.div`
    font-weight: 600;
    font-size: 40px;
    color: #5c5c5c;
    @media (max-width: 400px) {
        font-size: 30px;
    }
`;

function UsageSummaryMinutes({ startDate, endDate, organizationId, type }: Props) {
    const { data, loading } = useQuery<GetAnalyticsTotalsOrganization, GetAnalyticsTotalsOrganizationVariables>(
        GET_ANALYTICS_TOTALS_ORGANIZATION,
        {
            variables: {
                organizationId,
                startDate,
                endDate,
            },
        },
    );

    if (loading || !data) {
        return (
            <Number>
                <CircularProgress style={{ color: "white" }} />
            </Number>
        );
    }

    const total = tooltipFormatter(
        type === UsageType.transcoded
            ? data.getAnalyticsTotalOrganization.transcodedMinutes
            : data.getAnalyticsTotalOrganization.viewedMinutes,
    );
    return <Number>{total}</Number>;
}

export default UsageSummaryMinutes;

import * as React from "react";
import { TextField, MenuItem, styled } from "@mui/material";
import moment from "moment";

export interface AvcRoyaltyPeriod {
    start: string;
    end: string;
}

interface Props {
    periods: AvcRoyaltyPeriod[];
    selected: string;
    // eslint-disable-next-line no-unused-vars
    onChangeSelected: (period: string) => void;
}

function RegionSelector({ selected, onChangeSelected, periods }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 30px 8px 10px",
            fontSize: "14px",
            minWidth: "80px",
            width: "100%",
        },
    };

    const CssTextField = styled(TextField)(smallCss);
    const smallSizeText = { fontSize: "14px" };
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <CssTextField
            select
            name="region"
            onChange={(e: any) => onChangeSelected(e.target.value)}
            variant="outlined"
            value={selected}
            fullWidth
        >
            <MenuItem value="total" style={smallSizeText}>
                Total
            </MenuItem>
            {periods.map(({ start, end }: AvcRoyaltyPeriod) => (
                <MenuItem value={start} key={start} selected={selected === start} style={smallSizeText}>
                    {`From ${moment(start).tz(tz).format("YYYY-MM-DD")} until ${moment(end)
                        .tz(tz)
                        .format("YYYY-MM-DD")}`}
                </MenuItem>
            ))}
        </CssTextField>
    );
}

export default RegionSelector;

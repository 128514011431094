import styled from "styled-components";

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.lightBlueBackground};
    padding: 20px 10px;
    border-radius: 8px;
    font-size: 13px;
`;

export default function NoSchedulersFound() {
    return <Wrapper>🔎 No schedulers found matching these search parameters</Wrapper>;
}

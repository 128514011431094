import { TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

const TextValueWrapper = styled.div`
    cursor: pointer;
`;

interface Props {
    value: string;
    isEditable: boolean;
    onValueChange: (text: string) => void;
    onDoubleClick: () => void;
}

function CustomizeItemTextField({ value, isEditable, onValueChange, onDoubleClick }: Props) {
    return (
        <TextValueWrapper>
            <TextField
                variant="outlined"
                fullWidth
                value={value}
                disabled={!isEditable}
                inputProps={{
                    style: {
                        padding: "8px 10px",
                        background: "white",
                    },
                }}
                size="small"
                onChange={(e) => onValueChange(e.target.value)}
                onDoubleClick={onDoubleClick}
            />
        </TextValueWrapper>
    );
}

export default CustomizeItemTextField;

import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import moment from "moment";
import * as React from "react";
import DialogHESP from "../../DialogHESP/DialogHESP";
import SchedulerItem from "../../SchedulersOverview/SchedulerItem";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";

interface Props {
    channel: GetChannelQuery_channel;
    open: boolean;
    onClose: () => void;
}

export default function ChannelSchedulersDialog({ channel, onClose, open }: Props) {
    return (
        <DialogHESP
            open={open}
            title={"Schedules for this channel"}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid spacing={2} container style={{ marginTop: "10px" }}>
                            {channel.schedulers
                                .sort((a, b) => (a.start < b.start ? -1 : 1))
                                .map((s) => (
                                    <Grid item xs={12} key={`sched-${s.schedulerId}`}>
                                        <SchedulerItem
                                            id={s.schedulerId}
                                            name={s.name}
                                            start={moment(s.start)}
                                            end={s.end ? moment(s.end) : undefined}
                                            organizationId={channel.organizationId}
                                            phase={s.phase}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={onClose}>
                            Close
                        </Button>
                    </DialogActions>
                </>
            }
        />
    );
}

import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { useApolloClient } from "react-apollo";
import styled from "styled-components";
import AuthService from "../auth/AuthService";
import Callout from "../components/Callout/Callout";
import { HESPlink } from "../components/Landing/SignUpComplete";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
`;

interface Props extends RouteComponentProps {
    path?: string;
}

function Blocked(props: Props) {
    const client = useApolloClient();
    AuthService.logout();
    client.stop();
    client.clearStore();

    return (
        <Wrapper>
            <Callout
                type="danger"
                title="Organization blocked"
                description={
                    <>
                        <div>This organization has been blocked.</div>
                        <div>
                            For more information, please contact{" "}
                            <HESPlink link="mailto:contact@theo.live">contact@theo.live</HESPlink>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <HESPlink link="#">Go back</HESPlink>
                        </div>
                    </>
                }
            />
        </Wrapper>
    );
}

export default Blocked;

import * as React from "react";
import { TextField, MenuItem, styled } from "@mui/material";

import { GetOrganizationChannelSettingsQuery_organization } from "../../views/App/__generated__/GetOrganizationChannelSettingsQuery";
import { GetChannelAdminPartQuery_channel_organization } from "../Channels/Overview/__generated__/GetChannelAdminPartQuery";

interface Props {
    organization: GetOrganizationChannelSettingsQuery_organization | GetChannelAdminPartQuery_channel_organization;
    selectDatacenterId: string;
    // eslint-disable-next-line no-unused-vars
    onChangeSelected: (datacenterId: string) => void;
    size?: "small" | "medium";
}

export default function DatacenterSelector({ onChangeSelected, organization, selectDatacenterId, size }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 30px 8px 10px",
            fontSize: "14px",
            minWidth: "80px",
            width: "100%",
            backgroundColor: "white",
        },
    };

    const CssTextField = styled(TextField)(size === "small" ? smallCss : { backgroundColor: "white" });
    const smallSizeText =
        size === "small" ? { fontSize: "14px", backgroundColor: "white" } : { backgroundColor: "white" };

    const datacenters = organization.tla?.datacenters ?? [];

    return (
        <CssTextField
            select
            name="region"
            onChange={(e: any) => onChangeSelected(e.target.value)}
            variant="outlined"
            value={selectDatacenterId}
            fullWidth
        >
            {datacenters.map((dc) => (
                <MenuItem value={dc.id} key={dc.id} selected={selectDatacenterId === dc.id} style={smallSizeText}>
                    {dc.name} ({dc.id})
                </MenuItem>
            ))}
        </CssTextField>
    );
}

import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import WebhooksOverview from "../../components/WebhooksOverview/WebhooksOverview";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function Webhooks({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center" alignItems={"center"} alignContent="center">
            <Grid item xs={12}>
                <WebhooksOverview organizationId={organizationId!} />
            </Grid>
        </Grid>
    );
}

export default Webhooks;

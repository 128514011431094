import { Alert, Grid } from "@mui/material";
import * as React from "react";
import { GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting } from "../../views/App/__generated__/GetAvcRoyaltyReportingQuery";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { HESPlink } from "../Landing/SignUpComplete";

interface TableItem {
    id: string;
    name: React.ReactNode;
    maxEncoders: number;
}

interface Props {
    organizations: GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting[];
}

function AvcRoyaltyTotals({ organizations }: Props) {
    const headers: TableHeadItem[] = [
        {
            id: "id",
            label: "ID",
        },
        {
            id: "name",
            label: "Name",
            allowSort: true,
        },
        {
            id: "maxEncoders",
            label: "Max encoders used",
            align: "center",
            allowSort: true,
        },
    ];

    const records: TableItem[] = organizations.map(({ organizationId, name, avcRoyaltyReporting, isIndividual }) => ({
        id: organizationId,
        name: <HESPlink link={`/app/${organizationId}`}>{isIndividual ? `Individual (${name})` : name}</HESPlink>,
        maxEncoders: avcRoyaltyReporting!.maxConcurrentOverall,
    }));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert severity="info" style={{ marginTop: "5px" }}>
                    Note: this table is showing the overall totals and should not be used for reporting. Select a
                    specific period to see the full details to report.
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <HESPTable
                    headers={headers}
                    data={records}
                    defaultSortOn={"maxEncoders"}
                    defaultSortDirection={"desc"}
                    size="medium"
                    enablePagination
                    paginationRowsPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                />
            </Grid>
        </Grid>
    );
}

export default AvcRoyaltyTotals;

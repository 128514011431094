import * as React from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import DataItem from "../DataItem/DataItem";
import { GetFullBillingDetails_organization } from "./__generated__/GetFullBillingDetails";
import { getName } from "country-list";
import AddPaymentMethodDialog from "./AddPaymentMethodDialog";
import AddBillingCardSection from "./AddBillingCardSection";
import { Edit } from "@mui/icons-material";
import { CancelLink } from "../Profile/PersonalInfoEdit";

interface Props {
    organization: GetFullBillingDetails_organization;
}

function BillingDetailsData({ organization }: Props) {
    const [openPaymentMethodDialog, setOpenPaymentMethodDialog] = React.useState<boolean>(false);
    const [editPaymentMethod, setEditPaymentMethod] = React.useState<boolean>(false);
    const { organizationId, name, isIndividual, billingDetails } = organization;
    const { vat, street, city, zip, country, state, paymentMethod } = billingDetails!;
    const countryName = getName(country);

    function addCreditCard() {
        setOpenPaymentMethodDialog(true);
    }

    function onPaymentMethodAddedSuccess() {
        setEditPaymentMethod(false);
        setOpenPaymentMethodDialog(false);
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {!isIndividual && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <DataItem title="Company name" value={name.includes("Account created by") ? "" : name} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DataItem title="VAT Number" value={vat !== null ? vat : "N/A"} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={6}>
                    <DataItem title="Address" value={street} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DataItem title="City" value={`${city} , ${zip} (${state})`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DataItem title="Country" value={countryName} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DataItem
                        title="Payment method"
                        value={
                            <div style={{ width: "100%" }}>
                                {paymentMethod === null && (
                                    <AddBillingCardSection onAddCreditClick={() => addCreditCard()} />
                                )}
                                {paymentMethod !== null && !editPaymentMethod && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <div>Ending on {paymentMethod.last4}</div>
                                        <div>
                                            <Tooltip title={"Replace"}>
                                                <IconButton size="small" onClick={() => setEditPaymentMethod(true)}>
                                                    <Edit fontSize="small" style={{ color: "black" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                                {paymentMethod !== null && editPaymentMethod && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <AddBillingCardSection onAddCreditClick={() => addCreditCard()} />
                                        <CancelLink
                                            href=""
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setEditPaymentMethod(false);
                                            }}
                                        >
                                            Cancel
                                        </CancelLink>
                                    </div>
                                )}
                            </div>
                        }
                    />
                </Grid>
            </Grid>
            <AddPaymentMethodDialog
                details={billingDetails!}
                organizationName={name}
                open={openPaymentMethodDialog}
                onClose={() => setOpenPaymentMethodDialog(false)}
                onSuccess={() => onPaymentMethodAddedSuccess()}
                organizationId={organizationId}
                type={"card"}
            />
        </React.Fragment>
    );
}

export default BillingDetailsData;

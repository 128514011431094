import { Button, FormControl, Grid, MenuItem, TextField, styled as materialStyled } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { OrderDirectionType, SchedulerPhaseType } from "../../__generated__/globalTypes";
import SchedulerPhaseItem from "./SchedulerPhaseItem";
import { Search } from "@mui/icons-material";
import styled from "styled-components";
import { SchedulerFilterValues } from "../../views/App/SchedulerOverviewWithFilters";
import * as React from "react";

export interface SortItem {
    id: string;
    text: string;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 22px;
    // background-color: ${(props) => props.theme.grayBackground};
    margin-bottom: 16px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 8px;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: bolder;
    color: black;
`;

interface Props {
    filterValues: SchedulerFilterValues;
    sortItems: SortItem[];
    onApplyFilters: (filter: SchedulerFilterValues) => void;
}

export default function SchedulerFilters({ filterValues, sortItems, onApplyFilters }: Props) {
    const [startDate, setStartDate] = React.useState<string>(filterValues.startDate);
    const [endDate, setEndDate] = React.useState<string>(filterValues.endDate);
    const [search, setSearch] = React.useState<string>(filterValues.search);
    const [phases, setPhases] = React.useState<SchedulerPhaseType[]>(filterValues.phases);
    const [sortDirection, setSortDirection] = React.useState<OrderDirectionType>(filterValues.sortDirection);
    const [sortBy, setSortBy] = React.useState<string>(filterValues.sortBy);

    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 16px",
            fontSize: "14px",
            minWidth: "200px",
            // width: "80px",
        },
    };
    const CssTextField = materialStyled(TextField)(smallCss);

    return (
        <Wrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Title>Filters</Title>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <HESPFormLabel label="Start" />
                    <FormControl fullWidth>
                        <DateTimePicker
                            format="YYYY/MM/DD HH:mm"
                            ampm={false}
                            value={moment(startDate)}
                            minDateTime={moment("2023-01-01").startOf("day")}
                            onChange={(newValue: moment.Moment | null) => {
                                if (newValue !== null) {
                                    setStartDate(newValue.startOf("minute").toISOString());
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <HESPFormLabel label="End" />
                    <FormControl fullWidth>
                        <DateTimePicker
                            format="YYYY/MM/DD HH:mm"
                            ampm={false}
                            value={moment(endDate)}
                            minDate={moment(startDate)}
                            minTime={
                                moment(startDate).date() === moment(endDate).date() ? moment(startDate) : undefined
                            }
                            onChange={(newValue: moment.Moment | null) => {
                                if (newValue !== null) {
                                    setEndDate(newValue.startOf("minute").toISOString());
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                    <HESPFormLabel label="Search value (ID, name, client ID)" />
                    <TextField
                        fullWidth
                        inputProps={{
                            style: {
                                padding: "8px 10px",
                                background: "white",
                            },
                        }}
                        placeholder="Search..."
                        size="small"
                        onChange={(e) => setSearch(e.target.value.trim())}
                        style={{ backgroundColor: "white", padding: "0px" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <HESPFormLabel label="Selected phase" />
                    <Grid container spacing={2}>
                        <Grid item>
                            <SchedulerPhaseItem
                                onClick={() => setPhases([SchedulerPhaseType.active, SchedulerPhaseType.starting])}
                                selected={phases.includes(SchedulerPhaseType.active)}
                                text="Active"
                            />
                        </Grid>
                        <Grid item>
                            <SchedulerPhaseItem
                                onClick={() => setPhases([SchedulerPhaseType.pending])}
                                selected={phases.includes(SchedulerPhaseType.pending)}
                                text="Pending"
                            />
                        </Grid>
                        <Grid item>
                            <SchedulerPhaseItem
                                onClick={() => setPhases([SchedulerPhaseType.terminated])}
                                selected={phases.includes(SchedulerPhaseType.terminated)}
                                text="Terminated"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <HESPFormLabel label="Sort by" />
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                onChange={(e) => setSortBy(e.target.value)}
                                variant="outlined"
                                value={sortBy}
                            >
                                {sortItems.map((item) => (
                                    <MenuItem value={item.id} key={`sort-${item.id}`} style={{ fontSize: "14px" }}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Grid>
                        <Grid item>
                            <HESPFormLabel label="Direction" />
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                onChange={(e) => setSortDirection(e.target.value as OrderDirectionType)}
                                variant="outlined"
                                value={sortDirection}
                            >
                                <MenuItem value={OrderDirectionType.asc} style={{ fontSize: "14px" }}>
                                    ASC
                                </MenuItem>
                                <MenuItem value={OrderDirectionType.desc} style={{ fontSize: "14px" }}>
                                    DESC
                                </MenuItem>
                            </CssTextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"flex-end"}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Search />}
                            onClick={() =>
                                onApplyFilters({
                                    endDate,
                                    phases,
                                    search,
                                    sortBy,
                                    sortDirection,
                                    startDate,
                                })
                            }
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

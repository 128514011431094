import * as React from "react";
import { GetEventsQuery_events_events } from "./__generated__/GetEventsQuery";
import styled from "styled-components";
import moment from "moment";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { EventType } from "../../__generated__/globalTypes";
import { HESPlink } from "../Landing/SignUpComplete";
import { Table, TableRow } from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import atomOneLight from "react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light";
import { Code } from "../Channels/Details/ChannelEmbedExample";

interface Props {
    event: GetEventsQuery_events_events;
    organizationId: string;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 6px;
    padding-top: 14px;
    font-size: 13px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const Details = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
`;

const Collapse = styled.div`
    margin: 6px 0px;
    padding: 10px 4px;
    border-radius: 8px;
    background-color: rgb(250, 250, 250);
`;

const Left = styled.div`
    width: 90%;
`;

const Right = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 140px;
    color: ${(props) => props.theme.gray};
`;

const Link = styled.div`
    margin-left: 10px;
`;

const TableCell = styled.td`
    font-size: 13px;
    padding: 5px 10px 5px 10px;
`;

export default function EventItem({ event, organizationId }: Props) {
    const [showDetails, setShowDetails] = React.useState<boolean>(false);
    return (
        <Wrapper>
            <Details onClick={() => setShowDetails(!showDetails)}>
                <Left>{getEventDescription(event, organizationId)}</Left>
                <Right>
                    <div>{moment(event.timestamp).format("MMM DD - HH:mm:ss")}</div>
                    <Link>
                        {showDetails ? (
                            <KeyboardArrowUp color="primary" style={{ fontSize: "20px" }} />
                        ) : (
                            <KeyboardArrowDown color="primary" style={{ fontSize: "20px" }} />
                        )}
                    </Link>
                </Right>
            </Details>
            {showDetails && (
                <Collapse>
                    <Table size="small">
                        <TableRow>
                            <TableCell>
                                <strong>Event ID</strong>
                            </TableCell>
                            <TableCell>{event.eventId}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Type</strong>
                            </TableCell>
                            <TableCell>
                                <Code>{event.type.replace("_", ".")}</Code>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Object ID</strong>
                            </TableCell>
                            <TableCell>
                                <HESPlink underline link={`/app/${organizationId}/${getObjectLink(event)}`}>
                                    {event.objectId}
                                </HESPlink>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Timestamp (epoch)</strong>
                            </TableCell>
                            <TableCell>{event.timestamp}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Data</strong>
                            </TableCell>
                            <TableCell>
                                <SyntaxHighlighter
                                    style={{ ...atomOneLight, padding: "5px 40px 5px 20px !important" }}
                                    language={"json"}
                                >
                                    {JSON.stringify(JSON.parse(event.data ?? "{}"), null, " ")}
                                </SyntaxHighlighter>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Collapse>
            )}
        </Wrapper>
    );
}

function getObjectLink(event: GetEventsQuery_events_events) {
    const { data: dataString, type, objectId } = event;
    const data = JSON.parse(dataString);

    if (type.includes("channel")) {
        return `channels/${objectId}`;
    }
    if (type.includes("alias")) {
        return `channels/${data!.parentChannelId}`;
    }
    if (type.includes("webhook")) {
        return `webhooks/${objectId}`;
    }
    if (type.includes("scheduler")) {
        return `schedulers/${objectId}`;
    }
}

function getEventDescription(event: GetEventsQuery_events_events, organizationId: string) {
    const { objectId, type, data } = event;
    switch (type) {
        case EventType.channel_creating:
            return <span>A new channel {objectId} got created</span>;
        case EventType.channel_created:
            return <span>Creation of channel {objectId} completed</span>;
        case EventType.channel_deploying:
            return <span>Channel {objectId} got started and is deploying</span>;
        case EventType.channel_starting:
            return <span>Channel {objectId} moved to "starting" mode</span>;
        case EventType.channel_waiting:
            return <span>Channel {objectId} moved to "waiting" mode</span>;
        case EventType.channel_ingesting:
            return <span>Channel {objectId} moved to "ingesting" mode</span>;
        case EventType.channel_error:
            return <span>Channel {objectId} received an error.</span>;
        case EventType.channel_playing:
            return <span>Channel {objectId} is playing content</span>;
        case EventType.channel_scheduled:
            return <span>Channel {objectId} got started due to a scheduler and will be available shortly. </span>;
        case EventType.channel_stopping:
            return <span>Channel {objectId} got stopped</span>;
        case EventType.channel_stopped:
            return <span>Stopping of channel {objectId} is completed.</span>;
        case EventType.channel_updated:
            return <span>The config of channel {objectId} got updated</span>;
        case EventType.channel_deleting:
            return <span>Channel {objectId} got deleted</span>;
        case EventType.channel_deleted:
            return <span>Request to delete channel {objectId} completed</span>;
        case EventType.alias_created:
            return <span>Channel alias {objectId} got created</span>;
        case EventType.alias_enabled:
            return <span>Channel alias {objectId} got enabled</span>;
        case EventType.alias_disabled:
            return <span>Channel alias {objectId} got disabled</span>;
        case EventType.alias_updated:
            return <span>The config of channel alias {objectId} got updated</span>;
        case EventType.alias_deleting:
            return <span>Channel alias {objectId} got deleted</span>;
        case EventType.alias_deleted:
            return <span>The deletion of channel alias {objectId} got completed</span>;
        case EventType.webhook_created:
            return <span>A new webhook {objectId} got created</span>;
        case EventType.webhook_updated:
            return <span>The config of webhook {objectId} got updated</span>;
        case EventType.webhook_enabled:
            return <span>Webhook {objectId} got enabled</span>;
        case EventType.webhook_disabled:
            return <span>Webhook {objectId} got disabled</span>;
        case EventType.webhook_deleted:
            return <span>Webhook {objectId} got deleted</span>;
        case EventType.scheduler_created:
            return <span>Scheduler {objectId} got created</span>;
        case EventType.scheduler_updated:
            return <span>Scheduler {objectId} got updated</span>;
        case EventType.scheduler_deleted:
            return <span>Scheduler {objectId} got deleted</span>;
        case EventType.scheduler_starting:
            return <span>Scheduler {objectId} is starting up in the background. Stream is not yet available</span>;
        case EventType.scheduler_terminated:
            return <span>Scheduler {objectId} got terminated</span>;
        case EventType.scheduler_active:
            return <span>Scheduler {objectId} got activated.</span>;
        case EventType.channel_log_error:
            return (
                <span>
                    Channel {objectId} received an error log: <Code>{JSON.parse(data).message ?? ""} </Code>
                </span>
            );
        case EventType.channel_log_warn:
            return (
                <span>
                    Channel {objectId} received a warn log: <Code>{JSON.parse(data).message ?? ""} </Code>
                </span>
            );
        case EventType.channel_log_info:
            return (
                <span>
                    Channel {objectId} received an info log: <Code>{JSON.parse(data).message ?? ""}</Code>
                </span>
            );
        default:
            return "";
    }
}

import { Button, FormControl, Grid, MenuItem, TextField } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import {
    GetChannelQuery_channel,
    GetChannelQuery_channel_aliases_fallback,
    GetChannelQuery_channel_fallback,
} from "../Overview/__generated__/GetChannelQuery";
import ContentSettingsWithSwitchCard from "./ContentSettingsWithSwitchCard";
import CopyAliasConfigToFailover from "./CopyAliasConfigToFailover";
import { UpdateFallbackMutation, UpdateFallbackMutationVariables } from "./__generated__/UpdateFallbackMutation";
import {
    UpdateAliasFallbackMutation,
    UpdateAliasFallbackMutationVariables,
} from "./__generated__/UpdateAliasFallbackMutation";

import { FallbackType } from "../../../__generated__/globalTypes";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { GetChannelsOrganizationQuery_channels } from "../Overview/__generated__/GetChannelsOrganizationQuery";
import {
    GetFallbackChannelOrAlias,
    GetFallbackChannelOrAliasVariables,
} from "./__generated__/GetFallbackChannelOrAlias";

interface Props {
    playoutId: string;
    channel: GetChannelQuery_channel;
    allParentChannels: GetChannelsOrganizationQuery_channels[];
}

interface IdName {
    id: string;
    name: string;
}

interface InitConfig {
    isEnabled: boolean;
    manifestUrl: string;
    selectedType: FallbackType;
    selectedSrcId?: string;
}

export const GET_FALLBACK_CHANNEL_OR_ALIAS = gql`
    query GetFallbackChannelOrAlias($channelId: ID, $organizationId: ID!) {
        channelOrAlias(channelId: $channelId, organizationId: $organizationId) {
            channelId
            aliasId
            aliases {
                channelId
                metadata {
                    name
                }
            }
        }
    }
`;

export const UPDATE_FALLBACK_MUTATION = gql`
    mutation UpdateFallbackMutation($input: UpdateFallbackInput!) {
        updateFallback(input: $input) {
            channelId
        }
    }
`;

export const UPDATE_ALIAS_FALLBACK_MUTATION = gql`
    mutation UpdateAliasFallbackMutation($input: UpdateAliasFallbackInput!) {
        updateAliasFallback(input: $input) {
            channelId
        }
    }
`;

const FallbackItem = styled.div.attrs((props: { selected: boolean }) => ({
    selected: props.selected,
}))`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    width: 140px;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    background-color: ${(props) => (props.selected ? "#fffcf2" : "white")};
    border: 1px solid ${(props) => (props.selected ? props.theme.primary : props.theme.lightGrayBottom)};
`;

export default function ChannelPlayoutFailoverManager({ playoutId, channel, allParentChannels }: Props) {
    const { hybridFallback } = channel.organization.channelSettings;
    const hybridFallbackEnabled = hybridFallback?.enabled === true;
    const parentChannelsThatCanBeSelected: IdName[] = allParentChannels
        .filter((ch) => ch.channelId !== channel.channelId)
        .map((ch) => ({
            id: ch.channelId,
            name: ch.metadata.name,
        }));
    const isAlias = channel.channelId !== playoutId;

    const currentFallback: GetChannelQuery_channel_fallback | GetChannelQuery_channel_aliases_fallback | null = !isAlias
        ? channel.fallback
        : channel.aliases.find((a) => a.channelId === playoutId)!.fallback;

    const [currentId, setCurrentId] = React.useState<string | undefined>(playoutId);

    const {
        isEnabled: initEnabled,
        manifestUrl: initManifestUrl,
        selectedType: initType,
        selectedSrcId: initSrcId,
    } = getInitConfig(currentFallback, hybridFallbackEnabled, parentChannelsThatCanBeSelected);

    React.useEffect(() => {
        setEnabled(initEnabled);
        setCurrentId(playoutId);
        setSelectedType(initType);
        setSelectedSrcId(initSrcId);
        setManifestUrl(initManifestUrl);
        setNeedsSave(false);
    }, [initEnabled, initManifestUrl, initSrcId, initType, playoutId, currentId]);

    const [needsSave, setNeedsSave] = React.useState<boolean>(false);

    const [selectedType, setSelectedType] = React.useState<FallbackType>(
        hybridFallback.enabled === false ? FallbackType.theo_live : channel.fallback?.type ?? FallbackType.theo_live,
    );
    const [enabled, setEnabled] = React.useState<boolean>(initEnabled);
    const [selectedSrcId, setSelectedSrcId] = React.useState<string | undefined>(initSrcId);
    const [manifestUrl, setManifestUrl] = React.useState<string>(initManifestUrl);

    const [updateFailoverMut, { loading: loadingUpdateChannelFailoverLoading }] = useMutation<
        UpdateFallbackMutation,
        UpdateFallbackMutationVariables
    >(UPDATE_FALLBACK_MUTATION);
    const [updateAliasFailoverMut, { loading: loadingUpdateAliasFailoverLoading }] = useMutation<
        UpdateAliasFallbackMutation,
        UpdateAliasFallbackMutationVariables
    >(UPDATE_ALIAS_FALLBACK_MUTATION);

    const { data } = useQuery<GetFallbackChannelOrAlias, GetFallbackChannelOrAliasVariables>(
        GET_FALLBACK_CHANNEL_OR_ALIAS,
        {
            variables: {
                organizationId: channel.organizationId,
                channelId: selectedSrcId,
            },
        },
    );

    function onEnabledChange() {
        setEnabled(!enabled);
        if (parentChannelsThatCanBeSelected.length > 0) {
            setNeedsSave(true);
        }
    }

    function onTypeChange(type: FallbackType) {
        setSelectedType(type);
        setNeedsSave(true);
        if (type === FallbackType.theo_live) {
            if (parentChannelsThatCanBeSelected.length > 0) {
                setSelectedSrcId(parentChannelsThatCanBeSelected[0].id);
            }
        }
    }

    function onDiscard() {
        setEnabled(initEnabled);
        setSelectedType(initType);
        setSelectedSrcId(initSrcId);
        setNeedsSave(false);
    }

    async function onSave() {
        try {
            if (!isAlias) {
                await updateFailoverMut({
                    variables: {
                        input: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                            enabled,
                            src: selectedType === FallbackType.theo_live ? selectedSrcId! : manifestUrl,
                            type: selectedType,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_CHANNEL_QUERY,
                            variables: {
                                channelId: channel.channelId,
                                organizationId: channel.organizationId,
                            },
                        },
                    ],
                });
            } else {
                await updateAliasFailoverMut({
                    variables: {
                        input: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                            channelAliasId: playoutId,
                            enabled,
                            src: selectedType === FallbackType.theo_live ? selectedSrcId! : manifestUrl,
                            type: selectedType,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_CHANNEL_QUERY,
                            variables: {
                                channelId: channel.channelId,
                                organizationId: channel.organizationId,
                            },
                        },
                    ],
                });
            }
            setNeedsSave(false);
            toast.success("Failover updated");
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    return (
        <>
            <ContentSettingsWithSwitchCard
                title="Fallback channel"
                subtitle="Enabling and setting a fallback will result in this playout being switched to it when your stream is unavailable for a short amount of time"
                enabled={enabled}
                onSaveClick={onSave}
                showSwitch
                needsSave={needsSave}
                onEnabledChange={onEnabledChange}
                onDiscardClick={onDiscard}
                isLoading={loadingUpdateChannelFailoverLoading || loadingUpdateAliasFailoverLoading}
            >
                {enabled ? (
                    <Grid container spacing={2}>
                        {hybridFallbackEnabled && (
                            <>
                                <Grid item xs={12} md={8}>
                                    <FormControl>
                                        <HESPFormLabel label="Type" />
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <FallbackItem
                                                    selected={selectedType === FallbackType.theo_live}
                                                    onClick={() => onTypeChange(FallbackType.theo_live)}
                                                >
                                                    THEOlive channel
                                                </FallbackItem>
                                            </Grid>
                                            <Grid item>
                                                <FallbackItem
                                                    selected={selectedType === FallbackType.hls}
                                                    onClick={() => onTypeChange(FallbackType.hls)}
                                                >
                                                    HLS stream
                                                </FallbackItem>
                                            </Grid>
                                            <Grid item>
                                                <FallbackItem
                                                    selected={selectedType === FallbackType.dash}
                                                    onClick={() => onTypeChange(FallbackType.dash)}
                                                >
                                                    Dash stream
                                                </FallbackItem>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}></Grid>
                            </>
                        )}
                        {selectedType === FallbackType.theo_live && (
                            <>
                                {parentChannelsThatCanBeSelected.length === 0 ? (
                                    <>
                                        <Grid item xs={12}>
                                            No channels found to fallback to.
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() =>
                                                    navigate(`/app/${channel.organizationId}/channels/create`)
                                                }
                                            >
                                                Create channel
                                            </Button>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <HESPFormLabel label="Parent channel" />
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        disabled={typeof data === "undefined"}
                                                        size="small"
                                                        name="resolution"
                                                        onChange={(e) => {
                                                            setNeedsSave(true);
                                                            setSelectedSrcId(e.target.value);
                                                        }}
                                                        variant="outlined"
                                                        value={data?.channelOrAlias.channelId ?? ""}
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {parentChannelsThatCanBeSelected.map((c, i) => (
                                                            <MenuItem
                                                                value={c.id}
                                                                key={`r-${i}`}
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                {c.name} ({c.id})
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <HESPFormLabel label="Alias" />
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        disabled={typeof data === "undefined"}
                                                        size="small"
                                                        name="resolution"
                                                        onChange={(e) => {
                                                            setNeedsSave(true);
                                                            setSelectedSrcId(e.target.value);
                                                        }}
                                                        variant="outlined"
                                                        value={selectedSrcId}
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        <MenuItem
                                                            value={data?.channelOrAlias.channelId ?? undefined}
                                                            style={{ fontSize: "14px" }}
                                                        >
                                                            Default ({data?.channelOrAlias.channelId})
                                                        </MenuItem>
                                                        {data?.channelOrAlias.aliases.map((c, i) => (
                                                            <MenuItem
                                                                value={c.channelId}
                                                                key={`r-${i}`}
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                {c.channelId === data.channelOrAlias.channelId
                                                                    ? "Default"
                                                                    : c.metadata.name}{" "}
                                                                ({c.channelId})
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    </>
                                )}
                            </>
                        )}
                        {[FallbackType.dash, FallbackType.hls].includes(selectedType) && (
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Manifest URL" />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        onChange={(e) => {
                                            setManifestUrl(e.target.value);
                                            setNeedsSave(true);
                                        }}
                                        variant="outlined"
                                        value={manifestUrl}
                                        style={{ backgroundColor: "white" }}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        {!needsSave &&
                            selectedType === FallbackType.theo_live &&
                            selectedSrcId &&
                            data?.channelOrAlias.channelId && (
                                <Grid item xs={12}>
                                    <CopyAliasConfigToFailover
                                        playoutId={playoutId}
                                        isAlias={isAlias}
                                        channel={channel}
                                        toAliasId={selectedSrcId}
                                        toChannelId={data.channelOrAlias.channelId}
                                        isFailoverAlias={data.channelOrAlias.channelId !== selectedSrcId}
                                    />
                                </Grid>
                            )}
                    </Grid>
                ) : (
                    <></>
                )}
            </ContentSettingsWithSwitchCard>
            <ToastContainer />
        </>
    );
}

function getInitConfig(
    fallback: GetChannelQuery_channel_fallback | GetChannelQuery_channel_aliases_fallback | null,
    hybridFallbackEnabled: boolean,
    parentChannels: IdName[],
): InitConfig {
    const isEnabled = fallback !== null && fallback.enabled === true;
    const manifestUrl = fallback?.src ?? "";
    const selectedType = hybridFallbackEnabled ? fallback?.type ?? FallbackType.theo_live : FallbackType.theo_live;

    return {
        isEnabled,
        manifestUrl,
        selectedType,
        selectedSrcId:
            typeof fallback?.src === "string"
                ? fallback.src
                : parentChannels.length > 0
                ? parentChannels[0].id
                : undefined,
    };
}

import * as React from "react";
import { DialogContent, Grid, DialogActions, Button, Link, FormControlLabel, Checkbox } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { ActivatePaygMutation, ActivatePaygMutationVariables } from "./__generated__/ActivatePaygMutation";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "./AddPaymentMethodDialog";
import { GET_FULL_BILLING_DETAILS } from "./BillingDetailsCardContent";
import { GET_ORGANIZATION_BASICS_QUERY } from "../GetStarted/AccountWarningMessage";

interface Props {
    open: boolean;
    organizationId: string;
    onClose: () => void;
}

export const ACTIVATE_PAYG = gql`
    mutation ActivatePaygMutation($input: EnablePaygInput!) {
        enablePayg(input: $input)
    }
`;

export default function ActivatePaygDialog({ open, onClose, organizationId }: Props) {
    const [acceptedTerms, setAcceptedTerms] = React.useState<boolean>(false);
    const [acceptedStripeTerms, setAcceptedStripeTerms] = React.useState<boolean>(false);

    const [activationSuccessful, setActivationSuccessful] = React.useState<boolean>(false);

    const [activatePaygMut, { loading }] = useMutation<ActivatePaygMutation, ActivatePaygMutationVariables>(
        ACTIVATE_PAYG,
    );

    const termsStripeLabel = (
        <span>
            I agree that the payment transactions will be subject to{" "}
            <Link target="_blank" href="https://stripe.com/en-be/legal/end-users">
                the terms and conditions of Stripe
            </Link>
            .
        </span>
    );

    const termsLabel = (
        <span>
            I accept{" "}
            <Link target="_blank" href="https://www.theoplayer.com/terms-and-conditions">
                the THEOlive General Terms and Conditions and the THEO Technologies Privacy Policy
            </Link>
            .
        </span>
    );

    async function activate() {
        if (acceptedTerms && acceptedStripeTerms) {
            try {
                await activatePaygMut({
                    variables: {
                        input: {
                            organizationId,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_FULL_BILLING_DETAILS,
                            variables: {
                                organizationId,
                            },
                        },
                        {
                            query: GET_ORGANIZATION_BASICS_QUERY,
                            variables: {
                                organizationId,
                            },
                        },
                    ],
                });
                setActivationSuccessful(true);
            } catch (_e) {
                toast.error("Something went wrong", TOAST_SETTINGS);
            }
        }
    }

    return (
        <DialogHESP
            title={activationSuccessful ? "PAYG mode activated!" : "Activate PAYG mode"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {activationSuccessful ? (
                                    <p>
                                        The pay as you go mode got successfully activated. You have now access to
                                        unlimited transoding and viewing minutes!
                                    </p>
                                ) : (
                                    <>
                                        <p>
                                            When activating the pay-as-you-go option, the default THEOlive pricing
                                            applies. Please visit{" "}
                                            <a
                                                href="https://www.theoplayer.com/pricing/theolive"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                https://www.theoplayer.com/pricing/theolive
                                            </a>{" "}
                                            for the latest pricings.
                                        </p>
                                        <p>You'll receive a coupon of $20.00 once the activation is completed!</p>
                                        <FormControlLabel
                                            style={{ marginTop: "20px" }}
                                            label={termsStripeLabel}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={acceptedStripeTerms}
                                                    onChange={() => setAcceptedStripeTerms(!acceptedStripeTerms)}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            style={{ marginTop: "20px" }}
                                            label={termsLabel}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={acceptedTerms}
                                                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                                                />
                                            }
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {activationSuccessful ? (
                            <Button onClick={onClose} color="secondary">
                                Close
                            </Button>
                        ) : (
                            <DialogFooterWithLoading
                                onActionClick={activate}
                                isLoading={loading}
                                onCancelClick={onClose}
                                defaultText={"Activate"}
                                disabled={!acceptedTerms || !acceptedStripeTerms}
                                loadingText={"Activating..."}
                            />
                        )}
                    </DialogActions>
                </>
            }
        />
    );
}

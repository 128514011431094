import * as React from "react";
import styled from "styled-components";

interface Props {
    title?: string;
    customizations: React.ReactNode;
}

const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
`;

const Customizations = styled.div`
    margin-bottom: 10px;
    padding-bottom: 10px;
`;

function ChannelCustomizerSection({ title, customizations }: Props) {
    return (
        <React.Fragment>
            {title && <Title>{title}</Title>}
            <Customizations>{customizations}</Customizations>
        </React.Fragment>
    );
}

export default ChannelCustomizerSection;

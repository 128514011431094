import { AddToQueue } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import CardHESP from "../../CardHESP/CardHESP";
import CardHESPTitle from "../../CardHESP/CardHESPTitle";
import { getInitialCustomization } from "../../ChannelCustomizer/customizationHelpers";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChannelCreateAliasDialog from "./ChannelCreateAliasDialog";
import ChannelPlayoutCustomization, { GET_THEO_TOKEN } from "./ChannelPlayoutCustomization";
import ChannelPlayoutIncludeDocumentation from "./ChannelPlayoutIncludeDocumentation";
import ChannelPlayoutsNav from "./ChannelPlayoutsNav";
import ChannelPlayoutsSelector, { ChannelWithName } from "./ChannelPlayoutsSelector";
import { ChannelCustomization as ChannelCustomizationObj } from "../../ChannelCustomizer/customizationHelpers";
import { GET_CHANNEL_QUERY, UPDATE_CHANNEL_MUTATION } from "../Overview/channelQueriesMutations";
import { useMutation, useQuery } from "react-apollo";
import styled from "styled-components";
import { UpdateChannelMutation, UpdateChannelMutationVariables } from "../Overview/__generated__/UpdateChannelMutation";
import { UPDATE_CHANNEL_ALIAS_MUTATION } from "./ChannelUpdateAliasDialog";
import {
    UpdateChannelAliasMutation,
    UpdateChannelAliasMutationVariables,
} from "./__generated__/UpdateChannelAliasMutation";
import ChannelPlayoutSecurity from "./ChannelPlayoutSecurity";
import ChannelPlayoutAdmin from "./ChannelPlayoutAdmin";
import ChannelPlayoutFailover from "./ChannelPlayoutFailover";
import { GetTHEOTokenQuery, GetTHEOTokenQueryVariables } from "./__generated__/GetTHEOTokenQuery";
export type AliasNavigationType = "customization" | "security" | "include" | "admin" | "failover";

interface Props {
    channel: GetChannelQuery_channel;
}

const NotActiveWarning = styled.div`
    background-color: #ffebcf;
    padding: 16px 10px;
    font-size: 14px;
`;

function ChannelPlayouts({ channel }: Props) {
    const [selectedPlayoutId, setSelectedPlayoutId] = React.useState<string>(channel.channelId);
    const [selectedNavItem, setSelectedNavItem] = React.useState<AliasNavigationType>("customization");
    const [openCreateAliasDialog, setOpenCreateAliasDialog] = React.useState<boolean>(false);
    const [customizationChanged, setCustomizationChanged] = React.useState<boolean>(false);

    const isAlias = channel.channelId !== selectedPlayoutId;

    const { data, refetch } = useQuery<GetTHEOTokenQuery, GetTHEOTokenQueryVariables>(GET_THEO_TOKEN, {
        variables: {
            aliasId: selectedPlayoutId,
            channelId: channel.channelId,
            organizationId: channel.organizationId,
        },
        pollInterval: 60_000,
    });

    const metadata = isAlias
        ? channel.aliases.find((a) => a.channelId === selectedPlayoutId)!.metadata
        : channel.metadata;

    const initialCustomization: ChannelCustomizationObj = getInitialCustomization(metadata);

    const [channelCustomization, setChannelCustomization] =
        React.useState<ChannelCustomizationObj>(initialCustomization);

    const [updateChannelMut, { loading: loadingChannelMut }] = useMutation<
        UpdateChannelMutation,
        UpdateChannelMutationVariables
    >(UPDATE_CHANNEL_MUTATION);

    const [updateChannelAliasMut, { loading: loadingAliasMut }] = useMutation<
        UpdateChannelAliasMutation,
        UpdateChannelAliasMutationVariables
    >(UPDATE_CHANNEL_ALIAS_MUTATION);

    function onChangePlayout(playoutId: string) {
        const isAlias = channel.channelId !== playoutId;
        const metadata = isAlias ? channel.aliases.find((a) => a.channelId === playoutId)!.metadata : channel.metadata;
        setChannelCustomization(getInitialCustomization(metadata));
        setCustomizationChanged(false);
        refetch({
            aliasId: playoutId,
            channelId: channel.channelId,
            organizationId: channel.organizationId,
        }).then(() => setSelectedPlayoutId(playoutId));
    }

    async function saveCustomization() {
        if (isAlias) {
            await saveAliasCustomization();
        } else {
            await saveChannelCustomization();
        }
        setCustomizationChanged(false);
    }

    async function saveChannelCustomization() {
        try {
            await updateChannelMut({
                variables: {
                    input: {
                        channelId: channel.channelId,
                        organizationId: channel.organizationId,
                        metadata: {
                            customization: channelCustomization,
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            toast.success(`Customization successfully updated 🚀`, TOAST_SETTINGS);
        } catch (_e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    async function saveAliasCustomization() {
        try {
            await updateChannelAliasMut({
                variables: {
                    input: {
                        organizationId: channel.organizationId,
                        channelAliasId: selectedPlayoutId,
                        customization: channelCustomization,
                        channelId: channel.channelId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            toast.success(`Customization successfully updated 🚀`, TOAST_SETTINGS);
        } catch (_e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    function getTabContent() {
        switch (selectedNavItem) {
            case "customization":
                return (
                    <ChannelPlayoutCustomization
                        token={data?.token}
                        channel={channel}
                        isAlias={isAlias}
                        playoutId={selectedPlayoutId}
                        manifestUrl={channel.manifestUrl}
                        customization={channelCustomization}
                        customizationChanged={customizationChanged}
                        onChangeCustomization={(updatedCustomization: ChannelCustomizationObj) => {
                            setCustomizationChanged(true);
                            setChannelCustomization(updatedCustomization);
                        }}
                        loading={loadingChannelMut || loadingAliasMut}
                        onSave={() => saveCustomization()}
                    />
                );
            case "include":
                return <ChannelPlayoutIncludeDocumentation channelId={selectedPlayoutId} />;
            case "security":
                return <ChannelPlayoutSecurity channel={channel} playoutId={selectedPlayoutId} />;
            case "admin":
                return <ChannelPlayoutAdmin channel={channel} playoutId={selectedPlayoutId} />;
            case "failover":
                return <ChannelPlayoutFailover channel={channel} playoutId={selectedPlayoutId} />;
        }
    }

    const channelsWithName: ChannelWithName[] = [
        {
            channelId: channel.channelId,
            name: channel.metadata.name,
            isDefault: true,
            isActive: true,
        },
    ];

    if (channel.aliases.length > 0) {
        channel.aliases.forEach((a) => {
            channelsWithName.push({
                channelId: a.channelId,
                name: a.metadata.name,
                isDefault: false,
                isActive: a.active,
            });
        });
    }

    const selectedPlayout = channelsWithName.find((c) => c.channelId === selectedPlayoutId)!;

    function onCreateAliasSuccess(newAliasId: string) {
        toast.success(`Channel alias successfully created 🚀`, TOAST_SETTINGS);
        const metadata = channel.metadata;
        setChannelCustomization(getInitialCustomization(metadata));
        setCustomizationChanged(false);
        setSelectedPlayoutId(newAliasId);
    }

    function onCreateAliasError() {
        toast.error("Something went wrong 😞", TOAST_SETTINGS);
    }

    return (
        <>
            <CardHESP>
                <CardHESPTitle
                    title="Playout configurations"
                    button={
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AddToQueue />}
                            onClick={() => setOpenCreateAliasDialog(true)}
                        >
                            Create alias
                        </Button>
                    }
                />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ChannelPlayoutsSelector
                            channelAndAliases={channelsWithName}
                            selectedAlias={selectedPlayoutId}
                            onChange={(alias: string) => {
                                onChangePlayout(alias);
                            }}
                        />
                    </Grid>
                    {!selectedPlayout.isActive && (
                        <Grid item xs={12}>
                            <NotActiveWarning>
                                <strong>Warning: </strong> this alias is currently disabled. It cannot be shared with
                                your viewers.
                            </NotActiveWarning>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <ChannelPlayoutsNav
                            alias={selectedPlayoutId}
                            channel={channel}
                            selectedItem={selectedNavItem}
                            isActive={selectedPlayout?.isActive}
                            onChange={(item: AliasNavigationType) => setSelectedNavItem(item)}
                            isDefaultChannel={selectedPlayoutId === channel.channelId}
                            onDeletedAlias={() => setSelectedPlayoutId(channel.channelId)}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        {getTabContent()}
                    </Grid>
                </Grid>
            </CardHESP>
            <ChannelCreateAliasDialog
                channel={channel}
                open={openCreateAliasDialog}
                onClose={() => setOpenCreateAliasDialog(false)}
                onError={onCreateAliasError}
                onSuccess={(aliasId: string) => onCreateAliasSuccess(aliasId)}
            />
            <ToastContainer />
        </>
    );
}

export default ChannelPlayouts;

import * as React from "react";
import { Switch } from "@mui/material";
import { UserRoleType } from "../../__generated__/globalTypes";
import { toast } from "react-toastify";
import AuthService from "../../auth/AuthService";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { UpdateUserMutation, UpdateUserMutationVariables } from "./__generated__/UpdateUserMutation";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const UPDATE_USER_MUTATION = gql`
    mutation UpdateUserMutation($input: UpdateUserInput!) {
        updateUser(input: $input) {
            auth0Id
        }
    }
`;

interface Props {
    userId: string;
    roles: UserRoleType[];
}

function UserRoleSwitch({ userId, roles }: Props) {
    const viewer = AuthService.getLocalUserProfile()!;
    const viewerRoles = viewer.roles;
    const disable = viewerRoles?.includes(UserRoleType.hesp_user) || userId === viewer?.auth0Id;

    const [isDisabled, setIsDisabled] = React.useState(disable);
    const [isChecked, setIsChecked] = React.useState(!roles.includes(UserRoleType.hesp_user));
    const [updateUserMut] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_MUTATION);

    async function onChangeRole(isAdmin: boolean) {
        setIsDisabled(true);
        try {
            await updateUserMut({
                variables: {
                    input: {
                        auth0Id: userId,
                        role: isAdmin ? UserRoleType.hesp_admin : UserRoleType.hesp_user,
                    },
                },
            });
            setIsChecked(isAdmin);
            toast.success("Role got changed successfully", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        setIsDisabled(disable);
    }

    return (
        <Switch
            checked={isChecked}
            disabled={isDisabled}
            color={"secondary"}
            onChange={(e) => onChangeRole(e.target.checked)}
        />
    );
}

export default UserRoleSwitch;

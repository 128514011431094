import styled from "styled-components";
import { GetFullOrganizationDetails_organization_tla_datacenters } from "../../views/App/__generated__/GetFullOrganizationDetails";
import { Code } from "../Channels/Details/ChannelEmbedExample";
import { IconButton } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";

interface Props {
    onRemove: () => void;
    datacenter: GetFullOrganizationDetails_organization_tla_datacenters;
}

const Wrapper = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 10px;
    font-size: 14px;
    align-items: center;
`;

export default function OrganizationDatacenterItem({ datacenter, onRemove }: Props) {
    return (
        <Wrapper>
            <div>
                {datacenter.name} (<Code>{datacenter.id}</Code>)
            </div>
            <div>
                <IconButton size="small" onClick={onRemove}>
                    <DeleteForever fontSize="small" color="error" />
                </IconButton>
            </div>
        </Wrapper>
    );
}

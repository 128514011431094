import styled from "styled-components";
import { GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps_runsOfChannelDuringScheduler } from "./__generated__/GetSchedulerChannelsWithOverlapsQuery";
import moment from "moment";
import { HESPlink } from "../Landing/SignUpComplete";
import { AuthCredTypeEnum } from "../../__generated__/globalTypes";

const Info = styled.p`
    padding: 0;
    margin: 0;
    font-size: 14px;
`;

interface Props {
    runs: GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps_runsOfChannelDuringScheduler[];
}

const Item = styled.div`
    display: flex;
    flex-direction: row;
    border-radius 8px;
    padding: 10px 16px;
    font-size: 13px;
    background-color: #fafafa;
    margin: 16px 0px;
`;

const Times = styled.div`
    width: 300px;
`;

const DoneBy = styled.div``;

export default function SchedulerAuditChannelRunsDuringScheduler({ runs }: Props) {
    return (
        <>
            <Info>Runs of channel during scheduler time range:</Info>
            {runs.map((run, i) => (
                <Item key={`run-${i}-${run.id}`}>
                    <Times>
                        <div>
                            <strong>Start: </strong> {moment(run.start).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                        <div style={{ marginTop: "8px" }}>
                            <strong>End: </strong> {moment(run.end).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                    </Times>
                    <DoneBy>
                        <div>
                            <strong>Started by: </strong> {getStartedBy(run)}
                            <div style={{ marginTop: "8px" }}>
                                <strong>Stopped by: </strong> {getStoppedBy(run)}
                            </div>
                        </div>
                    </DoneBy>
                </Item>
            ))}
        </>
    );
}

function getStartedBy(
    run: GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps_runsOfChannelDuringScheduler,
) {
    const { startedById, startedByType, organizationId } = run;

    if (startedByType === AuthCredTypeEnum.token) {
        return (
            <HESPlink underline link={`/app/${organizationId}/tokens`}>
                Token ({run.startedById})
            </HESPlink>
        );
    }

    if (startedByType === AuthCredTypeEnum.scheduler) {
        return (
            <HESPlink underline link={`/app/${organizationId}/schedulers/${startedById}`}>
                Scheduler ({run.startedById})
            </HESPlink>
        );
    }

    if (startedByType === AuthCredTypeEnum.user) {
        return "User";
    }

    if (startedByType === AuthCredTypeEnum.auto) {
        return "Automatically";
    }

    if (startedByType === AuthCredTypeEnum.maintenance) {
        return "Automatically due to maintenance";
    }
}

function getStoppedBy(
    run: GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps_runsOfChannelDuringScheduler,
) {
    const { stoppedById, stoppedByType, organizationId, end } = run;

    if (!end) {
        return "Not stopped yet";
    }

    if (stoppedByType === AuthCredTypeEnum.token) {
        return (
            <HESPlink underline link={`/app/${organizationId}/tokens`}>
                Token ({run.stoppedById})
            </HESPlink>
        );
    }

    if (stoppedByType === AuthCredTypeEnum.scheduler) {
        return (
            <HESPlink underline link={`/app/${organizationId}/schedulers/${stoppedById}`}>
                Scheduler ({run.stoppedById})
            </HESPlink>
        );
    }

    if (stoppedByType === AuthCredTypeEnum.user) {
        return "User";
    }

    if (stoppedByType === AuthCredTypeEnum.auto) {
        return "Automatically";
    }

    if (stoppedByType === AuthCredTypeEnum.maintenance) {
        return "Automatically due to maintenance";
    }
}

import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { GetChannelWithAllAliasesQuery_channel } from "../Channels/Overview/__generated__/GetChannelWithAllAliasesQuery";
import {
    GetViewingMinutesPerAlias,
    GetViewingMinutesPerAliasVariables,
} from "./__generated__/GetViewingMinutesPerAlias";
import ChannelUsageViewed from "./ChannelUsageViewed";
import moment from "moment";

export const GET_VIEWING_MINUTES_PER_ALIAS_QUERY = gql`
    query GetViewingMinutesPerAlias($channelId: ID!, $organizationId: ID!, $startDate: String!, $endDate: String!) {
        getViewedMinutesPerChannelAlias(
            channelId: $channelId
            organizationId: $organizationId
            startDate: $startDate
            endDate: $endDate
        ) {
            id
            records {
                timestamp
                amount
            }
        }
    }
`;

interface Props {
    channel: GetChannelWithAllAliasesQuery_channel;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

function ChannelUsageViewedWrapper({ channel, startDate, endDate }: Props) {
    const { data, loading } = useQuery<GetViewingMinutesPerAlias, GetViewingMinutesPerAliasVariables>(
        GET_VIEWING_MINUTES_PER_ALIAS_QUERY,
        {
            variables: {
                channelId: channel.channelId,
                organizationId: channel.organizationId,
                startDate: moment(startDate).startOf("day").utc().toISOString(),
                endDate: moment(endDate).endOf("day").utc().toISOString(),
            },
        },
    );

    return (
        <>
            <ChannelUsageViewed channel={channel} isLoading={loading} data={data} />
        </>
    );
}

export default ChannelUsageViewedWrapper;

import { GetChannelQuery_channel_metadata } from "../Channels/Overview/__generated__/GetChannelQuery";

export interface ChannelCustomization {
    posterImg: string;
    announcement: string;
    logo: string;
    autoplay: boolean;
    colors: ChannelCustomizationColors;
    targetLatency: number;
}

export type ProperyChangeType = "colors" | "prelive-settings" | "main" | "latency";

export interface ChannelCustomizationColors {
    primary: string;
    secondary: string;
    tertiary: string;
}

interface UpdateChannelCustomization {
    customization: ChannelCustomization;
    type: ProperyChangeType;
    key: string;
    value: string | boolean | number;
}

const BASE_PRIMARY_COLOR = "#ffc50f";
const BASE_SECONDARY_COLOR = "white";
const BASE_TERTIARY_COLOR = "black";
const BASE_ANNOUNCEMENT = "Live stream hasn't started yet";
const BASE_POSTER_IMG = "";
const BASE_LOGO_IMG = "";
const BASE_AUTOPLAY = false;

export function getInitialCustomization(metadata?: GetChannelQuery_channel_metadata): ChannelCustomization {
    return {
        targetLatency: metadata?.customization?.targetLatency ?? 0,
        logo:
            metadata?.customization?.logo && metadata?.customization?.logo !== ""
                ? metadata?.customization?.logo
                : BASE_LOGO_IMG,
        posterImg:
            metadata?.customization?.posterImg && metadata?.customization?.posterImg !== ""
                ? metadata?.customization?.posterImg
                : BASE_POSTER_IMG,
        autoplay:
            metadata?.customization?.autoplay && typeof metadata?.customization?.autoplay !== "undefined"
                ? metadata.customization.autoplay
                : BASE_AUTOPLAY,
        announcement:
            typeof metadata?.customization?.announcement !== "string"
                ? BASE_ANNOUNCEMENT
                : metadata?.customization?.announcement,
        colors: {
            primary: metadata?.customization?.colors?.primary ?? BASE_PRIMARY_COLOR,
            secondary: metadata?.customization?.colors?.secondary ?? BASE_SECONDARY_COLOR,
            tertiary: metadata?.customization?.colors?.tertiary ?? BASE_TERTIARY_COLOR,
        },
    };
}

export function updateCustomization({
    customization,
    type,
    key,
    value,
}: UpdateChannelCustomization): ChannelCustomization {
    switch (type) {
        case "colors":
            return updateColors(customization, key, value as string);
        case "prelive-settings":
        case "main":
        case "latency":
            return updateSetting(customization, key, value);
    }
    // check for type in later stage
}

function updateColors(customization: ChannelCustomization, color: string, value: string): ChannelCustomization {
    return {
        ...customization,
        colors: {
            ...customization.colors,
            [color]: value,
        },
    };
}

function updateSetting(
    customization: ChannelCustomization,
    key: string,
    value: string | boolean | number,
): ChannelCustomization {
    return {
        ...customization,
        [key]: value,
    };
}

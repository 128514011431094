import { Switch } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    description: string;
    children?: React.ReactNode;
    switchUI?: {
        enabled: boolean;
        onChange: () => void;
    };
}

const Wrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.grayBackground};
    border-radius: 8px;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
`;

const MainSettingsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContentWrapper = styled.div`
    display: flex;
    background-color: white;
    border-radius: 8px;
    margin-top: 10px;
    padding: 5px 10px;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 15px;
    font-weight: bolder;
`;

const Description = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

const SwitchWrapper = styled.div``;

export default function OrganizationChannelSettingsItem({ description, title, children, switchUI }: Props) {
    return (
        <Wrapper>
            <MainSettingsWrapper>
                <MainWrapper>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </MainWrapper>
                {switchUI && (
                    <SwitchWrapper>
                        <Switch checked={switchUI.enabled} onChange={() => switchUI.onChange()} />
                    </SwitchWrapper>
                )}
            </MainSettingsWrapper>

            {switchUI?.enabled === true && <ContentWrapper>{children}</ContentWrapper>}
        </Wrapper>
    );
}

import { Switch } from "@mui/material";
import styled from "styled-components";

interface Props {
    title: string;
    onChange: () => void;
    checked: boolean;
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SwitchTitle = styled.span`
    margin-left: 10px;
    font-size: 15px;
`;

export default function SwitchWithTitle({ checked, onChange, title }: Props) {
    return (
        <Wrapper>
            <Switch color="primary" checked={checked} onChange={onChange} size="small" />{" "}
            <SwitchTitle>{title}</SwitchTitle>
        </Wrapper>
    );
}

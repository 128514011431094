import { FormGroup, TextField } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";

interface Props {
    version: string;
    onChangeVersion: (version: string) => void;
}

export default function OrganizationChannelSettingsEngine({ onChangeVersion, version }: Props): JSX.Element {
    return (
        <FormGroup>
            <HESPFormLabel label="Default engine version" description="Leave empty if latest should be used" />
            <TextField
                fullWidth
                type="text"
                size="small"
                placeholder="Version to use"
                onChange={(e) => onChangeVersion(e.target.value)}
                variant="outlined"
                key="engine-version"
                value={version}
            />
        </FormGroup>
    );
}

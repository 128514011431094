import * as React from "react";
import ChannelCustomizerColor from "./ChannelCustomizerColor";
import { ChannelCustomizationColors, ProperyChangeType } from "./customizationHelpers";

interface Props {
    colors: ChannelCustomizationColors;
    onCustomizationChange: (type: ProperyChangeType, key: string, value: string) => void;
}

function ChannelCustomizerColors({ colors, onCustomizationChange }: Props) {
    const { primary, secondary, tertiary } = colors;

    return (
        <>
            <ChannelCustomizerColor
                title={"Primary color"}
                description={"Big Play button, play progress on seekbar"}
                color={primary}
                onColorChange={(color: string) => onCustomizationChange("colors", "primary", color)}
            />
            <ChannelCustomizerColor
                title={"Secondary color"}
                description={"Control bar icons, time display"}
                color={secondary}
                onColorChange={(color: string) => onCustomizationChange("colors", "secondary", color)}
            />
            <ChannelCustomizerColor
                title={"Tertiary color"}
                description={"Control bar background"}
                color={tertiary}
                onColorChange={(color: string) => onCustomizationChange("colors", "tertiary", color)}
            />
        </>
    );
}

export default ChannelCustomizerColors;

import { getName } from "country-list";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { PlayoutSecuritySettings } from "./ChannelPlayoutSecurity";
import {
    UpdateGeoblockingChannelMutation,
    UpdateGeoblockingChannelMutationVariables,
} from "./__generated__/UpdateGeoblockingChannelMutation";
import {
    UpdateGeoblockingChannelAliasMutation,
    UpdateGeoblockingChannelAliasMutationVariables,
} from "./__generated__/UpdateGeoblockingChannelAliasMutation";
import GeoBlockingAddCountry from "./GeoBlockingAddCountry";
import GeoBlockingOverview, { GeoBlockingCountry } from "./GeoBlockingOverview";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import { GeoBlockingModeEnum } from "../../../__generated__/globalTypes";
import GeoBlockingModeSelector from "./GeoBlockingModeSelector";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import ContentSettingsWithSwitchCard from "./ContentSettingsWithSwitchCard";

export const UPDATE_GEOBLOCKING_CHANNEL_MUTATION = gql`
    mutation UpdateGeoblockingChannelMutation($input: UpdateChannelInput!) {
        updateChannel(input: $input) {
            channelId
        }
    }
`;

export const UPDATE_GEOBLOCKING_CHANNEL_ALIAS_MUTATION = gql`
    mutation UpdateGeoblockingChannelAliasMutation($input: UpdateChannelAliasInput!) {
        updateChannelAlias(input: $input) {
            channelId
        }
    }
`;

interface Props {
    securitySettings: PlayoutSecuritySettings;
    organizationId: string;
    disabled?: boolean;
}

function ChannelPlayoutSecurityGeoBlocking({ securitySettings, organizationId, disabled }: Props) {
    const initialConfig = securitySettings.geoBlocking;
    const [currentId, setCurrentId] = React.useState<string | undefined>(securitySettings.channelId);

    React.useEffect(() => {
        if (!currentId || currentId !== securitySettings.channelId) {
            setEnabled(initialConfig.enabled);
            setCountries(initialConfig.countries);
            setMode(initialConfig.mode);
            setCurrentId(securitySettings.channelId);
        }
    }, [initialConfig, securitySettings.channelId, currentId]);

    const [enabled, setEnabled] = React.useState<boolean>(initialConfig.enabled);
    const [mode, setMode] = React.useState<GeoBlockingModeEnum>(initialConfig.mode);
    const [countries, setCountries] = React.useState<string[]>(initialConfig.countries);
    const [needsSave, setNeedsSave] = React.useState<boolean>(false);
    const [showAtLeastOneCountryError, setShowAtLeastOneCountryError] = React.useState(false);

    const [updateGeoblockingChannel, { loading: loadingUpdateChannelMut }] = useMutation<
        UpdateGeoblockingChannelMutation,
        UpdateGeoblockingChannelMutationVariables
    >(UPDATE_GEOBLOCKING_CHANNEL_MUTATION);

    const [updateGeoblockingChannelAlias, { loading: loadingUpdateChannelAliasMut }] = useMutation<
        UpdateGeoblockingChannelAliasMutation,
        UpdateGeoblockingChannelAliasMutationVariables
    >(UPDATE_GEOBLOCKING_CHANNEL_ALIAS_MUTATION);

    function onEnabledChange() {
        setEnabled(!enabled);
        setNeedsSave(true);
    }

    function onDiscard() {
        setEnabled(initialConfig.enabled);
        setCountries(initialConfig.countries);
        setMode(initialConfig.mode);
        setNeedsSave(false);
    }

    function removeCountryFromList(code: string) {
        const newList = countries.filter((a) => a !== code);
        setCountries(newList);
        setNeedsSave(true);
    }

    function addCountryToList(code: string) {
        if (!countries.includes(code) && code !== "none") {
            const newList = countries.concat([code]);
            setCountries(newList);
            setNeedsSave(true);
            setShowAtLeastOneCountryError(false);
        }
    }

    function onChangeMode(newMode: GeoBlockingModeEnum) {
        setNeedsSave(true);
        setMode(newMode);
    }

    async function onSave() {
        setShowAtLeastOneCountryError(false);
        if (enabled && countries.length === 0) {
            setShowAtLeastOneCountryError(true);
        } else {
            try {
                if (!securitySettings.isAlias) {
                    await updateGeoblockingChannel({
                        variables: {
                            input: {
                                channelId: securitySettings.channelId,
                                organizationId,
                                publicationUpdates: {
                                    geoblockingUpdates: {
                                        countries,
                                        enabled,
                                        mode,
                                    },
                                },
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_CHANNEL_QUERY,
                                variables: {
                                    channelId: securitySettings.parentChannelId,
                                    organizationId,
                                },
                            },
                        ],
                    });
                } else {
                    await updateGeoblockingChannelAlias({
                        variables: {
                            input: {
                                channelAliasId: securitySettings.channelId,
                                organizationId,
                                channelId: securitySettings.parentChannelId,
                                publicationUpdates: {
                                    geoblockingUpdates: {
                                        countries,
                                        enabled,
                                        mode,
                                    },
                                },
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_CHANNEL_QUERY,
                                variables: {
                                    channelId: securitySettings.parentChannelId,
                                    organizationId,
                                },
                            },
                        ],
                    });
                }
                toast.success("Geoblocking config updated! 🚀", TOAST_SETTINGS);
            } catch (_ignore) {
                toast.error("Something went wrong 😞", TOAST_SETTINGS);
            } finally {
                setNeedsSave(false);
            }
        }
    }

    const countriesWithName: GeoBlockingCountry[] = countries
        .map((a) => ({
            code: a,
            name: getName(a)!,
        }))
        .sort((a, b) => (a.code > b.code ? -1 : 1));

    return (
        <>
            <ContentSettingsWithSwitchCard
                title="Geoblocking"
                subtitle="Limit which countries can access this playout"
                enabled={enabled}
                onSaveClick={onSave}
                needsSave={!disabled && needsSave}
                showSwitch
                onEnabledChange={onEnabledChange}
                onDiscardClick={onDiscard}
                isLoading={loadingUpdateChannelMut || loadingUpdateChannelAliasMut}
            >
                <HESPFormLabel label="Mode:" />
                <GeoBlockingModeSelector
                    mode={mode}
                    onChange={(newMode: GeoBlockingModeEnum) => onChangeMode(newMode)}
                />
                <HESPFormLabel label="Countries:" />
                <GeoBlockingAddCountry
                    alreadySelectedCountries={countries}
                    onAddCountry={(countryCode: string) => addCountryToList(countryCode)}
                />
                <div style={{ marginTop: "10px" }}>
                    <GeoBlockingOverview
                        countries={countriesWithName}
                        showAtLeastOneCountryError={showAtLeastOneCountryError}
                        onRemove={(code: string) => removeCountryFromList(code)}
                    />
                </div>
            </ContentSettingsWithSwitchCard>
            <ToastContainer />
        </>
    );
}

export default ChannelPlayoutSecurityGeoBlocking;

import { Button, Grid } from "@mui/material";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { Add } from "@mui/icons-material";
import { GetDatacenter_datacenter_tla_deviceContexts } from "../../views/App/__generated__/GetDatacenter";
import TLADatacenterDeviceContextsItem from "./TLADatacenterDeviceContextsItem";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";
import { useState } from "react";
import TLARemoveDeviceContextDialog from "./TLARemoveDeviceContextDialog";
import TLAUpdateDeviceContextDialog from "./TLAUpdateDeviceContextDialog";

interface Props {
    datacenterId: string;
    deviceContexts: GetDatacenter_datacenter_tla_deviceContexts[];
}

export default function TLADatacenterDeviceContexts({ deviceContexts, datacenterId }: Props): JSX.Element {
    const [selectedDeviceCtx, setSelectedDeviceCtx] = useState<GetDatacenter_datacenter_tla_deviceContexts | undefined>(
        undefined,
    );

    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    return (
        <>
            <CardHESP>
                <CardHESPTitle
                    title="Device contexts"
                    button={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => navigate(`/app/${THEO_ID}/tla/data-centers/${datacenterId}/create`)}
                        >
                            Add
                        </Button>
                    }
                />
                <Grid container spacing={2} style={{ marginTop: "1px" }}>
                    {deviceContexts.length === 0 && (
                        <Grid item xs={12}>
                            No device contexts connected
                        </Grid>
                    )}
                    {deviceContexts.map((device) => (
                        <Grid item xs={12}>
                            <TLADatacenterDeviceContextsItem
                                deviceContext={device}
                                onDeleteClick={() => {
                                    setSelectedDeviceCtx(device);
                                    setOpenRemoveDialog(true);
                                }}
                                onEditClick={() => {
                                    setSelectedDeviceCtx(device);
                                    setOpenEditDialog(true);
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </CardHESP>
            {selectedDeviceCtx && (
                <>
                    <TLARemoveDeviceContextDialog
                        deviceContextId={selectedDeviceCtx.id}
                        name={selectedDeviceCtx.name}
                        datacenterId={datacenterId}
                        open={openRemoveDialog}
                        onClose={() => setOpenRemoveDialog(false)}
                    />
                    <TLAUpdateDeviceContextDialog
                        datacenterId={datacenterId}
                        deviceContext={selectedDeviceCtx}
                        onClose={() => setOpenEditDialog(false)}
                        open={openEditDialog}
                    />
                </>
            )}
        </>
    );
}

import * as React from "react";
import styled from "styled-components";
import { AnalyzerEvent } from "./StreamAnalyzerEvents";

interface Props {
    isSelected: boolean;
    icon: React.ReactNode;
    selectedColor: string;
    title: string;
    events: AnalyzerEvent[];
    onSelect: () => void;
}

const Wrapper = styled.div.attrs((props: { isSelected: boolean; selectedColor: string }) => ({
    isSelected: props.isSelected,
    selectedColor: props.selectedColor,
}))`
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? props.selectedColor : props.theme.grayedOut)};
`;

const Title = styled.div`
    font-size: 14px;
    margin-left: 4px;
    margin-top: -6px;
    font-weight: bolder;
`;

export default function StreamAnalyzerEventTypeTab({
    isSelected,
    icon,
    selectedColor,
    onSelect,
    title,
    events,
}: Props) {
    return (
        <Wrapper onClick={onSelect} isSelected={isSelected} selectedColor={selectedColor}>
            <div>{icon}</div>
            <Title>{title}</Title>
        </Wrapper>
    );
}

import * as React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import styled from "styled-components";
import { HESPlink } from "../Landing/SignUpComplete";

const Wrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
`;

function NoAccount() {
    return (
        <>
            <SectionTitle title="No THEOlive organization found" align="center" />
            <Wrapper>
                <p style={{ marginBottom: "40px" }}>
                    There's no THEOlive organization found where you are connected to with your THEO user account.{" "}
                </p>
                <p>
                    You can request access to a THEOlive account{" "}
                    <HESPlink link={`https://www.theoplayer.com/free-trial`}>here</HESPlink>.
                </p>
            </Wrapper>
        </>
    );
}

export default NoAccount;

import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import OrganizationDetails from "../../components/OrganizationDetails/OrganizationDetails";
import { useQuery } from "react-apollo";
import {
    GetFullOrganizationDetails,
    GetFullOrganizationDetailsVariables,
} from "./__generated__/GetFullOrganizationDetails";
import CentralPageLoader from "../../components/Loading/CentralPageLoader";
import SectionTitleWithAction from "../../components/SectionTitle/SectionTitleWithAction";
import gql from "graphql-tag";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export const GET_FULL_ORGANIZATION_DETAILS = gql`
    query GetFullOrganizationDetails($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            organizationId
            name
            isIndividual
            organizationStatus
            totalNextInvoice
            nextInvoiceAt
            paymentThreshold
            createdAt
            channelSettings {
                hybridFallback {
                    enabled
                }
                perpetual {
                    enabled
                }
                drm {
                    enabled
                }
                engine {
                    override {
                        enabled
                        defaultVersion
                    }
                }
                domain {
                    override {
                        enabled
                        defaultDomain
                    }
                }
            }
            tla {
                activated
                datacenters {
                    id
                    name
                }
            }
            billingDetails {
                email
                street
                city
                state
                zip
                country
                vat
                paymentMethod {
                    brand
                    last4
                }
            }
            contractType
            hasPaidFirstContractInvoice
            paygPricing {
                transcodingCost
                viewingCost
            }
            contracts {
                start
                end
                id
                hasAccessToSD
                durationInMonths
                overageType
                mrr
                commitment
                billAutomatically
                billOveragesAutomatically
                billInGb
                gbUsedPerMinute
            }
            currentContract {
                start
                end
                id
                hasAccessToSD
                currentPeriod {
                    start
                    end
                    transcodedMinutes
                    viewedMinutes
                }
            }
        }
    }
`;

function ManageOrganization({ organizationId }: Props) {
    const { data, loading } = useQuery<GetFullOrganizationDetails, GetFullOrganizationDetailsVariables>(
        GET_FULL_ORGANIZATION_DETAILS,
        {
            variables: {
                organizationId: organizationId!,
            },
        },
    );

    if (!data || loading) {
        return <CentralPageLoader text="Getting organization data..." />;
    }

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitleWithAction title={`Details of ${data.organization.name}`} />
                <OrganizationDetails organization={data.organization} />
            </Grid>
        </Grid>
    );
}

export default ManageOrganization;

import styled from "styled-components";

interface Props {
    selected: boolean;
    text: string;
    onClick: () => void;
}

const Wrapper = styled.div.attrs((props: { selected?: boolean }) => ({
    selected: props.selected === true,
}))`
    border: ${(props) =>
        props.selected === true ? `1px solid ${props.theme.primary}` : `1px solid ${props.theme.grayedOut}`};
    background-color: ${(props) => (props.selected === true ? props.theme.primaryLight : "white")};
    padding: 7px 20px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
`;

export default function SchedulerPhaseItem({ onClick, selected, text }: Props) {
    return (
        <Wrapper onClick={onClick} selected={selected}>
            {text}
        </Wrapper>
    );
}

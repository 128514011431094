import { Button, Grid } from "@mui/material";
import { getName } from "country-list";
import * as React from "react";
import { CSVLink } from "react-csv";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_regions } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import ViewerBrowserTableLoad from "./ViewerBrowserTableLoad";
import ViewerRegionsTable from "./ViewerRegionsTable";

interface Props {
    data?: GetChannelViewerInsightsQuery_channel_viewerInsights_regions[];
}

function ViewerWorldMap({ data }: Props) {
    const [initLoad, setInitLoad] = React.useState<boolean>(true);
    const [myData, setMyData] = React.useState<
        GetChannelViewerInsightsQuery_channel_viewerInsights_regions[] | undefined
    >(undefined);

    React.useEffect(() => {
        if (data) {
            if (!myData) {
                setInitLoad(false);
            }
            setMyData(data);
        }
    }, [initLoad, data, setMyData, myData]);

    const csvHeaders = ["city", "region", "country", "amount"];
    const csvData = myData
        ? myData.map((item) => {
              return {
                  city: item.city,
                  region: item.region,
                  country: getName(item.country),
                  amount: item.amount,
              };
          })
        : [];

    return (
        <CardHESP>
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <CardHESPTitle
                        title="Viewer locations"
                        subtitle="Total unique sessions since start period"
                        button={
                            myData && myData.length > 0 ? (
                                <CSVLink
                                    data={csvData}
                                    headers={csvHeaders}
                                    filename={"ViewerLocations.csv"}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button variant="contained" style={{ color: "black" }} color="inherit">
                                        Export as CSV
                                    </Button>
                                </CSVLink>
                            ) : (
                                <></>
                            )
                        }
                    />
                </Grid>
            </Grid>

            {initLoad ? <ViewerBrowserTableLoad /> : <ViewerRegionsTable data={myData!} />}
        </CardHESP>
    );
}

export default ViewerWorldMap;

import * as React from "react";

import { Edit, Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import CardHESP from "../CardHESP/CardHESP";
import PersonalInfoData from "./PersonalInfoData";
import PersonalInfoEdit, { ProfileUpdateFormData } from "./PersonalInfoEdit";
import ChangePasswordDialog from "./ChangePasswordDialog";
import gql from "graphql-tag";
import {
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables,
} from "./__generated__/UpdateUserProfileMutation";
import { useMutation } from "react-apollo";
import AuthService from "../../auth/AuthService";
import Loading from "../Loading/Loading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const UPDATE_USERPROFILE_MUTATION = gql`
    mutation UpdateUserProfileMutation($input: UpdateUserProfileInput!) {
        updateUserProfile(input: $input) {
            firstName
            lastName
            phone
        }
    }
`;
function PersonalInfo() {
    const [editState, setEditState] = React.useState<boolean>(false);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = React.useState<boolean>(false);

    const { handleSubmit, register, errors } = useForm<ProfileUpdateFormData>();

    const [updateUserProfileMut, { loading }] = useMutation<
        UpdateUserProfileMutation,
        UpdateUserProfileMutationVariables
    >(UPDATE_USERPROFILE_MUTATION);

    const updateProfileData = handleSubmit(async ({ firstName, lastName, phone }: ProfileUpdateFormData) => {
        try {
            await updateUserProfileMut({
                variables: {
                    input: {
                        firstName,
                        lastName,
                        phone,
                    },
                },
            });
            AuthService.updateLocalUserProfile({ firstName, lastName, phone });
            toast.success("Profile updated", TOAST_SETTINGS);

            setEditState(false);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    });

    return (
        <>
            <form onSubmit={handleSubmit((data1, e) => updateProfileData(e))}>
                <SectionTitleWithAction
                    title="Personal info"
                    action={
                        <React.Fragment>
                            {loading && <Loading />}
                            {!loading && editState && (
                                <Button variant="contained" color="primary" startIcon={<Save />} type="submit">
                                    Save
                                </Button>
                            )}
                            {!loading && !editState && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Edit />}
                                    onClick={() => setEditState(true)}
                                >
                                    Edit
                                </Button>
                            )}
                        </React.Fragment>
                    }
                />
                <CardHESP>
                    {!editState ? (
                        <PersonalInfoData onChangePassword={() => setShowChangePasswordDialog(true)} />
                    ) : (
                        <PersonalInfoEdit errors={errors} register={register} onCancel={() => setEditState(false)} />
                    )}
                </CardHESP>
            </form>
            <ChangePasswordDialog open={showChangePasswordDialog} onClose={() => setShowChangePasswordDialog(false)} />
        </>
    );
}

export default PersonalInfo;

import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import SectionTitle from "../SectionTitle/SectionTitle";
import UsageGraph from "./UsageGraph";
import CardHESP from "../CardHESP/CardHESP";
import { UsageType } from "../../__generated__/globalTypes";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import UsageSettings from "./UsageSettings";

interface Props {
    organizationId: string;
}

const UsageSection = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 320px;
`;

const SettingsWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

function UsageOverview({ organizationId }: Props) {
    const [startDate, setStartDate] = React.useState(moment().subtract(1, "month").startOf("day"));
    const [endDate, setEndDate] = React.useState(moment().endOf("day"));

    function onDatesChanged(start: moment.Moment, end: moment.Moment) {
        setStartDate(moment(start).startOf("day"));
        setEndDate(moment(end).endOf("day"));
    }

    const height = 450;

    return (
        <>
            <SectionTitle title="Usage overview channels" align="left" showBottomBorder />
            <CardHESP>
                <CardHESPTitle title="Settings" />
                <SettingsWrapper>
                    <UsageSettings
                        startDate={startDate}
                        endDate={endDate}
                        onSetStart={(newStart: moment.Moment) => onDatesChanged(newStart, endDate)}
                        onSetEnd={(newEnd: moment.Moment) => onDatesChanged(startDate, newEnd)}
                    />
                </SettingsWrapper>
            </CardHESP>
            <UsageSection>
                <CardHESP style={{ height: `${height}px` }}>
                    <UsageGraph
                        type={UsageType.transcoded}
                        organizationId={organizationId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </CardHESP>
            </UsageSection>
            <UsageSection>
                <CardHESP style={{ height: `${height}px` }}>
                    <UsageGraph
                        type={UsageType.viewed}
                        organizationId={organizationId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </CardHESP>
            </UsageSection>
        </>
    );
}

export default UsageOverview;

import { Grid } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { DATE_FORMAT } from "../UsageSummary/UsageSummary";
import {
    GetFullBillingDetails,
    GetFullBillingDetailsVariables,
    GetFullBillingDetails_organization,
} from "./__generated__/GetFullBillingDetails";
import DataItem from "../DataItem/DataItem";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { useQuery } from "react-apollo";
import CentralLoader from "../Loading/CentralLoader";
import { OverageType } from "../../__generated__/globalTypes";
import { getOverageValue } from "../Contracts/ContractCommitment";
import { GET_FULL_BILLING_DETAILS } from "./BillingDetailsCardContent";

interface Props {
    organization: GetFullBillingDetails_organization;
}

function CurrentBillingCommitment({ organization }: Props) {
    const { contracts, organizationId } = organization;
    const contract =
        contracts.filter((contract) => moment(contract.start) < moment() && moment(contract.end) > moment())[0] ??
        contracts[0];

    const { mrr, commitment, overageType, start: contractStart, end: contractEnd } = contract;

    const { data, loading } = useQuery<GetFullBillingDetails, GetFullBillingDetailsVariables>(
        GET_FULL_BILLING_DETAILS,
        {
            variables: {
                organizationId,
            },
        },
    );

    if (!data || loading) {
        return <CentralLoader />;
    }

    if (!data.organization.currentContract) {
        return <div>No current contract running</div>;
    }

    const {
        viewedMinutes,
        transcodedMinutes,
        start: periodStart,
        end: periodEnd,
    } = data.organization.currentContract!.currentPeriod!;

    return (
        <Grid container>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CardHESPTitle title="Contract Details" />
                            </Grid>
                            <Grid item xs={12}>
                                From <strong>{moment(contractStart).format(DATE_FORMAT)}</strong> until
                                <strong> {moment(contractEnd).format(DATE_FORMAT)}</strong>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DataItem title="Financial details" value={`$${commitment} ($${mrr} billed monthly)`} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DataItem
                                    title="Overages calculated"
                                    value={getOverageValue(
                                        overageType,
                                        overageType === OverageType.monthly ? mrr : commitment,
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CardHESPTitle title={"Usage Current Period"} />
                        </Grid>
                        <Grid item xs={12}>
                            From <strong>{moment(periodStart).format(DATE_FORMAT)}</strong> until{" "}
                            <strong>{moment(periodEnd).format(DATE_FORMAT)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <DataItem value={`${viewedMinutes} minutes`} title="Viewed minutes" />
                        </Grid>
                        <Grid item xs={6}>
                            <DataItem value={`${transcodedMinutes} minutes`} title="Transcoded minutes" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CurrentBillingCommitment;

import React from "react";
import { styled } from "@mui/material/styles";
import { Autocomplete, MenuItem, TextField } from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        padding: "0px",
        marginTop: "-7px",
    },
    "& .MuiAutocomplete-inputRoot": {
        fontSize: "14px",
        padding: "0px 10px",
        height: "40px",
        background: "white",
    },
});

interface Props {
    title: string;
    selectedItem: string | undefined;
    onSelectItem: (item: string) => void;
    items: AutoCompleteItem[];
    onEnterClick?: () => void;
    key?: string;
}

export interface AutoCompleteItem {
    id: string;
    name: string;
}

export default function AutocompleteDropdown({ title, items, selectedItem, onSelectItem, onEnterClick, key }: Props) {
    function onKeyPress(e: any) {
        if (e.key === "Enter" && onEnterClick && selectedItem) {
            onEnterClick();
        }
    }

    return (
        <StyledAutocomplete
            options={items}
            disableClearable
            getOptionLabel={(option) => (option as AutoCompleteItem).name}
            defaultValue={{ id: selectedItem, name: selectedItem }}
            onKeyPress={onKeyPress}
            clearOnEscape
            key={key}
            onChange={(_event, value) => {
                if (value !== null) {
                    onSelectItem((value as AutoCompleteItem).id);
                }
            }}
            renderOption={(props, option) => (
                <MenuItem
                    {...props}
                    style={{
                        fontSize: "14px",
                        paddingTop: "0px",
                    }}
                    selected={(option as AutoCompleteItem).id === selectedItem}
                >
                    {(option as AutoCompleteItem).name}
                </MenuItem>
            )}
            renderInput={(params) => {
                return <TextField {...params} variant="outlined" placeholder={title} fullWidth />;
            }}
        />
    );
}

import * as React from "react";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { GetInvoicesOrganization_organization_invoices } from "./__generated__/GetInvoicesOrganization";
import moment from "moment";
import { DATE_FORMAT } from "../UsageSummary/UsageSummary";
import InvoiceStatusChip from "./InvoiceStatusChip";
import { Button } from "@mui/material";
import { CloudDownload, Payment } from "@mui/icons-material";
import { InvoiceStatusType } from "../../__generated__/globalTypes";

interface Props {
    invoices: GetInvoicesOrganization_organization_invoices[];
}

function InvoicesTable({ invoices }: Props) {
    const headers: TableHeadItem[] = [
        {
            id: "number",
            label: "Invoice Number",
            align: "left",
        },
        {
            id: "date",
            label: "Date",
            align: "left",
            allowSort: true,
        },
        {
            id: "status",
            label: "Status",
            align: "left",
        },
        {
            id: "amount",
            label: "Amount",
            align: "left",
            allowSort: true,
        },
        {
            id: "actions",
            label: "",
            align: "center",
        },
    ];

    const data = invoices.map((invoice: GetInvoicesOrganization_organization_invoices) => ({
        number: invoice.invoiceNumber,
        date: moment(invoice.createdAt).format(DATE_FORMAT),
        status: <InvoiceStatusChip status={invoice.status} />,
        amount: <strong>{`$${(invoice.amount / 100).toFixed(2)}`}</strong>,
        actions: (
            <div style={{ display: "flex", flexDirection: "row" }}>
                {invoice.pdfLink ? (
                    <Button
                        size="small"
                        color="primary"
                        startIcon={<CloudDownload />}
                        // eslint-disable-next-line no-undef
                        onClick={() => window.open(invoice.pdfLink!)}
                    >
                        Get invoice
                    </Button>
                ) : (
                    <div></div>
                )}
                {invoice.payLink !== "" && invoice.status !== InvoiceStatusType.paid ? (
                    <Button
                        size="small"
                        color="secondary"
                        startIcon={<Payment />}
                        // eslint-disable-next-line no-undef
                        onClick={() => window.open(invoice.payLink!)}
                    >
                        Pay invoice
                    </Button>
                ) : (
                    <div></div>
                )}
            </div>
        ),
    }));

    return (
        <HESPTable headers={headers} data={data} enablePagination defaultSortOn="number" defaultSortDirection="desc" />
    );
}

export default InvoicesTable;

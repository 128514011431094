import { DialogActions, DialogContent, FormControl, Grid } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import React from "react";
import SwitchWithTitle from "../../SwitchWithTitle/SwitchWithTitle";
import {
    GET_CHANNELS_ORGANIZATION_QUERY,
    GET_CHANNEL_QUERY,
    UPDATE_CHANNEL_MUTATION,
} from "../Overview/channelQueriesMutations";
import { UpdateChannelMutation, UpdateChannelMutationVariables } from "../Overview/__generated__/UpdateChannelMutation";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";

interface Props {
    channel: GetChannelQuery_channel;
    open: boolean;
    onClose: () => void;
}

export default function ChannelChangePerpetualDialog({ channel, onClose, open }: Props) {
    const [perpetual, setPerpetual] = React.useState<boolean>(channel.perpetual === true);

    const [updateChannelMut, { loading }] = useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(
        UPDATE_CHANNEL_MUTATION,
    );

    async function onSave() {
        try {
            await updateChannelMut({
                variables: {
                    input: { channelId: channel.channelId, perpetual, organizationId: channel.organizationId },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,

                            organizationId: channel.organizationId,
                        },
                    },
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            onClose();
            toast.success("24/7 settings updated", TOAST_SETTINGS);
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    return (
        <DialogHESP
            title={"Change 24/7 settings"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <FormControl fullWidth>
                                    <HESPFormLabel
                                        label="24/7 channel"
                                        description="Indicate if this channel will run 24/7. Pricing for this channel will be fixed based on your contract."
                                    />
                                    <div>
                                        <SwitchWithTitle
                                            checked={perpetual}
                                            onChange={() => setPerpetual(!perpetual)}
                                            title="Channel will run 24/7"
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onActionClick={onSave}
                            onCancelClick={onClose}
                            defaultText={"Update"}
                            loadingText={"Updating..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import * as React from "react";
import moment from "moment";
import { AdminUsagAnalyticsRange } from "./AdminUsageAnalyticsSettings";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetAdminAnalyticsPerMode, GetAdminAnalyticsPerModeVariables } from "./__generated__/GetAdminAnalyticsPerMode";
import { UsageType, AnalyticsRangeType } from "../../__generated__/globalTypes";
import { SkeletonRectangle } from "../Loading/Skeletons";
import _ from "lodash";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "../Usage/CustomTooltip";
import { Grid } from "@mui/material";
import { SmallSelector } from "../AdminTopOrganizations/TopOrganizationSettings";

interface Props {
    startDate: moment.Moment;
    endDate: moment.Moment;
    usageType: UsageType;
    range: AdminUsagAnalyticsRange;
}

export const GET_ADMIN_ANALYTICS_PER_MODE = gql`
    query GetAdminAnalyticsPerMode(
        $startDate: String!
        $endDate: String!
        $usageType: UsageType!
        $range: AnalyticsRangeType!
    ) {
        getAdminAnalyticsPerMode(startDate: $startDate, endDate: $endDate, usageType: $usageType, range: $range) {
            id
            records {
                timestamp
                amount
            }
        }
    }
`;

export default function AdminUsageModesChart({ startDate, endDate, range, usageType }: Props) {
    const [showProd, setShowProd] = React.useState<boolean>(true);
    const [showPromo, setShowPromo] = React.useState<boolean>(true);
    const [showDemo, setShowDemo] = React.useState<boolean>(true);

    const { data, loading } = useQuery<GetAdminAnalyticsPerMode, GetAdminAnalyticsPerModeVariables>(
        GET_ADMIN_ANALYTICS_PER_MODE,
        {
            variables: {
                endDate: moment(endDate).utc().toISOString(),
                startDate: moment(startDate).utc().toISOString(),
                range: range === "daily" ? AnalyticsRangeType.daily : AnalyticsRangeType.monthly,
                usageType,
            },
        },
    );

    if (!data || loading) {
        return (
            <>
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
            </>
        );
    }

    const modeData = data!.getAdminAnalyticsPerMode;

    const chartContainerMargin = {
        top: 0,
        right: 0,
        left: 0,
        bottom: 10,
    };

    const recordsWithTz = modeData.map((mode) => {
        return mode.records.map((record) => {
            return {
                mode: mode.id,
                timestamp: moment(record.timestamp).format(range === "daily" ? "MM/DD" : "YYYY/MM"),
                amount: record.amount,
            };
        });
    });

    const flatted: {
        mode: string;
        timestamp: string;
        amount: number;
    }[] = _.flatMap(recordsWithTz);

    const groupedByMode = _.chain(flatted)
        .groupBy("mode")
        .map((value, key1) => ({
            mode: key1,
            records: _.chain(value)
                .groupBy("timestamp")
                .map((value, key2) => ({
                    timestamp: key2,
                    mode: key1,
                    total: value.map((v) => v.amount).reduce((a, b) => a + b, 0),
                }))
                .value(),
        }))
        .value();

    const flat2 = _.flatMap(groupedByMode.map((c) => c.records));

    const groupedByDate = _.chain(flat2)
        .groupBy("timestamp")
        .map((value, key) => ({
            timestamp: key,
            modes: value.map((v) => ({
                mode: v.mode,
                amount: v.total,
            })),
        }))
        .value();

    const sorted = _.orderBy(groupedByDate, ["time"], ["asc"]);

    const chartData = sorted.map((entry) => {
        let object: {
            [key: string]: any;
        } = {};
        object["timeLabel"] = entry.timestamp;

        entry.modes.map((mode) => (object[mode.mode] = mode.amount));

        return object;
    });

    return (
        <>
            <div style={{ height: "250px" }}>
                <ResponsiveContainer height="90%" width="100%">
                    <BarChart data={chartData} margin={chartContainerMargin} style={{ fontSize: "13px" }}>
                        <CartesianGrid vertical={false} />
                        <Tooltip content={<CustomTooltip />} />
                        <XAxis dataKey="timeLabel" name={range === "daily" ? "Day" : "Month"} />
                        <YAxis />

                        {showDemo && <Bar dataKey="demo" stackId="a" fill="#EAF1F5" stroke="#32739c" />}
                        {showPromo && <Bar dataKey="promotional" stackId="a" fill="#f4e7f3" stroke="#99118b" />}
                        {showProd && <Bar dataKey="production" stackId="a" fill="#eef4e9" stroke="#5a9926" />}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <SmallSelector
                        selected={showDemo}
                        colorSelected={"#32739c"}
                        backgroundSelected={"#EAF1F5"}
                        onClick={() => setShowDemo(!showDemo)}
                    >
                        Demo
                    </SmallSelector>
                </Grid>
                <Grid item>
                    <SmallSelector
                        selected={showPromo}
                        colorSelected={"#99118b"}
                        backgroundSelected={"#f4e7f3"}
                        onClick={() => setShowPromo(!showPromo)}
                    >
                        Promotional
                    </SmallSelector>
                </Grid>
                <Grid item>
                    <SmallSelector
                        selected={showProd}
                        colorSelected={"#5a9926"}
                        backgroundSelected={"#eef4e9"}
                        onClick={() => setShowProd(!showProd)}
                    >
                        Production
                    </SmallSelector>
                </Grid>
            </Grid>
        </>
    );
}

import { NavigateNext } from "@mui/icons-material";
import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    padding: 8px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const IconTextWrapper = styled.div`
    display: flex;
    width: 90%;
`;

const IconWrapper = styled.div`
    margin-right: 20px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
`;

const SubTitle = styled.div`
    font-size: 13px;
    margin-top: 4px;
    color: ${(props) => props.theme.gray};
`;

const IconLinkWrapper = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

interface Props {
    title: string;
    description: string;
    icon: React.ReactNode;
    onClick: () => void;
}

function ChannelAnalyticsItem({ title, description, icon, onClick }: Props) {
    return (
        <Wrapper onClick={onClick}>
            <IconTextWrapper>
                <IconWrapper>{icon}</IconWrapper>
                <TextWrapper>
                    <Title>{title}</Title>
                    <SubTitle>{description}</SubTitle>
                </TextWrapper>
            </IconTextWrapper>
            <IconLinkWrapper>
                <NavigateNext color={"primary"} style={{ fontSize: "28px" }} />
            </IconLinkWrapper>
        </Wrapper>
    );
}

export default ChannelAnalyticsItem;

import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import gql from "graphql-tag";
import * as React from "react";
import CardHESP from "../../components/CardHESP/CardHESP";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import CreateWebhookForm from "../../components/WebhooksOverview/CreateWebhookForm";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export const GET_THEOLIVE_WEBHOOK_EVENTS = gql`
    query GetTHEOliveWebhookEvents {
        webhookEvents
    }
`;

function CreateWebhook({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <SectionTitle title="Create a webhook" align="left" showBottomBorder />
                <CardHESP>
                    <CreateWebhookForm organizationId={organizationId!} />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

export default CreateWebhook;

import { DialogActions, DialogContent } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import styled from "styled-components";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import {
    TerminateSchedulerMutation,
    TerminateSchedulerMutationVariables,
} from "./__generated__/TerminateSchedulerMutation";
import { GET_SCHEDULER_QUERY } from "./ManageSchedulerForm";
import { GetSchedulersBasedOnParamsVariables } from "./__generated__/GetSchedulersBasedOnParams";
import { GET_SCHEDULERS_BASED_ON_PARAMS } from "./SchedulerSection";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";
import { GET_SCHEDULERS_PER_PHASE } from "./SchedulersAnalytics";

export const TERMINATE_SCHEDULER_MUTATION = gql`
    mutation TerminateSchedulerMutation($input: TerminateSchedulerInput!) {
        terminateScheduler(input: $input)
    }
`;

interface Props {
    scheduler: {
        id: string;
        name: string;
        organizationId: string;
    };
    open: boolean;
    onClose: () => void;
    onDone?: () => void;
    queryParamsVariables?: GetSchedulersBasedOnParamsVariables;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

export default function TerminateSchedulerDialog({ open, onClose, scheduler, queryParamsVariables, onDone }: Props) {
    const [terminateSchedulerMut, { loading }] = useMutation<
        TerminateSchedulerMutation,
        TerminateSchedulerMutationVariables
    >(TERMINATE_SCHEDULER_MUTATION);

    const { id, name, organizationId } = scheduler;

    async function terminateScheduler() {
        try {
            await terminateSchedulerMut({
                variables: {
                    input: {
                        schedulerId: id,
                        organizationId: scheduler.organizationId,
                    },
                },
                refetchQueries: [
                    ...(queryParamsVariables
                        ? [
                              {
                                  query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                  variables: { ...queryParamsVariables, phases: [SchedulerPhaseType.pending] },
                              },
                              {
                                  query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                  variables: {
                                      ...queryParamsVariables,
                                      phases: [SchedulerPhaseType.active, SchedulerPhaseType.starting],
                                  },
                              },
                              {
                                  query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                  variables: {
                                      ...queryParamsVariables,
                                      phases: [SchedulerPhaseType.terminated],
                                  },
                              },
                              {
                                  query: GET_SCHEDULERS_PER_PHASE,
                                  variables: {
                                      organizationId,
                                  },
                              },
                          ]
                        : []),
                    {
                        query: GET_SCHEDULER_QUERY,
                        variables: {
                            schedulerId: id,
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Scheduler got terminated", TOAST_SETTINGS);
            if (onDone) {
                onDone();
            }
        } catch (_ignore) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Terminate scheduler"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>
                            Are you sure you want to terminate the scheduler "{name}"? All connected channels will be
                            stopped as well.
                        </Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Terminate"}
                            loadingText={"Terminating..."}
                            onActionClick={() => terminateScheduler()}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import LandingCard, { LandingComponentToShowType } from "../components/Landing/LandingCard";
import LandingWrapper from "../components/Landing/LandingWrapper";
import KeyReasons from "../components/KeyReasons/KeyReasons";

interface Props extends RouteComponentProps {
    path?: string;
}

function Landing({ path }: Props) {
    const theme = useTheme();

    const showKeyReasonsFirst = useMediaQuery(theme.breakpoints.up("sm"));

    function getPath(): LandingComponentToShowType {
        const validPaths: LandingComponentToShowType[] = [
            "activation",
            "forgot-password",
            "forgot-password-mail-sent",
            "login",
            "google-denied",
            "access-denied",
            "registration-success",
            "signup",
            "accounts-merged",
            "no-account",
            "access-denied",
        ];

        if (path && validPaths.includes(path as LandingComponentToShowType)) {
            return path as LandingComponentToShowType;
        }
        return "login";
    }

    return (
        <LandingWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} xl={7} style={{ order: showKeyReasonsFirst ? 1 : 2 }}>
                    <KeyReasons />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={5}
                    style={{
                        order: showKeyReasonsFirst ? 2 : 1,
                    }}
                >
                    <LandingCard mode={getPath()} />
                </Grid>
            </Grid>
        </LandingWrapper>
    );
}

export default Landing;

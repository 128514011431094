import { Button, Divider, Grid } from "@mui/material";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import styled from "styled-components";
import { THEO_ID } from "../../../views/App/AppRoot";
import { GET_PROFILE } from "../../../views/App/EditProfile";
import { GetProfile_profile } from "../../../views/App/__generated__/GetProfile";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import AbrLadderConstruction from "./AbrLadderConstruction";
import ManageProfileBasicsForm from "./ManageProfileBasicsForm";
import ManageProfilePricingForm from "./ManageProfilePricingForm";
import { GET_PROFILES } from "./ProfilesCard";
import { CreateProfileMutation, CreateProfileMutationVariables } from "./__generated__/CreateProfileMutation";
import { UpdateProfileMutation, UpdateProfileMutationVariables } from "./__generated__/UpdateProfileMutation";

export const CREATE_PROFILE_MUTATION = gql`
    mutation CreateProfileMutation($input: CreateProfileInput!) {
        createProfile(input: $input) {
            id
        }
    }
`;

export const UPDATE_PROFILE_MUTATION = gql`
    mutation UpdateProfileMutation($input: UpdateProfileInput!) {
        updateProfile(input: $input) {
            id
        }
    }
`;

interface Props {
    organizationId: string;
    profile?: GetProfile_profile;
    isCreate: boolean;
}

export interface AbrEntry {
    resolution: string;
    bitRate: number;
}

export interface ProfileObject {
    maxResolution: string;
    maxBitrate: number;
    maxFps: number;
    publiclyAvailable: boolean;
    pricing: {
        transcoded: number;
        viewed: number;
    };
    abrLadder: {
        entries: AbrEntry[];
    };
}

const SectionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
`;

export default function ManageProfileForm({ organizationId, profile, isCreate }: Props) {
    if (!isCreate && !profile) {
        throw new Error("Wrong properties");
    }

    const [profileObj, setProfileObj] = React.useState<ProfileObject>(getInitialProfileObj(isCreate, profile));

    const [createProfileMut, { loading: loadingCreateMut }] = useMutation<
        CreateProfileMutation,
        CreateProfileMutationVariables
    >(CREATE_PROFILE_MUTATION);

    const [updateProfileMut, { loading: loadingUpdateMut }] = useMutation<
        UpdateProfileMutation,
        UpdateProfileMutationVariables
    >(UPDATE_PROFILE_MUTATION);

    async function onSubmitClick() {
        const { abrLadder, maxBitrate, maxFps, maxResolution, pricing, publiclyAvailable } = profileObj;

        try {
            if (isCreate) {
                await createProfileMut({
                    variables: {
                        input: {
                            abrLadder,
                            maxBitrate,
                            maxFps,
                            maxResolution,
                            organizationId,
                            publiclyAvailable,
                            transcodedPricePerMinute: pricing.transcoded,
                            viewedPricePerMinute: pricing.viewed,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_PROFILES,
                            variables: {
                                organizationId,
                            },
                        },
                    ],
                });
                navigate(`/app/${organizationId}/config/profiles`);
            } else {
                await updateProfileMut({
                    variables: {
                        input: {
                            id: profile!.id,
                            abrLadder,
                            maxBitrate,
                            maxFps,
                            maxResolution,
                            publiclyAvailable,
                            transcodedPricePerMinute: pricing.transcoded,
                            viewedPricePerMinute: pricing.viewed,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_PROFILES,
                            variables: {
                                organizationId,
                            },
                        },
                        {
                            query: GET_PROFILE,
                            variables: {
                                id: profile!.id,
                            },
                        },
                    ],
                });
                if (organizationId === THEO_ID) {
                    navigate(`/app/${organizationId}/config/profiles`);
                }
            }
            toast.success(`Profile successfully ${isCreate ? "created" : "updated"}! 🚀`, TOAST_SETTINGS);
        } catch (_e) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    function onChangeMaxResolution(newValue: string) {
        setProfileObj({
            ...profileObj,
            maxResolution: newValue,
            abrLadder: {
                entries: [
                    {
                        bitRate: profileObj.maxBitrate,
                        resolution: newValue,
                    },
                ],
            },
        });
    }

    function onChangemaxBitrate(newValue: number) {
        setProfileObj({
            ...profileObj,
            maxBitrate: newValue,
            abrLadder: {
                entries: [
                    {
                        bitRate: newValue,
                        resolution: profileObj.maxResolution,
                    },
                ],
            },
        });
    }

    function onAddEntry() {
        const entries = profileObj.abrLadder.entries.concat([
            { bitRate: profileObj.maxBitrate, resolution: profileObj.maxResolution },
        ]);

        setProfileObj({
            ...profileObj,
            abrLadder: {
                ...profileObj.abrLadder,
                entries,
            },
        });
    }

    function onChangeAbrEntryBitrate(newValue: number, index: number) {
        const entries = profileObj.abrLadder.entries;
        entries[index] = {
            bitRate: newValue,
            resolution: entries[index].resolution,
        };

        setProfileObj({
            ...profileObj,
            abrLadder: {
                ...profileObj.abrLadder,
                entries,
            },
        });
    }

    function onChangeAbrEntryResolution(newValue: string, index: number) {
        const entries = profileObj.abrLadder.entries;
        entries[index] = {
            resolution: newValue,
            bitRate: entries[index].bitRate,
        };

        setProfileObj({
            ...profileObj,
            abrLadder: {
                ...profileObj.abrLadder,
                entries,
            },
        });
    }

    function onRemoveAbrEntry(index: number) {
        const entries = profileObj.abrLadder.entries.filter((entry, i) => i !== index);
        setProfileObj({
            ...profileObj,
            abrLadder: {
                ...profileObj.abrLadder,
                entries,
            },
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionTitle>Basic information</SectionTitle>
                <ManageProfileBasicsForm
                    isCreate={isCreate}
                    profile={profileObj}
                    onChangeBitrate={(newValue: number) => onChangemaxBitrate(newValue)}
                    onChangeFps={(newValue: number) =>
                        setProfileObj({
                            ...profileObj,
                            maxFps: newValue,
                        })
                    }
                    onChangeResolution={(newValue: string) => onChangeMaxResolution(newValue)}
                    onPubliclyAvailableChange={() =>
                        setProfileObj({
                            ...profileObj,
                            publiclyAvailable: !profileObj.publiclyAvailable,
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>ABR ladder</SectionTitle>
                <AbrLadderConstruction
                    maxBitrate={profileObj.maxBitrate}
                    maxResolution={profileObj.maxResolution}
                    entries={profileObj.abrLadder.entries}
                    onAddEntry={onAddEntry}
                    onChangeBitrate={(newValue: number, index: number) => onChangeAbrEntryBitrate(newValue, index)}
                    onChangeResolution={(newValue: string, index: number) =>
                        onChangeAbrEntryResolution(newValue, index)
                    }
                    onRemoveEntry={(index: number) => onRemoveAbrEntry(index)}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Pricing</SectionTitle>
                <ManageProfilePricingForm
                    profile={profileObj}
                    onTranscodingPriceChange={(newValue: number) =>
                        setProfileObj({
                            ...profileObj,
                            pricing: {
                                ...profileObj.pricing,
                                transcoded: newValue,
                            },
                        })
                    }
                    onViewingPriceChange={(newValue: number) =>
                        setProfileObj({
                            ...profileObj,
                            pricing: {
                                ...profileObj.pricing,
                                viewed: newValue,
                            },
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button
                        onClick={onSubmitClick}
                        variant="contained"
                        size="large"
                        disabled={loadingCreateMut || loadingUpdateMut}
                    >
                        {loadingCreateMut || loadingUpdateMut
                            ? `${isCreate ? "Creating" : "Updating"} profile...`
                            : `${isCreate ? "Create" : "Update"} profile`}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

function getInitialProfileObj(isCreate: boolean, profileObj?: GetProfile_profile): ProfileObject {
    if (isCreate) {
        return {
            maxBitrate: 8,
            maxFps: 60,
            maxResolution: "1080p",
            pricing: {
                transcoded: 0.07,
                viewed: 0.0015,
            },
            publiclyAvailable: true,
            abrLadder: {
                entries: [
                    {
                        bitRate: 8,
                        resolution: "1080p",
                    },
                ],
            },
        };
    }

    const {
        abrLadder,
        maxBitrate,
        maxFps,
        maxResolution,
        publiclyAvailable,
        transcodedPricePerMinute,
        viewedPricePerMinute,
    } = profileObj!;

    return {
        maxBitrate,
        maxFps,
        maxResolution: maxResolution.replace("r_", ""),
        pricing: {
            transcoded: transcodedPricePerMinute,
            viewed: viewedPricePerMinute,
        },
        publiclyAvailable,
        abrLadder: {
            entries: abrLadder.entries.map((e) => ({
                bitRate: e.bitRate,
                resolution: e.resolution.replace("r_", ""),
            })),
        },
    };
}

import * as React from "react";
import CardHESP from "../../CardHESP/CardHESP";
import CardHESPTitle from "../../CardHESP/CardHESPTitle";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import styled from "styled-components";
import CodeSample from "../../CodeSample/CodeSample";
import { HESPlink } from "../../Landing/SignUpComplete";

interface Props {
    channel: GetChannelQuery_channel;
}

const Wrapper = styled.div`
    margin-top: 20px;
`;

export const Code = styled.code`
    color: #de5b5b;
    padding: 2px;
    background-color: #fcfcfc;
`;

const EMBED_SCRIPT_URL = process.env.REACT_APP_EMBED_SCRIPT_URL!;

function ChannelEmbedExample({ channel }: Props) {
    const embedScriptCode = `
<script src="${EMBED_SCRIPT_URL}"></script>
`;

    const embedDivCode = `
<div data-theo-live-id="${channel.channelId}" style="width: 600px; height: 400px"></div>
`;

    return (
        <CardHESP>
            <CardHESPTitle title="Include channel on your page" />
            <Wrapper>
                <p>
                    Showing your channel with your stream on a page is very straightforward! First of all, include a{" "}
                    <Code>{"<div>"}</Code> somewhere on your page, and give it a <Code>data-theo-live-id</Code>{" "}
                    attribute. Pass your channel ID as a value to this attribute, and apply some styling to determine
                    the dimensions of your player:
                </p>
                <CodeSample language="html" code={embedDivCode} />
                <p>
                    Next, include our embed script{" "}
                    <strong>
                        after the closing <Code>{"</body>"}</Code> tag
                    </strong>{" "}
                    in your HTML page:
                </p>
                <CodeSample language="html" code={embedScriptCode} />
                <p>
                    That's it, time to share it with your viewers! Looking for an example? Then check our{" "}
                    <HESPlink
                        link="https://developers.theo.live/docs/including-a-theolive-player-on-your-page"
                        openInNewTab
                    >
                        documentation
                    </HESPlink>
                    .
                </p>
            </Wrapper>
        </CardHESP>
    );
}

export default ChannelEmbedExample;

import { Grid } from "@mui/material";
import DeveloperLinkItem from "../DevelopersZone/DeveloperLinkItem";
import { navigate } from "@reach/router";
import { Engineering } from "@mui/icons-material";
import { GetOrganizationBasics_organization } from "../GetStarted/__generated__/GetOrganizationBasics";

interface Props {
    organization: GetOrganizationBasics_organization;
}

export default function SettingsContent({ organization }: Props) {
    // pass whole organization. Maintenance should only be shown when 24/7 is acitve
    return (
        <div style={{ marginTop: "8px" }}>
            <Grid container spacing={4}>
                {organization.channelSettings.perpetual.enabled === true && (
                    <Grid item xs={12} md={6} lg={4}>
                        <DeveloperLinkItem
                            title="Maintenance windows"
                            description="Define when THEOlive channel upgrades should take place"
                            onClick={() => navigate(`/app/${organization.organizationId}/settings/maintenance`)}
                            icon={<Engineering />}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

import { Button } from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import * as React from "react";
import { useForm } from "react-hook-form";
import CardHESP from "../CardHESP/CardHESP";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import BillingDetailsCardContent, { GET_FULL_BILLING_DETAILS } from "./BillingDetailsCardContent";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { ManageBillingDetails, ManageBillingDetailsVariables } from "./__generated__/ManageBillingDetails";
import Loading from "../Loading/Loading";
import { toast } from "react-toastify";
import { useQuery } from "react-apollo";
import {
    GetFullBillingDetails,
    GetFullBillingDetailsVariables,
    GetFullBillingDetails_organization,
} from "./__generated__/GetFullBillingDetails";
import Callout, { CalloutType } from "../Callout/Callout";
import { TOAST_SETTINGS } from "./AddPaymentMethodDialog";
import BillingPricing from "./BillingPricing";

export const MANAGE_BILLING_DETAILS_MUTATION = gql`
    mutation ManageBillingDetails($input: ManageBillingDetailsInput!) {
        manageBillingDetails(input: $input) {
            organizationId
        }
    }
`;

interface Props {
    organizationWithBillingDetails: GetFullBillingDetails_organization;
}

export interface ManageBillingDetailsFormData {
    email: string;
    street: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    organizationName?: string;
    vat?: string;
}

// eslint-disable-next-line no-unused-vars
function BillingDetails({ organizationWithBillingDetails }: Props) {
    const { organizationId, isIndividual: dbIsIndividualValue, organizationStatus } = organizationWithBillingDetails;
    const [editState, setEditState] = React.useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = React.useState<string>("");
    const [isIndividual, setIsIndividual] = React.useState(dbIsIndividualValue);
    const [error, setError] = React.useState({ showError: false, title: "", description: "", type: "danger" });

    const { data, loading: loadingQuery } = useQuery<GetFullBillingDetails, GetFullBillingDetailsVariables>(
        GET_FULL_BILLING_DETAILS,
        {
            variables: {
                organizationId,
            },
        },
    );

    const [manageBillingDetailsMut, { loading }] = useMutation<ManageBillingDetails, ManageBillingDetailsVariables>(
        MANAGE_BILLING_DETAILS_MUTATION,
    );

    const { register, errors, setValue, handleSubmit } = useForm<ManageBillingDetailsFormData>();

    const manageBillingDetails = handleSubmit(
        async ({ street, organizationName, city, zip, state, vat, email }: ManageBillingDetailsFormData) => {
            setError({ showError: false, title: "", description: "", type: "danger" });
            if (editState && !loadingQuery && data) {
                try {
                    await manageBillingDetailsMut({
                        variables: {
                            input: {
                                organizationId,
                                isIndividual,
                                email,
                                name: organizationName ?? "",
                                street,
                                city,
                                zip,
                                state,
                                country:
                                    selectedCountry !== ""
                                        ? selectedCountry
                                        : data.organization.billingDetails!.country,
                                vat: vat ? vat.trim() : "",
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_FULL_BILLING_DETAILS,
                                variables: {
                                    organizationId,
                                },
                            },
                        ],
                    });
                    toast.success("Billing details updated! 🚀", TOAST_SETTINGS);
                    setEditState(false);
                } catch (e: any) {
                    handleError(e.graphQLErrors[0].message);
                }
            }
        },
    );

    function handleError(errorMessage: string) {
        if (errorMessage === "ERROR_ADDING_VAT") {
            setError({
                showError: true,
                title: "Invalid VAT",
                description: `No valid VAT number for this country`,
                type: "danger",
            });
        } else {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    function onChangeIndividual() {
        if (!isIndividual) {
            // if next value of isIndividual = true
            setValue("organizationName", "Individual");
            setValue("vat", "");
        } else {
            setValue(
                "organizationName",
                data?.organization.name.includes("Account created by") ? "" : data?.organization.name,
            );
            setValue("vat", data?.organization.billingDetails?.vat ?? "");
        }
        setIsIndividual(!isIndividual);
    }

    return (
        <form onSubmit={handleSubmit((data1, e) => manageBillingDetails(e))}>
            <>
                <SectionTitleWithAction
                    title="Billing Details"
                    action={
                        <React.Fragment>
                            {loading && <Loading />}
                            {!loading && editState && (
                                <Button variant="contained" color="primary" startIcon={<Save />} type="submit">
                                    Save
                                </Button>
                            )}
                            {!loading && !editState && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    startIcon={<Edit />}
                                    onClick={() => setEditState(true)}
                                >
                                    Edit
                                </Button>
                            )}
                        </React.Fragment>
                    }
                />
                <CardHESP>
                    {error.showError && (
                        <Callout type={error.type as CalloutType} title={error.title} description={error.description} />
                    )}
                    <BillingDetailsCardContent
                        details={data}
                        errors={errors}
                        register={register}
                        isEditing={editState}
                        isLoading={loadingQuery}
                        selectedCountry={selectedCountry}
                        onChangeCountry={(e: any) => setSelectedCountry(e.target.value)}
                        setValue={setValue}
                        onAddDetailsClick={() => setEditState(true)}
                        onCancel={() => setEditState(false)}
                        isIndividual={isIndividual}
                        onIsIndividualChange={onChangeIndividual}
                    />
                </CardHESP>
                {organizationStatus !== "production" && (
                    <BillingPricing
                        organizationId={organizationId}
                        detailsFilledIn={
                            organizationWithBillingDetails.billingDetails !== null &&
                            organizationWithBillingDetails.billingDetails.paymentMethod !== null
                        }
                    />
                )}
            </>
        </form>
    );
}

export default BillingDetails;

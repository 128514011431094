import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import SectionTitleWithAction from "../../SectionTitle/SectionTitleWithAction";
import CardHESP from "../../CardHESP/CardHESP";
import ChannelRunsContent from "./ChannelRunsContent";

interface Props extends RouteComponentProps {
    channelId?: string;
    organizationId?: string;
}

export default function ChannelRuns({ channelId, organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center" alignItems={"center"} alignContent="center">
            <Grid item xs={12}>
                <SectionTitleWithAction title={`Runs of channel ${channelId}`} />
                <CardHESP>
                    <ChannelRunsContent channelId={channelId!} organizationId={organizationId!} />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

import * as React from "react";
import { getStatusVisuals } from "./ChannelsOverview";
import { ChannelStatusType } from "../../../__generated__/globalTypes";
interface Props {
    channelStatus: ChannelStatusType;
}

function ChannelStatusCell({ channelStatus }: Props) {
    return <div>{getStatusVisuals(channelStatus)}</div>;
}

export default ChannelStatusCell;

import * as React from "react";
import { DialogActions, DialogContent, FormControl, Grid, TextField, Typography } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { useMutation } from "react-apollo";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import { GetChannelQuery_channel_aliases } from "../Overview/__generated__/GetChannelQuery";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import {
    UpdateChannelAliasMutation,
    UpdateChannelAliasMutationVariables,
} from "./__generated__/UpdateChannelAliasMutation";
import gql from "graphql-tag";
import { useForm } from "react-hook-form";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";

interface Props {
    open: boolean;
    alias: GetChannelQuery_channel_aliases;
    parentChannelId: string;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
}

export const UPDATE_CHANNEL_ALIAS_MUTATION = gql`
    mutation UpdateChannelAliasMutation($input: UpdateChannelAliasInput!) {
        updateChannelAlias(input: $input) {
            channelId
        }
    }
`;

interface FormData {
    name: string;
}

function ChannelUpdateAliasDialog({ open, onClose, alias, onSuccess, onError, parentChannelId }: Props) {
    const [updateChannelAliasMut, { loading }] = useMutation<
        UpdateChannelAliasMutation,
        UpdateChannelAliasMutationVariables
    >(UPDATE_CHANNEL_ALIAS_MUTATION);

    const { handleSubmit, register, errors } = useForm<FormData>();

    const { channelId, metadata } = alias;

    const updateChannelAlias = handleSubmit(async ({ name }: FormData) => {
        try {
            await updateChannelAliasMut({
                variables: {
                    input: {
                        organizationId: alias.organizationId,
                        channelAliasId: channelId,
                        channelId: alias.parentChannelId,
                        name,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: parentChannelId,
                            organizationId: alias.organizationId,
                        },
                    },
                ],
            });
            onSuccess();
        } catch (e: any) {
            onError();
        }
        onClose();
    });

    return (
        <DialogHESP
            title={"Update channel alias"}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit((_ignore, e) => updateChannelAlias(e))}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Name" />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={metadata.name}
                                        name="name"
                                        inputRef={register({
                                            required: "Name is required",
                                        })}
                                    />
                                    <Typography variant="subtitle2" color="error">
                                        {errors.name && errors.name.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default ChannelUpdateAliasDialog;

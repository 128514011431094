import { FormControl, Grid, MenuItem, Switch, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { ProfileObject } from "./ManageProfileForm";

interface Props {
    profile: ProfileObject;
    isCreate: boolean;
    onChangeBitrate: (newValue: number) => void;
    onChangeFps: (newValue: number) => void;
    onChangeResolution: (newValue: string) => void;
    onPubliclyAvailableChange: () => void;
}

const SwitchTitle = styled.span`
    font-size: 15px;
`;

const maxResolutions = ["1080p", "720p", "576p", "360p", "240p"];
const maxFpsVals = [60, 30];

export default function ManageProfileBasicsForm({
    profile,
    onChangeBitrate,
    onChangeFps,
    onChangeResolution,
    isCreate,
    onPubliclyAvailableChange,
}: Props) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Max bit rate (in Mbps)" />
                    <TextField
                        fullWidth
                        type="number"
                        size="small"
                        disabled={!isCreate}
                        onChange={(e) => onChangeBitrate(parseFloat(e.target.value))}
                        variant="outlined"
                        value={profile.maxBitrate}
                        InputProps={{ inputProps: { min: 0.0, max: 10.0, step: 0.1 } }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Max resolution" />
                    <TextField
                        fullWidth
                        select
                        size="small"
                        disabled={!isCreate}
                        name="resolution"
                        onChange={(e) => onChangeResolution(e.target.value)}
                        variant="outlined"
                        value={profile.maxResolution}
                    >
                        {maxResolutions.map((r, i) => (
                            <MenuItem value={r} key={`r-${i}`} style={{ fontSize: "14px" }}>
                                {r}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Max FPS" />
                    <TextField
                        select
                        fullWidth
                        disabled={!isCreate}
                        size="small"
                        name="channel"
                        onChange={(e) => onChangeFps(parseInt(e.target.value))}
                        variant="outlined"
                        value={profile.maxFps}
                    >
                        {maxFpsVals.map((f, i) => (
                            <MenuItem value={f} key={`f-${i}`} style={{ fontSize: "14px" }}>
                                {f} fps
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Availability" />
                    <div>
                        <Switch
                            color="primary"
                            checked={profile.publiclyAvailable}
                            onChange={onPubliclyAvailableChange}
                            size="small"
                        />{" "}
                        <SwitchTitle>Publicly available</SwitchTitle>
                    </div>
                </FormControl>
            </Grid>
        </Grid>
    );
}

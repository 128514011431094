import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    description: string;
    options: { key: string; value: string }[];
    selectedKey: string;
    onClick: (key: string) => void;
}

const Wrapper = styled.div`
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
    display: flex;
    padding: 10px;
    border-radius: 8px;
    flex-direction: column;
    max-width: 750px;
    height: 100%;
    padding: 10px 20px;
    width: 100%;
`;

const InfoPart = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const Subtitle = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 14px;
`;

const Options = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
`;

const Option = styled.div.attrs((props: { selected: boolean }) => ({
    selected: props.selected,
}))`
    cursor: pointer;
    border-radius: 8px;
    height: 36px;
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    border: 1px solid ${(props) => (props.selected === true ? props.theme.primary : props.theme.lightGrayBottom)};
    background-color: ${(props) => (props.selected === true ? props.theme.primaryLight : "white")};
`;

export default function ChannelSelectorConfigurator({ title, description, options, selectedKey, onClick }: Props) {
    return (
        <Wrapper>
            <InfoPart>
                <Title>{title}</Title>
                <Subtitle>{description}</Subtitle>
            </InfoPart>
            <Options>
                {options.map((option) => (
                    <Option
                        key={`option-${option.key}`}
                        selected={selectedKey === option.key}
                        onClick={() => onClick(option.key)}
                    >
                        {option.value}
                    </Option>
                ))}
            </Options>
        </Wrapper>
    );
}

import { navigate } from "@reach/router";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
// import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import AuthService from "../auth/AuthService";

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

// const onErrorLink = onError((error: any) => {}) as ApolloLink;

const authLink = setContext((_, { headers }) => {
    const token = AuthService.getAccessToken();
    const tokenIsExpired = AuthService.tokenIsExpired();

    if (token && tokenIsExpired) {
        navigate("/logout");
        return headers;
    } else {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        };
    }
}) as ApolloLink;

const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
}) as ApolloLink;

const createApolloClient = () =>
    new ApolloClient({
        // link: ApolloLink.from([authLink, onErrorLink, httpLink]),
        link: ApolloLink.from([authLink, httpLink]),
        cache: new InMemoryCache({ addTypename: false }),
    });

export default createApolloClient;

import { navigate, RouteComponentProps } from "@reach/router";
import Loading from "../components/Loading/Loading";
import * as React from "react";
import AuthService from "../auth/AuthService";
import { useMutation } from "react-apollo";
import { CREATE_ORGANIZATION_FOR_USER_MUTATION } from "../components/Landing/SignIn";
import { CreateOrganizationForUserMutation } from "../components/Landing/__generated__/CreateOrganizationForUserMutation";
import gql from "graphql-tag";
import { CheckMergeAccountsMutation } from "./__generated__/CheckMergeAccountsMutation";

export const CHECK_MERGE_ACCOUNTS_MUTATION = gql`
    mutation CheckMergeAccountsMutation {
        checkMergeAccounts
    }
`;

function Provider(props: RouteComponentProps) {
    const [createOrganizationForUserMut] = useMutation<CreateOrganizationForUserMutation>(
        CREATE_ORGANIZATION_FOR_USER_MUTATION,
    );

    const [checkMergeAccountsMut] = useMutation<CheckMergeAccountsMutation>(CHECK_MERGE_ACCOUNTS_MUTATION);

    React.useEffect(() => {
        async function createOrganization() {
            const url = props.location!.hash;
            const split = url.split("&");
            const token = split[0].replace("#access_token=", "");
            const user = await AuthService.getUserProfile(token);

            // const scope = split[1].replace("#scope", "");

            const res = {
                accessToken: token,
                idToken: token,
                scope: "openid profile email role:hesp_admin",
                expiresIn: 7200,
                tokenType: "Bearer",
            };

            AuthService.setAuth(res, user);

            if (user.organizationId && user.organizationId !== null) {
                navigate(`/app/${user.organizationId}`);
            } else {
                const mergeResult = await checkMergeAccountsMut();

                if (mergeResult.data?.checkMergeAccounts === true) {
                    navigate("accounts-merged");
                } else {
                    navigate("google-denied");
                }
            }
        }

        function checkAuth() {
            const url = props.location!.hash.replace("#", "");
            const splitted = url.split("&");
            const obj: Record<string, string> = {};
            splitted.forEach((item) => {
                obj[item.split("=")[0]] = item.split("=")[1];
            });

            if (obj["error"]) {
                navigate("access-denied");
            } else {
                createOrganization();
            }
        }
        checkAuth();
    }, [props.location, createOrganizationForUserMut, checkMergeAccountsMut]);

    return <Loading />;
}

export default Provider;

import * as React from "react";
import styled from "styled-components";

interface Props {
    color: ColorType;
    title: string;
    button: React.ReactNode;
    items: React.ReactNode[];
    icon: React.ReactNode;
}

type ColorType = "primary" | "secondary";

const OuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
`;

const Wrapper = styled.div.attrs((props: { color: ColorType }) => ({
    color: props.color,
}))`
    background-color: ${(props) => (props.color === "primary" ? "#fffbf0" : "#f5f8f9")};
    border: 1px solid ${(props) => (props.color === "primary" ? "#ffe389" : "#d7e3e9")};
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
    border-radius: 20px;
    height: 360px;
    width: 460px;
    padding: 20px 36px;
    flex-direction: column;

    @media (max-width: 480px) {
        width: 300px;
    }
`;

const Title = styled.div.attrs((props: { color: ColorType }) => ({
    color: props.color,
}))`
    text-align: center;
    color: ${(props) => (props.color === "primary" ? props.theme.primary : props.theme.secondary)};
    font-weight: 600;
    font-size: 24px;
`;

const BorderWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 15px 0px;
`;

const TitleBorder = styled.div.attrs((props: { color: ColorType }) => ({
    color: props.color,
}))`
    height: 2px;
    width: 40px;
    background-color: ${(props) => (props.color === "primary" ? props.theme.primary : props.theme.secondary)};
`;

const InfoWrapper = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const LI = styled.li`
    margin-bottom: 8px;
`;

const Icon = styled.div`
    margin-top: -50px;
    text-align: center;
    margin-bottom: 10px;
`;

export default function PricingItem({ color, title, button, items, icon }: Props) {
    return (
        <OuterWrapper>
            <Wrapper color={color}>
                <Icon>{icon}</Icon>
                <Title color={color}>{title}</Title>
                <BorderWrapper>
                    <TitleBorder color={color} />
                </BorderWrapper>
                <InfoWrapper>
                    <ul>
                        {items.map((item, i) => (
                            <LI key={`item-${i}`}>{item}</LI>
                        ))}
                    </ul>
                    <ButtonWrapper>{button}</ButtonWrapper>
                </InfoWrapper>
            </Wrapper>
        </OuterWrapper>
    );
}

import * as React from "react";
import styled from "styled-components";

export type SelectedRegistrationTabType = "signup" | "login";

interface Props {
    selectedTab: SelectedRegistrationTabType;
    // eslint-disable-next-line no-unused-vars
    onChangeTab: (tab: SelectedRegistrationTabType) => void;
}

const TabsWrapper = styled.div`
    width: 100%;
    display: flex;
`;

const Tab = styled.div.attrs((props: { checked: boolean }) => ({
    checked: props.checked,
}))`
    cursor: pointer;
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0px;
    color: ${(props) => (props.checked ? props.theme.secondary : props.theme.grayedOut)};
    border-bottom: ${(props) => (props.checked ? `2px solid` : `1px solid`)};
    border-image: ${(props) => (props.checked ? `${props.theme.secondary}` : `${props.theme.grayedOut}`)};
`;

function RegistrationTabs({ selectedTab, onChangeTab }: Props) {
    return (
        <TabsWrapper>
            <Tab
                onClick={() => onChangeTab("login")}
                style={{ borderRight: "1px solid #e3e3e3" }}
                checked={selectedTab === "login"}
            >
                Sign In
            </Tab>
            <Tab
                onClick={() => window.open("https://www.theoplayer.com/free-trial")}
                checked={selectedTab === "signup"}
            >
                Sign Up
            </Tab>
        </TabsWrapper>
    );
}

export default RegistrationTabs;

/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { UsageType } from "../../__generated__/globalTypes";
import { Grid } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import AdminUsageAnalytics from "../../components/Analytics/AdminUsageAnalytics";

interface Props extends RouteComponentProps {
    path?: string;
    type?: string;
}

export default function Analytics({ type }: Props) {
    const usageType: UsageType = type === "transcoded" ? UsageType.transcoded : UsageType.viewed;

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`Analytics ${usageType} minutes`} align={"left"} showBottomBorder />
                <AdminUsageAnalytics usageType={usageType} />
            </Grid>
        </Grid>
    );
}

import * as React from "react";
import styled from "styled-components";
import search from "../../img/search.svg";

interface Props {
    onKeyStroke: (value: string) => void;
    placeholder: string;
    size?: "small" | "medium";
}

const SearchbarWrapper = styled.div.attrs((props: { size: "small" | "medium" }) => ({
    size: props.size,
}))`
    padding: ${(props) => (props.size === "small" ? "10px 4px 4px 4px" : "10px")};
    margin-bottom: ${(props) => (props.size === "small" ? "0px" : "20px")};
    width: 100%;
    display: flex;
    border: 1px solid ${(props) => props.theme.grayedOut};
    border-radius: 4px;
    flex-direction: row;
    background: white;
`;

// @ts-ignore
const SearchTextfield = styled.input.attrs((props: { size?: "small" | "medium" }) => ({
    size: props.size ?? "medium",
    type: "text",
}))`
    margin-left: 20px;
    width: 100%;
    border: none;
    font-size: ${(props) => (props.size === "small" ? "14px" : "18px")};
    outline: none;
    color: black;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
    ::placeholder {
        color: ${(props) =>
            // @ts-ignore
            props.theme.gray};
    }
    :focus {
        border-bottom: 1px solid
            ${(props) =>
                // @ts-ignore
                props.theme.primary};
    }
`;

function SearchField({ onKeyStroke, placeholder, size }: Props) {
    return (
        <SearchbarWrapper size={size}>
            <img src={search} alt="search" height={size === "small" ? 14 : 20} style={{ marginLeft: "6px" }} />
            <SearchTextfield placeholder={placeholder} onChange={(e: any) => onKeyStroke(e.target.value)} size={size} />
        </SearchbarWrapper>
    );
}

export default SearchField;

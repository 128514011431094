import { Grid, FormControl, TextField, Typography } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SectionTitle from "../SectionTitle/SectionTitle";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import Callout, { CalloutType } from "../Callout/Callout";
import LoadingButtonPublic from "../Loading/LoadingButtonPublic";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { RequestChangePassword, RequestChangePasswordVariables } from "./__generated__/RequestChangePassword";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const REQUEST_CHANGE_PASSWORD_MUTATION = gql`
    mutation RequestChangePassword($input: RequestChangePasswordInput!) {
        requestChangePassword(input: $input)
    }
`;

const PasswordActions = styled.div`
    margin-top: 30px;
`;

export const ReturnToLogin = styled.div`
    margin-top: 50px;
    color: ${(props) => props.theme.gray};
    text-align: center;
    cursor: pointer;
`;

interface Props {
    onBackToLogin: () => void;
    onLinkSent: () => void;
}

interface FormData {
    email: string;
}

function ForgotPassword({ onBackToLogin, onLinkSent }: Props) {
    const [error, setError] = React.useState({ showError: false, title: "", description: "", type: "danger" });

    const { handleSubmit, register, errors } = useForm<FormData>();
    const [requestChangePasswordMut, { loading }] = useMutation<RequestChangePassword, RequestChangePasswordVariables>(
        REQUEST_CHANGE_PASSWORD_MUTATION,
    );

    const onSubmit = handleSubmit(async ({ email }: FormData) => {
        try {
            await requestChangePasswordMut({
                variables: {
                    input: {
                        email,
                    },
                },
            });
            onLinkSent();
        } catch (e: any) {
            setError({
                showError: true,
                type: "danger",
                title: "Error",
                description: e.description,
            });
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    });

    return (
        <form onSubmit={handleSubmit((data, e) => onSubmit(e))}>
            <SectionTitle title="Forgot password" align="center" />
            {error.showError && (
                <Callout type={error.type as CalloutType} title={error.title} description={error.description} />
            )}
            <PasswordActions>
                <p>Please fill in your email address to receive an email to reset your password.</p>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <FormControl fullWidth>
                            <HESPFormLabel label={"Work email"} />
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="email"
                                inputRef={register({
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i,
                                        message: "invalid email address",
                                    },
                                })}
                            />
                            <Typography variant="subtitle2" color="error">
                                {errors.email && errors.email.message}
                            </Typography>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <LoadingButtonPublic isLoading={loading} defaultText={"Send"} loadingText={"Sending..."} />
                    </Grid>
                </Grid>

                <ReturnToLogin onClick={onBackToLogin}>Back to Sign In</ReturnToLogin>
            </PasswordActions>
        </form>
    );
}

export default ForgotPassword;

import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { DoubleArrowRounded } from "@mui/icons-material";
import CardHESP from "../CardHESP/CardHESP";
import UsageSummaryItem from "./UsageSummaryItem";
import gql from "graphql-tag";
import { UsageType } from "../../__generated__/globalTypes";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useTheme } from "@mui/material";

export const GET_ANALYTICS_ORGANIZATION = gql`
    query GetAnalyticsOrganization(
        $organizationId: ID!
        $startDate: String!
        $endDate: String!
        $usageType: UsageType!
    ) {
        getAnalyticsOrganization(
            organizationId: $organizationId
            startDate: $startDate
            endDate: $endDate
            usageType: $usageType
        ) {
            id
            records {
                timestamp
                amount
            }
        }
    }
`;

export const GET_ANALYTICS_TOTALS_ORGANIZATION = gql`
    query GetAnalyticsTotalsOrganization(
        $organizationId: ID!
        $startDate: String!
        $endDate: String!
        $channelMode: ChannelModeType
    ) {
        getAnalyticsTotalOrganization(
            endDate: $endDate
            organizationId: $organizationId
            startDate: $startDate
            channelMode: $channelMode
        ) {
            transcodedMinutes
            viewedMinutes
        }
    }
`;

interface Props {
    organizationId: string;
}

const ItemsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 85%;
    flex-direction: column;
    justify-content: space-evenly;
`;

const SeeMoreWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const LinkItem = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    font-size: 16px;
    color: ${(props) => props.theme.secondary};
    font-weight: 600;
`;

export const DATE_FORMAT = "MMMM DD, YYYY";

function UsageSummary({ organizationId }: Props) {
    const startDate = moment().subtract(1, "month").startOf("day").utc();
    const end = moment().utc();
    const remainder = 15 - (end.minute() % 15);
    const endDate = moment().utc().subtract(remainder, "minutes"); // prevent re-render on every possible second

    const theme = useTheme();

    const startFormatted = moment(startDate).add(1, "day");

    return (
        <>
            <SectionTitle
                title={`Usage overview ${startFormatted.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`}
                showBottomBorder
                align="left"
            />
            <CardHESP
                style={{
                    height: "90%",
                }}
                background={theme.palette.secondary.light}
            >
                <ItemsWrapper>
                    <UsageSummaryItem
                        type={UsageType.transcoded}
                        organizationId={organizationId}
                        startDate={startDate.toISOString()}
                        endDate={endDate.toISOString()}
                    />
                    <UsageSummaryItem
                        type={UsageType.viewed}
                        organizationId={organizationId}
                        startDate={startDate.toISOString()}
                        endDate={endDate.toISOString()}
                    />
                </ItemsWrapper>
                <SeeMoreWrapper>
                    <LinkItem onClick={() => navigate(`/app/${organizationId}/usage`)}>
                        <DoubleArrowRounded fontSize="small" />
                        <div style={{ paddingLeft: "6px" }}>More details</div>
                    </LinkItem>
                </SeeMoreWrapper>
            </CardHESP>
        </>
    );
}

export default UsageSummary;

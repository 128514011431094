import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import EventsFilters from "../EventsOverview/EventsFilters";
import moment from "moment";
import { EventFiltersArgs } from "../EventsOverview/EventsOverview";
import { SkeletonRectangle } from "../Loading/Skeletons";
import EventsData from "../EventsOverview/EventsData";
import { GetSchedulerAuditReportQuery_scheduler } from "../../views/App/__generated__/GetSchedulerAuditReportQuery";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
    GetEventsRelatedToSchedulerQuery,
    GetEventsRelatedToSchedulerQueryVariables,
} from "./__generated__/GetEventsRelatedToSchedulerQuery";

interface Props {
    scheduler: GetSchedulerAuditReportQuery_scheduler;
}

export const GET_EVENTS_RELATED_TO_SCHEDULER = gql`
    query GetEventsRelatedToSchedulerQuery(
        $startingAfter: Int!
        $organizationId: ID!
        $startDate: String!
        $endDate: String!
        $types: [String!]
        $objectIds: [String!]!
    ) {
        schedulerEvents(
            organizationId: $organizationId
            startingAfter: $startingAfter
            types: $types
            objectIds: $objectIds
            startDate: $startDate
            endDate: $endDate
        ) {
            events {
                eventId
                objectId
                type
                timestamp
                data
            }
            hasMore
        }
    }
`;

export default function SchedulerAuditEvents({ scheduler }: Props) {
    const { organizationId } = scheduler;

    const initValues: EventFiltersArgs = {
        startDate: moment().subtract(30, "days").startOf("day").toISOString(), // scheduler start - 15 minutes
        endDate: moment().endOf("day").toISOString(), // scheduler end
        objectId: undefined,
        types: undefined,
    };

    const [filters, setFilters] = React.useState<EventFiltersArgs>(initValues);

    const { data, loading, fetchMore } = useQuery<
        GetEventsRelatedToSchedulerQuery,
        GetEventsRelatedToSchedulerQueryVariables
    >(GET_EVENTS_RELATED_TO_SCHEDULER, {
        variables: {
            organizationId,
            startingAfter: 0,
            objectIds: [scheduler.schedulerId, ...scheduler.channelIds],
            types: filters.types,
            startDate: scheduler.start,
            endDate: scheduler.end!,
        },
    });

    function onMoreClick() {
        const events = data!.schedulerEvents.events;
        fetchMore({
            variables: {
                organizationId,
                startingAfter: events.length,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                const res: GetEventsRelatedToSchedulerQuery = {
                    schedulerEvents: {
                        __typename: "EventsListResult",
                        events: [
                            ...previousQueryResult.schedulerEvents.events,
                            ...fetchMoreResult.schedulerEvents.events,
                        ],
                        hasMore: fetchMoreResult.schedulerEvents.hasMore,
                    },
                };

                return res;
            },
        });
    }

    return (
        <CardHESP>
            <EventsFilters
                filters={filters}
                typesFilter="scheduler"
                onApplyFilters={(newFilters: EventFiltersArgs) => {
                    setFilters({
                        ...filters,
                        types: newFilters.types,
                    });
                }}
                hideObjectIds
                hideDateSelectors
            />
            {loading || !data ? (
                <div>
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                    <SkeletonRectangle width="100%" />
                </div>
            ) : (
                <EventsData
                    hasMore={data.schedulerEvents.hasMore}
                    events={data.schedulerEvents.events}
                    onMoreClick={onMoreClick}
                    organizationId={organizationId}
                />
            )}
        </CardHESP>
    );
}

import { FormControl, Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import RegionSelector from "../../Regions/RegionSelector";
import { CreateChannelInput } from "./CreateChannelForm";
import { GetOrganizationChannelSettingsQuery_organization } from "../../../views/App/__generated__/GetOrganizationChannelSettingsQuery";
import AuthService from "../../../auth/AuthService";
import SwitchWithTitle from "../../SwitchWithTitle/SwitchWithTitle";
import ChannelIngestSettingsSelectorItem from "../Details/ChannelIngestSettingsSelectorItem";
import styled from "styled-components";
import DatacenterSelector from "../../Regions/DataCenterSelector";
import { IngestProtocol } from "../../../__generated__/globalTypes";

const Selections = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

interface Props {
    organization: GetOrganizationChannelSettingsQuery_organization;
    input: CreateChannelInput;
    firstSubmitDone: boolean;
    onNameChange: (val: string) => void;
    onChangePullUrl: (val: string) => void;
    onLocationChange: (val: string) => void;
    onDatacenterChange: (val: string) => void;
    onChangeIngestProtocol: (protocol: IngestProtocol) => void;
    onChangeTLA: () => void;
}

export default function CreateChannelFormBasics({
    input,
    onNameChange,
    onChangePullUrl,
    onLocationChange,
    onDatacenterChange,
    firstSubmitDone,
    organization,
    onChangeTLA,
    onChangeIngestProtocol,
}: Props) {
    const isTHEOAdmin = AuthService.isTHEOAdmin();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Name" />
                    <TextField
                        type="text"
                        fullWidth
                        placeholder="Name for your THEOlive channel"
                        variant="outlined"
                        value={input.name}
                        onChange={(e) => onNameChange(e.target.value)}
                    />
                    {firstSubmitDone && input.name.trim() === "" && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a name
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            {organization.tla?.activated === true && isTHEOAdmin && (
                <Grid item xs={12} md={7}>
                    <FormControl>
                        <HESPFormLabel label="THEOlive Anywhere channel" />
                        <SwitchWithTitle
                            checked={input.tla}
                            onChange={() => onChangeTLA()}
                            title="Channel should be deployed on THEOlive Anywhere"
                        />
                    </FormControl>
                </Grid>
            )}
            <Grid item xs={12} md={7}>
                <FormControl fullWidth>
                    <HESPFormLabel label={input.tla ? "Data center" : "Region"} />
                    {/* TODO: add Data center selector, as region selector itself queries all regions */}
                    {input.tla ? (
                        <DatacenterSelector
                            organization={organization}
                            selectDatacenterId={input.dataCenterId!}
                            onChangeSelected={(datacenterId: string) => onDatacenterChange(datacenterId)}
                        />
                    ) : (
                        <RegionSelector
                            selectedRegion={input.ingestLocation!}
                            onChangeSelected={(region: string) => onLocationChange(region)}
                        />
                    )}
                </FormControl>
            </Grid>
            {input.tla && (
                <>
                    <Grid item xs={12} md={7}>
                        <FormControl>
                            <HESPFormLabel label="Ingest Protocol" />
                            <Selections>
                                <ChannelIngestSettingsSelectorItem
                                    onClick={() => onChangeIngestProtocol(IngestProtocol.rtmp)}
                                    selected={input.ingestProtocol === IngestProtocol.rtmp}
                                    title="RTMP"
                                    tooltip=""
                                />
                                <ChannelIngestSettingsSelectorItem
                                    onClick={() => onChangeIngestProtocol(IngestProtocol.srt)}
                                    selected={input.ingestProtocol === IngestProtocol.srt}
                                    title="SRT"
                                    tooltip=""
                                />
                            </Selections>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <FormControl fullWidth>
                            <HESPFormLabel label="Pull URL" />
                            <TextField
                                type="text"
                                fullWidth
                                placeholder="Pull URL"
                                variant="outlined"
                                value={input.ingestPullUrl}
                                onChange={(e) => onChangePullUrl(e.target.value)}
                            />
                            {(!input.ingestPullUrl || input.ingestPullUrl.trim().length === 0) && input.tla === true ? (
                                <Typography variant="subtitle2" color="error">
                                    Please enter an ingest Pull URL
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </FormControl>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

import * as React from "react";
import { ContractType } from "../../__generated__/globalTypes";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import ContractPAYG from "../Contracts/ContractPAYG";
import ContractCommitment from "../Contracts/ContractCommitment";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";

interface Props {
    organization: GetFullOrganizationDetails_organization;
}

function OrganizationContract({ organization }: Props) {
    const { contractType } = organization;

    function getContractComponent() {
        switch (contractType) {
            case ContractType.payg:
                return <ContractPAYG organization={organization} />;
            case ContractType.commitment:
                return <ContractCommitment organization={organization} />;
        }
    }

    return (
        <CardHESP>
            <CardHESPTitle title="Contract information" />
            {getContractComponent()}
        </CardHESP>
    );
}

export default OrganizationContract;

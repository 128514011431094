import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import UsageOverview from "../../components/Usage/UsageOverview";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function Usage({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <UsageOverview organizationId={organizationId!} />
            </Grid>
        </Grid>
    );
}

export default Usage;

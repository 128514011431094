import * as React from "react";
import { IngestProtocol, IngestType } from "../../../__generated__/globalTypes";
import styled from "styled-components";
import ChannelIngestSettingsSelectorItem from "./ChannelIngestSettingsSelectorItem";

const Wrapper = styled.div`
    margin-top: 8px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    align-items: center;
`;

const Title = styled.div`
    font-size: 13px;
    font-weight: bolder;
    width: 80px;
`;

const Selections = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

interface Props {
    selectedValues: {
        protocol: IngestProtocol;
        type: IngestType;
    };
    onChange: (protocol: IngestProtocol, type: IngestType) => void;
    disablePush: boolean;
    disabled: boolean;
}

export default function ChannelIngestSettingsSelector({ onChange, disabled, selectedValues, disablePush }: Props) {
    return (
        <Wrapper>
            <Item>
                <Title>Protocol:</Title>
                <Selections>
                    <ChannelIngestSettingsSelectorItem
                        onClick={() => (disabled ? null : onChange(IngestProtocol.rtmp, selectedValues.type))}
                        selected={selectedValues.protocol === IngestProtocol.rtmp}
                        title="RTMP"
                        tooltip=""
                        disabled={disabled}
                    />
                    <ChannelIngestSettingsSelectorItem
                        onClick={() => (disabled ? null : onChange(IngestProtocol.srt, selectedValues.type))}
                        selected={selectedValues.protocol === IngestProtocol.srt}
                        title="SRT"
                        tooltip=""
                        disabled={disabled}
                    />
                </Selections>
            </Item>
            <Item>
                <Title>Type:</Title>
                <Selections>
                    <ChannelIngestSettingsSelectorItem
                        tooltip={
                            [IngestProtocol.srt, IngestProtocol.sdi].includes(selectedValues.protocol)
                                ? "Push method only available for RTMP"
                                : "Provided by THEOlive"
                        }
                        onClick={() =>
                            [IngestProtocol.srt, IngestProtocol.sdi].includes(selectedValues.protocol) || disabled
                                ? null
                                : onChange(selectedValues.protocol, IngestType.push)
                        }
                        selected={selectedValues.type === IngestType.push}
                        disabled={
                            disabled ||
                            disablePush ||
                            [IngestProtocol.srt, IngestProtocol.sdi].includes(selectedValues.protocol)
                        }
                        title="Push"
                    />

                    <ChannelIngestSettingsSelectorItem
                        onClick={() => (disabled ? null : onChange(selectedValues.protocol, IngestType.pull))}
                        selected={selectedValues.type === IngestType.pull}
                        title="Pull"
                        disabled={disabled}
                        tooltip="Use your own"
                    />
                </Selections>
            </Item>
        </Wrapper>
    );
}

/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TLADatacentersContent from "../../components/TLA/TLADatacentersContent";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetAllDatacenters } from "./__generated__/GetAllDatacenters";

interface Props extends RouteComponentProps {
    path?: string;
}

export const GET_DATACENTERS = gql`
    query GetAllDatacenters {
        getAllDataCenters {
            id
            name
            numberOfOrganizations
        }
    }
`;

// eslint-disable-next-line no-empty-pattern
export default function TLADatacenters({}: Props) {
    const { data } = useQuery<GetAllDatacenters>(GET_DATACENTERS);

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`THEOlive Data centers`} align={"left"} showBottomBorder />
                <TLADatacentersContent datacenters={data} />
            </Grid>
        </Grid>
    );
}

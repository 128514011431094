import * as React from "react";
import { GetSchedulersBasedOnParams_schedulersWithParams } from "./__generated__/GetSchedulersBasedOnParams";
import styled from "styled-components";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteForever, MoreHoriz, PlayArrow, Schedule, Stop } from "@mui/icons-material";
import moment from "moment";
import { navigate } from "@reach/router";

interface Props {
    item: GetSchedulersBasedOnParams_schedulersWithParams;
    onDelete: () => void;
    onTerminate: () => void;
    onStart: () => void;
}

const Wrapper = styled.div`
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 8px;
    margin: 5px 0px;
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 180px;
`;

const Title = styled.div`
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 14px;
`;

const Description = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 12px;
`;

const InfoAndChannels = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

const Channels = styled.div`
    margin: 0px 20px;
    @media (max-width: 500px) {
        margin: 8px 0px;
    }
`;

const ChannelsConnected = styled.div`
    border-radius: 12px;
    padding: 6px 12px;
    background-color: ${(props) => props.theme.lightBlueBackground};
    font-size: 13px;
`;

export default function SchedulersOverviewListItem({ item, onDelete, onStart, onTerminate }: Props) {
    const { clientId, end, name, phase, schedulerId, organizationId, start, numberOfChannelsConnected } = item;
    const isActive = phase === SchedulerPhaseType.active || phase === SchedulerPhaseType.starting;

    function getNamePart(name: string, phase: SchedulerPhaseType) {
        if (!isActive) {
            return name;
        }

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {phase === SchedulerPhaseType.active ? (
                    <Tooltip title="Channels are playing content">
                        <PlayArrow
                            style={{ color: "green", fontSize: "16px", marginRight: "6px", marginLeft: "-5px" }}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title="Channels are scheduled to be active shortly">
                        <Schedule
                            style={{ color: "#d3bade", fontSize: "16px", marginRight: "6px", marginLeft: "-5px" }}
                        />
                    </Tooltip>
                )}
                {name}
            </div>
        );
    }

    return (
        <Wrapper>
            <InfoAndChannels>
                <Info>
                    <Title>{getNamePart(name, SchedulerPhaseType.active)}</Title>
                    {clientId && <Description>ClientID: {clientId}</Description>}
                    <Description>
                        {moment(start).format("MMM DD, HH:mm")} - {end ? moment(end).format("MMM DD, HH:mm") : "..."}
                    </Description>
                </Info>
                <Channels>
                    <ChannelsConnected>
                        {numberOfChannelsConnected} channel{numberOfChannelsConnected !== 1 ? "s" : ""} connected
                    </ChannelsConnected>
                </Channels>
            </InfoAndChannels>
            <div>
                {[SchedulerPhaseType.pending, SchedulerPhaseType.starting].includes(phase) && (
                    <Tooltip title="start now">
                        <IconButton onClick={onStart} size="small">
                            <PlayArrow fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                {isActive && (
                    <Tooltip title="terminate">
                        <IconButton onClick={onTerminate} size="small">
                            <Stop fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="details">
                    <IconButton
                        onClick={() => navigate(`/app/${organizationId}/schedulers/${schedulerId}`)}
                        size="small"
                    >
                        <MoreHoriz fontSize="small" />
                    </IconButton>
                </Tooltip>
                {!isActive && (
                    <Tooltip title="delete">
                        <IconButton onClick={onDelete} size="small">
                            <DeleteForever fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Wrapper>
    );
}

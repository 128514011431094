import * as React from "react";
import { GetSchedulerAuditReportQuery_scheduler } from "../../views/App/__generated__/GetSchedulerAuditReportQuery";
import CardHESP from "../CardHESP/CardHESP";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
    GetSchedulerChannelsWithOverlapsQuery,
    GetSchedulerChannelsWithOverlapsQueryVariables,
} from "./__generated__/GetSchedulerChannelsWithOverlapsQuery";
import { SkeletonRectangle } from "../Loading/Skeletons";
import SchedulerAuditChannel from "./SchedulerAuditChannel";

interface Props {
    scheduler: GetSchedulerAuditReportQuery_scheduler;
}

export const GET_SCHEDULER_CHANNELS_WITH_OVERLAPS = gql`
    query GetSchedulerChannelsWithOverlapsQuery($schedulerId: ID!, $organizationId: ID!) {
        scheduler(schedulerId: $schedulerId, organizationId: $organizationId) {
            schedulerId
            channelsAndOverlaps {
                channel {
                    channelId
                    metadata {
                        name
                    }
                }
                overlappingSchedulers {
                    schedulerId
                    clientId
                    organizationId
                    name
                    start
                    end
                }
                runsOfChannelDuringScheduler {
                    id
                    start
                    end
                    config
                    startedByType
                    startedById
                    organizationId
                    stoppedByType
                    stoppedById
                }
            }
        }
    }
`;

export function SchedulerAuditChannels({ scheduler }: Props) {
    const { data, loading } = useQuery<
        GetSchedulerChannelsWithOverlapsQuery,
        GetSchedulerChannelsWithOverlapsQueryVariables
    >(GET_SCHEDULER_CHANNELS_WITH_OVERLAPS, {
        variables: {
            organizationId: scheduler.organizationId,
            schedulerId: scheduler.schedulerId,
        },
    });

    return (
        <CardHESP>
            {loading || !data ? (
                <div>
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                </div>
            ) : (
                <div>
                    {data.scheduler.channelsAndOverlaps.map((channel) => (
                        <>
                            <SchedulerAuditChannel
                                key={`sched-channel-${channel.channel.channelId}`}
                                channelItem={channel}
                            />
                        </>
                    ))}
                </div>
            )}
        </CardHESP>
    );
}

import { getName } from "country-list";
import * as React from "react";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_regions } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";

interface Props {
    data: GetChannelViewerInsightsQuery_channel_viewerInsights_regions[];
}

interface DataRow {
    city: string;
    region: string;
    country: string;
    amount: number;
}

function ViewerRegionsTable({ data }: Props) {
    if (data.length < 1) {
        return <p style={{ textAlign: "center" }}>No viewer data found for the selected period</p>;
    }

    const headers: TableHeadItem[] = [
        {
            id: "city",
            label: "City",
            allowSort: true,
        },
        {
            id: "region",
            label: "Region",
            allowSort: true,
        },
        {
            id: "country",
            label: "Country",
            allowSort: true,
        },
        {
            id: "amount",
            label: "Amount",
            allowSort: true,
        },
    ];

    const mappedData: DataRow[] = data.map(
        ({ city, region, country, amount }: GetChannelViewerInsightsQuery_channel_viewerInsights_regions) => ({
            city,
            region,
            country: getName(country) ?? country,
            amount,
        }),
    );

    return (
        <HESPTable
            headers={headers}
            data={mappedData}
            defaultSortOn={"amount"}
            enablePagination
            defaultSortDirection="desc"
            paginationRowsPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15]}
            size="small"
        />
    );
}

export default ViewerRegionsTable;

import numeral from "numeral";
import * as React from "react";

export const tooltipFormatter = (value: string | number | React.ReactText[]): React.ReactNode =>
    numeral(value).format("0,0.[00]");

export const labelFormatter = (value: string | number) => {
    if (value.toString().includes(":")) {
        return `Time: ${value}`;
    }
    if (value.toString().includes("/")) {
        return `Date: ${value}`;
    }
    return `Date: ${value}`;
};

export const numberFormatter = (num: number, digits = 1) => {
    const symbols = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "Qua" },
        { value: 1e18, symbol: "Qui" },
        { value: 1e21, symbol: "Sex" },
        { value: 1e24, symbol: "Sep" },
        { value: 1e27, symbol: "O" },
    ];
    const regExp = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = symbols.length - 1; i > 0; i--) {
        if (num >= symbols[i].value) {
            break;
        }
    }
    return (num / symbols[i].value).toFixed(digits).replace(regExp, "$1") + symbols[i].symbol;
};

import * as React from "react";
import gql from "graphql-tag";
import { ChannelModeType } from "../../../__generated__/globalTypes";
import { Grid, DialogContent, FormControl, TextField, MenuItem, DialogActions } from "@mui/material";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import { GET_CHANNELS_ORGANIZATION_QUERY, GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import { useMutation } from "react-apollo";
import {
    ChangeChannelModeMutation,
    ChangeChannelModeMutationVariables,
} from "./__generated__/ChangeChannelModeMutation";
import { GetPromotionalChannelsByMode_channelsByMode } from "../../AdminPromotionalChannels/__generated__/GetPromotionalChannelsByMode";
import { GET_CHANNELS_BY_MODE } from "../../AdminPromotionalChannels/AdminPromotionalChannelsTable";

export const CHANGE_CHANNEL_MODE_MUTATION = gql`
    mutation ChangeChannelModeMutation($input: ChangeChannelModeInput!) {
        changeChannelMode(input: $input) {
            channelId
            channelMode
        }
    }
`;

interface Props {
    channel: GetChannelQuery_channel | GetPromotionalChannelsByMode_channelsByMode;
    open: boolean;
    onClose: () => void;
    onSuccess: (newMode: ChannelModeType) => void;
    onError: () => void;
}

function ChangeChannelModeDialog({ channel, open, onClose, onSuccess, onError }: Props) {
    const { channelId, organizationId, channelMode } = channel;
    const [selectedMode, setSelectedMode] = React.useState<ChannelModeType>(channelMode);
    const modes: ChannelModeType[] = [ChannelModeType.demo, ChannelModeType.promotional, ChannelModeType.production];

    const [changeChannelModeMut, { loading }] = useMutation<
        ChangeChannelModeMutation,
        ChangeChannelModeMutationVariables
    >(CHANGE_CHANNEL_MODE_MUTATION);

    async function onSave() {
        try {
            await changeChannelModeMut({
                variables: {
                    input: {
                        channelId,
                        channelMode: selectedMode,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId,
                            organizationId,
                        },
                    },
                    {
                        query: GET_CHANNELS_BY_MODE,
                        variables: {
                            channelMode: ChannelModeType.promotional,
                        },
                    },
                ],
            });
            onSuccess(selectedMode);
        } catch (e: any) {
            onError();
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Change channel method"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Mode" />
                                    <TextField
                                        select
                                        name="mode"
                                        onChange={(e: any) => setSelectedMode(e.target.value as ChannelModeType)}
                                        variant="outlined"
                                        value={selectedMode}
                                    >
                                        {modes.map((mode: ChannelModeType) => (
                                            <MenuItem value={mode} key={mode}>
                                                {mode}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onSave}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default ChangeChannelModeDialog;

import * as React from "react";
import { Button, CircularProgress } from "@mui/material";

interface Props {
    onCancelClick: () => void;
    defaultText: string;
    loadingText: string;
    isLoading: boolean;
    disabled?: boolean;
    onActionClick?: () => void;
}

function DialogFooterWithLoading({
    defaultText,
    disabled,
    loadingText,
    isLoading,
    onCancelClick,
    onActionClick,
}: Props) {
    return (
        <>
            {!isLoading && (
                <Button onClick={onCancelClick} color="secondary">
                    Cancel
                </Button>
            )}
            <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                type={onActionClick ? "button" : "submit"}
                onClick={onActionClick}
                startIcon={
                    isLoading && <CircularProgress size="20px" style={{ color: "white", marginRight: "10px" }} />
                }
                style={{
                    opacity: isLoading ? 0.3 : 1,
                    cursor: isLoading ? "default" : "pointer",
                }}
            >
                {isLoading ? loadingText : defaultText}
            </Button>
        </>
    );
}

export default DialogFooterWithLoading;

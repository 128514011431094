import { DialogActions, DialogContent } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import styled from "styled-components";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { StartSchedulerMutation, StartSchedulerMutationVariables } from "./__generated__/StartSchedulerMutation";
import { GET_SCHEDULER_QUERY } from "./ManageSchedulerForm";
import { GetSchedulersBasedOnParamsVariables } from "./__generated__/GetSchedulersBasedOnParams";
import { GET_SCHEDULERS_BASED_ON_PARAMS } from "./SchedulerSection";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";
import { GET_SCHEDULERS_PER_PHASE } from "./SchedulersAnalytics";

export const START_SCHEDULER_MUTATION = gql`
    mutation StartSchedulerMutation($input: StartSchedulerInput!) {
        startScheduler(input: $input) {
            schedulerId
        }
    }
`;

interface Props {
    scheduler: {
        name: string;
        id: string;
        organizationId: string;
    };
    open: boolean;
    onClose: () => void;
    queryParamsVariables?: GetSchedulersBasedOnParamsVariables;
    onDone?: () => void;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

export default function StartSchedulerNowDialog({ open, onClose, scheduler, queryParamsVariables, onDone }: Props) {
    const [startSchedulerNowMut, { loading }] = useMutation<StartSchedulerMutation, StartSchedulerMutationVariables>(
        START_SCHEDULER_MUTATION,
    );

    const { id, name, organizationId } = scheduler;

    async function startSchedulerNow() {
        try {
            await startSchedulerNowMut({
                variables: {
                    input: {
                        schedulerId: id,
                        organizationId: scheduler.organizationId,
                    },
                },
                refetchQueries: [
                    ...(queryParamsVariables
                        ? [
                              {
                                  query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                  variables: { ...queryParamsVariables, phases: [SchedulerPhaseType.pending] },
                              },
                              {
                                  query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                  variables: {
                                      ...queryParamsVariables,
                                      phases: [SchedulerPhaseType.active, SchedulerPhaseType.starting],
                                  },
                              },
                              {
                                  query: GET_SCHEDULERS_PER_PHASE,
                                  variables: {
                                      organizationId,
                                  },
                              },
                          ]
                        : []),
                    {
                        query: GET_SCHEDULER_QUERY,
                        variables: {
                            schedulerId: id,
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Scheduler started!", TOAST_SETTINGS);
            if (onDone) {
                onDone();
            }
        } catch (_ignore) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Start scheduler now"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>
                            Are you sure you want to start the scheduler "{name}" now? All channels will immediately be
                            available for your viewers, but might not be fully started up yet.
                        </Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Start now"}
                            loadingText={"Starting..."}
                            onActionClick={() => startSchedulerNow()}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import { Button, Grid } from "@mui/material";
import * as React from "react";
import { ChannelStatusType } from "../../__generated__/globalTypes";
import { GetChannelWithAllAliasesQuery_channel } from "../Channels/Overview/__generated__/GetChannelWithAllAliasesQuery";
import { SkeletonRectangle } from "../Loading/Skeletons";
import ChannelUsageViewedChart, { calculateTotalsForViewedMinutesForChannel } from "./ChannelUsageViewedChart";
import ChannelUsageViewedSettings, { UsageItem } from "./ChannelUsageViewedSettings";
import {
    GetViewingMinutesPerAlias,
    GetViewingMinutesPerAlias_getViewedMinutesPerChannelAlias,
} from "./__generated__/GetViewingMinutesPerAlias";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { CSVLink } from "react-csv";
import moment from "moment";
import _ from "lodash";

interface Props {
    channel: GetChannelWithAllAliasesQuery_channel;
    data?: GetViewingMinutesPerAlias;
    isLoading: boolean;
}

function ChannelUsageViewed({ channel, isLoading, data }: Props) {
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [selectAll, setSelectAll] = React.useState<boolean>(true);
    const [showDetailed, setShowDetailed] = React.useState<boolean>(false);
    const [showDeleted, setShowDeleted] = React.useState<boolean>(true);
    const [showNames, setShowNames] = React.useState<boolean>(false);

    const items: UsageItem[] = channel.aliases.map((a) => ({
        id: a.channelId,
        isDeleted: a.isDeleted,
        name: a.metadata.name,
    }));

    items.push({
        id: channel.channelId,
        name: channel.metadata.name,
        isDeleted: channel.channelStatus === ChannelStatusType.deleted,
    });

    const [selectedItems, setSelectedItems] = React.useState<UsageItem[]>(items);

    function onDeselect(item: UsageItem) {
        const newSelectedItems = selectedItems.filter((i) => i.id !== item.id);
        setSelectedItems(newSelectedItems);
    }

    function onSelect(item: UsageItem) {
        const newSelectedItems = selectedItems.concat([item]);
        setSelectedItems(newSelectedItems);
    }

    function onSearchInput(input: string) {
        if (input.trim() !== "") {
            const newItemsToShow = selectedItems.filter((item) => inputSearchFilter(item, input.trim(), showNames));
            setSelectedItems(newItemsToShow);
            setSelectAll(false);
        } else {
            setSelectedItems(showDeleted ? items : items.filter((item) => !item.isDeleted));
            setSelectAll(true);
        }
        setSearchValue(input.trim());
    }

    function selectAllChange() {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(showDeleted ? items : items.filter((item) => !item.isDeleted));
        }

        setSelectAll(!selectAll);
    }

    function inputSearchFilter(item: UsageItem, input: string, searchOnName: boolean): boolean {
        if (input.trim().length === 0) {
            return true;
        }
        return searchOnName
            ? item.name.toLowerCase().startsWith(input.toLowerCase())
            : item.id.toLowerCase().startsWith(input.toLowerCase());
    }

    const csvHeaders = showDetailed ? ["date", "id", "amount"] : ["date", "amount"];
    const csvData = !data
        ? []
        : showDetailed
        ? aliasUsage(data.getViewedMinutesPerChannelAlias) // todo
        : calculateTotalsForViewedMinutesForChannel(data.getViewedMinutesPerChannelAlias.map((v) => v.records)).map(
              (r) => ({
                  date: r.timestamp,
                  amount: r.total,
              }),
          );

    function aliasUsage(getViewedMinutesPerChannelAlias: GetViewingMinutesPerAlias_getViewedMinutesPerChannelAlias[]) {
        const selectedIds = selectedItems.map((i) => i.id);

        const filteredAliases = getViewedMinutesPerChannelAlias.filter((alias) => selectedIds.includes(alias.id));
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const recordsWithTz = filteredAliases.map((alias) => {
            return alias.records.map((record) => {
                return {
                    aliasId: alias.id,
                    timestamp: moment(record.timestamp).tz(tz).format("YYYY-MM-DD"),
                    amount: record.amount,
                };
            });
        });

        const flatted: {
            aliasId: string;
            timestamp: string;
            amount: number;
        }[] = _.flatMap(recordsWithTz);

        const groupedByAlias = _.chain(flatted)
            .groupBy("aliasId")
            .map((value, key) => ({
                aliasId: key,
                records: _.chain(value)
                    .groupBy("timestamp")
                    .map((v2, k2) => ({
                        date: k2,
                        id: key,
                        amount: v2.map((r2) => r2.amount).reduce((a, b) => a + b, 0),
                    }))
                    .value(),
            }))
            .value();

        return _.flatMap(groupedByAlias.map((a) => a.records));
    }

    return (
        <>
            <CardHESPTitle
                title="Minutes viewed in period"
                subtitle="View in total or per channel alias"
                button={
                    data ? (
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={`channel-${channel.channelId}-usage-viewed.csv`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button variant="contained" style={{ color: "black" }} color="inherit">
                                Export as CSV
                            </Button>
                        </CSVLink>
                    ) : (
                        <></>
                    )
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: "8px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ChannelUsageViewedSettings
                                items={items}
                                selectedItems={selectedItems}
                                onDeselectItem={(item: UsageItem) => onDeselect(item)}
                                onSelectItem={(item: UsageItem) => onSelect(item)}
                                searchValue={searchValue}
                                onSearchValueChange={(value: string) => onSearchInput(value)}
                                selectAll={selectAll}
                                onSelectAllChange={() => selectAllChange()}
                                showDetailed={showDetailed}
                                onShowDetailedChange={() => setShowDetailed(!showDetailed)}
                                showDeleted={showDeleted}
                                onShowDeletedChange={() => setShowDeleted(!showDeleted)}
                                showNames={showNames}
                                onShowNamesChange={() => setShowNames(!showNames)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {isLoading && (
                                <div>
                                    <SkeletonRectangle width="100%" />
                                    <SkeletonRectangle width="100%" />
                                    <SkeletonRectangle width="100%" />
                                    <SkeletonRectangle width="100%" />
                                    <SkeletonRectangle width="100%" />
                                </div>
                            )}
                            {!isLoading && !data && <div>An error occurred</div>}
                            {!isLoading && data && (
                                <ChannelUsageViewedChart
                                    viewingData={data.getViewedMinutesPerChannelAlias}
                                    selectedItems={selectedItems}
                                    showTotal={!showDetailed}
                                    showName={showNames}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default ChannelUsageViewed;

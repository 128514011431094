import * as React from "react";
import { GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps } from "./__generated__/GetSchedulerChannelsWithOverlapsQuery";
import styled from "styled-components";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import SchedulerAuditOverlappingSchedulers from "./SchedulerAuditOverlappingSchedulers";
import SchedulerAuditChannelRunsDuringScheduler from "./SchedulerAuditChannelRunsDuringScheduler";

interface Props {
    channelItem: GetSchedulerChannelsWithOverlapsQuery_scheduler_channelsAndOverlaps;
}

const Wrapper = styled.div`
    border-radius: 8px;
    background-color: #fafafa;
    padding: 12px 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 14px;
`;

const Main = styled.div`
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
`;

const Link = styled.div`
    display: flex;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 3px;
`;

const ID = styled.div`
    font-size: 13px;
`;

const Overlaps = styled.span`
    color: ${(props) => props.theme.error};
    margin-right: 20px;
    font-weight: normal;
    font-size: 13px;
    padding-top: 4px;
`;

const Details = styled.div`
    margin-top: 10px;
`;

const DetailsSection = styled.div`
    border-radius: 8px;
    padding: 10px 14px;
    background-color: white;
    margin-bottom: 10px;
`;

export default function SchedulerAuditChannel({ channelItem }: Props) {
    const { channel, overlappingSchedulers, runsOfChannelDuringScheduler } = channelItem;

    const [open, setOpen] = React.useState<boolean>(false);

    const hasOverlaps = overlappingSchedulers.length > 0;

    return (
        <Wrapper>
            <Main onClick={() => setOpen(!open)}>
                <div>
                    <Name>{channel.metadata.name}</Name>
                    <ID>{channel.channelId}</ID>
                </div>
                <Link>
                    {hasOverlaps && (
                        <Overlaps>
                            {overlappingSchedulers.length} overlap{overlappingSchedulers.length !== 1 ? "s" : ""}{" "}
                            detected
                        </Overlaps>
                    )}
                    {open ? (
                        <KeyboardArrowUp color="primary" style={{ fontSize: "24px" }} />
                    ) : (
                        <KeyboardArrowDown color="primary" style={{ fontSize: "24px" }} />
                    )}
                </Link>
            </Main>
            {open && (
                <Details>
                    {hasOverlaps && (
                        <DetailsSection>
                            <SchedulerAuditOverlappingSchedulers schedulers={overlappingSchedulers} />
                        </DetailsSection>
                    )}
                    <DetailsSection>
                        <SchedulerAuditChannelRunsDuringScheduler runs={runsOfChannelDuringScheduler} />
                    </DetailsSection>
                </Details>
            )}
        </Wrapper>
    );
}

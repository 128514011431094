import * as React from "react";
import styled from "styled-components";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Table, TableBody, TableRow } from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import atomOneLight from "react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light";
import { GetRunsQuery_runs_runs } from "./__generated__/GetRunsQuery";
import _ from "lodash";
import moment from "moment";
import { HESPlink } from "../../Landing/SignUpComplete";
import { AuthCredTypeEnum } from "../../../__generated__/globalTypes";
import AuthService from "../../../auth/AuthService";
import Badge from "../../Badge/Badge";
import { Code } from "../Details/ChannelEmbedExample";

interface Props {
    run: GetRunsQuery_runs_runs;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 6px;
    padding-top: 14px;
    font-size: 13px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const Details = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
`;

const Collapse = styled.div`
    margin: 6px 0px;
    padding: 10px 4px;
    border-radius: 8px;
    background-color: rgb(250, 250, 250);
`;

const Left = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
`;

const Times = styled.div`
    width: 320px;
`;

const Config = styled.div`
    margin-right: 10px;
`;

const Right = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 140px;
    color: ${(props) => props.theme.gray};
`;

const Link = styled.div`
    margin-left: 10px;
`;

const TableCell = styled.td`
    font-size: 13px;
    padding: 5px 10px 5px 10px;
`;

export default function ChannelRunItem({ run }: Props) {
    const [showDetails, setShowDetails] = React.useState<boolean>(false);

    const isTHEOAdmin = AuthService.isTHEOAdmin();

    function getStartedBy() {
        const { startedById, startedByType, organizationId } = run;

        if (startedByType === AuthCredTypeEnum.token) {
            return (
                <HESPlink underline link={`/app/${organizationId}/tokens`}>
                    Token ({run.startedById})
                </HESPlink>
            );
        }

        if (startedByType === AuthCredTypeEnum.scheduler) {
            return (
                <HESPlink underline link={`/app/${organizationId}/schedulers/${startedById}`}>
                    Scheduler ({run.startedById})
                </HESPlink>
            );
        }

        if (startedByType === AuthCredTypeEnum.user) {
            return "User";
        }

        if (startedByType === AuthCredTypeEnum.auto) {
            return "Automatically";
        }

        if (startedByType === AuthCredTypeEnum.maintenance) {
            return "Automatically due to maintenance";
        }
    }

    function getStoppedBy() {
        const { stoppedById, stoppedByType, organizationId, end } = run;

        if (!end) {
            return "Not stopped yet";
        }

        if (stoppedByType === AuthCredTypeEnum.token) {
            return (
                <HESPlink underline link={`/app/${organizationId}/tokens`}>
                    Token ({run.stoppedById})
                </HESPlink>
            );
        }

        if (stoppedByType === AuthCredTypeEnum.scheduler) {
            return (
                <HESPlink underline link={`/app/${organizationId}/schedulers/${stoppedById}`}>
                    Scheduler ({run.stoppedById})
                </HESPlink>
            );
        }

        if (stoppedByType === AuthCredTypeEnum.user) {
            return "User";
        }

        if (stoppedByType === AuthCredTypeEnum.auto) {
            return "Automatically";
        }

        if (stoppedByType === AuthCredTypeEnum.maintenance) {
            return "Automatically due to maintenance";
        }
    }

    const time = run.end
        ? `${moment(run.start).format("YYYY-MM-DD HH:mm:ss")} ➡️ ${moment(run.end).format("YYYY-MM-DD HH:mm:ss")}`
        : `Started at ${moment(run.start).format("YYYY-MM-DD HH:mm:ss")}`;

    function getConfig() {
        const config = JSON.parse(run.config ?? "{}");

        if (!config.streamConfig || config.isCustomConfig) {
            return <Code>Custom config</Code>;
        }

        const abr = config.streamConfig?.abr === true ? "ABR" : "No ABR";
        const drm = config.streamConfig?.drm === true ? "DRM, " : "";

        const resolution = config.streamConfig?.resolution;
        const bitrate = `${config.streamConfig?.bitrate}Mbps`;
        const fps = `${config.streamConfig?.fps}fps`;

        return (
            <Code>
                {abr}, {drm}
                {resolution}, {bitrate}, {fps}
            </Code>
        );
    }

    return (
        <Wrapper>
            <Details onClick={() => setShowDetails(!showDetails)}>
                <Left>
                    <Times>{time}</Times>
                    <Config>{getConfig()}</Config>
                </Left>
                <Right>
                    {isTHEOAdmin && <Badge type="error">{run.errorsAmount}</Badge>}
                    <Link>
                        {showDetails ? (
                            <KeyboardArrowUp color="primary" style={{ fontSize: "20px" }} />
                        ) : (
                            <KeyboardArrowDown color="primary" style={{ fontSize: "20px" }} />
                        )}
                    </Link>
                </Right>
            </Details>
            {showDetails && (
                <Collapse>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <strong>Started by</strong>
                                </TableCell>
                                <TableCell>{getStartedBy()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <strong>Stopped by</strong>
                                </TableCell>
                                <TableCell>{getStoppedBy()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <strong>Ingest timeout</strong>
                                </TableCell>
                                <TableCell>
                                    {run.ingestTimeout === 0 ? "Disabled" : `After ${run.ingestTimeout} seconds`}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <strong>Config</strong>
                                </TableCell>
                                <TableCell>
                                    <SyntaxHighlighter
                                        style={{ ...atomOneLight, padding: "5px 40px 5px 20px !important" }}
                                        language={"json"}
                                    >
                                        {JSON.stringify(
                                            _.pick(JSON.parse(run.config ?? "{}"), [
                                                "ingest",
                                                "streamConfig",
                                                "isCustomConfig",
                                                "customAbrLadder",
                                            ]),
                                            null,
                                            " ",
                                        )}
                                    </SyntaxHighlighter>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Collapse>
            )}
        </Wrapper>
    );
}

import * as React from "react";
import gql from "graphql-tag";
import { Grid, DialogContent, FormControl, DialogActions, TextField, MenuItem } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import DialogHESP from "../DialogHESP/DialogHESP";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import {
    ChangeOrganizationTypeMutationVariables,
    ChangeOrganizationTypeMutation,
} from "./__generated__/ChangeOrganizationTypeMutation";
import { GET_ORGANIZATION_BASICS_QUERY } from "../GetStarted/AccountWarningMessage";

export const CHANGE_ORGANIZATION_TYPE_MUTATION = gql`
    mutation ChangeOrganizationTypeMutation($input: ChangeOrganizationTypeInput!) {
        changeOrganizationType(input: $input)
    }
`;

interface Props {
    organizationId: string;
    isIndividual: boolean;
    open: boolean;
    onClose: () => void;
}

type OrganizationType = "company" | "individual";

function ChangeOrganizationTypeDialog({ organizationId, open, onClose, isIndividual }: Props) {
    const [selectedType, setSelectedType] = React.useState<OrganizationType>(isIndividual ? "individual" : "company");

    const [changeOrganizationTypeMut, { loading }] = useMutation<
        ChangeOrganizationTypeMutation,
        ChangeOrganizationTypeMutationVariables
    >(CHANGE_ORGANIZATION_TYPE_MUTATION);

    async function onSave() {
        try {
            await changeOrganizationTypeMut({
                variables: {
                    input: {
                        organizationId,
                        isIndividual: selectedType === "individual",
                    },
                },
                refetchQueries: [
                    {
                        query: GET_ORGANIZATION_BASICS_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            toast.success(`Organization type changed to '${selectedType}'`, TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Change organization type"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Select the organization type" />
                                    <TextField
                                        select
                                        name="mode"
                                        onChange={(e: any) => setSelectedType(e.target.value)}
                                        variant="outlined"
                                        value={selectedType}
                                    >
                                        <MenuItem value={"company"} key={"company"}>
                                            Company
                                        </MenuItem>
                                        <MenuItem value={"individual"} key={"individual"}>
                                            Individual
                                        </MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onSave}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default ChangeOrganizationTypeDialog;

import { Chip, Divider, FormControl, Grid, Hidden, Switch, TextField, Typography } from "@mui/material";
import moment from "moment";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { SchedulerObject } from "./ManageSchedulerForm";
import styled from "styled-components";
import * as React from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import SwitchWithTitle from "../SwitchWithTitle/SwitchWithTitle";
import { Check, Clear } from "@mui/icons-material";
import momentTZ from "moment-timezone";
import AutocompleteDropdown from "../AutocompleteDropdown/AutocompleteDropdown";

interface Props {
    schedulerObj: SchedulerObject;
    initObj: SchedulerObject;
    onChangeStart: (newValue: string | undefined) => void;
    onChangeEnd: (newValue: string | undefined) => void;
    onChangeName: (newValue: string) => void;
    onChangeClientId: (newValue: string) => void;
    firstSubmitDone: boolean;
    isActive: boolean;
    isCreate: boolean;
    onChangeRecurring: (recurring: { enabled: boolean; days: number[]; tz?: string }) => void;
}

const BadgeWrapper = styled.div`
    margin: 7px;
    margin-right: 10px;
`;

const DateInfo = styled.div`
    background-color: ${(props) => props.theme.lightBlueBackground};
    font-size: 13px;
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;
`;

const SwitchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
`;

const SwitchTitle = styled.span`
    font-size: 15px;
    margin-left: 6px;
`;

export default function SchedulerBasicsForm({
    schedulerObj,
    initObj,
    onChangeEnd,
    onChangeStart,
    onChangeName,
    onChangeClientId,
    firstSubmitDone,
    isCreate,
    isActive,

    onChangeRecurring,
}: Props) {
    const { end, name, start, clientId, startNow, recurring } = schedulerObj;

    const shortTzName = new Date()
        .toLocaleDateString("en-US", {
            day: "2-digit",
            timeZoneName: "short",
        })
        .slice(4);

    const endDisabled = typeof end === "undefined";

    function getStartMessage() {
        if (startNow) {
            return (
                <div>
                    All channels will be started immediately. Note that starting up a channel takes time and customers
                    will see an inactive livestream for a short period.{" "}
                </div>
            );
        }
        if (moment(start) < moment().add(15, "minutes")) {
            return (
                <div>
                    Start of scheduler out of 15 minute safety zone: some channels might not be fully started up yet.
                </div>
            );
        }
        return (
            <>
                <div>
                    <strong>UTC: </strong>
                    {moment(start).utc().format("MMM DD, YYYY HH:mm")}
                </div>
                <div>
                    <strong>{shortTzName}</strong>: {moment(start).format("MMM DD, YYYY HH:mm")}
                </div>
            </>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Name" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={name}
                        placeholder="Name of scheduler"
                        onChange={(e: any) => onChangeName(e.target.value)}
                        name="name"
                    />
                    {name?.trim().length === 0 && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a name
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel
                        label="Your unique identifier (optional)"
                        description="A unique identifier that you can set and that will be included in reporting"
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={clientId}
                        placeholder="Your identifier"
                        onChange={(e: any) => onChangeClientId(e.target.value)}
                    />
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} style={{ marginTop: "6px", marginBottom: "6px" }}>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <HESPFormLabel label="Start" />
                <FormControl>
                    <SwitchWrapper>
                        <Switch
                            color="primary"
                            disabled={isActive}
                            checked={startNow}
                            onChange={() => onChangeStart(startNow ? start : undefined)}
                            size="small"
                        />{" "}
                        <SwitchTitle>Start immediately</SwitchTitle>
                    </SwitchWrapper>
                    <DateTimePicker
                        format="YYYY/MM/DD HH:mm"
                        ampm={false}
                        value={moment(start)}
                        disabled={startNow}
                        minDateTime={isActive ? undefined : moment().startOf("minute").add(2, "minutes")}
                        onChange={(newValue: moment.Moment | null) => {
                            if (newValue !== null) {
                                onChangeStart(newValue.startOf("minute").toISOString());
                            }
                        }}
                    />
                    {/* {moment().add(15, "minutes").isAfter(start) &&
                        firstSubmitDone &&
                        (isCreate || (!isCreate && initObj.start !== schedulerObj.start)) &&
                        !isActive && (
                            <Typography variant="subtitle2" color="error">
                                Start date should lay at least 20 minutes in the future
                            </Typography>
                        )} */}
                    <DateInfo>{getStartMessage()}</DateInfo>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
                <HESPFormLabel label="End" />
                <FormControl>
                    <SwitchWrapper>
                        <Switch
                            color="primary"
                            checked={endDisabled}
                            onChange={() => {
                                onChangeEnd(
                                    endDisabled
                                        ? initObj.end
                                            ? initObj.end
                                            : moment(start).add(60, "minutes").toISOString()
                                        : undefined,
                                );
                            }}
                            size="small"
                        />{" "}
                        <SwitchTitle>Don't set an end time</SwitchTitle>
                    </SwitchWrapper>
                    <DateTimePicker
                        format="YYYY/MM/DD HH:mm"
                        ampm={false}
                        disabled={endDisabled}
                        value={
                            endDisabled
                                ? initObj.end
                                    ? moment(initObj.end)
                                    : moment(start).add(60, "minutes")
                                : moment(end)
                        }
                        minDate={startNow ? moment().add(2, "minutes") : moment(start)}
                        minTime={
                            startNow
                                ? moment().add(2, "minutes")
                                : moment(start).date() === moment(end).date()
                                ? moment(start)
                                : undefined
                        }
                        disableIgnoringDatePartForTimeValidation
                        onChange={(newValue: moment.Moment | null) => {
                            if (newValue !== null) {
                                onChangeEnd(newValue.startOf("minute").toISOString());
                            }
                        }}
                    />
                    {!endDisabled &&
                        firstSubmitDone &&
                        ((startNow && moment(end) < moment().add(2, "minutes")) ||
                            (!startNow && moment(start).isAfter(end))) && (
                            <Typography variant="subtitle2" color="error">
                                End date should be later than the start date, or at least be 2 minutes in the future
                            </Typography>
                        )}
                    <DateInfo>
                        {endDisabled && (
                            <div>
                                End time disabled: scheduler and channels won't stop automatically, you have to do this
                                yourself.
                            </div>
                        )}
                        {!endDisabled && (
                            <>
                                <div>
                                    <strong>UTC: </strong>
                                    {moment(end).utc().format("MMM DD, YYYY HH:mm")}
                                </div>
                                <div>
                                    <strong>{shortTzName}</strong>: {moment(end).format("MMM DD, YYYY HH:mm")}
                                </div>
                            </>
                        )}
                    </DateInfo>
                </FormControl>
            </Grid>
            {!endDisabled && (
                <>
                    <Grid item xs={12} style={{ marginTop: "6px", marginBottom: "6px" }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <HESPFormLabel
                            label="Repeat schedule"
                            description="In case you want to start and stop this list of channels on several days per week with the same time settings as above, you can select here the days on which it should"
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SwitchWithTitle
                                    checked={recurring.enabled}
                                    onChange={() =>
                                        onChangeRecurring({
                                            ...recurring,
                                            enabled: !recurring.enabled,
                                            days: [],
                                        })
                                    }
                                    title="Enable schedule repeat"
                                />
                            </Grid>
                            {recurring.enabled && (
                                <>
                                    <Grid item xs={12} style={{ marginTop: "12px" }}>
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{ paddingLeft: "16px", paddingRight: "16px" }}
                                        >
                                            {[0, 1, 2, 3, 4, 5, 6].map((i) => (
                                                <BadgeWrapper key={`day-${i}`}>
                                                    <Chip
                                                        style={{
                                                            padding: "6px",
                                                            height: "30px",
                                                            cursor: "pointer",
                                                            backgroundColor: recurring.days.includes(i)
                                                                ? "#dde7ed"
                                                                : "",
                                                        }}
                                                        size="small"
                                                        label={
                                                            [
                                                                "Sunday",
                                                                "Monday",
                                                                "Tuesday",
                                                                "Wednesday",
                                                                "Thursday",
                                                                "Friday",
                                                                "Saturday",
                                                                "Sunday",
                                                            ][i]
                                                        }
                                                        onClick={() => {
                                                            if (recurring.days.includes(i)) {
                                                                onChangeRecurring({
                                                                    ...recurring,
                                                                    days: recurring.days.filter((day) => day !== i),
                                                                });
                                                            } else {
                                                                onChangeRecurring({
                                                                    ...recurring,
                                                                    days: recurring.days.concat([i]),
                                                                });
                                                            }
                                                        }}
                                                        icon={recurring.days.includes(i) ? <Check /> : <Clear />}
                                                    />
                                                </BadgeWrapper>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SwitchWithTitle
                                            checked={typeof recurring.tz !== "undefined"}
                                            onChange={() =>
                                                onChangeRecurring({
                                                    ...recurring,
                                                    tz:
                                                        typeof recurring.tz === "undefined"
                                                            ? Intl.DateTimeFormat().resolvedOptions().timeZone
                                                            : undefined,
                                                })
                                            }
                                            title="Use specific timezone (using UTC by default, not paying attention to daylight saving time)"
                                        />
                                    </Grid>

                                    {typeof recurring.tz !== "undefined" && (
                                        <Grid item xs={12} lg={6}>
                                            <AutocompleteDropdown
                                                items={momentTZ.tz
                                                    .names()
                                                    .map((timezone) => ({ id: timezone, name: timezone }))}
                                                selectedItem={
                                                    recurring.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                                                }
                                                title="Time zone"
                                                onSelectItem={(timezone) =>
                                                    onChangeRecurring({ ...recurring, tz: timezone })
                                                }
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <Divider style={{ marginTop: "10px" }} />
            </Grid>
        </Grid>
    );
}

import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import SearchField from "../SearchField/SearchField";
import { Button, Grid } from "@mui/material";
import OrganizationsOverview from "../OrganizationsOverview/OrganizationsOverview";
import AdminAdvancedSearchDialog from "./AdminAdvancedSearchDialog";
import { navigate } from "@reach/router";
import { ContractType } from "../../__generated__/globalTypes";
import ContractSelector from "../ContractSelector/ContractSelector";
import CreateOrganizationDialog from "./CreateOrganizationDialog";

export type SearchInputStatus = "empty" | "typing" | "results";
function AdminSearchCard() {
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [selectedContractType, setSelectedContractType] = React.useState<ContractType | "all">("all");
    const [showAdvancedSearchDialog, setShowAdvancedSearchDialog] = React.useState<boolean>(false);
    const [showCreateOrgDialog, setShowCreateOrgDialog] = React.useState<boolean>(false);

    return (
        <>
            <CardHESP style={{ height: "100%" }}>
                <CardHESPTitle
                    title="Organizations"
                    button={
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    onClick={() => navigate(`avc-royalty-reporting`)}
                                >
                                    AVC Royalty Reporting
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" size="small" onClick={() => setShowCreateOrgDialog(true)}>
                                    Create organization
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Grid container alignItems={"center"} justifyContent={"right"} direction="row" gap={2}>
                            <Grid item xs={12} sm={2}>
                                <ContractSelector
                                    selectedContractType={selectedContractType}
                                    size={"small"}
                                    onChangeSelected={setSelectedContractType}
                                />
                            </Grid>
                            <div style={{ width: "320px" }}>
                                <SearchField
                                    size={"small"}
                                    placeholder="Search on name"
                                    onKeyStroke={(value: string) => setSearchValue(value)}
                                />
                            </div>
                            <Button
                                onClick={() => setShowAdvancedSearchDialog(true)}
                                variant="contained"
                                color="inherit"
                            >
                                Advanced Search
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <OrganizationsOverview
                            searchValue={searchValue === "all" ? "All Contract Types" : searchValue}
                            filterContractTypes={selectedContractType}
                        />
                    </Grid>
                </Grid>
                <AdminAdvancedSearchDialog
                    open={showAdvancedSearchDialog}
                    onClose={() => setShowAdvancedSearchDialog(false)}
                />
                <CreateOrganizationDialog open={showCreateOrgDialog} onClose={() => setShowCreateOrgDialog(false)} />
            </CardHESP>
        </>
    );
}

export default AdminSearchCard;

import { Tooltip } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import * as React from "react";

interface Props {
    timestampISOString: string;
}

const Timezones = styled.div`
    font-size: 12px;
    color: black;
    width: 160px;
`;

const TimezoneEntry = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
`;

export default function TimestampWithTooltip({ timestampISOString }: Props) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const shortTzName = new Date()
        .toLocaleDateString("en-US", {
            day: "2-digit",
            timeZoneName: "short",
        })
        .slice(4);

    return (
        <Tooltip
            title={
                <Timezones>
                    <TimezoneEntry>
                        <strong>UTC: </strong>
                        {moment(timestampISOString).utc().format("MMM DD, YYYY HH:mm")}
                    </TimezoneEntry>
                    <TimezoneEntry>
                        <strong>{shortTzName}: </strong>
                        {moment(timestampISOString).tz(tz).format("MMM DD, YYYY HH:mm")}
                    </TimezoneEntry>
                </Timezones>
            }
            placement="top-start"
        >
            <div>{moment(timestampISOString).tz(tz).format("MMM DD, YYYY HH:mm")}</div>
        </Tooltip>
    );
}

import { DialogActions, DialogContent } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import DialogHESP from "../../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";

interface Props {
    channel: {
        channelId: string;
        name: string;
    };
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

export default function StopChannelDialog({ open, onClose, onConfirm, channel }: Props) {
    const { channelId, name } = channel;

    return (
        <DialogHESP
            title={"Stop channel"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>
                            Are you sure you want to stop the channel "{name}" ({channelId})?
                        </Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={false}
                            onCancelClick={onClose}
                            defaultText={"Stop"}
                            loadingText={"Stopping..."}
                            onActionClick={onConfirm}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import { Grid } from "@mui/material";
import CardHESP from "../CardHESP/CardHESP";
import SectionTitle from "../SectionTitle/SectionTitle";
import { GetSchedulerAuditReportQuery_scheduler } from "../../views/App/__generated__/GetSchedulerAuditReportQuery";
import DataItem from "../DataItem/DataItem";
import TimestampWithTooltip from "../TimestampWithTooltip/TimestampWithTooltip";
import moment from "moment";
import SchedulerAuditAnalytics from "./SchedulerAuditAnalytics";
import { HESPlink } from "../Landing/SignUpComplete";

interface Props {
    scheduler: GetSchedulerAuditReportQuery_scheduler;
}

export default function SchedulerAuditMainDetails({ scheduler }: Props) {
    return (
        <>
            <SectionTitle title={`Scheduler ${scheduler.name}`} align="left" showBottomBorder />
            <CardHESP>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sm={6}>
                        <DataItem title="ID" value={scheduler.schedulerId} />
                        {scheduler.clientId && <DataItem title="Client ID" value={scheduler.clientId} />}
                        <DataItem
                            title="Period"
                            value={
                                <div style={{ display: "flex" }}>
                                    <span style={{ marginRight: "4px" }}>From</span>
                                    <TimestampWithTooltip timestampISOString={moment(scheduler.start).toISOString()} />
                                    <span style={{ marginLeft: "4px", marginRight: "4px" }}>until</span>
                                    <TimestampWithTooltip timestampISOString={moment(scheduler.end!).toISOString()} />
                                </div>
                            }
                        />
                        <DataItem
                            title="Report"
                            value={
                                <HESPlink link={scheduler.report!.link!} openInNewTab>
                                    View
                                </HESPlink>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SchedulerAuditAnalytics
                            value={scheduler.usageViewedSeconds.toLocaleString()}
                            text="Seconds viewed according to usage report"
                        />
                        <SchedulerAuditAnalytics
                            value={scheduler.reportViewedSeconds.toLocaleString()}
                            text="Seconds viewed according to scheduler report"
                        />
                        <SchedulerAuditAnalytics
                            value={scheduler.hadOverlaps ? "Yes" : "No"}
                            text="Had channels which overlapped with other scheduler?"
                        />
                    </Grid>
                </Grid>
            </CardHESP>
        </>
    );
}

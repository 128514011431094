import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import SchedulerDetails from "../../components/SchedulerDetails/SchedulerDetails";

interface Props extends RouteComponentProps {
    schedulerId?: string;
    organizationId?: string;
}

export default function Scheduler({ schedulerId, organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SchedulerDetails schedulerId={schedulerId!} organizationId={organizationId!} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

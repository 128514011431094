import { DeleteForever } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { GetChannelsOrganizationQuery_channels } from "../Channels/Overview/__generated__/GetChannelsOrganizationQuery";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import styled from "styled-components";

interface Props {
    channels: GetChannelsOrganizationQuery_channels[];
    onDelete: (channelId: string) => void;
    firstSubmitDone: boolean;
    canDelete: boolean;
}

const Error = styled.div`
    color: ${(props) => props.theme.error};
    font-size: 14px;
`;

export default function SchedulerChannelsOverview({ channels, onDelete, firstSubmitDone, canDelete }: Props) {
    const headers: TableHeadItem[] = [
        {
            id: "channel",
            label: "Channel",
        },
        { id: "actions", label: "Actions", align: "right", allowSort: false },
    ];

    const records = channels.map(({ channelId, metadata }) => ({
        channel: `${metadata.name} (${channelId})`,
        actions: canDelete ? (
            <>
                <Tooltip title="delete">
                    <IconButton onClick={() => onDelete(channelId)}>
                        <DeleteForever fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        ) : (
            <></>
        ),
    }));

    return (
        <Grid container spacing={2}>
            {channels.length === 0 && firstSubmitDone && (
                <Grid item xs={12}>
                    <Error> You should add at least one channel</Error>
                </Grid>
            )}
            <Grid item xs={12}>
                <HESPTable
                    headers={headers}
                    data={records}
                    size="small"
                    defaultSortOn="channel"
                    defaultSortDirection="asc"
                />
            </Grid>
        </Grid>
    );
}

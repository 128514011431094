import * as React from "react";
import { TextField, MenuItem, styled } from "@mui/material";
import { ContractType } from "../../__generated__/globalTypes";

interface Props {
    selectedContractType: ContractType | "all";
    // eslint-disable-next-line no-unused-vars
    onChangeSelected: (contractType: ContractType | "all") => void;
    size?: "small" | "medium";
}

function ContractSelector({ selectedContractType, onChangeSelected, size }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 30px 8px 10px",
            fontSize: "14px",
            minWidth: "80px",
            width: "100%",
        },
    };

    const CssTextField = styled(TextField)(size === "small" && smallCss);

    const smallSizeText = size === "small" ? { fontSize: "14px" } : {};

    return (
        <CssTextField
            select
            name="contractType"
            onChange={(e: any) => onChangeSelected(e.target.value)}
            variant="outlined"
            value={selectedContractType}
            fullWidth
        >
            <MenuItem value={"all"} key={"all"} selected={selectedContractType === "all"} style={smallSizeText}>
                All Contract Types
            </MenuItem>
            <MenuItem
                value={ContractType.payg}
                key={ContractType.payg}
                selected={selectedContractType === ContractType.payg}
                style={smallSizeText}
            >
                Pay-as-you-go
            </MenuItem>
            <MenuItem
                value={ContractType.commitment}
                key={ContractType.commitment}
                selected={selectedContractType === ContractType.commitment}
                style={smallSizeText}
            >
                Commitment
            </MenuItem>
        </CssTextField>
    );
}

export default ContractSelector;

import { Button, DialogActions, DialogContent } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import DialogHESP from "../DialogHESP/DialogHESP";
import { GetTokensQuery_tokens } from "./__generated__/GetTokensQuery";
import gql from "graphql-tag";
import { RevokeTokenMutation, RevokeTokenMutationVariables } from "./__generated__/RevokeTokenMutation";
import { useMutation } from "react-apollo";
import { GET_TOKENS_QUERY } from "./TokensOverview";
import Loading from "../Loading/Loading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const REVOKE_TOKEN_MUTATION = gql`
    mutation RevokeTokenMutation($input: RevokeTokenInput!) {
        revokeToken(input: $input) {
            tokenKey
        }
    }
`;

interface Props {
    open: boolean;
    onClose: () => void;
    token: GetTokensQuery_tokens;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

function RevokeTokenDialog({ open, onClose, token }: Props) {
    const [revokeTokenMut, { loading }] = useMutation<RevokeTokenMutation, RevokeTokenMutationVariables>(
        REVOKE_TOKEN_MUTATION,
    );

    async function revokeToken() {
        try {
            await revokeTokenMut({
                variables: {
                    input: {
                        tokenKey: token.tokenKey,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_TOKENS_QUERY,
                        variables: {
                            organizationId: token.organizationId,
                        },
                    },
                ],
            });
            toast.success("Token got revoked succesfully", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("An error occurred when trying to revoke the token");
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Revoke token"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Title>
                            Are you sure you want to revoke the token "{token.name}" ({token.tokenKey})? This action
                            can't be undone!
                        </Title>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">
                            Cancel
                        </Button>
                        {loading ? (
                            <Loading />
                        ) : (
                            <Button variant="contained" color="primary" onClick={() => revokeToken()}>
                                Revoke
                            </Button>
                        )}
                    </DialogActions>
                </>
            }
        />
    );
}

export default RevokeTokenDialog;

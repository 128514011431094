import * as React from "react";
import styled from "styled-components";
import { Button, FormControl, Grid } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { RunTimeRange } from "../Channels/Runs/ChannelRunsContent";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import TabSelector from "../TabSelector/TabSelector";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 22px;
    margin-bottom: 16px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 8px;
`;

const Filters = styled.div`
    margin: 6px 0px;
`;

interface Props {
    onApplyFilter: (filters: RecentErrorsFilter) => void;
    filters: RecentErrorsFilter;
}

export interface RecentErrorsFilter {
    startDate: string;
    endDate: string;
    range: RunTimeRange;
}

export default function RecentErrorsFilters({ onApplyFilter, filters }: Props) {
    const [startDate, setStartDate] = React.useState<string>(filters.startDate);
    const [endDate, setEndDate] = React.useState<string>(filters.endDate);
    const [range, setRange] = React.useState<RunTimeRange>(filters.range);

    const items: { key: RunTimeRange; value: string }[] = [
        {
            key: "day",
            value: "Last 24h",
        },
        {
            key: "week",
            value: "Last week",
        },
        {
            key: "custom",
            value: "Custom",
        },
    ];

    function onChangeRange(newRange: RunTimeRange) {
        if (newRange === "day") {
            const newStartDate = moment().utc().subtract(24, "hours").startOf("hour").toISOString();
            const newEndDate = moment().utc().endOf("day").toISOString();
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onApplyFilter({
                endDate: newEndDate,
                startDate: newStartDate,
                range: newRange,
            });
        }

        if (newRange === "week") {
            const newStartDate = moment().utc().subtract(7, "days").startOf("day").toISOString();
            const newEndDate = moment().utc().endOf("day").toISOString();
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onApplyFilter({
                endDate: newEndDate,
                startDate: newStartDate,
                range: newRange,
            });
        }

        setRange(newRange);
    }

    return (
        <Wrapper>
            <Filters>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HESPFormLabel label="Time range" />
                        <TabSelector
                            items={items}
                            selectedItem={range}
                            onSelect={(selectedRange: any) => onChangeRange(selectedRange as RunTimeRange)}
                        />
                    </Grid>
                    {range === "custom" && (
                        <>
                            <Grid item xs={12} sm={6} lg={3}>
                                <HESPFormLabel label="Start" />
                                <FormControl fullWidth>
                                    <DateTimePicker
                                        format="YYYY/MM/DD HH:mm"
                                        ampm={false}
                                        value={moment(startDate)}
                                        minDateTime={moment().subtract(30, "days").utc().startOf("day")}
                                        onChange={(newValue: moment.Moment | null) => {
                                            if (newValue !== null) {
                                                setStartDate(newValue.startOf("minute").toISOString());
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <HESPFormLabel label="End" />
                                <FormControl fullWidth>
                                    <DateTimePicker
                                        format="YYYY/MM/DD HH:mm"
                                        ampm={false}
                                        value={moment(endDate)}
                                        minDate={moment(startDate)}
                                        minTime={
                                            moment(startDate).date() === moment(endDate).date()
                                                ? moment(startDate)
                                                : undefined
                                        }
                                        onChange={(newValue: moment.Moment | null) => {
                                            if (newValue !== null) {
                                                setEndDate(newValue.startOf("minute").toISOString());
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={
                                        moment(startDate) > moment(endDate) ||
                                        moment(startDate) < moment().subtract(30, "days")
                                    }
                                    onClick={() =>
                                        onApplyFilter({
                                            endDate,
                                            startDate,
                                            range,
                                        })
                                    }
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Filters>
        </Wrapper>
    );
}

import { WarningAmberOutlined } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import { OrganizationStatusType } from "../../__generated__/globalTypes";
import CardHESP from "../CardHESP/CardHESP";
import { GetOrganizationBasics, GetOrganizationBasicsVariables } from "./__generated__/GetOrganizationBasics";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 420px) {
        flex-direction: column;
    }
`;

const IconAndInfo = styled.div`
    display: flex;
    flex-direction: row;
`;

const Action = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 10px;
    align-content: center;
    height: 100%;
    width: 180px;
    @media (max-width: 420px) {
        width: 100%;
        margin-top: 20px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-content: center;
    margin-right: 18px;
    align-items: center;
    @media (max-width: 420px) {
        display: none;
    }
`;

const Icon = styled.div`
    border-radius: 8px;
    background-color: #fde4bc;
    height: 40px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    color: ${(props) => props.theme.warning};
    font-weight: 600;
`;

const Description = styled.div`
    margin-top: 4px;
    font-size: 14px;
`;

interface Props {
    organizationId: string;
}

export const GET_ORGANIZATION_BASICS_QUERY = gql`
    query GetOrganizationBasics($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            organizationId
            name
            organizationStatus
            isIndividual
            contractType
            hasUnpaidInvoices
            currentContract {
                hasAccessToSD
            }
            hasMaintenanceSet
            channelSettings {
                hybridFallback {
                    enabled
                }
                perpetual {
                    enabled
                    maintenance {
                        type
                        slots {
                            day
                            time
                        }
                        interval {
                            period
                            amount
                            time
                        }
                    }
                }
                engine {
                    override {
                        enabled
                        defaultVersion
                    }
                }
            }
        }
    }
`;

export default function AccountWarningMessage({ organizationId }: Props) {
    const { data, loading } = useQuery<GetOrganizationBasics, GetOrganizationBasicsVariables>(
        GET_ORGANIZATION_BASICS_QUERY,
        {
            variables: {
                organizationId,
            },
        },
    );

    if (loading || !data) {
        return null;
    }
    const { organizationStatus, hasUnpaidInvoices } = data.organization;

    const showCard = organizationStatus === OrganizationStatusType.demo || hasUnpaidInvoices;

    if (!showCard) {
        return null;
    }

    function getContent(): { title: string; description: React.ReactNode; buttonText: string } {
        if (organizationStatus === OrganizationStatusType.demo) {
            return {
                title: "Account in demo mode",
                description: (
                    <>
                        Your THEOlive account is currently in demo mode. You'll{" "}
                        <strong>only be able to stream for 5 minutes</strong>, afterwards your channel will shut down
                        automatically. Want to go unlimited? <strong>Activate the PAYG mode to go unlimited.</strong>
                    </>
                ),
                buttonText: "Go unlimited",
            };
        }

        return {
            title: "You have open invoices",
            description: (
                <>
                    It seems you still have some unpaid invoices. Please head over to over billing page in order to pay
                    them.{" "}
                </>
            ),
            buttonText: "To billing",
        };
    }

    // demo account, show demo mode message
    return (
        <Grid item xs={12}>
            <CardHESP>
                <Wrapper>
                    <IconAndInfo>
                        <IconWrapper>
                            <Icon>
                                <WarningAmberOutlined color="warning" />
                            </Icon>
                        </IconWrapper>
                        <InfoWrapper>
                            <Title>{getContent().title}</Title>
                            <Description>{getContent().description}</Description>
                        </InfoWrapper>
                    </IconAndInfo>
                    <Action>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => navigate(`/app/${organizationId}/billing`)}
                        >
                            {getContent().buttonText}
                        </Button>
                    </Action>
                </Wrapper>
            </CardHESP>
        </Grid>
    );
}

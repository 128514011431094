import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { getTHEOliveDescription } from "./WebhookEventsSelector";

export type THEOliveEventCategory = "channel" | "alias";

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    align-items: center;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: ${(props) => props.theme.secondary};
`;

const SelectItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const SelectItemInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectItemTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
`;

const SelectItemDescription = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.lightGray};
`;

const SelectItemCheckboxWrapper = styled.div``;

interface Props {
    title: string;
    category: THEOliveEventCategory;
    allEvents: string[];
    selectedEvents: string[];
    onSelectEvent: (event: string) => void;
    onDeselectEvent: (event: string) => void;
}

function WebhookEventsSelectorCategory({
    title,
    category,
    allEvents,
    selectedEvents,
    onSelectEvent,
    onDeselectEvent,
}: Props) {
    const events = allEvents.filter((e) => e.includes(category));

    const numberOfEvents = events.length;
    const numberOfSelectedEvents = selectedEvents.filter((e) => e.includes(category)).length;

    function onCheckboxClick(event: string) {
        if (selectedEvents.includes(event)) {
            onDeselectEvent(event);
        } else {
            onSelectEvent(event);
        }
    }

    return (
        <Accordion style={{ backgroundColor: "#fcfcfc" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <TitleWrapper>
                    <Title>{title}</Title>
                    <Chip
                        style={{ backgroundColor: "#ffeeb8" }}
                        label={`${numberOfSelectedEvents}/${numberOfEvents} selected`}
                    />
                </TitleWrapper>
            </AccordionSummary>
            <AccordionDetails>
                {events.map((e) => (
                    <SelectItemWrapper key={e}>
                        <SelectItemInfoWrapper>
                            <SelectItemTitle>{e}</SelectItemTitle>
                            <SelectItemDescription>{getTHEOliveDescription(e)}</SelectItemDescription>
                        </SelectItemInfoWrapper>
                        <SelectItemCheckboxWrapper>
                            <Checkbox checked={selectedEvents.includes(e)} onChange={() => onCheckboxClick(e)} />
                        </SelectItemCheckboxWrapper>
                    </SelectItemWrapper>
                ))}
            </AccordionDetails>
        </Accordion>
    );
}

export default WebhookEventsSelectorCategory;

import { Grid } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import ChannelFilterGroup from "./ChannelFilterGroup";
import { GetActiveChannelsQuery_activeChannels_organization } from "./__generated__/GetActiveChannelsQuery";

const Wrapper = styled.div`
    border-radius: 8px;
    padding: 14px 20px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

export type ActiveChannelFilterType = "organization";

interface Props {
    items: {
        organizations: GetActiveChannelsQuery_activeChannels_organization[];
    };
    selectedItems: {
        organizations: string[];
    };
    onDeselectItem: (type: ActiveChannelFilterType, item: string) => void;
    onSelectItem: (type: ActiveChannelFilterType, item: string) => void;
    onSelectAll: (type: ActiveChannelFilterType) => void;
    onDeselectAll: (type: ActiveChannelFilterType) => void;
    initFiltersToShow: ActiveChannelFilterType[];
}

export default function ActiveChannelsFilter({
    items,
    initFiltersToShow,
    selectedItems,
    onDeselectAll,
    onDeselectItem,
    onSelectAll,
    onSelectItem,
}: Props) {
    return (
        <Wrapper>
            <Grid container spacing={2} style={{ marginTop: "2px" }}>
                <Grid item xs={12}>
                    <ChannelFilterGroup
                        items={items.organizations.map((o) => ({
                            key: o.organizationId,
                            value: o.name,
                        }))}
                        onDeselectAll={() => onDeselectAll("organization")}
                        onSelectAll={() => onSelectAll("organization")}
                        onDeselectItem={(item: string) => onDeselectItem("organization", item)}
                        onSelectItem={(item: string) => onSelectItem("organization", item)}
                        selectedItems={selectedItems.organizations}
                        title="Organizations"
                        isDropdownSearch
                    />
                </Grid>
            </Grid>
        </Wrapper>
    );
}

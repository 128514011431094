import { Button, Grid, DialogActions, DialogContent, FormControl, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { toast } from "react-toastify";
import DialogHESP from "../DialogHESP/DialogHESP";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import CopyLabel from "../CopyLabel/CopyLabel";
import Callout from "../Callout/Callout";
import gql from "graphql-tag";
import { CreateTokenMutation, CreateTokenMutationVariables } from "./__generated__/CreateTokenMutation";
import { useMutation } from "@apollo/react-hooks";
import { GET_TOKENS_QUERY } from "./TokensOverview";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const CREATE_TOKEN_MUTATION = gql`
    mutation CreateTokenMutation($input: CreateTokenInput!) {
        createToken(input: $input) {
            tokenKey
            secret
        }
    }
`;

const TokenInfo = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

interface Props {
    open: boolean;
    organizationId: string;
    onClose: () => void;
}

interface FormData {
    name: string;
}

function CreateTokenDialog({ open, onClose, organizationId }: Props) {
    const { handleSubmit, errors, register } = useForm<FormData>();
    const [token, setToken] = React.useState<{
        tokenKey: string;
        secret: string;
    }>({
        tokenKey: "",
        secret: "",
    });

    const [createTokenMut, { loading }] = useMutation<CreateTokenMutation, CreateTokenMutationVariables>(
        CREATE_TOKEN_MUTATION,
    );

    const generateToken = handleSubmit(async ({ name }: FormData) => {
        try {
            const res = await createTokenMut({
                variables: {
                    input: {
                        organizationId,
                        name,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_TOKENS_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            if (res.data?.createToken.secret && res.data.createToken.tokenKey) {
                setToken({
                    tokenKey: res.data.createToken.tokenKey,
                    secret: res.data.createToken.secret,
                });
            }
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    });

    function onCloseAction() {
        setToken({
            tokenKey: "",
            secret: "",
        });
        onClose();
    }

    return (
        <DialogHESP
            title={"Generate token"}
            open={open}
            onClose={() => onCloseAction()}
            content={
                <form onSubmit={handleSubmit((data1, e) => generateToken(e))}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {token.secret === "" && (
                                    <FormControl fullWidth>
                                        <HESPFormLabel label="Name" />
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            inputRef={register({
                                                required: "Name is required",
                                            })}
                                        />
                                        <Typography variant="subtitle2" color="error">
                                            {errors.name && errors.name.message}
                                        </Typography>
                                    </FormControl>
                                )}
                                {token.secret !== "" && (
                                    <>
                                        <TokenInfo>
                                            <CopyLabel title={"Key"} value={token.tokenKey}></CopyLabel>
                                            <CopyLabel title={"Secret"} value={token.secret}></CopyLabel>
                                            <div style={{ marginTop: "20px" }}>
                                                <Callout
                                                    type="warning"
                                                    title="Save your secret!"
                                                    description="You will only see this secret once! Make sure to copy and store your
                                            secret on a secure place."
                                                />
                                            </div>
                                        </TokenInfo>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {token.secret !== "" ? (
                            <Button variant="contained" color="secondary" onClick={onCloseAction}>
                                {token.secret !== "" ? "Close" : "Cancel"}
                            </Button>
                        ) : (
                            <DialogFooterWithLoading
                                isLoading={loading}
                                onCancelClick={onCloseAction}
                                defaultText={"Generate"}
                                loadingText={"Generating..."}
                            />
                        )}
                    </DialogActions>
                </form>
            }
        />
    );
}

export default CreateTokenDialog;

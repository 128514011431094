import * as React from "react";

import { Divider, Paper, Typography } from "@mui/material";
import { labelFormatter, tooltipFormatter } from "./formatter";

export interface CustomTooltipProps {
    payload?: any;
    label?: string;
}

const disableColor = "grey";

const CustomTooltip = (props: CustomTooltipProps) => {
    const { label, payload = [] } = props;

    const total = payload ? payload.reduce((acc: any, item: any) => acc + item.value, 0) : 0;
    const totalFormatted = tooltipFormatter(total);
    const labelFormatted = labelFormatter(label || "");

    return (
        <Paper style={{ opacity: 0.95, padding: "5px", display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">{`Minutes: ${totalFormatted}`}</Typography>
            <Typography variant="caption">{labelFormatted}</Typography>
            <Divider />
            {payload &&
                payload.map((item: any) => (
                    <Typography
                        variant="caption"
                        key={item.dataKey}
                        style={{
                            color: item.value > 0 ? item.stroke : disableColor,
                        }}
                    >
                        {`${item.dataKey}: ${item.value}`}
                    </Typography>
                ))}
        </Paper>
    );
};

export default CustomTooltip;

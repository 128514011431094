import * as React from "react";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from "recharts";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_concurrentViewerSessions_details } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import InsightsChartTooltip from "./InsightsChartTooltip";
import moment from "moment-timezone";
import { ViewerInsightsIntervalType } from "../../__generated__/globalTypes";

interface Props {
    details: GetChannelViewerInsightsQuery_channel_viewerInsights_concurrentViewerSessions_details[];
    intervalType: ViewerInsightsIntervalType;
}

function ViewerConcurrentSessionsChart({ details, intervalType }: Props) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const detailsWithTz = details.map((record) => ({
        timestamp: moment(record.timestamp).tz(tz).format(getFormatBasedOnIntervalType(intervalType)),
        amount: record.amount,
    }));

    // convert data: first to timezone
    // then to format based on intervalType (add as a prop)

    return (
        <div style={{ height: "200px" }}>
            <ResponsiveContainer width="100%" height="95%">
                <LineChart
                    data={detailsWithTz}
                    style={{ fontSize: "13px" }}
                    margin={{
                        top: 10,
                        right: 30,
                        left: -10,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<InsightsChartTooltip />} />
                    <XAxis dataKey="timestamp" />
                    <YAxis allowDecimals={false} />
                    <Line type="monotone" dataKey="amount" stroke="#FFC713" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default ViewerConcurrentSessionsChart;

function getFormatBasedOnIntervalType(intervalType: ViewerInsightsIntervalType): string {
    switch (intervalType) {
        case ViewerInsightsIntervalType.minute:
            return "HH:mm";
        case ViewerInsightsIntervalType.hour:
            return "HH:00";
        case ViewerInsightsIntervalType.day:
            return "DD/MM";
    }
}

import { Chip, Divider, Grid, Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import AuthService from "../../../auth/AuthService";
import CardHESP from "../../CardHESP/CardHESP";
import DataItem from "../../DataItem/DataItem";
import ModeBadge from "../../ModeBadge/ModeBadge";
import { CHANNEL_STATES_ICONS_DESCRIPTION_RECORD } from "../Overview/ChannelsOverview";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChangeChannelModeDialog from "./ChangeChannelModeDialog";
import ChannelInfoTitle from "./ChannelInfoTitle";
import EditChannelDialog from "./EditChannelDialog";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import { BitrateMode, ChannelModeType, ChannelStatusType } from "../../../__generated__/globalTypes";
import UpdateStreamConfigDialog from "./UpdateStreamConfigDialog";
import { STARTING_STATES } from "../Overview/ChannelActionsCell";
import { useQuery } from "react-apollo";
import { GetProfiles, GetProfilesVariables } from "../../AdminConfig/Profiles/__generated__/GetProfiles";
import { GET_PROFILES } from "../../AdminConfig/Profiles/ProfilesCard";
import ChannelAdminLinks from "./ChannelAdminLinks";
import ChannelChangeAutostopDialog from "./ChannelChangeAutostopDialog";
import ChannelChangePerpetualDialog from "./ChannelChangePerpetualDialog";
import moment from "moment";
import ChannelMaintenanceDialog from "./ChannelMaintenanceDialog";
import { HESPlink } from "../../Landing/SignUpComplete";
import { THEO_ID } from "../../../views/App/AppRoot";

export const iconStyling = { color: "#616161", fontSize: "18px" };

const ModeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    align-items: center;
`;

const chipStyle = {
    backgroundColor: "#edf5fa",
    marginRight: "8px",
    marginBottom: "8px",
};

const InfoWrapper = styled.div`
    background-color: ${(props) => props.theme.infoLight};
    color: ${(props) => props.theme.info};
    padding: 8px 14px;
    font-size: 14px;
    border-radius: 4px;
`;

interface Props {
    channel: GetChannelQuery_channel;
    isLoading: boolean;
    onChangeStatus: (starting: boolean) => void;
}

function ChannelMainDetails({ channel, onChangeStatus, isLoading }: Props) {
    const [openEditNameDialog, setOpenEditNameDialog] = React.useState<boolean>(false);
    const [openChangeModeDialog, setOpenChangeModeDialog] = React.useState<boolean>(false);
    const [openUpdateStreamConfigDialog, setOpenUpdateStreamConfigDialog] = React.useState<boolean>(false);
    const [openChangeAutostopDialog, setOpenChangeAutostopDialog] = React.useState<boolean>(false);
    const [openChangePerpetualDialog, setOpenChangePerpetualDialog] = React.useState<boolean>(false);
    const [openMaintenanceDialog, setOpenMaintenanceDialog] = React.useState<boolean>(false);

    const isTHEOAdminSales = AuthService.isTHEOAdminSales();
    const isTHEOAdmin = AuthService.isTHEOAdmin();

    const {
        channelStatus,
        channelId,
        channelMode,
        region,
        streamConfig,
        hasNoStandardConfig,
        lastErrorMessage,
        perpetual,
        ingestTimeout,
        tla,
    } = channel;

    const { fps, bitrate, resolution, abr, drm, bitrateMode } = streamConfig;

    const { data } = useQuery<GetProfiles, GetProfilesVariables>(GET_PROFILES, {
        variables: {
            organizationId: channel.organizationId,
        },
    });

    function onSuccessfulModeChange(newMode: ChannelModeType) {
        toast.success(`Channel mode changed to ${newMode} 🎉`, TOAST_SETTINGS);
    }

    function onErrorModeChange() {
        toast.error("Something went wrong 😞", TOAST_SETTINGS);
    }

    function onSuccessfulNameChange() {
        toast.success(`Channel name got changed! 🎉`, TOAST_SETTINGS);
    }

    function onErrorNameChange() {
        toast.error("Something went wrong 😞", TOAST_SETTINGS);
    }

    function onSuccessfulIngestConfigUpdate() {
        toast.success("Config updated! 🎉 Restart your channel in order to make it active", TOAST_SETTINGS);
    }

    function onErrorIngestConfigUpdate() {
        toast.error("Something went wrong 😞", TOAST_SETTINGS);
    }

    const isPlaying = STARTING_STATES.includes(channel.channelStatus);

    const channelCanBePerpetual = channel.organization.channelSettings.perpetual.enabled === true;

    return (
        <>
            <CardHESP style={{ height: "100%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <ChannelInfoTitle
                            channel={channel}
                            isLoading={isLoading}
                            onChangeStatus={(starting: boolean) => onChangeStatus(starting)}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "5px" }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {channel.currentRun && (
                                <Grid item xs={12}>
                                    <InfoWrapper>
                                        Auto-stop is {channel.currentRun.ingestTimeout === 0 ? "disabled" : "enabled"}{" "}
                                        for this run.{" "}
                                        {channel.currentRun.ingestTimeout === 0
                                            ? "You'll have to make sure to stop the channel yourself."
                                            : `The channel will be stopped automatically when no ingest is received for ${channel.currentRun.ingestTimeout} seconds.`}
                                    </InfoWrapper>
                                </Grid>
                            )}

                            <Grid item xs={12} lg={6}>
                                <DataItem title={"ID"} value={channelId} size="small" copyable />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <DataItem
                                    title={"Name"}
                                    editable
                                    onEditClick={() => setOpenEditNameDialog(true)}
                                    size="small"
                                    value={
                                        channel.metadata.name.length > 60 ? (
                                            <Tooltip title={channel.metadata.name}>
                                                <div>Channel {channel.metadata.name.substring(0, 60)}...</div>
                                            </Tooltip>
                                        ) : (
                                            `Channel ${channel.metadata.name}`
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <DataItem
                                    title={tla?.enabled === true ? "Data center" : "Region"}
                                    value={
                                        tla?.enabled === true ? (
                                            isTHEOAdmin ? (
                                                <HESPlink
                                                    link={`/app/${THEO_ID}/tla/data-centers/${tla?.datacenter?.id}`}
                                                >
                                                    {channel.tla!.datacenter!.id}
                                                </HESPlink>
                                            ) : (
                                                tla!.datacenter!.id
                                            )
                                        ) : (
                                            region!.regionId
                                        )
                                    }
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <DataItem
                                    title={"Current status"}
                                    size="small"
                                    value={
                                        <>
                                            {channelStatus === ChannelStatusType.error && lastErrorMessage !== ""
                                                ? `Error: ${lastErrorMessage}`
                                                : CHANNEL_STATES_ICONS_DESCRIPTION_RECORD[channelStatus].description}
                                        </>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} lg={channelCanBePerpetual ? 4 : 6}>
                                <DataItem
                                    title={"Mode"}
                                    size="small"
                                    editable
                                    onEditClick={isTHEOAdmin ? () => setOpenChangeModeDialog(true) : undefined}
                                    value={<ModeBadge mode={channelMode} />}
                                />
                            </Grid>
                            <Grid item xs={12} lg={channelCanBePerpetual ? 4 : 6}>
                                <DataItem
                                    title={"Auto-stop"}
                                    size="small"
                                    editable={!isPlaying}
                                    onEditClick={() => setOpenChangeAutostopDialog(true)}
                                    value={
                                        ingestTimeout !== 0 ? `Yes, after ${channel.ingestTimeout} seconds` : `Disabled`
                                    }
                                />
                            </Grid>
                            {channelCanBePerpetual && (
                                <Grid item xs={12} lg={4}>
                                    <DataItem
                                        title={"24/7 channel"}
                                        size="small"
                                        editable={!isPlaying}
                                        onEditClick={() => setOpenChangePerpetualDialog(true)}
                                        value={perpetual ? `Yes` : `No`}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} lg={12}>
                                <DataItem
                                    title={"Configuration"}
                                    editable={isPlaying ? false : !hasNoStandardConfig}
                                    onEditClick={() => setOpenUpdateStreamConfigDialog(true)}
                                    value={
                                        <ModeWrapper>
                                            {" "}
                                            {hasNoStandardConfig ? (
                                                <div>
                                                    A custom config for this channel has been set and can't be changed
                                                </div>
                                            ) : (
                                                <>
                                                    <div style={{ marginTop: "5px" }}>
                                                        <Chip
                                                            key={"abr"}
                                                            label={`ABR ${abr ? "enabled" : "disabled"}`}
                                                            style={chipStyle}
                                                        />
                                                        <Chip
                                                            key={"bitrate"}
                                                            label={`${bitrate} Mbps`}
                                                            style={chipStyle}
                                                        />
                                                        <Chip
                                                            key={"bitratemode"}
                                                            label={`${
                                                                bitrateMode === BitrateMode.constant ? "CBR" : "VBR"
                                                            }`}
                                                            style={chipStyle}
                                                        />
                                                        <Chip
                                                            key={"resolution"}
                                                            label={`${resolution.replace("r_", "")}`}
                                                            style={chipStyle}
                                                        />
                                                        <Chip key={"fps"} label={`${fps}fps`} style={chipStyle} />
                                                        <Chip
                                                            key={"drm"}
                                                            label={`DRM ${
                                                                drm?.enabled === true ? "enabled" : "disabled"
                                                            }`}
                                                            style={chipStyle}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </ModeWrapper>
                                    }
                                    size="small"
                                />
                            </Grid>

                            {channel.nextMaintenance && (
                                <Grid item xs={12}>
                                    <DataItem
                                        title={"Next maintenance"}
                                        editable={true}
                                        onEditClick={() => setOpenMaintenanceDialog(true)}
                                        value={`Planned at ${moment(channel.nextMaintenance).toString()}`}
                                    />
                                </Grid>
                            )}
                            {isTHEOAdmin && (
                                <Grid item xs={12}>
                                    <DataItem
                                        title={"Admin links"}
                                        value={
                                            <ChannelAdminLinks
                                                channelId={channelId}
                                                organizationId={channel.organizationId}
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardHESP>
            <EditChannelDialog
                channel={channel}
                onClose={() => setOpenEditNameDialog(false)}
                open={openEditNameDialog}
                onSuccess={() => onSuccessfulNameChange()}
                onError={() => onErrorNameChange()}
            />
            <ChannelChangeAutostopDialog
                channel={channel}
                onClose={() => setOpenChangeAutostopDialog(false)}
                open={openChangeAutostopDialog}
            />
            {channelCanBePerpetual && (
                <ChannelChangePerpetualDialog
                    channel={channel}
                    onClose={() => setOpenChangePerpetualDialog(false)}
                    open={openChangePerpetualDialog}
                />
            )}
            {data?.profiles && (
                <UpdateStreamConfigDialog
                    profiles={data.profiles}
                    channel={channel}
                    open={openUpdateStreamConfigDialog}
                    onClose={() => setOpenUpdateStreamConfigDialog(false)}
                    onSuccess={() => onSuccessfulIngestConfigUpdate()}
                    onError={() => onErrorIngestConfigUpdate()}
                />
            )}

            {isTHEOAdminSales && (
                <ChangeChannelModeDialog
                    open={openChangeModeDialog}
                    channel={channel}
                    onClose={() => setOpenChangeModeDialog(false)}
                    onSuccess={(newMode: ChannelModeType) => onSuccessfulModeChange(newMode)}
                    onError={() => onErrorModeChange()}
                />
            )}
            {channel.perpetual === true && (
                <ChannelMaintenanceDialog
                    channel={channel}
                    open={openMaintenanceDialog}
                    onClose={() => setOpenMaintenanceDialog(false)}
                />
            )}
            <ToastContainer />
        </>
    );
}

export default ChannelMainDetails;

import { Grid, Button, Switch } from "@mui/material";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { GET_WEBHOOK_QUERY } from "../WebhookDetails/WebhookDetails";
import { GetWebhookQuery_webhook } from "../WebhookDetails/__generated__/GetWebhookQuery";
import { CreateWebhookFormData } from "./CreateWebhookForm";
import WebhookEventsSelector from "./WebhookEventsSelector";
import WebhookFormBasicInfo from "./WebhookFormBasicInfo";
import { UpdateWebhookMutation, UpdateWebhookMutationVariables } from "./__generated__/UpdateWebhookMutation";
import { GET_WEBHOOKS_ORGANIZATION } from "./WebhooksOverview";

interface Props {
    webhook: GetWebhookQuery_webhook;
}

export const UPDATE_WEBHOOK_MUTATION = gql`
    mutation UpdateWebhookMutation($input: UpdateWebhookInput!) {
        updateWebhook(input: $input) {
            webhookId
        }
    }
`;

function EditWebhookForm({ webhook }: Props) {
    const { handleSubmit, register, errors } = useForm<CreateWebhookFormData>();

    const { webhookId, validEvents, events, organizationId } = webhook;

    const allEvents = validEvents.filter((w) => w !== "*");

    const [webhookEnabled, setWebhookEnabled] = React.useState<boolean>(webhook.active === true);
    const [selectAll, setSelectAll] = React.useState<boolean>(events.includes("*"));
    const [selectedEvents, setSelectedEvents] = React.useState<string[]>(events.includes("*") ? allEvents : events);

    const [updateWebhookMut, { loading: loadingMut }] = useMutation<
        UpdateWebhookMutation,
        UpdateWebhookMutationVariables
    >(UPDATE_WEBHOOK_MUTATION);

    function onSelectAllChange() {
        if (!selectAll) {
            setSelectedEvents(allEvents);
        } else {
            setSelectedEvents([]);
        }
        setSelectAll(!selectAll);
    }

    function setNewEvents(events: string[]) {
        setSelectedEvents(events);
        if (events.length === allEvents.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }

    async function onSubmitForm({ name, url, description }: CreateWebhookFormData) {
        try {
            await updateWebhookMut({
                variables: {
                    input: {
                        webhookId,
                        organizationId: webhook.organizationId,
                        name,
                        url,
                        active: webhookEnabled,
                        events: selectAll === true ? ["*"] : selectedEvents,
                        ...(description !== "" && { description }),
                    },
                },
                refetchQueries: [
                    {
                        query: GET_WEBHOOK_QUERY,
                        variables: {
                            webhookId,
                            organizationId: webhook.organizationId,
                        },
                    },
                    {
                        query: GET_WEBHOOKS_ORGANIZATION,
                        variables: {
                            organizationId: webhook.organizationId,
                        },
                    },
                ],
            });

            toast.success("Webhook updated! 🚀", TOAST_SETTINGS);
            navigate(`/app/${organizationId}/webhooks/${webhookId}`);
        } catch (e) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <WebhookFormBasicInfo
                        register={register}
                        errors={errors}
                        defaultValues={{ name: webhook.name, description: webhook.description ?? "", url: webhook.url }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Switch
                        color="primary"
                        checked={webhookEnabled}
                        onChange={() => setWebhookEnabled(!webhookEnabled)}
                    />{" "}
                    Enable webhook
                </Grid>
                <Grid item xs={12}>
                    <WebhookEventsSelector
                        allEvents={allEvents}
                        selectAll={selectAll}
                        onSelectAllChange={onSelectAllChange}
                        selectedEvents={selectedEvents}
                        onSelectedEventsChange={(events: string[]) => setNewEvents(events)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button
                            onClick={handleSubmit((d) => onSubmitForm(d))}
                            variant="contained"
                            size="large"
                            disabled={loadingMut}
                        >
                            {loadingMut ? "Updating webhook..." : "Update webhook"}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <ToastContainer />
        </>
    );
}

export default EditWebhookForm;

import * as React from "react";
import moment from "moment";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetRunsQuery, GetRunsQueryVariables } from "./__generated__/GetRunsQuery";
import ChannelRunsFilter from "./ChannelRunsFilter";
import ChannelRunsList from "./ChannelRunsList";
import { SkeletonRectangle } from "../../Loading/Skeletons";

interface Props {
    organizationId?: string;
    channelId?: string;
}

export interface ChannelRunFilters {
    channelId?: string;
    organizationId?: string;
    startDate: string;
    endDate: string;
    range: RunTimeRange;
}

export type RunTimeRange = "day" | "week" | "custom";

export const GET_RUNS_QUERY = gql`
    query GetRunsQuery(
        $channelId: ID
        $organizationId: ID
        $startDate: String!
        $endDate: String!
        $startingAfter: Int!
    ) {
        runs(
            channelId: $channelId
            organizationId: $organizationId
            startDate: $startDate
            endDate: $endDate
            startingAfter: $startingAfter
        ) {
            runs {
                id
                start
                end
                config
                startedByType
                startedById
                organizationId
                stoppedByType
                stoppedById
                scheduler
                errorsAmount
                ingestTimeout
            }
            hasMore
        }
    }
`;

export default function ChannelRunsContent({ channelId, organizationId }: Props) {
    const initEndDate = moment().endOf("day").toISOString();
    const initStartDate = moment().subtract(24, "hours").startOf("hour").toISOString();
    const initRange: RunTimeRange = "day";

    const { data, loading, fetchMore, refetch } = useQuery<GetRunsQuery, GetRunsQueryVariables>(GET_RUNS_QUERY, {
        variables: {
            organizationId,
            startingAfter: 0,
            startDate: initStartDate,
            endDate: initEndDate,
            channelId,
        },
    });

    async function onApplyFilters({ endDate, startDate }: ChannelRunFilters) {
        refetch({
            endDate,
            startDate,
            startingAfter: 0,
            channelId,
            organizationId,
        });
    }

    function onMoreClick() {
        const runs = data!.runs.runs;
        fetchMore({
            variables: {
                startingAfter: runs.length,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                const res: GetRunsQuery = {
                    runs: {
                        __typename: "ChannelRunListResult",
                        runs: [...previousQueryResult.runs.runs, ...fetchMoreResult.runs.runs],
                        hasMore: fetchMoreResult.runs.hasMore,
                    },
                };

                return res;
            },
        });
    }

    return (
        <>
            <ChannelRunsFilter
                filters={{
                    endDate: initEndDate,
                    startDate: initStartDate,
                    range: initRange,
                    channelId,
                    organizationId,
                }}
                onApplyFilter={(updatedFilters: ChannelRunFilters) => onApplyFilters(updatedFilters)}
            />
            {loading ||
                (!data && (
                    <>
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                        <SkeletonRectangle width="100%" />
                    </>
                ))}
            {!loading && data && (
                <ChannelRunsList hasMore={data.runs.hasMore} onMoreClick={onMoreClick} runs={data.runs.runs} />
            )}
        </>
    );
}

import { DialogActions, DialogContent } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import DialogHESP from "../DialogHESP/DialogHESP";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { GetFullBillingDetails_organization } from "../BillingDetails/__generated__/GetFullBillingDetails";
import {
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables,
} from "./__generated__/DeleteOrganizationMutation";
import { GET_ORGANIZATIONS_QUERY } from "../OrganizationsOverview/OrganizationsOverview";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";

export const DELETE_ORGANIZATION_MUTATION = gql`
    mutation DeleteOrganizationMutation($input: DeleteOrganizationInput!) {
        deleteOrganization(input: $input)
    }
`;

interface Props {
    open: boolean;
    onClose: () => void;
    organization: GetFullBillingDetails_organization;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

function RemoveOrganizationDialog({ open, onClose, organization }: Props) {
    const { handleSubmit } = useForm<FormData>();

    const [deleteOrganizationMut, { loading }] = useMutation<
        DeleteOrganizationMutation,
        DeleteOrganizationMutationVariables
    >(DELETE_ORGANIZATION_MUTATION);

    const deleteUser = handleSubmit(async () => {
        try {
            await deleteOrganizationMut({
                variables: {
                    input: {
                        organizationId: organization.organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_ORGANIZATIONS_QUERY,
                    },
                ],
            });
            toast.success("Organization got deleted successfully", TOAST_SETTINGS);
            navigate(`/app/${THEO_ID}`);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    });

    return (
        <>
            <DialogHESP
                title={"Delete organization"}
                open={open}
                onClose={onClose}
                content={
                    <form onSubmit={handleSubmit((data1, e) => deleteUser(e))}>
                        <DialogContent>
                            <Title>
                                Are you sure you want to delete {organization.name}? All connected users, channels
                                (together with the analytical data) and tokens will be deleted as well. <br />
                                <br /> This action is irreversible!
                            </Title>
                        </DialogContent>
                        <DialogActions>
                            <DialogFooterWithLoading
                                isLoading={loading}
                                onCancelClick={onClose}
                                defaultText={"Delete"}
                                loadingText={"Deleting..."}
                            />
                        </DialogActions>
                    </form>
                }
            />
            <ToastContainer />
        </>
    );
}

export default RemoveOrganizationDialog;

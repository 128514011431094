import { KeyboardArrowRight } from "@mui/icons-material";
import styled from "styled-components";
import CardHESP from "../CardHESP/CardHESP";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const IconAndInfo = styled.div`
    display: flex;
    flex-direction: row;
`;

const Action = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 10px;
    align-content: center;
    height: 100%;
`;

const IconWrapper = styled.div`
    display: flex;
    align-content: center;
    margin-right: 18px;
    align-items: center;
`;

const Icon = styled.div`
    border-radius: 8px;
    background-color: ${(props) => props.theme.lighterBlue};
    height: 40px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    color: ${(props) => props.theme.secondary};
    font-weight: 600;
`;

const Description = styled.div`
    margin-top: 4px;
    font-size: 14px;
`;

interface Props {
    title: string | React.ReactNode;
    description: string;
    icon: React.ReactNode;
    onClick: () => void;
}

export default function DeveloperLinkItem({ title, description, icon, onClick }: Props) {
    return (
        <CardHESP
            style={{ marginBottom: "20px", cursor: "pointer", paddingBottom: "12px", paddingTop: "10px" }}
            onClick={onClick}
        >
            <Wrapper>
                <IconAndInfo>
                    <IconWrapper>
                        <Icon>{icon}</Icon>
                    </IconWrapper>
                    <InfoWrapper>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </InfoWrapper>
                </IconAndInfo>
                <Action>
                    <KeyboardArrowRight color={"primary"} />
                </Action>
            </Wrapper>
        </CardHESP>
    );
}

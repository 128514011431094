import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { Button, Grid } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { GetOrganizationUsers_organization_users } from "../TeamOverview/__generated__/GetOrganizationUsers";
import TeamOverviewTable from "../TeamOverview/TeamOverviewTable";
import AddUserDialog from "../TeamOverview/AddUserDialog";
import DeleteUserDialog from "../TeamOverview/DeleteUserDialog";

interface Props {
    organizationId: string;
}

function OrganizationUsers({ organizationId }: Props) {
    const [showAddUser, setShowAddUser] = React.useState<boolean>(false);
    const [showDeleteUser, setShowDeleteUser] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<GetOrganizationUsers_organization_users>();

    function onDeleteUser(user: GetOrganizationUsers_organization_users) {
        setSelectedUser(user);
        setShowDeleteUser(true);
    }

    return (
        <>
            <CardHESP>
                <CardHESPTitle
                    title="Members"
                    button={
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PersonAdd />}
                            onClick={() => setShowAddUser(true)}
                        >
                            Add
                        </Button>
                    }
                />
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                        <TeamOverviewTable
                            organizationId={organizationId}
                            onDeleteUser={(user: GetOrganizationUsers_organization_users) => onDeleteUser(user)}
                        />
                    </Grid>
                </Grid>
            </CardHESP>
            <AddUserDialog organizationId={organizationId} onClose={() => setShowAddUser(false)} open={showAddUser} />
            {selectedUser && (
                <DeleteUserDialog
                    onClose={() => setShowDeleteUser(false)}
                    open={showDeleteUser}
                    user={selectedUser}
                    organizationId={organizationId}
                />
            )}
        </>
    );
}

export default OrganizationUsers;

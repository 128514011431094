/* eslint-disable no-undef */
import { Grid } from "@mui/material";
import * as React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import InsightsChannelData, {
    InsightAliasItem,
    InsightPeriodType,
} from "../../components/Insights/InsightsChannelData";
import ViewerConcurrentSessions from "../../components/Insights/ViewerConcurrentSessions";
import ViewerLatency from "../../components/Insights/ViewerLatency";
import ViewerWorldMap from "../../components/Insights/ViewerWorldMap";
import ViewerBrowserOverview from "../../components/Insights/ViewerBrowserOverview";
import CardHESP from "../../components/CardHESP/CardHESP";
import styled from "styled-components";
import moment from "moment";
import { useQuery } from "react-apollo";

import { ViewerInsightsIntervalType } from "../../__generated__/globalTypes";
import { GetChannelWithAllAliasesQuery_channel } from "../../components/Channels/Overview/__generated__/GetChannelWithAllAliasesQuery";
import { GET_CHANNEL_VIEWER_INSIGHTS } from "../../views/App/Insights";
import {
    GetChannelViewerInsightsQuery,
    GetChannelViewerInsightsQueryVariables,
} from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import { GetChannelBasics_channel } from "../../views/App/__generated__/GetChannelBasics";

const DataWrapper = styled.div`
    margin-top: 16px;
`;

interface Props {
    channel: GetChannelWithAllAliasesQuery_channel;
}

function InsightsWrapper({ channel }: Props) {
    const [period, setPeriod] = React.useState<InsightPeriodType>("hour");
    const [selectedAlias, setSelectedAlias] = React.useState<string>("total"); // TODO: change to "total"
    const [start, setStart] = React.useState<string>(getNewStartDateBasedOnPeriodType("hour").toISOString());
    const [initialLoadDone, setInitialLoadDone] = React.useState<boolean>(false);

    const { data, loading } = useQuery<GetChannelViewerInsightsQuery, GetChannelViewerInsightsQueryVariables>(
        GET_CHANNEL_VIEWER_INSIGHTS,
        {
            variables: {
                channelId: channel.channelId,
                organizationId: channel.organizationId,
                showTotal: selectedAlias === "total",
                aliasId: selectedAlias,
                timestamp: start,
                intervalType: getIntervalTypeBasedOnPeriod(period),
            },
        },
    );

    function onPeriodChange(newPeriod: InsightPeriodType) {
        setInitialLoadDone(true);
        const newStart = getNewStartDateBasedOnPeriodType(newPeriod);
        setStart(newStart.toISOString());
        setPeriod(newPeriod);
    }

    const isLoading = !data || loading;

    function onRefetch() {
        const newStart = getNewStartDateBasedOnPeriodType(period);
        setInitialLoadDone(true);
        setStart(newStart.toISOString());
    }

    const allAliases: InsightAliasItem[] = [
        {
            id: "total",
            name: "Total",
        },
        {
            id: channel.channelId,
            name: channel.metadata.name,
        },
        ...channel.aliases.map((a) => ({
            id: a.channelId,
            name: a.metadata.name,
        })),
    ];

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle
                    title={`Viewer insights for channel ${channel.channelId}`}
                    align={"left"}
                    showBottomBorder
                />
                <CardHESP>
                    <InsightsChannelData
                        channel={channel as GetChannelBasics_channel}
                        type="viewer"
                        aliases={allAliases}
                        isLoading={isLoading}
                        selectedAlias={selectedAlias}
                        onChangeAlias={(aliasId: string) => setSelectedAlias(aliasId)}
                        initialLoadDone={initialLoadDone}
                        selectedPeriod={period}
                        onChangePeriod={(newPeriod: InsightPeriodType) => onPeriodChange(newPeriod)}
                        onRefetch={onRefetch}
                    />
                </CardHESP>
                <DataWrapper>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <ViewerConcurrentSessions
                                intervalType={getIntervalTypeBasedOnPeriod(period)}
                                data={data?.channel?.viewerInsights?.concurrentViewerSessions}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ViewerLatency data={data?.channel?.viewerInsights?.viewerLatency} />
                        </Grid>
                        <Grid item xs={12}>
                            <ViewerWorldMap data={data?.channel?.viewerInsights?.regions} />
                        </Grid>
                        <Grid item xs={12}>
                            <ViewerBrowserOverview data={data?.channel?.viewerInsights?.browsersAndOS} />
                        </Grid>
                    </Grid>
                </DataWrapper>
            </Grid>
        </Grid>
    );
}

export default InsightsWrapper;

function getIntervalTypeBasedOnPeriod(period: InsightPeriodType): ViewerInsightsIntervalType {
    switch (period) {
        case "hour":
            return ViewerInsightsIntervalType.minute;
        case "day":
            return ViewerInsightsIntervalType.minute;
        case "week":
        case "month":
            return ViewerInsightsIntervalType.day;
    }
}

function getNewStartDateBasedOnPeriodType(period: InsightPeriodType): moment.Moment {
    switch (period) {
        case "month":
            return moment().subtract(30, "days").startOf("day").utc();
        case "week":
            return moment().subtract(7, "days").startOf("day").utc();
        case "day":
            return moment().subtract(24, "hours").startOf("hour").utc();
        case "hour":
            return moment().subtract(60, "minutes").startOf("minute").utc();
    }
}

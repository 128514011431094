import * as React from "react";
import styled from "styled-components";

interface Props {
    children: React.ReactNode;
    style?: {};
    onClick?: () => void;
    background?: string;
}

const CardWrapper = styled.div.attrs((props: { background: string }) => ({
    background: props.background,
}))`
    border: 1px solid #ececec;
    padding: 15px 20px 10px 20px;
    border-radius: 8px;
    // height: 90%;
    box-shadow: 0 0px 0px 0 rgb(60 66 87 / 8%), 0 2px 15px 0 rgb(0 0 0 / 12%);
    background: ${(props) => props.background ?? "white"};
`;

function CardHESP({ children, style, onClick, background }: Props) {
    return (
        <CardWrapper style={style} onClick={onClick} background={background}>
            {children}
        </CardWrapper>
    );
}

export default CardHESP;

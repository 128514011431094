import React from "react";
import { Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import gql from "graphql-tag";
import { useLazyQuery } from "react-apollo";
import {
    GetSchedulersViaSearchParams,
    GetSchedulersViaSearchParamsVariables,
    GetSchedulersViaSearchParams_schedulersWithParams,
} from "./__generated__/GetSchedulersViaSearchParams";
import { OrderDirectionType } from "../../__generated__/globalTypes";
import { SkeletonRectangle } from "../Loading/Skeletons";
import SchedulerSearchItem from "./SchedulerSearchItem";
import { useForm } from "react-hook-form";
import NoSchedulersFound from "./NoSchedulersFound";

interface Props {
    organizationId: string;
}

interface FormData {
    search: string;
}

export const GET_SCHEDULERS_VIA_SEARCH_PARAMS = gql`
    query GetSchedulersViaSearchParams(
        $organizationId: ID!
        $start: String
        $end: String
        $page: Int!
        $search: String
        $orderBy: String!
        $orderDirection: OrderDirectionType!
        $limit: Int!
    ) {
        schedulersWithParams(
            organizationId: $organizationId
            start: $start
            end: $end
            page: $page
            search: $search
            orderBy: $orderBy
            orderDirection: $orderDirection
            limit: $limit
        ) {
            schedulerId
            name
            channelIds
            name
            start
            end
            phase
            organizationId
            clientId
            metadata {
                key
                value
            }
            channels {
                channelId
                metadata {
                    name
                }
            }
        }
    }
`;

export default function SchedulersQuickSearch({ organizationId }: Props) {
    const { register, errors, handleSubmit } = useForm<FormData>();
    const [data, setData] = React.useState<GetSchedulersViaSearchParams_schedulersWithParams[]>([]);
    const [didFirstSubmit, setDidFirstSubmit] = React.useState<boolean>(false);

    const [getSchedulers, { loading }] = useLazyQuery<
        GetSchedulersViaSearchParams,
        GetSchedulersViaSearchParamsVariables
    >(GET_SCHEDULERS_VIA_SEARCH_PARAMS, {
        onCompleted: (data) => {
            setData(data.schedulersWithParams);
        },

        variables: {
            limit: 5,
            page: 1,
            orderBy: "id",
            orderDirection: OrderDirectionType.asc,
            organizationId,
            search: "",
        },
    });

    const search = handleSubmit(async ({ search }: FormData) => {
        setDidFirstSubmit(true);
        try {
            getSchedulers({
                variables: {
                    limit: 5,
                    page: 1,
                    orderBy: "id",
                    orderDirection: OrderDirectionType.asc,
                    organizationId,
                    search,
                },
            });
        } catch (_) {}
    });

    return (
        <form onSubmit={handleSubmit((_data, e) => search(e))}>
            <Grid container spacing={2} style={{ marginTop: "2px" }}>
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            placeholder="Search on ID, name, clientId"
                            name="search"
                            size="small"
                            inputRef={register({
                                required: "Search is required",
                            })}
                            inputProps={{
                                style: {
                                    padding: "8px 10px",
                                },
                            }}
                        />
                    </FormControl>
                    <Typography variant="subtitle2" color="error">
                        {errors.search && errors.search?.message}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={search} fullWidth>
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <>
                            <SkeletonRectangle />
                            <SkeletonRectangle />
                            <SkeletonRectangle />
                        </>
                    ) : (
                        <>
                            {didFirstSubmit && data.length === 0 && <NoSchedulersFound />}
                            {data.length > 0 &&
                                data.map((s) => <SchedulerSearchItem key={`sched-search-${s.schedulerId}`} item={s} />)}
                        </>
                    )}
                </Grid>
            </Grid>
        </form>
    );
}

import * as React from "react";
import styled from "styled-components";
import { Button, useTheme } from "@mui/material";
import { ChannelStatusType } from "../../../__generated__/globalTypes";
import Loading from "../../Loading/Loading";
import { getStatusVisuals } from "../Overview/ChannelsOverview";
import { STARTED_STATES } from "../Overview/ChannelActionsCell";
import { PlayCircleFilledOutlined, Stop } from "@mui/icons-material";
import StopChannelDialog from "./StopChannelDialog";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";

interface Props {
    channel: GetChannelQuery_channel;
    isLoading: boolean;
    // eslint-disable-next-line no-unused-vars
    onChangeStatus: (starting: boolean) => void;
}

const ChannelTitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const ChannelTitleNameDiv = styled.div`
    padding-top: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const ChannelTitle = styled.div`
    margin-left: 10px;
    font-weight: 500;
    font-size: 20px;
`;

function ChannelInfoTitle({ channel, onChangeStatus, isLoading }: Props) {
    const theme = useTheme();
    const [openStopChannel, setOpenStopChannel] = React.useState<boolean>(false);
    const { channelStatus } = channel;

    const [lastState, setLastState] = React.useState<ChannelStatusType>(channelStatus);

    React.useEffect(() => {
        setLastState(channelStatus);
    }, [channelStatus]);

    function showButtons() {
        return (
            (STARTED_STATES.includes(channelStatus) || channelStatus === ChannelStatusType.stopped) &&
            lastState === channelStatus
        );
    }

    const isStopped: boolean = channelStatus === ChannelStatusType.stopped;

    function changeStatus() {
        if (isStopped) {
            setLastState(ChannelStatusType.deploying);
            onChangeStatus(isStopped);
        } else {
            setOpenStopChannel(true);
        }
    }

    function onConfirmStop() {
        setLastState(ChannelStatusType.stopping);
        setOpenStopChannel(false);
        onChangeStatus(isStopped);
    }

    return (
        <>
            <ChannelTitleContainer>
                <ChannelTitleNameDiv>
                    <div>{getStatusVisuals(channelStatus)}</div>
                    <ChannelTitle>Details</ChannelTitle>
                </ChannelTitleNameDiv>
                {isLoading ? (
                    <Loading size={"small"} />
                ) : (
                    <>
                        {showButtons() && (
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: isStopped ? theme.palette.success.main : theme.palette.error.main,
                                    color: "white",
                                }}
                                variant="contained"
                                startIcon={
                                    isStopped ? (
                                        <PlayCircleFilledOutlined fontSize="small" />
                                    ) : (
                                        <Stop fontSize="small" />
                                    )
                                }
                                onClick={changeStatus}
                            >
                                {isStopped ? "Start" : "Stop"}
                            </Button>
                        )}
                    </>
                )}
            </ChannelTitleContainer>
            <StopChannelDialog
                channel={{ channelId: channel.channelId, name: channel.metadata.name }}
                onClose={() => setOpenStopChannel(false)}
                onConfirm={() => onConfirmStop()}
                open={openStopChannel}
            />
        </>
    );
}

export default ChannelInfoTitle;

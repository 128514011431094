import { Button } from "@mui/material";
import { Save } from "@mui/icons-material";
import * as React from "react";
import ChannelCustomizer from "../../ChannelCustomizer/ChannelCustomizer";
import styled from "styled-components";
import {
    ChannelCustomization as ChannelCustomizationObj,
    ProperyChangeType,
} from "../../ChannelCustomizer/customizationHelpers";
import Loading from "../../Loading/Loading";

interface Props {
    customizationChanged: boolean;
    customization: ChannelCustomizationObj;
    onCustomizationChange: (type: ProperyChangeType, key: string, value: string | boolean | number) => void;
    onSaveClick: () => void;
    loading: boolean;
}

const Wrapper = styled.div`
    margin: 5px;
    padding: 10px 20px;
    background-color: #fafafa;
    border-radius: 8px;
`;

const NotSaved = styled.div`
    color: ${(props) => props.theme.warning};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
    padding-top: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 10px;
    align-items: center;
`;

function ChannelCustomization({
    customizationChanged,
    customization,
    onCustomizationChange,
    loading,
    onSaveClick,
}: Props) {
    return (
        <Wrapper>
            {customizationChanged && (
                <NotSaved>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div style={{ marginRight: "10px" }}>Config not saved yet!</div>
                            <Button
                                startIcon={<Save />}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={onSaveClick}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </NotSaved>
            )}
            <ChannelCustomizer
                customization={customization}
                onCustomizationChange={(type: ProperyChangeType, key: string, value: string | boolean | number) =>
                    onCustomizationChange(type, key, value)
                }
            />
        </Wrapper>
    );
}

export default ChannelCustomization;

import { Grid } from "@mui/material";
import * as React from "react";
import { CreateWebhookSectionTitle } from "./WebhookFormBasicInfo";
import SwitchWithTitle from "../SwitchWithTitle/SwitchWithTitle";
import styled from "styled-components";

interface Props {
    selected: boolean;
    onChange: () => void;
}

const Description = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 14px;
    margin-bottom: 15px;
`;

export default function WebhookAdminSelector({ selected, onChange }: Props) {
    return (
        <>
            <CreateWebhookSectionTitle style={{ marginBottom: "5px" }}>Admin webhook</CreateWebhookSectionTitle>
            <Description>
                When enabled, this webhook will capture events of all THEOlive channels that exist.
            </Description>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SwitchWithTitle checked={selected} onChange={() => onChange()} title="Enable admin mode" />
                </Grid>
            </Grid>
        </>
    );
}

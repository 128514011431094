import * as React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

interface Props {
    text?: string;
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Spinner = styled.div`
    margin-bottom: 20px;
`;

const Text = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.gray};
`;

function CentralLoader({ text }: Props) {
    return (
        <Wrapper>
            <Spinner>
                <CircularProgress />
            </Spinner>
            {text && <Text>{text}</Text>}
        </Wrapper>
    );
}

export default CentralLoader;

import { DialogActions, DialogContent, FormControl, Grid } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import { MaintenanceSettings } from "../../Settings/SettingsMaintenanceWindow/MaintenanceManagement";
import { MaintenanceRecurringPeriodType, MaintenanceType } from "../../../__generated__/globalTypes";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import SwitchWithTitle from "../../SwitchWithTitle/SwitchWithTitle";
import React from "react";
import MaintenanceWindowRecurring from "../../Settings/SettingsMaintenanceWindow/MaintenanceWindowRecurring";
import MaintenanceWindowFixedSlots from "../../Settings/SettingsMaintenanceWindow/MaintenanceWindowFixedSlots";
import MaintenanceTypeSelector from "../../Settings/SettingsMaintenanceWindow/MaintenanceTypeSelector";
import styled from "styled-components";
import { HESPlink } from "../../Landing/SignUpComplete";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import {
    UpdateChannelMaintenanceMutation,
    UpdateChannelMaintenanceMutationVariables,
} from "./__generated__/UpdateChannelMaintenanceMutation";
import { toast } from "react-toastify";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";

const TypeWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    @media (max-width: 620px) {
        flex-direction: column;
    }
`;

const ScheduleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background-color: white;
    padding: 12px 16px;
`;

const Wrapper = styled.div`
    border-radius: 10px;
    padding: 15px 20px;
    background: #fafafa;
`;

interface Props {
    channel: GetChannelQuery_channel;
    open: boolean;
    onClose: () => void;
}

export const UPDATE_CHANNEL_MAINTENANCE_MUTATION = gql`
    mutation UpdateChannelMaintenanceMutation($input: UpdateChannelMaintenanceInput!) {
        updateChannelMaintenance(input: $input) {
            channelId
        }
    }
`;

export default function ChannelMaintenanceDialog({ channel, onClose, open }: Props) {
    const [useSpecificSchedule, setUseSpecificSchedule] = React.useState<boolean>(channel.maintenance !== null);
    const sorter: Record<string, number> = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
    };

    const initValues: MaintenanceSettings = {
        type: channel.maintenance?.type ?? MaintenanceType.scheduled,
        slots:
            channel.maintenance?.slots && channel.maintenance?.slots.length > 0
                ? channel.maintenance?.slots.sort((a, b) =>
                      a.day === b.day ? (a.time < b.time ? -1 : 1) : sorter[a.day] - sorter[b.day],
                  )
                : [{ day: "Monday", time: "10:00" }],
        interval:
            channel.maintenance?.interval !== null && channel.maintenance?.interval
                ? channel.maintenance?.interval
                : {
                      amount: 3,
                      period: MaintenanceRecurringPeriodType.days,
                      time: "10:00",
                  },
    };

    const [settings, setSettings] = React.useState<MaintenanceSettings>(initValues);

    const [updateMaintenanceMut, { loading }] = useMutation<
        UpdateChannelMaintenanceMutation,
        UpdateChannelMaintenanceMutationVariables
    >(UPDATE_CHANNEL_MAINTENANCE_MUTATION);

    async function onSave() {
        try {
            await updateMaintenanceMut({
                variables: {
                    input: {
                        channelId: channel.channelId,
                        organizationId: channel.organizationId,
                        isCustom: useSpecificSchedule,
                        maintenance: {
                            type: settings.type,
                            interval: settings.interval,
                            slots: settings.slots,
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            toast.success("Maintenance settings for channel updated");
            onClose();
        } catch (_e) {
            toast.error("Something went wrong");
        }
    }

    function changeTime(idx: number, time: string) {
        const slots = settings.slots!;
        slots[idx].time = time;
        setSettings({
            ...settings,
            slots,
        });
    }

    function changeDay(idx: number, day: string) {
        const slots = settings.slots!;
        slots[idx].day = day;
        setSettings({
            ...settings,
            slots,
        });
    }

    function removeEntry(idx: number) {
        const slots = settings.slots!;
        slots.splice(idx, 1);
        setSettings({
            ...settings,
            slots,
        });
    }

    return (
        <DialogHESP
            title={"Channel maintenance"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel
                                        label="Use global organization settings, or specific one for channel"
                                        description={
                                            channel.maintenance !== null ? (
                                                <>
                                                    A specific schedule has been set for this schedule. Disable the
                                                    switch below to make use of the{" "}
                                                    <HESPlink
                                                        link={`/app/${channel.organizationId}/settings/maintenance`}
                                                    >
                                                        global organization schedule
                                                    </HESPlink>
                                                    .
                                                </>
                                            ) : (
                                                <>
                                                    This channel currently makes use of the{" "}
                                                    <HESPlink
                                                        link={`/app/${channel.organizationId}/settings/maintenance`}
                                                    >
                                                        global organization schedule
                                                    </HESPlink>
                                                    . Enable the switch below if you want to set a custom schedule for
                                                    this channel alone."
                                                </>
                                            )
                                        }
                                    />
                                    <SwitchWithTitle
                                        checked={useSpecificSchedule}
                                        title="Set channel specific maintenance schedule"
                                        onChange={() => setUseSpecificSchedule(!useSpecificSchedule)}
                                    />
                                </FormControl>
                            </Grid>
                            {useSpecificSchedule && (
                                <Grid item xs={12}>
                                    <Wrapper>
                                        <HESPFormLabel label="Type:" />
                                        <TypeWrapper>
                                            <MaintenanceTypeSelector
                                                selectedType={settings.type}
                                                onSelectType={(type: MaintenanceType) => {
                                                    setSettings({
                                                        ...settings,
                                                        type,
                                                    });
                                                }}
                                            />
                                        </TypeWrapper>
                                        <ScheduleWrapper>
                                            {settings.type === MaintenanceType.scheduled && (
                                                <MaintenanceWindowFixedSlots
                                                    settings={settings}
                                                    changeTimeOfSlot={(idx: number, time: string) =>
                                                        changeTime(idx, time)
                                                    }
                                                    changeDayOfSlot={(idx: number, day: string) => changeDay(idx, day)}
                                                    addEntry={() => {
                                                        setSettings({
                                                            ...settings,
                                                            slots: settings.slots!.concat([
                                                                {
                                                                    day: "Monday",
                                                                    time: "10:00",
                                                                },
                                                            ]),
                                                        });
                                                    }}
                                                    removeEntry={(idx: number) => removeEntry(idx)}
                                                />
                                            )}
                                            {settings.type === MaintenanceType.recurring && (
                                                <MaintenanceWindowRecurring
                                                    settings={settings}
                                                    onAmountChange={(amount: number) => {
                                                        setSettings({
                                                            ...settings,
                                                            interval: {
                                                                ...settings.interval!,
                                                                amount,
                                                            },
                                                        });
                                                    }}
                                                    onPeriodChange={(period: MaintenanceRecurringPeriodType) => {
                                                        setSettings({
                                                            ...settings,
                                                            interval: {
                                                                ...settings.interval!,
                                                                amount: 1,
                                                                period,
                                                            },
                                                        });
                                                    }}
                                                    onTimeChange={(time: string) => {
                                                        setSettings({
                                                            ...settings,
                                                            interval: {
                                                                ...settings.interval!,
                                                                time,
                                                            },
                                                        });
                                                    }}
                                                />
                                            )}
                                        </ScheduleWrapper>
                                    </Wrapper>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onActionClick={onSave}
                            onCancelClick={onClose}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import { FormGroup, TextField, styled as materialStyled } from "@mui/material";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";

interface Props {
    version: string;
    onChangeVersion: (version: string) => void;
}

const smallCss = {
    "& .MuiOutlinedInput-input": {
        padding: "10px 10px 10px 10px",
        fontSize: "14px",
        minWidth: "80px",
        backgroundColor: "white",
    },
};
const CssTextField = materialStyled(TextField)(smallCss);

export default function ChannelAdminEngine({ onChangeVersion, version }: Props): JSX.Element {
    return (
        <FormGroup>
            <HESPFormLabel label="Engine version" description="Leave empty if latest should be used" />
            <CssTextField
                fullWidth
                type="text"
                size="small"
                placeholder="Version to use"
                onChange={(e) => onChangeVersion(e.target.value)}
                variant="outlined"
                key="engine-version"
                value={version}
            />
        </FormGroup>
    );
}

/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import { Grid } from "@mui/material";
import * as React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import InsightsChannelData from "../../components/Insights/InsightsChannelData";

import CardHESP from "../../components/CardHESP/CardHESP";
import styled from "styled-components";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetChannelBasics, GetChannelBasicsVariables } from "./__generated__/GetChannelBasics";
import StreamAnalyzer from "../../components/StreamAnalyzer/StreamAnalyzer";

export const GET_CHANNEL_BASICS = gql`
    query GetChannelBasics($channelId: ID!, $organizationId: ID!) {
        channel(channelId: $channelId, organizationId: $organizationId) {
            channelId
            channelStatus
            organizationId
            tla {
                enabled
                networkingConfig {
                    websocketUrl
                }
            }
            metadata {
                name
            }
            region {
                regionId
                awsRegion
            }
        }
    }
`;

const DataWrapper = styled.div`
    margin-top: 16px;
`;

interface Props extends RouteComponentProps {
    path?: string;
    channelId?: string;
    organizationId?: string;
}

export default function IngestHealth({ channelId, organizationId }: Props) {
    const { data, loading } = useQuery<GetChannelBasics, GetChannelBasicsVariables>(GET_CHANNEL_BASICS, {
        variables: {
            channelId: channelId!,
            organizationId: organizationId!,
        },
    });

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`Ingest health for channel ${channelId}`} align={"left"} showBottomBorder />
                <CardHESP>
                    <InsightsChannelData
                        type="streaming"
                        channel={data?.channel}
                        isLoading={loading}
                        initialLoadDone={false}
                    />
                </CardHESP>
                {!loading && data && (
                    <DataWrapper>
                        <StreamAnalyzer channel={data.channel} />
                    </DataWrapper>
                )}
            </Grid>
        </Grid>
    );
}

import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Button, Grid } from "@mui/material";
import SectionTitleWithAction from "../../components/SectionTitle/SectionTitleWithAction";
import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import EventsOverview from "../../components/EventsOverview/EventsOverview";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function Events({ organizationId }: Props) {
    const [showFilters, setShowFilters] = React.useState<boolean>(false);
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center" alignItems={"center"} alignContent="center">
            <Grid item xs={12}>
                <SectionTitleWithAction
                    title="Events"
                    action={
                        <Button
                            startIcon={showFilters ? <FilterAltOff /> : <FilterAlt />}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            {showFilters ? "Remove" : "Add"} filters
                        </Button>
                    }
                />
                <EventsOverview organizationId={organizationId!} showFilters={showFilters} />
            </Grid>
        </Grid>
    );
}

export default Events;

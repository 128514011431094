import * as React from "react";
import { GetChannelsOrganizationQuery_channels } from "./__generated__/GetChannelsOrganizationQuery";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { Stop, PlayCircleFilledOutlined, Settings, DeleteForever, Equalizer } from "@mui/icons-material";
import { navigate } from "@reach/router";
import Loading from "../../Loading/Loading";
import { ChannelStatusType } from "../../../__generated__/globalTypes";
import { toast } from "react-toastify";
import {
    GET_CHANNELS_ORGANIZATION_QUERY,
    GET_CHANNEL_QUERY,
    START_CHANNEL_MUTATION,
    STOP_CHANNEL_MUTATION,
} from "./channelQueriesMutations";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import { GET_CHANNEL_BASICS } from "../../../views/App/IngestHealthV2";
import { useMutation } from "react-apollo";
import DeleteChannelDialog from "./DeleteChannelDialog";
import StopChannelDialog from "../Details/StopChannelDialog";
import { StartChannelMutation, StartChannelMutationVariables } from "./__generated__/StartChannelMutation";
import { StopChannelMutation, StopChannelMutationVariables } from "./__generated__/StopChannelMutation";

export const DELETING_STATES: ChannelStatusType[] = [ChannelStatusType.deleting, ChannelStatusType.deleted];
export const STARTING_STATES: ChannelStatusType[] = [
    ChannelStatusType.deploying,
    ChannelStatusType.playing,
    ChannelStatusType.ingesting,
    ChannelStatusType.starting,
    ChannelStatusType.waiting,
];
export const STARTUP_STATES: ChannelStatusType[] = [ChannelStatusType.deploying];
export const STARTED_STATES: ChannelStatusType[] = [
    ChannelStatusType.starting,
    ChannelStatusType.ingesting,
    ChannelStatusType.waiting,
    ChannelStatusType.playing,
    ChannelStatusType.error,
    ChannelStatusType.scheduled,
];
export const STOPPING_STATES: ChannelStatusType[] = [ChannelStatusType.stopped, ChannelStatusType.stopping];

interface Props {
    channel: GetChannelsOrganizationQuery_channels;
}

function ChannelActionsCell({ channel }: Props) {
    const { channelId, organizationId, channelStatus } = channel;

    const [showDeleteChannelDialog, setShowDeleteChannelDialog] = React.useState<boolean>(false);
    const [showStopChannelDialog, setShowStopChannelDialog] = React.useState<boolean>(false);

    const [startChannelMut, { loading: loadingStartMut }] = useMutation<
        StartChannelMutation,
        StartChannelMutationVariables
    >(START_CHANNEL_MUTATION);

    const [stopChannelMut, { loading: loadingStopMut }] = useMutation<
        StopChannelMutation,
        StopChannelMutationVariables
    >(STOP_CHANNEL_MUTATION);

    const theme = useTheme();

    async function confirmStopChannel(channelId: string) {
        setShowStopChannelDialog(false);
        try {
            await stopChannelMut({
                variables: {
                    input: {
                        channelId,
                        organizationId,
                    },
                },
                refetchQueries: [
                    { query: GET_CHANNEL_QUERY, variables: { channelId, organizationId } },
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                    {
                        query: GET_CHANNEL_BASICS,
                        variables: {
                            channelId,
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Channel stopped successfully", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    async function startChannel() {
        try {
            await startChannelMut({
                variables: {
                    input: {
                        channelId,
                        organizationId,
                    },
                },
                refetchQueries: [
                    { query: GET_CHANNEL_QUERY, variables: { channelId, organizationId } },
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Channel started succesfully! 🚀", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error(e.graphQLErrors[0].message ?? "Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    const isMutLoading = loadingStopMut || loadingStartMut;

    if (isMutLoading) {
        return <Loading />;
    }

    return (
        <>
            {STARTUP_STATES.includes(channelStatus) ? (
                <div style={{ color: theme.palette.success.main }}>Starting...</div>
            ) : channelStatus === "stopping" ? (
                <div style={{ color: theme.palette.error.main }}>Stopping...</div>
            ) : channelStatus === "deleting" ? (
                <div>Deleting...</div>
            ) : (
                <>
                    {STARTED_STATES.includes(channelStatus) && (
                        <Tooltip title="Stop channel">
                            <IconButton onClick={() => setShowStopChannelDialog(true)}>
                                <Stop />
                            </IconButton>
                        </Tooltip>
                    )}
                    {channelStatus === "stopped" && (
                        <Tooltip title="Start channel">
                            <IconButton onClick={startChannel}>
                                <PlayCircleFilledOutlined />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Insights">
                        <IconButton
                            onClick={() => window.open(`/app/${organizationId}/channels/${channelId}/insights`)}
                        >
                            <Equalizer fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="details">
                        <IconButton onClick={() => navigate(`/app/${organizationId}/channels/${channelId}`)}>
                            <Settings fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {channelStatus === "stopped" && (
                        <Tooltip title="delete">
                            <IconButton onClick={() => setShowDeleteChannelDialog(true)}>
                                <DeleteForever fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            )}
            <DeleteChannelDialog
                open={showDeleteChannelDialog}
                channel={channel}
                onClose={() => setShowDeleteChannelDialog(false)}
            />
            <StopChannelDialog
                channel={{
                    channelId: channel.channelId,
                    name: channel.metadata.name,
                }}
                onConfirm={() => confirmStopChannel(channel.channelId)}
                onClose={() => setShowStopChannelDialog(false)}
                open={showStopChannelDialog}
            />
        </>
    );
}

export default ChannelActionsCell;

import * as React from "react";
import styled from "styled-components";
import {
    GetFullOrganizationDetails_organization,
    GetFullOrganizationDetails_organization_tla_datacenters,
} from "../../views/App/__generated__/GetFullOrganizationDetails";
import OrganizationDatacenterItem from "./OrganizationDatacenterItem";
import RemoveDataCenterDialog from "./RemoveDataCenterDialog";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import AddDatacenterToOrganizationDialog from "./AddDatacenterToOrganizationDialog";
import { useQuery } from "react-apollo";
import { GET_DATACENTERS } from "../../views/App/TLADatacenters";
import { GetAllDatacenters } from "../../views/App/__generated__/GetAllDatacenters";

interface Props {
    organization: GetFullOrganizationDetails_organization;
}

const Wrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.grayBackground};
    border-radius: 8px;
    padding: 12px 20px;
`;

const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 15px;
    font-weight: bolder;
`;

const Description = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

const Content = styled.div`
    background-color: white;
    border-radius: 8px;
    margin-top: 10px;
    padding: 8px 16px;
`;

const SwitchWrapper = styled.div``;

export default function OrganizationTLADataCenterOverview({ organization }: Props) {
    const [openRemoveDialog, setOpenRemoveDialog] = React.useState<boolean>(false);
    const [openAssignDialog, setOpenAssignDialog] = React.useState<boolean>(false);

    const { data } = useQuery<GetAllDatacenters>(GET_DATACENTERS);

    const [activeDC, setActiveDC] = React.useState<GetFullOrganizationDetails_organization_tla_datacenters | undefined>(
        undefined,
    );
    const { datacenters } = organization.tla;

    async function removeDC(datacenter: GetFullOrganizationDetails_organization_tla_datacenters) {
        setActiveDC(datacenter);
        setOpenRemoveDialog(true);
    }

    return (
        <>
            <Wrapper>
                <InnerWrapper>
                    <MainWrapper>
                        <Title>Data centers</Title>
                        <Description>An overview of all data centers available for this organization</Description>
                    </MainWrapper>
                    <SwitchWrapper>
                        <Button startIcon={<Add />} color="secondary" onClick={() => setOpenAssignDialog(true)}>
                            Add
                        </Button>
                    </SwitchWrapper>
                </InnerWrapper>
                <Content>
                    {datacenters.length === 0 ? (
                        <>No data centers connected yet</>
                    ) : (
                        <>
                            {datacenters.map((datacenter, idx) => (
                                <OrganizationDatacenterItem
                                    datacenter={datacenter}
                                    key={`dc-${idx}`}
                                    onRemove={() => removeDC(datacenter)}
                                />
                            ))}
                        </>
                    )}
                </Content>
            </Wrapper>
            {data && (
                <AddDatacenterToOrganizationDialog
                    organizationId={organization.organizationId}
                    onClose={() => setOpenAssignDialog(false)}
                    open={openAssignDialog}
                    alreadyConnectedIds={datacenters.map((dc) => dc.id)}
                    allDatacenters={data.getAllDataCenters.map((dc) => ({
                        id: dc.id,
                        name: dc.name,
                    }))}
                />
            )}

            {activeDC && (
                <RemoveDataCenterDialog
                    datacenterId={activeDC.id}
                    organizationId={organization.organizationId}
                    open={openRemoveDialog}
                    onClose={() => setOpenRemoveDialog(false)}
                />
            )}
        </>
    );
}

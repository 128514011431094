import { Grid } from "@mui/material";
import * as React from "react";
import CardHESP from "../../CardHESP/CardHESP";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import ChannelAnalyticsItem from "./ChannelAnalyticsItem";
import { PeopleOutline, Schedule } from "@mui/icons-material";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChannelSchedulersDialog from "./ChannelSchedulersDialog";

interface Props {
    channel: GetChannelQuery_channel;
}

function ChannelAnalytics({ channel }: Props) {
    const { channelId, organizationId } = channel;

    const [openSchedulers, setOpenSchedulers] = React.useState<boolean>(false);

    return (
        <>
            <CardHESP>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ChannelAnalyticsItem
                            title={"Channel usage"}
                            description={"Transcoded and viewed minutes of this channel and per alias"}
                            icon={<BarChartOutlinedIcon color={"secondary"} style={{ fontSize: "28px" }} />}
                            onClick={() => window.open(`/app/${organizationId}/channels/${channelId}/usage`)}
                        />
                        <ChannelAnalyticsItem
                            title={"Viewer insights"}
                            description={"Real-time insights on viewers, latency, regions and systems"}
                            icon={<PeopleOutline color={"secondary"} style={{ fontSize: "28px" }} />}
                            onClick={() => window.open(`/app/${organizationId}/channels/${channelId}/insights`)}
                        />
                        <ChannelAnalyticsItem
                            title={"Ingest health"}
                            description={"Real-time information about ingested bitrate and framerate"}
                            icon={<MonitorHeartOutlinedIcon color={"secondary"} style={{ fontSize: "28px" }} />}
                            onClick={() => window.open(`/app/${organizationId}/channels/${channelId}/ingest-health`)}
                        />
                        <ChannelAnalyticsItem
                            title={"Channel runs"}
                            description={"Overview of all the runs the channel had"}
                            icon={<BarChartOutlinedIcon color={"secondary"} style={{ fontSize: "28px" }} />}
                            onClick={() => window.open(`/app/${organizationId}/channels/${channelId}/runs`)}
                        />
                        {channel.schedulers.length > 0 && (
                            <ChannelAnalyticsItem
                                title={"Schedules"}
                                description={"See when the channel is scheduled to start"}
                                icon={<Schedule color={"secondary"} style={{ fontSize: "28px" }} />}
                                onClick={() => setOpenSchedulers(true)}
                            />
                        )}
                    </Grid>
                </Grid>
            </CardHESP>
            <ChannelSchedulersDialog open={openSchedulers} onClose={() => setOpenSchedulers(false)} channel={channel} />
        </>
    );
}

export default ChannelAnalytics;

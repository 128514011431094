import {
    Alert,
    Button,
    ButtonGroup,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    Grid,
    Switch,
    TextField,
    Tooltip,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import { OverageType } from "../../__generated__/globalTypes";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import styled from "styled-components";
import { Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import {
    CreateContractForOrganizationMutation,
    CreateContractForOrganizationMutationVariables,
} from "./__generated__/CreateContractForOrganizationMutation";
import { GET_FULL_BILLING_DETAILS } from "../BillingDetails/BillingDetailsCardContent";
import { GetFullBillingDetails_organization } from "../BillingDetails/__generated__/GetFullBillingDetails";
import { GET_ORGANIZATIONS_QUERY } from "../OrganizationsOverview/OrganizationsOverview";
import { HESPlink } from "../Landing/SignUpComplete";
import { DatePicker } from "@mui/x-date-pickers";

export const PRICE_PER_MINUTE_TRANSCODED = 0.07;
export const PRICE_PER_MINUTE_VIEWED = 0.0015;
export const PRICE_PER_DRM_MINUTE_VIEWED = 0.0005;

export const CREATE_CONTRACT_FOR_ORGANIZATION_MUTATION = gql`
    mutation CreateContractForOrganizationMutation($input: CreateContractInput!) {
        createContractForOrganization(input: $input) {
            organizationId
        }
    }
`;

interface Props {
    organization: GetFullBillingDetails_organization;
    onClose: () => void;
}

interface InputValues {
    startDate: moment.Moment;
    durationInMonths: number;
    commitment: number;
    overageType: OverageType;
    billAutomatically: boolean;
    billOveragesAutomatically: boolean;
    billInGb: boolean;
    gbUsedPerMinute: number;
    hasAccessToSD: boolean;
}

const Error = styled.div`
    margin-top: 5px;
    color: ${(props) => props.theme.error};
    font-size: 11px;
`;

// const CurrentPrice = styled.div`
//     margin-top: 5px;
//     color: ${(props) => props.theme.gray};
//     font-size: 11px;
// `;

const SwitchTitle = styled.span`
    font-size: 15px;
`;

function CreateCommitmentContract({ organization, onClose }: Props) {
    const [inputValues, setInputValues] = React.useState<InputValues>({
        startDate: moment().utc().startOf("day"),
        durationInMonths: 12,
        overageType: OverageType.yearly,
        commitment: 1,
        billAutomatically: false,
        billOveragesAutomatically: false,
        billInGb: false,
        gbUsedPerMinute: 0,
        hasAccessToSD: false,
    });

    const { organizationId, billingDetails } = organization;

    const {
        startDate,
        durationInMonths,
        overageType,
        commitment,
        billAutomatically,
        billOveragesAutomatically,
        billInGb,
        hasAccessToSD,
        gbUsedPerMinute,
    } = inputValues;

    const [createContractMut, { loading }] = useMutation<
        CreateContractForOrganizationMutation,
        CreateContractForOrganizationMutationVariables
    >(CREATE_CONTRACT_FOR_ORGANIZATION_MUTATION);

    function isValidInput() {
        if (durationInMonths < 1) {
            return false;
        }

        return true;
    }

    async function onCreate() {
        if (!isValidInput()) {
            toast.error("Invalid input", TOAST_SETTINGS);
        }
        try {
            await createContractMut({
                variables: {
                    input: {
                        organizationId,
                        commitment,
                        start: startDate.utc().startOf("day").toISOString(),
                        durationInMonths,
                        overageType,
                        billAutomatically,
                        billOveragesAutomatically,
                        billInGb,
                        hasAccessToSD,
                        gbUsedPerMinute,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_FULL_BILLING_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                    {
                        query: GET_ORGANIZATIONS_QUERY,
                    },
                ],
            });
            onClose();
        } catch (_e) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
    }

    const mrr = Math.round((commitment / durationInMonths) * 100) / 100;
    return (
        <>
            <DialogContent style={{ overflowY: "hidden", marginTop: "20px" }}>
                <Grid container spacing={2}>
                    {!billingDetails && (billAutomatically || billOveragesAutomatically) && (
                        <Grid item xs={12}>
                            <Alert severity="warning">
                                This organization has no billing details filled in. You can't assign a contract right
                                now. You can fill in the billing details{" "}
                                <HESPlink link={`/app/${organizationId}/billing`}>here</HESPlink>.
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <HESPFormLabel label="Start date" />
                            <DatePicker
                                openTo="day"
                                format="YYYY/MM/DD"
                                views={["day"]}
                                value={startDate}
                                onChange={(newValue: moment.Moment | null) => {
                                    if (newValue !== null) {
                                        setInputValues({
                                            ...inputValues,
                                            startDate: newValue,
                                        });
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <HESPFormLabel label="Duration (months)" />
                            <TextField
                                type="number"
                                value={durationInMonths}
                                onChange={(e) =>
                                    setInputValues({
                                        ...inputValues,
                                        durationInMonths: parseInt(e.target.value),
                                    })
                                }
                                fullWidth
                                InputProps={{ inputProps: { min: 1, max: 100 } }}
                            />
                            {durationInMonths < 1 && <Error>Duration should be at least one month</Error>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <HESPFormLabel label="Commitment in USD" />
                            <TextField
                                type="number"
                                value={commitment}
                                onChange={(e) =>
                                    setInputValues({
                                        ...inputValues,
                                        commitment: parseInt(e.target.value),
                                    })
                                }
                                fullWidth
                                InputProps={{ inputProps: { min: 1 } }}
                            />
                            {commitment < 1 && <Error>Commitment should be higher than $1</Error>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <HESPFormLabel label="MRR in USD" />
                            <TextField type="number" value={mrr} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <HESPFormLabel label="Overage calculation" />
                            <ButtonGroup color="secondary">
                                <Tooltip
                                    title={`Each month, we'll see if their monthly usage passed the MRR of $${mrr}. If so, an overage invoice will be sent`}
                                >
                                    <Button
                                        variant={overageType === OverageType.monthly ? "contained" : "outlined"}
                                        onClick={(_e) =>
                                            setInputValues({
                                                ...inputValues,
                                                overageType: OverageType.monthly,
                                            })
                                        }
                                        startIcon={
                                            overageType === OverageType.monthly && <Check style={{ color: "white" }} />
                                        }
                                    >
                                        Month
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title={`Each month, we'll see if their total usage passed the commitment of $${commitment}. If so, an overage invoice will be sent`}
                                >
                                    <Button
                                        variant={overageType === OverageType.yearly ? "contained" : "outlined"}
                                        onClick={(_e) =>
                                            setInputValues({
                                                ...inputValues,
                                                overageType: OverageType.yearly,
                                            })
                                        }
                                        startIcon={
                                            overageType === OverageType.yearly && <Check style={{ color: "white" }} />
                                        }
                                    >
                                        Annual
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <HESPFormLabel label="Invoice generation" />
                        <FormControl style={{ display: "flex", flexDirection: "row" }}>
                            <Switch
                                color="primary"
                                size="small"
                                checked={billAutomatically}
                                onChange={() =>
                                    setInputValues({
                                        ...inputValues,
                                        billAutomatically: !billAutomatically,
                                    })
                                }
                            />{" "}
                            <SwitchTitle> Bill monthly invoices automatically</SwitchTitle>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ display: "flex", flexDirection: "row" }}>
                            <Switch
                                color="primary"
                                size="small"
                                checked={billOveragesAutomatically}
                                onChange={() =>
                                    setInputValues({
                                        ...inputValues,
                                        billOveragesAutomatically: !billOveragesAutomatically,
                                    })
                                }
                            />{" "}
                            <SwitchTitle> Bill overage invoices automatically</SwitchTitle>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <HESPFormLabel label="Invoice calculation in GB used" />
                        <FormControl style={{ display: "flex", flexDirection: "row" }}>
                            <Switch
                                color="primary"
                                size="small"
                                checked={billInGb}
                                onChange={() =>
                                    setInputValues({
                                        ...inputValues,
                                        billInGb: !billInGb,
                                    })
                                }
                            />{" "}
                            <SwitchTitle>Convert minutes to GB</SwitchTitle>
                        </FormControl>
                    </Grid>
                    {billInGb && (
                        <Grid item xs={6}>
                            <HESPFormLabel label="GB per minute used" description="Conversion at 1 Mbit per second" />
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    value={gbUsedPerMinute}
                                    onChange={(e) =>
                                        setInputValues({
                                            ...inputValues,
                                            gbUsedPerMinute: parseFloat(e.target.value),
                                        })
                                    }
                                    fullWidth
                                    InputProps={{ inputProps: { min: 0.0, max: 10.0, step: 0.00001 } }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <HESPFormLabel label="Access to Service Desk" />
                        <FormControl style={{ display: "flex", flexDirection: "row" }}>
                            <Switch
                                color="primary"
                                size="small"
                                checked={hasAccessToSD}
                                onChange={() =>
                                    setInputValues({
                                        ...inputValues,
                                        hasAccessToSD: !hasAccessToSD,
                                    })
                                }
                            />{" "}
                            <SwitchTitle>Organization has access to Service Desk</SwitchTitle>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <DialogFooterWithLoading
                    isLoading={loading}
                    disabled={!isValidInput() || (!billingDetails && (billAutomatically || billOveragesAutomatically))}
                    onCancelClick={onClose}
                    defaultText={"Create"}
                    loadingText={"Creating..."}
                    onActionClick={onCreate}
                />
            </DialogActions>
        </>
    );
}

export default CreateCommitmentContract;

import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles";
// eslint-disable-next-line import/no-unresolved
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "styled-components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import createApolloClient from "./apollo/createApolloClient";
import muiTheme from "./styles/createMuiTheme";

import "./styles/fonts.css";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routes";

export const apolloClient = createApolloClient();

export const STYLED_THEME = {
    primary: "#FFC713",
    primaryLight: "#fff9e7",
    secondary: "#344a5e",
    warning: "#f79f11",
    warningLight: "#ffeed4",
    success: "#9cdbad",
    successLight: "#c5ebcf",
    error: "#ef5350",
    info: "#3279a8",
    infoLight: "#EAF1F6",
    errorLight: "#edb9b9",
    grayedOut: "#bfbfbf",
    lightBlue: "#9CB9C9",
    lighterBlue: "#dde7ed",
    lightBlueBackground: "#f6f8fa",
    gray: "#8c8c8c",
    lightGrayBottom: "rgba(0, 0, 0, 0.12)",
    grayBackground: "#fcfcfc",
};

export default function AppWrapper() {
    React.useEffect(() => {
        // eslint-disable-next-line no-undef
        window.scrollTo(0, 0);
    }, []);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

    return (
        <MaterialThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={STYLED_THEME}>
                    <Elements stripe={stripePromise}>
                        <ApolloProvider client={apolloClient}>
                            <CssBaseline />
                            <Routes />
                        </ApolloProvider>
                    </Elements>
                </ThemeProvider>
            </LocalizationProvider>
        </MaterialThemeProvider>
    );
}

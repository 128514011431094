import { Check } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    tooltip: string;
    selected?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

const Wrapper = styled.div.attrs((props: { selected: boolean; disabled: boolean }) => ({
    selected: props.selected,
    disabled: props.disabled,
}))`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    width: 80px;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: ${(props) => (props.disabled === true ? "not-allowed" : "pointer")};
    font-size: 12px;
    border: 1px solid ${(props) => (props.selected === true ? props.theme.primary : props.theme.lightGrayBottom)};
    margin-right: 16px;
    background-color: ${(props) =>
        props.selected === true ? "#FFF3CF" : props.disabled === true ? "#f5f5f5" : "white"};
`;

export default function ChannelIngestSettingsSelectorItem({ onClick, selected, title, disabled, tooltip }: Props) {
    return (
        <Tooltip title={tooltip}>
            <Wrapper
                selected={selected === true}
                onClick={() => (disabled === true ? null : onClick())}
                disabled={disabled === true}
            >
                {selected === true && <Check color="primary" style={{ fontSize: "15px", marginRight: "6px" }} />}
                {title}
            </Wrapper>
        </Tooltip>
    );
}

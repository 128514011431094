import { Grid } from "@mui/material";
import * as React from "react";
import DataItem from "../DataItem/DataItem";
import ModeBadge from "../ModeBadge/ModeBadge";
import styled from "styled-components";
import AuthService from "../../auth/AuthService";
import ChangeContractDialog from "./ChangeContractDialog";
import ContractDangerZone from "./ContractDangerZone";
import ChangePaygPricingDialog from "./ChangePaygPricingDialog";
import { ToastContainer } from "react-toastify";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";

interface Props {
    organization: GetFullOrganizationDetails_organization;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    align-items: center;
`;

function ContractPAYG({ organization }: Props) {
    const [openChangeContract, setOpenChangeContract] = React.useState<boolean>(false);
    const [openChangePaygPricingDialog, setOpenChangePaygPricingDialog] = React.useState<boolean>(false);
    const { contractType, paygPricing } = organization;
    const isTHEOAdminSales = AuthService.isTHEOAdminSales();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataItem
                        title="Contract Type"
                        editable={isTHEOAdminSales}
                        onEditClick={() => setOpenChangeContract(true)}
                        value={
                            <Wrapper>
                                <ModeBadge mode={contractType} />
                            </Wrapper>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DataItem
                        title="Transcoding cost per minute"
                        editable={isTHEOAdminSales}
                        onEditClick={() => setOpenChangePaygPricingDialog(true)}
                        value={`$${paygPricing.transcodingCost}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DataItem
                        title="Viewing cost per minute"
                        editable={isTHEOAdminSales}
                        onEditClick={() => setOpenChangePaygPricingDialog(true)}
                        value={`$${paygPricing.viewingCost}`}
                    />
                </Grid>
                {isTHEOAdminSales && (
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <ContractDangerZone organization={organization} />
                    </Grid>
                )}
            </Grid>
            <ChangeContractDialog
                open={openChangeContract}
                onClose={() => setOpenChangeContract(false)}
                organization={organization}
            />
            <ChangePaygPricingDialog
                open={openChangePaygPricingDialog}
                onClose={() => setOpenChangePaygPricingDialog(false)}
                organization={organization}
            />
            <ToastContainer />
        </>
    );
}

export default ContractPAYG;

import * as React from "react";
import { GetWebhookLogsQuery_webhookLogs_logs } from "./__generated__/GetWebhookLogsQuery";
import styled from "styled-components";
import WebhookHistoryDataOverviewItem from "./WebhookHistoryDataOverviewItem";
import { Button } from "@mui/material";

interface Props {
    showMore: boolean;
    onShowMoreClick: () => void;
    logs: GetWebhookLogsQuery_webhookLogs_logs[];
    selectedLog: GetWebhookLogsQuery_webhookLogs_logs;
    onChangeSelectedLog: (webhook: GetWebhookLogsQuery_webhookLogs_logs) => void;
}

const Wrapper = styled.div`
    max-height: 600px;
    overflow-y: auto;
    margin: 10px 20px;
`;

const ShowMoreWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
`;

function WebhookHistoryDataOverview({ logs, onChangeSelectedLog, onShowMoreClick, selectedLog, showMore }: Props) {
    return (
        <Wrapper>
            {logs.map((l, i) => (
                <WebhookHistoryDataOverviewItem
                    onClick={() => onChangeSelectedLog(l)}
                    key={`log-${i}`}
                    log={l}
                    selected={l.timestamp === selectedLog.timestamp}
                />
            ))}
            {showMore && (
                <ShowMoreWrapper>
                    <Button color="secondary" onClick={onShowMoreClick}>
                        Show more
                    </Button>
                </ShowMoreWrapper>
            )}
        </Wrapper>
    );
}

export default WebhookHistoryDataOverview;

import * as React from "react";
import EventItem from "./EventItem";
import { GetEventsQuery_events_events } from "./__generated__/GetEventsQuery";

interface Props {
    events: GetEventsQuery_events_events[];
    organizationId: string;
}

export default function EventsTable({ events, organizationId }: Props) {
    return (
        <>
            {events.map((e, i) => (
                <EventItem event={e} organizationId={organizationId} key={`event-${i}`} />
            ))}
        </>
    );
}

import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { useQuery } from "react-apollo";
import CardHESP from "../../components/CardHESP/CardHESP";
import { SkeletonRectangle } from "../../components/Loading/Skeletons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { GET_WEBHOOK_QUERY } from "../../components/WebhookDetails/WebhookDetails";
import {
    GetWebhookQuery,
    GetWebhookQueryVariables,
} from "../../components/WebhookDetails/__generated__/GetWebhookQuery";
import EditWebhookForm from "../../components/WebhooksOverview/EditWebhookForm";

interface Props extends RouteComponentProps {
    webhookId?: string;
    organizationId?: string;
}

function EditWebhook({ webhookId, organizationId }: Props) {
    const { data, loading } = useQuery<GetWebhookQuery, GetWebhookQueryVariables>(GET_WEBHOOK_QUERY, {
        variables: {
            webhookId: webhookId!,
            organizationId: organizationId!,
        },
    });

    if (loading) {
        return (
            <>
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
            </>
        );
    }

    if (!data) {
        return <div>An error occurred</div>;
    }

    const { webhook } = data;

    return (
        <>
            <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
                <Grid item xs={12}>
                    <SectionTitle title="Update webhook" align="left" showBottomBorder />
                    <CardHESP>
                        <EditWebhookForm webhook={webhook} />
                    </CardHESP>
                </Grid>
            </Grid>
        </>
    );
}

export default EditWebhook;

import * as React from "react";
import { EventFiltersArgs } from "./EventsOverview";
import styled from "styled-components";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { Button, Chip, FormControl, Grid, Switch, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetAllEventsQuery } from "./__generated__/GetAllEventsQuery";
import { Check, Clear } from "@mui/icons-material";

interface Props {
    filters: EventFiltersArgs;
    onApplyFilters: (filters: EventFiltersArgs) => void;
    hideObjectIds?: boolean;
    hideDateSelectors?: boolean;
    typesFilter?: "all" | "scheduler";
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 22px;
    // background-color: ${(props) => props.theme.grayBackground};
    margin-bottom: 16px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 8px;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: bolder;
    color: black;
`;

const Filters = styled.div`
    margin: 12px 0px;
`;

const SwitchTitle = styled.span`
    font-size: 15px;
`;

const AllEventsWrapper = styled.div`
    margin-top: 30px;
`;

const BadgeWrapper = styled.div`
    margin: 7px;
    margin-right: 10px;
`;

export const GET_ALL_EVENTS = gql`
    query GetAllEventsQuery($sub: String) {
        possibleEvents(sub: $sub) {
            type
            subsetType
        }
    }
`;

export default function EventsFilters({
    filters,
    onApplyFilters,
    hideObjectIds,
    hideDateSelectors,
    typesFilter,
}: Props) {
    const [startDate, setStartDate] = React.useState<string>(filters.startDate);
    const [endDate, setEndDate] = React.useState<string>(filters.endDate);
    const [objectId, setObjectId] = React.useState<string | undefined>(filters.objectId);
    const [types, setTypes] = React.useState<string[] | undefined>(filters.types);
    const [allTypes, setAllTypes] = React.useState<boolean>(typeof types === "undefined");

    const { data, loading } = useQuery<GetAllEventsQuery>(GET_ALL_EVENTS, {
        ...(typesFilter && {
            variables: {
                sub: typesFilter !== "all" ? typesFilter : undefined,
            },
        }),
    });

    return (
        <Wrapper>
            <Title>Filters</Title>
            <Filters>
                <Grid container spacing={2}>
                    {hideDateSelectors !== true && (
                        <>
                            <Grid item xs={12} sm={6} lg={3}>
                                <HESPFormLabel label="Start" />
                                <FormControl fullWidth>
                                    <DateTimePicker
                                        format="YYYY/MM/DD HH:mm"
                                        ampm={false}
                                        value={moment(startDate)}
                                        minDateTime={moment().utc().subtract(31, "days").startOf("day")}
                                        onChange={(newValue: moment.Moment | null) => {
                                            if (newValue !== null) {
                                                setStartDate(newValue.startOf("minute").toISOString());
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <HESPFormLabel label="End" />
                                <FormControl fullWidth>
                                    <DateTimePicker
                                        format="YYYY/MM/DD HH:mm"
                                        ampm={false}
                                        value={moment(endDate)}
                                        minDate={moment(startDate)}
                                        minTime={
                                            moment(startDate).date() === moment(endDate).date()
                                                ? moment(startDate)
                                                : undefined
                                        }
                                        onChange={(newValue: moment.Moment | null) => {
                                            if (newValue !== null) {
                                                setEndDate(newValue.startOf("minute").toISOString());
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {hideObjectIds !== true && (
                        <Grid item xs={7}>
                            <HESPFormLabel label="Object ID (channel ID, alias ID, ...)" />
                            <TextField
                                fullWidth
                                placeholder="Object ID"
                                size="small"
                                onChange={(e) => setObjectId(e.target.value.trim() === "" ? undefined : e.target.value)}
                                style={{ backgroundColor: "white" }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <HESPFormLabel label="Events to show" />
                        <Switch
                            size="small"
                            checked={allTypes}
                            onChange={() => {
                                setTypes([]);
                                setAllTypes(!allTypes);
                            }}
                        />{" "}
                        <SwitchTitle>All events</SwitchTitle>
                        {!allTypes && types && (
                            <AllEventsWrapper>
                                {!loading && data && (
                                    <Grid container spacing={2}>
                                        {data.possibleEvents.map((e, i) => (
                                            <BadgeWrapper key={`event-${i}`}>
                                                <Chip
                                                    style={{
                                                        padding: "6px",
                                                        height: "30px",
                                                        cursor: "pointer",
                                                        backgroundColor: types.includes(e.type) ? "#dde7ed" : "",
                                                    }}
                                                    size="small"
                                                    label={e.type}
                                                    onClick={() => {
                                                        if (types.includes(e.type)) {
                                                            setTypes(types.filter((t) => t !== e.type));
                                                        } else {
                                                            setTypes([...types, e.type]);
                                                        }
                                                    }}
                                                    icon={types.includes(e.type) ? <Check /> : <Clear />}
                                                />
                                            </BadgeWrapper>
                                        ))}
                                    </Grid>
                                )}
                            </AllEventsWrapper>
                        )}
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                                onApplyFilters({
                                    endDate,
                                    startDate,
                                    objectId,
                                    types: allTypes ? undefined : types,
                                })
                            }
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Filters>
        </Wrapper>
    );
}

import * as React from "react";
import { useForm } from "react-hook-form";
import { DialogContent, Grid, Typography, FormControl, TextField, DialogActions } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import { useMutation } from "@apollo/react-hooks";
import {
    CreateChannelAliasMutation,
    CreateChannelAliasMutationVariables,
} from "./__generated__/CreateChannelAliasMutation";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import gql from "graphql-tag";

export const CREATE_CHANNEL_ALIAS_MUTATION = gql`
    mutation CreateChannelAliasMutation($input: CreateChannelAliasInput!) {
        createChannelAlias(input: $input) {
            channelId
        }
    }
`;

interface Props {
    open: boolean;
    channel: GetChannelQuery_channel;
    onClose: () => void;
    onSuccess: (aliasId: string) => void;
    onError: () => void;
}

interface FormData {
    name: string;
}

function ChannelCreateAliasDialog({ open, channel, onClose, onSuccess, onError }: Props) {
    const { handleSubmit, register, errors } = useForm<FormData>();

    const [createChannelAliasMut, { loading }] = useMutation<
        CreateChannelAliasMutation,
        CreateChannelAliasMutationVariables
    >(CREATE_CHANNEL_ALIAS_MUTATION);

    const { channelId } = channel;

    const createChannelAlias = handleSubmit(async ({ name }: FormData) => {
        try {
            const res = await createChannelAliasMut({
                variables: { input: { channelId, name, organizationId: channel.organizationId } },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            });
            onSuccess(res.data!.createChannelAlias!.channelId);
        } catch (e: any) {
            onError();
        }
        onClose();
    });

    return (
        <DialogHESP
            title={"Create channel alias"}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit((_ignore, e) => createChannelAlias(e))}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Name" />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="name"
                                        inputRef={register({
                                            required: "Name is required",
                                        })}
                                    />
                                    <Typography variant="subtitle2" color="error">
                                        {errors.name && errors.name.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Create"}
                            loadingText={"Create..."}
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default ChannelCreateAliasDialog;

import * as React from "react";

import { Grid, FormControl, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { DeepMap, FieldError } from "react-hook-form";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import AuthService from "../../auth/AuthService";

export const CancelLink = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.gray};
    text-align: right;
`;

export interface ProfileUpdateFormData {
    firstName: string;
    lastName: string;
    phone: string;
}

interface Props {
    onCancel: () => void;
    errors: DeepMap<ProfileUpdateFormData, FieldError>;
    register: any;
}

function PersonalInfoEdit({ onCancel, errors, register }: Props) {
    const userProfile = AuthService.getLocalUserProfile();

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <FormControl fullWidth>
                    <HESPFormLabel label={"First name"} />
                    <TextField
                        variant="outlined"
                        fullWidth
                        name="firstName"
                        defaultValue={userProfile?.firstName}
                        type="text"
                        inputRef={register({
                            required: "First Name is required",
                        })}
                    />
                    <Typography variant="subtitle2" color="error">
                        {errors.firstName?.message}
                    </Typography>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <HESPFormLabel label={"Last name"} />
                    <TextField
                        variant="outlined"
                        fullWidth
                        name="lastName"
                        defaultValue={userProfile?.lastName}
                        type="text"
                        inputRef={register({
                            required: "Last Name is required",
                        })}
                    />
                    <Typography variant="subtitle2" color="error">
                        {errors.lastName?.message}
                    </Typography>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <HESPFormLabel label={"Phone number"} />
                    <TextField
                        variant="outlined"
                        fullWidth
                        name="phone"
                        defaultValue={userProfile?.phone}
                        type="text"
                        inputRef={register({
                            required: "Phone number is required",
                            pattern: {
                                value: /^[0-9+]+$/,
                                message: "invalid phone number (only numbers and + sign)",
                            },
                        })}
                    />
                    <Typography variant="subtitle2" color="error">
                        {errors.phone?.message}
                    </Typography>
                </FormControl>
            </Grid>

            <Grid item style={{ textAlign: "right" }}>
                <CancelLink
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                    }}
                >
                    Cancel
                </CancelLink>
            </Grid>
        </Grid>
    );
}

export default PersonalInfoEdit;

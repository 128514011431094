import * as React from "react";
import { GetFullBillingDetails_organization_contracts } from "../BillingDetails/__generated__/GetFullBillingDetails";
import DialogHESP from "../DialogHESP/DialogHESP";
import { DialogActions, DialogContent, FormControl, Grid, Switch, TextField } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { UpdateContractMutation, UpdateContractMutationVariables } from "./__generated__/UpdateContractMutation";
import { toast } from "react-toastify";
import { GET_FULL_BILLING_DETAILS } from "../BillingDetails/BillingDetailsCardContent";

interface Props {
    open: boolean;
    onClose: () => void;
    contract: GetFullBillingDetails_organization_contracts;
    organizationId: string;
}

const SwitchTitle = styled.span`
    font-size: 15px;
`;

export const UPDATE_CONTRACT_MUTATION = gql`
    mutation UpdateContractMutation($input: UpdateContractInput!) {
        updateContractForOrganization(input: $input) {
            organizationId
        }
    }
`;

export default function EditContractGbDialog({ contract, onClose, open, organizationId }: Props) {
    const { gbUsedPerMinute: initGbUsed, billInGb: initBillInGb } = contract;

    const [billInGb, setBillInGb] = React.useState<boolean>(initBillInGb);

    const [gbUsedPerMinute, setGbUsedPerMinute] = React.useState<number>(initGbUsed);

    const [updateContractMut, { loading }] = useMutation<UpdateContractMutation, UpdateContractMutationVariables>(
        UPDATE_CONTRACT_MUTATION,
    );

    async function onSave() {
        try {
            await updateContractMut({
                variables: {
                    input: {
                        billInGb,
                        contractId: contract.id,
                        gbUsedPerMinute,
                        organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_FULL_BILLING_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });

            toast.success("Contract successfully updated");
            onClose();
        } catch (_e) {
            toast.error("Something went wrong");
        }
    }

    return (
        <DialogHESP
            title={"Change billing in GB settings"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent style={{ marginTop: "20px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <HESPFormLabel label="Invoice calculation in GB used" />
                                <FormControl style={{ display: "flex", flexDirection: "row" }}>
                                    <Switch
                                        color="primary"
                                        size="small"
                                        checked={billInGb}
                                        onChange={() => setBillInGb(!billInGb)}
                                    />{" "}
                                    <SwitchTitle>Convert minutes to GB</SwitchTitle>
                                </FormControl>
                            </Grid>
                            {billInGb && (
                                <Grid item xs={6}>
                                    <HESPFormLabel
                                        label="GB per minute used"
                                        description="Conversion at 1 Mbit per second"
                                    />
                                    <FormControl fullWidth>
                                        <TextField
                                            type="number"
                                            value={gbUsedPerMinute}
                                            onChange={(e) => setGbUsedPerMinute(parseFloat(e.target.value))}
                                            fullWidth
                                            InputProps={{ inputProps: { min: 0.0, max: 10.0, step: 0.00001 } }}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onSave}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

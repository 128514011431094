import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    icon: React.ReactNode;
    onClick: () => void;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
    margin-bottom: 10px;
`;

const Icon = styled.div`
    margin-right: 10px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 8px;
    background-color: ${(props) => props.theme.lighterBlue};
    height: 25px;
    width: 28px;
`;

const Title = styled.div`
    font-size: 13px;
    font-weight: bolder;
`;

export default function ChannelsOverviewFilterItem({ title, icon, onClick }: Props) {
    return (
        <Wrapper onClick={onClick}>
            <Icon>{icon}</Icon>
            <Title>{title}</Title>
        </Wrapper>
    );
}

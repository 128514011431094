import * as React from "react";

import { Divider, Paper, Typography } from "@mui/material";
import moment from "moment";
import { IngestHealthType } from "./IngestHealthGraph";

export interface Props {
    payload?: any;
    label?: string;
    title?: string;
    unit?: string;
    unitValue?: string;
    active?: boolean;
    type: IngestHealthType;
}

const InsightsHealthTooltip = ({ label, payload = [], unit, unitValue, active, type }: Props) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (active && payload && payload.length > 0) {
        return (
            <Paper style={{ opacity: 0.95, padding: "5px", display: "flex", flexDirection: "column" }}>
                <Typography variant="body1" style={{ fontSize: "14px", paddingBottom: "5px" }}>{`${moment(label)
                    .tz(tz)
                    .format("HH:mm:ss")}`}</Typography>
                <Divider />
                <Typography variant="caption" style={{ color: "#FFC713", paddingTop: "5px" }}>
                    Audio: {convertToKbpsIfNeeded(payload[0]?.value ?? 0, type)} {type === "bitrate" ? "kbps" : "fps"}
                </Typography>
                <Typography variant="caption" style={{ color: "#9CB9C9", paddingTop: "5px" }}>
                    Video: {payload[1]?.value ?? 0} {type === "bitrate" ? "Mbps" : "fps"}
                </Typography>
            </Paper>
        );
    }

    return null;
};

function convertToKbpsIfNeeded(amount: number, type: IngestHealthType) {
    if (type === "bitrate") {
        return amount * 1000;
    }
    return amount;
}

export default InsightsHealthTooltip;

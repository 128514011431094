/* eslint-disable no-undef */
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TLAContent from "../../components/TLA/TLAContent";

interface Props extends RouteComponentProps {
    path?: string;
}

// eslint-disable-next-line no-empty-pattern
export default function TLA({}: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`THEOlive Anywhere`} align={"left"} showBottomBorder />
                <TLAContent />
            </Grid>
        </Grid>
    );
}

import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div.attrs((props: { selected: boolean; disabled: boolean }) => ({
    selected: props.selected,
    disabled: props.disabled,
}))`
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid ${(props) => (props.selected ? props.theme.primary : props.theme.lightGrayBottom)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: white;
`;

const Value = styled.div.attrs((props: { disabled: boolean }) => ({
    disabled: props.disabled,
}))`
    font-size: 16px;
    color: ${(props) => (props.disabled ? props.theme.grayedOut : "black")};
    display: flex;
    align-items: center;
    margin-left: 30px;
`;
const RadioButtonWrapper = styled.div`
    width: 20px;
    padding-top: 8px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RadioButtonDiv = styled.div.attrs((props: { disabled: boolean }) => ({
    disabled: props.disabled,
}))`
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;

interface Props {
    value: string;
    selected: boolean;
    disabled: boolean;
    onClick: () => void;
}

export default function ChannelStreamConfiguratorPartItem({ onClick, selected, value, disabled }: Props) {
    return (
        <Wrapper onClick={() => (disabled ? null : onClick())} selected={selected} disabled={disabled}>
            <RadioButtonWrapper>
                <RadioButtonDiv>
                    {selected ? (
                        <RadioButtonChecked color="primary" />
                    ) : (
                        <RadioButtonUnchecked color={disabled ? "disabled" : "primary"} />
                    )}
                </RadioButtonDiv>
            </RadioButtonWrapper>
            <Value disabled={disabled}>{value}</Value>
        </Wrapper>
    );
}

import { Grid } from "@mui/material";
import { GetOrganizationBasics_organization } from "../../GetStarted/__generated__/GetOrganizationBasics";
import CardHESP from "../../CardHESP/CardHESP";
import styled from "styled-components";
import React from "react";
import MaintenanceManagement, { MaintenanceSettings } from "./MaintenanceManagement";
import { MaintenanceRecurringPeriodType, MaintenanceType } from "../../../__generated__/globalTypes";

interface Props {
    organization: GetOrganizationBasics_organization;
}

const InfoMessage = styled.div`
    line-height: 1.5em;
    font-size: 15px;
    margin-bottom: 10px;
`;

const P = styled.div`
    margin-bottom: 6px;
`;

export default function SettingsMaintenanceWindowContent({ organization }: Props) {
    const sorter: Record<string, number> = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
    };

    const initValues: MaintenanceSettings = {
        type: organization.channelSettings?.perpetual?.maintenance?.type ?? MaintenanceType.scheduled,
        slots:
            organization.channelSettings?.perpetual?.maintenance?.slots &&
            organization.channelSettings?.perpetual?.maintenance?.slots.length > 0
                ? organization.channelSettings?.perpetual?.maintenance?.slots.sort((a, b) =>
                      a.day === b.day ? (a.time < b.time ? -1 : 1) : sorter[a.day] - sorter[b.day],
                  )
                : [{ day: "Monday", time: "10:00" }],
        interval:
            organization.channelSettings?.perpetual?.maintenance?.interval !== null &&
            organization.channelSettings?.perpetual?.maintenance?.interval
                ? organization.channelSettings?.perpetual?.maintenance?.interval
                : {
                      amount: 3,
                      period: MaintenanceRecurringPeriodType.days,
                      time: "10:00",
                  },
    };

    const { hasMaintenanceSet } = organization;

    return (
        <div style={{ marginTop: "8px" }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CardHESP>
                        <InfoMessage>
                            <P>
                                THEOlive offers you to have 24/7 channels. However, due to continuous updates of our
                                systems, we require to restart your channels from time to time to make are sure you are
                                running our the latest and best stack. We therefore allow you to set some{" "}
                                <strong>maintenance windows</strong>.{" "}
                            </P>
                            <P>
                                During these windows, THEOlive will automatically stop and restart your channels in
                                order to upgrade them to the latest versions.
                            </P>
                            {!hasMaintenanceSet && (
                                <P>
                                    <strong>
                                        Currently, no maintenance window has been set. Please update the settings below
                                        and hit save.
                                    </strong>
                                </P>
                            )}
                        </InfoMessage>
                        <MaintenanceManagement
                            initValues={initValues}
                            objectId={organization.organizationId}
                            type="organization"
                        />
                    </CardHESP>
                </Grid>
            </Grid>
        </div>
    );
}

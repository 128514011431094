import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import styled from "styled-components";
import atomOneLight from "react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light";
import { copyToClipboard } from "../CopyLabel/CopyLabel";
import { toast } from "react-toastify";
import { IconButton, Tooltip } from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

interface Props {
    language: string;
    code: string;
}

const Wrapper = styled.div`
    position: relative;
    margin-bottom: 25px;
`;

const CopyDiv = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    color: yellow;
    padding-right: 10px;
    padding-top: 10px;
`;

function CodeSample({ code, language }: Props) {
    async function onCopy() {
        try {
            await copyToClipboard(code);
            toast.success("Code copied", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Code copy failed", TOAST_SETTINGS);
        }
    }

    return (
        <Wrapper>
            <CopyDiv>
                <Tooltip title="Copy">
                    <IconButton onClick={onCopy} size="small">
                        <FileCopy fontSize="small" style={{ color: "black" }} />
                    </IconButton>
                </Tooltip>
            </CopyDiv>
            <SyntaxHighlighter style={{ ...atomOneLight, padding: "5px 40px 5px 20px!important" }} language={language}>
                {code}
            </SyntaxHighlighter>
        </Wrapper>
    );
}

export default CodeSample;

import * as React from "react";
import gql from "graphql-tag";
import {
    GetInvoicesOrganization,
    GetInvoicesOrganizationVariables,
    // GetInvoicesOrganization_organization_invoices,
} from "./__generated__/GetInvoicesOrganization";
import { useQuery } from "@apollo/react-hooks";
import CentralLoader from "../Loading/CentralLoader";
import styled from "styled-components";
import InvoicesTable from "./InvoicesTable";

export const GET_INVOICES_ORGANIZATION = gql`
    query GetInvoicesOrganization($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            organizationId
            invoices {
                invoiceNumber
                createdAt
                amount
                paidAt
                pdfLink
                payLink
                status
            }
        }
    }
`;
interface Props {
    organizationId: string;
}

const NoInvoices = styled.div`
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

function InvoicesOverview({ organizationId }: Props) {
    const { data, loading } = useQuery<GetInvoicesOrganization, GetInvoicesOrganizationVariables>(
        GET_INVOICES_ORGANIZATION,
        {
            variables: {
                organizationId,
            },
        },
    );

    if (loading || !data) {
        return <CentralLoader text="Getting invoices..." />;
    }

    const { invoices } = data.organization;

    return (
        <>{invoices.length === 0 ? <NoInvoices>No invoices yet</NoInvoices> : <InvoicesTable invoices={invoices} />}</>
    );
}

export default InvoicesOverview;

import { Button, FormControl, FormHelperText, Grid, Hidden, TextField, Typography } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { useState } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import {
    CreateTLADatacenterMutation,
    CreateTLADatacenterMutationVariables,
} from "./__generated__/CreateTLADatacenterMutation";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";

export interface TLADatacenterInput {
    id: string;
    name: string;
    tlaName: string;
    tlaDescription: string;
    tlaDomain: string;
    tlaHostId: string;
}

const SectionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

interface Props {
    id?: string;
    values: TLADatacenterInput;
}

export const CREATE_TLA_DATACENTER_MUTATION = gql`
    mutation CreateTLADatacenterMutation($input: CreateDatacenterInput!) {
        createTLADatacenter(input: $input) {
            id
        }
    }
`;

export default function TLAManageDatacenterForm({ values }: Props): JSX.Element {
    const [id, setId] = useState<string>(values.id);
    const [name, setName] = useState<string>(values.name);
    const [tlaName, setTlaName] = useState<string>(values.tlaName);
    const [tlaDescription, setTlaDescription] = useState<string>(values.tlaDescription);
    const [tlaDomain, setTlaDomain] = useState<string>(values.tlaDomain);
    const [tlaHostId, setTlaHostId] = useState<string>(values.tlaHostId);

    const [firstSubmitDone, setFirstSubmitDone] = useState<boolean>(false);

    const [createDatacenter, { loading }] = useMutation<
        CreateTLADatacenterMutation,
        CreateTLADatacenterMutationVariables
    >(CREATE_TLA_DATACENTER_MUTATION);

    function validId(): boolean {
        if (id.includes(" ")) {
            return false;
        }
        if (id.trim().length === 0) {
            return false;
        }
        return true;
    }

    function validName(): boolean {
        if (name.trim().length === 0) {
            return false;
        }
        return true;
    }

    function validTLAHostId(): boolean {
        if (tlaHostId.includes(" ")) {
            return false;
        }
        if (tlaHostId.trim().length === 0) {
            return false;
        }
        return true;
    }

    function validDomain(): boolean {
        return tlaDomain.trim().length !== 0;
    }

    function isValid(): boolean {
        return validId() && validName() && validDomain() && validTLAHostId();
    }

    async function onSubmitClick(): Promise<void> {
        setFirstSubmitDone(true);
        if (isValid()) {
            try {
                const res = await createDatacenter({
                    variables: {
                        input: {
                            id,
                            name,
                            tlaDescription,
                            tlaDomain,
                            tlaName: tlaName.trim() === "" ? name : tlaName,
                            tlaHostId,
                        },
                    },
                });
                navigate(`/app/${THEO_ID}/tla/data-centers/${res.data!.createTLADatacenter.id}`);
                toast.success("TLA data center created", TOAST_SETTINGS);
            } catch (_e) {
                toast.error("Something went wrong", TOAST_SETTINGS);
            }
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionTitle>Basic information</SectionTitle>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="ID" description="Required: ID that will be visible to the customers" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={name}
                        placeholder="ID"
                        onChange={(e: any) => setId(e.target.value)}
                    />
                    <FormHelperText>Should not contain spaces</FormHelperText>
                    {!validId() && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid ID
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Name" description="Required: Name that will be visible to the customers" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={name}
                        placeholder="Name"
                        onChange={(e: any) => setName(e.target.value)}
                    />
                    {!validName() && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid name
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel
                        label="Name for TLA"
                        description="Optional: Name that will be used internally in TLA environment. Will default to 'Name' field above."
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={tlaName}
                        placeholder="Name for TLA"
                        onChange={(e: any) => setTlaName(e.target.value)}
                    />
                    <FormHelperText>Should not contain spaces</FormHelperText>
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel
                        label="Description"
                        description="Optional: Description that will be used internally in TLA environment"
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={tlaName}
                        placeholder="Description"
                        onChange={(e: any) => setTlaDescription(e.target.value)}
                    />
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Domain" description="Required: Domain to be use in TLA environment" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={tlaDomain}
                        placeholder="Domain"
                        onChange={(e: any) => setTlaDomain(e.target.value)}
                    />
                    {!validDomain() && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid domain
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <HESPFormLabel label="Host ID" description="Required: Host ID to be use in TLA environment" />
                    <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue={tlaHostId}
                        placeholder="Host ID"
                        onChange={(e: any) => setTlaHostId(e.target.value)}
                    />
                    {!validTLAHostId() && firstSubmitDone && (
                        <Typography variant="subtitle2" color="error">
                            Please enter a valid host ID
                        </Typography>
                    )}
                </FormControl>
            </Grid>
            <Hidden mdDown>
                <Grid item md={6}></Grid>
            </Hidden>
            <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button onClick={onSubmitClick} variant="contained" size="large" disabled={loading || !isValid()}>
                        {loading ? `Creating...` : `Create`}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHead,
    Tooltip,
    IconButton,
    Button,
    ButtonGroup,
} from "@mui/material";
import { Check, Reply } from "@mui/icons-material";
import styled from "styled-components";
import moment from "moment";
import * as React from "react";
import AuthService from "../../auth/AuthService";
import { TableWrapper } from "../Channels/Overview/ChannelsOverview";
import { DATE_FORMAT } from "../UsageSummary/UsageSummary";
import { useQuery } from "react-apollo";
import { GetTokensQuery, GetTokensQueryVariables, GetTokensQuery_tokens } from "./__generated__/GetTokensQuery";
import { GET_TOKENS_QUERY } from "./TokensOverview";
import RevokeTokenDialog from "./RevokeTokenDialog";
import CentralLoader from "../Loading/CentralLoader";
import { Code } from "../Channels/Details/ChannelEmbedExample";

const NoDetails = styled.div`
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TokenSelector = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
`;

interface Props {
    summary?: boolean;
    organizationId: string;
    onGenerateToken?: () => void;
}

function TokensOverviewTable({ organizationId, summary, onGenerateToken }: Props) {
    const [showActiveTokens, setShowActiveTokens] = React.useState<boolean>(true);
    const [currentToken, setCurrentToken] = React.useState<GetTokensQuery_tokens>();
    const [showRevokeToken, setShowRevokeToken] = React.useState<boolean>(false);
    const isAdmin = AuthService.isAdmin();

    const { data, loading } = useQuery<GetTokensQuery, GetTokensQueryVariables>(GET_TOKENS_QUERY, {
        variables: {
            organizationId,
        },
    });

    function onRevokeToken(token: GetTokensQuery_tokens) {
        setCurrentToken(token);
        setShowRevokeToken(true);
    }

    if (loading || !data) {
        return <CentralLoader text="Getting tokens..." />;
    }

    const { tokens } = data;

    const revokedTokens = tokens.filter((token) => Boolean(token.revoked));
    const activeTokens = getActiveTokens(tokens, revokedTokens);

    const tokensToShow = showActiveTokens ? activeTokens : revokedTokens;

    return (
        <>
            {(summary ? activeTokens : tokens).length === 0 ? (
                <NoDetails>
                    {summary || !onGenerateToken ? (
                        `No active tokens available`
                    ) : (
                        <Button color="primary" variant="contained" onClick={() => onGenerateToken()}>
                            Generate first token
                        </Button>
                    )}
                </NoDetails>
            ) : (
                <>
                    {!summary && (
                        <TokenSelector>
                            <ButtonGroup color="secondary" size="small">
                                <Button
                                    variant={showActiveTokens ? "contained" : "outlined"}
                                    onClick={() => setShowActiveTokens(true)}
                                    startIcon={showActiveTokens && <Check style={{ color: "white" }} />}
                                >
                                    Active tokens
                                </Button>
                                <Button
                                    variant={!showActiveTokens ? "contained" : "outlined"}
                                    onClick={() => setShowActiveTokens(false)}
                                    startIcon={!showActiveTokens && <Check style={{ color: "white" }} />}
                                >
                                    Revoked tokens
                                </Button>
                            </ButtonGroup>
                        </TokenSelector>
                    )}
                    <TableWrapper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Key</TableCell>
                                    {!summary && (
                                        <>
                                            <TableCell>Info</TableCell>
                                            {showActiveTokens ? (
                                                <TableCell>Actions</TableCell>
                                            ) : (
                                                <TableCell>Revoked on</TableCell>
                                            )}
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tokensToShow.length === 0 && <TableCell colSpan={4}>No record available</TableCell>}

                                {tokensToShow.length > 0 && (
                                    <>
                                        {tokensToShow.map((token) => (
                                            <TableRow key={token.tokenKey}>
                                                {tokensToShow.length > 0 && (
                                                    <>
                                                        <TableCell>{token.name}</TableCell>
                                                        <TableCell>
                                                            <Code>{token.tokenKey}</Code>
                                                        </TableCell>
                                                        {!summary && (
                                                            <>
                                                                <TableCell>
                                                                    Secret generated on{" "}
                                                                    {moment(token.generatedOn).format(DATE_FORMAT)} by{" "}
                                                                    {token.generatedBy}
                                                                </TableCell>
                                                                {showActiveTokens && (
                                                                    <TableCell>
                                                                        {isAdmin && (
                                                                            <Tooltip title="Revoke" placement="top">
                                                                                <IconButton
                                                                                    onClick={() => onRevokeToken(token)}
                                                                                >
                                                                                    <Reply fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </TableCell>
                                                                )}
                                                                {!showActiveTokens && (
                                                                    <TableCell>
                                                                        {moment(token.revokedOn).format(DATE_FORMAT)} by{" "}
                                                                        {token.revokedBy}
                                                                    </TableCell>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableWrapper>
                    {currentToken && (
                        <RevokeTokenDialog
                            open={showRevokeToken}
                            onClose={() => setShowRevokeToken(false)}
                            token={currentToken}
                        />
                    )}
                </>
            )}
        </>
    );
}

export default TokensOverviewTable;

function getActiveTokens(
    tokens: GetTokensQuery_tokens[],
    revokedTokens: GetTokensQuery_tokens[],
): GetTokensQuery_tokens[] {
    const revokedIds: string[] = revokedTokens.map((revokedToken) => revokedToken.tokenKey);
    const activeTokens = tokens.filter((token) => !revokedIds.includes(token.tokenKey));
    return activeTokens;
}

import { DialogActions, DialogContent, Grid } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";

import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import {
    DeleteDeviceContextMutation,
    DeleteDeviceContextMutationVariables,
} from "./__generated__/DeleteDeviceContextMutation";
import { GET_DATACENTER } from "../../views/App/TLADatacenter";

interface Props {
    open: boolean;
    deviceContextId: string;
    datacenterId: string;
    name: string;
    onClose: () => void;
}

export const REMOVE_TLA_DEVICE_CONTEXT = gql`
    mutation DeleteDeviceContextMutation($input: DeleteDeviceContextInput!) {
        deleteDeviceContext(input: $input)
    }
`;

export default function TLARemoveDeviceContextDialog({ onClose, open, deviceContextId, datacenterId, name }: Props) {
    const [removeDeviceContext, { loading }] = useMutation<
        DeleteDeviceContextMutation,
        DeleteDeviceContextMutationVariables
    >(REMOVE_TLA_DEVICE_CONTEXT);

    async function onConfirm() {
        try {
            await removeDeviceContext({
                variables: {
                    input: {
                        id: deviceContextId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_DATACENTER,
                        variables: {
                            id: datacenterId,
                        },
                    },
                ],
            });
            onClose();
        } catch (e) {
            toast.error("Error while removing device context", TOAST_SETTINGS);
        }
    }

    return (
        <DialogHESP
            title={"Remove device context"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginTop: "14px" }}>
                                Are you sure you want to remove device context "{name}"?
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onConfirm}
                            defaultText={"Remove"}
                            loadingText={"Removing..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

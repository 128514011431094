import { Grid, Hidden } from "@mui/material";
import * as React from "react";
import {
    ProperyChangeType,
    ChannelCustomization as ChannelCustomizationObj,
    updateCustomization,
} from "../../ChannelCustomizer/customizationHelpers";
import ChannelCustomization from "./ChannelCustomization";
import styled from "styled-components";
import ChannelCustomizationUsefulLinks from "./ChannelCustomizationUsefulLinks";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import gql from "graphql-tag";
import THEOlivePlayer from "../../THEOPlayer/THEOlivePlayer";

const PlayerWrapper = styled.div`
    padding: 15px 20px;
`;

const LinksWrapper = styled.div`
    margin-top: 30px;
`;

interface Props {
    channel: GetChannelQuery_channel;
    token?: string;
    playoutId: string;
    isAlias: boolean;
    manifestUrl: string;
    loading: boolean;
    onSave: () => void;
    customization: ChannelCustomizationObj;
    customizationChanged: boolean;
    onChangeCustomization: (updated: ChannelCustomizationObj) => void;
}

export const GET_THEO_TOKEN = gql`
    query GetTHEOTokenQuery($organizationId: String!, $channelId: String!, $aliasId: String!) {
        token(organizationId: $organizationId, channelId: $channelId, aliasId: $aliasId)
    }
`;

function ChannelPlayoutCustomization({
    channel,
    playoutId,
    isAlias,
    manifestUrl,
    token,
    customization,
    onChangeCustomization,
    customizationChanged,
    loading,
    onSave,
}: Props) {
    function onCustomizationChange(type: ProperyChangeType, key: string, value: string | boolean | number) {
        const updatedCustomization = updateCustomization({ customization, type, key, value });
        onChangeCustomization(updatedCustomization);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
                <PlayerWrapper>
                    {typeof token !== "undefined" && (
                        <THEOlivePlayer channelId={playoutId} token={token} channelStatus={channel.channelStatus} />
                    )}
                    <Hidden lgDown={true}>
                        <LinksWrapper>
                            <ChannelCustomizationUsefulLinks />
                        </LinksWrapper>
                    </Hidden>
                </PlayerWrapper>
            </Grid>
            <Grid item xs={12} lg={5}>
                <ChannelCustomization
                    loading={loading}
                    onSaveClick={onSave}
                    customizationChanged={customizationChanged}
                    customization={customization}
                    onCustomizationChange={(type: ProperyChangeType, key: string, value: string | boolean | number) =>
                        onCustomizationChange(type, key, value)
                    }
                />
            </Grid>
        </Grid>
    );
}

export default ChannelPlayoutCustomization;

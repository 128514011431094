import { Button, FormControl, Grid } from "@mui/material";
import * as React from "react";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { DateTimePicker } from "@mui/x-date-pickers";
import { GetSchedulerQuery_scheduler } from "../SchedulersOverview/__generated__/GetSchedulerQuery";
import moment from "moment";
import { Save } from "@mui/icons-material";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import {
    UpdateSchedulerReportMutation,
    UpdateSchedulerReportMutationVariables,
} from "./__generated__/UpdateSchedulerReportMutation";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { GET_SCHEDULER_QUERY } from "../SchedulersOverview/ManageSchedulerForm";

interface Props {
    scheduler: GetSchedulerQuery_scheduler;
}

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.grayBackground};
    padding: 10px 20px;
    border-radius: 8px;
`;

export const UPDATE_SCHEDULER_REPORT_TIMINGS = gql`
    mutation UpdateSchedulerReportMutation($input: UpdateSchedulerReportInput!) {
        updateSchedulerReport(input: $input) {
            schedulerId
        }
    }
`;

export default function SchedulerReportTimings({ scheduler }: Props) {
    const { schedulerId, organizationId } = scheduler;
    const [start, setStart] = React.useState<moment.Moment>(moment(scheduler.report.start ?? scheduler.start));
    const [end, setEnd] = React.useState<moment.Moment>(moment(scheduler.report.end ?? scheduler.end!));

    const [updateSchedulerReport, { loading }] = useMutation<
        UpdateSchedulerReportMutation,
        UpdateSchedulerReportMutationVariables
    >(UPDATE_SCHEDULER_REPORT_TIMINGS);

    async function onSave() {
        try {
            await updateSchedulerReport({
                variables: {
                    input: {
                        end: moment(end).utc().toISOString(),
                        start: moment(start).utc().toISOString(),
                        organizationId,
                        schedulerId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_SCHEDULER_QUERY,
                        variables: {
                            schedulerId,
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Report setting saved", TOAST_SETTINGS);
        } catch (_e) {}
    }

    return (
        <Wrapper>
            <HESPFormLabel label="Report timing settings" />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                        <HESPFormLabel label="Start time" />
                        <DateTimePicker
                            format="YYYY/MM/DD HH:mm"
                            ampm={false}
                            value={start}
                            minDateTime={moment(scheduler.start).subtract(1, "minute")}
                            maxDateTime={moment(scheduler.end!)}
                            onChange={(newValue: moment.Moment | null) => {
                                if (newValue !== null) {
                                    setStart(newValue.startOf("minute"));
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                        <HESPFormLabel label="End time" />
                        <DateTimePicker
                            format="YYYY/MM/DD HH:mm"
                            ampm={false}
                            value={end}
                            minDateTime={moment(scheduler.start)}
                            maxDateTime={moment(scheduler.end!)}
                            onChange={(newValue: moment.Moment | null) => {
                                if (newValue !== null) {
                                    setEnd(newValue.startOf("minute"));
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "4px" }}>
                        <Button startIcon={<Save />} variant="contained" onClick={onSave} disabled={loading}>
                            {loading ? "Saving..." : "Save"}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <ToastContainer />
        </Wrapper>
    );
}

import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import styled from "styled-components";
import { numberFormatter } from "../Usage/formatter";
import { SkeletonRectangle } from "../Loading/Skeletons";

interface Props {
    amount?: number;
    title: string;
    icon: React.ReactNode;
    iconColor: string;
    isLoading: boolean;
    onClick?: () => void;
    backgroundColor?: string;
    small?: boolean;
}

const Wrapper = styled.div.attrs((props: { small?: boolean }) => ({
    small: props.small === true,
}))`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.small ? 70 : 90)}px;
`;

const Icon = styled.div.attrs((props: { color: string; small?: boolean }) => ({
    color: props.color,
    small: props.small === true,
}))`
    font-size: ${(props) => (props.small ? 40 : 48)}px;
    color: ${(props) => props.color};
`;

const Data = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
`;

const Amount = styled.div.attrs((props: { small?: boolean }) => ({
    small: props.small === true,
}))`
    font-size: ${(props) => (props.small ? 40 : 48)}px;
    font-weight: bold;
`;

const Title = styled.div`
    // color: ${(props) => props.theme.gray};
    font-size: 14px;
`;

function AnalyticsCard({ amount, title, icon, iconColor, isLoading, onClick, backgroundColor, small }: Props) {
    return (
        <CardHESP
            onClick={onClick}
            style={{
                cursor: onClick ? "pointer" : "default",
                // boxShadow: "none",
                backgroundColor: backgroundColor ?? "white",
                // border: "none",
            }}
        >
            <Wrapper small={small}>
                <Icon color={iconColor} small={small}>
                    {icon}
                </Icon>
                <Data>
                    <Amount small={small}>
                        {isLoading ? <SkeletonRectangle height="20px" width="45px" /> : numberFormatter(amount!)}
                    </Amount>
                    <Title>{title}</Title>
                </Data>
            </Wrapper>
        </CardHESP>
    );
}

export default AnalyticsCard;

import { DeleteForever, Settings } from "@mui/icons-material";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { navigate } from "@reach/router";
import * as React from "react";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { GetWebhooksOrganization_webhooks } from "./__generated__/GetWebhooksOrganization";
import styled from "styled-components";
import { STYLED_THEME } from "../../AppWrapper";
import DeleteWebhookDialog from "./DeleteWebhookDialog";
import { ToastContainer } from "react-toastify";
import AuthService from "../../auth/AuthService";

interface Props {
    webhooks: GetWebhooksOrganization_webhooks[];
    organizationId: string;
}

const Name = styled.div`
    font-weight: 600;
`;

const URL = styled.div`
    margin-top: 3px;
    font-size: 12px;
    color: ${(props) => props.theme.gray};
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
`;

const BadgeWrapper = styled.div`
    margin-right: 20px;
`;

function WebhooksTable({ webhooks, organizationId }: Props) {
    const [selectedWebhook, setSelectedWebhook] = React.useState<GetWebhooksOrganization_webhooks | undefined>(
        undefined,
    );
    const [openDeleteWebhook, setOpenDeleteWebhook] = React.useState<boolean>(false);

    const isTHEOAdmin = AuthService.isTHEOAdmin();

    function onDeleteWebhook(webhookId: string) {
        setSelectedWebhook(webhooks.find((w) => w.webhookId === webhookId)!);
        setOpenDeleteWebhook(true);
    }

    const headers: TableHeadItem[] = [
        {
            id: "webhook",
            label: "Webhook",
            allowSort: false,
        },
        ...(isTHEOAdmin
            ? [
                  {
                      id: "admin",
                      label: "",
                      allowSort: false,
                  },
              ]
            : []),
        {
            id: "events",
            label: "Events",
            align: "left",
            allowSort: false,
        },
        { id: "actions", label: "Actions", align: "right", allowSort: false },
    ];

    const records = webhooks.map(({ webhookId, active, events, name, url, isAdmin }) => ({
        webhook: (
            <InfoWrapper>
                <BadgeWrapper>
                    <Chip
                        size="small"
                        label={active ? "Enabled" : "Disabled"}
                        style={{ backgroundColor: active ? STYLED_THEME.successLight : STYLED_THEME.errorLight }}
                    />
                </BadgeWrapper>
                <div>
                    <Name>{name}</Name>
                    <URL>{url}</URL>
                </div>
            </InfoWrapper>
        ),
        ...(isTHEOAdmin && { admin: isAdmin === true ? "⭐ Admin webhook" : "" }),
        events: `Listening on ${events.includes("*") ? "all" : events.length} events`,
        actions: (
            <>
                <Tooltip title="details">
                    <IconButton onClick={() => navigate(`/app/${organizationId}/webhooks/${webhookId}`)}>
                        <Settings fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="delete">
                    <IconButton onClick={() => onDeleteWebhook(webhookId)}>
                        <DeleteForever fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        ),
    }));

    return (
        <>
            <HESPTable headers={headers} data={records} size="medium" defaultSortOn="webhook" disableSorting />
            {selectedWebhook && (
                <DeleteWebhookDialog
                    open={openDeleteWebhook}
                    onClose={() => setOpenDeleteWebhook(false)}
                    webhook={selectedWebhook}
                />
            )}
            <ToastContainer />
        </>
    );
}

export default WebhooksTable;

import styled, { keyframes } from "styled-components";

interface SkeletonRectangleArgs {
    height?: string;
    width?: string;
}

interface SkeletonCircleArgs {
    radius?: number;
}

const skeletonAnimation = keyframes`
  0% {
    background-color: rgb(222, 222, 222, 0.2);
  }
  100% {
    background-color: rgb(222, 222, 222);
  }
`;

export const SkeletonCircle = styled.div.attrs(({ radius }: SkeletonCircleArgs) => ({
    radius,
}))`
    animation: ${skeletonAnimation} 1s linear infinite alternate;
    border-radius: 50%;
    width: ${(props) => props.radius ?? 20}px;
    height: ${(props) => props.radius ?? 20}px;
`;

export const SkeletonRectangle = styled.div.attrs(({ height, width }: SkeletonRectangleArgs) => ({
    height,
    width,
}))`
    animation: ${skeletonAnimation} 1s linear infinite alternate;
    width: ${(props) => props.width ?? "20px"};
    height: ${(props) => props.height ?? "12px"};
    border-radius: 20px;
    margin: 10px;
`;

import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { GetFullOrganizationDetails_organization } from "../../views/App/__generated__/GetFullOrganizationDetails";
import { Button } from "@mui/material";
import OrganizationChannelSettingsItem from "./OrganizationChannelSettingsItem";
import { Save } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { UpdateOrganizationTLA, UpdateOrganizationTLAVariables } from "./__generated__/UpdateOrganizationTLA";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import gql from "graphql-tag";
import { UpdateOrganizationTLASettingsInput } from "../../__generated__/globalTypes";
import OrganizationTLADataCenterOverview from "./OrganizationTLADataCenterOverview";

interface Props {
    organization: GetFullOrganizationDetails_organization;
}

export const UPDATE_ORGANIZATION_TLA = gql`
    mutation UpdateOrganizationTLA($input: UpdateOrganizationTLASettingsInput!) {
        updateOrganizationTLASettings(input: $input) {
            organizationId
        }
    }
`;

export default function OrganizationTLA({ organization }: Props) {
    const { tla, organizationId } = organization;

    const [input, setInput] = React.useState<UpdateOrganizationTLASettingsInput>({
        organizationId,
        activated: tla.activated,
    });

    const [updateTLASettingsMut, { loading }] = useMutation<UpdateOrganizationTLA, UpdateOrganizationTLAVariables>(
        UPDATE_ORGANIZATION_TLA,
    );

    async function onSave() {
        try {
            await updateTLASettingsMut({
                variables: {
                    input,
                },
            });
            toast.success("TLA settings saved!", TOAST_SETTINGS);
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    return (
        <>
            <CardHESP>
                <CardHESPTitle
                    title="THEOlive Anywhere"
                    button={
                        <Button
                            color="primary"
                            startIcon={<Save />}
                            variant="contained"
                            onClick={onSave}
                            disabled={loading}
                        >
                            {loading ? "Saving..." : "Save"}
                        </Button>
                    }
                />
                <OrganizationChannelSettingsItem
                    title="THEOlive Anywhere access"
                    description="Determine if customer has access to THEOlive Anywhere"
                    switchUI={{
                        enabled: input.activated,
                        onChange: () =>
                            setInput({
                                ...input,
                                activated: !input.activated,
                            }),
                    }}
                />
                {input.activated && (
                    <>
                        <OrganizationTLADataCenterOverview organization={organization} />
                    </>
                )}
            </CardHESP>
            <ToastContainer />
        </>
    );
}

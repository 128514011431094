import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string | React.ReactNode;
    align?: "left" | "right" | "center";
    showBottomBorder?: boolean;
    isAction?: boolean;
}

const Title = styled.div.attrs(
    (props: { align: "left" | "right" | "center"; showBottomBorder?: boolean; isAction?: boolean }) => ({
        align: props.align,
        showBottomBorder: props.showBottomBorder,
        isAction: props.isAction === true,
    }),
)`
    font-weight: 600;
    font-size: 18px;
    text-align: ${(props) => (props.align ? props.align : "center")};
    border-bottom: ${(props) => (props.showBottomBorder ? 1 : 0)}px solid #ececec;
    height: ${(props) => (props.isAction ? 25 : 40)}px;
    margin-bottom: ${(props) => (props.isAction ? 0 : 10)}px;
`;

function SectionTitle({ title, align, showBottomBorder, isAction }: Props) {
    return (
        <Title align={align} showBottomBorder={showBottomBorder} isAction={isAction}>
            {title}
        </Title>
    );
}

export default SectionTitle;

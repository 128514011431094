import { Grid } from "@mui/material";
import { GetDatacenter_datacenter } from "../../views/App/__generated__/GetDatacenter";
import TLADatacenterBasicDetails from "./TLADatacenterBasicDetails";
import TLADatacenterOrganizations from "./TLADatacenterOrganizations";
import TLADatacenterDeviceContexts from "./TLADatacenterDeviceContexts";
import TLADatacenterChannels from "./TLADatacenterChannels";

interface Props {
    datacenter: GetDatacenter_datacenter;
}

export default function TLADatacenterDetails({ datacenter }: Props): JSX.Element {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <TLADatacenterBasicDetails datacenter={datacenter} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TLADatacenterDeviceContexts
                    datacenterId={datacenter.id}
                    deviceContexts={datacenter.tla.deviceContexts}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <TLADatacenterOrganizations organizations={datacenter.organizations} datacenterId={datacenter.id} />
                <div style={{ marginTop: "16px" }}>
                    <TLADatacenterChannels channels={datacenter.channels} />
                </div>
            </Grid>
        </Grid>
    );
}

import {
    Button,
    ButtonGroup,
    FormControl,
    Typography,
    Grid,
    TextField,
    DialogActions,
    DialogContent,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DialogHESP from "../DialogHESP/DialogHESP";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { UserRoleType } from "../../__generated__/globalTypes";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { AddUserMutation, AddUserMutationVariables } from "./__generated__/AddUserMutation";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { GET_ORGANIZATION_USERS_QUERY } from "./TeamOverview";
import Callout, { CalloutType } from "../Callout/Callout";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const ADD_USER_MUTATION = gql`
    mutation AddUserMutation($input: AddUserInput!) {
        addUser(input: $input) {
            auth0Id
        }
    }
`;

interface Props {
    open: boolean;
    onClose: () => void;
    organizationId: string;
}

interface FormData {
    email: string;
}
// eslint-disable-next-line no-unused-vars
function AddUserDialog({ open, onClose, organizationId }: Props) {
    const { handleSubmit, register, errors } = useForm<FormData>();

    const [selectedRole, setSelectedRole] = React.useState<UserRoleType>(UserRoleType.hesp_user);
    const [error, setError] = React.useState({ showError: false, title: "", description: "", type: "danger" });
    const [addUserMut, { loading }] = useMutation<AddUserMutation, AddUserMutationVariables>(ADD_USER_MUTATION);

    const addUser = handleSubmit(async ({ email }: FormData) => {
        try {
            await addUserMut({
                variables: {
                    input: {
                        email: email.toLowerCase(),
                        organizationId,
                        role: selectedRole,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_ORGANIZATION_USERS_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            setError({
                showError: false,
                type: "",
                title: "",
                description: "",
            });
            toast.success("User added! 🚀", TOAST_SETTINGS);

            onClose();
        } catch (e: any) {
            handleError(e.graphQLErrors[0].message);
        }
    });

    function handleError(errorMessage: string) {
        switch (errorMessage) {
            case "USER_ALREADY_CONNECTED_TO_AN_ORGANIZATION":
                setError({
                    showError: true,
                    title: "Already connected",
                    description: "A user with this email address is already connected to an organization",
                    type: "danger",
                });
                break;
            default:
                setError({
                    showError: true,
                    type: "danger",
                    title: "Something went wrong",
                    description: "An unexpected error happened",
                });
        }
    }

    return (
        <DialogHESP
            title={"Add a user"}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit((data1, e) => addUser(e))}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {error.showError && (
                                <Grid item xs={12}>
                                    <Callout
                                        type={error.type as CalloutType}
                                        title={error.title}
                                        description={error.description}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Email" />
                                    <TextField
                                        name="email"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        inputRef={register({
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i,
                                                message: "invalid email address",
                                            },
                                        })}
                                    />
                                    <Typography variant="subtitle2" color="error">
                                        {errors.email && errors.email.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Role" />
                                    <ButtonGroup color="secondary">
                                        <Button
                                            variant={
                                                selectedRole === UserRoleType.hesp_admin ? "contained" : "outlined"
                                            }
                                            onClick={() => setSelectedRole(UserRoleType.hesp_admin)}
                                            startIcon={
                                                selectedRole === UserRoleType.hesp_admin && (
                                                    <Check style={{ color: "white" }} />
                                                )
                                            }
                                        >
                                            Admin
                                        </Button>
                                        <Button
                                            variant={selectedRole === UserRoleType.hesp_user ? "contained" : "outlined"}
                                            onClick={() => setSelectedRole(UserRoleType.hesp_user)}
                                            startIcon={
                                                selectedRole === UserRoleType.hesp_user && (
                                                    <Check style={{ color: "white" }} />
                                                )
                                            }
                                        >
                                            User
                                        </Button>
                                    </ButtonGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Add"}
                            loadingText={"Adding..."}
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default AddUserDialog;

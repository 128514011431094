import * as React from "react";
import { GetRunsQuery_runs_runs } from "./__generated__/GetRunsQuery";
import ChannelRunItem from "./ChannelRunsItem";

interface Props {
    runs: GetRunsQuery_runs_runs[];
}

export default function ChannelRunsTable({ runs }: Props) {
    return (
        <>
            {runs.map((run, idx) => (
                <ChannelRunItem run={run} key={`run-idx-${idx}`} />
            ))}
        </>
    );
}

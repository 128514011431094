import * as React from "react";

import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import CardHESP from "../CardHESP/CardHESP";
import TokensOverviewTable from "./TokensOverviewTable";
import CreateTokenDialog from "./CreateTokenDialog";
import gql from "graphql-tag";

export const GET_TOKENS_QUERY = gql`
    query GetTokensQuery($organizationId: ID!) {
        tokens(organizationId: $organizationId) {
            tokenKey
            name
            generatedBy
            generatedOn
            revoked
            revokedBy
            revokedOn
            name
            organizationId
        }
    }
`;

interface Props {
    organizationId: string;
}

function TokensOverview({ organizationId }: Props) {
    const [showGenerateToken, setShowGenerateToken] = React.useState<boolean>(false);

    return (
        <>
            <SectionTitleWithAction
                title="My Tokens"
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircle />}
                        onClick={() => setShowGenerateToken(true)}
                    >
                        Generate Token
                    </Button>
                }
            />
            <CardHESP>
                <TokensOverviewTable
                    organizationId={organizationId}
                    onGenerateToken={() => setShowGenerateToken(true)}
                />
            </CardHESP>
            <CreateTokenDialog
                open={showGenerateToken}
                onClose={() => setShowGenerateToken(false)}
                organizationId={organizationId}
            />
        </>
    );
}

export default TokensOverview;

import styled from "styled-components";
import { UsageType } from "../../__generated__/globalTypes";
import { OrganizationAnalyticsMode } from "./AdminTopOrganizations";

interface Props {
    selectedType: UsageType;
    onChangeType: (type: UsageType) => void;
    selectedMode: OrganizationAnalyticsMode;
    onChangeMode: (mode: OrganizationAnalyticsMode) => void;
}

export const SmallSelector = styled.div.attrs(
    (props: { selected?: boolean; colorSelected: string; backgroundSelected: string }) => ({
        selected: props.selected === true,
        colorSelected: props.colorSelected,
        backgroundSelected: props.backgroundSelected,
    }),
)`
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 5px;
    color: ${(props) => (props.selected === true ? props.colorSelected : "black")};
    background-color: ${(props) => (props.selected === true ? props.backgroundSelected : "#f7f7f7")};
    margin-right: 15px;
    cursor: pointer;
`;

const SelectorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

export default function TopOrganizationSettings({ onChangeMode, onChangeType, selectedMode, selectedType }: Props) {
    return (
        <>
            <SelectorWrapper>
                <SmallSelector
                    selected={selectedType === UsageType.transcoded}
                    colorSelected={"black"}
                    backgroundSelected={"#cce0eb"}
                    onClick={() => onChangeType(UsageType.transcoded)}
                >
                    Transcoded
                </SmallSelector>
                <SmallSelector
                    selected={selectedType === UsageType.viewed}
                    colorSelected={"black"}
                    backgroundSelected={"#cce0eb"}
                    onClick={() => onChangeType(UsageType.viewed)}
                >
                    Viewed
                </SmallSelector>
            </SelectorWrapper>
            <SelectorWrapper>
                <SmallSelector
                    selected={selectedMode === "all"}
                    colorSelected={"black"}
                    backgroundSelected={"#cce0eb"}
                    onClick={() => onChangeMode("all")}
                >
                    Total
                </SmallSelector>
                <SmallSelector
                    selected={selectedMode === "production"}
                    colorSelected={"black"}
                    backgroundSelected={"#cce0eb"}
                    onClick={() => onChangeMode("production")}
                >
                    Production
                </SmallSelector>
                <SmallSelector
                    selected={selectedMode === "promotional"}
                    colorSelected={"black"}
                    backgroundSelected={"#cce0eb"}
                    onClick={() => onChangeMode("promotional")}
                >
                    Promotional
                </SmallSelector>
                <SmallSelector
                    selected={selectedMode === "demo"}
                    colorSelected={"black"}
                    backgroundSelected={"#cce0eb"}
                    onClick={() => onChangeMode("demo")}
                >
                    Demo
                </SmallSelector>
            </SelectorWrapper>
        </>
    );
}

import * as React from "react";

import { Grid } from "@mui/material";
import AdminSearchCard from "../AdminSearchCard/AdminSearchCard";

function AdminSearchOptions() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <AdminSearchCard />
            </Grid>
        </Grid>
    );
}

export default AdminSearchOptions;

import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ActiveChannelsContent from "../../components/Channels/Overview/ActiveChannelsContent";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function ActiveChannels({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            <Grid item xs={12}>
                <SectionTitle title={`Active channels`} align="left" showBottomBorder />
                <ActiveChannelsContent />
            </Grid>
        </Grid>
    );
}

export default ActiveChannels;

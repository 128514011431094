import * as React from "react";
import MaintenanceTimeSelector from "./MaintenanceTimeSelector";
import styled from "styled-components";
import MaintenanceRecurringPeriodSelector from "./MaintenanceRecurringPeriodSelector";
import { MaintenanceSettings } from "./MaintenanceManagement";
import moment from "moment";
import { MaintenanceRecurringPeriodType } from "../../../__generated__/globalTypes";

interface Props {
    settings: MaintenanceSettings;
    onPeriodChange: (period: MaintenanceRecurringPeriodType) => void;
    onAmountChange: (amount: number) => void;
    onTimeChange: (time: string) => void;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 8px;
    padding-top: 10px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const NextMaintenance = styled.div`
    font-size: 13px;
    margin-top: 10px;
    color: ${(props) => props.theme.gray};
`;

export default function MaintenanceWindowRecurring({ settings, onAmountChange, onPeriodChange, onTimeChange }: Props) {
    return (
        <div style={{ width: "100%" }}>
            <Wrapper>
                <InnerWrapper>
                    <MaintenanceRecurringPeriodSelector
                        settings={settings}
                        onAmountChange={(amount: number) => onAmountChange(amount)}
                        onPeriodChange={(period: MaintenanceRecurringPeriodType) => onPeriodChange(period)}
                    />
                    <MaintenanceTimeSelector
                        time={settings.interval!.time}
                        onTimeChange={(time: string) => onTimeChange(time)}
                    />
                </InnerWrapper>
                <NextMaintenance>
                    The next maintenance will be at{" "}
                    {moment().add(settings.interval!.amount, settings.interval!.period).format("YYYY/MM/DD")}{" "}
                    {settings.interval!.time} UTC
                </NextMaintenance>
            </Wrapper>
        </div>
    );
}

import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import ManageProfileForm from "../../components/AdminConfig/Profiles/ManageProfileForm";
import CardHESP from "../../components/CardHESP/CardHESP";
import { SkeletonRectangle } from "../../components/Loading/Skeletons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { GetProfile, GetProfileVariables } from "./__generated__/GetProfile";

interface Props extends RouteComponentProps {
    organizationId?: string;
    id?: string;
}

export const GET_PROFILE = gql`
    query GetProfile($id: ID!) {
        profile(id: $id) {
            id
            profileId
            maxBitrate
            maxResolution
            maxFps
            deprecated
            publiclyAvailable
            transcodedPricePerMinute
            viewedPricePerMinute
            abrLadder {
                entries {
                    resolution
                    bitRate
                }
            }
        }
    }
`;

export default function EditProfile({ organizationId, id }: Props) {
    const { data, loading } = useQuery<GetProfile, GetProfileVariables>(GET_PROFILE, {
        variables: {
            id: id!,
        },
    });

    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <SectionTitle title={`Edit a profile`} align="left" showBottomBorder />
                <CardHESP>
                    {!data || loading ? (
                        <>
                            <SkeletonRectangle width="100%" />
                            <SkeletonRectangle width="100%" />
                            <SkeletonRectangle width="100%" />
                        </>
                    ) : (
                        <ManageProfileForm organizationId={organizationId!} profile={data.profile} isCreate={false} />
                    )}
                </CardHESP>
            </Grid>
        </Grid>
    );
}

import * as React from "react";
import { GetChannelViewerInsightsQuery_channel_viewerInsights_browsersAndOS } from "../../views/App/__generated__/GetChannelViewerInsightsQuery";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";

interface Props {
    data: GetChannelViewerInsightsQuery_channel_viewerInsights_browsersAndOS[];
}

interface DataRow {
    browserName: string;
    browserVersion: string;
    osName: string;
    osVersion: string;
    amount: number;
}

function ViewerBrowserTable({ data }: Props) {
    if (data.length < 1) {
        return <p style={{ textAlign: "center" }}>No viewer data found for the selected period</p>;
    }

    const headers: TableHeadItem[] = [
        {
            id: "browserName",
            label: "Browser",
            allowSort: true,
        },
        {
            id: "browserVersion",
            label: "Browser version",
        },
        {
            id: "osName",
            label: "OS",
            allowSort: true,
        },
        {
            id: "osVersion",
            label: "OS version",
        },
        {
            id: "amount",
            label: "Amount",
            allowSort: true,
        },
    ];

    const mappedData: DataRow[] = data.map(
        ({
            browser,
            browserVersion,
            os,
            osVersion,
            amount,
        }: GetChannelViewerInsightsQuery_channel_viewerInsights_browsersAndOS) => ({
            browserName: browser,
            browserVersion,
            osName: os,
            osVersion,
            amount,
        }),
    );

    return (
        <HESPTable
            headers={headers}
            data={mappedData}
            defaultSortOn={"amount"}
            enablePagination
            defaultSortDirection="desc"
            paginationRowsPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15]}
            size="small"
        />
    );
}

export default ViewerBrowserTable;

import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import CardHESP from "../../components/CardHESP/CardHESP";
import ManageSchedulerForm from "../../components/SchedulersOverview/ManageSchedulerForm";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export default function CreateScheduler({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <SectionTitle title="Create a scheduler" align="left" showBottomBorder />
                <CardHESP>
                    <ManageSchedulerForm organizationId={organizationId!} isCreate={true} />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import CardHESP from "../../components/CardHESP/CardHESP";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import TLAManageDatacenterForm from "../../components/TLA/TLAManageDatacenterForm";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export default function TLADatacenterCreate({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <SectionTitle title="Create a TLA data center" align="left" showBottomBorder />
                <CardHESP>
                    <TLAManageDatacenterForm
                        values={{ id: "", name: "", tlaDescription: "", tlaDomain: "", tlaName: "", tlaHostId: "" }}
                    />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

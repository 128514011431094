import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import styled from "styled-components";
import { Check } from "@mui/icons-material";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { AddDcToOrganization, AddDcToOrganizationVariables } from "./__generated__/AddDcToOrganization";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { GET_FULL_ORGANIZATION_DETAILS } from "../../views/App/ManageOrganization";

interface Props {
    open: boolean;
    organizationId: string;
    onClose: () => void;
    alreadyConnectedIds: string[];
    allDatacenters: { id: string; name: string }[];
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.grayBackground};
`;

export const ADD_DC_TO_ORGANIZATION = gql`
    mutation AddDcToOrganization($input: AddDatacenterToOrganizationInput!) {
        addDatacenterToOrganization(input: $input) {
            organizationId
        }
    }
`;

export default function AddDatacenterToOrganizationDialog({
    alreadyConnectedIds,
    onClose,
    open,
    allDatacenters,
    organizationId,
}: Props): JSX.Element {
    const [addDatacenterToOrganization, { loading }] = useMutation<AddDcToOrganization, AddDcToOrganizationVariables>(
        ADD_DC_TO_ORGANIZATION,
    );

    async function assign(dcId: string): Promise<void> {
        try {
            await addDatacenterToOrganization({
                variables: {
                    input: {
                        datacenterId: dcId,
                        organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_FULL_ORGANIZATION_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("Data center added", TOAST_SETTINGS);
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    return (
        <DialogHESP
            title={"Assign TLA data centers to organization"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {allDatacenters.map((dc) => (
                                <Grid item xs={12}>
                                    <Wrapper>
                                        <div>
                                            <div style={{ fontWeight: "bolder", fontSize: "14px" }}>{dc.name}</div>
                                            <div style={{ fontSize: "13px" }}>{dc.id}</div>
                                        </div>
                                        <div>
                                            {alreadyConnectedIds.includes(dc.id) ? (
                                                <>
                                                    <Check color="success" />
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        disabled={loading}
                                                        onClick={() => assign(dc.id)}
                                                    >
                                                        connect
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </Wrapper>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </>
            }
        />
    );
}

import React from "react";
import styled from "styled-components";

interface Props {
    children?: React.ReactNode;
}

const Wrapper = styled.div`
    padding-top: 110px;
    padding-left: 330px;
    padding-right: 20px;
    background-color: white;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 100px;
    @media (max-width: 900px) {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 100px;
    }
`;

const InnerWrapper = styled.div`
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
`;

function CenterPage({ children }: Props) {
    return (
        <Wrapper>
            <InnerWrapper>{children}</InnerWrapper>
        </Wrapper>
    );
}

export default CenterPage;

import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    subtitle?: string;
    button?: React.ReactNode;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    min-height: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
`;

const Subtitle = styled.div`
    font-weight: 500;
    color: ${(props) => props.theme.gray};
    font-size: 12px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

function CardHESPTitle({ title, subtitle, button }: Props) {
    return (
        <Wrapper>
            <TitleWrapper>
                <Title>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </TitleWrapper>
            <div>{button}</div>
        </Wrapper>
    );
}

export default CardHESPTitle;

import * as React from "react";
import { useForm } from "react-hook-form";
import { DialogContent, Grid, Typography, FormControl, TextField, DialogActions } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import { useMutation } from "@apollo/react-hooks";
import { UpdateChannelMutation, UpdateChannelMutationVariables } from "../Overview/__generated__/UpdateChannelMutation";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import {
    GET_CHANNELS_ORGANIZATION_QUERY,
    GET_CHANNEL_QUERY,
    UPDATE_CHANNEL_MUTATION,
} from "../Overview/channelQueriesMutations";

interface Props {
    open: boolean;
    channel: GetChannelQuery_channel;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
}

interface FormData {
    name: string;
}

function EditChannelDialog({ open, channel, onClose, onSuccess, onError }: Props) {
    const { handleSubmit, register, errors } = useForm<FormData>();

    const [updateChannelMut, { loading }] = useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(
        UPDATE_CHANNEL_MUTATION,
    );

    const { metadata, channelId, organizationId } = channel;

    const editChannel = handleSubmit(async ({ name }: FormData) => {
        try {
            await updateChannelMut({
                variables: { input: { channelId, organizationId: channel.organizationId, metadata: { name } } },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            onSuccess();
        } catch (e: any) {
            onError();
        }
        onClose();
    });

    return (
        <DialogHESP
            title={"Edit channel"}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit((_ignore, e) => editChannel(e))}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Name" />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={metadata.name}
                                        name="name"
                                        inputRef={register({
                                            required: "Name is required",
                                        })}
                                    />
                                    <Typography variant="subtitle2" color="error">
                                        {errors.name && errors.name.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default EditChannelDialog;

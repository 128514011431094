import * as React from "react";
import { GeoBlockingModeEnum } from "../../../__generated__/globalTypes";
import styled from "styled-components";
import { Public, VpnLock } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import TypeSelectorItemWithIcon from "./TypeSelectorItemWithIcon";

const Wrapper = styled.div`
    display: flex;
    margin-top: 10px;
    @media (max-width: 620px) {
        flex-direction: column;
    }
`;

interface Props {
    mode: GeoBlockingModeEnum;
    onChange: (newMode: GeoBlockingModeEnum) => void;
}

export default function GeoBlockingModeSelector({ mode, onChange }: Props) {
    const theme = useTheme();
    const disabledColor = "rgba(0, 0, 0, 0.12)";

    return (
        <Wrapper>
            <TypeSelectorItemWithIcon
                selected={mode === GeoBlockingModeEnum.whitelist}
                icon={
                    <Public
                        style={{
                            fontSize: "22px",
                            color: mode === GeoBlockingModeEnum.whitelist ? theme.palette.primary.main : disabledColor,
                        }}
                    />
                }
                title="Whitelist"
                description="Only countries in the list will have access to the stream"
                onClick={() => onChange(GeoBlockingModeEnum.whitelist)}
            />
            <TypeSelectorItemWithIcon
                selected={mode === GeoBlockingModeEnum.blacklist}
                icon={
                    <VpnLock
                        style={{
                            fontSize: "22px",
                            color: mode === GeoBlockingModeEnum.blacklist ? theme.palette.primary.main : disabledColor,
                        }}
                    />
                }
                title="Blacklist"
                description="Stream will be blocked for countries in the list"
                onClick={() => onChange(GeoBlockingModeEnum.blacklist)}
            />
        </Wrapper>
    );
}

import { DialogActions, DialogContent, FormControl, FormHelperText, Grid, TextField } from "@mui/material";
import DialogHESP from "../../DialogHESP/DialogHESP";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import DialogFooterWithLoading from "../../Loading/DialogFooterWithLoading";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import React from "react";
import SwitchWithTitle from "../../SwitchWithTitle/SwitchWithTitle";
import {
    GET_CHANNELS_ORGANIZATION_QUERY,
    GET_CHANNEL_QUERY,
    UPDATE_CHANNEL_MUTATION,
} from "../Overview/channelQueriesMutations";
import { UpdateChannelMutation, UpdateChannelMutationVariables } from "../Overview/__generated__/UpdateChannelMutation";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";

interface Props {
    channel: GetChannelQuery_channel;
    open: boolean;
    onClose: () => void;
}

export default function ChannelChangeAutostopDialog({ channel, onClose, open }: Props) {
    const [amount, setAmount] = React.useState<number>(channel.ingestTimeout);

    const [updateChannelMut, { loading }] = useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(
        UPDATE_CHANNEL_MUTATION,
    );

    const MIN_AMOUNT = 60;

    async function onSave() {
        try {
            await updateChannelMut({
                variables: {
                    input: {
                        channelId: channel.channelId,
                        ingestTimeout: amount,
                        organizationId: channel.organizationId,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNEL_QUERY,
                        variables: {
                            channelId: channel.channelId,
                            organizationId: channel.organizationId,
                        },
                    },
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId: channel.organizationId,
                        },
                    },
                ],
            });
            onClose();
            toast.success("Auto-stop settings updated", TOAST_SETTINGS);
        } catch (_e) {
            toast.error("Something went wrong", TOAST_SETTINGS);
        }
    }

    return (
        <DialogHESP
            title={"Change auto-stop settings"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <FormControl fullWidth>
                                    <div>
                                        <SwitchWithTitle
                                            checked={amount !== 0}
                                            onChange={() => setAmount(amount === 0 ? MIN_AMOUNT : 0)}
                                            title="Channel should stop automatically"
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                            {amount !== 0 && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <HESPFormLabel
                                            label="Ingest timeout in seconds"
                                            description={`Channel will stop automatically when no ingest has been received for ${amount} seconds `}
                                        />
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            type="number"
                                            size="small"
                                            onChange={(e) => setAmount(parseInt(e.target.value))}
                                            value={amount}
                                            InputProps={{
                                                inputProps: { min: MIN_AMOUNT, max: 60000, step: 1 },
                                                style: {
                                                    minWidth: "80px",
                                                    fontSize: "14px",
                                                    padding: "0px 10px",
                                                    height: "43px",
                                                },
                                            }}
                                        />
                                        {amount < 60 && (
                                            <FormHelperText style={{ color: "red" }}>
                                                Amount should be 60 seconds or higher
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onActionClick={onSave}
                            disabled={amount !== 0 && amount < MIN_AMOUNT}
                            onCancelClick={onClose}
                            defaultText={"Update"}
                            loadingText={"Updating..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

import { lighten } from "@mui/material";
import * as React from "react";
import { InvoiceStatusType } from "./../../__generated__/globalTypes";

interface Props {
    status: InvoiceStatusType;
}

function InvoiceStatusChip({ status }: Props) {
    return (
        <div
            style={{
                backgroundColor: lighten(getColorForStatus(status), 0.95),
                color: getColorForStatus(status),
                padding: "5px 10px",
                borderRadius: "20px",
                textAlign: "center",
                fontSize: "12px",
                width: "120px",
                marginTop: "5px",
                marginBottom: "5px",
                fontWeight: 600,
            }}
        >
            {status.toUpperCase()}
        </div>
    );
}

function getColorForStatus(status: InvoiceStatusType): string {
    switch (status) {
        case "draft":
            return "#11a0d9";
        case "uncollectible":
            return "#f54242";
        case "paid":
            return "#389c4a";
        case "open":
            return "#dbb600";
        case "void":
            return "#787878";
        default:
            return "#787878";
    }
}

export default InvoiceStatusChip;

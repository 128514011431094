import * as React from "react";
import { TextField, MenuItem, styled } from "@mui/material";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import Loading from "../Loading/Loading";
import { GetFpsValuesQuery, GetFpsValuesQuery_fpsValues } from "./__generated__/GetFpsValuesQuery";

export const GET_FPS_VALUES_QUERY = gql`
    query GetFpsValuesQuery {
        fpsValues {
            fps
        }
    }
`;

interface Props {
    selectedFps: number;
    // eslint-disable-next-line no-unused-vars
    onChangeFps: (fps: number) => void;
    size?: "small" | "medium";
}

function FpsSelector({ selectedFps, onChangeFps, size }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 30px 8px 10px",
            fontSize: "14px",
            minWidth: "80px",
            width: "100%",
        },
    };

    const CssTextField = styled(TextField)(size === "small" && smallCss);

    const { data, loading } = useQuery<GetFpsValuesQuery>(GET_FPS_VALUES_QUERY);

    if (loading || !data) {
        return <Loading />;
    }

    const { fpsValues } = data;

    const smallSizeText = size === "small" ? { fontSize: "14px" } : {};

    return (
        <CssTextField
            select
            name="region"
            onChange={(e: any) => onChangeFps(e.target.value)}
            variant="outlined"
            value={selectedFps}
            fullWidth
        >
            {fpsValues.map(({ fps }: GetFpsValuesQuery_fpsValues) => (
                <MenuItem value={fps} key={fps} selected={selectedFps === fps} style={smallSizeText}>
                    {fps}
                </MenuItem>
            ))}
        </CssTextField>
    );
}

export default FpsSelector;

import * as React from "react";
import { GetFullBillingDetails_organization } from "../BillingDetails/__generated__/GetFullBillingDetails";
import DialogHESP from "../DialogHESP/DialogHESP";
import CreateCommitmentContract from "./CreateCommitmentContract";

interface Props {
    open: boolean;
    onClose: () => void;
    organization: GetFullBillingDetails_organization;
}

function ChangeContractDialog({ open, onClose, organization }: Props) {
    return (
        <DialogHESP
            title={"Create contract"}
            open={open}
            onClose={onClose}
            content={<CreateCommitmentContract organization={organization} onClose={onClose} />}
        />
    );
}

export default ChangeContractDialog;

import * as React from "react";
import styled from "styled-components";
import { GetWebhookLogsQuery_webhookLogs_logs } from "./__generated__/GetWebhookLogsQuery";
import SyntaxHighlighter from "react-syntax-highlighter";
import atomOneLight from "react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light";

interface Props {
    log: GetWebhookLogsQuery_webhookLogs_logs;
}

const Wrapper = styled.div`
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 8px;
    margin-top: 15px;
    padding: 8px;
`;

const TitleWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;
    align-items: center;
`;

const EventTitle = styled.div`
    font-weight: 600;
`;

const StatusBadge = styled.div.attrs((props: { success: boolean }) => ({
    success: props.success,
}))`
    border-radius: 4px;
    background-color: ${(props) => (props.success ? props.theme.success : props.theme.error)};
    color: white;
    font-weight: 600;
    padding: 4px 6px;
`;

const RequestSectionWrapper = styled.div`
    margin-top: 10px;
`;

const RequestTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
`;

function WebhookHistoryDataDetails({ log }: Props) {
    const { requestData, statusCode } = log;
    return (
        <Wrapper>
            <TitleWrapper>
                <EventTitle>{requestData.type}</EventTitle>
                <StatusBadge success={statusCode === 200}>{statusCode}</StatusBadge>
            </TitleWrapper>
            <RequestSectionWrapper>
                <RequestTitle>Request: </RequestTitle>
                <SyntaxHighlighter
                    style={{ ...atomOneLight, padding: "5px 40px 5px 20px !important" }}
                    language={"json"}
                >
                    {JSON.stringify(requestData, null, " ")}
                </SyntaxHighlighter>
            </RequestSectionWrapper>
        </Wrapper>
    );
}

export default WebhookHistoryDataDetails;

import * as React from "react";

import { Button } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import SectionTitleWithAction from "../SectionTitle/SectionTitleWithAction";
import CardHESP from "../CardHESP/CardHESP";
import TeamOverviewTable from "./TeamOverviewTable";
import AddUserDialog from "./AddUserDialog";
import DeleteUserDialog from "./DeleteUserDialog";
import gql from "graphql-tag";
import { GetOrganizationUsers_organization_users } from "./__generated__/GetOrganizationUsers";
import AuthService from "../../auth/AuthService";

export const GET_ORGANIZATION_USERS_QUERY = gql`
    query GetOrganizationUsers($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            organizationId
            users {
                auth0Id
                roles
                email
                createdAt
                picture
                activation {
                    activated
                    url
                    activationMailSentAt
                }
            }
        }
    }
`;

interface Props {
    organizationId: string;
}

function TeamOverview({ organizationId }: Props) {
    const [showAddUser, setShowAddUser] = React.useState<boolean>(false);
    const [showDeleteUser, setShowDeleteUser] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<GetOrganizationUsers_organization_users>();

    function onDeleteUser(user: GetOrganizationUsers_organization_users) {
        setSelectedUser(user);
        setShowDeleteUser(true);
    }

    const isAdmin = AuthService.isAdmin();

    return (
        <>
            <SectionTitleWithAction
                title="My Team"
                action={
                    isAdmin && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PersonAdd />}
                            onClick={() => setShowAddUser(true)}
                        >
                            Add member
                        </Button>
                    )
                }
            />
            <CardHESP>
                <TeamOverviewTable
                    organizationId={organizationId}
                    onDeleteUser={(user: GetOrganizationUsers_organization_users) => onDeleteUser(user)}
                />
            </CardHESP>
            <AddUserDialog organizationId={organizationId} onClose={() => setShowAddUser(false)} open={showAddUser} />
            {selectedUser && (
                <DeleteUserDialog
                    onClose={() => setShowDeleteUser(false)}
                    open={showDeleteUser}
                    user={selectedUser}
                    organizationId={organizationId}
                />
            )}
        </>
    );
}

export default TeamOverview;

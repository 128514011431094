import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Button, Grid } from "@mui/material";
import {
    GetAvcRoyaltyReportingQuery,
    GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting,
} from "./__generated__/GetAvcRoyaltyReportingQuery";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import CentralPageLoader from "../../components/Loading/CentralPageLoader";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import CardHESP from "../../components/CardHESP/CardHESP";
import CardHESPTitle from "../../components/CardHESP/CardHESPTitle";
import AvcPeriodSelector, {
    AvcRoyaltyPeriod as AvcRoyaltyPeriodItem,
} from "../../components/AvcRoyaltyReporting/AvcPeriodSelector";
import moment from "moment";
import AvcRoyaltyTotals from "../../components/AvcRoyaltyReporting/AvcRoyaltyTotals";
import AvcRoyaltyPeriod from "../../components/AvcRoyaltyReporting/AvcRoyaltyPeriod";
import { CSVLink } from "react-csv";
import { getName } from "country-list";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export const GET_AVC_ROYALTY_REPORTING = gql`
    query GetAvcRoyaltyReportingQuery {
        organizationsWithAvcReporting {
            organizationId
            name
            isIndividual
            avcRoyaltyReporting {
                maxConcurrentOverall
                periods {
                    start
                    end
                    maxConcurrentInPeriod
                    toReport
                }
            }
            billingDetails {
                country
            }
        }
    }
`;

function AvcRoyaltyReporting({ organizationId }: Props) {
    const periods: AvcRoyaltyPeriodItem[] = [
        { start: "2021-04-16T00:00:00.000Z", end: "2021-10-31T00:00:00.000Z" },
        ...constructPeriods(),
    ];

    const [selectedPeriod, setSelectedPeriod] = React.useState<string>("total");

    const { data, loading } = useQuery<GetAvcRoyaltyReportingQuery>(GET_AVC_ROYALTY_REPORTING);

    if (!data || loading) {
        return <CentralPageLoader text="Getting AVC Royalty data data..." />;
    }

    const filteredOrgs = data.organizationsWithAvcReporting.filter(
        (o) => o.avcRoyaltyReporting!.maxConcurrentOverall > 0,
    );

    const getCsvData = (data: GetAvcRoyaltyReportingQuery_organizationsWithAvcReporting[]) => {
        const result = data
            .map((item) => {
                return {
                    id: item.organizationId,
                    name: item.name,
                    country: getName(item.billingDetails!.country),
                    toReport: item.avcRoyaltyReporting?.periods.find((p) => p.start === selectedPeriod)?.toReport,
                };
            })
            .filter((item) => item.toReport);
        return result;
    };

    const csvHeaders = ["id", "name", "country", "toReport"];
    const csvData = getCsvData(data.organizationsWithAvcReporting);

    return (
        <Grid container>
            <Grid item xs={12}>
                <SectionTitle title={`AVC Royalty Reporting`} align="left" showBottomBorder />
            </Grid>
            <Grid item xs={12}>
                <CardHESP>
                    <CardHESPTitle
                        title="Details per period"
                        button={
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item>
                                    {selectedPeriod !== "total" && (
                                        <CSVLink
                                            data={csvData}
                                            headers={csvHeaders}
                                            filename={"AvcRoyaltyReport.csv"}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <Button variant="contained" style={{ color: "black" }} color="inherit">
                                                Export as CSV
                                            </Button>
                                        </CSVLink>
                                    )}
                                </Grid>
                                <Grid item>
                                    <AvcPeriodSelector
                                        periods={periods}
                                        selected={selectedPeriod}
                                        onChangeSelected={(val: string) =>
                                            setSelectedPeriod(
                                                val === "total"
                                                    ? "total"
                                                    : moment(val).utc().startOf("day").toISOString(),
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        }
                    />
                    {selectedPeriod === "total" ? (
                        <AvcRoyaltyTotals organizations={filteredOrgs} />
                    ) : (
                        <AvcRoyaltyPeriod organizations={filteredOrgs} selectedPeriodStart={selectedPeriod} />
                    )}
                </CardHESP>
            </Grid>
        </Grid>
    );
}

export default AvcRoyaltyReporting;

function constructPeriods(): AvcRoyaltyPeriodItem[] {
    const periods: AvcRoyaltyPeriodItem[] = [];
    const currentStart = moment("2021-11-15").utc().startOf("month");
    const currentEnd = moment("2022-04-15").utc().endOf("month").startOf("day");

    while (currentStart < moment()) {
        periods.push({
            start: currentStart.toISOString(),
            end: currentEnd.toISOString(),
        });
        currentStart.add(6, "months").utc().startOf("month");
        currentEnd.add(6, "months").utc().endOf("month").startOf("day");
    }

    return periods;
}

import { Button, Grid } from "@mui/material";
import moment from "moment";
import * as React from "react";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import { Search } from "@mui/icons-material";
import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers";

interface Props {
    startDate: moment.Moment;
    endDate: moment.Moment;
    onChangeStart: (day: moment.Moment) => void;
    onChangeEnd: (day: moment.Moment) => void;
    onSubmit: () => void;
}

const Wrapper = styled.div`
    border-radius: 8px;
    margin-top: 10px;
    background-color: ${(props) => props.theme.grayBackground};
    padding: 16px 12px;
`;

export default function OrganizationCommitmentUsageSettings({
    endDate,
    onSubmit,
    startDate,
    onChangeEnd,
    onChangeStart,
}: Props) {
    return (
        <Wrapper>
            <Grid container spacing={2} justifyContent={"center"} alignItems="flex-end">
                <Grid item xs={6} lg={3}>
                    <HESPFormLabel label="Start" />
                    <DatePicker
                        openTo="day"
                        format="YYYY/MM/DD"
                        views={["day"]}
                        value={startDate}
                        onChange={(newValue: moment.Moment | null) => {
                            if (newValue !== null) {
                                onChangeStart(newValue);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <HESPFormLabel label="End" />
                    <DatePicker
                        disableFuture
                        openTo="day"
                        format="YYYY/MM/DD"
                        views={["day"]}
                        value={endDate}
                        onChange={(newValue: moment.Moment | null) => {
                            if (newValue !== null) {
                                onChangeEnd(newValue);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Search />}
                        style={{ marginBottom: "5px" }}
                        onClick={onSubmit}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

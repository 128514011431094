import * as React from "react";
import { Settings, Visibility } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import styled from "styled-components";
import UsageSummaryMinutes from "./UsageSummaryMinutes";
import { UsageType } from "../../__generated__/globalTypes";
import GradientIcon from "./GradientIcon";

interface Props {
    type: UsageType;
    organizationId: string;
    startDate: string;
    endDate: string;
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
`;

const IconDiv = styled.div`
    display: flex;
    width: 60px;
    margin-right: 50px;
    @media (max-width: 400px) {
        margin-right: 30px;
        width: 50px;
    }
`;

const StatisticsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Minutes = styled.div`
    margin-top: 10px;
    color: #5c5c5c;
    font-size: 14px;
`;

function UsageSummaryItem({ type, organizationId, startDate, endDate }: Props) {
    const theme = useTheme();

    const style = {
        fontSize: "54px",
        fill: "url(#linearColors)", // defined in GradientIcon.tsx
        [theme.breakpoints.down(400)]: {
            fontSize: "32px",
        },
    };

    return (
        <Wrapper>
            <IconDiv>{getIcon(type, style)}</IconDiv>
            <StatisticsDiv>
                <UsageSummaryMinutes
                    organizationId={organizationId}
                    startDate={startDate}
                    endDate={endDate}
                    type={type}
                />
                <Minutes>minutes {type}</Minutes>
            </StatisticsDiv>
        </Wrapper>
    );
}

export default UsageSummaryItem;

function getIcon(type: UsageType, style: object) {
    if (type === "transcoded") {
        // return <Settings style={style} />;
        return <GradientIcon icon={<Settings style={style} />} />;
    }
    return <GradientIcon icon={<Visibility style={style} />} />;
}

import React from "react";
import { TableRowSkeleton } from "../AdminSignUps/AdminSignUpsTableSkeleton";
import { SkeletonRectangle } from "../Loading/Skeletons";

function OrganizationOverviewSkeleton() {
    return (
        <>
            {[0, 1, 2, 3, 4].map((i) => (
                <TableRowSkeleton key={`skeleton-row-${i}`}>
                    <SkeletonRectangle width={"40%"} />
                    <SkeletonRectangle width={"15%"} />
                    <SkeletonRectangle width={"15%"} />
                    <SkeletonRectangle width={"10%"} />
                    <SkeletonRectangle width={"10%"} />
                    <SkeletonRectangle width={"10%"} />
                </TableRowSkeleton>
            ))}
        </>
    );
}

export default OrganizationOverviewSkeleton;

import * as React from "react";
import { Button, Grid } from "@mui/material";
import { LiveTv, PlayArrow } from "@mui/icons-material";
import { navigate } from "@reach/router";
import CardHESP from "../CardHESP/CardHESP";
import SectionTitle from "../SectionTitle/SectionTitle";
import AnalyticsCard from "../Analytics/AnalyticsCard";
import styled from "styled-components";
import { GET_CHANNELS_ORGANIZATION_QUERY } from "../Channels/Overview/channelQueriesMutations";
import {
    GetChannelsOrganizationQuery,
    GetChannelsOrganizationQueryVariables,
} from "../Channels/Overview/__generated__/GetChannelsOrganizationQuery";
import { useQuery } from "react-apollo";
import { ChannelStatusType } from "../../__generated__/globalTypes";

interface Props {
    organizationId: string;
}

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.lightBlueBackground};
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 6px;
`;

const Description = styled.div`
    font-size: 15px;
    @media (max-width: 500px) {
        margin-bottom: 15px;
    }
`;

function GetStarted({ organizationId }: Props) {
    const { data, loading } = useQuery<GetChannelsOrganizationQuery, GetChannelsOrganizationQueryVariables>(
        GET_CHANNELS_ORGANIZATION_QUERY,
        {
            variables: {
                organizationId,
            },
        },
    );

    const amountChannels = data?.channels.length;

    const startedChannels = data?.channels.filter((c) => c.channelStatus === ChannelStatusType.playing).length;

    return (
        <>
            <SectionTitle title="Getting started" showBottomBorder align="left" />
            <CardHESP style={{ height: "90%" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={12} lg={6}>
                        <AnalyticsCard
                            icon={<PlayArrow fontSize="inherit" />}
                            iconColor="green"
                            isLoading={loading}
                            small
                            title="Channels playing"
                            amount={startedChannels}
                            backgroundColor="#e4f5e8"
                            onClick={() => navigate(`/app/${organizationId}/channels?status=playing`)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6}>
                        <AnalyticsCard
                            icon={<LiveTv fontSize="inherit" />}
                            iconColor="#FFC713"
                            small
                            isLoading={loading}
                            title="Channels created"
                            amount={amountChannels}
                            backgroundColor="#FFF9E7"
                            onClick={() => navigate(`/app/${organizationId}/channels`)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Wrapper>
                            <TextWrapper>
                                <Title>🚀 High-quality real-time video at scale</Title>
                                <Description>Create a channel and start sharing your THEOlive stream!</Description>
                            </TextWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => navigate(`/app/${organizationId}/channels/create`)}
                            >
                                Create channel
                            </Button>
                        </Wrapper>
                    </Grid>
                </Grid>
            </CardHESP>
        </>
    );
}

export default GetStarted;

import { GET_REGIONS_QUERY } from "../../components/Regions/RegionSelector";
import { GET_TOKENS_QUERY } from "../../components/TokensOverview/TokensOverview";
import { GET_CHANNELS_ORGANIZATION_QUERY } from "../../components/Channels/Overview/channelQueriesMutations";
import { GET_FULL_BILLING_DETAILS } from "../../components/BillingDetails/BillingDetailsCardContent";
import { GET_INVOICES_ORGANIZATION } from "../../components/Invoices/InvoicesOverview";
import { GET_ORGANIZATION_USERS_QUERY } from "../../components/TeamOverview/TeamOverview";
import ApolloClient from "apollo-client";
import AuthService from "../../auth/AuthService";
import { GET_FPS_VALUES_QUERY } from "../../components/Fps/FpsSelector";
import { GET_THEOLIVE_WEBHOOK_EVENTS } from "./CreateWebhook";
import { GET_WEBHOOKS_ORGANIZATION } from "../../components/WebhooksOverview/WebhooksOverview";

export function preloadMainQueries(client: ApolloClient<any>, organizationId: string) {
    const isAdmin = AuthService.isAdmin();

    client.query({
        query: GET_REGIONS_QUERY,
        variables: {
            showDeprecated: false,
        },
    });
    client.query({
        query: GET_FPS_VALUES_QUERY,
    });
    client.query({
        query: GET_TOKENS_QUERY,
        variables: {
            organizationId,
        },
    });
    client.query({
        query: GET_CHANNELS_ORGANIZATION_QUERY,
        variables: {
            organizationId,
        },
    });
    client.query({
        query: GET_ORGANIZATION_USERS_QUERY,
        variables: {
            organizationId,
        },
    });
    client.query({
        query: GET_WEBHOOKS_ORGANIZATION,
        variables: {
            organizationId,
        },
    });

    client.query({
        query: GET_THEOLIVE_WEBHOOK_EVENTS,
    });

    if (isAdmin) {
        client.query({
            query: GET_FULL_BILLING_DETAILS,
            variables: {
                organizationId,
            },
        });
        client.query({
            query: GET_INVOICES_ORGANIZATION,
            variables: {
                organizationId,
            },
        });
    }
}

import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { MaintenanceSettings } from "./MaintenanceManagement";
import { MaintenanceRecurringPeriodType } from "../../../__generated__/globalTypes";

interface Props {
    settings: MaintenanceSettings;
    onPeriodChange: (period: MaintenanceRecurringPeriodType) => void;
    onAmountChange: (amount: number) => void;
}

export default function MaintenanceRecurringPeriodSelector({ onAmountChange, onPeriodChange, settings }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 10px 8px 15px",
            fontSize: "14px",
            width: "90px",
            maxWidth: "90px",
        },
    };

    const CssTextField = styled(TextField)(smallCss);
    const smallSizeText = { fontSize: "14px" };

    const maxAmount: Record<MaintenanceRecurringPeriodType, number> = {
        [MaintenanceRecurringPeriodType.days]: 14,
        [MaintenanceRecurringPeriodType.weeks]: 6,
        [MaintenanceRecurringPeriodType.month]: 1,
    };

    return (
        <>
            <CssTextField
                select
                name="interval-selector"
                onChange={(e: any) => onAmountChange(e.target.value as number)}
                variant="outlined"
                value={settings.interval!.amount}
            >
                {[...Array(maxAmount[settings.interval!.period]).keys()].map((a) => (
                    <MenuItem value={a + 1} key={`selector-${a + 1}`} style={smallSizeText}>
                        Every {a + 1}
                    </MenuItem>
                ))}
            </CssTextField>
            <CssTextField
                select
                name="period"
                onChange={(e: any) => onPeriodChange(e.target.value as MaintenanceRecurringPeriodType)}
                variant="outlined"
                value={settings.interval!.period}
                style={{ marginLeft: "15px" }}
            >
                <MenuItem
                    value={MaintenanceRecurringPeriodType.days}
                    key={`period-selector-days`}
                    style={smallSizeText}
                >
                    day(s)
                </MenuItem>
                <MenuItem
                    value={MaintenanceRecurringPeriodType.weeks}
                    key={`period-selector-week`}
                    style={smallSizeText}
                >
                    week(s)
                </MenuItem>
                <MenuItem
                    value={MaintenanceRecurringPeriodType.month}
                    key={`period-selector-month`}
                    style={smallSizeText}
                >
                    month
                </MenuItem>
            </CssTextField>
        </>
    );
}

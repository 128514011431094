import { FormControl, Grid, TextField, Typography } from "@mui/material";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import HESPFormLabel from "../../HESPFormLabel/HESPFormLabel";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import { PlayoutSecuritySettings } from "./ChannelPlayoutSecurity";
import {
    ManageChannelTokenSecurity,
    ManageChannelTokenSecurityVariables,
} from "./__generated__/manageChannelTokenSecurity";
import ContentSettingsWithSwitchCard from "./ContentSettingsWithSwitchCard";

interface Props {
    organizationId: string;
    settings: PlayoutSecuritySettings;
}

export const MANAGE_CHANNEL_TOKEN_SECURITY = gql`
    mutation ManageChannelTokenSecurity($input: ManageChannelTokenSecurityInput!) {
        manageChannelTokenSecurity(input: $input)
    }
`;

export default function ChannelPlayoutTokenSecurity({ settings, organizationId }: Props) {
    const initialConfig = settings.jwt;

    const { channelId, isAlias, parentChannelId } = settings;

    const [enabled, setEnabled] = React.useState<boolean>(initialConfig.enabled);
    const [key, setKey] = React.useState("");
    const [needsSave, setNeedsSave] = React.useState<boolean>(false);
    const [currentId, setCurrentId] = React.useState<string | undefined>(channelId);

    const [manageTokenSecurityMut, { loading }] = useMutation<
        ManageChannelTokenSecurity,
        ManageChannelTokenSecurityVariables
    >(MANAGE_CHANNEL_TOKEN_SECURITY);

    React.useEffect(() => {
        if (!currentId || currentId !== channelId) {
            setEnabled(initialConfig.enabled);
            setCurrentId(channelId);
            setKey(initialConfig.key);
        }
    }, [channelId, initialConfig, currentId]);

    async function onSave() {
        if ((enabled && key.length > 0) || !enabled) {
            try {
                await manageTokenSecurityMut({
                    variables: {
                        input: {
                            channelId,
                            organizationId,
                            parentChannelId,
                            isAlias,
                            enable: enabled,
                            key,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_CHANNEL_QUERY,
                            variables: {
                                channelId: parentChannelId,
                                organizationId,
                            },
                        },
                    ],
                });
                setNeedsSave(false);
                toast.success(`Token security ${enabled ? "enabled" : "disabled"}`);
            } catch (_e) {
                toast.error("Something went wrong", TOAST_SETTINGS);
            }
        }
    }

    function onDiscard() {
        setEnabled(initialConfig.enabled);
        setKey("");
        setNeedsSave(false);
    }

    function onKeyChange(value: string) {
        setKey(value);
        setNeedsSave(true);
    }

    function onEnabledChange() {
        setEnabled(!enabled);
        if (!enabled === initialConfig.enabled) {
            setNeedsSave(false);
        } else {
            setNeedsSave(true);
        }
    }

    const shouldPassKey = initialConfig.enabled === false && enabled && key.length === 0;

    return (
        <>
            <ContentSettingsWithSwitchCard
                title="Token security"
                subtitle="Passes a JWT token on every call the player makes to the CDN, which will determine if content can be shared with end user"
                enabled={enabled}
                onSaveClick={onSave}
                showSwitch={true}
                needsSave={needsSave}
                onEnabledChange={onEnabledChange}
                onDiscardClick={onDiscard}
                isLoading={loading}
            >
                {enabled && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <HESPFormLabel label="Shared (in case of HS256/HS512) or public (RS256/RS512) key to use" />
                                <TextField
                                    variant="outlined"
                                    style={{ background: "white" }}
                                    fullWidth
                                    value={key}
                                    multiline
                                    rows={10}
                                    placeholder={
                                        shouldPassKey
                                            ? "Your public/shared key to use"
                                            : "Public key passed, passing another one here will overwrite the saved one"
                                    }
                                    inputProps={{
                                        style: {
                                            padding: "8px 10px",
                                            background: "white",
                                            fontSize: "12px",
                                        },
                                    }}
                                    size="small"
                                    onChange={(e) => onKeyChange(e.target.value)}
                                />
                            </FormControl>
                            {shouldPassKey && (
                                <Typography variant="body2" color="error">
                                    Please pass a public/shared key
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                )}
            </ContentSettingsWithSwitchCard>
            <ToastContainer />
        </>
    );
}

import * as React from "react";
import styled from "styled-components";
import { GetFullBillingDetails_organization } from "../BillingDetails/__generated__/GetFullBillingDetails";
import ContractDangerZoneItem from "./ContractDangerZoneItem";
import RemoveOrganizationDialog from "./RemoveOrganizationDialog";

const Wrapper = styled.div`
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.error};
`;

const Title = styled.div`
    color: ${(props) => props.theme.error};
    background: #fafafa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 600;
    padding: 10px 10px;
    font-size: 14px;
`;

const Content = styled.div`
    padding: 5px 10px;
`;

interface Props {
    organization: GetFullBillingDetails_organization;
}

function ContractDangerZone({ organization }: Props) {
    const [openRemoveOrgDialog, setOpenRemoveDialog] = React.useState<boolean>(false);

    return (
        <>
            {" "}
            <Wrapper>
                <Title>Danger zone</Title>
                <Content>
                    <ContractDangerZoneItem
                        title={"Delete organization"}
                        subtitle={"Remove the organization and connected users, tokens and channels from THEOlive"}
                        buttonText="Delete"
                        onClick={() => setOpenRemoveDialog(true)}
                    />
                </Content>
            </Wrapper>
            <RemoveOrganizationDialog
                open={openRemoveOrgDialog}
                organization={organization}
                onClose={() => setOpenRemoveDialog(false)}
            />
        </>
    );
}

export default ContractDangerZone;

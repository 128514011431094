import * as React from "react";
import styled from "styled-components";
import { OrderDirectionType, SchedulerPhaseType } from "../../__generated__/globalTypes";
import SmallSchedulerItem from "./SmallSchedulerItem";
import StartSchedulerNowDialog from "./StartSchedulerNowDialog";
import DeleteSchedulerDialog from "./DeleteSchedulerDialog";
import TerminateSchedulerDialog from "./TerminateSchedulerDialog";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
    GetSchedulersBasedOnParams,
    GetSchedulersBasedOnParamsVariables,
    GetSchedulersBasedOnParams_schedulersWithParams,
} from "./__generated__/GetSchedulersBasedOnParams";
import { SkeletonRectangle } from "../Loading/Skeletons";
import { Button } from "@mui/material";
import { navigate } from "@reach/router";

interface Props {
    title: string;
    icon: React.ReactNode;
    phases: SchedulerPhaseType[];
    organizationId: string;
}

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
`;

const OverviewWrapper = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const GET_SCHEDULERS_BASED_ON_PARAMS = gql`
    query GetSchedulersBasedOnParams(
        $organizationId: ID!
        $start: String
        $end: String
        $page: Int!
        $search: String
        $orderBy: String!
        $orderDirection: OrderDirectionType!
        $limit: Int!
        $phases: [SchedulerPhaseType!]
    ) {
        schedulersWithParams(
            organizationId: $organizationId
            start: $start
            end: $end
            search: $search
            page: $page
            orderBy: $orderBy
            orderDirection: $orderDirection
            limit: $limit
            phases: $phases
        ) {
            schedulerId
            organizationId
            name
            start
            end
            phase
            clientId
            numberOfChannelsConnected
        }
    }
`;

export default function SchedulerSection({ title, icon, phases, organizationId }: Props) {
    const [selectedScheduler, setSelectedScheduler] = React.useState<
        GetSchedulersBasedOnParams_schedulersWithParams | undefined
    >(undefined);
    const [openDeleteScheduler, setOpenDeleteScheduler] = React.useState<boolean>(false);
    const [openTerminateScheduler, setOpenTerminateScheduler] = React.useState<boolean>(false);
    const [openStartSchedulerNow, setOpenStartSchedulerNow] = React.useState<boolean>(false);

    const variables = {
        limit: 5,
        orderBy: "start",
        orderDirection: OrderDirectionType.desc,
        organizationId,
        page: 1,
        phases,
    };

    const { data, loading } = useQuery<GetSchedulersBasedOnParams, GetSchedulersBasedOnParamsVariables>(
        GET_SCHEDULERS_BASED_ON_PARAMS,
        {
            variables,
        },
    );

    function onDeleteScheduler(scheduler: GetSchedulersBasedOnParams_schedulersWithParams) {
        setSelectedScheduler(scheduler);
        setOpenDeleteScheduler(true);
    }

    function onTerminateScheduler(scheduler: GetSchedulersBasedOnParams_schedulersWithParams) {
        setSelectedScheduler(scheduler);
        setOpenTerminateScheduler(true);
    }

    function onStartSchedulerNow(scheduler: GetSchedulersBasedOnParams_schedulersWithParams) {
        setSelectedScheduler(scheduler);
        setOpenStartSchedulerNow(true);
    }

    return (
        <div style={{ height: "100%" }}>
            <Title>
                {icon}
                <div>{title}</div>
            </Title>
            <div style={{ height: "100%" }}>
                {(!data || loading) && (
                    <>
                        <SkeletonRectangle />
                        <SkeletonRectangle />
                        <SkeletonRectangle />
                    </>
                )}
                {data?.schedulersWithParams.length === 0 && <>No schedulers found</>}
                {data && data?.schedulersWithParams.length > 0 && (
                    <OverviewWrapper>
                        <div>
                            {data.schedulersWithParams.map((sched, i) => (
                                <SmallSchedulerItem
                                    key={`sched-${sched.schedulerId}`}
                                    scheduler={sched}
                                    onDelete={() => onDeleteScheduler(sched)}
                                    onTerminate={() => onTerminateScheduler(sched)}
                                    onStart={() => onStartSchedulerNow(sched)}
                                />
                            ))}
                        </div>
                        {data.schedulersWithParams.length > 2 && (
                            <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() =>
                                        navigate(
                                            `/app/${organizationId}/schedulers/overview?phases=${phases.join(",")}`,
                                        )
                                    }
                                >
                                    Show more
                                </Button>
                            </div>
                        )}
                    </OverviewWrapper>
                )}
            </div>
            {selectedScheduler && (
                <>
                    <StartSchedulerNowDialog
                        onClose={() => setOpenStartSchedulerNow(false)}
                        open={openStartSchedulerNow}
                        scheduler={{
                            id: selectedScheduler?.schedulerId,
                            name: selectedScheduler?.name,
                            organizationId,
                        }}
                        queryParamsVariables={variables}
                    />
                    <DeleteSchedulerDialog
                        open={openDeleteScheduler}
                        onClose={() => setOpenDeleteScheduler(false)}
                        scheduler={{
                            id: selectedScheduler?.schedulerId,
                            name: selectedScheduler?.name,
                            organizationId,
                        }}
                        view="overview"
                        queryParamsVariables={variables}
                    />
                    <TerminateSchedulerDialog
                        open={openTerminateScheduler}
                        onClose={() => setOpenTerminateScheduler(false)}
                        scheduler={{
                            id: selectedScheduler?.schedulerId,
                            name: selectedScheduler?.name,
                            organizationId,
                        }}
                        queryParamsVariables={variables}
                    />
                </>
            )}
        </div>
    );
}

import { useTheme } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import * as React from "react";
import styled from "styled-components";
import SectionTitle from "../SectionTitle/SectionTitle";
import { ReturnToLogin } from "./ForgotPassword";
import { HESPListItem } from "./SignUpComplete";

const PasswordSent = styled.div`
    margin-top: 30px;
`;

interface Props {
    onBackToLogin: () => void;
}

function ForgotPasswordSent({ onBackToLogin }: Props) {
    const theme = useTheme();

    return (
        <>
            <SectionTitle title="Reset link sent!" align="center" />
            <PasswordSent>
                <HESPListItem>
                    <CheckCircle style={{ color: theme.palette.primary.main }} />
                    <div style={{ marginLeft: "15px" }}>A reset link has been sent to your email address.</div>
                </HESPListItem>
                <ReturnToLogin onClick={onBackToLogin}>Back to Sign In</ReturnToLogin>
            </PasswordSent>
        </>
    );
}

export default ForgotPasswordSent;

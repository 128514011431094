import * as React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import styled from "styled-components";

const Wrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`;

function AccessDenied() {
    return (
        <>
            <SectionTitle title="Access denied" align="center" />
            <Wrapper>
                <p style={{ marginBottom: "40px" }}>Access to the THEOlive console has been denied for you.</p>
            </Wrapper>
        </>
    );
}

export default AccessDenied;

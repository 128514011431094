import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import SchedulerAuditMainDetails from "../../components/SchedulerAudit/SchedulerAuditMainDetails";
import {
    GetSchedulerAuditReportQuery,
    GetSchedulerAuditReportQueryVariables,
} from "./__generated__/GetSchedulerAuditReportQuery";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { SkeletonRectangle } from "../../components/Loading/Skeletons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { SchedulerAuditChannels } from "../../components/SchedulerAudit/SchedulerAuditChannels";
import SchedulerAuditEvents from "../../components/SchedulerAudit/SchedulerAuditEvents";

interface Props extends RouteComponentProps {
    schedulerId?: string;
    organizationId?: string;
}

export const GET_SCHEDULER_AUDIT_REPORT = gql`
    query GetSchedulerAuditReportQuery($schedulerId: ID!, $organizationId: ID!) {
        scheduler(schedulerId: $schedulerId, organizationId: $organizationId) {
            schedulerId
            name
            start
            end
            channelIds
            organizationId
            phase
            clientId
            shouldGenerateReport
            metadata {
                key
                value
            }
            auditDone
            reportViewedSeconds
            usageViewedSeconds
            hadOverlaps
            report {
                status
                link
            }
        }
    }
`;

export default function SchedulerAudit({ schedulerId, organizationId }: Props) {
    const { data, loading } = useQuery<GetSchedulerAuditReportQuery, GetSchedulerAuditReportQueryVariables>(
        GET_SCHEDULER_AUDIT_REPORT,
        {
            variables: {
                schedulerId: schedulerId!,
                organizationId: organizationId!,
            },
        },
    );

    if (loading) {
        return (
            <div>
                <SkeletonRectangle />
                <SkeletonRectangle />
                <SkeletonRectangle />
                <SkeletonRectangle />
                <SkeletonRectangle />
            </div>
        );
    }

    if (!data) {
        return <div>No scheduler found</div>;
    }

    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SchedulerAuditMainDetails scheduler={data.scheduler} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <SectionTitle title="Connected channels" align="left" showBottomBorder />
                        <SchedulerAuditChannels scheduler={data.scheduler} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <SectionTitle title="Events related to scheduler" align="left" showBottomBorder />
                        <SchedulerAuditEvents scheduler={data.scheduler} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

import { Grid } from "@mui/material";
import DeveloperLinkItem from "../DevelopersZone/DeveloperLinkItem";
import HdIcon from "@mui/icons-material/Hd";
import { navigate } from "@reach/router";
import { THEO_ID } from "../../views/App/AppRoot";

export default function AdminConfigContent() {
    return (
        <div style={{ marginTop: "22px" }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4}>
                    <DeveloperLinkItem
                        title="THEOlive ABR profiles"
                        description="Collection of different ABR ladders with their pricing and availability"
                        onClick={() => navigate(`/app/${THEO_ID}/config/profiles`)}
                        icon={<HdIcon />}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    subtitle: string;
    buttonText: string;
    onClick: () => void;
}

const Wrapper = styled.div`
    margin-bottom: 10px;
    padding: 6px 0px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBottom};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
`;

const Subtitle = styled.div`
    margin-top: 5px;
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

function ContractDangerZoneItem({ buttonText, onClick, subtitle, title }: Props) {
    return (
        <Wrapper>
            <div>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </div>
            <div style={{ marginRight: "10px" }}>
                <Button size="small" onClick={onClick} color="secondary" variant="outlined">
                    {buttonText}
                </Button>
            </div>
        </Wrapper>
    );
}

export default ContractDangerZoneItem;

import * as React from "react";
import { AnalyzerEvent, AnalyzerEventType } from "./StreamAnalyzerEvents";
import HESPTable from "../HESPTable/HESPTable";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import moment from "moment";
import { Code } from "../Channels/Details/ChannelEmbedExample";
import styled from "styled-components";

interface Props {
    events: AnalyzerEvent[];
}

const AnalyzerTypeBadge = styled.div.attrs((props: { level: AnalyzerEventType }) => ({
    level: props.level,
}))`
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 11px;
    text-align: center;
    font-weight: 600;
    background-color: ${(props) => getTypeColor(props.level)};
`;

const BadgeWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

function getTypeColor(level: AnalyzerEventType): string {
    switch (level) {
        case "error":
            return "#fdcfcb";
        case "warning":
            return "#ffe7bd";
    }
}

export default function StreamAnalyzerEventsTable({ events }: Props) {
    const headers: TableHeadItem[] = [
        {
            id: "type",
            label: "",
            maxWidth: 140,
        },
        {
            id: "timestamp",
            label: "Timestamp",
            maxWidth: 160,
        },
        {
            id: "media",
            label: "Media type",
            maxWidth: 120,
        },
        {
            id: "message",
            label: "Message",
        },
    ];

    const records = events.map((e) => ({
        type: (
            <BadgeWrapper>
                <AnalyzerTypeBadge level={e.level}>{e.type}</AnalyzerTypeBadge>
            </BadgeWrapper>
        ),
        timestamp: moment(e.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        media: e.mediatype,
        message: <Code>{e.message}</Code>,
    }));

    return (
        <HESPTable
            headers={headers}
            data={records}
            defaultSortOn={"timestamp"}
            defaultSortDirection="desc"
            size="small"
            enablePagination
            paginationRowsPerPage={15}
            paginationRowsPerPageOptions={[15, 30, 50]}
        />
    );
}

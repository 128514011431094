import moment from "moment";
import * as React from "react";
import { SchedulerPhaseType } from "../../__generated__/globalTypes";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { KeyboardArrowRight } from "@mui/icons-material";

interface Props {
    id: string;
    organizationId: string;
    name: string;
    start: moment.Moment;
    end?: moment.Moment;
    phase: SchedulerPhaseType;
}

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
`;

const Name = styled.div`
    font-size: 14px;
`;

const Dates = styled.div`
    margin-top: 4px;
    font-size: 13px;
    color: ${(props) => props.theme.gray};
`;

export default function SchedulerItem({ id, name, organizationId, start, end, phase }: Props) {
    return (
        <Item onClick={() => navigate(`/app/${organizationId}/schedulers/${id}`)}>
            <div>
                <Name>
                    <strong>{name}</strong> |{" "}
                    <span style={{ fontSize: "13px" }}>
                        {start.format("MMM DD, HH:mm")} - {end ? end.format("MMM DD, HH:mm") : "..."}
                    </span>
                </Name>
                <Dates>{id}</Dates>
            </div>
            <div>
                <KeyboardArrowRight color="primary" />
            </div>
        </Item>
    );
}

import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    time: string;
    onTimeChange: (time: string) => void;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
`;

const Divider = styled.div`
    margin: 0px 6px;
    margin-top: 8px;
    text-wrap: nowrap;
`;

export default function MaintenanceTimeSelector({ time, onTimeChange }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 10px 8px 15px",
            fontSize: "14px",
            width: "24px",
        },
    };

    const CssTextField = styled(TextField)(smallCss);
    const smallSizeText = { fontSize: "14px" };

    const hours = [...Array(24).keys()].map((k) => (k < 10 ? `0${k}` : `${k}`));
    const minutes = [...Array(6).keys()].map((k) => (k === 0 ? `00` : `${k * 10}`));

    const hour = time.split(":")[0];
    const minute = time.split(":")[1];

    function changeHour(newHour: string) {
        onTimeChange(`${newHour}:${minute}`);
    }

    function changeMinute(newMinute: string) {
        onTimeChange(`${hour}:${newMinute}`);
    }

    return (
        <Wrapper>
            <Divider style={{ marginRight: "10px", marginLeft: "10px" }}>at</Divider>
            <CssTextField
                select
                name="hours"
                onChange={(e: any) => changeHour(e.target.value)}
                variant="outlined"
                value={hour}
                fullWidth
            >
                {hours.map((h) => (
                    <MenuItem value={h} key={`hour-${h}`} style={smallSizeText}>
                        {h}
                    </MenuItem>
                ))}
            </CssTextField>{" "}
            <Divider>:</Divider>
            <CssTextField
                select
                name="minute"
                onChange={(e: any) => changeMinute(e.target.value)}
                variant="outlined"
                value={minute}
                fullWidth
            >
                {minutes.map((m) => (
                    <MenuItem style={smallSizeText} value={m} key={`minute-${m}`}>
                        {m}
                    </MenuItem>
                ))}
            </CssTextField>
            <Divider style={{ fontSize: "12px", marginTop: "13px", marginLeft: "8px" }}>UTC</Divider>
        </Wrapper>
    );
}

import * as React from "react";
import styled from "styled-components";

import background from "../../img/background-theolive-blue.jpg";
import logoTextWhite from "../../img/theolive-white-horizontal.png";

interface Props {
    children: React.ReactNode;
}

const LandingnBackground = styled.div`
    height: 100vh;
    background-image: url(${background});
    background-size: cover;
`;

const Wrapper = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    padding-top: 40px;
    padding: 10px 15px;
`;

const Content = styled.div`
    margin-top: 80px;
    @media (max-width: 600px) {
        margin-top: 10px;
    }
`;

function LandingWrapper({ children }: Props) {
    return (
        <LandingnBackground>
            <Wrapper>
                <div style={{ marginTop: "10px" }}>
                    <img src={logoTextWhite} width="130" alt="logo" />
                </div>
                <Content>{children}</Content>
            </Wrapper>
        </LandingnBackground>
    );
}

export default LandingWrapper;

import * as React from "react";
import { TextField, MenuItem, styled } from "@mui/material";
import gql from "graphql-tag";
import { GetRegionsQuery, GetRegionsQuery_regions, GetRegionsQueryVariables } from "./__generated__/GetRegionsQuery";
import { useQuery } from "react-apollo";
import Loading from "../Loading/Loading";

export const GET_REGIONS_QUERY = gql`
    query GetRegionsQuery($showDeprecated: Boolean!) {
        regions(showDeprecated: $showDeprecated) {
            regionId
            name
        }
    }
`;

interface Props {
    selectedRegion: string;
    // eslint-disable-next-line no-unused-vars
    onChangeSelected: (region: string) => void;
    showAllRegionsOption?: boolean;
    size?: "small" | "medium";
}

function RegionSelector({ selectedRegion, onChangeSelected, showAllRegionsOption, size }: Props) {
    const smallCss = {
        "& .MuiOutlinedInput-input": {
            padding: "8px 30px 8px 10px",
            fontSize: "14px",
            minWidth: "80px",
            width: "100%",
            backgroundColor: "white",
        },
    };

    const CssTextField = styled(TextField)(size === "small" && smallCss);

    const { data, loading } = useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GET_REGIONS_QUERY, {
        variables: { showDeprecated: false },
    });

    if (loading || !data) {
        return <Loading />;
    }

    const { regions } = data;

    const smallSizeText = size === "small" ? { fontSize: "14px" } : {};

    return (
        <CssTextField
            select
            name="region"
            onChange={(e: any) => onChangeSelected(e.target.value)}
            variant="outlined"
            value={selectedRegion}
            fullWidth
        >
            {showAllRegionsOption && (
                <MenuItem value="all" style={smallSizeText}>
                    All regions
                </MenuItem>
            )}
            {regions.map((region: GetRegionsQuery_regions) => (
                <MenuItem
                    value={region.regionId}
                    key={region.regionId}
                    selected={selectedRegion === region.regionId}
                    style={smallSizeText}
                >
                    {region.regionId}
                </MenuItem>
            ))}
        </CssTextField>
    );
}

export default RegionSelector;

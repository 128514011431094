import GoogleButton from "react-google-button";

interface Props {
    isSignUp: boolean;
    onClick: () => void;
}

function GoogleButtonHESP({ isSignUp, onClick }: Props) {
    return (
        <GoogleButton
            label={`Sign ${isSignUp ? "Up" : "In"} With Google`}
            onClick={onClick}
            style={{
                width: "100%",
                outline: "none",
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "0.875rem",
                boxShadow: "none",
                borderRadius: "4px",
            }}
        />
    );
}

export default GoogleButtonHESP;

import { DialogActions, DialogContent } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { toast } from "react-toastify";
import DialogHESP from "../DialogHESP/DialogHESP";
import { GetOrganizationUsers_organization_users } from "./__generated__/GetOrganizationUsers";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { DeleteUserMutation, DeleteUserMutationVariables } from "./__generated__/DeleteUserMutation";
import { GET_ORGANIZATION_USERS_QUERY } from "./TeamOverview";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const DELETE_USER_MUTATION = gql`
    mutation DeleteUserMutation($input: DeleteUserInput!) {
        deleteUser(input: $input)
    }
`;

interface Props {
    open: boolean;
    onClose: () => void;
    user: GetOrganizationUsers_organization_users;
    organizationId: string;
}

interface FormData {
    email: string;
}

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 30px;
`;

function DeleteUserDialog({ open, onClose, user, organizationId }: Props) {
    const { handleSubmit } = useForm<FormData>();

    const [deleteUserMut, { loading }] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
        DELETE_USER_MUTATION,
    );

    const deleteUser = handleSubmit(async () => {
        try {
            await deleteUserMut({
                variables: {
                    input: {
                        auth0Id: user.auth0Id,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_ORGANIZATION_USERS_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });
            toast.success("User got deleted successfully", TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    });

    return (
        <DialogHESP
            title={"Delete user"}
            open={open}
            onClose={onClose}
            content={
                <form onSubmit={handleSubmit((data1, e) => deleteUser(e))}>
                    <DialogContent>
                        <Title>Are you sure you want to delete {user.email}?</Title>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            defaultText={"Delete"}
                            loadingText={"Deleting..."}
                        />
                    </DialogActions>
                </form>
            }
        />
    );
}

export default DeleteUserDialog;

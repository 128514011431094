import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import AdminAnalytics from "../../components/Analytics/AdminAnalytics";
import AdminSearchOptions from "../../components/AdminSearchOptions/AdminSearchOptions";
import AdminPromotionalChannels from "../../components/AdminPromotionalChannels/AdminPromotionalChannels";
import QuickChannelSearch from "../../components/QuickChannelSearch/QuickChannelSearch";
import AdminTopOrganizations from "../../components/AdminTopOrganizations/AdminTopOrganizations";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function Admin({ organizationId }: Props) {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <AdminAnalytics />
            </Grid>
            <Grid item xs={12} lg={6}>
                <QuickChannelSearch />
                <AdminPromotionalChannels />
            </Grid>
            <Grid item xs={12} lg={6}>
                <AdminTopOrganizations />
            </Grid>
            <Grid item xs={12}>
                <AdminSearchOptions />
            </Grid>
        </Grid>
    );
}

export default Admin;

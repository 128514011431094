import moment from "moment";
import * as React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import InsightsHealthTooltip from "./InsightsHealthTooltip";

export interface IngestHealthChartItem {
    timestamp: string;
    video: number;
    audio: number;
}

export type IngestHealthType = "bitrate" | "fps";

export const INGEST_HEALTH_INTERVAL = 2000;

interface Props {
    items: IngestHealthChartItem[];
    type: IngestHealthType;
    onRefetch: () => void;
    isLive: boolean;
}

function IngestHealthGraph({ items, type, onRefetch, isLive }: Props) {
    React.useEffect(() => {
        if (isLive) {
            const interval = setInterval(() => onRefetch(), INGEST_HEALTH_INTERVAL);

            return () => {
                clearInterval(interval);
            };
        }
    });
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <div style={{ height: "175px", marginTop: "20px" }}>
            <ResponsiveContainer width="100%" height="95%">
                <LineChart
                    data={items.map((i) => ({
                        ...i,
                        audio: i.audio === -1 ? undefined : i.audio,
                        video: i.video === -1 ? undefined : i.video,
                    }))}
                    style={{ fontSize: "13px" }}
                    margin={{
                        // right: 20,
                        left: -25,
                        right: 15,
                    }}
                >
                    <CartesianGrid vertical={false} />
                    <Tooltip content={<InsightsHealthTooltip type={type} />} />
                    <XAxis
                        dataKey="timestamp"
                        tickFormatter={(timestamp: string) => moment(timestamp).tz(tz).format("HH:mm:ss")}
                    />
                    <YAxis allowDecimals={false} />
                    <Line type="linear" dataKey="audio" stroke="#FFC713" isAnimationActive={false} dot={false} />
                    <Line type="linear" dataKey="video" stroke="#9CB9C9" isAnimationActive={false} dot={false} />
                    <Legend
                        verticalAlign="bottom"
                        iconType="circle"
                        iconSize={12}
                        wrapperStyle={{
                            fontSize: 12,
                        }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default IngestHealthGraph;

import * as React from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
    GetPromotionalChannelsByMode,
    GetPromotionalChannelsByModeVariables,
    GetPromotionalChannelsByMode_channelsByMode,
} from "./__generated__/GetPromotionalChannelsByMode";
import AdminPromotionalChannelsTableSkeleton from "./AdminPromotionalChannelsTableSkeleton";
import { ChannelModeType } from "../../__generated__/globalTypes";
import AuthService from "../../auth/AuthService";
import { TableHeadItem } from "../HESPTable/HESPTableHead";
import { HESPlink } from "../Landing/SignUpComplete";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import HESPTable from "../HESPTable/HESPTable";

const NoChannelsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: ${(props) => props.theme.gray};
`;

export const GET_CHANNELS_BY_MODE = gql`
    query GetPromotionalChannelsByMode($channelMode: ChannelModeType!) {
        channelsByMode(channelMode: $channelMode) {
            channelId
            channelMode
            organizationId
            metadata {
                name
            }
            organization {
                organizationId
                name
            }
        }
    }
`;

interface Props {
    onChangeClick: (channel: GetPromotionalChannelsByMode_channelsByMode) => void;
}

function AdminPromotionalChannelsTable({ onChangeClick }: Props) {
    const { data, loading } = useQuery<GetPromotionalChannelsByMode, GetPromotionalChannelsByModeVariables>(
        GET_CHANNELS_BY_MODE,
        {
            variables: {
                channelMode: ChannelModeType.promotional,
            },
        },
    );

    if (loading || !data) {
        return <AdminPromotionalChannelsTableSkeleton />;
    }

    const channels = data.channelsByMode;

    if (channels.length === 0) {
        return <NoChannelsWrapper>Currently no promotional channels</NoChannelsWrapper>;
    }

    const isSalesAdmin = AuthService.isTHEOAdminSales();

    const headers: TableHeadItem[] = [
        {
            id: "id",
            label: "ID",
        },
        {
            id: "organization",
            label: "Organization",
        },
        {
            id: "actions",
            label: "Change",
            hide: !isSalesAdmin,
        },
    ];

    const records = channels.map((channel: GetPromotionalChannelsByMode_channelsByMode) => ({
        id: (
            <HESPlink link={`/app/${channel.organization.organizationId}/channels/${channel.channelId}`}>
                {channel.channelId}
            </HESPlink>
        ),
        organization: (
            <HESPlink link={`/app/${channel.organization.organizationId}`}>{channel.organization.name}</HESPlink>
        ),
        actions: (
            <IconButton size="small" onClick={() => onChangeClick(channel)}>
                <Edit fontSize="small" />
            </IconButton>
        ),
    }));

    return (
        <HESPTable
            headers={headers}
            data={records}
            defaultSortOn={"id"}
            size="small"
            enablePagination
            paginationRowsPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15]}
        />
    );
}

export default AdminPromotionalChannelsTable;

import { FileCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import styled from "styled-components";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { copyToClipboard } from "../CopyLabel/CopyLabel";
import { SkeletonRectangle } from "../Loading/Skeletons";
import { GetWebhookSecretQuery, GetWebhookSecretQueryVariables } from "./__generated__/GetWebhookSecretQuery";

interface Props {
    webhookId: string;
    organizationId: string;
}

export const GET_WEBHOOK_SECRET_QUERY = gql`
    query GetWebhookSecretQuery($webhookId: ID!, $organizationId: ID!) {
        webhook(webhookId: $webhookId, organizationId: $organizationId) {
            webhookId
            secret
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

function WebhookSecretValue({ webhookId, organizationId }: Props) {
    const { data, loading } = useQuery<GetWebhookSecretQuery, GetWebhookSecretQueryVariables>(
        GET_WEBHOOK_SECRET_QUERY,
        {
            variables: {
                webhookId,
                organizationId,
            },
        },
    );

    async function copyValue() {
        try {
            if (typeof data?.webhook.secret === "string") {
                await copyToClipboard(data!.webhook.secret as string);
                toast.success("Value copied", TOAST_SETTINGS);
            }
        } catch (e: any) {
            toast.error("Error while copying", TOAST_SETTINGS);
        }
    }

    return loading || !data ? (
        <SkeletonRectangle width="50%" />
    ) : (
        <Wrapper>
            <div style={{ marginRight: "10px" }}>{data.webhook.secret}</div>
            <div>
                <Tooltip title="Copy">
                    <IconButton size="small" onClick={copyValue}>
                        <FileCopy fontSize="small" style={{ color: "black" }} />
                    </IconButton>
                </Tooltip>
            </div>
        </Wrapper>
    );
}

export default WebhookSecretValue;

import * as React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import AuthService from "../../auth/AuthService";
import GoogleButtonHESP from "../GoogleButtonHESP/GoogleButtonHESP";
import styled from "styled-components";

const Wrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
`;

function AccountsMerged() {
    function signInWithGoogle() {
        AuthService.signInWithGoogle();
    }

    return (
        <>
            <SectionTitle title="Accounts merged!" align="center" />
            <Wrapper>
                <p style={{ marginBottom: "40px" }}>
                    Your existing THEOplayer account got merged with your Google Sign In. You can now use both to login
                    to THEOlive.
                </p>
                <GoogleButtonHESP isSignUp={false} onClick={signInWithGoogle} />
            </Wrapper>
        </>
    );
}

export default AccountsMerged;

import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { Grid } from "@mui/material";
import Invoices from "../../components/Invoices/Invoices";
import BillingDetails from "../../components/BillingDetails/BillingDetails";
import CurrentBillingPeriodInfo from "../../components/BillingDetails/CurrentBillingPeriodInfo";
import { useQuery } from "react-apollo";
import { GET_FULL_BILLING_DETAILS } from "../../components/BillingDetails/BillingDetailsCardContent";
import {
    GetFullBillingDetails,
    GetFullBillingDetailsVariables,
} from "../../components/BillingDetails/__generated__/GetFullBillingDetails";
import CentralPageLoader from "../../components/Loading/CentralPageLoader";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function Billing({ organizationId }: Props) {
    const { data, loading } = useQuery<GetFullBillingDetails, GetFullBillingDetailsVariables>(
        GET_FULL_BILLING_DETAILS,
        {
            variables: {
                organizationId: organizationId!,
            },
        },
    );

    if (loading || !data) {
        return <CentralPageLoader text="Getting data" />;
    }

    const { organization } = data;

    return (
        <Grid container spacing={{ xs: 5, md: 6 }}>
            {organization.organizationStatus === "production" && (
                <Grid item xs={12}>
                    <CurrentBillingPeriodInfo organization={organization} />
                </Grid>
            )}
            <Grid item xs={12}>
                <BillingDetails organizationWithBillingDetails={data.organization} />
            </Grid>
            {organization.organizationStatus === "production" && (
                <Grid item xs={12}>
                    <Invoices organizationId={organizationId!} />
                </Grid>
            )}
        </Grid>
    );
}

export default Billing;

import { Button, Chip, Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { STYLED_THEME } from "../../AppWrapper";
import { getTHEOliveDescription } from "../WebhooksOverview/WebhookEventsSelector";

interface Props {
    allEvents: string[];
    selectedEvents: string[];
}

const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const EventsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
`;

function WebhookEventsListenedOn({ allEvents, selectedEvents }: Props) {
    const [showEvents, setShowEvents] = React.useState<boolean>(false);

    const eventsToShow = selectedEvents.includes("*") ? allEvents : selectedEvents;
    const numberEventsListeningOn = eventsToShow.length;

    return (
        <MainWrapper>
            <InfoWrapper>
                <div>Listening on {numberEventsListeningOn} events</div>
                <div>
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowEvents(!showEvents)}
                    >
                        {showEvents ? "Hide events" : "Show events"}
                    </Button>
                </div>
            </InfoWrapper>
            {showEvents && (
                <EventsWrapper>
                    {eventsToShow.map((s) => (
                        <Tooltip title={getTHEOliveDescription(s)}>
                            <Chip label={s} style={{ margin: "5px 5px", backgroundColor: STYLED_THEME.lighterBlue }} />
                        </Tooltip>
                    ))}
                </EventsWrapper>
            )}
        </MainWrapper>
    );
}

export default WebhookEventsListenedOn;

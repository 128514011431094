import * as React from "react";
import styled from "styled-components";
import WebhookSecretValue from "./WebhookSecretValue";

interface Props {
    webhookId: string;
    organizationId: string;
}

const RevealButton = styled.div`
    background-color: white;
    border: 1px solid ${(props) => props.theme.gray};
    font-size: 15px;
    padding 2px 15px;
    border-radius: 8px;
    cursor: pointer;
`;

function WebhookSecretCell({ webhookId, organizationId }: Props) {
    const [showSecret, setShowSecret] = React.useState<boolean>(false);

    return (
        <>
            {!showSecret && <RevealButton onClick={() => setShowSecret(true)}>Show</RevealButton>}
            {showSecret && <WebhookSecretValue webhookId={webhookId} organizationId={organizationId} />}
        </>
    );
}

export default WebhookSecretCell;

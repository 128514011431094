import * as React from "react";
import CardHESP from "../../CardHESP/CardHESP";
import gql from "graphql-tag";

import { TableHeadItem } from "../../HESPTable/HESPTableHead";
import ModeBadge from "../../ModeBadge/ModeBadge";
import { HESPlink } from "../../Landing/SignUpComplete";
import { navigate } from "@reach/router";
import { IconButton, Popover } from "@mui/material";
import {
    DonutSmall,
    Explicit,
    InfoOutlined,
    ListAlt,
    LiveTv,
    SettingsOutlined,
    VideoCameraBack,
} from "@mui/icons-material";
import HESPTable from "../../HESPTable/HESPTable";
import ChannelStatusCell from "./ChannelStatusCell";
import ActiveChannelsFilter, { ActiveChannelFilterType } from "./ActiveChannelsFilter";
import _ from "lodash";
import ChannelsOverviewFilterItem from "./ChannelsOverviewFilterItem";
import styled from "styled-components";
import {
    GetActiveRunsQuery_activeChannelRuns,
    GetActiveRunsQuery_activeChannelRuns_channel_organization,
} from "./__generated__/GetActiveRunsQuery";
import Badge from "../../Badge/Badge";
import { THEO_ID } from "../../../views/App/AppRoot";

export const GET_ACTIVE_RUNS = gql`
    query GetActiveRunsQuery {
        activeChannelRuns {
            id
            errorsAmount
            channel {
                channelId
                tla {
                    enabled
                    datacenter {
                        id
                        name
                    }
                }
                metadata {
                    name
                }
                organization {
                    organizationId
                    name
                }
                channelMode
                adminLinks {
                    channel
                    ingest
                    elastic
                }
                channelStatus
                regionId
            }
        }
    }
`;

interface Props {
    activeRuns: GetActiveRunsQuery_activeChannelRuns[];
}

const InfoSelectorWrapper = styled.div`
    padding: 4px 10px;
    width: 250px;
`;

function ActiveChannelsTable({ activeRuns }: Props) {
    const allOrgs = activeRuns.map((r) => r.channel.organization);
    const allOrganizations: GetActiveRunsQuery_activeChannelRuns_channel_organization[] = _.uniqBy(
        allOrgs,
        "organizationId",
    ).sort((a, b) => (a.name > b.name ? 1 : -1));

    const [selectedValues, setSelectedValues] = React.useState<{
        organizations: string[];
    }>({
        organizations: allOrganizations.map((o) => o.organizationId),
    });

    const [selectedChannel, setSelectedChannel] = React.useState<string>("");

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setSelectedChannel(event.currentTarget.id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedChannel("");
    };

    function onSelectAll(type: ActiveChannelFilterType) {
        const newValues = selectedValues;

        switch (type) {
            case "organization":
                setSelectedValues({
                    ...newValues,
                    organizations: allOrganizations.map((o) => o.organizationId),
                });
                break;
        }
    }

    function onDeselectAll(type: ActiveChannelFilterType) {
        const newValues = selectedValues;

        switch (type) {
            case "organization":
                setSelectedValues({
                    ...newValues,
                    organizations: [],
                });
                break;
        }
    }

    function onSelectItem(type: ActiveChannelFilterType, item: string) {
        const newValues = selectedValues;

        switch (type) {
            case "organization":
                setSelectedValues({
                    ...newValues,
                    organizations: selectedValues.organizations.concat([item]),
                });
                break;
        }
    }

    function onDeselectItem(type: ActiveChannelFilterType, item: string) {
        const newValues = selectedValues;

        switch (type) {
            case "organization":
                setSelectedValues({
                    ...newValues,
                    organizations: selectedValues.organizations.filter((i) => i !== item),
                });
                break;
        }
    }

    function getRunsToShow() {
        const afterOrgFilter = activeRuns.filter((r) =>
            selectedValues.organizations.includes(r.channel.organization.organizationId),
        );

        return afterOrgFilter;
    }

    const headers: TableHeadItem[] = [
        {
            id: "mode",
            label: "Mode",
            maxWidth: 180,
        },
        {
            id: "status",
            label: "Status",
            maxWidth: 140,
        },
        {
            id: "errors",
            label: "",
            allowSort: false,
            maxWidth: 100,
        },
        {
            id: "id",
            label: "ID",
            allowSort: true,
        },
        {
            id: "name",
            label: "Name",
            allowSort: true,
        },
        {
            id: "organization",
            label: "Organization",
            allowSort: true,
        },
        {
            id: "region",
            label: "Region",
            allowSort: true,
        },

        {
            id: "settings",
            label: "Settings",
            align: "center",
        },
    ];

    const records = getRunsToShow().map((run: GetActiveRunsQuery_activeChannelRuns) => ({
        mode: <ModeBadge mode={run.channel.channelMode} />,
        status: <ChannelStatusCell channelStatus={run.channel.channelStatus} />,
        errors: run.errorsAmount > 0 ? <Badge type="error">{run.errorsAmount}</Badge> : "",
        id: (
            <HESPlink link={`/app/${run.channel.organization.organizationId}/channels/${run.channel.channelId}`}>
                {run.channel.channelId}
            </HESPlink>
        ),
        name: run.channel.metadata.name,
        organization: (
            <HESPlink link={`/app/${run.channel.organization.organizationId}`}>
                {run.channel.organization.name}
            </HESPlink>
        ),
        region:
            run.channel.tla?.enabled === true ? (
                <HESPlink link={`/app/${THEO_ID}/tla/data-centers/${run.channel.tla?.datacenter!.id}`}>
                    {run.channel.tla!.datacenter!.id}
                </HESPlink>
            ) : (
                run.channel.regionId
            ),
        settings: (
            <>
                <IconButton
                    size="small"
                    onClick={() =>
                        navigate(`/app/${run.channel.organization.organizationId}/channels/${run.channel.channelId}`)
                    }
                >
                    <SettingsOutlined />
                </IconButton>
                <IconButton size="small" onClick={handleClick} key={run.channel.channelId} id={run.channel.channelId}>
                    <InfoOutlined />
                </IconButton>
                <Popover
                    open={Boolean(anchorEl) && selectedChannel === run.channel.channelId}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    style={{
                        marginTop: "4px",
                    }}
                >
                    <InfoSelectorWrapper>
                        <ChannelsOverviewFilterItem
                            icon={
                                run.channel.tla?.enabled === true ? (
                                    <Explicit color="secondary" style={{ fontSize: "16px" }} />
                                ) : (
                                    <LiveTv color="secondary" style={{ fontSize: "16px" }} />
                                )
                            }
                            title={run.channel.tla?.enabled === true ? "Grafana: engine" : "Grafana: channel"}
                            onClick={() => window.open(run.channel.adminLinks.channel)}
                        />
                        {run.channel.adminLinks.ingest.trim().length !== 0 && (
                            <ChannelsOverviewFilterItem
                                icon={<VideoCameraBack color="secondary" style={{ fontSize: "16px" }} />}
                                title={"Grafana ingest"}
                                onClick={() => window.open(run.channel.adminLinks.ingest)}
                            />
                        )}
                        {run.channel.adminLinks.elastic.trim().length > 0 && (
                            <ChannelsOverviewFilterItem
                                icon={<DonutSmall color="secondary" style={{ fontSize: "16px" }} />}
                                title={"Elastic"}
                                onClick={() => window.open(run.channel.adminLinks.elastic)}
                            />
                        )}

                        <ChannelsOverviewFilterItem
                            icon={<ListAlt color="secondary" style={{ fontSize: "16px" }} />}
                            title={"Channel runs"}
                            onClick={() =>
                                navigate(
                                    `/app/${run.channel.organization.organizationId}/channels/${run.channel.channelId}/runs`,
                                )
                            }
                        />
                    </InfoSelectorWrapper>
                </Popover>
            </>
        ),
    }));

    return (
        <CardHESP>
            <ActiveChannelsFilter
                initFiltersToShow={["organization"]}
                items={{ organizations: allOrganizations }}
                onDeselectAll={() => onDeselectAll("organization")}
                onSelectAll={() => onSelectAll("organization")}
                onDeselectItem={(type: ActiveChannelFilterType, item: string) => onDeselectItem(type, item)}
                onSelectItem={(type: ActiveChannelFilterType, item: string) => onSelectItem(type, item)}
                selectedItems={selectedValues}
            />
            <HESPTable headers={headers} data={records} enablePagination defaultSortOn="id" />
        </CardHESP>
    );
}

export default ActiveChannelsTable;

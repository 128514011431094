import * as React from "react";
import { DialogContent, DialogActions, FormControl, TextField, Typography } from "@mui/material";
import DialogHESP from "../DialogHESP/DialogHESP";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import styled from "styled-components";

const ImageWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: center;
`;
interface Props {
    open: boolean;
    title: string;
    currentUrl: string;
    onUploadComplete: (url: string) => void;
    onClose: () => void;
}

function UploadImageDialog({ title, open, onClose, onUploadComplete, currentUrl }: Props) {
    const [uploadUrl, setUploadUrl] = React.useState<string>(currentUrl);
    const [showError, setShowError] = React.useState<boolean>(false);

    function getPicture() {
        if (uploadUrl.trim() === "") {
            return "No image";
        }
        if (!isImageUrl(uploadUrl.trim())) {
            return "No valid image url";
        }
        return <img src={uploadUrl} alt="poster" style={{ maxHeight: "300px", maxWidth: "200px" }} />;
    }

    function changeUrl(url: string): void {
        setUploadUrl(url);
    }

    async function onSave(): Promise<void> {
        setShowError(false);
        if (isImageUrl(uploadUrl)) {
            const stripped = stripUrl(uploadUrl);
            onUploadComplete(stripped.trim());
            onClose();
        } else {
            setShowError(true);
        }
    }

    function stripUrl(url: string): string {
        return url.indexOf("?") > -1 ? url.substring(0, url.indexOf("?")) : url;
    }

    function isImageUrl(url: string): boolean {
        const stripped = stripUrl(url);
        if (stripped.trim() === "") {
            return true;
        }
        if (stripped.match(/\.(jpeg|jpg|gif|png|svg)$/) == null) {
            return false;
        }
        return true;
    }

    function onCloseDialog() {
        setUploadUrl(currentUrl);
        onClose();
    }

    const loading = false;
    return (
        <DialogHESP
            title={title}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <FormControl fullWidth>
                            <HESPFormLabel label="Image URL" />
                            <TextField
                                defaultValue={currentUrl}
                                onChange={(e) => changeUrl(e.target.value)}
                                fullWidth
                                variant="outlined"
                            />
                            {showError && (
                                <Typography variant="subtitle2" color="error">
                                    No valid image URL
                                </Typography>
                            )}
                        </FormControl>
                        <ImageWrapper>{getPicture()}</ImageWrapper>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            onActionClick={() => onSave()}
                            isLoading={loading}
                            onCancelClick={onCloseDialog}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default UploadImageDialog;

import { UsageType } from "../../__generated__/globalTypes";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import AdminUsageAnalyticsSettings, { AdminUsagAnalyticsRange } from "./AdminUsageAnalyticsSettings";
import * as React from "react";
import { Button, ButtonGroup, Grid } from "@mui/material";
import { Check } from "@mui/icons-material";
import moment from "moment";
import AdminUsageModesChart from "./AdminUsageModesChart";

interface Props {
    usageType: UsageType;
}

export default function AdminUsageModes({ usageType }: Props) {
    const [range, setRange] = React.useState<AdminUsagAnalyticsRange>("monthly");

    const [startDate, setStartDate] = React.useState(moment().subtract(12, "months").startOf("hour"));
    const [endDate, setEndDate] = React.useState(moment().endOf("month"));

    function onChangeRange(newRange: AdminUsagAnalyticsRange) {
        setRange(newRange);
        if (newRange === "daily") {
            setStartDate(moment().subtract(30, "days").startOf("hour"));
            setEndDate(moment().endOf("day"));
        } else {
            setStartDate(moment().subtract(12, "months").startOf("hour"));
            setEndDate(moment().endOf("day"));
        }
    }

    return (
        <CardHESP>
            <CardHESPTitle
                title="Minutes for different modes"
                subtitle="THEO and Akamai excluded"
                button={
                    <ButtonGroup color="secondary" size="small">
                        <Button
                            variant={range === "daily" ? "contained" : "outlined"}
                            onClick={(_e) => onChangeRange("daily")}
                            startIcon={range === "daily" && <Check style={{ color: "white" }} />}
                            size="small"
                        >
                            Daily
                        </Button>
                        <Button
                            variant={range === "monthly" ? "contained" : "outlined"}
                            onClick={(_e) => onChangeRange("monthly")}
                            startIcon={range === "monthly" && <Check style={{ color: "white" }} />}
                            size="small"
                        >
                            Monthly
                        </Button>
                    </ButtonGroup>
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AdminUsageAnalyticsSettings
                        range={range}
                        endDate={endDate}
                        startDate={startDate}
                        setEndDate={(date: moment.Moment) => setEndDate(date)}
                        setStartDate={(date: moment.Moment) => setStartDate(date)}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "15px" }}>
                    <AdminUsageModesChart startDate={startDate} endDate={endDate} range={range} usageType={usageType} />
                </Grid>
            </Grid>
        </CardHESP>
    );
}

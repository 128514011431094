import { Save, Undo } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import * as React from "react";
import { useMutation } from "react-apollo";
import { IngestProtocol, IngestType } from "../../../__generated__/globalTypes";
import CardHESP from "../../CardHESP/CardHESP";
import CardHESPTitle from "../../CardHESP/CardHESPTitle";
import {
    ChangeIngestTypeMutation,
    ChangeIngestTypeMutationVariables,
} from "../../IngestServerSelector/__generated__/ChangeIngestTypeMutation";
import Loading from "../../Loading/Loading";
import { GET_CHANNEL_QUERY } from "../Overview/channelQueriesMutations";
import { GetChannelQuery_channel } from "../Overview/__generated__/GetChannelQuery";
import ChannelIngestSettingsPull from "./ChannelIngestSettingsPull";
import ChannelIngestSettingsPush from "./ChannelIngestSettingsPush";
import ChannelIngestSettingsSelector from "./ChannelIngestSettingsSelector";
import gql from "graphql-tag";

interface Props {
    channel: GetChannelQuery_channel;
    disabled: boolean;
}

export const CHANGE_INGEST_TYPE_MUTATION = gql`
    mutation ChangeIngestTypeMutation($input: ChangeIngestTypeInput!) {
        changeIngestType(input: $input) {
            channelId
        }
    }
`;

function ChannelIngestSettings({ channel, disabled }: Props) {
    const { ingestType, channelId, ingestProtocol } = channel;

    const [selectedType, setSelectedType] = React.useState<IngestType>(ingestType);
    const [selectedProtocol, setSelectedProtocol] = React.useState<IngestProtocol>(ingestProtocol);
    const [ingestUrl, setIngestUrl] = React.useState<string>(channel.ingestPullUrl ?? "");
    const [audioPid, setAudioPid] = React.useState<string>(channel.streamConfig.audioPid ?? "");
    const [needsSave, setNeedsSave] = React.useState<boolean>(false);

    React.useEffect(() => {
        setSelectedProtocol(channel.ingestProtocol);
        setSelectedType(channel.ingestType);
        setIngestUrl(channel.ingestPullUrl ?? "");
        setAudioPid(channel.streamConfig.audioPid ?? "");
    }, [channel]);

    const [changeIngestTypeMut, { loading }] = useMutation<ChangeIngestTypeMutation, ChangeIngestTypeMutationVariables>(
        CHANGE_INGEST_TYPE_MUTATION,
    );

    function onSelectionChange(protocol: IngestProtocol, type: IngestType) {
        setSelectedType([IngestProtocol.srt, IngestProtocol.sdi].includes(protocol) ? IngestType.pull : type);
        setSelectedProtocol(protocol);
        setNeedsSave(true);
    }

    async function onSave() {
        if (isValid() && !disabled) {
            try {
                await changeIngestTypeMut({
                    variables: {
                        input: {
                            channelId,
                            organizationId: channel.organizationId,
                            ingestType: selectedType,
                            ingestProtocol: selectedProtocol,
                            ...(selectedType === IngestType.pull && {
                                ingestPullUrl: ingestUrl,
                                ...(selectedProtocol === IngestProtocol.srt && { audioPid }),
                            }),
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_CHANNEL_QUERY,
                            variables: {
                                channelId,
                                organizationId: channel.organizationId,
                            },
                        },
                    ],
                });
                setNeedsSave(false);
            } catch (_ignore: any) {}
        }
    }

    function isValid(): boolean {
        if (selectedType === IngestType.push) {
            return true;
        }

        if (selectedProtocol === IngestProtocol.rtmp) {
            return ingestUrl.startsWith("rtmps://") || ingestUrl.startsWith("rtmp://");
        }

        if (selectedProtocol === IngestProtocol.srt) {
            return ingestUrl.startsWith("srt://");
        }

        return true;
    }

    function onUndo(): void {
        setSelectedType(channel.ingestType);
        setAudioPid(channel.streamConfig.audioPid ?? "");
        setIngestUrl(channel.ingestPullUrl ?? "");
        setSelectedProtocol(channel.ingestProtocol);
        setNeedsSave(false);
    }

    return (
        <>
            <CardHESP>
                <CardHESPTitle
                    title="Ingest server"
                    subtitle={disabled ? "Settings can only be updated when channel is stopped" : ""}
                    button={
                        loading ? (
                            <Loading />
                        ) : needsSave && !disabled ? (
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button
                                        startIcon={<Undo />}
                                        variant="contained"
                                        size="small"
                                        color="inherit"
                                        onClick={onUndo}
                                    >
                                        Undo
                                    </Button>
                                </Grid>{" "}
                                <Grid item>
                                    <Button
                                        startIcon={<Save />}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={!isValid()}
                                        onClick={onSave}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : null
                    }
                />
                <ChannelIngestSettingsSelector
                    disablePush={channel.tla?.enabled === true}
                    disabled={disabled}
                    selectedValues={{
                        protocol: selectedProtocol,
                        type: selectedType,
                    }}
                    onChange={(protocol: IngestProtocol, type: IngestType) => onSelectionChange(protocol, type)}
                />
                {selectedType === IngestType.push && <ChannelIngestSettingsPush channel={channel} />}
                {selectedType === IngestType.pull && (
                    <ChannelIngestSettingsPull
                        disabled={disabled}
                        audioPid={audioPid}
                        ingestProtocol={selectedProtocol}
                        ingestUrl={ingestUrl}
                        onChangeAudioPid={(val: string) => {
                            setAudioPid(val);
                            setNeedsSave(true);
                        }}
                        onChangeIngestUrl={(url: string) => {
                            setIngestUrl(url);
                            setNeedsSave(true);
                        }}
                    />
                )}
            </CardHESP>
        </>
    );
}

export default ChannelIngestSettings;

import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import * as React from "react";
import { DeepMap, FieldError } from "react-hook-form";
import styled from "styled-components";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";

const FormControlWrapper = styled.div`
    width: 100%;
    margin-bottom: 16px;
`;

export interface CreateOrganizationFormData {
    name: string;
    primaryEmail: string;
    contactEmail: string;
}

interface Props {
    errors: DeepMap<CreateOrganizationFormData, FieldError>;
    register: any;
    isIndividual: boolean;
    onChangeIndividual: () => void;
}

export const HelperText = styled.div`
    margin-top: 5px;
    color: ${(props) => props.theme.gray};
    font-size: 12px;
`;

function CreateOrganizationForm({ errors, register, isIndividual, onChangeIndividual }: Props) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup value={isIndividual ? "individual" : "company"} onChange={onChangeIndividual} row>
                        <FormControlLabel value="company" control={<Radio />} label="Company" defaultChecked={true} />
                        <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlWrapper>
                    <FormControl fullWidth>
                        <HESPFormLabel label={isIndividual ? "Individual name" : "Company name"} />
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="name"
                            inputRef={register({
                                required: "Organization name is required",
                            })}
                        />
                    </FormControl>
                    <Typography variant="subtitle2" color="error">
                        {errors.name?.message ?? ""}
                    </Typography>
                </FormControlWrapper>
                <FormControlWrapper>
                    <FormControl fullWidth>
                        <HESPFormLabel label="Primary email" />
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="primaryEmail"
                            inputRef={register({
                                required: "Primary email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i,
                                    message: "Invalid email address",
                                },
                            })}
                        />
                        <HelperText>Email address used to send invoices to.</HelperText>
                    </FormControl>
                    <Typography variant="subtitle2" color="error">
                        {errors.primaryEmail?.message ?? ""}
                    </Typography>
                </FormControlWrapper>
                <FormControlWrapper>
                    <FormControl fullWidth>
                        <HESPFormLabel label="Contact email" />
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="contactEmail"
                            inputRef={register({
                                required: "Contact email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i,
                                    message: "Invalid email address",
                                },
                            })}
                        />
                        <HelperText>
                            Email address of first user to be added. More users can be added later on.
                        </HelperText>
                    </FormControl>
                    <Typography variant="subtitle2" color="error">
                        {errors.contactEmail?.message ?? ""}
                    </Typography>
                </FormControlWrapper>
            </Grid>
        </Grid>
    );
}

export default CreateOrganizationForm;

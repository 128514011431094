import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid } from "@mui/material";
import TeamOverview from "../../components/TeamOverview/TeamOverview";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

function Team({ organizationId }: Props) {
    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <TeamOverview organizationId={organizationId!} />
            </Grid>
        </Grid>
    );
}

export default Team;

import * as React from "react";
import { GetSchedulersViaSearchParams_schedulersWithParams } from "./__generated__/GetSchedulersViaSearchParams";
import styled from "styled-components";
import { KeyboardArrowRight } from "@mui/icons-material";
import { navigate } from "@reach/router";

interface Props {
    item: GetSchedulersViaSearchParams_schedulersWithParams;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.lightGrayBottom};
    cursor: pointer;
    align-items: center;
    margin-bottom: 10px;
`;

const Left = styled.div`
    display: flex;
    flex-direction: row;
`;

const Right = styled.div``;

const NameInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 14px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
`;

const Description = styled.div`
    color: ${(props) => props.theme.gray};
    font-size: 13px;
`;

export default function SchedulerSearchItem({ item }: Props) {
    return (
        <Wrapper onClick={() => navigate(`/app/${item.organizationId}/schedulers/${item.schedulerId}`)}>
            <Left>
                <div>
                    <NameInfo>
                        <Title>{item.name}</Title>
                        <Description>
                            {item.schedulerId}{" "}
                            {item.clientId && item.clientId.length > 0 ? ` (clientId: ${item.clientId})` : ""}
                        </Description>
                    </NameInfo>
                </div>
            </Left>
            <Right>
                <KeyboardArrowRight color={"primary"} />
            </Right>
        </Wrapper>
    );
}

import { Button, Grid } from "@mui/material";
import gql from "graphql-tag";
import moment from "moment";
import * as React from "react";
import { GetSchedulerQuery_scheduler } from "./__generated__/GetSchedulerQuery";
import styled from "styled-components";
import { CreateSchedulerMutation, CreateSchedulerMutationVariables } from "./__generated__/CreateSchedulerMutation";
import { useMutation } from "react-apollo";
import { UpdateSchedulerMutation, UpdateSchedulerMutationVariables } from "./__generated__/UpdateSchedulerMutation";
import SchedulerBasicsForm from "./SchedulerBasicsForm";
import SchedulerChannelsSelector from "./SchedulerChannelsSelector";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";
import { navigate } from "@reach/router";
import { KeyValuePairInput, OrderDirectionType, SchedulerPhaseType } from "../../__generated__/globalTypes";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import KeyValueConstructor from "../../KeyValueConstructor/KeyValueConstructor";
import { GET_SCHEDULERS_BASED_ON_PARAMS } from "./SchedulerSection";
import { GET_SCHEDULERS_PER_PHASE } from "./SchedulersAnalytics";

export const GET_SCHEDULER_QUERY = gql`
    query GetSchedulerQuery($schedulerId: ID!, $organizationId: ID!) {
        scheduler(schedulerId: $schedulerId, organizationId: $organizationId) {
            schedulerId
            name
            start
            end
            channelIds
            organizationId
            phase
            clientId
            shouldGenerateReport
            auditDone
            metadata {
                key
                value
            }
            channels {
                channelId
                metadata {
                    name
                }
            }
            report {
                status
                link
                start
                end
            }
            recurring {
                enabled
                days
                tz
            }
        }
    }
`;

export const CREATE_SCHEDULER_MUTATION = gql`
    mutation CreateSchedulerMutation($input: CreateSchedulerInput!) {
        createScheduler(input: $input) {
            schedulerId
        }
    }
`;

export const UPDATE_SCHEDULER_MUTATION = gql`
    mutation UpdateSchedulerMutation($input: UpdateSchedulerInput!) {
        updateScheduler(input: $input) {
            schedulerId
        }
    }
`;

interface Props {
    organizationId: string;
    scheduler?: GetSchedulerQuery_scheduler;
    isCreate: boolean;
}

export interface SchedulerObject {
    schedulerId?: string;
    name: string;
    startNow: boolean;
    start: string;
    end?: string;
    clientId: string;
    metadata: KeyValuePairInput[];
    channelIds: string[];
    recurring: {
        enabled: boolean;
        days: number[];
        tz?: string;
    };
}

const SectionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
`;

const ChannelsWrapper = styled.div`
    border-radius: 10px;
    padding: 10px 10px;
    background: #fafafa;
`;

export default function ManageSchedulerForm({ organizationId, scheduler, isCreate }: Props) {
    if (!isCreate && !scheduler) {
        throw new Error("Wrong properties");
    }

    const [schedulerObj, setSchedulerObj] = React.useState<SchedulerObject>(
        getInitialSchedulerObj(isCreate, scheduler),
    );

    const [firstSubmitDone, setFirstSubmitDone] = React.useState<boolean>(false);

    const [createSchedulerMut, { loading: loadingCreateMut }] = useMutation<
        CreateSchedulerMutation,
        CreateSchedulerMutationVariables
    >(CREATE_SCHEDULER_MUTATION);

    const [updateSchedulerMut, { loading: loadingUpdateMut }] = useMutation<
        UpdateSchedulerMutation,
        UpdateSchedulerMutationVariables
    >(UPDATE_SCHEDULER_MUTATION);

    const variables = {
        limit: 5,
        orderBy: "start",
        orderDirection: OrderDirectionType.desc,
        organizationId,
        page: 1,
    };

    async function onSubmitClick() {
        setFirstSubmitDone(true);
        const { channelIds, end, name, start, schedulerId, clientId, metadata, startNow, recurring } = schedulerObj;
        if (
            validationPassed({
                schedulerObj,
                initObject: getInitialSchedulerObj(isCreate, scheduler),
                isActive: scheduler?.phase === SchedulerPhaseType.active,
                isCreate,
            })
        ) {
            try {
                if (isCreate) {
                    await createSchedulerMut({
                        variables: {
                            input: {
                                channelIds,
                                end,
                                name,
                                startNow,
                                start,
                                organizationId,
                                clientId,
                                metadata,
                                recurring,
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                variables: {
                                    ...variables,
                                    phases: [SchedulerPhaseType.active, SchedulerPhaseType.starting],
                                },
                            },
                            {
                                query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                variables: {
                                    ...variables,
                                    phases: [SchedulerPhaseType.pending],
                                },
                            },
                            {
                                query: GET_SCHEDULERS_PER_PHASE,
                                variables: {
                                    organizationId,
                                },
                            },
                        ],
                    });
                    navigate(`/app/${organizationId}/schedulers`);
                } else {
                    await updateSchedulerMut({
                        variables: {
                            input: {
                                channelIds,
                                organizationId,
                                end,
                                name,
                                schedulerId: schedulerId!,
                                clientId,
                                metadata,
                                start: typeof start === "undefined" ? moment().utc().toISOString() : start,
                                recurring,
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                variables: {
                                    ...variables,
                                    phases: [SchedulerPhaseType.active, SchedulerPhaseType.starting],
                                },
                            },
                            {
                                query: GET_SCHEDULERS_BASED_ON_PARAMS,
                                variables: {
                                    ...variables,
                                    phases: [SchedulerPhaseType.pending],
                                },
                            },
                            {
                                query: GET_SCHEDULERS_PER_PHASE,
                                variables: {
                                    organizationId,
                                },
                            },
                            {
                                query: GET_SCHEDULER_QUERY,
                                variables: {
                                    organizationId,
                                    schedulerId,
                                },
                            },
                        ],
                    });
                    navigate(`/app/${organizationId}/schedulers/${schedulerId}`);
                }
                toast.success(`Scheduler successfully ${isCreate ? "created" : "updated"}! 🚀`, TOAST_SETTINGS);
            } catch (_e) {
                toast.error("Something went wrong 😞", TOAST_SETTINGS);
            }
        }
    }

    function onAddKeyValueItem() {
        const currentItems = schedulerObj.metadata;
        const newItems = currentItems.concat([{ key: "", value: "" }]);
        setSchedulerObj({
            ...schedulerObj,
            metadata: newItems,
        });
    }

    function onUpdateKeyValueItem(type: "key" | "value", value: string, index: number) {
        const currentItems = schedulerObj.metadata;
        currentItems[index] = {
            key: type === "key" ? value : currentItems[index].key,
            value: type === "value" ? value : currentItems[index].value,
        };
        setSchedulerObj({
            ...schedulerObj,
            metadata: currentItems,
        });
    }

    function onRemoveKeyValueItem(index: number) {
        const currentItems = schedulerObj.metadata;
        const newItems = currentItems.filter((_item, i) => i !== index);
        setSchedulerObj({
            ...schedulerObj,
            metadata: newItems,
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionTitle>Basic information</SectionTitle>
                <SchedulerBasicsForm
                    schedulerObj={schedulerObj}
                    initObj={getInitialSchedulerObj(isCreate, scheduler)}
                    isCreate={isCreate}
                    isActive={scheduler?.phase === SchedulerPhaseType.active}
                    onChangeStart={(newValue: string | undefined) => {
                        setSchedulerObj({
                            ...schedulerObj,
                            ...(newValue !== undefined && { start: newValue }),
                            startNow: newValue === undefined,
                        });
                    }}
                    onChangeEnd={(newValue: string | undefined) =>
                        setSchedulerObj({
                            ...schedulerObj,
                            end: newValue,
                            ...(typeof newValue === "undefined" && { recurring: { enabled: false, days: [] } }),
                        })
                    }
                    onChangeName={(newValue: string) => {
                        setSchedulerObj({
                            ...schedulerObj,
                            name: newValue,
                        });
                    }}
                    onChangeClientId={(newValue: string) => {
                        setSchedulerObj({
                            ...schedulerObj,
                            clientId: newValue,
                        });
                    }}
                    onChangeRecurring={(recurring: { enabled: boolean; days: number[] }) =>
                        setSchedulerObj({ ...schedulerObj, recurring })
                    }
                    firstSubmitDone={firstSubmitDone}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <HESPFormLabel
                    label="Metadata"
                    description="Optional key value pairs to pass and connect to the scheduler"
                />
                <KeyValueConstructor
                    items={schedulerObj.metadata}
                    onAdd={onAddKeyValueItem}
                    onUpdate={(type, val, index) => onUpdateKeyValueItem(type, val, index)}
                    onRemove={(index: number) => onRemoveKeyValueItem(index)}
                />
            </Grid>
            <Grid item xs={12}>
                <ChannelsWrapper>
                    <SectionTitle>Connected channels</SectionTitle>
                    <SchedulerChannelsSelector
                        isActive={
                            scheduler?.phase === SchedulerPhaseType.active ||
                            scheduler?.phase === SchedulerPhaseType.starting
                        }
                        organizationId={organizationId}
                        channelIds={schedulerObj.channelIds}
                        firstSubmitDone={firstSubmitDone}
                        onChannelsChange={(newChannelIds: string[]) =>
                            setSchedulerObj({
                                ...schedulerObj,
                                channelIds: newChannelIds,
                            })
                        }
                    />
                </ChannelsWrapper>
            </Grid>
            <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button
                        onClick={onSubmitClick}
                        variant="contained"
                        size="large"
                        disabled={loadingCreateMut || loadingUpdateMut}
                    >
                        {loadingCreateMut || loadingUpdateMut
                            ? `${isCreate ? "Creating" : "Updating"} scheduler...`
                            : `${isCreate ? "Create" : "Update"} scheduler`}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

function getInitialSchedulerObj(isCreate: boolean, schedulerObj?: GetSchedulerQuery_scheduler): SchedulerObject {
    if (isCreate) {
        return {
            name: "",
            startNow: false,
            start: moment().startOf("minute").add(30, "minutes").toISOString(),
            end: moment().startOf("minute").add(90, "minutes").toISOString(),
            channelIds: [],
            clientId: "",
            metadata: [],
            recurring: {
                enabled: false,
                days: [],
            },
        };
    }

    const { channelIds, end, start, name, schedulerId, clientId, metadata, recurring } = schedulerObj!;

    return {
        schedulerId,
        name,
        start,
        startNow: false,
        end: end === null ? undefined : end,
        channelIds,
        clientId: clientId ?? "",
        metadata,
        recurring: {
            days: recurring.days,
            enabled: recurring.enabled,
            tz: recurring.tz === null ? undefined : recurring.tz,
        },
    };
}

interface ValidatorArgs {
    schedulerObj: SchedulerObject;
    initObject: SchedulerObject;
    isActive: boolean;
    isCreate: boolean;
}

function validationPassed({ initObject, isActive, isCreate, schedulerObj }: ValidatorArgs): boolean {
    const { channelIds, end, name, start, metadata, startNow } = schedulerObj;

    if (end && !startNow && moment(start).isAfter(moment(end))) {
        return false;
    }

    if (startNow && moment(end) < moment().add(2, "minutes")) {
        return false;
    }

    if (name.trim().length === 0) {
        return false;
    }

    if (channelIds.length === 0) {
        return false;
    }

    if (metadata.length > 0) {
        const emptyKeys = metadata.map((m) => m.key).filter((k) => k.trim().length === 0);
        if (emptyKeys.length > 0) {
            return false;
        }
    }

    return true;
}

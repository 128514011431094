import { Button, Divider, Grid } from "@mui/material";
import * as React from "react";
import { BitrateMode, IngestProtocol, ResolutionType } from "../../../__generated__/globalTypes";
import CreateChannelFormBasics from "./CreateChannelFormBasics";
import styled from "styled-components";

import { useMutation } from "react-apollo";
import { GetProfiles_profiles } from "../../AdminConfig/Profiles/__generated__/GetProfiles";
import { CREATE_CHANNEL_MUTATION, GET_CHANNELS_ORGANIZATION_QUERY } from "../Overview/channelQueriesMutations";
import { CreateChannelMutation, CreateChannelMutationVariables } from "../Overview/__generated__/CreateChannelMutation";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../BillingDetails/AddPaymentMethodDialog";
import { navigate } from "@reach/router";
import _ from "lodash";
import ChannelStreamConfigurator, {
    determineSelectedFpsAfterBitrateOrResolutionChange,
    determineSelectedResolutionAfterBitrateChange,
    getFpsValues,
    StreamConfigType,
} from "./ChannelStreamConfigurator";
import StreamConfigurationInformation from "./StreamConfigurationInformation";
import { GetOrganizationChannelSettingsQuery_organization } from "../../../views/App/__generated__/GetOrganizationChannelSettingsQuery";
import CreateChannelMiscellaneousSettings, {
    CreateChannelMiscellaneousType,
} from "./CreateChannelMiscellaneousSettings";

interface Props {
    organization: GetOrganizationChannelSettingsQuery_organization;
    profiles: GetProfiles_profiles[];
}

export interface CreateChannelInput {
    name: string;
    ingestLocation?: string;
    streamConfig: {
        fps: number;
        resolution: ResolutionType;
        bitrate: number;
        abr: boolean;
        drm: boolean;
        bitrateMode: BitrateMode;
    };
    perpetual: boolean;
    autoStop: boolean;
    tla: boolean;
    dataCenterId?: string;
    ingestPullUrl?: string;
    ingestProtocol?: IngestProtocol;
}

const SectionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
`;

export default function CreateChannelForm({ organization, profiles }: Props) {
    const allBitrates = _.uniq(profiles.map((p) => p.maxBitrate)).sort((a, b) => (a > b ? -1 : 1));
    const allResolutions = _.uniq(profiles.map((p) => p.maxResolution)).sort(resolutionSort);
    const fpses = _.uniq(profiles.map((p) => p.maxFps));
    const allFpsValues = getFpsValues(fpses);

    const initBitrate = 4.5;
    const initResolution = ResolutionType.r_1080p;
    const initFps = 30;
    const initAbr = true;
    const initDrm = false;
    const initBitrateMode: BitrateMode = BitrateMode.variable;

    const { organizationId, channelSettings } = organization;

    const [values, setValues] = React.useState<CreateChannelInput>({
        streamConfig: {
            bitrate: initBitrate,
            fps: initFps,
            resolution: initResolution,
            abr: initAbr,
            drm: initDrm,
            bitrateMode: initBitrateMode,
        },
        ingestLocation: "europe-west",
        dataCenterId: organization.tla?.datacenters?.length > 0 ? organization.tla.datacenters[0].id : undefined,
        name: "",
        autoStop: true,
        perpetual: false,
        tla: false,
        ingestProtocol: IngestProtocol.rtmp,
        ingestPullUrl: "",
    });

    const [firstSubmitDone, setFirstSubmitDone] = React.useState<boolean>(false);

    const [createChannelMut, { loading: loadingCreate }] = useMutation<
        CreateChannelMutation,
        CreateChannelMutationVariables
    >(CREATE_CHANNEL_MUTATION);

    async function onSubmitClick() {
        setFirstSubmitDone(true);
        const {
            streamConfig,
            ingestLocation,
            name,
            autoStop,
            perpetual,
            tla,
            dataCenterId,
            ingestProtocol,
            ingestPullUrl,
        } = values;

        if (name.trim() !== "") {
            try {
                const createdChannel = await createChannelMut({
                    variables: {
                        input: {
                            metadata: {
                                name,
                            },
                            ingestLocation: tla ? undefined : ingestLocation,
                            streamConfig,
                            organizationId,
                            ingestTimeout: autoStop ? 3600 : 0,
                            ...(channelSettings.perpetual.enabled === true && { perpetual }),
                            ...(tla === true && {
                                datacenterId: dataCenterId!,
                                ingestProtocol: ingestProtocol!,
                                ingestPullUrl: ingestPullUrl!,
                            }),
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_CHANNELS_ORGANIZATION_QUERY,
                            variables: {
                                organizationId,
                            },
                        },
                    ],
                });
                navigate(`/app/${organizationId}/channels/${createdChannel.data?.createChannel.channelId}`);
            } catch (_e) {
                toast.error("Something went wrong", TOAST_SETTINGS);
            }
        }
    }

    function isValidInput() {
        if (values.name.trim() === "") {
            return false;
        }

        if (values.tla === true) {
            if (!values.ingestPullUrl || values.ingestPullUrl.trim().length === 0) {
                return false;
            }
        }

        return true;
    }

    function onChangeConfigValue(type: StreamConfigType, value: string | number | ResolutionType | boolean) {
        switch (type) {
            case "bitratemode":
                setValues({
                    ...values,
                    streamConfig: {
                        ...values.streamConfig,
                        bitrateMode: value as BitrateMode,
                    },
                });
                break;
            case "bitrate":
                onBitrateChange(value as number);
                break;
            case "resolution":
                onResolutionChanged(value as ResolutionType);
                break;
            case "abr":
                setValues({
                    ...values,
                    streamConfig: {
                        ...values.streamConfig,
                        abr: value as boolean,
                    },
                });
                break;
            case "drm":
                setValues({
                    ...values,
                    streamConfig: {
                        ...values.streamConfig,
                        drm: value as boolean,
                    },
                });
                break;
            case "fps":
                setValues({
                    ...values,
                    streamConfig: {
                        ...values.streamConfig,
                        fps: value as number,
                    },
                });
                break;
        }
    }

    function onBitrateChange(value: number) {
        const newBitrate = value as number;
        const newResolution = determineSelectedResolutionAfterBitrateChange(
            profiles,
            newBitrate,
            values.streamConfig.resolution,
        );
        const newFps = determineSelectedFpsAfterBitrateOrResolutionChange(
            profiles,
            newBitrate,
            newResolution,
            values.streamConfig.fps,
        );
        setValues({
            ...values,
            streamConfig: {
                ...values.streamConfig,
                bitrate: newBitrate,
                fps: newFps,
                resolution: newResolution,
            },
        });
    }

    function onResolutionChanged(value: ResolutionType) {
        const newResolution = value;
        const newFps = determineSelectedFpsAfterBitrateOrResolutionChange(
            profiles,
            values.streamConfig.bitrate,
            newResolution,
            values.streamConfig.fps,
        );
        setValues({
            ...values,
            streamConfig: {
                ...values.streamConfig,
                fps: newFps,
                resolution: newResolution,
            },
        });
    }

    const selectedProfile = profiles.find(
        (p) =>
            p.maxBitrate === values.streamConfig.bitrate &&
            p.maxFps === getMaxFps(values.streamConfig.fps) &&
            p.maxResolution === values.streamConfig.resolution,
    );

    function getMaxFps(val: number) {
        return val <= 30 ? 30 : 60;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionTitle>Basic information</SectionTitle>
                <CreateChannelFormBasics
                    organization={organization}
                    firstSubmitDone={firstSubmitDone}
                    input={values}
                    onChangeTLA={() => setValues({ ...values, tla: !values.tla })}
                    onNameChange={(val: string) => setValues({ ...values, name: val })}
                    onChangePullUrl={(val: string) => setValues({ ...values, ingestPullUrl: val })}
                    onLocationChange={(val: string) => setValues({ ...values, ingestLocation: val })}
                    onDatacenterChange={(val: string) => setValues({ ...values, dataCenterId: val })}
                    onChangeIngestProtocol={(protocol: IngestProtocol) =>
                        setValues({ ...values, ingestProtocol: protocol })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Stream configuration</SectionTitle>
                <ChannelStreamConfigurator
                    abr={values.streamConfig.abr}
                    bitrateMode={values.streamConfig.bitrateMode}
                    allBitrates={allBitrates}
                    allFpsValues={allFpsValues}
                    allResolutions={allResolutions}
                    selectedBitrate={values.streamConfig.bitrate}
                    selectedResolution={values.streamConfig.resolution}
                    selectedFps={values.streamConfig.fps}
                    profiles={profiles}
                    onChange={(type: StreamConfigType, value: string | number | ResolutionType | boolean) =>
                        onChangeConfigValue(type, value)
                    }
                    drm={values.streamConfig.drm}
                    organizationId={organization.organizationId}
                    hasAccessToDrm={organization.channelSettings?.drm?.enabled === true}
                />
            </Grid>
            <Grid item xs={12}>
                <StreamConfigurationInformation
                    drm={values.streamConfig.drm}
                    abr={values.streamConfig.abr}
                    profile={selectedProfile!}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Miscellaneous</SectionTitle>
                <CreateChannelMiscellaneousSettings
                    channelSettings={channelSettings}
                    values={{ autoStop: values.autoStop, perpetual: values.perpetual }}
                    onChangeBoolean={(type: CreateChannelMiscellaneousType) => {
                        setValues({
                            ...values,
                            ...(type === "autoStop" && { autoStop: !values.autoStop }),
                            ...(type === "perpetual" && { perpetual: !values.perpetual }),
                        });
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    {}
                    <Button
                        onClick={onSubmitClick}
                        variant="contained"
                        size="large"
                        disabled={loadingCreate || !isValidInput()}
                    >
                        {loadingCreate ? "Creating..." : "Create"}
                    </Button>
                </div>
            </Grid>
            <ToastContainer />
        </Grid>
    );
}

export function resolutionSort(a: ResolutionType, b: ResolutionType) {
    const resOrder: Record<ResolutionType, number> = {
        r_1080p: 0,
        r_720p: 1,
        r_576p: 2,
        r_360p: 3,
        r_240p: 4,
    };

    if (resOrder[a] < resOrder[b]) {
        return -1;
    } else if (a === b) {
        return 0;
    } else {
        return 1;
    }
}

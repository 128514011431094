import { Player, requiresServiceWorker } from "@theolive/player";
import { useCallback, useEffect, useState } from "react";
import { ChannelStatusType } from "../../__generated__/globalTypes";

interface Props {
    channelId: string;
    token: string;
    channelStatus: ChannelStatusType;
}

const env = process.env.REACT_APP_THEO_ENV as string;

export default function THEOlivePlayer({ channelId, token, channelStatus }: Props) {
    const [player, setPlayer] = useState<Player | null>(null);

    const onMount = useCallback((node: HTMLElement | null) => {
        if (node) {
            setPlayer(
                new Player(node, {
                    // @ts-ignore
                    fallbackEnabled: false,
                    discoveryHeader: "no-redirect",
                    ...(env === "dev" && { discoveryUrl: "https://discovery.sneezysparrow.com/channels/" }),
                }),
            );
        }
    }, []);

    useEffect(() => {
        return () => {
            player?.destroy();
        };
    }, [player]);

    useEffect(() => {
        if (player) {
            const headerProvider = (url: string) => {
                const manifestOrHSPCall =
                    url.includes("manifest.json") || url.includes(".hspc") || url.includes(".hspi");

                return new Headers({
                    ...(manifestOrHSPCall && { Authorization: `Bearer ${token}` }),
                });
            };
            player.addHeaderProvider(headerProvider);
            return () => {
                player.removeHeaderProvider(headerProvider);
            };
        }
    }, [token, player]);

    useEffect(() => {
        if (requiresServiceWorker()) {
            navigator.serviceWorker.register("/THEOLive.sw.js");
        }
        player?.loadChannel(channelId).catch(console.error);
    }, [player, channelId, channelStatus]);

    return <div ref={onMount} />;
}

import React from "react";
import { TableRowSkeleton } from "../AdminSignUps/AdminSignUpsTableSkeleton";
import { SkeletonRectangle, SkeletonCircle } from "../Loading/Skeletons";

function AdminPromotionalChannelsTableSkeleton() {
    return (
        <>
            {[0, 1, 2, 3, 4].map((i) => (
                <TableRowSkeleton key={`skeleton-row-${i}`}>
                    <SkeletonRectangle width={"50%"} />
                    <SkeletonRectangle width={"50%"} />
                    <SkeletonCircle />
                </TableRowSkeleton>
            ))}
        </>
    );
}

export default AdminPromotionalChannelsTableSkeleton;

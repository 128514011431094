import * as React from "react";

import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

import styled from "styled-components";

interface Props {
    title: string;
    open: boolean;
    bigSize?: boolean;
    onClose: () => void;
    content: React.ReactNode;
}

const Title = styled.div`
    font-weight: 600;
    font-size: 20px;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

function DialogHESP({ open, title, onClose, bigSize, content }: Props) {
    return (
        <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth={bigSize ? "md" : "sm"}>
            <DialogTitle style={{ borderBottom: "none" }}>
                <Header>
                    <Title>{title}</Title>
                    <IconButton
                        aria-label="Close"
                        onClick={() => onClose()}
                        style={{ position: "absolute", right: "10px", top: "5px" }}
                    >
                        <Clear />
                    </IconButton>
                </Header>
            </DialogTitle>
            {content}
        </Dialog>
    );
}

export default DialogHESP;

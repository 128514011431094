import * as React from "react";
import gql from "graphql-tag";
import { Grid, DialogContent, FormControl, TextField, MenuItem, DialogActions } from "@mui/material";
import HESPFormLabel from "../HESPFormLabel/HESPFormLabel";
import DialogHESP from "../DialogHESP/DialogHESP";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { OrganizationStatusType } from "../../__generated__/globalTypes";
import { GET_FULL_BILLING_DETAILS } from "../BillingDetails/BillingDetailsCardContent";
import {
    ChangeOrganizationStatusMutation,
    ChangeOrganizationStatusMutationVariables,
} from "./__generated__/ChangeOrganizationStatusMutation";
import { GET_CHANNELS_ORGANIZATION_QUERY } from "../Channels/Overview/channelQueriesMutations";
import DialogFooterWithLoading from "../Loading/DialogFooterWithLoading";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

export const CHANGE_ORGANIZATION_STATUS_MUTATION = gql`
    mutation ChangeOrganizationStatusMutation($input: ChangeOrganizationStatusInput!) {
        changeOrganizationStatus(input: $input) {
            organizationId
        }
    }
`;

interface Props {
    organizationId: string;
    currentStatus: OrganizationStatusType;
    open: boolean;
    onClose: () => void;
}

function ChangeOrganizationStatusDialog({ organizationId, currentStatus, open, onClose }: Props) {
    const [selectedStatus, setSelectedStatus] = React.useState<OrganizationStatusType>(currentStatus);

    const [changeOrganizationStatusMut, { loading }] = useMutation<
        ChangeOrganizationStatusMutation,
        ChangeOrganizationStatusMutationVariables
    >(CHANGE_ORGANIZATION_STATUS_MUTATION);

    const statuses: OrganizationStatusType[] = [
        OrganizationStatusType.demo,
        OrganizationStatusType.locked,
        OrganizationStatusType.production,
    ];

    async function onSave() {
        try {
            await changeOrganizationStatusMut({
                variables: {
                    input: {
                        organizationId,
                        newStatus: selectedStatus,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CHANNELS_ORGANIZATION_QUERY,
                        variables: {
                            organizationId,
                        },
                    },
                    {
                        query: GET_FULL_BILLING_DETAILS,
                        variables: {
                            organizationId,
                        },
                    },
                ],
            });

            toast.success(`Organization status changed to ${selectedStatus}`, TOAST_SETTINGS);
        } catch (e: any) {
            toast.error("Something went wrong 😞", TOAST_SETTINGS);
        }
        onClose();
    }

    return (
        <DialogHESP
            title={"Change organization status"}
            open={open}
            onClose={onClose}
            content={
                <>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <HESPFormLabel label="Mode" />
                                    <TextField
                                        select
                                        name="mode"
                                        onChange={(e: any) =>
                                            setSelectedStatus(e.target.value as OrganizationStatusType)
                                        }
                                        variant="outlined"
                                        value={selectedStatus}
                                    >
                                        {statuses.map((status: OrganizationStatusType) => (
                                            <MenuItem value={status} key={status}>
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogFooterWithLoading
                            isLoading={loading}
                            onCancelClick={onClose}
                            onActionClick={onSave}
                            defaultText={"Save"}
                            loadingText={"Saving..."}
                        />
                    </DialogActions>
                </>
            }
        />
    );
}

export default ChangeOrganizationStatusDialog;

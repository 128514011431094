import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import { useQuery } from "react-apollo";
import { GET_PROFILES } from "../../components/AdminConfig/Profiles/ProfilesCard";
import { GetProfiles, GetProfilesVariables } from "../../components/AdminConfig/Profiles/__generated__/GetProfiles";
import CardHESP from "../../components/CardHESP/CardHESP";
import CreateChannelForm from "../../components/Channels/Create/CreateChannelForm";
import { SkeletonRectangle } from "../../components/Loading/Skeletons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import gql from "graphql-tag";
import {
    GetOrganizationChannelSettingsQuery,
    GetOrganizationChannelSettingsQueryVariables,
} from "./__generated__/GetOrganizationChannelSettingsQuery";

interface Props extends RouteComponentProps {
    organizationId?: string;
}

export const GET_ORGANIZATION_CHANNEL_SETTINGS = gql`
    query GetOrganizationChannelSettingsQuery($organizationId: ID!) {
        organization(organizationId: $organizationId) {
            organizationId
            tla {
                activated
                datacenters {
                    id
                    name
                }
            }
            channelSettings {
                perpetual {
                    enabled
                }
                drm {
                    enabled
                }
                engine {
                    override {
                        enabled
                        defaultVersion
                    }
                }
            }
        }
    }
`;

export default function CreateChannel({ organizationId }: Props) {
    const { data: dataProfiles, loading: loadingProfiles } = useQuery<GetProfiles, GetProfilesVariables>(GET_PROFILES, {
        variables: {
            organizationId: organizationId!,
        },
    });

    const { data: dataOrg, loading: loadingOrg } = useQuery<
        GetOrganizationChannelSettingsQuery,
        GetOrganizationChannelSettingsQueryVariables
    >(GET_ORGANIZATION_CHANNEL_SETTINGS, {
        variables: {
            organizationId: organizationId!,
        },
    });

    if (loadingProfiles || !dataProfiles || loadingOrg || !dataOrg) {
        return (
            <>
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
                <SkeletonRectangle width="100%" />
            </>
        );
    }

    return (
        <Grid container spacing={{ xs: 5, md: 6 }} justifyContent="center">
            <Grid item xs={12}>
                <SectionTitle title="Create a channel" align="left" showBottomBorder />
                <CardHESP>
                    <CreateChannelForm organization={dataOrg.organization} profiles={dataProfiles.profiles} />
                </CardHESP>
            </Grid>
        </Grid>
    );
}

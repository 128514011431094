import { Grid } from "@mui/material";
import * as React from "react";
import { GetWebhookLogsQuery_webhookLogs_logs } from "./__generated__/GetWebhookLogsQuery";
import WebhookHistoryDataOverview from "./WebhookHistoryDataOverview";
import WebhookHistoryDataDetails from "./WebhookHistoryDataDetails";

interface Props {
    logs: GetWebhookLogsQuery_webhookLogs_logs[];
    onLoadMoreLogs: () => void;
    hasMore: boolean;
}

function WebhookHistoryData({ logs, onLoadMoreLogs, hasMore }: Props) {
    const [selectedLog, setSelectedLog] = React.useState<GetWebhookLogsQuery_webhookLogs_logs>(logs[0]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <WebhookHistoryDataOverview
                    logs={logs}
                    onChangeSelectedLog={(log: GetWebhookLogsQuery_webhookLogs_logs) => setSelectedLog(log)}
                    onShowMoreClick={onLoadMoreLogs}
                    selectedLog={selectedLog}
                    showMore={hasMore}
                />
            </Grid>
            <Grid item xs={6}>
                <WebhookHistoryDataDetails log={selectedLog} />
            </Grid>
        </Grid>
    );
}

export default WebhookHistoryData;

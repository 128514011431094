import * as React from "react";
import { Button } from "@mui/material";

interface Props {
    onAddCreditClick: () => void;
}

function AddBillingCardSection({ onAddCreditClick }: Props) {
    return (
        <div>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    onAddCreditClick();
                }}
            >
                Add Credit Card
            </Button>
        </div>
    );
}

export default AddBillingCardSection;

import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useQuery } from "react-apollo";
import { ResponsiveContainer, LineChart, CartesianGrid, Tooltip, XAxis, YAxis, Line } from "recharts";
import { GetChannelWithAllAliasesQuery_channel } from "../Channels/Overview/__generated__/GetChannelWithAllAliasesQuery";
import { SkeletonRectangle } from "../Loading/Skeletons";
import CustomTooltip from "../Usage/CustomTooltip";
import { chartContainerStyle } from "../Usage/UsageGraph";
import {
    GetTranscodedMinutesOfChannel,
    GetTranscodedMinutesOfChannelVariables,
} from "./__generated__/GetTranscodedMinutesOfChannel";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { Button, Grid } from "@mui/material";
import { CSVLink } from "react-csv";

interface Props {
    channel: GetChannelWithAllAliasesQuery_channel;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export const GET_TRANSCODED_MINUTES_OF_CHANNEL = gql`
    query GetTranscodedMinutesOfChannel($channelId: ID!, $organizationId: ID!, $startDate: String!, $endDate: String!) {
        getTranscodedMinutesOfChannel(
            channelId: $channelId
            organizationId: $organizationId
            startDate: $startDate
            endDate: $endDate
        ) {
            id
            records {
                timestamp
                amount
            }
        }
    }
`;

function ChannelUsageTranscoded({ channel, startDate, endDate }: Props) {
    const { data, loading } = useQuery<GetTranscodedMinutesOfChannel, GetTranscodedMinutesOfChannelVariables>(
        GET_TRANSCODED_MINUTES_OF_CHANNEL,
        {
            variables: {
                channelId: channel.channelId,
                organizationId: channel.organizationId,
                startDate: moment(startDate).startOf("day").utc().toISOString(),
                endDate: moment(endDate).endOf("day").utc().toISOString(),
            },
        },
    );

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const detailsWithTz = data?.getTranscodedMinutesOfChannel.records.map((record) => ({
        timestamp: moment(record.timestamp).tz(tz).format("YYYY-MM-DD"),
        amount: record.amount,
    }));

    const groupedByDate = _.chain(detailsWithTz)
        .groupBy("timestamp")
        .map((value, key) => ({
            timestamp: key,
            total: value.map((v) => v.amount).reduce((a, b) => a + b, 0),
        }))
        .value();

    const sorted = _.orderBy(groupedByDate, ["time"], ["asc"]);

    const csvHeaders = ["date", "amount"];
    const csvData = !data
        ? []
        : groupedByDate.map((r) => ({
              date: r.timestamp,
              amount: r.total,
          }));

    return (
        <>
            <CardHESPTitle
                title="Minutes transcoded in period"
                subtitle="Only applicable for parent channel"
                button={
                    data && data.getTranscodedMinutesOfChannel.records.length > 0 ? (
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={`channel-${channel.channelId}-usage-transcoded.csv`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button variant="contained" style={{ color: "black" }} color="inherit">
                                Export as CSV
                            </Button>
                        </CSVLink>
                    ) : (
                        <></>
                    )
                }
            />
            <Grid container spacing={2} style={{ marginTop: "2px" }}>
                <Grid item xs={12}>
                    {loading || !data ? (
                        <div>
                            <SkeletonRectangle width="100%" />
                            <SkeletonRectangle width="100%" />
                            <SkeletonRectangle width="100%" />
                            <SkeletonRectangle width="100%" />
                            <SkeletonRectangle width="100%" />
                        </div>
                    ) : (
                        <div style={{ height: "250px" }}>
                            <ResponsiveContainer height="90%" width="100%">
                                <LineChart
                                    data={sorted}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: -15,
                                        bottom: 1 - 0,
                                    }}
                                    style={chartContainerStyle()}
                                >
                                    <CartesianGrid vertical={false} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <XAxis dataKey="timestamp" name={"Day"} minTickGap={10} />
                                    <YAxis minTickGap={10} />
                                    <Line
                                        type="monotone"
                                        dataKey="total"
                                        stroke="#FFC713"
                                        strokeWidth={2}
                                        activeDot={{ r: 8 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default ChannelUsageTranscoded;

import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import { GetFullBillingDetails_organization } from "./__generated__/GetFullBillingDetails";
import CurrentBillingPayg from "./CurrentBillingPayg";
import CurrentBillingCommitment from "./CurrentBillingCommitment";
import { ContractType } from "../../__generated__/globalTypes";

interface Props {
    organization: GetFullBillingDetails_organization;
}

function CurrentBillingPeriodInfo({ organization }: Props) {
    const { contractType } = organization;

    return (
        <CardHESP>
            {contractType === ContractType.payg && <CurrentBillingPayg organization={organization} />}
            {contractType === ContractType.commitment && <CurrentBillingCommitment organization={organization} />}
        </CardHESP>
    );
}

export default CurrentBillingPeriodInfo;

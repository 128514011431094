import * as React from "react";
import CardHESP from "../CardHESP/CardHESP";
import CardHESPTitle from "../CardHESP/CardHESPTitle";
import { Grid } from "@mui/material";
import AdminPromotionalChannelsTable from "./AdminPromotionalChannelsTable";
import ChangeChannelModeDialog from "../Channels/Details/ChangeChannelModeDialog";
import { GetPromotionalChannelsByMode_channelsByMode } from "./__generated__/GetPromotionalChannelsByMode";
import { toast, ToastContainer } from "react-toastify";
import { ChannelModeType } from "../../__generated__/globalTypes";
import { TOAST_SETTINGS } from "../BillingDetails/AddPaymentMethodDialog";

function AdminPromotionalChannels() {
    const [selectedChannel, setSelectedChannel] = React.useState<
        GetPromotionalChannelsByMode_channelsByMode | undefined
    >();
    const [openChangeModeDialog, setOpenChangeModeDialog] = React.useState<boolean>(false);

    function onOpenChangeDialog(channel: GetPromotionalChannelsByMode_channelsByMode) {
        setSelectedChannel(channel);
        setOpenChangeModeDialog(true);
    }

    function onSuccessfulModeChange(newMode: ChannelModeType) {
        toast.success(`Channel mode changed to ${newMode}`, TOAST_SETTINGS);
    }

    function onErrorModeChange() {
        toast.error("Something went wrong 😞", TOAST_SETTINGS);
    }

    return (
        <>
            <CardHESP style={{ marginTop: "30px" }}>
                <CardHESPTitle title="Promotional channels" />
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <AdminPromotionalChannelsTable
                        onChangeClick={(channel: GetPromotionalChannelsByMode_channelsByMode) =>
                            onOpenChangeDialog(channel)
                        }
                    />
                </Grid>
            </CardHESP>
            {selectedChannel && (
                <ChangeChannelModeDialog
                    open={openChangeModeDialog}
                    channel={selectedChannel}
                    onClose={() => setOpenChangeModeDialog(false)}
                    onSuccess={(newMode: ChannelModeType) => onSuccessfulModeChange(newMode)}
                    onError={() => onErrorModeChange()}
                />
            )}
            <ToastContainer />
        </>
    );
}

export default AdminPromotionalChannels;

import { Mark } from "@mui/base";
import { Slider } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

interface Props {
    selectedValue: number;
    isEditable: boolean;
    sliderMinValue: number;
    sliderMaxValue: number;
    showMarks: boolean;
    sliderStep?: number;
    marks?: Mark[];
    rangeTitles?: {
        left: string;
        right: string;
    };
    onValueChange: (val: number) => void;
    onDoubleClick: () => void;
    transformTooltipText: (value: number) => string;
}

const Wrapper = styled.div`
    font-size: 11px;
    display: flex;
    color: #757575;
    justify-content: space-between;
`;

const SliderWrapper = styled.div`
    padding: 0px 10px;
`;

const Left = styled.div`
    width: 40%;
    text-align: left;
`;

const Right = styled.div`
    width: 40%;
    text-align: right;
`;

function CustomizeItemSlider({
    selectedValue,
    isEditable,
    sliderMaxValue,
    sliderMinValue,
    sliderStep,
    showMarks,
    onValueChange,
    onDoubleClick,
    rangeTitles,
    marks,
    transformTooltipText,
}: Props) {
    return (
        <div onDoubleClick={onDoubleClick}>
            <SliderWrapper>
                <Slider
                    value={selectedValue}
                    valueLabelFormat={transformTooltipText}
                    step={sliderStep ?? 1}
                    size="small"
                    marks={showMarks ? marks : []}
                    min={sliderMinValue}
                    max={sliderMaxValue}
                    onChange={(e: any) => onValueChange(e.target.value)}
                    valueLabelDisplay={"auto"}
                    disabled={!isEditable}
                />
            </SliderWrapper>
            {rangeTitles && (
                <Wrapper>
                    <Left>{rangeTitles.left}</Left>
                    <Right>{rangeTitles.right}</Right>
                </Wrapper>
            )}
        </div>
    );
}

export default CustomizeItemSlider;

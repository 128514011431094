import styled from "styled-components";

interface Props {
    title: string;
    amount: number | React.ReactNode;
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0px;
`;

const Amount = styled.div`
    font-weight: bold;
    font-size: 32px;
    text-align: center;
`;

const Title = styled.div`
    color: ${(props) => props.theme.gray};
    text-align: center;
    font-size: 13px;
`;

function ViewerNumberWithTitle({ amount, title }: Props) {
    return (
        <Wrapper>
            <Amount>{typeof amount === "number" ? new Intl.NumberFormat("en-GB").format(amount) : amount}</Amount>
            <Title>{title}</Title>
        </Wrapper>
    );
}

export default ViewerNumberWithTitle;
